import { LinkLike } from "@/common/components/link-like/LinkLike";
import { routes } from "@/config/routes";
import { BuyoutReleasesFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { getUserName } from "../../../../../../common/utils/users/getUserName";

type Props = {
  buyout: BuyoutReleasesFieldsFragment;
};

const Container = tw.div`flex flex-col gap-1`;
const BuyoutLink = tw.div``;
const Creator = tw.div`text-xs`;

export const BuyoutReleaseInfo: FC<Props> = ({ buyout }) => {
  return (
    <Container>
      <BuyoutLink>
        <LinkLike to={generatePath(routes.buyout, { id: buyout.id })}>
          <FormattedMessage
            id="BUYOUT"
            values={{ number: buyout.clientIdentifier }}
          />
        </LinkLike>
      </BuyoutLink>
      <Creator>
        <FormattedMessage
          id="BY_USERNAME"
          values={{ username: getUserName(buyout.createdBy) }}
        />
      </Creator>
    </Container>
  );
};
