import { useMemo } from "react";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";
import { InvoiceBreadcrumbAdditionalItem } from "../enums/InvoiceBreadcrumbAppendedItem";

export const useInvoiceBreadcrumbsAppendedItems = () => {
  const { invoice } = useInvoiceVerification();

  const appendedItems = useMemo(() => {
    const items = [];
    if (invoice) {
      items.push(InvoiceBreadcrumbAdditionalItem.InvoiceStatusChip);
    }
    if (invoice?.archivedAt) {
      items.push(InvoiceBreadcrumbAdditionalItem.Archive);
    }
    return items;
  }, [invoice]);

  return { appendedItems };
};
