import { useDialog } from "@/common/components/dialog/DialogProvider";
import { BuyoutStatus } from "@/generated/graphql";
import { ReactElement, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useArchiveBuyouts } from "../../hooks/useArchiveBuyouts";
import { useBuyoutSequence } from "../../hooks/useBuyoutSequence";
import { useBuyouts } from "../../providers/BuyoutsProvider";

const BUYOUT_DELETE_STATUSES = [
  BuyoutStatus.Cancelled,
  BuyoutStatus.Declined,
  BuyoutStatus.Draft,
];

type Props = {
  deleteIcon: ReactElement;
};

export const useBuyoutSideActions = ({ deleteIcon }: Props) => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useBuyoutSequence();
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { refetch, filter } = useBuyouts();
  const { archiveBuyouts } = useArchiveBuyouts();

  const deleteItems = useCallback(() => {
    const removableItems = selectedEntities.filter(
      (buyout) =>
        !buyout.deletedAt && BUYOUT_DELETE_STATUSES.includes(buyout.status),
    );
    if (removableItems.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        includeWarningIcon: true,
        title: intl.$t({ id: "DELETE_CONFIRMATION" }),
        text:
          removableItems.length === 1 && removableItems[0]?.id
            ? intl.$t(
                { id: "DELETE_BUYOUT_MESSAGE" },
                {
                  buyoutNumber: removableItems[0].clientIdentifier,
                },
              )
            : intl.$t(
                { id: "DELETE_BUYOUTS_MESSAGE" },
                { number: removableItems.length },
              ),
        handleConfirm: async () => {
          if (
            await archiveBuyouts(removableItems.map((release) => release.id))
          ) {
            setSelectedEntities([]);
            refetch();
          }
        },
      });
    }
  }, [
    selectedEntities,
    openDialog,
    intl,
    archiveBuyouts,
    setSelectedEntities,
    refetch,
  ]);

  const actions = useMemo(() => {
    const sideActions = [];

    if (
      selectedEntities.some(
        (buyout) =>
          !buyout.deletedAt && BUYOUT_DELETE_STATUSES.includes(buyout.status),
      ) &&
      filter?.deleted !== true
    ) {
      sideActions.push({
        icon: deleteIcon,
        tooltipKey: "DELETE_ITEMS",
        callback: deleteItems,
      });
    }

    return sideActions;
  }, [selectedEntities, filter?.deleted, deleteIcon, deleteItems]);

  return { actions, sequenceIds };
};
