import { If } from "@/common/components/if/If";
import { useResizeColumnsStore } from "@/common/components/resizable-columns/useResizeColumnsStore";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useExpandableFooterRef } from "../../../../../../../../../common/components/footer/hooks/useExpandableFooterRef";
import { InvoiceCreateReleaseFormValues } from "../../matched-order/components/InvoiceVerificationForm";
import { InvoiceItemsPanel } from "./InvoiceItemsPanel";
import { InvoicePanel } from "./InvoicePanel";
import { ReleasePanel } from "./ReleasePanel";
import { ReleaseUninvoicedPanel } from "./ReleaseUninvoicedPanel";

const Container = tw.div`w-full bg-white`;

type Props = {
  form: UseFormReturn<InvoiceCreateReleaseFormValues>;
  displayExtended: boolean;
  displayRemainingBalance: boolean;
};

export const InvoiceFooterExpanded: FC<Props> = ({
  form,
  displayExtended,
  displayRemainingBalance,
}) => {
  const ref = useExpandableFooterRef();
  const { leftWidth } = useResizeColumnsStore();

  return (
    <Container
      ref={ref}
      style={{
        display: "grid",
        gridTemplateColumns: `${leftWidth}% calc(100% - ${leftWidth}%)`,
      }}
    >
      <ReleasePanel form={form} />
      <If isTrue={displayExtended}>
        <ReleaseUninvoicedPanel display={displayRemainingBalance} />
        <InvoiceItemsPanel />
      </If>
      <InvoicePanel />
    </Container>
  );
};
