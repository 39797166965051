import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import { useInvoiceSequence } from "../../providers/InvoiceSequenceProvider";
import { useInvoicesSideActions } from "./hooks/useInvoicesSideActions";

export const InvoicesSideActions = () => {
  const { sequenceIds } = useInvoiceSequence();
  const { loading, actions } = useInvoicesSideActions();

  return (
    <SideActionBar
      loading={loading}
      visible={sequenceIds.length > 0}
      actions={actions}
    />
  );
};
