import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { KickbackSummaryFieldsFragment } from "@/generated/graphql";
import { Repeat } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useKickbackIndicator } from "./hooks/useKickbackIndicator";
const Rounded = tw.div<{
  $disabled: boolean;
  $notRequired?: boolean;
  $failed?: boolean;
}>`
${({ $disabled }) =>
  $disabled ? "bg-white text-gray-500" : "bg-green-300 text-black"}
${({ $failed }) => $failed && "bg-red-500 text-white"}
${({ $notRequired }) => $notRequired && "bg-gray-200 text-black"}
rounded-full p-3 text-xs border border-gray-300 w-5 h-5 flex items-center justify-center`;
const KickbackIndicatorContainer = tw(Rounded)<{
  $isActiveKickback: boolean;
  $isResolvedKickback: boolean;
}>`
${({ $isActiveKickback }) => $isActiveKickback && "bg-orange-500 text-black border-orange-500"}
${({ $isResolvedKickback }) => $isResolvedKickback && "bg-green-300 text-black border-green-300"}
`;
const KickbackIcon = tw(Repeat)`h-4 w-4`;
const KickbackInfoLine = tw.div`text-center`;

export const KickbackIndicator: FC<{
  kickbacks: KickbackSummaryFieldsFragment[];
}> = ({ kickbacks }) => {
  const { kickbackInfo, totalKickbacks, resolvedKickbacks } =
    useKickbackIndicator({ kickbacks });

  return (
    <Tooltip
      id="invoice-kickbacks"
      element={
        <KickbackIndicatorContainer
          $disabled
          $isActiveKickback={totalKickbacks !== resolvedKickbacks}
          $isResolvedKickback={
            totalKickbacks === resolvedKickbacks && totalKickbacks !== 0
          }
        >
          <KickbackIcon />
        </KickbackIndicatorContainer>
      }
    >
      {kickbackInfo.map((text, index) => (
        <KickbackInfoLine key={index}>{text}</KickbackInfoLine>
      ))}
    </Tooltip>
  );
};
