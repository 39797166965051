import {
  ProjectListOptionFieldsFragment,
  ProjectStatus,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useProjectListOptionsStore } from "../stores/useProjectListOptionsStore";

type ProjectListProps = {
  includeClosedProjects?: boolean | null;
  skip?: boolean;
};

export const useProjectListOptions = ({
  includeClosedProjects = null,
  skip = false,
}: ProjectListProps = {}) => {
  const { projects, loading, fetchProjects, setProjects } =
    useProjectListOptionsStore(
      useShallow((state) => ({
        projects: state.projects,
        loading: state.loading,
        fetchProjects: state.fetchProjects,
        setProjects: state.setProjects,
      })),
    );

  useEffect(() => {
    if (!skip) {
      fetchProjects();
    }
  }, [fetchProjects, skip]);

  const addProject = useCallback(
    (project: ProjectListOptionFieldsFragment) => {
      setProjects([...projects, project]);
    },
    [setProjects, projects],
  );

  const removeProject = useCallback(
    (id: string) => {
      setProjects(projects.filter((project) => project.id !== id));
    },
    [setProjects, projects],
  );

  const filteredProjects = useMemo(() => {
    if (includeClosedProjects === null) {
      return projects;
    }

    return projects.filter((project) =>
      includeClosedProjects
        ? [ProjectStatus.Completed, ProjectStatus.Lost].includes(project.status)
        : [ProjectStatus.Active, ProjectStatus.Awarded].includes(
            project.status,
          ),
    );
  }, [projects, includeClosedProjects]);

  return {
    projects: filteredProjects,
    loading,
    addProject,
    removeProject,
  };
};
