import { SpecifyCostCodeExpandable } from "@/common/components/specify-cost-code/SpecifyCostCodeExpandable";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceUpdateRelease } from "../../../../providers/InvoiceUpdateReleaseProvider";
import { useInvoiceUpdateReleaseSpreadsheetConfig } from "./InvoiceUpdateRelease.config";
import { ReleaseItemType } from "./types/ReleaseItemType";

const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-full`;

type Props = {
  releaseItems: ReleaseItemType[];
  updateSubtotal: (data: Record<string, string>[]) => void;
};

export const InvoiceUpdateReleaseItemizedView: FC<Props> = ({
  releaseItems,
  updateSubtotal,
}) => {
  const { release } = useRelease();
  const { saving } = useInvoiceUpdateRelease();
  const spreadsheetViewColumns = useInvoiceUpdateReleaseSpreadsheetConfig();

  return (
    <>
      <SpreadSheetView>
        <SpreadSheetTable
          items={releaseItems}
          columns={spreadsheetViewColumns}
          saving={saving}
          height="550px"
          onChanges={updateSubtotal}
        />
      </SpreadSheetView>
      <SpecifyCostCodeExpandable costCode={release?.costCode} />
    </>
  );
};
