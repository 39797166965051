import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { ProjectExtendedFieldsFragment } from "@/generated/graphql";
import { FC, useEffect, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { UNSPECIFIED_COST_CODE_ID } from "../../../../../common/hooks/useUnspecifiedCostCode";
import { useProjectMaps } from "../hooks/useProjectMaps";
import {
  ProjectItemsZonesProvider,
  useProjectItemsZones,
} from "../providers/ProjectItemsZonesProvider";
import {
  SyncEstimatedItemsProvider,
  useSyncEstimatedItems,
} from "../providers/SyncEstimatedItemsProvider";
import { ProjectContentFooter } from "./ProjectFooter";
import { ProjectHeader } from "./project-header/ProjectHeader";
import { useProjectListItemConfiguration } from "./project-list/ProjectListItem.configuration";

type Props = {
  project: ProjectExtendedFieldsFragment;
};

const ProjectContentWithProviders: FC<Props> = ({ project }) => {
  const { zones } = useProjectItemsZones();
  const { estimatedItemsMap } = useProjectMaps(project);
  const { saving } = useSyncEstimatedItems();

  const items = useMemo(
    () =>
      zones
        .map((zone) =>
          zone.items.map((code) =>
            code.items.map((item) =>
              item.estimatedItems
                .filter((ei) => estimatedItemsMap.get(ei.id))
                .map((ei) => ({
                  ...item,
                  zone,
                  code:
                    code.id !== UNSPECIFIED_COST_CODE_ID
                      ? code
                      : {
                          ...code,
                          name: "",
                        },
                  ...estimatedItemsMap.get(ei.id),
                })),
            ),
          ),
        )
        .flat(3),
    [zones, estimatedItemsMap],
  );

  const { spreadsheetConfig } = useProjectListItemConfiguration();
  return (
    <>
      <ProjectHeader />
      <SpreadSheetTable
        items={items}
        columns={spreadsheetConfig}
        saving={saving}
        height="calc(100vh - 350px)"
      />
      <ProjectContentFooter />
    </>
  );
};

export const ProjectContent: FC<Props> = ({ project }) => {
  const { spreadsheetConfig } = useProjectListItemConfiguration();
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.normal);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  return (
    <ProjectItemsZonesProvider project={project}>
      <ColumnMapperProvider config={spreadsheetConfig}>
        <SyncEstimatedItemsProvider>
          <ProjectContentWithProviders project={project} />
        </SyncEstimatedItemsProvider>
      </ColumnMapperProvider>
    </ProjectItemsZonesProvider>
  );
};
