import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useSequence } from "@/common/hooks/useSequence";
import { routes } from "@/config/routes";
import { ProjectProjectsFieldsFragment } from "@/generated/graphql";
import { generatePath } from "react-router";
import { useProjectSequenceStore } from "./useProjectSequenceStore";

export const useProjectSequence = () => {
  return useSequence<ProjectProjectsFieldsFragment>({
    listPath: routes.projects,
    localStorageConfig: LOCAL_STORAGE_KEYS.PROJECT_SEQUENCES,
    singEntityPath: (id: string) => generatePath(routes.project, { id }),
    useStore: useProjectSequenceStore,
  });
};
