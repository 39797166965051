import { useDialog } from "@/common/components/dialog/DialogProvider";
import {
  ExportCompleted,
  ExportType,
} from "@/common/components/export-completed/ExportCompleted";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useExportBatch } from "@/common/providers/ExportBatchProvider";
import { useCallback, useMemo } from "react";
import { useExportInvoice } from "../../../../providers/ExportInvoiceProvider";
import { useInvoicesByIds } from "../../../../providers/InvoicesByIdsProvider";
import { ConnectInvoicesPanelProps } from "../types/ConnectInvoicesPanelProps";

export const useConnectInvoicesPanel = ({
  onClose,
}: Pick<ConnectInvoicesPanelProps, "onClose">) => {
  const { validatedInvoices } = useInvoicesByIds();
  const invoicesToExportCount = useMemo(
    () => validatedInvoices.filter((i) => i.validated).length,
    [validatedInvoices],
  );
  const { linkInvoices } = useExportInvoice();
  const { openDialog } = useDialog();
  const { newBatch, externalBatch, postAutomatically } = useExportBatch();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const onSave = useCallback(async () => {
    const linkingResult = await linkInvoices();
    if (!linkingResult?.success) {
      return;
    }
    openDialog({
      content: (
        <ExportCompleted
          count={invoicesToExportCount}
          type={ExportType.INVOICE}
          batch={linkingResult.batch}
        />
      ),
      closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      hideTitle: true,
    });
    onClose();
  }, [openDialog, invoicesToExportCount, onClose, linkInvoices]);

  const disableSave = useMemo(() => {
    if (
      hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceBatching) &&
      !postAutomatically &&
      !newBatch &&
      !externalBatch
    ) {
      return true;
    }
    return (
      invoicesToExportCount === 0 || validatedInvoices.some((v) => v.loading)
    );
  }, [
    externalBatch,
    hasFeatureInConnectedSourceSystem,
    invoicesToExportCount,
    newBatch,
    postAutomatically,
    validatedInvoices,
  ]);

  return { onSave, disableSave };
};
