import { MessageOrgType } from "@/common/components/messages/Messages";
import { MessagesPanel } from "@/common/components/messages/components/MessagesPanel";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { MESSAGES_SEGMENT } from "@/config/routes";
import { MessageContextKind } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router";
import tw from "tailwind-styled-components";
import {
  QuoteMessagesProvider,
  useQuoteMessage,
} from "../../providers/QuoteMessagesProvider";
import { RfqWideCheckbox } from "../quote-messages/RfqWideCheckbox";

type Props = {
  quote: {
    id: string;
    clientIdentifier: string;
    rfqId: string;
    projectId: string;
  };
  text: React.ReactNode;
  org: MessageOrgType;
};

const Container = tw.div`flex items-center`;

const RfqMessagesButtonWithProvider: FC<Props> = ({
  text,
  quote,
  org,
}: Props) => {
  const { isRfqWideMessage } = useQuoteMessage();
  const location = useLocation();
  const { bidId } = useParams();
  const defaultOpened = useMemo(
    () => location.pathname.includes(MESSAGES_SEGMENT) && bidId === quote.id,
    [location.pathname, bidId, quote.id],
  );
  const params = useMemo(() => {
    if (isRfqWideMessage) {
      return { customId: quote.rfqId, customKind: MessageContextKind.Rfq };
    } else {
      return { customId: quote.id, customKind: MessageContextKind.Quote };
    }
  }, [isRfqWideMessage, quote.id, quote.rfqId]);

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <Container onClick={() => togglePanel(true)}>{text}</Container>
      )}
      content={(togglePanel) => (
        <MessagesPanel
          title="MESSENGER"
          id={quote.id}
          kind={MessageContextKind.Quote}
          context={{ quote }}
          isContractor
          org={org}
          messageTabName={<FormattedMessage id="MESSAGE_VENDOR" />}
          noMessage="QUOTE_NO_MESSAGES"
          onClose={() => togglePanel(false)}
          customParams={params}
          components={{ MessageSenderBottom: <RfqWideCheckbox /> }}
          projectId={quote.projectId}
        />
      )}
      defaultOpened={defaultOpened}
    />
  );
};

export const RfqMessagesButton: FC<Props> = (props) => (
  <QuoteMessagesProvider>
    <RfqMessagesButtonWithProvider {...props} />
  </QuoteMessagesProvider>
);
