import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { SetProjectAllowanceInput } from "@/generated/graphql";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useProjectBudget } from "../../hooks/useProjectBudget";
import { BudgetHeaderFormValues } from "./BudgetHeaderForm";

export const useBudgetByCostCodes = () => {
  const { spreadsheetData, gotoInvalidRow } = useColumnMapper();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const {
    updateProjectBudget,
    linkBudget,
    updateBudgetLink,
    allowance,
    zones,
    currentProjectId,
    budgetLink,
    isZoneSpecificBudget,
  } = useProjectBudget();
  const { getCostCodeId } = useTableHelpers();
  const { connectedSourceSystem } = useOrgSettings();
  const { watch, setValue } = useFormContext<BudgetHeaderFormValues>();
  const zoneSpecificBudget = watch("zoneSpecificBudget");

  const validateItems = useCallback(async () => {
    if (
      !(await validateRequiredValues([COLUMN_TYPE.CostCode])) ||
      !(await validateRowValues([COLUMN_TYPE.CostCode]))
    ) {
      gotoInvalidRow();
      return false;
    }
    return true;
  }, [gotoInvalidRow, validateRequiredValues, validateRowValues]);

  const getCostCodeBudget = useCallback(
    (formInput?: BudgetHeaderFormValues) => {
      const codes = spreadsheetData.filter((row) => !rowIsEmpty(row));
      const input = {
        amount: formInput?.allowance ? String(formInput.allowance) : "0",
        costCodes: codes
          .map((row, index) => {
            if (zoneSpecificBudget) {
              return zones.map((zone, zoneIndex) => {
                const costTypes =
                  allowance.costCodes.find(
                    (cc) =>
                      cc.costCode.id === getCostCodeId(row) &&
                      cc.zone?.id === zone.id,
                  )?.costTypes || [];
                return {
                  costCodeId: getCostCodeId(row),
                  amount:
                    getCellValue(row, `${COLUMN_TYPE.Budget}_${zone.id}`) ||
                    "0",
                  zoneId: zone.id,
                  position: index * zones.length + zoneIndex,
                  costTypeIds: costTypes.map((costType) => costType.id),
                };
              });
            } else {
              const costTypes =
                allowance.costCodes.find(
                  (cc) =>
                    cc.costCode.id === getCostCodeId(row) && cc.zone === null,
                )?.costTypes || [];
              return {
                costCodeId: getCostCodeId(row),
                amount: getCellValue(row, COLUMN_TYPE.Budget) || "0",
                zoneId: null,
                position: index,
                costTypeIds: costTypes.map((costType) => costType.id),
              };
            }
          })
          .flat(),
        tags: allowance.tags.map((tag) => ({
          tagId: tag.tag.id,
          amount: tag.amount,
        })),
      } as SetProjectAllowanceInput;
      return input;
    },
    [spreadsheetData, getCostCodeId, zones, zoneSpecificBudget, allowance],
  );

  const saveCostCodeBudget = useCallback(
    async (formInput: BudgetHeaderFormValues) => {
      if (!(await validateItems())) {
        return undefined;
      }
      const result = await updateProjectBudget({
        budgetAllowance: getCostCodeBudget(formInput),
        restrictCostCodes: formInput.restrictCostCodes,
      });

      return result;
    },
    [updateProjectBudget, getCostCodeBudget, validateItems],
  );

  const saveAutoSyncBudget = useCallback(
    async (value: boolean) => {
      if (!connectedSourceSystem) {
        return;
      }

      let result;
      if (value && currentProjectId && !budgetLink?.id) {
        result = await linkBudget({
          sourceSystem: connectedSourceSystem,
          projectId: currentProjectId,
        });
      } else if (budgetLink?.id) {
        result = await updateBudgetLink({
          budgetLinkID: budgetLink?.id,
          autoSync: value,
        });
      }
      setValue("zoneSpecificBudget", isZoneSpecificBudget);
      return result;
    },
    [
      linkBudget,
      updateBudgetLink,
      connectedSourceSystem,
      currentProjectId,
      budgetLink,
      isZoneSpecificBudget,
      setValue,
    ],
  );

  const calculateBudget = useCallback(() => {
    const costCodes = getCostCodeBudget();
    const total =
      costCodes.costCodes.reduce(
        (acc, costCode) => acc.add(costCode.amount),
        new DecimalSafe(0),
      ) || new DecimalSafe(0);
    setValue("allowance", total?.toNumber());
  }, [getCostCodeBudget, setValue]);

  return {
    validateItems,
    saveCostCodeBudget,
    saveAutoSyncBudget,
    calculateBudget,
  };
};
