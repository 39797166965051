import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { InvoiceStatus } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  DEFAULT_INVOICES_FILTER,
  useInvoices,
} from "../../../../providers/InvoicesProvider";

export const useScannedInvoicesHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useInvoices();
  const { projects } = useProjectListOptions({
    includeClosedProjects: filter?.closedProjects,
  });

  const options = useMemo(() => {
    return [
      {
        value: InvoiceStatus.Approved,
        name: intl.$t({ id: "INVOICE_STATUS_APPROVED" }),
      },
      {
        value: InvoiceStatus.AwaitingApproval,
        name: intl.$t({ id: "INVOICE_STATUS_AWAITING_APPROVAL" }),
      },
      {
        value: InvoiceStatus.IssuesReported,
        name: intl.$t({ id: "INVOICE_STATUS_ISSUES_REPORTED" }),
      },
      {
        value: InvoiceStatus.Paid,
        name: intl.$t({ id: "INVOICE_STATUS_PAID" }),
      },
    ];
  }, [intl]);

  const setFilterHandler = useCallback(
    (values: InvoiceStatus[] | undefined) => {
      setFilter({
        ...filter,
        statuses: values,
      });
    },
    [filter, setFilter],
  );

  const setFolderFilterHandler = useCallback(
    (folderIds: string[] | undefined) => {
      if (!folderIds) {
        setFilter({
          ...filter,
          folderIds: undefined,
        });
      } else {
        setFilter({
          ...filter,
          folderIds,
        });
      }
    },
    [filter, setFilter],
  );

  const appliedFilters = useMemo(() => {
    return (
      Number(filter?.kickbackResolved != null) +
      Number(
        !!filter?.kickbackByUserIds && filter.kickbackByUserIds.length !== 0,
      ) +
      Number(
        !!filter?.kickbackToUserIds && filter.kickbackToUserIds.length !== 0,
      ) +
      Number(filter?.hasKickback != null) +
      Number(filter?.poExported != null) +
      Number(filter?.receiptPosted != null) +
      Number(filter?.archived !== false) +
      Number(filter?.exported != null) +
      Number(filter?.sellerOrgLocationIds != null) +
      Number(filter?.downloaded != null) +
      Number(filter?.closedProjects !== false) +
      Number(filter?.releaseTypeIds != null) +
      Number(filter?.batchMonth != null) +
      Number(filter?.batchNumber != null) +
      Number(filter?.orderAssigned != null) +
      Number(filter?.projectMemberUserIds != null) +
      Number(filter?.equipmentItemIds != null)
    );
  }, [
    filter?.kickbackResolved,
    filter?.kickbackByUserIds,
    filter?.kickbackToUserIds,
    filter?.hasKickback,
    filter?.poExported,
    filter?.receiptPosted,
    filter?.archived,
    filter?.exported,
    filter?.sellerOrgLocationIds,
    filter?.downloaded,
    filter?.closedProjects,
    filter?.releaseTypeIds,
    filter?.batchMonth,
    filter?.batchNumber,
    filter?.orderAssigned,
    filter?.projectMemberUserIds,
    filter?.equipmentItemIds,
  ]);

  const appliedDateFilters = useMemo(() => {
    return (
      Number(filter?.minIssueDate != null || filter?.maxIssueDate != null) +
      Number(filter?.minDueDate != null || filter?.maxDueDate != null) +
      Number(filter?.minCreatedAt != null || filter?.maxCreatedAt != null) +
      Number(filter?.minApprovedAt != null || filter?.maxApprovedAt != null)
    );
  }, [
    filter?.minIssueDate,
    filter?.maxIssueDate,
    filter?.minCreatedAt,
    filter?.maxCreatedAt,
    filter?.minDueDate,
    filter?.maxDueDate,
    filter?.minApprovedAt,
    filter?.maxApprovedAt,
  ]);

  const countNonDefaultFilters = useMemo(() => {
    return (
      appliedFilters +
      appliedDateFilters +
      Number(filter?.search != null && filter?.search !== "") +
      Number((filter?.statuses || []).length !== 0) +
      Number((filter?.projectIds || []).length !== 0) +
      Number((filter?.folderIds || []).length !== 0)
    );
  }, [
    appliedDateFilters,
    appliedFilters,
    filter?.folderIds,
    filter?.projectIds,
    filter?.search,
    filter?.statuses,
  ]);

  const resetFilterToDefault = useCallback(() => {
    setFilter(DEFAULT_INVOICES_FILTER);
  }, [setFilter]);

  return {
    options,
    projects,
    setFilterHandler,
    setFolderFilterHandler,
    countNonDefaultFilters,
    resetFilterToDefault,
    appliedFilters,
    appliedDateFilters,
  };
};
