import { SequenceStore } from "@/common/hooks/useSequence";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { StoredInvoiceEmail } from "./useInvoiceEmailsSequence";

const invoiceEmailToStoredInvoiceEmail = (invoiceEmail: StoredInvoiceEmail) => {
  return {
    id: invoiceEmail.id,
    archivedAt: invoiceEmail.archivedAt,
  };
};

export const useInvoiceEmailsSequenceStore = create<
  SequenceStore<StoredInvoiceEmail>
>()(
  devtools((set) => ({
    selectedEntities: [],
    step: 0,
    sequenceActive: false,
    sequenceEnabled: false,
    setSelectedEntities: (values: StoredInvoiceEmail[]) =>
      set(() => ({
        selectedEntities: values.map(invoiceEmailToStoredInvoiceEmail),
      })),
    setStep: (step: number) =>
      set(() => ({
        step,
      })),
    setSequenceActive: (sequenceActive: boolean) =>
      set(() => ({
        sequenceActive,
      })),
    setSequenceEnabled: (sequenceEnabled: boolean) =>
      set(() => ({
        sequenceEnabled,
      })),
  })),
);
