import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { NoResults } from "@/common/components/no-results/NoResults";
import { MissingBudgetCombinationsProvider } from "@/contractor/pages/home/release/components/connections/providers/MissingBudgetCombinationsProvider";
import tw from "tailwind-styled-components";
import { MatchedOrderViewState } from "../../enums/MatchedOrderViewState";
import { useInvoiceOrderEdit } from "../../hooks/useInvoiceOrderEdit";
import { useInvoiceMatchedOrder } from "../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../providers/InvoiceVerificationProvider";
import { InvoiceCreateRelease } from "./components/invoice-create-release/InvoiceCreateRelease";
import { InvoiceOrderSelectorView } from "./components/invoice-match-header/invoice-order-selector/InvoiceOrderSelectorView";
import { InvoiceMatchedHeader } from "./components/invoice-match-header/InvoiceMatchedHeader";
import { InvoiceReleaseItems } from "./components/invoice-release-items/InvoiceReleaseItems";
import { InvoiceUpdateRelease } from "./components/invoice-update-release/InvoiceUpdateRelease";
import { NoReleaseForInvoiceInfo } from "./components/no-release-warning/NoReleaseForInvoiceInfo";
import { useInvoiceItemPrice } from "./hooks/useInvoiceItemPrice";
import { useInvoiceMatchedOrderHook } from "./hooks/useInvoiceMatchedOrder";

const Container = tw.div`bg-gray-100 rounded-3xl pt-4 h-full overflow-y-scroll`;
const InnerContainer = tw.div`flex flex-col`;
const NoResultsContainer = tw.div`px-5 text-sm`;

export const InvoiceMatchedOrder = () => {
  const { invoice } = useInvoiceVerification();
  const { isLoading, isDefaultMatchedOrderViewState } =
    useInvoiceMatchedOrderHook();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  useInvoiceOrderEdit();
  useInvoiceItemPrice();

  return (
    <Loader loading={isLoading}>
      <Container>
        <If isTrue={isDefaultMatchedOrderViewState}>
          <Loader loading={!invoice}>
            <InnerContainer className="-mt-6">
              <MissingBudgetCombinationsProvider>
                <InvoiceMatchedHeader />
              </MissingBudgetCombinationsProvider>
              {invoice?.release ? (
                <InvoiceReleaseItems />
              ) : !invoice?.hasRelease ? (
                <NoReleaseForInvoiceInfo />
              ) : (
                <NoResultsContainer>
                  <NoResults
                    translationKey="INVOICE_HAS_BEEN_ASSIGNED_TO_UNAUTHORIZED_PROJECT"
                    includeWarningIcon
                    rounded
                  />
                </NoResultsContainer>
              )}
            </InnerContainer>
          </Loader>
        </If>
        <If
          isTrue={matchedOrderViewState === MatchedOrderViewState.MATCH_ORDER}
        >
          <InnerContainer>
            <InvoiceOrderSelectorView />
          </InnerContainer>
        </If>
        <InnerContainer>
          <If
            isTrue={
              matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER ||
              matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
            }
          >
            <InvoiceCreateRelease />
          </If>
          <If
            isTrue={matchedOrderViewState === MatchedOrderViewState.EDIT_ORDER}
          >
            <InvoiceUpdateRelease />
          </If>
        </InnerContainer>
      </Container>
    </Loader>
  );
};
