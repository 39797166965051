import { LinkLike } from "@/common/components/link-like/LinkLike";
import { routes } from "@/config/routes";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";

type ContainerProps = {
  $multiQuote: boolean;
};

type Props = {
  buyoutId?: string;
  buyoutIdentifier?: string;
};

const BuyoutContainer = tw.div<ContainerProps>`
  flex justify-end pr-2 pt-2
  ${(props: ContainerProps) => (props.$multiQuote ? "mt-6" : "")};
`;

export const BuyoutName: FC<Props> = ({ buyoutId, buyoutIdentifier }) => {
  const { multiQuote } = useRfqQuotes();

  return (
    <BuyoutContainer $multiQuote={multiQuote}>
      <LinkLike to={generatePath(routes.buyout, { id: buyoutId })}>
        <FormattedMessage id="BUYOUT" values={{ number: buyoutIdentifier }} />
      </LinkLike>
    </BuyoutContainer>
  );
};
