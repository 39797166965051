import { Dialog } from "@/common/components/dialog/Dialog";
import { Loader } from "@/common/components/loader/Loader";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutStore } from "../../../store/useBuyoutStore";
import { useBuyoutProjectItemsWithUpdates } from "../providers/BuyoutProjectItemsProvider";
import { useBuyoutProjectListItemConfiguration } from "./BuyoutProjectListItemConfiguration";
import { ProjectItems } from "./ProjectItems";

interface AddEstimatedItemsDialogProps {
  setShowEstimatedItemsDialog: (value: boolean) => void;
}

export const AddEstimatedItemsDialog = ({
  setShowEstimatedItemsDialog,
}: AddEstimatedItemsDialogProps) => {
  const intl = useIntl();
  const { addToBuyout, isAdding, updates } = useBuyoutProjectItemsWithUpdates();
  const { buyout, loading } = useContractorBuyout();
  const { setSuccessAlert, setWarningAlert } = useSnackbar();
  const { buyout: storeBuyout, updateStoreItems } = useBuyoutStore(
    useShallow((state) => ({
      buyout: state.buyout,
      updateStoreItems: state.updateStoreItems,
    })),
  );

  const handleOnCancel = useCallback(() => {
    setShowEstimatedItemsDialog(false);
  }, [setShowEstimatedItemsDialog]);

  const handleAddToBuyout = useCallback(async () => {
    if (updates.length === 0) {
      setWarningAlert(<FormattedMessage id={`NOTHING_TO_ADD`} />);
      return;
    }
    const addedItems = await addToBuyout();
    if (addedItems) {
      setSuccessAlert(intl.$t({ id: "ITEMS_ADDED_TO_BUYOUT" }));
      setShowEstimatedItemsDialog(false);
      if (storeBuyout) {
        updateStoreItems(
          storeBuyout.items.concat(
            addedItems.map((item) => {
              return {
                id: item.id,
                unitPrice: item.unitPrice,
                quantityDecimal: item.quantityDecimal,
              } as BuyoutItemFieldsFragment;
            }),
          ),
        );
      }
    }
  }, [
    addToBuyout,
    intl,
    setShowEstimatedItemsDialog,
    setSuccessAlert,
    setWarningAlert,
    storeBuyout,
    updateStoreItems,
    updates.length,
  ]);

  const configuration = useBuyoutProjectListItemConfiguration({ buyout });

  if (!buyout || loading) {
    return <Loader loading />;
  }

  return (
    <Dialog
      title={<FormattedMessage id={"ADD_ITEMS_TO_BUYOUT_FROM_ESTIMATES"} />}
      confirmButtonText={intl.$t(
        {
          id:
            updates.length === 0
              ? "BUYOUT_ADD_ITEMS"
              : "ADD_TO_BUYOUT_WITH_COUNT",
        },
        { count: updates.length },
      )}
      handleConfirm={handleAddToBuyout}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
      }}
      extraButtonMargin={false}
      loading={isAdding}
      handleCancel={handleOnCancel}
      maxWidth={false}
      show={true}
      content={
        <ProjectItems
          buyout={buyout}
          columns={configuration}
          loading={loading}
        />
      }
    />
  );
};
