import {
  Breadcrumbs,
  DeletedContainer,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { QuestionBox } from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { InvoiceStatusChip } from "@/common/components/statuses/InvoiceStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { UserAvatar } from "@/common/components/user-avatar/UserAvatar";
import { ReleaseMessagesButton } from "@/contractor/pages/home/release/components/ReleaseMessagesButton";
import { MessageContextKind } from "@/generated/graphql";

import { FC, ReactElement, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../providers/InvoiceVerificationProvider";
import { ConnectInvoiceButton } from "../export/ConnectInvoiceButton";
import { ExportInvoicesButton } from "../export/ExportInvoiceButton";
import { InvoiceSequenceStepper } from "../sequence-stepper/InvoiceSequenceStepper";
import { InvoiceBreadcrumbAction } from "./enums/InvoiceBreadcrumbAction";
import { InvoiceBreadcrumbAdditionalItem } from "./enums/InvoiceBreadcrumbAppendedItem";
import { useInvoiceBreadcrumbsAppendedItems } from "./hooks/useInvoiceAppendedItems";
import { useInvoiceBreadcrumbs } from "./hooks/useInvoiceBreadcrumbs";
import { useInvoiceBreadcrumbsActions } from "./hooks/useInvoiceBreadcrumbsActions";

const Row = tw.div`flex flex-row items-center gap-2 text-sm text-gray-600`;

export const InvoiceBreadcrumbs: FC = () => {
  const { invoice } = useInvoiceVerification();
  const { actions } = useInvoiceBreadcrumbsActions();
  const { appendedItems } = useInvoiceBreadcrumbsAppendedItems();
  const { breadcrumbs } = useInvoiceBreadcrumbs();

  const breadcrumbsActions = useMemo(() => {
    if (!invoice) {
      return [];
    }
    return actions.flatMap((action) => {
      if (action === InvoiceBreadcrumbAction.ApprovedInfo) {
        return (
          <Row>
            <FormattedMessage id="APPROVED_BY" />:
            <UserAvatar
              {...invoice?.approvedBy}
              size={32}
              date={invoice.approvedAt}
            />
          </Row>
        );
      } else if (
        action === InvoiceBreadcrumbAction.Messages &&
        invoice?.release
      ) {
        return (
          <ReleaseMessagesButton
            key="messages"
            release={invoice.release}
            text={
              <QuestionBox>
                <Tooltip
                  id="invoice-messages"
                  element={
                    <MessageButton
                      id={invoice.release.id}
                      kind={MessageContextKind.Release}
                    />
                  }
                >
                  <FormattedMessage id="MESSENGER" />
                </Tooltip>
              </QuestionBox>
            }
          />
        );
      } else if (action === InvoiceBreadcrumbAction.ConnectInvoice) {
        return <ConnectInvoiceButton key="connect-invoice" invoice={invoice} />;
      } else if (action === InvoiceBreadcrumbAction.ExportInvoices) {
        return (
          <ExportInvoicesButton key="export-invoices" invoiceId={invoice.id} />
        );
      } else if (action === InvoiceBreadcrumbAction.SequenceStepper) {
        return <InvoiceSequenceStepper key="invoice-sequence-stepper" />;
      }
      return [];
    });
  }, [actions, invoice]);

  const breadcrumbAppendedItems = useMemo(() => {
    const items = [] as ReactElement[];
    appendedItems.forEach((item) => {
      if (item === InvoiceBreadcrumbAdditionalItem.InvoiceStatusChip) {
        items.push(
          <InvoiceStatusChip
            key="status"
            status={invoice?.status}
            type="breadcrumb"
          />,
        );
      } else if (item === InvoiceBreadcrumbAdditionalItem.Archive) {
        items.push(
          <FormattedMessage
            key="deleted"
            id="DELETED"
            tagName={DeletedContainer}
          />,
        );
      }
    });
    return items;
  }, [appendedItems, invoice?.status]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      appendItems={breadcrumbAppendedItems}
      actions={breadcrumbsActions}
    />
  );
};
