import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useSpreadsheetBuyoutItems } from "./hooks/useSpreadsheetBuyoutItems";

type ProviderContextType = {
  syncBuyoutItems: () => Promise<boolean>;
  saving: boolean;
};

type Props = {
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  syncBuyoutItems: NoFunctionBooleanPromise,
  saving: false,
});

export const SyncBuyoutItemsProvider: FC<Props> = ({ children }) => {
  const { resetPreviousData, gotoInvalidRow } = useColumnMapper();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { buyout, updateBuyout, updating, hasChanges } = useContractorBuyout();
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();
  const getSpreadsheetBuyoutItems = useSpreadsheetBuyoutItems();
  const { hasPhaseCodes } = useOrgSettings();

  const syncBuyoutItems = useCallback(async () => {
    if (
      !(await validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UOM,
      ])) ||
      !(await validateRowValues([
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.PrefilledPrice,
        COLUMN_TYPE.UOM,
        ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
      ]))
    ) {
      gotoInvalidRow();
      return false;
    }

    const { updates, addedItems, removedItems } =
      await getSpreadsheetBuyoutItems();

    if (
      updates.length > 0 ||
      addedItems.length > 0 ||
      removedItems.length > 0 ||
      hasChanges
    ) {
      const result = await updateBuyout(
        {
          buyoutId: buyout?.id || "",
          version: buyout?.version || 0,
          updates,
          addedItems,
          removedItems,
        },
        true,
      );

      if (result) {
        resetPreviousData();
        setSuccessAlert(intl.$t({ id: "BUYOUT_ITEMS_SAVED_SUCCESS" }));
      }
      return result;
    }
    return true;
  }, [
    buyout?.id,
    buyout?.version,
    getSpreadsheetBuyoutItems,
    gotoInvalidRow,
    hasPhaseCodes,
    intl,
    resetPreviousData,
    setSuccessAlert,
    validateRequiredValues,
    validateRowValues,
    updateBuyout,
    hasChanges,
  ]);

  return (
    <ProviderContext.Provider
      value={{
        syncBuyoutItems,
        saving: updating || false,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSyncBuyoutItems = (): ProviderContextType =>
  useContext(ProviderContext);
