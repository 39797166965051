import { useMemo } from "react";
import { isItemExpired } from "./utils/isItemExpired";

export const useExpirationPicker = (date: number | null | undefined) => {
  const expirationDate = useMemo(
    () => (date ? new Date(date) : new Date()),
    [date],
  );

  const isExpired = useMemo(
    () => isItemExpired(expirationDate),
    [expirationDate],
  );
  return { expirationDate, isExpired };
};
