import { useUserLocations } from "@/common/hooks/useUserLocations";
import { OrgLocationPermissions } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { SelectControlled } from "../select/components/single/SelectControlled";

type Props = {
  name?: string;
  permission?: keyof OrgLocationPermissions;
  placeholder?: string;
  testId?: string;
};

export const LocationSelectorControlled: FC<Props> = ({
  name = "locationId",
  permission,
  placeholder,
  testId,
}) => {
  const { locations } = useUserLocations({ permission });
  const intl = useIntl();

  return (
    <SelectControlled
      name={name}
      placeholder={placeholder || intl.$t({ id: "PROJECT_LOCATION" })}
      options={locations || []}
      rules={{ required: true }}
      getLabel={(option) => option.name}
      getValue={(option) => option.id}
      noOptionsText={intl.$t({ id: "NO_LOCATIONS_FOUND" })}
      testId={testId}
    />
  );
};
