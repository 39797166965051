import { useUser } from "@/common/providers/UserProvider";
import { useInvoiceIssues } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoiceIssues";
import { useInvoiceKickbacks } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoiceKickbacks";
import { useReceiptKickbacks } from "@/contractor/pages/home/receipts/pages/receipts/hooks/useReceiptKickbacks";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  InvoiceDocumentType,
  InvoiceIssueProps,
  InvoiceIssueType,
} from "./InvoiceIssue";
import { ResponseType } from "./InvoiceRespondForm";
import { useInvoiceIssueDialogs } from "./useInvoiceIssueDialogs";

export const useInvoiceIssue = ({
  type,
  documentType = InvoiceDocumentType.INVOICE,
  issueDescription,
  resolutionDescription,
  rejectionReason,
  issueResponseDate,
  userCanRespond,
  assignees,
  id,
  index,
}: InvoiceIssueProps) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(false);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [editedCommentId, setEditedCommentId] = useState<undefined | string>();
  const [responseType, setResponseType] = useState<ResponseType | null>(null);
  const {
    updateInvoiceKickback,
    loading: updatingInvoiceKickback,
    resolveInvoiceKickback,
    deleteInvoiceKickback,
  } = useInvoiceKickbacks();
  const {
    showUpdateDialog,
    showResolveDialog,
    showAcceptDialog,
    showDeclineDialog,
  } = useInvoiceIssueDialogs();

  const {
    updateReceiptKickback,
    loading: updatingReceiptKickback,
    resolveReceiptKickback,
    deleteReceiptKickback,
  } = useReceiptKickbacks();

  const kickbackActions = {
    [InvoiceDocumentType.RECEIPT]: {
      updateKickback: updateReceiptKickback,
      resolveKickback: resolveReceiptKickback,
      deleteKickback: deleteReceiptKickback,
      loading: updatingReceiptKickback,
    },
    [InvoiceDocumentType.INVOICE]: {
      updateKickback: updateInvoiceKickback,
      resolveKickback: resolveInvoiceKickback,
      deleteKickback: deleteInvoiceKickback,
      loading: updatingInvoiceKickback,
    },
  };

  const { updateKickback, resolveKickback, deleteKickback, loading } =
    kickbackActions[documentType];

  const {
    updateInvoiceIssue,
    loading: updatingIssue,
    deleteInvoiceIssue,
    resolveInvoiceIssue,
  } = useInvoiceIssues();
  const { viewer, isContractor } = useUser();
  const methods = useForm({
    defaultValues: {
      description: issueDescription || "",
      assigneeIds: assignees?.map((a) => a.id) || [],
      resolution: resolutionDescription || "",
      assetUrls: [],
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const isAssignee = useMemo(
    () => !!assignees?.find((a) => a.id === viewer?.id),
    [assignees, viewer],
  );

  const update = useCallback(async () => {
    if (type === InvoiceIssueType.KICKBACK) {
      await updateKickback({
        id,
        description: methods.getValues("description"),
        assigneeIds: [...new Set(methods.getValues("assigneeIds"))],
        assetUrls: methods.getValues("assetUrls"),
      });
    } else {
      await updateInvoiceIssue({
        id,
        description: methods.getValues("description"),
        assetUrls: methods.getValues("assetUrls"),
      });
      showUpdateDialog(index);
    }
    setEdit(false);
  }, [
    type,
    updateKickback,
    id,
    methods,
    updateInvoiceIssue,
    showUpdateDialog,
    index,
  ]);

  const deleteItem = useCallback(async () => {
    if (type === InvoiceIssueType.KICKBACK) {
      if (await deleteKickback(id)) {
        setEdit(false);
      }
    } else if (await deleteInvoiceIssue(id)) {
      setEdit(false);
    }
  }, [type, deleteKickback, id, deleteInvoiceIssue]);

  const resolve = useCallback(async () => {
    if (
      await resolveKickback({
        id,
        resolutionDescription: methods.getValues("resolution"),
        assetUrls: methods.getValues("assetUrls"),
      })
    ) {
      showResolveDialog(index);
      setResponseType(null);
    }
  }, [id, index, methods, resolveKickback, showResolveDialog]);

  const acceptIssue = useCallback(async () => {
    const description = methods.getValues("resolution");
    const assetUrls = methods.getValues("assetUrls");
    if (
      await resolveInvoiceIssue({
        id,
        resolutionDescription: description,
        issueAssetUrls: assetUrls,
      })
    ) {
      showAcceptDialog();
      setResponseType(null);
    }
  }, [id, methods, resolveInvoiceIssue, showAcceptDialog]);

  const declineIssue = useCallback(async () => {
    const description = methods.getValues("resolution");
    const assetUrls = methods.getValues("assetUrls");
    if (
      await resolveInvoiceIssue({
        id,
        rejectionReason: description,
        issueAssetUrls: assetUrls,
      })
    ) {
      showDeclineDialog();
      setResponseType(null);
    }
  }, [id, methods, resolveInvoiceIssue, showDeclineDialog]);

  const issueResolved = useMemo(() => {
    return Boolean(
      resolutionDescription || rejectionReason || issueResponseDate,
    );
  }, [resolutionDescription, rejectionReason, issueResponseDate]);

  const canResolve = useMemo(() => {
    return (isAssignee || userCanRespond) && !issueResolved && !responseType;
  }, [userCanRespond, issueResolved, responseType, isAssignee]);

  const handleAddComment = useCallback(() => {
    setIsAddingComment(true);
  }, []);

  const handleCancelAddingComment = useCallback(() => {
    setIsAddingComment(false);
  }, []);

  return {
    edit,
    setEdit,
    isAddingComment,
    editedCommentId,
    setEditedCommentId,
    responseType,
    setResponseType,
    isAssignee,
    expanded,
    setExpanded,
    methods,
    loading,
    updatingIssue,
    isContractor,
    update,
    deleteItem,
    resolve,
    acceptIssue,
    declineIssue,
    issueResolved,
    canResolve,
    handleAddComment,
    handleCancelAddingComment,
  };
};
