import { useSourceSystemConfig } from "@/common/hooks/integrations/source-system-config/useSourceSystemConfig";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { useMemo } from "react";
import { useCustomConfig } from "./custom-page-config/useCostomConfig";
import { useAgaveConnectionPage } from "./default-page-config/pages/useAgaveConnectionPage";
import { useAgaveFinalStep } from "./default-page-config/pages/useAgaveFinalStep";
import { useInvoiceExportSettingsPage } from "./default-page-config/pages/useInvoiceExportSettingsPage";
import { usePoExportSettingsPage } from "./default-page-config/pages/usePoExportSettingsPage";
import { useProjectsAutocloseSettingsPage } from "./default-page-config/pages/useProjectsAutocloseSettingsPage";
import { useTemplateStringsPage } from "./default-page-config/pages/useTemplateStringsPage";
import { useDefaultPageConfig } from "./default-page-config/useDefaultPageConfig";

export const usePagesConfig = () => {
  const agaveConnectionPage = useAgaveConnectionPage();
  const { sourceSystemPages } = useDefaultPageConfig();
  const { getCustomPages } = useCustomConfig();
  const finalStep = useAgaveFinalStep({
    hideHeader: sourceSystemPages.length === 1,
  });
  const poExportSettings = usePoExportSettingsPage();
  const invoiceExportSettings = useInvoiceExportSettingsPage();
  const templateStringsPage = useTemplateStringsPage();
  const projectsAutocloseSettings = useProjectsAutocloseSettingsPage();

  const { connectedSourceSystem } = useOrgSettingsExtended();
  const { sourceSystemConfig } = useSourceSystemConfig(
    connectedSourceSystem?.system,
  );

  const pages = useMemo(() => {
    if (!sourceSystemConfig) {
      return [];
    }
    if (!sourceSystemConfig?.customConfiguration) {
      const poExportSettingsPage =
        sourceSystemPages.length === 0
          ? {
              ...poExportSettings,
              pages: poExportSettings.pages.map((page) => ({
                ...page,
                hideHeader: true,
              })),
            }
          : poExportSettings;
      return [
        agaveConnectionPage,
        poExportSettingsPage,
        templateStringsPage,
        invoiceExportSettings,
        projectsAutocloseSettings,
        ...sourceSystemPages,
        finalStep,
      ];
    }

    return getCustomPages().pages;
  }, [
    sourceSystemConfig,
    getCustomPages,
    sourceSystemPages,
    poExportSettings,
    agaveConnectionPage,
    templateStringsPage,
    invoiceExportSettings,
    projectsAutocloseSettings,
    finalStep,
  ]);
  return { pages };
};
