import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Preview = tw.div`mt-2 text-sm`;
const PreviewTitle = tw.span`font-light text-gray-600`;
const PreviewContent = tw.span`ml-1 font-medium text-blue-800`;

type TemplatePreviewProps = {
  preview: string | null;
};

export const TemplatePreview: FC<TemplatePreviewProps> = ({ preview }) => {
  return (
    <Preview>
      <PreviewTitle>
        <FormattedMessage id="TEMPLATE_PREVIEW" />:
      </PreviewTitle>
      <PreviewContent>{preview}</PreviewContent>
    </Preview>
  );
};
