import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { If } from "@/common/components/if/If";
import {
  QuestionBox,
  QuestionText,
} from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { routes } from "@/config/routes";
import { VendorReleaseMessagesButton } from "@/distributor/common/release-messages/VendorReleaseMessagesButton";
import { MessageContextKind } from "@/generated/graphql";
import { ListAlt } from "@mui/icons-material";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useDistributorRelease } from "../../providers/DistributorReleaseProvider";
import { ReleaseInfoHeader } from "../ReleaseInfoHeader";
import { ReleasePrintButton } from "../ReleasePrintButton";

const Wrapper = tw.div`pr-2`;
const Headline = tw.div`flex items-center`;

export const ReleaseHeader: FC = () => {
  const { release } = useDistributorRelease();
  const intl = useIntl();
  const navigate = useNavigate();
  if (!release) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.$t(
            { id: "DELIVERY_WITH_NUMBER" },
            { number: release.sequenceNumber },
          )}
        </title>
      </Helmet>
      <Breadcrumbs
        hideRootChevronInMobile
        items={[
          ...(release.buyout
            ? [
                {
                  text: (
                    <Headline>
                      {intl.$t(
                        { id: "BUYOUT" },
                        { number: release.buyout.clientIdentifier },
                      )}
                    </Headline>
                  ),
                  link: generatePath(routes.distributorBuyout, {
                    id: release.buyout.id,
                  }),
                  keepSearchParams: true,
                  id: "buyouts",
                },
              ]
            : []),
          {
            id: "deliveryNo",
            text: (
              <Headline key="delivery-no">
                <Wrapper>
                  <FormattedMessage
                    id="DELIVERY_WITH_NUMBER"
                    values={{ number: release.sequenceNumber }}
                  />
                </Wrapper>
              </Headline>
            ),
          },
        ]}
        appendItems={[
          <ReleaseStatusChip
            key="status"
            status={release.status}
            type="breadcrumb"
            releaseType={release?.type}
          />,
        ]}
        actions={[
          <ReleasePrintButton release={release} key="print" />,

          <If key="orders-history" isTrue={release.buyout}>
            <QuestionBox
              onClick={() =>
                navigate(
                  generatePath(routes.distributorBuyoutDeliveries, {
                    id: release.buyout?.id,
                  }) + location.search,
                )
              }
            >
              <QuestionText>
                <FormattedMessage id="DISTRIBUTOR_BUYOUT_ORDERS_HISTORY" />
              </QuestionText>
              <ListAlt />
            </QuestionBox>
          </If>,
          <VendorReleaseMessagesButton
            key="messages"
            release={release}
            org={release.project?.location.org}
            text={
              <QuestionBox $fixedWidth>
                <QuestionText>
                  <FormattedMessage id="MESSAGE_CONTRACTOR_ABOUT_DELIVERY" />
                </QuestionText>
                <MessageButton
                  id={release.id}
                  kind={MessageContextKind.Release}
                />
              </QuestionBox>
            }
          />,
        ]}
      />
      <ReleaseInfoHeader release={release} key="release-info-header" />
    </>
  );
};
