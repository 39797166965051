import { If } from "@/common/components/if/If";
import { Select } from "@/common/components/select/components/single/Select";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { OrgLocationPermissions } from "@/generated/graphql";
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";

type Props = {
  locationId: string | null;
  setLocationId: (locationId: string | null) => void;
  placeholder?: string;
  permission?: keyof OrgLocationPermissions;
  staticText?: boolean;
};

export const LocationSelector: FC<Props> = ({
  locationId,
  setLocationId,
  placeholder,
  permission,
  staticText,
}) => {
  const { locations } = useUserLocations({ permission });
  const intl = useIntl();

  useEffect(() => {
    if (locations.length !== 0 && !locationId) {
      setLocationId(locations[0].id);
    }
  }, [locationId, locations, setLocationId]);

  return (
    <If isTrue={locations && locations.length !== 1}>
      <Select
        testId="form-locations-selector"
        placeholder={placeholder || intl.$t({ id: "PROJECT_LOCATION" })}
        options={locations || []}
        value={locationId}
        required
        onChange={setLocationId}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        noOptionsText={intl.$t({ id: "NO_LOCATIONS_FOUND" })}
        staticText={staticText}
      />
    </If>
  );
};
