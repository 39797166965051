import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NumericalInput } from "@/common/components/numerical-input/NumericalInput";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import { XlContainer } from "@/common/layout/ResponsiveClasses";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { TaxCodeFieldsFragment } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ExternalTaxCodeType } from "../../../hooks/useExternalTaxCodes";

const Row = tw.div`flex flex-row`;

export const useImportExternalTaxCodesTableConfiguration = (
  selectedItems?: string[],
): Array<GridCol<ExternalTaxCodeType | TaxCodeFieldsFragment>> => {
  return [
    {
      header: <FormattedMessage id="DESCRIPTION" />,
      wrapper: XlContainer,
      item: ({ item, index, count }) => (
        <SelectableItem
          item={item}
          index={index}
          selectedItems={selectedItems ?? []}
          itemNameSelectorFn={(item) => item.description || ""}
          count={count}
        />
      ),
      sortItemFn: (
        item1: ExternalTaxCodeType | TaxCodeFieldsFragment,
        item2: ExternalTaxCodeType | TaxCodeFieldsFragment,
      ) => (item1.description || "")?.localeCompare(item2.description || ""),
    },
    {
      header: <FormattedMessage id="CODE" />,
      wrapper: XlContainer,
      item: ({ item }) => <Row>{item.code}</Row>,
      sortItemFn: (item1, item2) => item1.code.localeCompare(item2.code),
    },
    {
      header: <FormattedMessage id="TAX_RATE" />,
      wrapper: XlContainer,
      item: ({ item }) => (
        <Row>
          <NumericalInput
            staticText
            suffix="%"
            value={
              "totalRate" in item
                ? new DecimalSafe(item.totalRate).mul(100).toNumber()
                : new DecimalSafe(item.rate).mul(100).toNumber()
            }
            centered
          />
        </Row>
      ),
      sortItemFn: (item1, item2) => item1.code.localeCompare(item2.code),
      position: "center",
    },
  ];
};
