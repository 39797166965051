import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { PhoneInputControlled } from "@/common/components/phone-input/PhoneInputControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { isDefaultCountry } from "@/common/utils/isDefaultCountry";
import { isValidCellPhone } from "@/common/utils/validationUtils";
import { routes } from "@/config/routes";
import { UpdateProfileInput } from "@/generated/graphql";
import { TextField } from "@mui/material";
import { useCallback, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import {
  ButtonsContainerStyled,
  Form,
  Item,
  Label,
  UserProfileHeader,
  Value,
} from "../common/UserProfile.styles";
import { ProfileImageWithUploader } from "./profile-image-uploader/ProfileImageWithUploader";

const PhoneInputStyled = tw(PhoneInputControlled)`w-full`;

export const UserAccount = () => {
  const { viewer, updateUser, loading } = useUser();
  const intl = useIntl();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();
  const methods = useForm<UpdateProfileInput>({
    defaultValues: {
      firstName: viewer?.firstName || "",
      lastName: viewer?.lastName || "",
      email: viewer?.email || "",
      cellPhone: viewer?.cellPhone || "",
    },
  });

  const save = useCallback(
    async (values: UpdateProfileInput) => {
      const { firstName, lastName, cellPhone } = values;
      const isPhoneUpdated = viewer?.cellPhone !== cellPhone;
      if (isPhoneUpdated && viewer?.getSmsNotifications) {
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "PROCEED" }),
          title: intl.$t({ id: "PHONE_UPDATE" }),
          text: intl.$t({ id: "PHONE_UPDATE_CONFIRM" }),
          handleConfirm: async () => {
            const result = await updateUser({
              firstName,
              lastName,
              cellPhone,
            });
            if (result) {
              setSuccessAlert(
                intl.$t({ id: "ACCOUNT_INFORMATION_UPDATE_SUCCESS" }),
              );
            }
          },
        });
      } else {
        const result = await updateUser({
          firstName,
          lastName,
          cellPhone,
        });
        if (result) {
          setSuccessAlert(
            intl.$t({ id: "PROFILE_INFORMATION_UPDATE_SUCCESS" }),
          );
          navigate(routes.projects);
        }
      }
    },
    [viewer, openDialog, updateUser, setSuccessAlert, intl, navigate],
  );

  const requiredStateAndCode = useMemo(() => {
    if (!viewer?.org.hqAddress?.country) {
      return false;
    }
    return isDefaultCountry(viewer?.org.hqAddress?.country);
  }, [viewer?.org.hqAddress?.country]);

  const isValidContactCellPhone = useCallback(
    (value: string | null) => {
      return !isValidCellPhone(value) && requiredStateAndCode
        ? intl.$t({ id: "PHONE_NUMBER_VALIDATION" })
        : true;
    },
    [intl, requiredStateAndCode],
  );

  if (!viewer) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Helmet>
        <title>{intl.$t({ id: "USER_YOUR_ACCOUNT" })}</title>
      </Helmet>
      <FormattedMessage id="USER_YOUR_ACCOUNT" tagName={UserProfileHeader} />
      <Form>
        <Item>
          <TextFieldControlled
            fullWidth
            size="small"
            label={intl.$t({ id: "USER_FIRST_NAME" })}
            rules={{ required: true }}
            name="firstName"
          />
        </Item>
        <Item>
          <TextFieldControlled
            fullWidth
            size="small"
            label={intl.$t({ id: "USER_LAST_NAME" })}
            rules={{ required: true }}
            name="lastName"
          />
        </Item>
        <Item>
          <TextFieldControlled
            name="email"
            fullWidth
            size="small"
            label={intl.$t({ id: "USER_EMAIL" })}
            rules={{ required: true }}
            disabled
          />
        </Item>
        <Item>
          <TextField
            size="small"
            fullWidth
            label={intl.$t({ id: "USER_PASSWORD" })}
            disabled
            value="********"
          />
        </Item>
        <Item>
          <PhoneInputStyled
            size="small"
            name="cellPhone"
            label={intl.$t({ id: "USER_CELL_PHONE" })}
            countryCode={viewer?.org.hqAddress?.country}
            rules={{ required: true, validate: isValidContactCellPhone }}
          />
        </Item>
        <Item>
          <Label>
            <FormattedMessage id="USER_PROFILE_PHOTO" />
          </Label>
          <Value>
            <ProfileImageWithUploader />
          </Value>
        </Item>
      </Form>
      <FloatingFooter>
        <ButtonsContainerStyled>
          <If isTrue={methods.formState.isDirty}>
            <OutlinedButton onClick={() => methods.reset()}>
              <FormattedMessage id="CANCEL" />
            </OutlinedButton>
          </If>
          <LoadingButton
            button={PrimaryButton}
            onClick={methods.handleSubmit(save)}
            loading={loading}
          >
            <FormattedMessage id="SAVE" />
          </LoadingButton>
        </ButtonsContainerStyled>
      </FloatingFooter>
    </FormProvider>
  );
};
