import { ExpandablePanel } from "@/common/components/expandable-panel/ExpandablePanel";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { routes } from "@/config/routes";
import { DistributorReleaseInvoiceFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { If } from "../../if/If";

const Container = tw.div`flex justify-start`;
const StyledImg = tw.img`mr-8 w-12`;

type Props = {
  invoice: DistributorReleaseInvoiceFieldsFragment | null | undefined;
};

export const InvoiceExpandableList: FC<Props> = ({ invoice }) => {
  const intl = useIntl();

  const invoiceDocument = useMemo(() => invoice?.documents[0], [invoice]);

  if (!invoice || !invoiceDocument) {
    return null;
  }
  return (
    <ExpandablePanel header={intl.$t({ id: "INVOICE" })} isOpened={true}>
      <Container>
        <If
          isTrue={
            invoiceDocument.asset.pages?.[invoiceDocument.pageRange.start]
          }
        >
          <StyledImg
            src={
              invoiceDocument.asset.pages?.[invoiceDocument.pageRange.start]
                ?.thumbnailUrl
            }
          />
        </If>
        <LinkLike
          keepSearchParams
          to={generatePath(routes.distributorInvoice, {
            invoiceId: invoice.id,
          })}
        >
          <FormattedMessage
            id="INVOICE_NUMBER"
            values={{ number: invoice.number }}
          />
        </LinkLike>
      </Container>
    </ExpandablePanel>
  );
};
