import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import { routes } from "@/config/routes";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router";
import { useInvoiceVerification } from "../../../../../invoice-verification/providers/InvoiceVerificationProvider";
import { useSplitInvoiceDocument } from "../../../../hooks/useSplitInvoiceDocument";
import { useInvoiceCreation } from "../../../../providers/InvoiceCreationProvider";
import { useInvoices } from "../../../../providers/InvoicesProvider";
import { useSplittingInvoicesWizard } from "../../../splitting-invoices/SplittingInvoicesWizardProvider";
import { InvoiceFormProps } from "../types/InvoiceFormProps";

export const useInvoiceForm = ({
  releaseId,
  onClose,
}: Pick<InvoiceFormProps, "releaseId" | "onClose">) => {
  const navigate = useNavigate();

  const { splitInvoiceDocument } = useSplitInvoiceDocument();
  const { createInvoices } = useInvoices();
  const { assets } = useUploadAssets();
  const { openWizard } = useSplittingInvoicesWizard();
  const { updateInvoice, invoice } = useInvoiceVerification();
  const { locationId } = useInvoiceCreation();

  const save = useCallback(async () => {
    if (!assets || !locationId) {
      return;
    }
    const pageRanges = await splitInvoiceDocument({
      assetUrl: assets[0].url,
    });
    if (!pageRanges) {
      return;
    }
    // automatically create invoices if there is only one page range
    if (pageRanges.length === 1 && pageRanges[0].start === 1) {
      const invoices = await createInvoices({
        assetUrl: assets[0].url,
        pageRanges,
        orgLocationId: locationId,
      });

      if (releaseId && invoices) {
        await Promise.all(
          invoices?.map((invoice) =>
            updateInvoice({
              id: invoice.id,
              releaseId: releaseId,
            }),
          ),
        );
      }

      if (invoices && invoices.length > 0) {
        const firstInvoice = invoices[0];
        navigate(
          generatePath(routes.invoiceVerification, {
            invoiceId: firstInvoice.id,
          }),
        );
      }
    } else {
      openWizard({
        initialPage: 0,
        urls: assets.map((asset) => asset.url),
        pageRanges,
        locationId,
      });
    }
    onClose();
  }, [
    assets,
    createInvoices,
    locationId,
    navigate,
    onClose,
    openWizard,
    releaseId,
    splitInvoiceDocument,
    updateInvoice,
  ]);

  const saveCorrection = useCallback(async () => {
    if (!!invoice && !!assets[0]) {
      await updateInvoice(
        {
          id: invoice?.id,
          assetUrl: assets[0].url,
        },
        { includeDocuments: true },
      );
      onClose();
    }
  }, [invoice, assets, updateInvoice, onClose]);

  return {
    save,
    saveCorrection,
  };
};
