import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import { routes } from "@/config/routes";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import { BuyoutStatus } from "@/generated/graphql";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { ButtonContainer, FooterContainer } from "../../../Buyout.styles";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutStore } from "../../../store/useBuyoutStore";
import { BackToAllBuyoutsButton } from "../../common/BackToAllBuyoutsButton";
import { BuyoutCancelButton } from "../../common/BuyoutCancelButton";
import { SubmitBuyoutDialog } from "../providers/submit-buyout-dialog/SubmitBuyoutDialog";
import { useSyncBuyoutItems } from "../providers/SyncBuyoutItemsProvider";
import { useBuyoutMutations } from "../providers/useBuyoutMutations";
import { BuyoutQuotedSubmitButton } from "./BuyoutQuotedSubmitButton";

const DeliveryWrapper = tw.div`flex flex-col items-end`;
const Item = tw.div`flex`;
const FooterContent = tw.div`flex items-center justify-end`;

type BuyoutActionsProps = {
  isNonQuoted: boolean;
  isEditable?: boolean;
};

export const BuyoutActions: FC<BuyoutActionsProps> = ({
  isNonQuoted,
  isEditable = false,
}) => {
  const { buyout, hasChanges } = useContractorBuyout();
  const { syncBuyoutItems, saving } = useSyncBuyoutItems();
  const { cancelBuyout, submitBuyout, submitting } = useBuyoutMutations();
  const { createReservedRelease, creating } = useCreateRelease();
  const navigate = useNavigate();
  const [updating, setUpdating] = useState(false);
  const { openDialog } = useDialog();
  const intl = useIntl();
  const [submitDialogVisible, setSubmitDialogVisible] = useState(false);
  const { buyout: storeBuyout, setStoreBuyout } = useBuyoutStore(
    useShallow((state) => ({
      buyout: state.buyout,
      setStoreBuyout: state.setStoreBuyout,
    })),
  );

  const readonly = useMemo(
    () =>
      buyout
        ? [BuyoutStatus.Depleted, BuyoutStatus.Cancelled].includes(
            buyout.status,
          )
        : false,
    [buyout],
  );

  const isSendToVendorEnabled = useMemo(() => {
    return storeBuyout && storeBuyout.items.length > 0;
  }, [storeBuyout]);

  const handleRequestDelivery = useCallback(async () => {
    if (buyout?.id) {
      const result = await createReservedRelease({
        buyoutId: buyout.id,
        typeId: buyout.releaseType.id,
      });
      if (result) {
        navigate(
          generatePath(routes.specifyDeliveryDetails, {
            deliveryId: result.id,
          }),
        );
      }
    }
  }, [buyout?.id, buyout?.releaseType.id, createReservedRelease, navigate]);

  const handleSubmitClick = useCallback(() => {
    setSubmitDialogVisible(true);
  }, []);

  const handleCancelBuyout = useCallback(async () => {
    if (!buyout?.id) {
      return;
    }
    await cancelBuyout({ buyoutId: buyout.id, version: buyout.version });
    navigate(routes.buyouts);
  }, [buyout?.id, buyout?.version, cancelBuyout, navigate]);

  const handleSubmitBuyout = useCallback(
    async (skipConfirmation?: boolean, skipVendorNotification?: boolean) => {
      if (!buyout) {
        return;
      }
      if (
        [BuyoutStatus.Requested, BuyoutStatus.Active].includes(buyout?.status)
      ) {
        const result = await syncBuyoutItems();
        if (!result) {
          return;
        }
      } else {
        const synced = await syncBuyoutItems();

        if (!synced) {
          setUpdating(false);
          return;
        }
        const result = await submitBuyout({
          buyoutId: buyout?.id,
          skipConfirmation,
          skipVendorNotification,
        });
        setUpdating(false);
        if (!result) {
          return;
        }
      }
      setSubmitDialogVisible(false);
      navigate(
        generatePath(routes.buyout, {
          id: buyout.id,
        }),
      );
    },
    [buyout, submitBuyout, syncBuyoutItems, navigate],
  );

  const handleCancelOrEditButtonClick = useCallback(() => {
    setStoreBuyout(buyout, true);

    navigate(
      generatePath(isEditable ? routes.buyout : routes.specifyBuyoutDetails, {
        id: buyout?.id,
      }),
    );
  }, [buyout, isEditable, navigate, setStoreBuyout]);

  const handleSubmitChanges = useCallback(() => {
    if (!buyout) {
      return;
    }
    handleSubmitBuyout();
  }, [buyout, handleSubmitBuyout]);

  const hasContacts = useMemo(() => {
    return (
      buyout?.preferredVendor?.contacts.filter(
        (contact) => contact.receivesBuyoutNotifications,
      ).length !== 0
    );
  }, [buyout?.preferredVendor?.contacts]);

  if (!buyout) {
    return null;
  }

  if (!isNonQuoted) {
    return (
      <FooterContainer>
        <If isTrue={!readonly}>
          <FloatingFooter>
            <ButtonContainer>
              <If isTrue={buyout.status === BuyoutStatus.Draft}>
                <BackToAllBuyoutsButton />
                <BuyoutQuotedSubmitButton buyout={buyout} />
              </If>
              <If isTrue={buyout.status === BuyoutStatus.Active}>
                <BuyoutCancelButton buyout={buyout} />
                <OutlinedButton
                  onClick={handleRequestDelivery}
                  testId="create-release-button"
                >
                  <FormattedMessage id="CREATE_DELIVERY" />
                </OutlinedButton>
              </If>
            </ButtonContainer>
          </FloatingFooter>
        </If>
      </FooterContainer>
    );
  }

  return (
    <DeliveryWrapper>
      <If isTrue={buyout.status !== BuyoutStatus.Depleted}>
        <FloatingFooter>
          <FooterContent>
            <Item>
              <ButtonsContainer>
                <If
                  isTrue={
                    buyout.status !== BuyoutStatus.Cancelled && !isEditable
                  }
                >
                  <OutlinedButton
                    onClick={() =>
                      openDialog({
                        cancelButtonText: intl.$t({ id: "CANCEL" }),
                        confirmButtonText: intl.$t({ id: "PROCEED" }),
                        includeWarningIcon: true,
                        title: intl.$t(
                          {
                            id: "CONFIRM_CANCEL_BUYOUT_WO_QUOTE_QUESTION",
                          },
                          { buyoutIdentifier: buyout.clientIdentifier },
                        ),
                        text: intl.$t({
                          id: checkBuyoutStatus(buyout, [
                            BuyoutStatus.Active,
                            BuyoutStatus.Requested,
                          ])
                            ? "CONFIRM_CANCEL_ACTIVE_BUYOUT_WO_QUOTE_QUESTION_TEXT"
                            : "CONFIRM_CANCEL_BUYOUT_WO_QUOTE_QUESTION_TEXT",
                        }),
                        handleConfirm: handleCancelBuyout,
                      })
                    }
                  >
                    <FormattedMessage id="CANCEL_RFQ_BUYOUT" />
                  </OutlinedButton>
                </If>
                <If isTrue={buyout.status === BuyoutStatus.Draft}>
                  <OutlinedButton
                    onClick={syncBuyoutItems}
                    disabled={!hasChanges || saving}
                  >
                    <FormattedMessage id="SAVE" />
                  </OutlinedButton>
                  <PrimaryButton
                    disabled={!isSendToVendorEnabled}
                    onClick={handleSubmitClick}
                    testId="submit-changes-or-specify-release-details"
                    loading={updating || saving}
                  >
                    <FormattedMessage id="SUBMIT" />
                  </PrimaryButton>
                </If>
                <If isTrue={buyout.status !== BuyoutStatus.Draft}>
                  <If isTrue={buyout.status !== BuyoutStatus.Cancelled}>
                    <OutlinedButton onClick={handleCancelOrEditButtonClick}>
                      <FormattedMessage
                        id={isEditable ? "CANCEL_CHANGES" : "EDIT_BUYOUT"}
                      />
                    </OutlinedButton>
                  </If>
                  <If isTrue={isEditable}>
                    <PrimaryButton
                      disabled={!isSendToVendorEnabled || !hasChanges}
                      onClick={handleSubmitChanges}
                      loading={updating || saving}
                    >
                      <FormattedMessage
                        id={
                          buyout.status === BuyoutStatus.Active
                            ? "UPDATE_BUYOUT"
                            : "BUYOUT_SUBMIT_CHANGES"
                        }
                      />
                    </PrimaryButton>
                  </If>
                </If>
                <If
                  isTrue={buyout.status === BuyoutStatus.Active && !isEditable}
                >
                  <OutlinedButton
                    onClick={handleRequestDelivery}
                    loading={creating}
                  >
                    <FormattedMessage id="CREATE_DELIVERY" />
                  </OutlinedButton>
                </If>
              </ButtonsContainer>
            </Item>
          </FooterContent>
        </FloatingFooter>
      </If>
      <SubmitBuyoutDialog
        visible={submitDialogVisible}
        setVisible={setSubmitDialogVisible}
        callback={handleSubmitBuyout}
        saving={submitting || updating || saving}
        status={buyout.status}
        hasContacts={hasContacts}
      />
    </DeliveryWrapper>
  );
};
