import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceItemCoverageWarning } from "./hooks/useInvoiceItemCoverageWarning";

const Container = tw.div`flex flex-col flex-1`;
const Title = tw.div`font-medium text-xs`;
const Text = tw.div`font-normal text-2xs`;
const Link = tw.a`cursor-pointer text-blue-500`;

export const InvoiceItemCoverageWarning = () => {
  const { addOrderMissingItems, invoiceItemsOrderItemsDiff } =
    useInvoiceItemCoverageWarning();

  return (
    <Container>
      <FormattedMessage
        id="INVOICE_COVERS_MORE_ITEMS_THAN_ORDER"
        values={{ itemDiff: invoiceItemsOrderItemsDiff }}
        tagName={Title}
      />
      <FormattedMessage
        id="INVOICE_COVERS_MORE_ITEMS_THAN_ORDER_DESCRIPTION"
        values={{
          sub: (...chunks) => (
            <Link onClick={addOrderMissingItems}>{chunks}</Link>
          ),
        }}
        tagName={Text}
      />
    </Container>
  );
};
