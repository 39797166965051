import { Tabs } from "@/common/components/tabs/Tabs";
import { routes } from "@/config/routes";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const InventoryManagementTabNavigation = () => {
  const intl = useIntl();

  const tabs = useMemo(
    () => [
      {
        label: intl.$t({ id: "CURRENT_STOCK" }),
        path: routes.currentStock,
      },
      {
        label: intl.$t({ id: "INVENTORY_LEDGER" }),
        path: routes.inventoryLedger,
      },
    ],
    [intl],
  );

  return <Tabs tabs={tabs} type="secondary" className="top-1 -mt-8 pt-8" />;
};
