import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceReleaseItemWholeSelectionCheckbox } from "./hooks/useInvoiceReleaseItemWholeSelectionCheckbox";

type Props = {
  items: ExpandedReleaseItem[];
};

const Container = tw.div`flex`;

export const InvoiceReleaseItemWholeSelectionCheckbox: FC<Props> = ({
  items,
}) => {
  const { checked, handleChange, hasSelectableItems } =
    useInvoiceReleaseItemWholeSelectionCheckbox(items);

  return (
    <If isTrue={hasSelectableItems}>
      <Container>
        <SelectionCheckbox checked={checked} setSelection={handleChange} />
      </Container>
    </If>
  );
};
