import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { routes } from "@/config/routes";
import { BuyoutsBuyoutFieldsFragment, BuyoutStatus } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { useCreateRelease } from "../../release/hooks/useCreateRelease";

type Props = {
  buyout?: BuyoutsBuyoutFieldsFragment;
};

export const RequestDeliveryButton: FC<Props> = ({ buyout }) => {
  const navigate = useNavigate();
  const { createReservedRelease, creating } = useCreateRelease();

  const handleRequestDelivery = useCallback(async () => {
    if (buyout?.id) {
      const result = await createReservedRelease({
        buyoutId: buyout.id,
        typeId: buyout.releaseType.id,
      });
      if (result) {
        navigate(
          generatePath(routes.specifyDeliveryDetails, {
            deliveryId: result.id,
          }),
        );
      }
    }
  }, [buyout?.id, buyout?.releaseType.id, createReservedRelease, navigate]);

  return (
    <If isTrue={buyout?.status === BuyoutStatus.Active}>
      <PrimaryButton onClick={handleRequestDelivery} loading={creating}>
        <FormattedMessage id="CREATE_DELIVERY" />
      </PrimaryButton>
    </If>
  );
};
