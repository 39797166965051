import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { DateView } from "@/common/utils/dates/DateView";
import { routes } from "@/config/routes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { LockOutlined } from "@mui/icons-material";
import { useCallback } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router";
import tw from "tailwind-styled-components";
import { useDeliverySlipImportExternalPO } from "../../../../hooks/useDeliverySlipImportExternalPO";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";

const Header = tw.div`flex flex-row justify-start items-center px-1 xl:px-3 mb-5`;
const RightPanel = tw.div`self-stretch border-r-2 border-dotted border-gray-300 pr-4 pl-2`;
const Row = tw.div`flex flex-row`;
const OrderDetails = tw(Row)`pl-4 gap-1 xl:gap-3 flex-1`;
const Details = tw.div`text-sm font-normal`;
const ListContent = tw.div`grid grid-flow-row justify-start items-start`;
const Title = tw.div`text-base font-medium`;
const DetailsGroup = tw.div`flex flex-row gap-1 text-xs`;
const ReceiveOrderRow = tw(Row)`mb-5 items-center pr-2`;
const ReleaseDetailsRow = tw(Row)`mb-1 items-center`;
const LinkLikeStyled = tw(LinkLike)`text-sm`;
const ClearButtonText = tw.div`text-xs font-normal text-blue-800`;
const Label = tw.div`font-medium`;
const Name = tw.div``;

export const DeliverySlipReceiveHeader = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { release } = useRelease();
  const { deliveryId } = useParams();
  const {
    deliverySlip,
    updateDeliverySlip,
    setPackingSlipReceiveViewState,
    setSelectedRelease,
  } = useDeliverySlipVerification();
  const { openModal } = useDeliverySlipImportExternalPO();

  const gotoOrder = useCallback(() => {
    navigate(
      generatePath(routes.delivery, {
        deliveryId: release?.id,
      }),
    );
  }, [release?.id, navigate]);

  const gotoExternalPO = useCallback(() => {
    openModal({ skipPoList: true });
  }, [openModal]);

  const clearDeliverySlipRelease = useCallback(async () => {
    if (
      deliverySlip &&
      (await updateDeliverySlip({
        id: deliverySlip.id,
        clearRelease: true,
      }))
    ) {
      setSelectedRelease(null);
      setPackingSlipReceiveViewState(PackingSlipReceiveViewState.DEFAULT);
      navigate(
        generatePath(routes.deliverySlipMatchOrder, {
          deliverySlipId: deliverySlip?.id,
        }),
      );
    }
  }, [
    deliverySlip,
    navigate,
    setPackingSlipReceiveViewState,
    setSelectedRelease,
    updateDeliverySlip,
  ]);

  return (
    <Header>
      <RightPanel>
        <ReceiveOrderRow>
          <FormattedMessage
            id="RECEIVE_ORDER_NUMBER"
            values={{
              orderNumber: release?.sequenceNumber
                ? release?.sequenceNumber
                : "",
            }}
            tagName={Title}
          />
        </ReceiveOrderRow>
        <If isTrue={!deliveryId}>
          <ReleaseDetailsRow>
            <LinkLikeStyled onClick={gotoOrder}>
              <FormattedMessage
                id="DELIVERY_WITH_NUMBER"
                values={{ number: release?.sequenceNumber }}
              />
            </LinkLikeStyled>
            <ReleaseStatusChip
              status={release?.status}
              type="small"
              releaseType={release?.type}
            />
            <If isTrue={!!deliverySlip?.release}>
              <OutlinedButton
                $small
                className="ml-2 h-6 min-w-16"
                onClick={clearDeliverySlipRelease}
              >
                <FormattedMessage id="CLEAR" tagName={ClearButtonText} />
              </OutlinedButton>
            </If>
          </ReleaseDetailsRow>
        </If>
        <DetailsGroup>
          <Label>
            <FormattedMessage id="DELIVERY" />:
          </Label>
          <Name>
            <DateView date={release?.time} />
          </Name>
        </DetailsGroup>
        <DetailsGroup>
          <Label>
            <FormattedMessage id="ORDER_TYPE" />:
          </Label>
          <Name>{release?.type?.name}</Name>
        </DetailsGroup>
        <PoNumberingSettingsCheck>
          <LinkLikeStyled
            onClick={gotoExternalPO}
            disabled={!release?.poLink}
            className={!release?.poLink ? "font-medium text-gray-600" : ""}
          >
            <If isTrue={release?.poLink?.immutable}>
              <Tooltip
                id="release-immutable-tooltip"
                element={<LockOutlined className="text-xl" />}
              >
                <FormattedMessage
                  id="ORDER_IMMUTABLE_DESCRIPTION"
                  values={{
                    sourceSystem:
                      release?.poLink?.sourceSystem ??
                      intl.$t({ id: "SOURCE_SYSTEM" }),
                  }}
                />
              </Tooltip>
            </If>
            <FormattedMessage id="PO_HASH" />
            {release?.poNumber}
          </LinkLikeStyled>
        </PoNumberingSettingsCheck>
      </RightPanel>
      <If isTrue={release}>
        <OrderDetails>
          <OrgDetailsHeaderItem
            title={<FormattedMessage id="VENDOR" />}
            name={<VendorList contacts={release?.vendorContacts} />}
            orgName={release?.sellerOrgLocation?.org.name}
            logoImageUrl={release?.sellerOrgLocation?.org.photoUrl}
            address={release?.sellerOrgLocation?.address}
          />
          <OrgDetailsHeaderItem
            title={<FormattedMessage id="PROJECT" />}
            name={release?.project?.name}
            address={release?.project?.address}
            navTo={generatePath(routes.projectDetails, {
              id: release?.project?.id,
            })}
            warehouseId={release?.warehouse?.id}
          />
          <InfoHeaderItem title={<FormattedMessage id="FULFILLMENT_DATE" />}>
            <ListContent>
              <Details>
                <FormattedDate
                  value={release?.time ?? ""}
                  day="numeric"
                  month="short"
                  year="numeric"
                />
              </Details>
            </ListContent>
          </InfoHeaderItem>
        </OrderDetails>
      </If>
    </Header>
  );
};
