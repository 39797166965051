import { Address } from "@/common/components/address/Address";
import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { getUserName } from "@/common/utils/users/getUserName";
import { routes } from "@/config/routes";
import { DistributorBuyoutReleasesFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";

type DistributorReleasesHeaderInfoProps = {
  buyout: DistributorBuyoutReleasesFieldsFragment;
};

const BuyoutLink = tw.div`text-xs`;
const Details = tw.div`
  text-sm font-normal
`;
const Label = tw.div`inline text-sm font-medium`;
const Container = tw.div`flex gap-1`;
const ListContent = tw.div`
  grid grid-flow-row justify-start items-start
`;
const Name = tw.div`text-sm`;
const InfoHeaderStyled = tw(InfoHeader)`justify-start gap-44`;

export const DistributorReleasesHeaderInfo: FC<
  DistributorReleasesHeaderInfoProps
> = ({ buyout }) => {
  const intl = useIntl();
  return (
    <InfoHeaderStyled>
      <InfoHeaderItem title={<FormattedMessage id="CONTRACTOR" />}>
        <OrgLogo
          logoImageUrl={buyout.createdBy.org.photoUrl}
          name={buyout.createdBy.org.name}
          width={50}
        />
        <ListContent>
          <Name>{getUserName(buyout.createdBy)}</Name>
          <Details>{buyout.createdBy.org.name}</Details>
          <Details>
            <FormattedMessage id="PO_HASH" />:{" "}
            {buyout.poNumber || intl.$t({ id: "NOT_SPECIFIED" })}
          </Details>
        </ListContent>
      </InfoHeaderItem>

      <InfoHeaderItem
        title={
          <LinkLike
            to={
              generatePath(routes.distributorBuyout, {
                id: buyout.id,
              }) + location.search
            }
          >
            <FormattedMessage
              id="BUYOUT"
              tagName={BuyoutLink}
              values={{ number: buyout.clientIdentifier }}
            />
          </LinkLike>
        }
      >
        <ListContent>
          <Container>
            <Label>
              <FormattedMessage id="PROJECT" />:
            </Label>
            <Details>{buyout.project.name}</Details>
          </Container>
          <Container>
            <Label>
              <FormattedMessage id="LOCATION" />:
            </Label>
            <Details>{addressToString(buyout.project.address)}</Details>
          </Container>
        </ListContent>
      </InfoHeaderItem>
      <InfoHeaderItem title={<FormattedMessage id="VENDOR" />}>
        <OrgLogo
          logoImageUrl={buyout.sellerOrgLocation.org.photoUrl}
          name={buyout.sellerOrgLocation.org.name}
          width={50}
        />
        <ListContent>
          <Name>
            <VendorList
              contacts={buyout.preferredVendor?.contacts.filter(
                (c) => c.receivesOrderNotifications,
              )}
            />
          </Name>
          <Details>
            <Address address={buyout.sellerOrgLocation.address} />
          </Details>
        </ListContent>
      </InfoHeaderItem>
    </InfoHeaderStyled>
  );
};
