import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { Loader } from "@/common/components/loader/Loader";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { isNonQuotedBuyout } from "@/common/utils/isNonQuotedBuyout";
import { routes } from "@/config/routes";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { BuyoutFieldsFragment, BuyoutStatus } from "@/generated/graphql";
import { FC, useEffect, useMemo } from "react";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useSetCurrentProjectId } from "../../../project/hooks/useSetCurrentProjectId";
import { useContractorBuyout } from "../../providers/ContractorBuyoutProvider";
import { BuyoutStandaloneReleaseItemsList } from "../common/buyout-standalone-release-items-list/BuyoutStandaloneReleaseItemsList";
import { VendorBuyoutInlineNotesAndTermsPanel } from "../common/notes-and-attachments/VendorBuyoutInlineNotesAndTermsPanel";
import { BuyoutActions } from "./components/BuyoutActions";
import { BuyoutDescription } from "./components/BuyoutDescription";
import { BuyoutList } from "./components/BuyoutList";
import { BuyoutHeader } from "./header/BuyoutHeader";
import { BuyoutGroupedProvider } from "./providers/BuyoutGroupedProvider";

const Container = tw.div`pb-15`;

const BuyoutDocumentWithProviders: FC<{
  buyout: BuyoutFieldsFragment;
  loading: boolean;
}> = ({ buyout, loading }) => {
  const { setGlobalVendorId } = useVendorPrices();

  const isNonQuoted = useMemo(() => isNonQuotedBuyout(buyout), [buyout]);

  useEffect(() => {
    if (setGlobalVendorId) {
      setGlobalVendorId(buyout?.sellerOrgLocation?.id);
    }
  }, [buyout?.sellerOrgLocation?.id, setGlobalVendorId]);

  return (
    <Container>
      <BuyoutHeader buyout={buyout} readonly isNonQuoted={isNonQuoted} />
      <BuyoutList
        buyoutItems={buyout.items}
        loading={loading}
        totalCount={buyout.items.length}
        isNonQuoted={isNonQuoted}
      />
      <BuyoutStandaloneReleaseItemsList />
      <If isTrue={isNonQuoted}>
        <Instructions
          instruction={buyout.instructions}
          projectId={buyout.project?.id}
          readonly
        >
          <BuyoutDescription buyout={buyout} readonly />
        </Instructions>
      </If>
      <VendorBuyoutInlineNotesAndTermsPanel readonly />
      <If isTrue={!!buyout.quote}>
        <QuoteDocumentPanel
          quoteDocument={buyout.quoteDocument}
          readonly
          quote={buyout.quote}
        />
      </If>
      <BuyoutActions isNonQuoted={isNonQuoted} />
    </Container>
  );
};

export const BuyoutDocument = () => {
  const { buyout, loading } = useContractorBuyout();
  const navigate = useNavigate();
  useSetCurrentProjectId(buyout?.project?.id);

  useEffect(() => {
    if (buyout?.status === BuyoutStatus.Draft) {
      if (buyout.quoteDocument?.id) {
        navigate(
          generatePath(routes.editBuyoutFromQuote, {
            quoteDocumentId: buyout.quoteDocument.id,
            buyoutId: buyout.id,
          }),
          { replace: true },
        );
        return;
      }
      navigate(generatePath(routes.specifyBuyoutDetails, { id: buyout.id }), {
        replace: true,
      });
    }
  }, [buyout, navigate]);

  if (!buyout) {
    return <Loader loading />;
  }

  return (
    <BuyoutGroupedProvider buyout={buyout}>
      <BuyoutDocumentWithProviders buyout={buyout} loading={loading} />
    </BuyoutGroupedProvider>
  );
};
