import { DeleteButton } from "@/common/components/button/DeleteButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorRelease } from "../providers/DistributorReleaseProvider";

const DeleteContainer = tw.div`w-6`;

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  readonly?: boolean;
};

export const DistributorReleaseItemActions: FC<Props> = ({
  item,
  readonly,
}: Props) => {
  const { release, updateVendorRelease } = useDistributorRelease();
  const [isRemoving, setIsRemoving] = useState(false);
  const { openDialog } = useDialog();
  const intl = useIntl();

  const remove = useCallback(async () => {
    if (!item) {
      return;
    }
    setIsRemoving(true);
    const parentItem = release?.items.find(
      (relItem) => item.name === relItem.name && item?.id !== relItem?.id,
    );
    if (!parentItem) {
      return;
    }
    await updateVendorRelease({
      removedItems: [item.id],
      updates: [
        {
          releaseItemId: parentItem.id,
          quantityDecimal: new DecimalSafe(item.quantityDecimal)
            .plus(Number(parentItem?.quantityDecimal))
            .toString(),
        },
      ],
    });
    setIsRemoving(false);
  }, [item, release, updateVendorRelease]);

  const openConfirmationDialog = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "REMOVE" }),
      includeWarningIcon: true,
      title: intl.$t({ id: "WARNING_REMOVING_ITEM" }),
      text: intl.$t(
        { id: "WARNING_REMOVING_ITEM_MESSAGE" },
        {
          itemName: <b>{item.name}</b>,
          br: <br />,
        },
      ),
      handleConfirm: remove,
    });
  }, [openDialog, remove, item, intl]);

  return (
    <DeleteContainer key={1}>
      <If isTrue={item.addedByVendor}>
        <DeleteButton
          onClick={openConfirmationDialog}
          disabled={readonly}
          loading={isRemoving}
        />
      </If>
    </DeleteContainer>
  );
};
