import { Address } from "@/common/components/address/Address";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Loader } from "@/common/components/loader/Loader";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { useRoles } from "@/common/components/org-roles-wrapper/hasRoles";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { routes } from "@/config/routes";
import { StyledTextField } from "@/contractor/pages/admin/integrations/components/wizard/Wizard.styles";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  AuthorizationStatus,
  OrgRole,
  ReleaseStatus,
  ReleaseSummaryFieldsFragment,
} from "@/generated/graphql";
import {
  ArrowForward,
  Check,
  CheckCircle,
  ErrorOutlineOutlined,
  Refresh,
} from "@mui/icons-material";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { useDefaultReleasePo } from "../../hooks/useDefaultReleasePo";
import { useUnlinkPo } from "../../hooks/useUnlinkPo";
import { AdminUsersInfo } from "./AdminUsersInfo";
import {
  Error,
  ErrorContainer,
  ErrorIcon,
  ErrorMessage,
} from "./ConnectionOptions.styles";
import {
  ConnectionError,
  ExportErrorList,
  ValidationType,
} from "./ExportErrorList";
const ProjectAndVendorContainer = tw.div`grid grid-cols-[1.5fr_2fr] flex-row gap-5`;
const ProjectContainer = tw.div`flex flex-col justify-start border-r border-gray-400 border-dashed pr-2`;
const ProjectNumber = tw.div`flex flex-row gap-1 items-center`;
const ProjectName = tw.div``;
const VendorContainer = tw.div`flex flex-row gap-2`;
const FulfillmentContainer = tw.div`flex flex-row`;
const CheckCircleStyled = tw(CheckCircle)`text-green-800`;
const CheckStyled = tw(Check)`text-blue-800`;
const Details = tw.div`flex flex-col`;
const AdminUsersInfoStyled = tw(AdminUsersInfo)`text-xs font-normal`;
const Container = tw.div`flex flex-col gap-3 text-sm`;
const CardStyled = tw.div<{
  $hasError: boolean;
  $hasMissingPO: boolean;
}>`rounded-3xl p-4 shadow-md
    ${({ $hasError }) => $hasError && "border border-red-500 bg-gray-100"}
    ${({ $hasMissingPO }) => $hasMissingPO && "bg-gray-100"}
`;
const IconContainer = tw.div`border border-dashed border-gray-500 rounded-full`;
const Arrow = tw(ArrowForward)`transition-all duration-500 animate-slide`;
const Refreshing = tw(Refresh)`transition-all duration-500 animate-spin`;

type Props = PropsWithChildren<{
  release: ReleaseSummaryFieldsFragment;
  poNumber: string;
  updatePoNumber: (poNumber: string) => void;
  includedValidations: ConnectionError[];
  editablePoNumber?: boolean;
  linking?: boolean;
  loading?: boolean;
  error?: string;
}>;

export const PoReleaseDetails: FC<Props> = ({
  release,
  poNumber,
  updatePoNumber,
  includedValidations,
  editablePoNumber = true,
  linking,
  loading,
  error,
  children,
}) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { hasRoles: isAdmin } = useRoles({ roles: [OrgRole.OrgAdmin] });
  const { unlinkPo } = useUnlinkPo();
  const { connectedSourceSystem } = useOrgSettings();
  const { getDefaultReleasePo } = useDefaultReleasePo();

  const releaseDate = useMemo(() => {
    return release?.status !== ReleaseStatus.Draft &&
      release?.status !== ReleaseStatus.Requested &&
      release?.time
      ? release?.time
      : release?.requestedTime;
  }, [release]);

  const defaultReleasePo = useMemo(() => {
    const defaultReleasePo = getDefaultReleasePo(release);
    return defaultReleasePo
      ? `${defaultReleasePo} - ${intl.$t({ id: "AUTOGENERATED_PO" })}`
      : "";
  }, [getDefaultReleasePo, intl, release]);

  const generateReleasePath = useCallback(
    () =>
      generatePath(
        checkReleaseStatus(release, [
          ReleaseStatus.Draft,
          ReleaseStatus.AwaitingApproval,
          ReleaseStatus.Rejected,
        ]) && release.permissions.submit === AuthorizationStatus.Authorized
          ? routes.specifyDeliveryDetails
          : routes.delivery,
        { deliveryId: release.id },
      ),
    [release],
  );

  const errorsAndWarnings = useMemo(
    () => includedValidations.filter((v) => v.condition),
    [includedValidations],
  );

  const errors = useMemo(
    () =>
      includedValidations.filter(
        (v) => v.condition && v.validationType !== ValidationType.Warning,
      ),
    [includedValidations],
  );

  const onPoChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (!release.poLink) {
        updatePoNumber(value);
      } else {
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "DISCONNECT_PO" }),
          includeWarningIcon: true,
          closeOnConfirm: true,
          title: intl.$t({ id: "CHANGE_PO_NUMBER" }),
          text: intl.$t(
            { id: "DISCONNECT_PO_CHANGE_TEXT" },
            {
              integration: intl.$t({
                id: `INTEGRATION_${connectedSourceSystem}`,
              }),
            },
          ),
          handleConfirm: async () => {
            await unlinkPo(release.poLink?.id || "", release.id);
            updatePoNumber(value);
          },
        });
      }
    },
    [
      release.poLink,
      release.id,
      updatePoNumber,
      openDialog,
      intl,
      connectedSourceSystem,
      unlinkPo,
    ],
  );

  if (linking && errors.length > 0) {
    return null;
  }

  return (
    <CardStyled $hasError={errors.length > 0} $hasMissingPO={!poNumber}>
      <Container>
        <If isTrue={errors.length}>
          <ErrorContainer>
            <ErrorIcon />
            <ErrorMessage>
              <FormattedMessage
                id={
                  isAdmin
                    ? "EXCLUDED_ITEMS_FROM_EXPORT_NO_CONTACT_ADMIN"
                    : "EXCLUDED_ITEMS_FROM_EXPORT_CONTACT_ADMIN"
                }
                values={{
                  sub: () => <AdminUsersInfoStyled />,
                }}
              />
            </ErrorMessage>
          </ErrorContainer>
        </If>
        <ProjectAndVendorContainer>
          <ProjectContainer>
            <ProjectNumber>
              {loading ? (
                <Loader loading small className="w-5" />
              ) : linking ? (
                release.poLink && !release.poLink.autoSync ? (
                  <IconContainer>
                    <Refreshing />
                  </IconContainer>
                ) : (
                  <IconContainer>
                    <Arrow />
                  </IconContainer>
                )
              ) : (
                <If isTrue={!errors.length}>
                  {release.poLink?.syncedAt ? (
                    <CheckCircleStyled />
                  ) : (
                    <CheckStyled />
                  )}
                </If>
              )}

              <LinkLike to={generateReleasePath()} target="_blank">
                <FormattedMessage
                  id="RELEASE_NAME"
                  values={{ number: release?.sequenceNumber }}
                />
              </LinkLike>
            </ProjectNumber>
            <ProjectName>{release.project?.name}</ProjectName>
          </ProjectContainer>
          <VendorContainer>
            <OrgLogo
              logoImageUrl={release.sellerOrgLocation?.org.photoUrl}
              name={release.sellerOrgLocation?.org.name || ""}
              width={40}
            />
            <Details>
              {release.sellerOrgLocation?.org.name || ""}
              <Address address={release.sellerOrgLocation?.address} />
            </Details>
          </VendorContainer>
        </ProjectAndVendorContainer>
        <If isTrue={error}>
          <Error>
            <ErrorOutlineOutlined />
            {error}
          </Error>
        </If>
        <FulfillmentContainer>
          <FormattedMessage id="FULFILLMENT" tagName="div" />:
          <DateView date={releaseDate} options={SHORT_DATE_OPTION} />
        </FulfillmentContainer>
        <PoNumberingSettingsCheck>
          <StyledTextField
            label={intl.$t({ id: "PO_NUMBER" })}
            className="mb-auto"
            value={poNumber}
            placeholder={defaultReleasePo}
            onChange={onPoChange}
            size="small"
            required
            shrink
            disabled={!editablePoNumber || linking}
          />
        </PoNumberingSettingsCheck>
        {children}
        <If isTrue={!loading}>
          <ExportErrorList includedValidations={errorsAndWarnings} />
        </If>
      </Container>
    </CardStyled>
  );
};
