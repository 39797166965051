import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useSequence } from "@/common/hooks/useSequence";
import { routes } from "@/config/routes";
import { RfqsRfqFieldsFragment } from "@/generated/graphql";
import { generatePath } from "react-router";
import { useRfqSequenceStore } from "./useRfqSequenceStore";

export const useRfqSequence = () => {
  return useSequence<RfqsRfqFieldsFragment>({
    listPath: routes.quotes,
    localStorageConfig: LOCAL_STORAGE_KEYS.RFQ_SEQUENCES,
    singEntityPath: (id: string) => generatePath(routes.rfqById, { id }),
    useStore: useRfqSequenceStore,
  });
};
