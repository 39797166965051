import { If } from "@/common/components/if/If";
import { ProjectFieldsFragment, User } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Tooltip } from "../tooltip/Tooltip";
import { useProjectNameListDetails } from "./useProjectNameListDetails";

const ProjectWrapper = tw.div`col-span-2`;
const ProjectName = tw.div`text-sm font-medium`;
const JobNumber = tw.div`text-xs text-gray-600`;

export type ProjectType = Pick<ProjectFieldsFragment, "name" | "jobNumber"> & {
  team?: Array<Pick<User, "id" | "firstName" | "lastName">> | null;
};

type Props = {
  project: ProjectType | null | undefined;
  classes?: {
    projectName?: string;
  };
  withTeamInfoTooltip?: boolean;
};

export const ProjectNameListDetails: FC<Props> = ({
  project,
  classes,
  withTeamInfoTooltip = false,
}) => {
  const { projectMembers } = useProjectNameListDetails(project);

  return (
    <Tooltip
      id="project-team"
      hideTooltip={!withTeamInfoTooltip || !projectMembers}
      element={
        <ProjectWrapper>
          <ProjectName className={classes?.projectName}>
            {project?.name}
          </ProjectName>
          <If isTrue={project?.jobNumber}>
            <JobNumber>
              <FormattedMessage
                id="JOB_NUMBER_WITH_NO"
                values={{
                  jobNumber: project?.jobNumber,
                }}
              />
            </JobNumber>
          </If>
        </ProjectWrapper>
      }
    >
      <FormattedMessage id="PROJECT_MEMBERS_LIST" values={{ projectMembers }} />
    </Tooltip>
  );
};
