import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { AuthorizationStatus, InvoiceStatus } from "@/generated/graphql";
import { useMemo } from "react";
import { useInvoiceSequence } from "../../../../scanned-invoices/providers/InvoiceSequenceProvider";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";
import { InvoiceBreadcrumbAction } from "../enums/InvoiceBreadcrumbAction";

export const useInvoiceBreadcrumbsActions = () => {
  const { invoice } = useInvoiceVerification();
  const { sequenceActive } = useInvoiceSequence();
  const {
    hasFeatureInConnectedIntegrationType,
    hasFeatureInConnectedSourceSystem,
  } = useIntegrationFeatureRequirement();

  const actions = useMemo(() => {
    const items = [];

    if (invoice?.approvedAt || invoice?.approvedBy) {
      items.push(InvoiceBreadcrumbAction.ApprovedInfo);
    }
    if (invoice?.release && invoice?.release?.sellerOrgLocation?.org) {
      items.push(InvoiceBreadcrumbAction.Messages);
    }
    if (
      (invoice?.status === InvoiceStatus.Approved ||
        invoice?.status === InvoiceStatus.Paid) &&
      invoice.permissions.export === AuthorizationStatus.Authorized &&
      invoice.release
    ) {
      if (
        hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceWrites) ||
        hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceFileExports)
      ) {
        items.push(InvoiceBreadcrumbAction.ConnectInvoice);
      } else if (
        hasFeatureInConnectedIntegrationType(
          IntegrationFeature.InvoiceFileExports,
        )
      ) {
        items.push(InvoiceBreadcrumbAction.ExportInvoices);
      }
    }
    if (sequenceActive) {
      items.push(InvoiceBreadcrumbAction.SequenceStepper);
    }

    return items;
  }, [
    hasFeatureInConnectedIntegrationType,
    hasFeatureInConnectedSourceSystem,
    invoice,
    sequenceActive,
  ]);

  return {
    actions,
  };
};
