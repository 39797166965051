import { If } from "@/common/components/if/If";
import { routes } from "@/config/routes";
import {
  Notification,
  NotificationMessageContextFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { DetailsContainer, MessageBody } from "../common/Notification.styles";
import { BaseNotification, Sender } from "./BaseNotification";

type MessageNotificationProps = {
  notification: Notification;
};

export const MessageNotification: FC<MessageNotificationProps> = ({
  notification,
}) => {
  const context =
    notification.context as NotificationMessageContextFieldsFragment;
  const navigate = useNavigate();
  const onCallback = () => {
    const rfqId = context.message.quote?.rfq.id;
    const quoteId = context.message.quote?.id;
    const buyoutId = context.message.buyout?.id;
    const releaseId = context.message.release?.id;
    if (buyoutId) {
      navigate(
        generatePath(routes.buyoutMessages, {
          id: buyoutId,
        }),
      );
    } else if (releaseId) {
      navigate(
        generatePath(routes.deliveryMessages, {
          deliveryId: releaseId,
        }),
      );
    } else {
      navigate(
        generatePath(routes.bidMessaging, {
          id: rfqId,
          bidId: quoteId,
        }),
      );
    }
  };

  return (
    <BaseNotification notification={notification} onCallback={onCallback}>
      <DetailsContainer>
        <Sender>
          {`${context.message.author.firstName} ${context.message.author.lastName}`}
        </Sender>
        <MessageBody>
          <If isTrue={context.message.quote}>
            <FormattedMessage
              id="NOTIFICATION_MESSAGE_SENT_QUOTE"
              values={{
                clientIdentifier: context.message.quote?.rfq?.clientIdentifier,
                projectName: context.message.quote?.rfq.project?.name,
                vendorOrgName:
                  context.message.quote?.sellerOrgLocation.org.name,
              }}
            />
          </If>
          <If isTrue={context.message.buyout}>
            <FormattedMessage
              id="NOTIFICATION_MESSAGE_SENT_BUYOUT"
              values={{
                clientIdentifier: context.message.buyout?.clientIdentifier,
                vendorOrgName:
                  context.message.buyout?.sellerOrgLocation.org.name,
                projectName: context.message.buyout?.project?.name,
              }}
            />
          </If>
          <If isTrue={context.message.release}>
            <FormattedMessage
              id="NOTIFICATION_MESSAGE_SENT_RELEASE"
              values={{
                sequenceNumber: context.message.release?.sequenceNumber,
                clientIdentifier:
                  context.message.release?.buyout?.clientIdentifier,
                projectName: context.message.release?.project?.name,
                vendorOrgName:
                  context.message.release?.sellerOrgLocation?.org.name,
              }}
            />
          </If>
        </MessageBody>
      </DetailsContainer>
    </BaseNotification>
  );
};
