import { Switch } from "@/common/components/switch/Switch";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { useIntl } from "react-intl";
import {
  DefaultSettingsDetails,
  DefaultSettingsToggleText,
  SimpleStepContainer,
  SimpleStepContent,
  SimpleStepItemRow,
  SimpleStepRow,
  SimpleStepTitle,
  SimpleStepTitleContainer,
} from "../../../Wizard.styles";
import { useAgaveHostedWizard } from "../../AgaveHostedWizardProvider";

export const AgaveWizardProjectsAutoCloseSettings: FC = () => {
  const intl = useIntl();
  const { autoCloseProjectsByJobStatus, setAutoCloseProjectsByJobStatus } =
    useAgaveHostedWizard();

  return (
    <SimpleStepContainer>
      <SimpleStepRow>
        <InfoOutlined className="mr-4 h-12 w-12 text-gray-400" />
        <SimpleStepTitleContainer>
          <SimpleStepTitle>
            {intl.$t({ id: "PROJECTS_AUTO_CLOSE_SETTINGS" })}
          </SimpleStepTitle>
        </SimpleStepTitleContainer>
      </SimpleStepRow>
      <SimpleStepContent>
        <SimpleStepItemRow className="border-b-0">
          <DefaultSettingsToggleText>
            {intl.$t({ id: "PROJECT_AUTO_CLOSE" })}
          </DefaultSettingsToggleText>
          <Switch
            width={100}
            offLabel={intl.$t({ id: "NO" })}
            onLabel={intl.$t({ id: "YES" })}
            onChange={setAutoCloseProjectsByJobStatus}
            value={autoCloseProjectsByJobStatus}
          />
          <DefaultSettingsDetails>
            {intl.$t({ id: "PROJECTS_AUTO_CLOSE_INFORMATION" })}
          </DefaultSettingsDetails>
        </SimpleStepItemRow>
      </SimpleStepContent>
    </SimpleStepContainer>
  );
};
