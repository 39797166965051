import { If } from "@/common/components/if/If";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorBuyout } from "../providers/DistributorBuyoutProvider";
const Container = tw.div`
  flex items-center z-100
`;

type Props = {
  items: DistributorBuyoutItemFieldsFragment[];
  readonly?: boolean;
};

export const DistributorBuyoutCategoryToggle: FC<Props> = ({
  items,
  readonly,
}) => {
  const intl = useIntl();

  const [includeItem, toggleItem] = useState<boolean>(
    items.every((item) => item.isIncluded),
  );
  const [isNeutral, setIsNeutral] = useState<boolean>(false);
  const { updateVendorBuyout } = useDistributorBuyout();

  useEffect(() => {
    if (items.every((i) => !i.isIncluded) && includeItem) {
      toggleItem(false);
    }
  }, [items, includeItem]);

  useEffect(() => {
    const neutral =
      items.some((item) => !item.isIncluded) &&
      items.some((item) => item.isIncluded);
    setIsNeutral(neutral);

    if (!items.every((i) => !i.isIncluded)) {
      toggleItem(true);
    }
  }, [items]);

  const onToggle = useCallback(
    async (checked: boolean) => {
      setIsNeutral(false);
      const updates = items.map((item) => ({
        buyoutItemId: item.id,
        isIncluded: checked,
      }));
      await updateVendorBuyout({
        updates,
      });

      toggleItem(checked);
    },
    [items, updateVendorBuyout],
  );

  return (
    <Container onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <If isTrue={!readonly}>
        <InnerLabeledSwitch
          toggle={onToggle}
          className="print:hidden"
          width={80}
          initialValue={includeItem}
          onLabel={
            isNeutral ? intl.$t({ id: "YES" }) : intl.$t({ id: "YES_ALL" })
          }
          offLabel={
            isNeutral ? intl.$t({ id: "NO" }) : intl.$t({ id: "NO_ALL" })
          }
          neutral={isNeutral}
        />
      </If>
    </Container>
  );
};
