import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  NestedWizardModalPage,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { TemplateType } from "@/contractor/pages/admin/integrations/enums/TemplateType.enum";
import { useTemplatePreviewStore } from "@/contractor/pages/admin/integrations/store/useTemplatePreviewStore";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { UpdateSourceSystemsSettingsInput } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useAgaveHostedWizard } from "../../../AgaveHostedWizardProvider";
import { TemplateStrings } from "../../../steps/template-strings/TemplateStrings";

export const useTemplateStringsPage = (): WizardModalPage => {
  const intl = useIntl();
  const { moveToPreviousStep, moveToNextStep } = useNestedStepper();
  const { handleSaveWizardData } = useAgaveHostedWizard();

  const { templates, setTemplate } = useTemplatePreviewStore(
    useShallow((state) => ({
      templates: state.templates,
      setTemplate: state.setTemplate,
    })),
  );

  const { connectedSourceSystem } = useOrgSettingsExtended();

  const onNextClick = useCallback(async () => {
    const settings: Omit<UpdateSourceSystemsSettingsInput, "system"> = {
      poNameTemplate: templates[TemplateType.PoName].value,
      poDescriptionTemplate: templates[TemplateType.PoDescription].value,
      invoiceDescriptionTemplate:
        templates[TemplateType.InvoiceDescription].value,
    };
    await handleSaveWizardData(settings);
    moveToNextStep();
  }, [templates, handleSaveWizardData, moveToNextStep]);

  return useMemo(() => {
    const pages: NestedWizardModalPage[] = [
      {
        component: <TemplateStrings />,
        onPreviousClick: moveToPreviousStep,
        onNextClick,
        onCloseClick: () => {
          setTemplate(
            TemplateType.PoName,
            connectedSourceSystem?.poNameTemplate ?? "",
          );
          setTemplate(
            TemplateType.PoDescription,
            connectedSourceSystem?.poDescriptionTemplate ?? "",
          );
          setTemplate(
            TemplateType.InvoiceDescription,
            connectedSourceSystem?.invoiceDescriptionTemplate ?? "",
          );
        },
      },
    ];

    return {
      feature: IntegrationFeature.TemplateStringsStep,
      title: intl.$t({ id: "TEMPLATE_STRINGS" }),
      completed:
        connectedSourceSystem?.poNameTemplate !== null &&
        connectedSourceSystem?.poDescriptionTemplate !== null &&
        connectedSourceSystem?.invoiceDescriptionTemplate !== null,
      pages,
    };
  }, [
    moveToPreviousStep,
    onNextClick,
    intl,
    connectedSourceSystem?.poNameTemplate,
    connectedSourceSystem?.poDescriptionTemplate,
    connectedSourceSystem?.invoiceDescriptionTemplate,
    setTemplate,
  ]);
};
