import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import { DeleteOutline } from "@mui/icons-material";
import { useMemo } from "react";
import { useInvoiceEmailsSequence } from "../../hooks/useInvoiceEmailsSequence";
import { useInvoiceEmailsSideAction } from "./useInvoiceEmailsSideAction";

export const InvoiceEmailsSideActions = () => {
  const { deleteItems, shouldShowDeleteAction } = useInvoiceEmailsSideAction();
  const { sequenceIds } = useInvoiceEmailsSequence();

  const actions = useMemo(() => {
    const sideActions = [];

    if (shouldShowDeleteAction()) {
      sideActions.push({
        icon: <DeleteOutline />,
        tooltipKey: "DELETE_ITEMS",
        callback: deleteItems,
      });
    }
    return sideActions;
  }, [shouldShowDeleteAction, deleteItems]);

  return <SideActionBar visible={sequenceIds.length > 0} actions={actions} />;
};
