import { useEquipmentItems } from "@/contractor/pages/admin/cost-structure/pages/equipment/hooks/useEquipmentItems";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  ALL_EQUIPMENT,
  EquipmentFilterSelectorProps,
} from "./EquipmentFilterSelector";

export const useEquipmentFilterSelector = ({
  onChange,
  value,
}: EquipmentFilterSelectorProps) => {
  const intl = useIntl();
  const { equipmentItems, loading } = useEquipmentItems();

  const options = useMemo(() => {
    return [
      { id: ALL_EQUIPMENT, description: intl.$t({ id: "ALL_ITEMS" }) },
      ...(equipmentItems || []),
    ];
  }, [equipmentItems, intl]);

  const selectedOption = useMemo(
    () =>
      value === ALL_EQUIPMENT
        ? undefined
        : equipmentItems.find((item) => item.id === value),
    [equipmentItems, value],
  );

  const onChangeHandler = useCallback(
    (value: string | null) => {
      onChange(value === ALL_EQUIPMENT ? null : value);
    },
    [onChange],
  );

  return { onChangeHandler, loading, options, selectedOption };
};
