import { If } from "@/common/components/if/If";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

export enum ExportType {
  ORDER,
  INVOICE,
  BUYOUT,
}

type Props = {
  count: number;
  type: ExportType;
  batch?: string;
  message?: string;
};

const SuccessMessage = tw.div`flex flex-col items-center justify-center gap-2 p-10`;
const Title = tw.div`text-2xl font-bold`;
const Message = tw.div`text-center text-base font-normal`;
const CheckIconStyled = tw(
  CheckCircleOutlineRounded,
)`text-blue-500 text-[120px]`;

export const ExportCompleted: FC<Props> = ({ count, type, batch, message }) => (
  <SuccessMessage>
    <CheckIconStyled />
    <Title>{message ?? <FormattedMessage id="EXPORT_COMPLETED" />}</Title>
    <If isTrue={count}>
      <Message>
        <FormattedMessage
          id={`${ExportType[type]}_SINGULAR_PLURAL`}
          values={{
            count,
          }}
        />
      </Message>
    </If>
    <If isTrue={batch}>
      <Message>{batch}</Message>
    </If>
  </SuccessMessage>
);
