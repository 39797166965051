import {
  CreateInvoiceKickbackCommentInput,
  UpdateInvoiceKickbackCommentInput,
  useCreateInvoiceKickbackCommentMutation,
  useDeleteInvoiceKickbackCommentMutation,
  useUpdateInvoiceKickbackCommentMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useGlobalError } from "./useGlobalError";

export const useKickbackCommentActions = () => {
  const { setError } = useGlobalError();
  const [createInvoiceKickbackCommentMutation] =
    useCreateInvoiceKickbackCommentMutation();
  const [updateInvoiceKickbackCommentMutation] =
    useUpdateInvoiceKickbackCommentMutation();
  const [deleteInvoiceKickbackCommentMutation] =
    useDeleteInvoiceKickbackCommentMutation();

  const createInvoiceKickbackComment = useCallback(
    async (values: CreateInvoiceKickbackCommentInput) => {
      try {
        const { errors, data } = await createInvoiceKickbackCommentMutation({
          variables: { input: values },
        });
        setError(errors);
        return !!data?.createInvoiceKickbackComment;
      } catch (errors) {
        setError(errors);
      }
    },
    [createInvoiceKickbackCommentMutation, setError],
  );

  const updateInvoiceKickbackComment = useCallback(
    async (values: UpdateInvoiceKickbackCommentInput) => {
      try {
        const { errors, data } = await updateInvoiceKickbackCommentMutation({
          variables: { input: values },
        });
        setError(errors);
        return !!data?.updateInvoiceKickbackComment;
      } catch (errors) {
        setError(errors);
      }
    },
    [updateInvoiceKickbackCommentMutation, setError],
  );

  const deleteInvoiceKickbackComment = useCallback(
    async (id: string) => {
      try {
        const { errors, data } = await deleteInvoiceKickbackCommentMutation({
          variables: { id },
        });
        setError(errors);
        return !!data?.deleteInvoiceKickbackComment;
      } catch (errors) {
        setError(errors);
      }
    },
    [deleteInvoiceKickbackCommentMutation, setError],
  );

  return {
    createInvoiceKickbackComment,
    updateInvoiceKickbackComment,
    deleteInvoiceKickbackComment,
  };
};
