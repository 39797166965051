import { InvoiceType, UpdateInvoicesInput } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useInvoiceSequence } from "../../../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../../../providers/InvoicesProvider";
import { useInvoiceFolders } from "../../../invoice-folder-picker/hooks/useInvoiceFolders";
import { InvoiceFolderSelectorProps } from "../types/InvoiceFolderSelectorProps";

export const useInvoiceFolderSelector = ({
  removing,
  existingFolders,
}: Pick<InvoiceFolderSelectorProps, "existingFolders" | "removing">) => {
  const { folders } = useInvoiceFolders(InvoiceType.Receipt);
  const { sequenceIds, selectedInvoices, setSelectedInvoices } =
    useInvoiceSequence();
  const { createFolder } = useInvoiceFolders(InvoiceType.Invoice);
  const { updateInvoices } = useInvoices();
  const filteredFolders = useMemo(
    () => (removing ? existingFolders || [] : folders),
    [folders, removing, existingFolders],
  );

  const updateEntity = useCallback(
    async (input: Omit<UpdateInvoicesInput, "invoiceIds">) => {
      const updateResult = await updateInvoices({
        invoiceIds: sequenceIds,
        ...input,
      });
      if (!updateResult) {
        return;
      }
      const selectedInvoicesCopy = selectedInvoices.map((invoice) => {
        const item = {
          ...invoice,
          folders: invoice.folders ? [...invoice.folders] : [],
        };
        input.assignedFolderIds?.forEach((id) => {
          const originalFolder = folders.find((folder) => folder.id === id);
          if (
            originalFolder &&
            !item.folders.find((folder) => folder.id === id)
          ) {
            item.folders.push({
              id: originalFolder.id,
              name: originalFolder.name,
            });
          }
        });
        input.unassignedFolderIds?.forEach((id) => {
          item.folders = item.folders.filter((folder) => folder.id !== id);
        });
        return item;
      });
      setSelectedInvoices(selectedInvoicesCopy);
    },
    [
      updateInvoices,
      sequenceIds,
      folders,
      selectedInvoices,
      setSelectedInvoices,
    ],
  );

  return {
    filteredFolders,
    updateEntity,
    createFolder,
  };
};
