import {
  ReleaseWithType,
  useOrderTypesConfig,
} from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { ReleaseBackorderDatePickerCustomActionBar } from "@/contractor/pages/home/release/pages/specify-details/components/date-picker-action-bars/ReleaseBackorderDatePickerCustomActionBar";
import { ReleaseDatePickerCustomActionBar } from "@/contractor/pages/home/release/pages/specify-details/components/date-picker-action-bars/ReleaseDatePickerCustomActionBar";
import { useReleaseStore } from "@/contractor/pages/home/release/store/useReleaseStore";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { isRetroactiveRelease } from "../../../../../../releases/pages/deliveries/utils/isRetroactiveRelease";

const inputLabelProps = {
  classes: {
    shrink: "mt-1 text-base",
    root: "ml-1 truncate text-xs max-w-full",
  },
};
export type DatePickerRelease =
  | (Pick<ReleaseFieldsFragment, "timeTBD"> & ReleaseWithType)
  | null
  | undefined;

type Props = {
  date: number | undefined | Date | null;
  labels?: {
    customLabel?: string;
    defaultLabel?: string;
  };
  release: DatePickerRelease;
  prefillIfTbd?: boolean;
};

export const useReleaseDateTimePickerConfig = ({
  date,
  labels,
  release,
  prefillIfTbd,
}: Props) => {
  const retroactiveRelease = useMemo(() => isRetroactiveRelease(date), [date]);
  const { orderTypeConfig } = useOrderTypesConfig({ release });
  const intl = useIntl();
  const { releaseUpdateOptions } = useReleaseStore(
    useShallow((state) => ({
      releaseUpdateOptions: state.releaseUpdateOptions,
    })),
  );

  const actionBar = useMemo(
    () =>
      retroactiveRelease
        ? ReleaseBackorderDatePickerCustomActionBar
        : ReleaseDatePickerCustomActionBar,
    [retroactiveRelease],
  );

  const labelProps = useMemo(() => {
    if (
      release?.timeTBD &&
      (!releaseUpdateOptions.requestedTime || !prefillIfTbd)
    ) {
      return {
        styling: {
          classes: {
            ...inputLabelProps.classes,
            root: `${inputLabelProps.classes.root} text-black`,
          },
        },
        label: intl.$t({ id: "HOLD_FOR_RELEASE" }),
      };
    }

    return {
      label:
        labels?.customLabel ??
        orderTypeConfig?.labels.datePicker ??
        labels?.defaultLabel,
      styling: inputLabelProps,
    };
  }, [
    release?.timeTBD,
    releaseUpdateOptions.requestedTime,
    prefillIfTbd,
    labels?.customLabel,
    labels?.defaultLabel,
    orderTypeConfig?.labels.datePicker,
    intl,
  ]);

  const value = useMemo(
    () =>
      (prefillIfTbd && releaseUpdateOptions.requestedTime
        ? new Date(releaseUpdateOptions.requestedTime)
        : undefined) ??
      (release?.timeTBD ? null : date ? new Date(date) : null),
    [prefillIfTbd, releaseUpdateOptions.requestedTime, release?.timeTBD, date],
  );

  const disabled = useMemo(
    () =>
      !!release?.timeTBD &&
      (!releaseUpdateOptions.requestedTime || !prefillIfTbd),
    [prefillIfTbd, release?.timeTBD, releaseUpdateOptions.requestedTime],
  );

  return { actionBar, labelProps, value, disabled };
};
