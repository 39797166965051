import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { ReleaseStatus } from "@/generated/graphql";
import { useState } from "react";
import { MatchedOrderViewState } from "../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useAssignOrderToInvoice = () => {
  const [isAssigningOrderToInvoice, setIsAssigningOrderToInvoice] =
    useState(false);
  const { invoice, setFooterState, updateInvoice } = useInvoiceVerification();
  const { setMatchedOrderViewState, selectedRelease } =
    useInvoiceMatchedOrder();
  const { setError } = useGlobalError();

  //can't use useCallback as updateInvoice is not memoized
  const assignOrderToInvoice = async () => {
    if (!invoice || !selectedRelease) {
      return false;
    }
    try {
      setIsAssigningOrderToInvoice(true);
      const result = await updateInvoice({
        id: invoice.id,
        releaseId: selectedRelease.id ?? undefined,
      });
      if (result?.updateInvoice) {
        const release = result.updateInvoice.release;
        if (release?.status === ReleaseStatus.Reserved && !release.itemCount) {
          setMatchedOrderViewState(MatchedOrderViewState.EDIT_ORDER);
          setFooterState(InvoiceFooterState.EDIT_ORDER);
        } else {
          setMatchedOrderViewState(
            MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
          );
          setFooterState(InvoiceFooterState.DEFAULT);
        }
        return true;
      }
      return false;
    } catch (error) {
      setError(error);
    } finally {
      setIsAssigningOrderToInvoice(false);
    }
  };

  return { assignOrderToInvoice, isAssigningOrderToInvoice };
};
