import { If } from "@/common/components/if/If";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import {
  ProjectReportCostCodeFieldsFragment,
  ProjectReportCostCodeVendorFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { CurrencyDisplay } from "../CurrencyDisplay";
import { TotalContainer, ValueWithTax } from "./CostCodeTotal.Styles";

type Props = {
  item:
    | ProjectReportCostCodeVendorFieldsFragment
    | ProjectReportCostCodeFieldsFragment
    | null
    | undefined;
};

export const CostCodePaid: FC<Props> = ({ item }) => {
  const { formatCurrency } = useFormatNumberToCurrency();
  if (!item) {
    return null;
  }
  return (
    <TotalContainer>
      <CurrencyDisplay value={item?.paidTotal} />
      <If isTrue={item?.paidTotal && item.paid && item.paidTotal !== item.paid}>
        <FormattedMessage
          id="VALUE_TAX_AND_FEES"
          tagName={ValueWithTax}
          values={{
            value: formatCurrency(
              new DecimalSafe(item?.paidTotal ?? 0).minus(item.paid ?? 0),
            ),
          }}
        />
      </If>
    </TotalContainer>
  );
};
