import { Dialog } from "@/common/components/dialog/Dialog";
import { Loader } from "@/common/components/loader/Loader";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { ProjectItemsZonesProvider } from "@/contractor/pages/home/project/providers/ProjectItemsZonesProvider";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../buyout/providers/ContractorBuyoutProvider";
import { ReleaseFilters } from "../../components/ReleaseFilters";
import { useRelease } from "../../providers/ReleaseProvider";
import {
  AddItemsToReleaseProvider,
  useAddItemsToRelease,
} from "./AddItemsToReleaseProvider";
import { AddFromEstimatesToReleaseList } from "./release-items-list/AddFromEstimatesToReleaseList";
import { useAddToReleaseFromEstimatesConfiguration } from "./release-items-list/AddToReleaseFromEstimates.configuration";
import {
  ReleaseProjectProvider,
  useReleaseProject,
} from "./release-items-list/ReleaseProjectProvider";

const Container = tw.div`w-[calc(80vw)] max-h-[calc(80vh)]`;

type AddItemsToReleaseDialogProps = {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
};

const AddItemsToReleaseWithProvider: FC<AddItemsToReleaseDialogProps> = ({
  visible = true,
  setVisible,
}) => {
  const intl = useIntl();
  const { release, updating } = useRelease();
  const { project } = useReleaseProject();
  const { setSuccessAlert, setWarningAlert } = useSnackbar();
  const { addItemsToRelease, selectedItems, setSelectedItems } =
    useAddItemsToRelease();
  const { configuration } = useAddToReleaseFromEstimatesConfiguration(
    release?.buyout?.id,
    release?.project?.id,
  );

  useEffect(() => {
    if (visible) {
      setSelectedItems([]);
    }
  }, [setSelectedItems, visible]);

  const handleCancel = () => {
    setVisible?.(false);
  };

  const addItems = () => {
    if (selectedItems.length === 0) {
      setWarningAlert(<FormattedMessage id={`NOTHING_TO_ADD`} />);
      return;
    }
    const result = addItemsToRelease();
    if (!result) {
      return;
    }
    setSuccessAlert(intl.$t({ id: "ITEMS_ADDED_TO_RELEASE" }));
    handleCancel();
  };

  if (!release || !project) {
    return;
  }

  return (
    <Dialog
      title={
        <FormattedMessage id={"ADD_ITEMS_TO_RELEASE_FROM_BILL_OF_MATERIALS"} />
      }
      confirmButtonText={intl.$t(
        {
          id:
            selectedItems.length === 0
              ? "ADD_TO_RELEASE"
              : "ADD_TO_RELEASE_WITH_COUNT",
        },
        { count: selectedItems.length },
      )}
      handleConfirm={addItems}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
      }}
      extraButtonMargin={false}
      loading={updating}
      handleCancel={handleCancel}
      maxWidth={false}
      show={visible}
      content={
        <Container>
          <ReleaseFilters />
          <AddFromEstimatesToReleaseList columns={configuration} />
        </Container>
      }
    />
  );
};

const AddItemsToReleaseFromProjectWithProjectProvider: FC<
  AddItemsToReleaseDialogProps
> = (props) => {
  const { project } = useReleaseProject();

  if (!project) {
    return <Dialog show content={<Loader loading />} />;
  }

  return (
    <ProjectItemsZonesProvider project={project}>
      <AddItemsToReleaseProvider>
        <AddItemsToReleaseWithProvider {...props} />
      </AddItemsToReleaseProvider>
    </ProjectItemsZonesProvider>
  );
};

export const AddItemsToReleaseFromEstimatesDialog: FC<
  AddItemsToReleaseDialogProps
> = (props) => {
  const { buyout } = useContractorBuyout();
  const { release } = useRelease();

  if (!props.visible) {
    return;
  }

  return (
    <ReleaseProjectProvider id={buyout?.project.id || release?.project?.id}>
      <AddItemsToReleaseFromProjectWithProjectProvider {...props} />
    </ReleaseProjectProvider>
  );
};
