import { useCallback } from "react";
import { useRfqSequence } from "../hooks/useRfqSequence";
import { RfqCheckboxProps } from "./RfqCheckbox";

export const useRfqCheckbox = ({ quote }: RfqCheckboxProps) => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useRfqSequence();

  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedEntities(
        newChecked
          ? [...selectedEntities, quote]
          : selectedEntities.filter((d) => d.id !== quote.id),
      );
    },
    [quote, selectedEntities, setSelectedEntities],
  );

  return { sequenceIds, handleChange };
};
