import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { ReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  ReleaseProvider,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseUpdateProvider } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { ReleaseItemList } from "@/contractor/pages/home/release/release-items-list/ReleaseItemList";
import { ReleaseItemFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceMatchedOrder } from "../../../../../providers/InvoiceMatchedOrderProvider";
import { InvoiceSuggestedOrders } from "../InvoiceSuggestedOrders";
import { BlankRelease } from "./BlankRelease";
import { InvoiceOrderFilters } from "./InvoiceOrderFilters";
import { useInvoiceReleaseDetailsConfiguration } from "./InvoiceReleaseDetails.configuration";

const Container = tw.div`flex flex-col flex-1 items-start overflow-scroll pb-5 px-2.5`;
const ListContainer = tw.div`mt-5 w-full`;
const LoaderStyled = tw(Loader)`mt-20`;

const InvoiceOrderSelectorWithProviders: FC = () => {
  const { release, loading } = useRelease();
  const configuration = useInvoiceReleaseDetailsConfiguration(release);

  const itemFn = useCallback((item: ReleaseItemFieldsFragment) => {
    return {
      className: `${item.isIncluded ? "" : "bg-gray-100"}`,
    };
  }, []);

  const items = useMemo(
    () =>
      release?.items.map((item) => {
        const estimatedItems = item.projectItem?.estimatedItems.filter(
          (ei) => ei.zone?.id === item.zone?.id,
        );
        return {
          ...{
            ...item.projectItem,
            material: {
              ...item.projectItem?.material,
              material: {
                ...item.projectItem?.material.material,
                name: item.name,
              },
            },
            estimatedItems,
          },
          ...item,
        } as ExpandedReleaseItem;
      }) ?? [],
    [release],
  );

  if (!release || loading) {
    return <LoaderStyled loading={loading} />;
  }

  if (
    release &&
    release.status === ReleaseStatus.Reserved &&
    !release.items.length
  ) {
    return <BlankRelease />;
  }

  return (
    <ReleaseUpdateProvider
      release={release}
      project={release?.project}
      items={items}
    >
      <ReleaseItemsZoneProvider items={items}>
        <ListContainer>
          <ReleaseItemList
            columns={configuration}
            classNames={{
              header: "top-[48px] z-10",
              category: "top-[36px] lg:top-[102px]",
              subCategory: (groupedByZones: boolean) =>
                groupedByZones
                  ? "top-[76px] lg:top-[144px]"
                  : "top-[36px] lg:top-[102px]",
            }}
            itemFn={itemFn}
          />
        </ListContainer>
      </ReleaseItemsZoneProvider>
    </ReleaseUpdateProvider>
  );
};

export const InvoiceOrderSelectorView: FC = () => {
  const { selectedRelease } = useInvoiceMatchedOrder();

  return (
    <Container>
      <InvoiceOrderFilters />
      <InvoiceSuggestedOrders />
      <If isTrue={selectedRelease}>
        <ReleaseProvider id={selectedRelease?.id}>
          <InvoiceOrderSelectorWithProviders />
        </ReleaseProvider>
      </If>
    </Container>
  );
};
