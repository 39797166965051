import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { PROJECT_ADDRESS_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { QUERYSTRING } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { RELEASE } from "@/common/queries/release";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useVendorPricesStore } from "@/contractor/pages/admin/org-items/pages/materials-prices/stores/useVendorPricesStore";
import { useNoteDocument } from "@/contractor/pages/home/common/note-document/providers/NoteDocumentProvider";
import { useSyncReleaseItems } from "@/contractor/pages/home/release/pages/specify-details/hooks/useSyncReleaseItems";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  OrgPreferredVendorsFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseStatus,
  ServiceType,
  UomsDocument,
  useCreateStandaloneReleaseMutation,
  useProjectPredictedPoNumberQuery,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionUndefined } from "@/types/NoFunction";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { CreateOrderFromNoteFormValues } from "../components/order-from-note/create-order-from-note/components/CreateOrderFromNoteForm";

type ProviderContextType = {
  syncCreateReleaseFromNote: (
    status?: ReleaseStatus,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
  syncUpdateReleaseFromNote: ({
    skipVendorNotification,
  }: {
    skipVendorNotification: boolean | undefined;
  }) => Promise<ReleaseFieldsFragment | undefined | null>;
  validateSpreadsheet: () => Promise<boolean>;
  loading: boolean;
  vendors: OrgPreferredVendorsFieldsFragment[];
  loadingVendors: boolean;
  findOrderTypeByLocationId: (locationId: string) => string | undefined;
  loadingPredictedPoNumber: boolean;
  setWasDocumentReplaced: (wasDocumentReplaced: boolean) => void;
  wasDocumentReplaced: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateReleaseFromNote: () => Promise.resolve(null),
  syncUpdateReleaseFromNote: () => Promise.resolve(null),
  validateSpreadsheet: () => Promise.resolve(false),
  loading: false,
  vendors: [],
  loadingPredictedPoNumber: false,
  loadingVendors: false,
  findOrderTypeByLocationId: NoFunctionUndefined,
  setWasDocumentReplaced: NoFunction,
  wasDocumentReplaced: false,
});

export const OrderFromNoteProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { queryParams } = useQueryParams();

  const { noteDocument } = useNoteDocument();
  const { setError } = useGlobalError();
  const { release } = useRelease();
  const [wasDocumentReplaced, setWasDocumentReplaced] = useState(
    queryParams.get(QUERYSTRING.REPLACE_DOCUMENT) === "true",
  );
  const { spreadsheetData, resetPreviousData } = useColumnMapper();
  const { getSyncedRelease, validateItems, saving } = useSyncReleaseItems();
  const {
    vendors,
    loading: loadingVendors,
    findOrderTypeByLocationId,
  } = useVendors();
  const { setWarningAlert } = useSnackbar();
  const { initViewStore, resetViewStore } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
    })),
  );
  const { fetchVendorPrices } = useVendorPricesStore(
    useShallow((state) => ({
      fetchVendorPrices: state.fetchVendorPrices,
    })),
  );

  const { setValue, watch, getValues } =
    useFormContext<CreateOrderFromNoteFormValues>();

  const projectId = watch("projectId");
  const poNumber = watch("poNumber");
  const orderDate = watch("orderDate");

  const {
    data: projectData,
    error: predictedPoNumberError,
    loading: loadingPredictedPoNumber,
  } = useProjectPredictedPoNumberQuery({
    variables: {
      id: projectId,
    },
    skip: !projectId || !!poNumber,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    initViewStore(TableViewState.spreadsheet);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  useEffect(() => {
    const predictedPoNumber = projectData?.project?.predictedPoNumber;

    if (!predictedPoNumberError && predictedPoNumber) {
      return setValue("predictedPoNumber", predictedPoNumber);
    }

    return setValue("predictedPoNumber", "");
  }, [
    orderDate,
    projectData?.project?.predictedPoNumber,
    predictedPoNumberError,
    setValue,
  ]);

  const [createStandaloneRelease, { loading: creating }] =
    useCreateStandaloneReleaseMutation();
  const [updateRelease, { loading: updating }] =
    useUpdateContractorReleaseMutation();

  const validateSpreadsheet = useCallback(async () => {
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    return await validateItems();
  }, [spreadsheetData, setWarningAlert, validateItems]);

  const syncCreateReleaseFromNote = async (status?: ReleaseStatus) => {
    const isValid = await validateSpreadsheet();
    const values = getValues();

    if (!isValid) {
      return null;
    }

    const { addedItems } = await getSyncedRelease({
      projectId: values.projectId,
    });
    if (addedItems.length > 0) {
      try {
        const includeServices = [];
        if (!values.willCall) {
          includeServices.push({ type: ServiceType.Delivery });
        }
        if (!values.willCall && values.vendorStocking) {
          includeServices.push({ type: ServiceType.Stocking });
        }

        const { data, errors } = await createStandaloneRelease({
          variables: {
            input: {
              projectId: values.projectId ?? "",
              sellerOrgLocationId: values.vendorId || undefined,
              poNumber: values.poNumber || undefined,
              time: values.orderDate?.getTime() ?? undefined,
              timeTBD: values.timeTBD,
              items: addedItems,
              requiresInventoryReceipt: values.requiresInventoryReceipt,
              noteDocumentId: noteDocument?.id ?? "",
              includeServices,
              taxRate:
                values.taxRate ||
                (values.clearCustomTaxAmount ||
                values.customTaxAmount?.length === 0
                  ? "0"
                  : undefined),
              customTaxAmount:
                values.clearCustomTaxAmount ||
                values.customTaxAmount?.length === 0
                  ? undefined
                  : values.customTaxAmount,
              additionalCharges: values.additionalCharges,
              instructions: values.instructions,
              paymentTerm: values.paymentTerm,
              status: status,
              typeId: values.orderTypeId || undefined,
              assignDefaultCostCodes: false,
              vendorContactIds: values.vendorContactIds,
              taxCodeId: values.taxCodeId,
              taxType: values.taxType,
              warehouseId: values.willCall
                ? undefined
                : values.fulfillmentLocationId === PROJECT_ADDRESS_ID
                  ? undefined
                  : values.fulfillmentLocationId,
              watcherIds: values.watcherIds,
              description: values.description || undefined,
              taxVariance: values.taxVariance,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: UomsDocument,
            },
          ],
        });
        setError(errors);
        if (!errors) {
          fetchVendorPrices(true);
          resetPreviousData();
        }
        return data?.createStandaloneRelease ?? null;
      } catch (error) {
        setError(error);
        return null;
      }
    }

    return release;
  };

  const syncUpdateReleaseFromNote = async ({
    skipVendorNotification,
  }: {
    skipVendorNotification: boolean | undefined;
  }) => {
    const values = getValues();
    const isValid = await validateItems();
    if (!isValid) {
      return null;
    }
    const { addedItems, updates, removedItems } = await getSyncedRelease();

    const includeServices = [];
    if (!values.willCall) {
      includeServices.push({ type: ServiceType.Delivery });
    }
    if (!values.willCall && values.vendorStocking) {
      includeServices.push({ type: ServiceType.Stocking });
    }

    if (release) {
      try {
        const { data, errors } = await updateRelease({
          variables: {
            input: {
              releaseId: release.id,
              version: release.version,
              addedItems,
              updates,
              removedItems,
              taxRate: values.taxRate || undefined,
              customTaxAmount: values.customTaxAmount || undefined,
              clearTaxRate: !!values.customTaxAmount,
              clearCustomTaxAmount: !values.customTaxAmount,
              additionalCharges: values.additionalCharges.filter(
                (charge) => charge.description && Number(charge.amount) > 0,
              ),
              poNumber: values.poNumber,
              requestedTime: values.orderDate?.getTime() ?? undefined,
              timeTBD: values.timeTBD,
              requiresInventoryReceipt: values.requiresInventoryReceipt,
              includeServices,
              instructions: values.instructions,
              typeId: values.orderTypeId || undefined,
              assignDefaultCostCodes: false,
              prefillPrices: false,
              sellerOrgLocationId: values.vendorId || undefined,
              clearWarehouse:
                values.fulfillmentLocationId === PROJECT_ADDRESS_ID,
              warehouseId: values.willCall
                ? undefined
                : values.fulfillmentLocationId === PROJECT_ADDRESS_ID
                  ? undefined
                  : values.fulfillmentLocationId,
              watcherIds: values.watcherIds,
              description: values.description || "",
              taxVariance: values.taxVariance,
              clearTaxVariance: !values.taxVariance,
              skipVendorNotification,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: RELEASE,
              variables: { id: release?.id ?? "" },
            },
            {
              query: UomsDocument,
            },
          ],
        });
        setError(errors);
        if (!errors) {
          fetchVendorPrices(true);
          resetPreviousData();
        }
        return data?.updateContractorRelease ?? null;
      } catch (error) {
        setError(error);
        return null;
      }
    }

    return release;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCreateReleaseFromNote,
        syncUpdateReleaseFromNote,
        validateSpreadsheet,
        loading: saving || creating || updating,
        vendors,
        loadingVendors,
        findOrderTypeByLocationId,
        loadingPredictedPoNumber,
        setWasDocumentReplaced,
        wasDocumentReplaced,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useOrderFromNote = (): ProviderContextType =>
  useContext(ProviderContext);
