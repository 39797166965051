import { DecimalSafe } from "@/common/utils/decimalSafe";
import {
  AddToBuyoutItemInput,
  BuyoutItemFieldsFragment,
} from "@/generated/graphql";
import { NoFunction, NoFunctionUndefinedPromise } from "@/types/NoFunction";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { useParams } from "react-router";
import { useBuyoutMutations } from "./useBuyoutMutations";

export type AddToBuyoutItem = AddToBuyoutItemInput & {
  itemId: string;
  estimatedItemIds: string[];
  unitPrice?: string;
  isIncluded: boolean;
};

type ContextType = {
  addToBuyout: () => Promise<BuyoutItemFieldsFragment[] | undefined>;
  updates: AddToBuyoutItem[];
  setUpdates: (updates: AddToBuyoutItem[]) => void;
  isAdding: boolean;
};

const ProjectItemContext = createContext<ContextType>({
  updates: [],
  setUpdates: NoFunction,
  addToBuyout: NoFunctionUndefinedPromise,
  isAdding: false,
});

export const BuyoutProjectItemsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { id } = useParams();
  const [bulkUpdates, setBulkUpdates] = useState<AddToBuyoutItem[]>([]);
  const { addToBuyout, adding: isAdding } = useBuyoutMutations();

  const setUpdateForBulkUpdate = (updates: AddToBuyoutItem[]) => {
    const updatedBulkUpdates = bulkUpdates.map((bu) => {
      const newItem =
        updates.find(
          (u) =>
            u.itemId === bu.itemId &&
            u.estimatedItemIds.some((ei) => bu.estimatedItemIds.includes(ei)),
        ) || ({} as AddToBuyoutItem);
      return {
        ...bu,
        ...newItem,
        estimatedItemIds: newItem.estimatedItemIds
          ? [...newItem.estimatedItemIds, ...bu.estimatedItemIds]
          : [...bu.estimatedItemIds],
        quantityDecimal: new DecimalSafe(newItem.quantityDecimal || 0)
          .add(bu?.quantityDecimal || 0)
          .toString(),
      };
    });
    const existingUpdates = bulkUpdates.filter((u) =>
      updates.some(
        (existing) =>
          existing.itemId === u.itemId &&
          existing.estimatedItemIds.some((ei) =>
            u.estimatedItemIds.includes(ei),
          ),
      ),
    );
    const newBulkUpdates = [
      ...updatedBulkUpdates,
      ...updates.filter(
        (u) =>
          !existingUpdates.some((existing) => existing.itemId === u.itemId),
      ),
    ].filter((u) => u.isIncluded);

    setBulkUpdates(newBulkUpdates);
  };

  const addToBuyoutHandler = async () => {
    return await addToBuyout({
      buyoutId: id || "",
      items: bulkUpdates.map((item) => ({
        description: item.description,
        manufacturerId: item.manufacturerId,
        projectItem: item.projectItem,
        quantityDecimal: item.quantityDecimal,
        costCodeId: item.costCodeId,
        tags: item.tags,
      })),
    });
  };

  return (
    <ProjectItemContext.Provider
      value={{
        updates: bulkUpdates,
        setUpdates: setUpdateForBulkUpdate,
        addToBuyout: addToBuyoutHandler,
        isAdding,
      }}
    >
      {children}
    </ProjectItemContext.Provider>
  );
};

export const useBuyoutProjectItemsWithUpdates = () => {
  return useContext(ProjectItemContext);
};
