import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  ProjectDocument,
  useCopyEstimatedItemsMutation,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useProjectEstimatedItemsCount } from "../../projects/hooks/useProjectEstimatedItemsCount";
import { useProject } from "../providers/ProjectProvider";

type ImportItemsFormValues = {
  sourceProjectId: string;
  replace: boolean;
};

export const useImportItems = () => {
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const { setError } = useGlobalError();
  const { project } = useProject();
  const { projects, loading: loadingProjectOptions } = useProjectListOptions();

  const [copyEstimatedItemsMutation, { loading: importingProjects }] =
    useCopyEstimatedItemsMutation();

  const form = useForm<ImportItemsFormValues>({
    defaultValues: {
      sourceProjectId: undefined,
      replace: false,
    },
  });

  const sourceProjectId = form.watch("sourceProjectId");
  const { estimatedItemsCount } =
    useProjectEstimatedItemsCount(sourceProjectId);

  const projectOptions = useMemo(
    () =>
      projects
        .filter((p) => p.id !== project?.id)
        .map((p) => ({
          id: p.id,
          name: `${p.name}${p.id === sourceProjectId && estimatedItemsCount ? ` (${estimatedItemsCount})` : ""}`,
        })),
    [projects, project?.id, sourceProjectId, estimatedItemsCount],
  );

  const importItems = useCallback(
    (onSuccess: () => void) => {
      form.handleSubmit(async (values) => {
        try {
          if (!project?.id) {
            return;
          }
          const { data, errors } = await copyEstimatedItemsMutation({
            variables: {
              input: {
                destinationProjectId: project.id,
                sourceProjectId: values.sourceProjectId,
                replace: values.replace,
              },
            },
            refetchQueries: [ProjectDocument],
          });
          if (errors) {
            setError(errors);
          }
          if (data?.copyEstimatedItems) {
            onSuccess();
            const sourceProject = projects.find(
              (project) => project.id === values.sourceProjectId,
            );
            setSuccessAlert(
              intl.$t(
                { id: "ITEMS_IMPORTED_FROM_BILL_OF_MATERIALS" },
                {
                  count: data.copyEstimatedItems.length,
                  sourceProjectName: sourceProject
                    ? `"${sourceProject.name}"`
                    : intl.$t({ id: "SELECTED_PROJECT" }),
                },
              ),
            );
          }
        } catch (error) {
          setError(error);
        }
      })();
    },
    [
      form,
      project?.id,
      intl,
      projects,
      copyEstimatedItemsMutation,
      setError,
      setSuccessAlert,
    ],
  );

  return {
    form,
    importItems,
    importingProjects,
    projectOptions,
    loadingProjectOptions,
  };
};
