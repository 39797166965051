import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { SourceSystem } from "@/generated/graphql";

export const SOURCE_SYSTEM_FEATURES = {
  [SourceSystem.Sage300]: [
    IntegrationFeature.PoWrites,
    IntegrationFeature.InvoiceWrites,
    IntegrationFeature.TemplateStringsStep,
    IntegrationFeature.IncludeCostCodes,
    IntegrationFeature.IncludeCostTypes,
    IntegrationFeature.ExternalPoReadsGlobal,
    IntegrationFeature.OrderItemPOItemReferences,
    IntegrationFeature.SupportPhasesOrExtrasAsZones,
    IntegrationFeature.ImportProjectCostCodes,
    IntegrationFeature.HumanReadableVendorCode,
    IntegrationFeature.InvoiceAccountingDate,
    IntegrationFeature.GLAccountForCostCodes,
    IntegrationFeature.GLAccountForNonConnectedCostCodes,
  ],
  [SourceSystem.Foundation]: [
    IntegrationFeature.GeneralLedgerAccount,
    IntegrationFeature.OrgMaterialsMapping,
    IntegrationFeature.PostInventoryReceipts,
    IntegrationFeature.WarehouseSupport,
    IntegrationFeature.IncludeCostCodes,
    IntegrationFeature.IncludeCostTypes,
    IntegrationFeature.IncludeEquipment,
    IntegrationFeature.IncludeServiceCodes,
    IntegrationFeature.PoWrites,
    IntegrationFeature.ExternalPoReadsGlobal,
    IntegrationFeature.ReceiptsFileBased,
    IntegrationFeature.InvoiceFileExports,
    IntegrationFeature.DisableExternalPoPagination,
    IntegrationFeature.SupportPhasesOrExtrasAsZones,
    IntegrationFeature.GLAccountForCostCodes,
    IntegrationFeature.GLAccountForNonConnectedCostCodes,
    IntegrationFeature.ImportProjectCostCodes,
    IntegrationFeature.HumanReadableVendorCode,
    IntegrationFeature.NonJobPO,
    IntegrationFeature.TemplateStringsStep,
  ],
  [SourceSystem.Cmic]: [
    IntegrationFeature.PoType,
    IntegrationFeature.ExternalPoReadsProjectSpecific,
    IntegrationFeature.PoWrites,
    IntegrationFeature.InvoiceWrites,
    IntegrationFeature.TemplateStringsStep,
    IntegrationFeature.IncludeCostCodes,
    IntegrationFeature.IncludeCostTypes,
    IntegrationFeature.OrderItemPOItemReferences,
    IntegrationFeature.ImportProjectCostCodes,
    IntegrationFeature.HumanReadableVendorCode,
  ],
  [SourceSystem.Sage100]: [
    IntegrationFeature.GeneralLedgerAccount,
    IntegrationFeature.PoType,
    IntegrationFeature.PoWrites,
    IntegrationFeature.OrgMaterialsMapping,
    IntegrationFeature.InvoiceWrites,
    IntegrationFeature.TemplateStringsStep,
    IntegrationFeature.IncludeCostCodes,
    IntegrationFeature.IncludeCostTypes,
    IntegrationFeature.ExternalPoReadsGlobal,
    IntegrationFeature.OrderItemPOItemReferences,
    IntegrationFeature.POAlwaysMutable,
    IntegrationFeature.ImportProjectCostCodes,
    IntegrationFeature.GLAccountForCostCodes,
    IntegrationFeature.GLAccountForNonConnectedCostCodes,
    IntegrationFeature.HumanReadableVendorCode,
    IntegrationFeature.IncludeOrderTypeBillable,
    IntegrationFeature.ProjectsAutoClose,
  ],
  [SourceSystem.Procore]: [
    IntegrationFeature.InvoiceWrites,
    IntegrationFeature.TemplateStringsStep,
    IntegrationFeature.ExternalPoReadsProjectSpecific,
    IntegrationFeature.PoWrites,
    IntegrationFeature.IncludeCostCodes,
    IntegrationFeature.IncludeCostTypes,
    IntegrationFeature.OrderItemPOItemReferences,
    IntegrationFeature.POAlwaysMutable,
    IntegrationFeature.ImportProjectCostCodes,
  ],
  [SourceSystem.Vista]: [
    IntegrationFeature.GlobalPhaseCodesStep,
    IntegrationFeature.POBatching,
    IntegrationFeature.InvoiceBatching,
    IntegrationFeature.InvoiceWrites,
    IntegrationFeature.TemplateStringsStep,
    IntegrationFeature.IncludeCostCodes,
    IntegrationFeature.IncludeCostTypes,
    IntegrationFeature.OrgMaterialsMapping,
    IntegrationFeature.PoWrites,
    IntegrationFeature.ExternalPoReadsGlobal,
    IntegrationFeature.AutoPostInvoices,
    IntegrationFeature.AutoPostPOs,
    IntegrationFeature.OrderItemPOItemReferences,
    IntegrationFeature.InvoiceAutoPost,
    IntegrationFeature.ImportProjectCostCodes,
    IntegrationFeature.IncludeTaxAuthorities,
    IntegrationFeature.HumanReadableVendorCode,
    IntegrationFeature.RemainingCostAmount,
    IntegrationFeature.POItemRemainingAmount,
  ],
  [SourceSystem.QbOnline]: [
    IntegrationFeature.ApLedgerAccountStep,
    IntegrationFeature.ExternalPoReadsGlobal,
    IntegrationFeature.InvoiceWrites,
    IntegrationFeature.TemplateStringsStep,
    IntegrationFeature.IncludeCostCodes,
    IntegrationFeature.OrderItemPOItemReferences,
    IntegrationFeature.POAlwaysMutable,
    IntegrationFeature.IncludeOrderTypeBillable,
    IntegrationFeature.IncludePaymentMethods,
  ],
  [SourceSystem.QbDesktop]: [
    IntegrationFeature.ExternalPoReadsGlobal,
    IntegrationFeature.InvoiceWrites,
    IntegrationFeature.TemplateStringsStep,
    IntegrationFeature.IncludeCostCodes,
    IntegrationFeature.POAlwaysMutable,
    IntegrationFeature.OrgMaterialsMapping,
    IntegrationFeature.IncludeOrderTypeSalesTaxOrgMaterial,
    IntegrationFeature.GeneralLedgerAccount,
    IntegrationFeature.IncludeOrderTypeBillable,
    IntegrationFeature.DefaultItemOrderTypeMapping,
  ],
  [SourceSystem.QbDesktopAlt]: [
    IntegrationFeature.GeneralLedgerAccount,
    IntegrationFeature.ExternalPoReadsGlobal,
    IntegrationFeature.InvoiceWrites,
    IntegrationFeature.TemplateStringsStep,
    IntegrationFeature.IncludeCostCodes,
    IntegrationFeature.POAlwaysMutable,
    IntegrationFeature.IncludeCostTypes,
    IntegrationFeature.OrderItemPOItemReferences,
    IntegrationFeature.IncludeOrderTypeBillable,
  ],
};
