import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { OrgRole, SystemRole } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router";
import tw from "tailwind-styled-components";

const Menu = tw.div`
  grid grid-flow-col mr-auto h-full
`;

type MenuItemProps = {
  $active: boolean;
};

const MenuItem = tw(Link)<MenuItemProps>`
  grid grid-flow-col items-center text-white mr-auto lg:p-4 h-full lg:px-6 px-3 py-5 cursor-pointer text-sm
  hover:bg-blue-500
  ${({ $active }: MenuItemProps) => $active && "bg-blue-400"}
`;

export const MainMenu = () => {
  const location = useLocation();
  const { viewer } = useUser();
  const intl = useIntl();

  const menuItems = useMemo(() => {
    const displayAdminTab =
      viewer?.orgRoles.includes(OrgRole.OrgAdmin) ||
      viewer?.systemRoles.includes(SystemRole.FmAdmin);
    const displayOrgSettingsTab =
      !viewer?.orgRoles.includes(OrgRole.OrgAdmin) &&
      !viewer?.systemRoles.includes(SystemRole.FmAdmin) &&
      !!viewer?.orgRoles.includes(OrgRole.OrgRestrictedAdmin);

    return [
      { path: routes.home, label: intl.$t({ id: "HOME" }) },
      ...(displayAdminTab
        ? [
            {
              path: routes.admin,
              label: intl.$t({ id: "ADMIN" }),
            },
          ]
        : []),
      ...(displayOrgSettingsTab
        ? [
            {
              path: routes.admin,
              label: intl.$t({ id: "ORG_SETTINGS" }),
            },
          ]
        : []),

      {
        path: "https://www.fieldmaterials.com/knowledge-base/introduction?pw",
        label: intl.$t({ id: "TUTORIALS" }),
        target: "_blank",
      },
    ];
  }, [viewer?.orgRoles, viewer?.systemRoles, intl]);

  return (
    <Menu>
      {menuItems.map(({ path, label, target }) => (
        <MenuItem
          key={path}
          to={path}
          target={target}
          $active={location.pathname.includes(path)}
        >
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
};
