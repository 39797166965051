import { Select } from "@/common/components/select/components/single/Select";
import {
  ToggleFilterItems,
  ToggleFilterType,
} from "@/common/components/toggle-filter-items/ToggleFilterItems";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../../../providers/InvoiceVerificationProvider";
import { useInvoiceReleaseItemsFilterHeader } from "./hooks/useInvoiceReleaseItemsFilterHeader";

const Container = tw.div`
  grid grid-flow-col gap-4 justify-end text-xs font-normal items-center my-1
`;

const SortContainer = tw.div`
  grid grid-flow-col gap-2 justify-end text-xs font-normal items-center my-1
`;

export const InvoiceReleaseItemsFilterHeader: FC = () => {
  const { showOnlyInvoicedItems, setShowOnlyInvoicedItems, sortingOrder } =
    useInvoiceVerification();
  const { sortOptions, onSortChange } = useInvoiceReleaseItemsFilterHeader();

  return (
    <Container>
      <ToggleFilterItems
        onToggle={setShowOnlyInvoicedItems}
        show={showOnlyInvoicedItems}
        type={ToggleFilterType.Invoice}
      />
      <SortContainer>
        <FormattedMessage id="SORT_ITEMS_BY" />
        <Select
          options={sortOptions}
          getLabel={(option) => option.label}
          getValue={(option) => option.value}
          value={sortingOrder}
          onChange={onSortChange}
        />
      </SortContainer>
    </Container>
  );
};
