import {
  AdditionalChargesItemContainer,
  TotalItemContainer,
  TotalItemOuter,
} from "@/common/components/additional-charges/AdditionalCharges.styles";
import { If } from "@/common/components/if/If";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

import { Price } from "@/common/components/price/Price";
import { TOTAL_PRICE_DECIMAL_POINTS } from "@/common/const";
import { useInvoicePricing } from "../../../hooks/useInvoicePricing";
import {
  Column,
  ContentColumn,
  QuarterPanel,
} from "./InvoiceExpandable.styled";

const TotalItem = tw.div`-mr-1 flex flex-row gap-1 justify-end items-center`;
const Item = tw.div`flex flex-row gap-1 justify-end items-center font-medium text-sm`;

type Props = {
  display: boolean;
};

export const ReleaseUninvoicedPanel: FC<Props> = ({ display }) => {
  const { uninvoicedBalance, invoicedSoFar } = useInvoicePricing();

  return (
    <QuarterPanel
      className={
        display
          ? "border-2 border-dashed border-gray-300 bg-gray-200"
          : "bg-blue-250"
      }
    >
      <If isTrue={display}>
        <Column />
        <ContentColumn>
          <AdditionalChargesItemContainer className="pr-14">
            <Item>
              <FormattedMessage id="TOTAL_INVOICES_FOR_THIS_ORDER" />
            </Item>
            <Price
              price={invoicedSoFar}
              className="text-sm font-normal"
              maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
            />
          </AdditionalChargesItemContainer>
          <TotalItemOuter className="-mr-3 -mt-4">
            <TotalItemContainer $highlightTotal className="h-7 pr-9 pt-1">
              <TotalItem>
                <FormattedMessage id="REMAINING_ORDER_BALANCE" />
              </TotalItem>
              <Price
                price={uninvoicedBalance}
                className="font-medium"
                maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
              />
            </TotalItemContainer>
          </TotalItemOuter>
        </ContentColumn>
      </If>
    </QuarterPanel>
  );
};
