import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { checkboxRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { rowIsEmpty } from "../utils/rowIsEmpty";

export const useCheckboxRenderer = () => {
  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const rows = instance?.getData();
      if (rowIsEmpty(rows[row])) {
        td.innerHTML = "";
      } else {
        checkboxRenderer(instance, td, row, col, prop, value, cellProperties);
      }
    },
    [],
  );

  return renderer;
};
