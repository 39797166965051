import { useDialog } from "@/common/components/dialog/DialogProvider";
import {
  ExportCompleted,
  ExportType,
} from "@/common/components/export-completed/ExportCompleted";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { IntegrationType } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExportReceiptProvider } from "../../providers/ExportReceiptProvider";
import { ReceiptsByIdsProvider } from "../../providers/ReceiptsByIdsProvider";
import { ReceiptConnectionOptions } from "./ReceiptConnectionOptions";
import { ReceiptsExport } from "./ReceiptsExport";
import { useExportReceiptsPanel } from "./useExportReceiptsPanel";

const CardList = tw.div`flex flex-col gap-3`;

type Props = {
  onClose: () => void;
  receiptIds: string[];
};

const ExportReceiptsPanelWithProvider: FC<Props> = ({ onClose }) => {
  const { openDialog } = useDialog();

  const {
    onSave,
    receiptsToExportCount,
    loading,
    receiptsByIds,
    connectedIntegrationType,
    postingDate,
  } = useExportReceiptsPanel({
    onExportCompleted: () => {
      openDialog({
        content: (
          <ExportCompleted
            count={receiptsToExportCount}
            type={ExportType.INVOICE}
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        hideTitle: true,
      });
      onClose();
    },
  });
  const intl = useIntl();

  return (
    <OverlayPanel
      title={intl.$t({ id: "EXPORT_RECEIPTS" })}
      isLoading={loading && receiptsByIds.length === 0}
      onCancel={onClose}
      onSave={onSave}
      saveLabel={intl.$t(
        {
          id:
            !receiptsToExportCount || receiptsByIds.length > 1
              ? "EXPORT_WITH_NUMBER"
              : "EXPORT",
        },
        { number: receiptsToExportCount },
      )}
      disableSave={
        receiptsToExportCount === 0 ||
        (connectedIntegrationType === IntegrationType.Foundation &&
          !postingDate)
      }
    >
      <CardList>
        <ReceiptConnectionOptions />
        <ReceiptsExport receipts={receiptsByIds} />
      </CardList>
    </OverlayPanel>
  );
};

export const ExportReceiptsPanel: FC<Props> = (props) => (
  <ReceiptsByIdsProvider receiptIds={props.receiptIds}>
    <ExportReceiptProvider>
      <ExportReceiptsPanelWithProvider {...props} />
    </ExportReceiptProvider>
  </ReceiptsByIdsProvider>
);
