import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Progress } from "@/common/components/progress/Progress";
import { ExportBatchProvider } from "@/common/providers/ExportBatchProvider";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { MissingBudgetCombinationsProvider } from "@/contractor/pages/home/release/components/connections/providers/MissingBudgetCombinationsProvider";
import { BatchType } from "@/generated/graphql";
import { FC } from "react";
import { FormattedNumber, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ExportInvoiceProvider,
  useExportInvoice,
} from "../../../providers/ExportInvoiceProvider";
import {
  InvoicesByIdsProvider,
  useInvoicesByIds,
} from "../../../providers/InvoicesByIdsProvider";
import { InvoiceAgaveConnections } from "../invoice-agave-connections/InvoiceAgaveConnections";
import { InvoiceConnectionOptions } from "../invoice-connection-options/InvoiceConnectionOptions";
import { useConnectInvoicesPanel } from "./hooks/useConnectInvoicesPanel";
import { ConnectInvoicesPanelProps } from "./types/ConnectInvoicesPanelProps";
const OverlayPanelStyled = tw(OverlayPanel)`justify-between gap-3`;
const CardList = tw.div`flex flex-col gap-3`;
const PanelTitle = tw.div`flex flex-row gap-1 items-center`;

const ConnectInvoicesPanelWithProvider: FC<ConnectInvoicesPanelProps> = ({
  onClose,
}) => {
  const intl = useIntl();
  const { invoiceIds, loading } = useInvoicesByIds();
  const { linking } = useExportInvoice();
  useIntegrationFeatureRequirement();

  const { disableSave, onSave } = useConnectInvoicesPanel({ onClose });

  return (
    <OverlayPanelStyled
      title={
        <PanelTitle>
          {intl.$t({ id: "CONNECT_INVOICE_PANEL_TITLE" })}{" "}
          <InfoTooltip
            message={intl.$t({ id: "CONNECT_INVOICE_TITLE_INFO" })}
            id="title"
            iconClassName="text-white"
          />
        </PanelTitle>
      }
      isLoading={loading}
      onCancel={onClose}
      onSave={onSave}
      saveLabel={intl.$t({ id: "EXPORT" })}
      disableSave={disableSave}
    >
      <CardList>
        <InvoiceConnectionOptions
          invoice={invoiceIds[0]}
          mode={ConnectionMode.Connect}
        />
        <InvoiceAgaveConnections invoices={invoiceIds} linking={linking} />
      </CardList>
      <If isTrue={linking.linking && invoiceIds.length > 1}>
        <Progress
          progress={linking.percentage}
          label={intl.$t(
            { id: "EXPORTING_WITH_VALUE" },
            {
              value: (
                <FormattedNumber
                  value={linking.percentage * 100}
                  maximumFractionDigits={0}
                />
              ),
            },
          )}
        />
      </If>
    </OverlayPanelStyled>
  );
};

export const ConnectInvoicesPanel: FC<ConnectInvoicesPanelProps> = (props) => (
  <InvoicesByIdsProvider invoiceIds={props.invoiceIds}>
    <ExportBatchProvider type={BatchType.Invoice}>
      <ExportInvoiceProvider>
        <MissingBudgetCombinationsProvider>
          <ConnectInvoicesPanelWithProvider {...props} />
        </MissingBudgetCombinationsProvider>
      </ExportInvoiceProvider>
    </ExportBatchProvider>
  </InvoicesByIdsProvider>
);
