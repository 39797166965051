import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback, useMemo } from "react";
import { MatchedOrderViewState } from "../../../../../../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../../../providers/InvoiceVerificationProvider";

export const useInvoiceItemCoverageWarning = () => {
  const { release } = useRelease();
  const { invoice, setFooterState } = useInvoiceVerification();
  const { setMatchedOrderViewState, setAddMissingInvoiceItems } =
    useInvoiceMatchedOrder();

  const invoiceItemsOrderItemsDiff = useMemo(
    () =>
      !!invoice?.release
        ? invoice.items.length - (release?.items ?? []).length
        : 0,
    [release, invoice?.release, invoice?.items.length],
  );

  const addOrderMissingItems = useCallback(() => {
    setMatchedOrderViewState(MatchedOrderViewState.EDIT_ORDER);
    setFooterState(InvoiceFooterState.EDIT_ORDER);
    setTimeout(() => {
      setAddMissingInvoiceItems(true);
    }, 1000);
  }, [setMatchedOrderViewState, setFooterState, setAddMissingInvoiceItems]);

  return { invoiceItemsOrderItemsDiff, addOrderMissingItems };
};
