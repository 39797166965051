import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CustomPaymentTermsProps } from "../types/CustomPaymentTermsProps";

const PAYMENT_OPTIONS = [10, 15, 30, 40, 45];

export const useCustomPaymentTerms = ({
  paymentTerm,
  updateItem,
  staticText,
  readonly,
}: Pick<
  CustomPaymentTermsProps,
  "paymentTerm" | "updateItem" | "staticText" | "readonly"
>) => {
  const intl = useIntl();

  const [input, setInput] = useState(
    paymentTerm ? `${intl.$t({ id: "NET" })} ${paymentTerm}` : "",
  );

  useEffect(() => {
    setInput(paymentTerm ? `${intl.$t({ id: "NET" })} ${paymentTerm}` : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTerm]);

  const generateOptions = useCallback(() => {
    const options = [
      { label: intl.$t({ id: "PAY_UPON_DELIVERY" }), id: "0" },
      ...PAYMENT_OPTIONS.map((count) => ({
        label: intl.$t({ id: "DAYS_COUNT" }, { count }),
        id: count.toString(),
      })),
    ];
    return options;
  }, [intl]);

  const paymentTermOptions = useMemo(() => {
    const options = generateOptions();
    if (
      paymentTerm &&
      !options.some((option) => option.id === paymentTerm) &&
      !isNaN(parseInt(paymentTerm))
    ) {
      options.push({
        label: intl.$t({ id: "DAYS_COUNT" }, { count: parseInt(paymentTerm) }),
        id: paymentTerm,
      });
    }
    return options;
  }, [generateOptions, paymentTerm, intl]);

  const handlePaymentTermChange = useCallback(
    (value: string | null) => {
      const parsedValue = value ? parseInt(value) : 0;
      updateItem?.(parsedValue.toString());
    },
    [updateItem],
  );

  const onBlur = useCallback(() => {
    if (input !== `${intl.$t({ id: "NET" })} ${paymentTerm}`) {
      handlePaymentTermChange(input);
    }
  }, [paymentTerm, input, intl, handlePaymentTermChange]);

  const value = useMemo(
    () =>
      paymentTerm
        ? staticText
          ? `${intl.$t({ id: "NET" })} ${paymentTerm}`
          : intl.$t({ id: "DAYS_COUNT" }, { count: paymentTerm })
        : intl.$t({ id: "UNSPECIFIED" }),
    [paymentTerm, staticText, intl],
  );

  const label = useMemo(() => {
    if (!readonly && !staticText) {
      return paymentTerm
        ? `${intl.$t({ id: "NET" })} ${paymentTerm}`
        : intl.$t({ id: "SELECT" });
    }
    return staticText
      ? ""
      : intl.$t({
          id: "NET",
        });
  }, [readonly, staticText, intl, paymentTerm]);

  const customPaymentTermsCreatableFn = useCallback(
    (_: string, text: string | undefined) => {
      const count = parseInt(text ?? "");
      if (text && !isNaN(count)) {
        return {
          id: text,
          label: intl.$t(
            {
              id: "SELECT_CUSTOM",
            },
            {
              option: intl.$t({ id: "DAYS_COUNT" }, { count }),
            },
          ),
        };
      }
      return undefined;
    },
    [intl],
  );

  return {
    input,
    setInput,
    paymentTermOptions,
    handlePaymentTermChange,
    onBlur,
    value,
    label,
    customPaymentTermsCreatableFn,
  };
};
