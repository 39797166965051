import { useCallback } from "react";
import { useBuyoutSequence } from "../hooks/useBuyoutSequence";
import { BuyoutCheckboxProps } from "./BuyoutCheckbox";

export const useBuyoutCheckbox = ({ buyout }: BuyoutCheckboxProps) => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useBuyoutSequence();

  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedEntities(
        newChecked
          ? [...selectedEntities, buyout]
          : selectedEntities.filter((d) => d.id !== buyout.id),
      );
    },
    [buyout, selectedEntities, setSelectedEntities],
  );

  return { sequenceIds, handleChange };
};
