import { FC, PropsWithChildren } from "react";
import { useInventoryManagementFeature } from "../../hooks/useInventoryManagementFeature";

export const IncludeInventoryManagement: FC<PropsWithChildren> = ({
  children,
}) => {
  const { includeInventoryManagement } = useInventoryManagementFeature();

  if (!includeInventoryManagement) {
    return null;
  }

  return <>{children}</>;
};
