import { CategoryState } from "@/common/hooks/useToggleCategory";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { useInvoiceValidation } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceValidationProvider";
import { useOrderItemPoItemReferences } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderItemPoItemReferences";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback, useMemo } from "react";

export const useInvoiceReleaseItemCostCodeCheckbox = (
  costCode: CategoryState<ExpandedReleaseItem>,
) => {
  const { updateInvoice } = useInvoiceVerification();
  const { invoice } = useInvoiceVerification();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const { release } = useRelease();

  const checked = useMemo(
    () => costCode.items.every((item) => (item.invoiceItems || [])?.length > 0),
    [costCode.items],
  );
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();

  const handleChange = useCallback(
    async (newChecked: boolean) => {
      const addedInvoicedReleaseItems = newChecked
        ? costCode.items
            .filter((item) => (item.invoiceItems || []).length === 0)
            .filter((item) => !hasOrderItemPoItemReferences(item, release))
            .map((item) => ({
              id: item.id,
              releaseItemId: item.id,
              quantity: item.quantityDecimal || "0",
              unitPrice: item.unitPrice
                ? item.unitPrice.toString()
                : isLumpSumItem(item)
                  ? "1"
                  : "0",
            }))
        : undefined;
      const removedInvoicedReleaseItems = !newChecked
        ? (costCode.items
            .map((item) => item.invoiceItems?.[0].id)
            .filter((id) => id) as string[])
        : undefined;
      await updateInvoice(
        {
          id: invoice?.id || "",
          addedInvoicedReleaseItems,
          removedInvoicedReleaseItems,
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
      refetchInvoiceValidation();
    },
    [
      costCode.items,
      hasOrderItemPoItemReferences,
      invoice?.id,
      invoice?.release?.id,
      release,
      updateInvoice,
      refetchInvoiceValidation,
    ],
  );

  const hasSelectableItems = useMemo(
    () =>
      !costCode.items.some((item) =>
        hasOrderItemPoItemReferences(item, release),
      ),
    [costCode.items, hasOrderItemPoItemReferences, release],
  );

  return {
    checked,
    handleChange,
    hasSelectableItems,
  };
};
