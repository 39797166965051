import { routes } from "@/config/routes";
import { RfqFieldsFragment, RfqStatus } from "@/generated/graphql";
import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router";

export const useEnsureRfqStatus = (
  rfq: Pick<RfqFieldsFragment, "id" | "status"> | null,
  ...statuses: RfqStatus[]
) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (rfq && !statuses.includes(rfq.status)) {
      navigate(generatePath(routes.rfqById, { id: rfq.id }));
    }
  }, [rfq, navigate, statuses]);
};
