import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  NestedWizardModalPage,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { UpdateSourceSystemsSettingsInput } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useAgaveHostedWizard } from "../../../AgaveHostedWizardProvider";
import { AgaveWizardProjectsAutoCloseSettings } from "../../../steps/projects-auto-close-settings/AgaveWizardProjectsAutoCloseSettings";

export const useProjectsAutocloseSettingsPage = (): WizardModalPage => {
  const intl = useIntl();
  const { moveToPreviousStep, moveToNextStep } = useNestedStepper();
  const { handleSaveWizardData, autoCloseProjectsByJobStatus } =
    useAgaveHostedWizard();

  const { connectedSourceSystem } = useOrgSettingsExtended();

  const onNextClick = useCallback(async () => {
    const settings: Omit<UpdateSourceSystemsSettingsInput, "system"> = {
      autoCloseProjectsByJobStatus,
    };
    await handleSaveWizardData(settings);
    moveToNextStep();
  }, [autoCloseProjectsByJobStatus, handleSaveWizardData, moveToNextStep]);

  return useMemo(() => {
    const pages: NestedWizardModalPage[] = [
      {
        component: <AgaveWizardProjectsAutoCloseSettings />,
        onPreviousClick: moveToPreviousStep,
        onNextClick,
      },
    ];

    return {
      feature: IntegrationFeature.ProjectsAutoClose,
      title: intl.$t({ id: "PROJECTS_AUTO_CLOSE_SETTINGS" }),
      completed: connectedSourceSystem?.autoCloseProjectsByJobStatus !== null,
      pages,
    };
  }, [
    moveToPreviousStep,
    onNextClick,
    intl,
    connectedSourceSystem?.autoCloseProjectsByJobStatus,
  ]);
};
