import {
  AddressFieldsFragment,
  WarehouseFieldsFragment,
} from "@/generated/graphql";
import { InputBaseComponentProps } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { SelectControlled } from "../select/components/single/SelectControlled";
import { WarehouseSelectorCustomRenderer } from "./WarehouseSelectorCustomRenderer";
import { useWarehouseOptions } from "./useWarehouseOptions";

type Props = {
  name: string;
  options: WarehouseFieldsFragment[];
  projectAddress?: AddressFieldsFragment;
  readonly?: boolean;
  inputProps?: InputBaseComponentProps | undefined;
  disableClearable?: boolean;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
};

export const WarehouseSelectorControlled: FC<Props> = ({
  name,
  options,
  projectAddress,
  readonly = false,
  inputProps,
  disableClearable,
  placeholder,
  required = true,
  loading = false,
}) => {
  const intl = useIntl();
  const { warehouseOptions } = useWarehouseOptions(options, projectAddress);

  return (
    <SelectControlled
      name={name}
      options={warehouseOptions}
      placeholder={placeholder ?? intl.$t({ id: "FULFILLMENT_LOCATION" })}
      inputProps={inputProps}
      getLabel={(option: WarehouseFieldsFragment) => option.name}
      getValue={(option: WarehouseFieldsFragment) => option.id}
      customRender={WarehouseSelectorCustomRenderer}
      required={required}
      disabled={readonly}
      disableClearable={disableClearable}
      loading={loading}
    />
  );
};
