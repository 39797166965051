import { useUser } from "@/common/providers/UserProvider";
import { CustomFilterContainer } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/list/invoice-additional-filters/InvoiceAdditionalFilters";
import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  AdditionalFiltersOverlay,
  DividerLine,
} from "../additional-filters-overlay/AdditionalFiltersOverlay";
import { If } from "../if/If";
import { Multiselect } from "../select/components/multiple/Multiselect";
import {
  KickbackFilter,
  KickbackFilterOption,
  KickbackInvoiceFilters,
  useKickbackFilters,
} from "./useKickbackFilters";

const KickbackFilterItem = tw.div`py-1 w-full ${({ $isItalic }: { $isItalic: boolean }) => ($isItalic ? "italic" : "")}`;

type Props = {
  filter: KickbackInvoiceFilters | undefined;
  setFilter: (filter: KickbackInvoiceFilters) => void;
};

export const KickbackFilters: FC<Props> = ({ filter, setFilter }) => {
  const { viewer } = useUser();

  const {
    kickbackOptions,
    handleKickbackToChange,
    loading,
    handleKickbackByChange,
    kickbackToFilter,
    kickbackByFilter,
  } = useKickbackFilters({ filter, setFilter });

  const renderFilterItem = useCallback(
    (item: KickbackFilterOption) => (
      <KickbackFilterItem
        $isItalic={[viewer?.id, KickbackFilter.ANYONE].includes(item.id)}
      >
        {item.label}
      </KickbackFilterItem>
    ),
    [viewer?.id],
  );

  return (
    <div
      className={`flex flex-col gap-2 ${filter?.hasKickback ? "-mx-2 -my-2 mb-1 bg-gray-100 px-2 py-2" : ""}`}
    >
      <CustomFilterContainer>
        <AdditionalFiltersOverlay.Name>
          <FormattedMessage id="INVOICE_KICKBACK" />
        </AdditionalFiltersOverlay.Name>
        <AdditionalFiltersOverlay.Configurations>
          <AdditionalFiltersOverlay.Configuration
            $active={AdditionalFiltersOverlay.isYes(
              filter?.hasKickback === true,
            )}
            onClick={() =>
              setFilter({
                ...filter,
                hasKickback: true,
              })
            }
          >
            <FormattedMessage id="YES" />
          </AdditionalFiltersOverlay.Configuration>
          <AdditionalFiltersOverlay.Configuration
            $active={AdditionalFiltersOverlay.isYes(
              filter?.hasKickback === false,
            )}
            onClick={() =>
              setFilter({
                ...filter,
                hasKickback: false,
                kickbackByUserIds: undefined,
                kickbackToUserIds: undefined,
                kickbackResolved: undefined,
              })
            }
          >
            <FormattedMessage id="NO" />
          </AdditionalFiltersOverlay.Configuration>
          <AdditionalFiltersOverlay.Configuration
            $active={AdditionalFiltersOverlay.isYes(
              filter?.hasKickback == null,
            )}
            onClick={() =>
              setFilter({
                ...filter,
                hasKickback: undefined,
                kickbackByUserIds: undefined,
                kickbackToUserIds: undefined,
                kickbackResolved: undefined,
              })
            }
            $isDefault
          >
            <FormattedMessage id="NO_FILTER" />
          </AdditionalFiltersOverlay.Configuration>
        </AdditionalFiltersOverlay.Configurations>
      </CustomFilterContainer>
      <If isTrue={filter?.hasKickback}>
        <>
          <DividerLine
            className={`mb-0 mt-0 ${filter?.hasKickback ? "border-gray-500" : ""}`}
          />
          <CustomFilterContainer>
            <AdditionalFiltersOverlay.Name>
              <FormattedMessage id="FILTER_KICKBACK_RESOLVED" />
            </AdditionalFiltersOverlay.Name>
            <AdditionalFiltersOverlay.Configurations>
              <AdditionalFiltersOverlay.Configuration
                $active={AdditionalFiltersOverlay.isYes(
                  filter?.kickbackResolved === true,
                )}
                onClick={() =>
                  setFilter({
                    ...filter,
                    kickbackResolved: true,
                  })
                }
              >
                <FormattedMessage id="YES" />
              </AdditionalFiltersOverlay.Configuration>
              <AdditionalFiltersOverlay.Configuration
                $active={AdditionalFiltersOverlay.isYes(
                  filter?.kickbackResolved === false,
                )}
                onClick={() =>
                  setFilter({
                    ...filter,
                    kickbackResolved: false,
                  })
                }
              >
                <FormattedMessage id="NO" />
              </AdditionalFiltersOverlay.Configuration>
              <AdditionalFiltersOverlay.Configuration
                $active={AdditionalFiltersOverlay.isYes(
                  filter?.kickbackResolved == null,
                )}
                onClick={() =>
                  setFilter({
                    ...filter,
                    kickbackResolved: undefined,
                  })
                }
                $isDefault
              >
                <FormattedMessage id="NO_FILTER" />
              </AdditionalFiltersOverlay.Configuration>
            </AdditionalFiltersOverlay.Configurations>
          </CustomFilterContainer>
          <CustomFilterContainer>
            <AdditionalFiltersOverlay.Name>
              <FormattedMessage id="KICKBACK_BY" />
            </AdditionalFiltersOverlay.Name>
            <Multiselect
              options={kickbackOptions}
              getLabel={(option) => option.label}
              getValue={(option) => option.id}
              loading={loading}
              values={kickbackByFilter}
              onMultipleChange={handleKickbackByChange}
              includeCheckbox
              limitTags={1}
              chipSize="small"
              disableCloseOnSelect
              customRender={renderFilterItem}
              className="flex-1"
            />
          </CustomFilterContainer>
          <CustomFilterContainer>
            <AdditionalFiltersOverlay.Name>
              <FormattedMessage id="KICKBACK_TO" />
            </AdditionalFiltersOverlay.Name>
            <Multiselect
              options={kickbackOptions}
              getLabel={(option) => option.label}
              getValue={(option) => option.id}
              loading={loading}
              values={kickbackToFilter}
              onMultipleChange={handleKickbackToChange}
              includeCheckbox
              limitTags={1}
              chipSize="small"
              disableCloseOnSelect
              customRender={renderFilterItem}
              className="flex-1"
            />
          </CustomFilterContainer>
        </>
      </If>
    </div>
  );
};
