import { If } from "@/common/components/if/If";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { InvoiceIssues } from "@/common/components/invoices/invoice-issue/InvoiceIssues";
import { Loader } from "@/common/components/loader/Loader";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { ValidationType } from "@/contractor/pages/home/release/components/connections/components/common/ExportErrorList";
import { InvoiceExportErrorList } from "@/contractor/pages/home/release/components/connections/components/common/InvoiceExportErrorList";
import { ValidationItem } from "@/contractor/pages/home/release/components/connections/components/common/ValidationItem";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceValidation } from "../../../../../scanned-invoices/providers/InvoiceValidationProvider";
import { useInvoiceReleaseValidation } from "../../../../hooks/useInvoiceValidation";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";
import { InvoiceItemCoverageWarning } from "./invoice-item-coverage-warning/InvoiceItemCoverageWarning";
import { InvoiceOrderInputSelector } from "./invoice-order-selector/InvoiceOrderInputSelector";

const Container = tw.div``;
const Header = tw.div`grid grid-cols-3 pt-5 px-4`;
const OrderSelectorGroup = tw.div`grid content-start gap-3 pt-2`;
const OrderNumberHeader = tw.div`grid grid-flow-col justify-start gap-1 place-items-center text-base font-medium`;
const Success = tw(CheckCircleOutlineOutlined)`text-green-800`;
const OrderSelectorInnerContainer = tw.div`flex flex-row items-center`;

export const InvoiceMatchedHeader: FC = () => {
  const { invoice } = useInvoiceVerification();
  const { loading } = useInvoiceValidation();
  const { validations } = useInvoiceReleaseValidation(
    invoice,
    invoice?.poNumber || invoice?.release?.poNumber?.toString() || "",
  );

  const invoiceHasMoreItemsThanInvoiceOrder = useMemo(
    () => {
      // TODO: Disable this for now as we have issues with that
      // return !!invoice?.release && release
      //   ? invoice.items.length > (release?.items ?? []).length
      //   : false
      return false;
    },
    // TODO: Disable this for now as we have issues with that
    // [invoice?.release, invoice?.items.length, release],
    [],
  );

  return (
    <Container>
      <Header>
        <OrderSelectorGroup>
          <If isTrue={invoice?.release}>
            <FormattedMessage id="YOUR_ORDER" tagName={OrderNumberHeader} />
          </If>
          <OrderSelectorInnerContainer>
            <InvoiceOrderInputSelector>
              <Loader className="h-6 w-6" small loading={loading}>
                <If isTrue={validations.length === 0}>
                  <Success className="h-6 w-6" />
                </If>
              </Loader>
            </InvoiceOrderInputSelector>
          </OrderSelectorInnerContainer>
        </OrderSelectorGroup>
        <If isTrue={invoice?.release}>
          <OrgDetailsHeaderItem
            title={<FormattedMessage id="VENDOR" />}
            name={
              <VendorList
                contacts={invoice?.release?.preferredVendor?.contacts.filter(
                  (c) => c.receivesInvoiceNotifications,
                )}
              />
            }
            orgName={invoice?.release?.sellerOrgLocation?.org.name}
            logoImageUrl={invoice?.release?.sellerOrgLocation?.org.photoUrl}
            address={invoice?.release?.sellerOrgLocation?.address}
          />
          <OrgDetailsHeaderItem
            title={<FormattedMessage id="Project" />}
            name={invoice?.release?.project?.name}
            address={invoice?.release?.project?.address}
            warehouseId={invoice?.release?.warehouse?.id}
          />
        </If>
      </Header>
      <InvoiceExportErrorList
        includedValidations={validations.filter(
          (validation) =>
            !validation.validationType ||
            validation.validationType === ValidationType.Error,
        )}
        classes={{ item: "text-xs" }}
        includeDivider={false}
        warnings={[
          ...(invoiceHasMoreItemsThanInvoiceOrder
            ? [
                {
                  id: "invoice-item-coverage-warning",
                  clearable: true,
                  child: <InvoiceItemCoverageWarning />,
                },
              ]
            : []),
          ...validations
            .filter(
              (validation) =>
                validation.validationType === ValidationType.Warning &&
                validation.alwaysInclude,
            )
            .map((validation) => ({
              id: validation.id,
              child: <ValidationItem validation={validation} grayed={false} />,
              excludeIcon: true,
            })),
        ]}
      />
      <InvoiceIssues invoice={invoice} />
    </Container>
  );
};
