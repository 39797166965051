import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useMemo } from "react";

export const useInvoiceReleaseItemMaterial = (item: ExpandedReleaseItem) => {
  const itemPhaseCode = useMemo(() => {
    return item.tags.find((tag) => tag.hasMapping)?.name;
  }, [item.tags]);

  return {
    itemPhaseCode,
  };
};
