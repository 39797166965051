import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import {
  FormattedCostCode,
  useStartupDataStore,
} from "@/common/stores/useStartupDataStore";
import {
  OrgLocationFieldsFragment,
  ProjectAllowanceFieldsFragment,
  ProjectBudgetLinkFieldsFragment,
  ProjectCostCodeFieldsFragment,
  ProjectStoreDocument,
  ProjectStoreQuery,
  TagExtendedFieldsFragment,
  ZoneExtendedFieldsFragment,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  currentProjectId: string | null | undefined;
  zones: ZoneExtendedFieldsFragment[];
  tags: TagExtendedFieldsFragment[];
  phaseCodes: TagExtendedFieldsFragment[];
  costCodes: FormattedCostCode[];
  restrictCostCodes: boolean;
  allowance: ProjectAllowanceFieldsFragment;
  budgetLink: ProjectBudgetLinkFieldsFragment | null;
  location: OrgLocationFieldsFragment | null;
  loading: boolean;
  error: Error | null;
  setCurrentProjectId: (projectId: string | null | undefined) => void;
  updateStoreData: (data: Partial<State>) => void;
};

const sortByName = (a: { name: string }, b: { name: string }) =>
  a.name.localeCompare(b.name);

const filterTags = (tags: TagExtendedFieldsFragment[] | undefined) =>
  tags?.filter((t: TagExtendedFieldsFragment) => !t.hasMapping) || [];

const filterPhaseCodes = (tags: TagExtendedFieldsFragment[] | undefined) =>
  tags?.filter((t: TagExtendedFieldsFragment) => t.hasMapping) || [];

export const useProjectStore = create<State>()(
  devtools((set, get) => ({
    currentProjectId: null,
    tags: [],
    phaseCodes: [],
    zones: [],
    costCodes: [],
    location: null,
    restrictCostCodes: false,
    allowance: {
      amount: "",
      costCodes: [],
      tags: [],
    },
    budgetLink: {
      autoSync: false,
      syncedAt: null,
      autoSyncError: null,
    },
    projectsMap: {},
    loading: false,
    error: null,
    setCurrentProjectId: async (projectId: string | null | undefined) => {
      if (!projectId || projectId === get().currentProjectId) {
        return;
      }
      const { client } = useApolloClientStore.getState();
      if (!client) {
        return;
      }
      const { formattedCostCodes } = useStartupDataStore.getState();
      set({
        loading: true,
        currentProjectId: projectId,
        allowance: {
          amount: "",
          costCodes: [],
          tags: [],
        },
      });
      try {
        const result = await client.query<ProjectStoreQuery>({
          query: ProjectStoreDocument,
          variables: { id: projectId },
          fetchPolicy: "network-only",
        });

        if (!result.data?.project) {
          return;
        }

        const project = result.data.project;
        set({
          zones: project.zones.toSorted(sortByName),
          tags: filterTags(project.tags).toSorted(sortByName),
          phaseCodes: filterPhaseCodes(project.tags).toSorted(sortByName),
          costCodes: formattedCostCodes.filter((cc) =>
            project.costCodes?.some(
              (pc: ProjectCostCodeFieldsFragment) => pc.id === cc.id,
            ),
          ),
          restrictCostCodes: project.restrictCostCodes,
          location: project.location,
          allowance: project.allowance,
          budgetLink: project.budgetLink,
          loading: false,
        });
      } catch (error) {
        set({ error: error as Error, loading: false });
      }
    },
    updateStoreData: (data: Partial<State>) => {
      const { formattedCostCodes } = useStartupDataStore.getState();
      set((state) => ({
        ...state,
        ...data,
        restrictCostCodes: data.restrictCostCodes ?? state.restrictCostCodes,
        tags: data.tags
          ? filterTags(data.tags).toSorted(sortByName)
          : state.tags,
        phaseCodes: data.tags
          ? filterPhaseCodes(data.tags).toSorted(sortByName)
          : state.phaseCodes,
        zones: (data.zones || state.zones).toSorted(sortByName),
        costCodes: formattedCostCodes.filter((cc) =>
          (
            data.costCodes ||
            data.allowance?.costCodes.map((ac) => ac.costCode) ||
            state.costCodes
          ).some((code) => code.id === cc.id),
        ),
      }));
    },
  })),
);
