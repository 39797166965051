import { useImportExternalPoStore } from "@/common/components/import-external-po/stores/useImportExternalPoStore";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useEnabledPoInSourceSystem } from "@/common/hooks/useEnabledPoInSourceSystem";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { MatchedOrderViewState } from "../../../../../enums/MatchedOrderViewState";
import { useInvoiceImportExternalPO } from "../../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceMatchedOrder } from "../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../../InvoiceVerificationForm";

export const useInvoiceSelectModeHeader = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { setFooterState, footerState, invoice } = useInvoiceVerification();
  const { setValue } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();

  const {
    setMatchedOrderViewState,
    matchedOrderViewState,
    setPreselectedPoNumber,
  } = useInvoiceMatchedOrder();
  const { setImportedItems, reset, openModal } = useInvoiceImportExternalPO();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const resetImportPoStore = useImportExternalPoStore((state) => state.reset);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  useEffect(() => {
    if (matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER) {
      setImportedItems(null);
    }
  }, [matchedOrderViewState, setImportedItems]);

  const options = useMemo(() => {
    const buttons = [
      {
        label: intl.$t({ id: "INVOICE_ORDER_SELECT_INFO" }),
        onClick: () => {
          setPreselectedPoNumber("");
          setFooterState(InvoiceFooterState.MATCH_ORDER);
          setMatchedOrderViewState(MatchedOrderViewState.MATCH_ORDER);
          reset();
          resetImportPoStore();
          handleClose();
        },
        selected:
          footerState === InvoiceFooterState.MATCH_ORDER &&
          matchedOrderViewState === MatchedOrderViewState.MATCH_ORDER,
      },
      {
        label: intl.$t({ id: "CREATE_NEW_ORDER_FROM_INVOICE" }),
        onClick: () => {
          reset();
          resetImportPoStore();
          if (invoice?.poNumber && includePoNumbering) {
            setValue("poNumber", invoice.poNumber);
          }
          setValue("vendorId", "");
          setFooterState(InvoiceFooterState.CREATE_ORDER);
          setMatchedOrderViewState(MatchedOrderViewState.CREATE_ORDER);
          handleClose();
          setImportedItems(null);
        },
        selected:
          footerState === InvoiceFooterState.CREATE_ORDER &&
          matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER,
      },
    ];
    if (connectedSourceSystem && isPoEnabledInSourceSystem) {
      buttons.push({
        label: intl.$t(
          { id: "IMPORT_PO_FROM_SYSTEM_AS_NEW_ORDER" },
          {
            system: connectedSourceSystem
              ? intl.$t({ id: `SOURCE_SYSTEM_${connectedSourceSystem}` })
              : "",
          },
        ),
        onClick: () => {
          reset();
          resetImportPoStore();
          handleClose();
          openModal({ skipPoList: false });
        },
        selected:
          footerState === InvoiceFooterState.IMPORT_ORDER &&
          matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER,
      });
    }
    return buttons;
  }, [
    intl,
    footerState,
    matchedOrderViewState,
    connectedSourceSystem,
    isPoEnabledInSourceSystem,
    setPreselectedPoNumber,
    setFooterState,
    setMatchedOrderViewState,
    handleClose,
    reset,
    invoice?.poNumber,
    includePoNumbering,
    setValue,
    setImportedItems,
    openModal,
    resetImportPoStore,
  ]);

  return { options, anchorEl, handleClose, handleClick };
};
