import { useCallback } from "react";
import { useProjectSequence } from "../../hooks/useProjectSequence";
import { ProjectCheckboxProps } from "./ProjectCheckbox";

export const useProjectCheckbox = ({ project }: ProjectCheckboxProps) => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useProjectSequence();

  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedEntities(
        newChecked
          ? [...selectedEntities, project]
          : selectedEntities.filter((d) => d.id !== project.id),
      );
    },
    [project, selectedEntities, setSelectedEntities],
  );

  return { handleChange, sequenceIds };
};
