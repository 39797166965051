import { InvoiceToExportFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { InvoiceExportInformation } from "./invoice-export-information/InvoiceExportInformation";

const Container = tw.div`flex flex-col gap-3`;

type Props = {
  invoices: InvoiceToExportFieldsFragment[];
};

export const InvoicesExport: FC<Props> = ({ invoices }) => {
  return (
    <Container>
      {invoices.map((invoice, key) => (
        <InvoiceExportInformation invoice={invoice} key={key} />
      ))}
    </Container>
  );
};
