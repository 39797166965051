import { HeaderContainerWithSecondaryNavigation } from "@/common/components/header-container/HeaderContainer";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { useLocalSearch } from "@/common/hooks/useLocalSearch";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { EquipmentList } from "./components/EquipmentList";
import { ImportExternalEquipmentItemsModal } from "./components/import-external-equipment-items/ImportExternalEquipmentItemsModal";
import { ImportExternalEquipmentItemsButton } from "./components/ImportExternalEquipmentItemsButton";
import { useEquipmentItems } from "./hooks/useEquipmentItems";

const HeaderRightPanel = tw.div`flex flex-row`;
const Filters = tw.div`
  grid grid-flow-col gap-5 items-start
`;

export const Equipment = () => {
  const intl = useIntl();
  const { equipmentItems, loading } = useEquipmentItems();
  const { setSearchText, filteredItems } = useLocalSearch(equipmentItems);

  return (
    <NestedStepperProvider>
      <Helmet>
        <title>{intl.$t({ id: "EQUIPMENT" })}</title>
      </Helmet>
      <HeaderContainerWithSecondaryNavigation>
        <Filters>
          <SearchInput
            placeHolder={intl.$t({ id: "SEARCH_EQUIPMENT" })}
            onChange={setSearchText}
          />
        </Filters>
        <HeaderRightPanel>
          <ImportExternalEquipmentItemsButton />
        </HeaderRightPanel>
      </HeaderContainerWithSecondaryNavigation>
      <EquipmentList items={filteredItems} loading={loading} />
      <ImportExternalEquipmentItemsModal />
    </NestedStepperProvider>
  );
};
