import { SelectCustomItemView } from "../select/components/single/components/SelectCustomItemView";
import { Select } from "../select/components/single/Select";
import { useEquipmentFilterSelector } from "./useEquipmentFilterSelector";

export type EquipmentFilterSelectorProps = {
  onChange: (newValue: string | null) => void;
  value?: string | null;
  withCustomView?: boolean;
  clear?: () => void;
  placeholder?: string;
};

export const ALL_EQUIPMENT = "-1";

export const EquipmentFilterSelector = (
  props: EquipmentFilterSelectorProps,
) => {
  const { value, withCustomView = false, clear, placeholder } = props;
  const { onChangeHandler, loading, options, selectedOption } =
    useEquipmentFilterSelector(props);

  return (
    <Select
      options={options}
      value={value || ALL_EQUIPMENT}
      onChange={onChangeHandler}
      getLabel={(option) => option.description}
      getValue={(option) => option.id}
      inputProps={{
        className: `${!value && "text-gray-600"} px-2 py-[2.5px]`,
      }}
      selectedOptionCustomView={
        withCustomView && selectedOption ? (
          <SelectCustomItemView
            text={selectedOption.description}
            clear={clear}
          />
        ) : null
      }
      loading={loading}
      placeholder={placeholder}
      className="flex-1"
    />
  );
};
