import { Address } from "@/common/components/address/Address";
import { If } from "@/common/components/if/If";
import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { ListDetailsHeaderItem } from "@/common/components/info-header/components/ListDetailsHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { PROJECT_STATUSES } from "@/common/components/project-statuses/ProjectStatuses";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { DateView } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { routes } from "@/config/routes";
import { useProjectNavigate } from "@/contractor/pages/home/projects/hooks/useProjectNavigate";
import { BuyoutVendorContactSelector } from "@/distributor/pages/buyout/components/BuyoutVendorContactSelector";
import { BuyoutFieldsFragment, BuyoutStatus } from "@/generated/graphql";
import { ChevronRight } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { BuyoutInlineOrderType } from "./BuyoutInlineOrderType";
import { BuyoutPONumber } from "./BuyoutPONumberInput";
import { ToggleBuyoutForm } from "./ToggleEditBuyoutButton";

type Props = {
  buyout: BuyoutFieldsFragment;
  readonly: boolean;
  isNonQuoted: boolean;
};

const ChevronRightStyled = tw(ChevronRight)`text-sm`;
const BuyoutName = tw.div`font-medium text-sm`;
const TitleWrapper = tw.div`flex gap-1 items-center`;
const LinkStyled = tw(LinkLike)`font-medium text-xs`;

export const BuyoutHeaderInfo: FC<Props> = ({
  buyout,
  readonly,
  isNonQuoted,
}) => {
  const intl = useIntl();
  const { getProjectPath } = useProjectNavigate();
  return (
    <InfoHeader>
      <ListDetailsHeaderItem
        title={
          <TitleWrapper>
            <ToggleBuyoutForm
              buyout={buyout}
              staticText={buyout.status !== BuyoutStatus.Draft}
            >
              <FormattedMessage
                id="BUYOUT"
                tagName={BuyoutName}
                values={{ number: buyout.clientIdentifier }}
              />
            </ToggleBuyoutForm>
            {isNonQuoted ? (
              <If isTrue={!buyout.quoteDocument}>
                <ChevronRightStyled />
                <FormattedMessage id="BUYOUT_WITHOUT_QUOTE" />
              </If>
            ) : (
              <>
                {intl.$t({ id: "FROM" }).toLowerCase()}
                <LinkStyled
                  to={generatePath(routes.rfqById, {
                    id: buyout.quote?.rfq.id || "",
                  })}
                >
                  <FormattedMessage
                    tagName="div"
                    id="RFQ_NUMBER_WITH_SYMBOL"
                    values={{ number: buyout.quote?.rfq.clientIdentifier }}
                  />
                </LinkStyled>
              </>
            )}
          </TitleWrapper>
        }
        list={[
          {
            label: (
              <FormattedMessage
                id="BY_USERNAME"
                values={{
                  username: "",
                }}
              />
            ),
            value: getUserName(buyout.createdBy),
          },
          {
            label: <FormattedMessage tagName="div" id="PROJECT_TITLE" />,
            value: (
              <Tooltip
                id="buyout_project_name"
                element={
                  <LinkLike to={getProjectPath(buyout.project)}>
                    {buyout.project.name}
                    {buyout.project.jobNumber
                      ? ` (${buyout.project.jobNumber})`
                      : ""}
                  </LinkLike>
                }
              >
                <Address address={buyout.project.address} />
              </Tooltip>
            ),
          },
          {
            label: <FormattedMessage id="STAGE_TITLE" />,
            value: (
              <FormattedMessage
                id={PROJECT_STATUSES[buyout.project.status].tooltip}
              />
            ),
          },
          {
            label: <FormattedMessage id="START_DATE_TITLE" />,
            value: buyout.project.startDate ? (
              <DateView date={buyout.project.startDate} />
            ) : (
              <FormattedMessage id="NOT_SET" />
            ),
          },
        ]}
      />
      <OrgDetailsHeaderItem
        title={<FormattedMessage id="VENDOR" />}
        name={
          <VendorList
            contacts={buyout.preferredVendor?.contacts.filter(
              (c) => c.receivesBuyoutNotifications,
            )}
          />
        }
        orgName={buyout.sellerOrgLocation.org.name}
        logoImageUrl={buyout.sellerOrgLocation.org.photoUrl}
        address={buyout.sellerOrgLocation.address}
      />
      <If isTrue={!readonly || !isNonQuoted}>
        <InfoHeaderItem title={<FormattedMessage id="VENDOR_CONTACT" />}>
          <BuyoutVendorContactSelector isNonQuoted={isNonQuoted} />
        </InfoHeaderItem>
      </If>
      <InfoHeaderItem title={<FormattedMessage id="ORDER_TYPE" />}>
        <BuyoutInlineOrderType readonly={readonly} />
      </InfoHeaderItem>
      <InfoHeaderItem title={<FormattedMessage id="PO_HASH" />}>
        <PoNumberingSettingsCheck>
          <BuyoutPONumber buyout={buyout} readonly={readonly} />
        </PoNumberingSettingsCheck>
      </InfoHeaderItem>
    </InfoHeader>
  );
};
