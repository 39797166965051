import { If } from "@/common/components/if/If";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorBuyout } from "../providers/DistributorBuyoutProvider";

const Container = tw.div`
  flex items-center 
`;

const Include = tw.div`
  mr-2 text-sm lg:hidden
`;

const PrintContainer = tw.div<{ $readonly?: boolean }>`
  print:block text-sm
  ${({ $readonly }: { $readonly?: boolean }) => ($readonly ? "" : "hidden")}
`;

type Props = {
  item: DistributorBuyoutItemFieldsFragment;
  readonly: boolean;
};

export const DistributorBuyoutItemToggle: FC<Props> = ({ item, readonly }) => {
  const intl = useIntl();
  const [includeItem, toggleItem] = useState<boolean>(item.isIncluded);
  const { buyout, updateVendorBuyout } = useDistributorBuyout();

  useEffect(() => {
    toggleItem(item.isIncluded);
  }, [item.isIncluded]);

  const onToggle = useCallback(
    async (checked: boolean) => {
      if (!buyout) {
        return;
      }
      const payload = {
        buyoutItemId: item.id,
        isIncluded: checked,
      };
      toggleItem(checked);
      await updateVendorBuyout({ updates: [payload] });
    },
    [buyout, item.id, updateVendorBuyout],
  );

  return (
    <Container>
      <Include>
        <FormattedMessage id="INCLUDE" />?
      </Include>
      <If isTrue={!readonly}>
        <InnerLabeledSwitch
          toggle={onToggle}
          className="print:hidden"
          width={55}
          initialValue={includeItem}
          onLabel={intl.$t({ id: "YES" })}
          offLabel={intl.$t({ id: "NO" })}
        />
      </If>
      <PrintContainer $readonly={readonly}>
        {includeItem ? intl.$t({ id: "YES" }) : intl.$t({ id: "NO" })}
      </PrintContainer>
    </Container>
  );
};
