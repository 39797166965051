import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import { DeleteOutline } from "@mui/icons-material";
import { useProjectsSideActions } from "./useProjectsSideActions";

export const ProjectsSideActions = () => {
  const { sequenceIds, actions } = useProjectsSideActions({
    deleteIcon: <DeleteOutline />,
  });

  return (
    <SideActionBar
      visible={sequenceIds.length > 0 && actions.length > 0}
      actions={actions}
    />
  );
};
