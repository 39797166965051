import { GroupSelector } from "@/common/components/groups/components/group-selector/GroupSelector";
import { FC } from "react";
import { useInvoiceFolderSelector } from "./hooks/useInvoiceFolderSelector";
import { InvoiceFolderSelectorProps } from "./types/InvoiceFolderSelectorProps";

export const InvoiceFolderSelector: FC<InvoiceFolderSelectorProps> = ({
  removing,
  onClose,
  existingFolders = [],
}) => {
  const { createFolder, filteredFolders, updateEntity } =
    useInvoiceFolderSelector({
      removing,
      existingFolders,
    });
  return (
    <GroupSelector
      createGroup={createFolder}
      groups={filteredFolders}
      onClose={onClose}
      removing={removing}
      updateEntity={updateEntity}
    />
  );
};
