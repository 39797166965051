import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  BuyoutReleaseFieldsFragment,
  BuyoutReleasesFieldsFragment,
  ReleaseStatus,
  useBuyoutReleasesQuery,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";

type ProviderContextType = {
  buyout: BuyoutReleasesFieldsFragment | null;
  releases: BuyoutReleaseFieldsFragment[];
  updatePONumber: (
    release?: Pick<
      BuyoutReleaseFieldsFragment,
      "id" | "status" | "version"
    > | null,
    poNumber?: string,
  ) => Promise<boolean>;
  loading: boolean;
  toggleArchived: boolean;
  setToggleArchived: (value: boolean) => void;
  hasArchivedReleases: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  buyout: null,
  releases: [],
  updatePONumber: NoFunctionBooleanPromise,
  loading: false,
  toggleArchived: false,
  setToggleArchived: NoFunction,
  hasArchivedReleases: false,
});

export const BuyoutReleasesProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { id } = useParams();
  const [updateContractorReleaseMutation] =
    useUpdateContractorReleaseMutation();
  const { setError } = useGlobalError();

  const { data, loading, error } = useBuyoutReleasesQuery({
    variables: { id: id || "" },
  });

  useErrorEffect(error);

  const [toggleArchived, setToggleArchived] = useState(false);

  const releases = useMemo(() => {
    if (!data?.buyout?.releases) {
      return [];
    }
    return !toggleArchived
      ? data.buyout.releases.filter((release) => !release.deletedAt)
      : data.buyout.releases;
  }, [data?.buyout?.releases, toggleArchived]);

  const hasArchivedReleases = useMemo(() => {
    return !!data?.buyout?.releases.some((release) => !!release.deletedAt);
  }, [data?.buyout?.releases]);

  const updatePONumber = async (
    release?: Pick<
      BuyoutReleaseFieldsFragment,
      "id" | "version" | "status"
    > | null,
    poNumber?: string,
  ) => {
    if (release) {
      try {
        const { errors } = await updateContractorReleaseMutation({
          variables: {
            input: {
              releaseId: release?.id,
              version:
                release?.status === ReleaseStatus.Draft
                  ? undefined
                  : release?.version,
              poNumber,
            },
          },
        });
        setError(errors);
        return !errors;
      } catch (error) {
        setError(error);
      }
    }
    return false;
  };

  return (
    <ProviderContext.Provider
      value={{
        buyout: data?.buyout || null,
        releases,
        updatePONumber,
        loading,
        toggleArchived,
        setToggleArchived,
        hasArchivedReleases,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useBuyoutReleases = (): ProviderContextType =>
  useContext(ProviderContext);
