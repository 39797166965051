import { routes } from "@/config/routes";
import { WithBudgetTabNavigation } from "./navigation/WithBudgetTabNavigation";
import { BudgetByCostCodes } from "./pages/project-budget/components/budget-by-cost-codes/BudgetByCostCodes";
import { BudgetByTags } from "./pages/project-budget/components/budget-by-tags/BudgetByTags";
import { ProjectDetails } from "./pages/project-details/ProjectDetails";
import { ProjectEstimatedItems } from "./pages/project-estimated-items/ProjectEstimatedItems";
import { SpendingReportReport } from "./pages/project-spending-report/ProjectSpendingReport";
import { ProjectTags } from "./pages/project-tags/ProjectTags";
import { ProjectZones } from "./pages/project-zones/ProjectZones";
export const projectRoutesConfig = {
  children: [
    {
      path: routes.projectEstimatedItems,
      element: <ProjectEstimatedItems />,
    },
    {
      path: routes.projectDetails,
      element: <ProjectDetails />,
    },
    {
      path: routes.projectZones,
      element: <ProjectZones />,
    },
    {
      path: routes.projectTags,
      element: <ProjectTags />,
    },
    {
      path: routes.projectBudget,
      skipCase: true,
      element: <WithBudgetTabNavigation />,
      children: [
        {
          path: routes.projectBudget,
          element: <BudgetByCostCodes />,
        },
        {
          path: routes.budgetByTags,
          element: <BudgetByTags />,
        },
      ],
    },
    {
      path: routes.projectBudgetReport,
      element: <SpendingReportReport />,
    },
  ],
};
