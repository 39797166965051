import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useReceipt } from "../../providers/ReceiptProvider";

export const useClearOrderFromReceipt = () => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { receipt, updateReceipt } = useReceipt();

  const clear = useCallback(() => {
    if (!receipt) {
      return;
    }

    openDialog({
      title: intl.$t({ id: "CLEAR_ORDER_CONFIRMATION_QUESTION" }),
      includeWarningIcon: true,
      content: intl.$t(
        { id: "CLEAR_ORDER_CONFIRMATION_TEXT" },
        {
          number: receipt.release?.sequenceNumber,
        },
      ),
      confirmButtonText: intl.$t({ id: "CLEAR" }),
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      handleConfirm: async () => {
        await updateReceipt({
          clearRelease: true,
          id: receipt?.id,
        });
      },
    });
  }, [intl, openDialog, receipt, updateReceipt]);

  return { clearOrder: clear };
};
