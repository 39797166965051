import { routes } from "@/config/routes";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router";
import { useInvoiceSequence } from "../../../../scanned-invoices/providers/InvoiceSequenceProvider";

export const useInvoiceSequenceStepper = () => {
  const navigate = useNavigate();
  const { step, sequenceIds } = useInvoiceSequence();

  const prevStepCallback = useCallback(() => {
    if (sequenceIds[step - 1]) {
      navigate(
        generatePath(routes.invoiceVerification, {
          invoiceId: sequenceIds[step - 1],
        }),
      );
    }
  }, [navigate, sequenceIds, step]);

  const nextStepCallback = useCallback(() => {
    if (sequenceIds[step + 1]) {
      navigate(
        generatePath(routes.invoiceVerification, {
          invoiceId: sequenceIds[step + 1],
        }),
      );
    }
  }, [navigate, sequenceIds, step]);

  return { prevStepCallback, nextStepCallback };
};
