import { If } from "@/common/components/if/If";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Price } from "@/common/components/price/Price";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { CheckCircleOutline, InfoOutlined } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { MatchedOrderViewState } from "../../../../../../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceExtPriceInput } from "./hooks/useInvoiceExtPriceInput";

const Container = tw.div`relative`;
const Row = tw.div`flex flex-row items-center`;
const ReadonlyField = tw.div`flex flex-col items-center text-xs`;
const CheckIcon = tw(
  CheckCircleOutline,
)`text-green-600 absolute absolute right-1 top-0 transform -translate-y-1/2 text-lg`;
const InvoiceSoFarLabel = tw.span`text-[9px] text-gray-600`;
const InvoiceSoFarLabelError = tw(InvoiceSoFarLabel)`text-red-500`;
const InfoIcon = tw(
  InfoOutlined,
)`text-xl  ml-1 ${({ $isNegative }: { $isNegative: boolean }) => ($isNegative ? "text-red-500" : "")}`;
const Error = tw.span`text-red-500`;
const TooltipItem = tw.li``;

type Props = {
  item: ExpandedReleaseItem;
};

export const InvoiceExtPriceInput = ({ item }: Props) => {
  const { matchedOrderViewState, useInvoiceItemPrice } =
    useInvoiceMatchedOrder();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { calcExtPrice } = usePriceCalculation();
  const {
    extPrice,
    remaining,
    onSave,
    inputClassName,
    isRemainingNegative,
    loading,
    quantitySoFar,
    isFullyInvoiced,
    tooltipItems,
  } = useInvoiceExtPriceInput(item);

  return (
    <If isTrue={(item.invoiceItems || []).length > 0}>
      <Row>
        <ReadonlyField className={useInvoiceItemPrice ? "" : "w-26 pl-5"}>
          {(matchedOrderViewState ===
            MatchedOrderViewState.EDIT_INVOICE_COVERAGES &&
            isLumpSumItem(item)) ||
          useInvoiceItemPrice ? (
            <ReadonlyField>
              <Container>
                <PricePicker
                  value={extPrice}
                  onBlur={onSave}
                  className={inputClassName}
                  helperText={
                    <FormattedMessage
                      id="REMAINING_VALUE"
                      values={{
                        value: formatCurrency(remaining),
                      }}
                      tagName={isRemainingNegative ? Error : undefined}
                    />
                  }
                />
                {isFullyInvoiced && <CheckIcon />}
              </Container>
            </ReadonlyField>
          ) : (
            <>
              <Tooltip
                hideTooltip={!quantitySoFar.greaterThan(item.quantityDecimal)}
                element={
                  <Price
                    price={calcExtPrice(
                      item.invoiceItems?.[0]?.quantity,
                      item.unitPrice,
                    )}
                    className={
                      quantitySoFar.greaterThan(item.quantityDecimal)
                        ? "text-red-500"
                        : ""
                    }
                  />
                }
                id="invoiced-ordered-quantity"
              >
                <FormattedMessage
                  id="INVOICED_AMOUNT_GREATER_THAN_ORDERED_AMOUNT"
                  values={{
                    invoicedAmount: formatCurrency(quantitySoFar),
                    orderedAmount: formatCurrency(item.quantityDecimal),
                  }}
                />
              </Tooltip>

              {loading ? (
                <div className="w-16">
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />{" "}
                </div>
              ) : (
                <FormattedMessage
                  id="REMAINING_VALUE"
                  tagName={
                    isRemainingNegative
                      ? InvoiceSoFarLabelError
                      : InvoiceSoFarLabel
                  }
                  values={{
                    value: formatCurrency(remaining),
                  }}
                />
              )}
            </>
          )}
        </ReadonlyField>
        <Tooltip
          id="po-amounts"
          noWrap
          element={<InfoIcon $isNegative={isRemainingNegative} />}
        >
          {tooltipItems.map((item) => (
            <FormattedMessage
              key={item.id}
              id={item.id}
              values={item.values}
              tagName={TooltipItem}
            />
          ))}
        </Tooltip>
      </Row>
    </If>
  );
};
