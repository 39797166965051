import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  leftWidth: number;
  setWidth: (width: number) => void;
};

export const useResizeColumnsStore = create<State>()(
  devtools((set) => ({
    leftWidth: 60,
    setWidth: (width: number) => {
      set({ leftWidth: width });
    },
  })),
);
