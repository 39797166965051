import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useArchiveProjectsMutation } from "@/generated/graphql";
import { useCallback } from "react";

export const useArchiveProjects = () => {
  const { setError } = useGlobalError();

  const [archiveProjectsMutation, { loading }] = useArchiveProjectsMutation();

  const archiveProjects = useCallback(
    async (ids: string[]): Promise<boolean> => {
      try {
        const { data, errors } = await archiveProjectsMutation({
          variables: {
            ids,
          },
        });
        setError(errors);
        return Boolean(!!data?.archiveProjects && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [setError, archiveProjectsMutation],
  );

  return { archiveProjects, loading };
};
