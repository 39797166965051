import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../store/useProjectStore";

export const useSetCurrentProjectId = (id: string | undefined) => {
  const { loading } = useStartupDataStore(
    useShallow((state) => ({
      loading: state.loading,
    })),
  );

  const { setCurrentProjectId } = useProjectStore(
    useShallow((state) => ({
      setCurrentProjectId: state.setCurrentProjectId,
    })),
  );

  useEffect(() => {
    if (id && !loading) {
      setCurrentProjectId(id);
    }
  }, [id, setCurrentProjectId, loading]);
};
