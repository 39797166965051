import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { routes } from "@/config/routes";
import {
  AssetFieldsFragment,
  ReceiptFieldsFragment,
} from "@/generated/graphql";
import { FC, ReactNode, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-col ml-1.5`;
const ScannedBy = tw.div`flex flex-row font-light text-black text-xs`;
const Div = tw.div``;
const Date = tw.div`text-2xs text-gray-600 font-light`;
const GrayBackground = tw.div`bg-gray-200 py-0.5 mt-1 rounded-2xl text-2xs items-center justify-center w-fit px-2 text-black`;

type Props = {
  release: {
    receipts: (Pick<
      ReceiptFieldsFragment,
      "id" | "number" | "createdBy" | "createdAt" | "prePaid"
    > & {
      documents: {
        asset: AssetFieldsFragment;
      }[];
    })[];
  };
  className?: string;
  prePaid?: boolean;
};

export const ReleaseReceipts: FC<Props> = ({ release, className, prePaid }) => {
  const intl = useIntl();

  const getReceiptByIndex = useCallback(
    (index: number = 0) => release.receipts?.[index],
    [release],
  );

  const getImageWrapper = useCallback(
    (url: string, children: ReactNode, index: number) => {
      const currentReceipt = getReceiptByIndex(index);
      return (
        <LinkLike
          to={generatePath(routes.processReceipt, {
            receiptId: currentReceipt?.id,
          })}
        >
          {children}
        </LinkLike>
      );
    },
    [getReceiptByIndex],
  );

  const getReceiptDetails = useCallback(
    (_: string, _createdDate?: number, index?: number) => {
      const currentReceipt = getReceiptByIndex(index);
      return (
        <Container>
          #{currentReceipt?.number}
          <ScannedBy>
            <Div>
              <FormattedMessage id="SCANNED_BY" />
            </Div>
            <Div>
              &nbsp;
              {getUserName(currentReceipt?.createdBy)}
            </Div>
          </ScannedBy>
          <Date>
            <DateView
              date={currentReceipt?.createdAt}
              options={SHORT_DATE_OPTION}
            />
          </Date>
          <If isTrue={currentReceipt?.prePaid === false}>
            <GrayBackground>
              <FormattedMessage id="PAID_VIA_INVOICE" />
            </GrayBackground>
          </If>
        </Container>
      );
    },
    [getReceiptByIndex],
  );

  const receiptsFiltered = useMemo(() => {
    if (prePaid !== undefined) {
      return release.receipts.filter((r) => r.prePaid === prePaid);
    }
    return release.receipts;
  }, [release.receipts, prePaid]);

  if (receiptsFiltered.length === 0) {
    return null;
  }

  return (
    <AssetsCard
      headerClassName="bg-blue-100"
      className={className}
      title={intl.$t({ id: "RECEIPTS" })}
      expanded={true}
      includeTooltip={false}
      includeNavigation={false}
      assets={[
        ...(receiptsFiltered.map(
          (invoice) => invoice.documents[invoice.documents.length - 1].asset,
        ) || []),
      ]}
      imageDetails={getReceiptDetails}
      getImageWrapper={getImageWrapper}
    />
  );
};
