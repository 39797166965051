import { routes } from "@/config/routes";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router";

export const useInvoicesCards = () => {
  const { invoice } = useInvoiceVerification();
  const navigate = useNavigate();

  const getInvoiceFromUrl = useCallback(
    (invoiceId: string) =>
      invoice?.release?.invoices.find((i) => i.id === invoiceId),
    [invoice],
  );

  const changeInvoice = useCallback(
    (invoice?: { id: string }) => {
      navigate(
        generatePath(routes.invoiceVerification, {
          invoiceId: invoice?.id,
        }),
        {
          replace: true,
        },
      );
    },
    [navigate],
  );
  return { getInvoiceFromUrl, changeInvoice };
};
