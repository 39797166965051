import { useDialog } from "@/common/components/dialog/DialogProvider";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { DIALOG_AUTO_CLOSE_TIMER, QUERYSTRING } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { routes } from "@/config/routes";
import {
  NoteDocumentFieldsFragment,
  useUpdateRfqMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { useRfq } from "../../../../rfq/hooks/useRfq";

export const useReplaceDocumentOnRfq = () => {
  const [updateRfq] = useUpdateRfqMutation();
  const { rfq } = useRfq();
  const { setError } = useGlobalError();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const intl = useIntl();

  const handleReplaceDocument = useCallback(
    async (document: NoteDocumentFieldsFragment) => {
      if (rfq) {
        try {
          const { errors, data } = await updateRfq({
            variables: {
              input: {
                rfqId: rfq.id,
                noteDocumentId: document.id,
              },
            },
          });
          setError(errors);
          if (data) {
            navigate({
              pathname: generatePath(routes.editRfqFromNote, {
                id: rfq.id,
                noteDocumentId: document.id,
              }),
              search: `${QUERYSTRING.REPLACE_DOCUMENT}=true`,
            });
          }
        } catch (error) {
          setError(error);
        }
      } else if (document) {
        openDialog({
          content: (
            <SuccessModal message={intl.$t({ id: "NOTE_DOCUMENT_REPLACED" })} />
          ),
          closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        });
        setTimeout(() => {
          navigate({
            pathname: generatePath(routes.rfqFromNote, {
              noteDocumentId: document?.id,
            }),
          });
        }, DIALOG_AUTO_CLOSE_TIMER);
      }
    },
    [rfq, updateRfq, setError, navigate, openDialog, intl],
  );

  return handleReplaceDocument;
};
