import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  AddToBuyoutInput,
  BuyoutDocument,
  BuyoutFieldsFragment,
  BuyoutQuery,
  CancelBuyoutInput,
  CreateBuyoutInput,
  namedOperations,
  RemoveFromBuyoutInput,
  SubmitBuyoutInput,
  UomsDocument,
  UpdateContractorBuyoutInput,
  useAddToBuyoutMutation,
  useCancelBuyoutMutation,
  useCreateBuyoutMutation,
  useRemoveFromBuyoutMutation,
  useSubmitBuyoutMutation,
  useUpdateContractorBuyoutMutation,
} from "@/generated/graphql";

export const useBuyoutMutations = () => {
  const [createBuyoutMutation] = useCreateBuyoutMutation({
    update: (cache) => cleanQuery(cache, namedOperations.Query.Buyouts),
  });
  const [updateContractorBuyoutMutation, { loading: updating }] =
    useUpdateContractorBuyoutMutation();
  const [addToBuyoutMutation, { loading: adding }] = useAddToBuyoutMutation();
  const [removeFromBuyoutMutation, { loading: removing }] =
    useRemoveFromBuyoutMutation();
  const [submitBuyoutMutation, { loading: submitting }] =
    useSubmitBuyoutMutation({
      update: (cache) => cleanQuery(cache, namedOperations.Query.Buyouts),
    });
  const [cancelBuyoutMutation] = useCancelBuyoutMutation({
    update: (cache) => cleanQuery(cache, namedOperations.Query.Buyouts),
  });

  const { setError } = useGlobalError();

  const createBuyout = async (
    input: CreateBuyoutInput,
  ): Promise<{ id: string; clientIdentifier: string } | undefined> => {
    try {
      const { data, errors } = await createBuyoutMutation({
        variables: { input },
      });
      setError(errors);
      if (data) {
        return data.createBuyout;
      }
    } catch (error) {
      setError(error);
      return undefined;
    }
  };

  const updateContractorBuyout = async (
    input: UpdateContractorBuyoutInput,
    // originalBuyout?: BuyoutFieldsFragment | null,
  ): Promise<boolean> => {
    try {
      const { errors } = await updateContractorBuyoutMutation({
        variables: { input: { ...input, mergeDuplicates: false } },
        refetchQueries: [
          {
            query: UomsDocument,
          },
        ],
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const addToBuyout = async (input: AddToBuyoutInput) => {
    try {
      const { data, errors } = await addToBuyoutMutation({
        variables: {
          input: {
            ...input,
            assignDefaultCostCodes: false,
            mergeDuplicates: false,
          },
        },
        refetchQueries: [
          { query: BuyoutDocument, variables: { id: input.buyoutId } },
          { query: UomsDocument },
        ],
        awaitRefetchQueries: true,
      });
      setError(errors);
      return data?.addToBuyout;
    } catch (error) {
      setError(error);
    }
  };

  const removeFromBuyout = async (
    input: RemoveFromBuyoutInput,
  ): Promise<boolean> => {
    try {
      const { errors } = await removeFromBuyoutMutation({
        variables: { input },
        update: (cache, { data: removeFromBuyoutResult }) => {
          const buyout = cache.readQuery<BuyoutQuery>({
            query: BuyoutDocument,
            variables: { id: input.buyoutId || "" },
          });
          if (buyout?.buyout && removeFromBuyoutResult?.removeFromBuyout) {
            cache.writeQuery({
              query: BuyoutDocument,
              variables: { id: input.buyoutId || "" },
              data: {
                buyout: {
                  ...buyout.buyout,
                  items: buyout.buyout.items.filter(
                    (item) =>
                      !removeFromBuyoutResult.removeFromBuyout.includes(
                        item.id,
                      ),
                  ),
                },
              },
            });
          }
        },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const submitBuyout = async (
    input: SubmitBuyoutInput,
  ): Promise<BuyoutFieldsFragment | undefined> => {
    try {
      const { data, errors } = await submitBuyoutMutation({
        variables: { input },
      });
      setError(errors);
      return data?.submitBuyout;
    } catch (error) {
      setError(error);
    }
  };

  const cancelBuyout = async (input: CancelBuyoutInput): Promise<boolean> => {
    try {
      const { errors } = await cancelBuyoutMutation({
        variables: { input },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    createBuyout,
    updateContractorBuyout,
    addToBuyout,
    removeFromBuyout,
    submitBuyout,
    cancelBuyout,
    loading: updating || adding || removing,
    adding,
    updating,
    removing,
    submitting,
  };
};
