import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useCallback } from "react";
import { useLocation } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { usePagination } from "../../components/pagination/PaginationProvider";
import { QUERYSTRING } from "../../const";
import { usePageParamsStore } from "../usePageParamsStore";
import { compressQueryParam } from "../utils/compressQueryParam";
import { decompressQueryParam } from "../utils/decompressQueryParam";

type FilterParams =
  | ({
      name?: string | null | undefined;
      search?: string | null | undefined;
    } & Record<string, unknown>)
  | undefined;

export const useFiltersQueryParams = () => {
  const { queryParams, setQueryParams } = useQueryParams();
  const { setPage } = usePagination();
  const setParamsForPage = usePageParamsStore(
    useShallow((state) => state.setParamsForPage),
  );
  const pageUrl = useLocation();

  const getFiltersQueryParam = useCallback(() => {
    const compressedQueryParams = queryParams.get(QUERYSTRING.FILTERS);
    setParamsForPage(
      pageUrl.pathname,
      `${QUERYSTRING.FILTERS}=${compressedQueryParams ?? ""}`,
    );
    if (!compressedQueryParams) {
      return undefined;
    }
    return decompressQueryParam(compressedQueryParams);
  }, [queryParams, pageUrl, setParamsForPage]);

  const setFiltersQueryParams = useCallback(
    (filterParams: FilterParams) => {
      if (filterParams) {
        const compressedQueryParams = compressQueryParam(filterParams);
        setPage({ page: 0 });
        setQueryParams({
          [QUERYSTRING.FILTERS]: compressedQueryParams,
        });
      } else {
        setQueryParams({
          [QUERYSTRING.FILTERS]: undefined,
        });
      }
    },
    [setQueryParams, setPage],
  );

  return { getFiltersQueryParam, setFiltersQueryParams };
};
