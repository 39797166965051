import { ExternalPOsQueryInputType } from "@/common/components/import-external-po/types/ExternalPOsQueryInputType";
import { useWarehouseOptions } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useInvoiceImportExternalPO } from "../../../../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceMatchedOrder } from "../../../../../../../providers/InvoiceMatchedOrderProvider";
import { InvoiceCreateReleaseFormValues } from "../../../../InvoiceVerificationForm";

export const useInvoiceCreateReleaseHeader = () => {
  const { watch } = useFormContext<InvoiceCreateReleaseFormValues>();
  const { projects } = useProjectListOptions();
  const { openModal, importedPoNumber } = useInvoiceImportExternalPO();

  const projectId = watch("projectId");
  const poNumber = watch("poNumber");
  const { warehouses, loading: loadingWarehouses } = useWarehouses();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const externalPOsQueryForm = useFormContext<ExternalPOsQueryInputType>();

  const { warehouseOptions } = useWarehouseOptions(
    warehouses,
    projects.find((p) => p.id === projectId)?.address,
  );

  const onPoNumberSearchIconClick = useCallback(() => {
    if (!importedPoNumber && poNumber) {
      externalPOsQueryForm?.setValue("search", poNumber);
    }
    openModal({ skipPoList: !!importedPoNumber });
  }, [importedPoNumber, poNumber, externalPOsQueryForm, openModal]);

  return {
    fulfillmentOptions: warehouseOptions,
    loadingWarehouses,
    matchedOrderViewState,
    onPoNumberSearchIconClick,
  };
};
