import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { getEstimatedItem } from "@/contractor/pages/home/project/utils/getEstimatedItem";
import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";

export const useAddEstimatedItemVendorView = (
  item: ProjectItemFieldsFragment,
) => {
  const { vendors, getVendorCode } = useVendors();

  const sellerOrgLocationId = useMemo(
    () => getEstimatedItem({ item })?.sellerOrgLocation?.id,
    [item],
  );

  const itemVendor = useMemo(
    () =>
      sellerOrgLocationId
        ? vendors.find(
            (vendor) => vendor.sellerOrgLocation.id === sellerOrgLocationId,
          )
        : null,
    [sellerOrgLocationId, vendors],
  );

  const vendorName = useMemo(
    () =>
      itemVendor
        ? vendorLabelFormatter(itemVendor.sellerOrgLocation, [], {
            vendorCode: getVendorCode(itemVendor),
          })
        : "",
    [getVendorCode, itemVendor],
  );

  return { vendorName };
};
