import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import {
  MAX_PRICE_DECIMALS,
  MAX_QUANTITY_DECIMALS,
  PDF_FONT,
  PDF_LEFT_SPACING,
} from "@/common/const";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { formattedDate } from "@/common/utils/dates/DateView";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { formatHeader } from "@/common/utils/pdf-print/formatHeader";
import { DistributorReleaseFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";
import { ZoneCategory } from "../../providers/DistributorReleaseItemsZoneProvider";

const HEADER = [
  { name: "", buyoutSpecific: false },
  { name: "ITEMS_IN_DELIVERY", buyoutSpecific: false },
  { name: "MANUFACTURER", buyoutSpecific: false },
  { name: "QUANTITY_TO_FULFILL", buyoutSpecific: false },
  { name: "REMAINING_IN_BUYOUT", buyoutSpecific: true },
  { name: "UNIT_COST", buyoutSpecific: false },
  { name: "BACKORDER", buyoutSpecific: false },
];

const itemsForPdf = (
  release: DistributorReleaseFieldsFragment,
  intl: IntlShape,
  zones: ZoneCategory[],
  formatCurrency: FormatCurrencyType,
): RowInput[] => {
  const result: RowInput[] = [];
  const isBuyoutOrder = !!release.buyout;
  let count = 0;
  zones.forEach((zone) => {
    if (
      !(zones.length === 1 && zones[0].id === UNSPECIFIED_ZONE_ID) &&
      zone.items.some((costCode) =>
        costCode.items.some(
          (i) =>
            i.alternativeFulfillmentRelease || i.alternativeFulfillmentTime,
        ),
      )
    ) {
      result.push([
        {
          content: `${zone.name} (${intl.$t({ id: "ZONE" })})`,
          colSpan: HEADER.filter((i) => isBuyoutOrder || !i.buyoutSpecific)
            .length,
          styles: {
            fontStyle: "bold",
            cellPadding: { left: 7, top: 3, bottom: 3 },
          },
        },
      ]);
    }
    zone.items.forEach((costCode) => {
      const items = costCode.items.filter(
        (i) => i.alternativeFulfillmentRelease || i.alternativeFulfillmentTime,
      );
      if (items.length > 0) {
        result.push([
          {
            content: `${costCode.name} (${intl.$t({ id: "COST_CODE" })})`,
            colSpan: HEADER.filter((i) => isBuyoutOrder || !i.buyoutSpecific)
              .length,
            styles: {
              fontStyle: "bold",
              cellPadding: { left: 7, top: 3, bottom: 3 },
            },
          },
        ]);
      }
      items.forEach((releaseItem) => {
        const quantity = `${intl.formatNumber(
          Number(releaseItem.quantityDecimal),
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: MAX_QUANTITY_DECIMALS,
          },
        )}\n${releaseItem.uom?.mnemonic || releaseItem.uom?.pluralDescription}`;

        const remainingQuantityNumber = new DecimalSafe(
          releaseItem.buyoutItem?.quantityDecimal || 0,
        );
        // .minus(releaseItem.buyoutItem?.releasedQuantity || 0);

        const remainingQuantity = `${intl.formatNumber(
          Number(remainingQuantityNumber),
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: MAX_QUANTITY_DECIMALS,
          },
        )}\n${releaseItem.uom?.mnemonic || releaseItem.uom?.pluralDescription}`;

        count++;
        result.push([
          count,
          `${releaseItem.projectItem?.material.material.name || ""}`,
          releaseItem.manufacturer?.name || intl.$t({ id: "ANY_MANUFACTURER" }),
          quantity,
          ...(releaseItem.buyoutItem ? [remainingQuantity] : [""]),
          formatCurrency(releaseItem.unitPrice, {
            maximumFractionDigits: MAX_PRICE_DECIMALS,
          }),
          releaseItem.alternativeFulfillmentTime
            ? formattedDate({
                date: new Date(releaseItem.alternativeFulfillmentTime),
              })
            : "",
        ]);
      });
    });
  });

  return result;
};

export const backorderMaterials = (
  doc: jsPDF,
  release: DistributorReleaseFieldsFragment,
  intl: IntlShape,
  zones: ZoneCategory[],
  formatCurrency: FormatCurrencyType,
) => {
  doc
    .setFont(PDF_FONT, "", "bold")
    .setFontSize(10)
    .text(intl.$t({ id: "BACKORDERED_ITEMS" }), PDF_LEFT_SPACING, 30);
  autoTable(doc, {
    theme: "grid",
    styles: {
      font: PDF_FONT,
      fontSize: 8,
    },
    margin: { top: 35 },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
      halign: "center",
    },
    columnStyles: {
      0: { valign: "middle" },
      1: { valign: "middle" },
      2: { halign: "center", valign: "middle" },
      3: { halign: "center", valign: "middle" },
      4: { halign: "center", valign: "middle" },
      5: { halign: "center", valign: "middle" },
      6: { halign: "center", valign: "middle" },
      7: { halign: "center", valign: "middle" },
    },
    head: [
      HEADER.filter((i) => release.buyout || !i.buyoutSpecific)
        .map((header) => header.name)
        .map((name) => formatHeader(name, intl)),
    ],
    body: [...itemsForPdf(release, intl, zones, formatCurrency)],
  });
};
