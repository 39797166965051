import { routes } from "@/config/routes";
import { BuyoutFieldsFragment, BuyoutStatus } from "@/generated/graphql";
import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router";

export const useEnsureBuyoutStatus = (
  buyout: Pick<BuyoutFieldsFragment, "status" | "id"> | null | undefined,
  ...statuses: Array<BuyoutStatus>
) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (buyout && !statuses.includes(buyout.status)) {
      navigate(generatePath(routes.buyout, { id: buyout.id }));
    }
  }, [buyout, navigate, statuses]);
};
