import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { routes } from "@/config/routes";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { Navigate } from "react-router";
import { SplittingInvoicesWizardProvider } from "../scanned-invoices/components/splitting-invoices/SplittingInvoicesWizardProvider";
import { InvoiceCreationProvider } from "../scanned-invoices/providers/InvoiceCreationProvider";
import { InvoiceSequenceProvider } from "../scanned-invoices/providers/InvoiceSequenceProvider";
import { InvoiceEmailsHeader } from "./components/list/InvoiceEmailsHeader";
import { InvoiceEmailsList } from "./components/list/InvoiceEmailsList";
import { InvoiceEmailsSideActions } from "./components/list/InvoiceEmailsSideAction";
import { useInvoiceEmails } from "./hooks/useInvoiceEmails";

const InvoiceEmailsWithProvider = () => {
  const { invoiceEmails, loading, totalCount, isFiltered } = useInvoiceEmails();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "SCANNED_INVOICES" })}</title>
      </Helmet>
      <InvoiceEmailsHeader />
      <InvoiceEmailsList
        invoiceEmails={invoiceEmails}
        isFiltered={isFiltered}
        loading={loading}
        totalCount={totalCount}
      />
      <InvoiceEmailsSideActions />
    </>
  );
};

export const InvoiceEmails = () => {
  const { canSeeInbox } = useInvoiceEmails({ skipQuery: true });

  if (!canSeeInbox) {
    return <Navigate to={routes.invoices} />;
  }

  return (
    <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
      <NestedStepperProvider>
        <InvoiceCreationProvider>
          <InvoiceSequenceProvider>
            <SplittingInvoicesWizardProvider>
              <InvoiceEmailsWithProvider />
            </SplittingInvoicesWizardProvider>
          </InvoiceSequenceProvider>
        </InvoiceCreationProvider>
      </NestedStepperProvider>
    </PaginationProvider>
  );
};
