import { DecimalSafe } from "@/common/utils/decimalSafe";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useMemo } from "react";

export const useInvoiceItemReceivedQuantity = (item: ExpandedReleaseItem) => {
  const receivedQtyIsGreaterThanOrdered = useMemo(() => {
    return (
      (item.invoiceItems || [])?.length > 0 &&
      new DecimalSafe(item.receivedQuantityDecimal).greaterThan(
        item.quantityDecimal,
      )
    );
  }, [item.invoiceItems, item.receivedQuantityDecimal, item.quantityDecimal]);

  const invoicedQtyIsGreaterThenReceivedQty = useMemo(() => {
    return (
      (item.invoiceItems || [])?.length > 0 &&
      new DecimalSafe(item.invoiceItems[0].quantity).greaterThan(
        item.receivedQuantityDecimal,
      )
    );
  }, [item.invoiceItems, item.receivedQuantityDecimal]);

  return {
    receivedQtyIsGreaterThanOrdered,
    invoicedQtyIsGreaterThenReceivedQty,
  };
};
