import { routes } from "@/config/routes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseStatus } from "@/generated/graphql";
import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { generatePath, useNavigate, useParams } from "react-router";
import { useOrderFromNote } from "../../../../providers/OrderFromNoteProvider";
import { CreateOrderFromNoteFormValues } from "./CreateOrderFromNoteForm";

export const useCreateOrderFromNoteFooter = () => {
  const navigate = useNavigate();
  const { deliveryId } = useParams();
  const { release } = useRelease();
  const [isSaving, setIsSaving] = useState(false);
  const {
    syncCreateReleaseFromNote,
    syncUpdateReleaseFromNote,
    validateSpreadsheet,
    loading,
  } = useOrderFromNote();
  const [isSaveDialogVisible, setIsSaveDialogVisible] = useState(false);

  const { reset } = useFormContext<CreateOrderFromNoteFormValues>();

  const handleSaveReleaseFromNote = useCallback(
    async (opts?: {
      skipNavigate?: boolean;
      skipVendorNotification?: boolean | undefined;
    }) => {
      if (!(await validateSpreadsheet())) {
        return null;
      }
      let result = null;
      setIsSaving(true);
      if (deliveryId) {
        result = await syncUpdateReleaseFromNote({
          skipVendorNotification: opts?.skipVendorNotification,
        });
      } else {
        result = await syncCreateReleaseFromNote(ReleaseStatus.Draft);
      }
      if (result && !opts?.skipNavigate) {
        navigate(
          generatePath(routes.specifyDeliveryDetails, {
            deliveryId: result.id,
          }),
        );
      }
      setIsSaving(false);
      return result;
    },
    [
      validateSpreadsheet,
      deliveryId,
      syncUpdateReleaseFromNote,
      syncCreateReleaseFromNote,
      navigate,
    ],
  );

  const finalizeOrder = useCallback(async () => {
    if (!release || release.status === ReleaseStatus.Draft) {
      const release = await handleSaveReleaseFromNote({
        skipNavigate: true,
      });
      if (release) {
        navigate(
          generatePath(routes.specifyDeliveryDetails, {
            deliveryId: deliveryId || release.id,
          }),
        );
      }
    } else {
      setIsSaveDialogVisible(true);
    }
  }, [deliveryId, handleSaveReleaseFromNote, navigate, release]);

  const onCancel = useCallback(() => {
    if (release) {
      navigate(
        generatePath(routes.specifyDeliveryDetails, {
          deliveryId: release.id,
        }),
      );
    } else {
      reset();
      navigate(routes.deliveries);
    }
  }, [navigate, release, reset]);

  return {
    onCancel,
    handleSaveReleaseFromNote,
    isSaving,
    loading,
    finalizeOrder,
    isSaveDialogVisible,
    setIsSaveDialogVisible,
  };
};
