import { Price } from "@/common/components/price/Price";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { MAX_COLUMN_WIDTH } from "@/contractor/pages/home/rfq-quotes/constants";
import {
  QuoteStatus,
  RfqItemFieldsFragment,
  RfqQuotesQuoteFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfqQuotes } from "../../../../providers/RfqQuotesProvider";

type CategoryTotalsProps = {
  category: CategoryState<RfqItemFieldsFragment>;
  quote: RfqQuotesQuoteFieldsFragment;
  onClick?: () => void;
  topOffset: number;
};

const CategoryTotalsContainer = tw.div`
  sticky z-20 self-start
  grid justify-end items-center text-right font-bold w-full p-2 
  text-xs h-full
  ${({ $deactivated }: { $deactivated: boolean }) =>
    $deactivated && "text-gray-500"}
`;

const TotalItemCount = tw.div`
  text-gray-600 font-normal
`;

export const CategoryTotals: FC<CategoryTotalsProps> = ({
  category,
  quote,
  onClick,
  topOffset,
}) => {
  const { rfq, allQuoteItems } = useRfqQuotes();
  const { calcExtPrice } = usePriceCalculation();

  const totals = useMemo(() => {
    let unmatchedItems = new DecimalSafe(0);
    let totalPrice = new DecimalSafe(0);

    category.items.forEach((item) => {
      const quoteItem = allQuoteItems.find(
        (qi) => qi.rfqItemId === item.id && qi.quoteId === quote.id,
      );
      if (quoteItem) {
        unmatchedItems = unmatchedItems.add(
          item.quantityDecimal !== quoteItem.quantityDecimal ||
            quoteItem.status === QuoteStatus.Withdrawn
            ? 1
            : 0,
        );
        totalPrice = totalPrice.add(
          calcExtPrice(
            quoteItem.quantityDecimal || 0,
            quoteItem.unitPrice || 0,
          ),
        );
      }
    });

    return {
      unmatchedItems: Number(unmatchedItems),
      totalPrice: Number(totalPrice),
    };
  }, [category, allQuoteItems, quote.id, calcExtPrice]);

  const isDeactivated = useMemo(() => {
    return (
      (rfq?.status === RfqStatus.Awarded ||
        rfq?.status === RfqStatus.Cancelled) &&
      quote.status !== QuoteStatus.Accepted
    );
  }, [rfq?.status, quote.status]);

  const count = useMemo(() => {
    return allQuoteItems.filter(
      (qi) =>
        qi.quoteId === quote.id &&
        category.items.map((i) => i.id).includes(qi.rfqItemId),
    ).length;
  }, [allQuoteItems, quote.id, category.items]);

  return (
    <CategoryTotalsContainer
      onClick={onClick}
      style={{
        top: `${topOffset}px`,
        maxWidth: `${MAX_COLUMN_WIDTH}px`,
      }}
      $deactivated={isDeactivated}
    >
      <Price price={totals.totalPrice} />
      <TotalItemCount>
        <FormattedMessage
          id="PRODUCTS_N"
          values={{
            value: count,
          }}
        />
      </TotalItemCount>
    </CategoryTotalsContainer>
  );
};
