import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import tw from "tailwind-styled-components";
import { InvoiceFooterExpanded } from "./expandable/InvoiceFooterExpanded";
import { InvoiceFooterButtons } from "./InvoiceFooterButtons";
import { InvoiceFooterTotals } from "./InvoiceFooterTotals";

const FloatingFooterStyled = tw(FloatingFooter)``;
const InvoiceFooterContainer = tw.div`flex h-10`;
const Placeholder = tw.div`flex flex-1 max-w-[calc(60%-92px)]`;

export const InvoiceFooter = () => {
  return (
    <FloatingFooterStyled expandedChildren={<InvoiceFooterExpanded />}>
      <InvoiceFooterContainer>
        <Placeholder />
        <InvoiceFooterTotals />
        <InvoiceFooterButtons />
      </InvoiceFooterContainer>
    </FloatingFooterStyled>
  );
};
