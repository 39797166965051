import { DeliveriesDateRangePickerActionBar } from "@/contractor/pages/home/releases/pages/deliveries/components/filters/DeliveriesDateRangePickerActionBar";
import { FC } from "react";
import DateRangePicker from "../date-range-picker/DateRangePicker";
import { ListDatesButton } from "../list-dates-button/ListDatesButton";

type Props = {
  filterDates?: {
    minDate?: number;
    maxDate?: number;
  } | null;
  setFilterDates: (dates: { minDate?: number; maxDate?: number }) => void;
  timeTbdFilter: boolean | undefined;
  setTimeTbdFilter: (value: boolean | undefined) => void;
  labels?: {
    timeTbd?: string;
  };
};

export const ReleaseItemsDateRangeFilter: FC<Props> = ({
  filterDates,
  setFilterDates,
  timeTbdFilter,
  setTimeTbdFilter,
  labels,
}) => {
  return (
    <ListDatesButton
      hideDates
      dates={[
        {
          startDate: filterDates?.minDate,
          endDate: filterDates?.maxDate,
        },
      ]}
      clearValues={() => {
        setFilterDates({
          minDate: undefined,
          maxDate: undefined,
        });
        setTimeTbdFilter(false);
      }}
      classes={{
        icon: "max-w-4 bg-transparent text-black",
        buttonContent: "bg-transparent",
      }}
      isDefault={true}
      includeIconLabel={false}
    >
      {(onClose) => (
        <DateRangePicker
          staticView
          disabled={timeTbdFilter}
          onClose={onClose}
          selectedValue={
            filterDates?.maxDate || filterDates?.minDate
              ? {
                  startDate: filterDates?.minDate
                    ? new Date(filterDates.minDate)
                    : new Date(),
                  endDate: filterDates?.maxDate
                    ? new Date(filterDates.maxDate)
                    : new Date(),
                }
              : undefined
          }
          onChange={(dateRange) => {
            setFilterDates({
              minDate: dateRange.startDate?.getTime(),
              maxDate: dateRange.endDate?.getTime(),
            });
          }}
          onClear={() => {
            setFilterDates({
              minDate: undefined,
              maxDate: undefined,
            });
            setTimeTbdFilter(false);
          }}
          customActionBar={() => (
            <DeliveriesDateRangePickerActionBar
              timeTbd={timeTbdFilter}
              setTimeTbd={setTimeTbdFilter}
              hideItemsDeliveryDates
              labels={labels}
            />
          )}
        />
      )}
    </ListDatesButton>
  );
};
