import { useDialog } from "@/common/components/dialog/DialogProvider";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { routes } from "@/config/routes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  NoteDocumentFieldsFragment,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { useOrderFromNote } from "../../../providers/OrderFromNoteProvider";

export const useReplaceDocumentOnOrder = () => {
  const [updateContractorRelease] = useUpdateContractorReleaseMutation();
  const { release } = useRelease();
  const { setError } = useGlobalError();
  const { setWasDocumentReplaced } = useOrderFromNote();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const intl = useIntl();

  const handleReplaceDocument = useCallback(
    async (document: NoteDocumentFieldsFragment) => {
      if (release) {
        try {
          const { errors, data } = await updateContractorRelease({
            variables: {
              input: {
                releaseId: release.id,
                version: release.version,
                noteDocumentId: document.id,
              },
            },
          });
          setError(errors);
          if (data) {
            setWasDocumentReplaced(true);
            navigate(
              generatePath(routes.editDeliveryFromNote, {
                noteDocumentId: document.id,
                deliveryId: release.id,
              }),
            );
          }
        } catch (error) {
          setError(error);
        }
      } else if (document) {
        openDialog({
          content: (
            <SuccessModal message={intl.$t({ id: "NOTE_DOCUMENT_REPLACED" })} />
          ),
          closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        });
        setTimeout(() => {
          navigate({
            pathname: generatePath(routes.deliveryFromNote, {
              noteDocumentId: document?.id,
            }),
          });
        }, DIALOG_AUTO_CLOSE_TIMER);
      }
    },
    [
      release,
      updateContractorRelease,
      setError,
      setWasDocumentReplaced,
      navigate,
      openDialog,
      intl,
    ],
  );

  return handleReplaceDocument;
};
