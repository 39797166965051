import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import {
  MAX_PRICE_DECIMALS,
  MAX_QUANTITY_DECIMALS,
  PDF_FONT,
  PDF_LEFT_SPACING,
  TOTAL_PRICE_DECIMAL_POINTS,
} from "@/common/const";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { formatHeader } from "@/common/utils/pdf-print/formatHeader";
import { DistributorReleaseFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";
import { ZoneCategory } from "../../providers/DistributorReleaseItemsZoneProvider";

const HEADER_WITH_ISSUES = [
  "",
  "ITEM_DESCRIPTION",
  "TAGS",
  "MANUFACTURER",
  "RELEASE_QUANTITY",
  "UNIT_COST",
  "EXT_PRICE",
  "DELIVERY_ISSUES",
  "SOLUTION",
];

const HEADER = [
  "",
  "ITEMS_IN_DELIVERY",
  "TAGS",
  "MANUFACTURER",
  "QUANTITY",
  "UNIT_COST",
  "EXT_PRICE",
];

const getHeader = ({
  hasIssues,
  includeTaxableColumn,
}: {
  hasIssues: boolean;
  includeTaxableColumn: boolean;
}) => {
  let header = hasIssues ? HEADER_WITH_ISSUES : HEADER;
  if (includeTaxableColumn) {
    header = [...header, "TAXABLE"];
  }
  return header;
};

export const itemsForPdf = (
  release: DistributorReleaseFieldsFragment,
  intl: IntlShape,
  zones: ZoneCategory[],
  header: string[],
  hasManufacturersSetting: boolean,
  groupedByCostCode: boolean,
  formatCurrency: FormatCurrencyType,
  includeTaxableColumn: boolean,
): RowInput[] => {
  const result: RowInput[] = [];
  let count = 0;
  const hasIssues = release.issues.length > 0;

  const usePhaseCodes = zones.some((zone) =>
    zone.items.some((costCode) =>
      costCode.items.some((item) => item.tags.some((tag) => tag.hasMapping)),
    ),
  );
  zones.forEach((zone) => {
    if (
      !(zones.length === 1 && zones[0].id === UNSPECIFIED_ZONE_ID) &&
      zone.items.some((costCode) =>
        costCode.items.some(
          (i) =>
            !i.alternativeFulfillmentRelease && !i.alternativeFulfillmentTime,
        ),
      )
    ) {
      result.push([
        {
          content: `${zone.name} (${intl.$t({ id: "ZONE" })})`,
          colSpan: header.length,
          styles: {
            fontStyle: "bold",
            cellPadding: { left: 7, top: 3, bottom: 3 },
          },
        },
      ]);
    }
    zone.items.forEach((costCode) => {
      const items = costCode.items.filter(
        (i) =>
          !i.alternativeFulfillmentRelease && !i.alternativeFulfillmentTime,
      );
      let formattedCostCode = groupedByCostCode
        ? costCode.id === UNSPECIFIED_COST_CODE_ID
          ? intl.$t({ id: "UNSPECIFIED" })
          : costCode.name
        : "";
      if (items.length > 0 && groupedByCostCode) {
        result.push([
          {
            content: `${formattedCostCode} (${intl.$t({
              id: !usePhaseCodes ? "COST_CODE" : "PHASE_CODE",
            })})`,
            colSpan: header.length,
            styles: {
              fontStyle: "bold",
              cellPadding: { left: 7, top: 3, bottom: 3 },
            },
          },
        ]);
      }
      items.forEach((releaseItem) => {
        const quantity = `${intl.formatNumber(
          Number(releaseItem.quantityDecimal),
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: MAX_QUANTITY_DECIMALS,
          },
        )}\n${releaseItem.uom?.mnemonic || releaseItem.uom?.pluralDescription}`;

        const totalPriceForItem = new DecimalSafe(
          releaseItem.unitPrice || 0,
        ).mul(releaseItem.quantityDecimal);
        if (!groupedByCostCode) {
          formattedCostCode = releaseItem.costCode
            ? releaseItem.costCode.description
            : intl.$t({ id: "UNSPECIFIED_COST_CODE" });
        }
        const itemName =
          releaseItem.name ||
          releaseItem.projectItem?.material.material.name ||
          "";

        count++;
        result.push([
          count,
          `${itemName}${groupedByCostCode ? "" : ` (${formattedCostCode})`}${
            releaseItem.instructions?.text
              ? `\n     ${intl.$t({
                  id: "CONTRACTOR_NOTE_LABEL",
                })} ${releaseItem.instructions?.text}`
              : ""
          }`,
          releaseItem.tags.map((tag) => tag.name).join(", ") || "-",
          ...(hasManufacturersSetting
            ? [
                releaseItem.manufacturer?.name ||
                  intl.$t({ id: "ANY_MANUFACTURER" }),
              ]
            : []),
          isLumpSumItem(releaseItem) ? "--" : quantity,
          isLumpSumItem(releaseItem)
            ? "--"
            : formatCurrency(releaseItem.unitPrice, {
                maximumFractionDigits: MAX_PRICE_DECIMALS,
              }),
          formatCurrency(totalPriceForItem, {
            maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
          }),
          ...(hasIssues
            ? [
                releaseItem.issues?.length > 0
                  ? `${releaseItem.issues[0].quantityDecimal} ${
                      releaseItem.uom?.mnemonic ||
                      releaseItem.uom?.pluralDescription
                    } ${intl.$t({
                      id: `RELEASE_ISSUE_${releaseItem.issues[0].issueType}`,
                    })}${
                      releaseItem.issues[0]?.description
                        ? `\n\n${releaseItem.issues[0]?.description}`
                        : ""
                    }`
                  : "",
                releaseItem.issues?.[0]?.resolution
                  ? `${intl.$t({
                      id: releaseItem.issues?.[0].resolution.resolutionType,
                    })}\n\n${releaseItem.issues?.[0].resolution.comments}`
                  : "",
              ]
            : []),
          ...(includeTaxableColumn
            ? [
                releaseItem.taxable
                  ? intl.$t({ id: "YES" })
                  : intl.$t({ id: "NO" }),
              ]
            : []),
        ]);
      });
    });
  });

  return result;
};

export const materials = (
  doc: jsPDF,
  release: DistributorReleaseFieldsFragment,
  intl: IntlShape,
  zones: ZoneCategory[],
  startY: number,
  hasManufacturersSetting: boolean,
  groupedByCostCode: boolean,
  formatCurrency: FormatCurrencyType,
  includeTaxableColumn: boolean,
) => {
  const header = getHeader({
    hasIssues: release.issues.length > 0,
    includeTaxableColumn,
  });
  doc
    .setFont(PDF_FONT, "", "bold")
    .setFontSize(10)
    .text(intl.$t({ id: "ORDERED_ITEMS" }), PDF_LEFT_SPACING, startY);
  autoTable(doc, {
    theme: "grid",
    styles: {
      font: PDF_FONT,
      fontSize: 8,
    },
    startY: startY + 5,
    margin: { top: 35 },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
      valign: "middle",
      halign: "center",
      cellPadding: { top: 2, bottom: 1, left: 2, right: 2 },
    },
    columnStyles: {
      0: { halign: "center", valign: "middle" },
      1: { valign: "middle" },
      2: { halign: "center", valign: "middle" },
      3: { halign: "center", valign: "middle" },
      4: { halign: "center", valign: "middle" },
      5: { halign: "right", valign: "middle" },
      6: { halign: "right", valign: "middle" },
      7: { halign: "center", valign: "middle" },
    },
    head: [
      header
        .filter((h) => hasManufacturersSetting || h !== "MANUFACTURER")
        .map((name) => formatHeader(name, intl)),
    ],
    body: [
      ...itemsForPdf(
        release,
        intl,
        zones,
        header,
        hasManufacturersSetting,
        groupedByCostCode,
        formatCurrency,
        includeTaxableColumn,
      ),
    ],
    didParseCell: function (data) {
      if (
        data.section === "head" &&
        data.column.index >= 5 &&
        data.column.index <= 6
      ) {
        data.cell.styles.halign = "right";
      }
    },
  });
};
