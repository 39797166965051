import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useEnabledPoInSourceSystem } from "@/common/hooks/useEnabledPoInSourceSystem";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  IntegrationType,
  ReleaseStatus,
  SourceSystem,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useRelease } from "../providers/ReleaseProvider";

export enum ReleaseExportButtonView {
  Immutable = "Immutable",
  FoundationIntegration = "FoundationIntegration",
  GenericIntegration = "GenericIntegration",
  PoLinkOrPoExists = "PoLinkOrPoExists",
  Connect = "Connect",
}

export const useReleaseExportButtons = () => {
  const { release } = useRelease();
  const { connectedIntegrationType, connectedSourceSystem } = useOrgSettings();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();

  const getReleaseExportButtons = useCallback(() => {
    const buttons = [];

    const isIncluded = !checkReleaseStatus(release, [
      ReleaseStatus.Canceled,
      ReleaseStatus.AwaitingApproval,
      ReleaseStatus.Rejected,
    ]);

    if (
      release &&
      release?.sellerOrgLocation &&
      isIncluded &&
      isPoEnabledInSourceSystem &&
      (includePoNumbering || release.poLink || release.poExists)
    ) {
      if (!!release.poLink?.immutable) {
        return [ReleaseExportButtonView.Immutable];
      }

      if (
        connectedIntegrationType &&
        connectedIntegrationType === IntegrationType.Foundation &&
        (!connectedSourceSystem ||
          connectedSourceSystem === SourceSystem.Foundation)
      ) {
        buttons.push(ReleaseExportButtonView.FoundationIntegration);
      }

      if (
        connectedIntegrationType &&
        !connectedSourceSystem &&
        connectedIntegrationType !== IntegrationType.Foundation
      ) {
        buttons.push(ReleaseExportButtonView.GenericIntegration);
      }

      if (
        connectedSourceSystem &&
        !(
          connectedIntegrationType === IntegrationType.Foundation &&
          connectedSourceSystem === SourceSystem.Foundation &&
          !release.poLink?.syncedAt
        )
      ) {
        if (release.poLink || release.poExists) {
          buttons.push(ReleaseExportButtonView.PoLinkOrPoExists);
        }
        if (!release.poLink && !release.buyout?.poLink) {
          buttons.push(ReleaseExportButtonView.Connect);
        }
      }
    }

    return buttons;
  }, [
    connectedIntegrationType,
    connectedSourceSystem,
    includePoNumbering,
    isPoEnabledInSourceSystem,
    release,
  ]);

  return {
    getReleaseExportButtons,
  };
};
