import { PoNumberInput } from "@/common/components/po-number/PoNumberInput";
import { useUser } from "@/common/providers/UserProvider";
import {
  BuyoutFieldsFragment,
  PoNumberingMode,
  useProjectPredictedPoNumberQuery,
} from "@/generated/graphql";
import { FC, useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

const Container = tw.div`relative hidden lg:inline-grid`;

type Props = {
  buyout: Pick<BuyoutFieldsFragment, "version" | "poNumber" | "id" | "project">;
  readonly: boolean;
};

export const BuyoutPONumber: FC<Props> = ({ buyout, readonly }) => {
  const { poNumbering } = useUser();
  const { updateBuyout } = useContractorBuyout();

  const { data: predictedPoNumberData } = useProjectPredictedPoNumberQuery({
    variables: {
      id: buyout.project.id,
    },
    skip: !buyout.project.id || poNumbering === PoNumberingMode.Never,
    fetchPolicy: "no-cache",
  });

  const [poNumber, setPONumber] = useState(buyout.poNumber || "");

  useEffect(() => {
    setPONumber(buyout.poNumber || "");
  }, [buyout.poNumber]);

  return (
    <Container>
      <PoNumberInput
        value={poNumber}
        onChange={setPONumber}
        hideLabel
        onBlur={(value) =>
          updateBuyout({
            buyoutId: buyout.id,
            version: buyout.version,
            poNumber: value ?? poNumber ?? undefined,
          })
        }
        autoFillValue={
          !poNumber && !readonly
            ? predictedPoNumberData?.project?.predictedPoNumber || ""
            : undefined
        }
        staticText={readonly}
        autoSize
      />
    </Container>
  );
};
