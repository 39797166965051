import { TagPicker } from "@/common/components/tag-picker/TagPicker";
import { useProjectTags } from "@/contractor/pages/home/project/hooks/useProjectTags";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";

type Props = {
  item?: BuyoutItemFieldsFragment;
  visibleTags?: number;
};

export const BuyoutTagsPicker: FC<Props> = ({ item, visibleTags = 3 }) => {
  const { allTags } = useProjectTags();

  return (
    <TagPicker
      options={allTags}
      values={item?.tags ?? []}
      visibleTags={visibleTags}
      readonly
    />
  );
};
