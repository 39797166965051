import { useMemo } from "react";
import { CustomPaymentTermsProps } from "../types/CustomPaymentTermsProps";

const selectInputPropsClassName = "-mr-1 pl-1.5";
export const useCustomPaymentTermsStyles = ({
  classes,
  hideLabel,
  readonly,
  staticText,
}: Pick<
  CustomPaymentTermsProps,
  "classes" | "hideLabel" | "readonly" | "staticText"
>) => {
  const containerClassName = useMemo(
    () =>
      `${hideLabel ? "justify-end" : "justify-between"}${classes?.container ? ` ${classes.container}` : ""}`,
    [hideLabel, classes],
  );

  const inputPropsClassName = useMemo(() => {
    if (!readonly && !staticText) {
      return classes?.select
        ? `${selectInputPropsClassName} ${classes.select}`
        : selectInputPropsClassName;
    }

    return `text-right ${staticText ? "" : "bg-white"}${classes?.select ? ` ${classes.select}` : ""}`;
  }, [readonly, staticText, classes]);

  return { containerClassName, inputPropsClassName };
};
