import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { useImportExternalPoStore } from "@/common/components/import-external-po/stores/useImportExternalPoStore";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { useSourceSystemLogo } from "@/common/hooks/integrations/source-system-config/useSourceSystemLogo";
import { useEnabledPoInSourceSystem } from "@/common/hooks/useEnabledPoInSourceSystem";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ExternalPOModal } from "@/contractor/pages/home/common/external-po/ExternalPOModal";
import { ReleaseStatus } from "@/generated/graphql";
import { Menu, MenuItem } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaChevronDown } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlipImportExternalPO } from "../../../../hooks/useDeliverySlipImportExternalPO";
import {
  PackingSlipMatchViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipReleaseFormValues } from "../delivery-slip-form/DeliverySlipVerificationForm";

const MenuButton = tw(
  PrimaryButton,
)`w-6 h-6 min-w-6 max-h-6 rounded-xl cursor-pointer h-full items-center justify-center mr-2`;
const Row = tw.div`flex flex-row items-center justify-center`;
const Header = tw(Row)`justify-between mb-4 px-3`;
const ChevronDownStyled = tw(FaChevronDown)`text-base mt-0.5`;
const Title = tw.div`text-base font-medium ml-1 mt-0.5`;
const LinkStyled = tw(LinkLike)`flex flex-row`;
const SourceSystemLogo = tw.img`mr-2 w-6 h-6`;
const RightView = tw.div`flex self-stretch justify-end mr-2`;

export const DeliverySlipSelectModeHeader = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const {
    deliverySlip,
    setPackingSlipMatchViewState,
    packingSlipMatchViewState,
    selectedRelease,
    setSelectedRelease,
  } = useDeliverySlipVerification();
  const { importModalOpened, openModal, importedItems, closeModal, reset } =
    useDeliverySlipImportExternalPO();
  const { sourceSystemLogo } = useSourceSystemLogo(connectedSourceSystem, true);
  const { setValue } = useFormContext<DeliverySlipReleaseFormValues>();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();
  const resetImportPoStore = useImportExternalPoStore((state) => state.reset);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [predictedReleasePOModalOpened, setPredictedReleasePOModalOpened] =
    useState(false);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const options = useMemo(() => {
    const buttons = [
      {
        label: intl.$t(
          {
            id:
              deliverySlip?.predictedRelease?.id === selectedRelease?.id
                ? "PACKING_SLIP_MATCHED_TO_ORDER"
                : "SELECT_EXISTING_ORDER_FOR_THIS_SLIP",
          },
          { orderNumber: deliverySlip?.predictedRelease?.sequenceNumber },
        ),
        onClick: () => {
          resetImportPoStore();
          setPackingSlipMatchViewState(PackingSlipMatchViewState.MATCH_ORDER);
          handleClose();
        },
        selected:
          packingSlipMatchViewState === PackingSlipMatchViewState.MATCH_ORDER,
      },
      {
        label: intl.$t({ id: "CREATE_NEW_ORDER_FROM_PACKING_SLIP" }),
        onClick: () => {
          setSelectedRelease(null);
          closeModal();
          reset();
          resetImportPoStore();
          if (deliverySlip?.poNumber) {
            setValue("poNumber", deliverySlip.poNumber);
          }
          setValue("vendorId", "");
          setPackingSlipMatchViewState(PackingSlipMatchViewState.CREATE_ORDER);
          handleClose();
        },
        selected:
          packingSlipMatchViewState ===
            PackingSlipMatchViewState.CREATE_ORDER && !importedItems,
      },
    ];
    if (connectedSourceSystem && isPoEnabledInSourceSystem) {
      buttons.push({
        label: intl.$t(
          { id: "IMPORT_PO_FROM_SYSTEM_AS_NEW_ORDER" },
          {
            system: connectedSourceSystem
              ? intl.$t({
                  id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                })
              : "",
          },
        ),
        onClick: () => {
          resetImportPoStore();
          handleClose();
          openModal({ skipPoList: false });
        },
        selected:
          packingSlipMatchViewState ===
            PackingSlipMatchViewState.CREATE_ORDER && !!importedItems,
      });
    }

    return buttons;
  }, [
    intl,
    deliverySlip?.predictedRelease?.id,
    deliverySlip?.predictedRelease?.sequenceNumber,
    deliverySlip?.poNumber,
    selectedRelease?.id,
    packingSlipMatchViewState,
    importedItems,
    connectedSourceSystem,
    isPoEnabledInSourceSystem,
    setPackingSlipMatchViewState,
    handleClose,
    setSelectedRelease,
    closeModal,
    reset,
    setValue,
    openModal,
    resetImportPoStore,
  ]);

  return (
    <>
      <Header>
        <Row>
          <MenuButton onClick={handleClick}>
            <ChevronDownStyled />
          </MenuButton>
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            variant="selectedMenu"
            onClose={handleClose}
            keepMounted
          >
            {options.map((item, index) => (
              <MenuItem
                key={index}
                onClick={item.onClick}
                selected={item.selected}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
          <Title>{options.find((o) => o.selected)?.label}</Title>
          <If
            isTrue={
              packingSlipMatchViewState ===
                PackingSlipMatchViewState.CREATE_ORDER && !importModalOpened
            }
          >
            <ReleaseStatusChip
              className="ml-2 bg-gray-100"
              key="status"
              status={ReleaseStatus.Draft}
              type="breadcrumb"
              releaseType={undefined}
            />
          </If>
        </Row>
        <If
          isTrue={
            connectedSourceSystem &&
            selectedRelease &&
            deliverySlip?.predictedRelease?.id === selectedRelease.id
          }
        >
          <RightView>
            <LinkStyled onClick={() => setPredictedReleasePOModalOpened(true)}>
              <SourceSystemLogo src={sourceSystemLogo} />
              <FormattedMessage
                id="PREVIEW_PO_NUMBER"
                values={{ number: deliverySlip?.predictedRelease?.poNumber }}
              />
            </LinkStyled>
          </RightView>
        </If>
      </Header>
      <ExternalPOModal
        poNumber={deliverySlip?.poNumber}
        projectId={deliverySlip?.predictedRelease?.project?.id}
        opened={predictedReleasePOModalOpened}
        goBack={() => setPredictedReleasePOModalOpened(false)}
      />
    </>
  );
};
