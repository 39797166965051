import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { SuccessIcon } from "@/common/components/dialog-icons/SuccessIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import { routes } from "@/config/routes";
import {
  BuyoutStatus,
  DistributorBuyoutFieldsFragment,
} from "@/generated/graphql";
import { NoFunctionPromise } from "@/types/NoFunction";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useLocation, useNavigate } from "react-router";
import { ButtonContainer, Footer } from "../common/Footer.styles";
import { useDistributorBuyout } from "../providers/DistributorBuyoutProvider";

type Props = {
  buyout: DistributorBuyoutFieldsFragment;
};

export const NonQuotedBuyoutFooter: FC<Props> = ({ buyout }: Props) => {
  const { acceptBuyout, declineBuyout, setValidationError } =
    useDistributorBuyout();
  const { openDialog } = useDialog();
  const intl = useIntl();
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleAccept = useCallback(async () => {
    if (await acceptBuyout()) {
      openDialog({
        cancelButtonText: null,
        confirmButtonText: intl.$t({ id: "CLOSE" }),
        title: intl.$t({ id: "SUCCESS" }),
        text: intl.$t({ id: "BUYOUT_SUBMIT_SUCCESS" }),
        handleConfirm: NoFunctionPromise,
        icon: <SuccessIcon />,
        fullScreenCentered: true,
      });
      if (!buyout.releases.length) {
        return;
      }
      const base = generatePath(routes.distributorDelivery, {
        deliveryId: buyout.releases[0].id,
      });
      navigate(`${base}${search}`);
    } else {
      setValidationError(true);
    }
  }, [
    openDialog,
    intl,
    acceptBuyout,
    buyout.releases,
    navigate,
    search,
    setValidationError,
  ]);

  const handleDecline = useCallback(async () => {
    if (await declineBuyout()) {
      openDialog({
        cancelButtonText: null,
        confirmButtonText: intl.$t({ id: "CLOSE" }),
        title: intl.$t({ id: "SUCCESS" }),
        text: intl.$t({ id: "BUYOUT_REJECT_SUCCESS" }),
        handleConfirm: NoFunctionPromise,
        icon: <SuccessIcon />,
        fullScreenCentered: true,
      });
    }
  }, [declineBuyout, intl, openDialog]);

  const onAccept = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "ACCEPT" }),
      includeWarningIcon: true,
      title: intl.$t(
        { id: "ACCEPT_BUYOUT_QUESTION" },
        { number: buyout?.clientIdentifier },
      ),
      text: intl.$t({ id: "ACCEPT_BUYOUT_TEXT" }),
      handleConfirm: handleAccept,
      closeOnConfirm: false,
    });
  }, [buyout?.clientIdentifier, handleAccept, intl, openDialog]);

  const onDecline = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "ACCEPT" }),
      includeWarningIcon: true,
      title: intl.$t(
        { id: "REJECT_BUYOUT_QUESTION" },
        { number: buyout?.clientIdentifier },
      ),
      text: intl.$t({ id: "REJECT_BUYOUT_TEXT" }),
      handleConfirm: handleDecline,
      closeOnConfirm: false,
    });
  }, [buyout?.clientIdentifier, handleDecline, intl, openDialog]);

  return (
    <Footer>
      <If isTrue={checkBuyoutStatus(buyout, [BuyoutStatus.Requested])}>
        <FloatingFooter>
          <ButtonContainer>
            <OutlinedButton onClick={onDecline}>
              <FormattedMessage id="REJECT_BUYOUT" />
            </OutlinedButton>
            <LoadingButton
              button={PrimaryButton}
              onClick={onAccept}
              testId="accept-buyout-button"
            >
              <FormattedMessage id="ACCEPT_BUYOUT" />
            </LoadingButton>
          </ButtonContainer>
        </FloatingFooter>
      </If>
    </Footer>
  );
};
