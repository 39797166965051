import { If } from "@/common/components/if/If";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { MAX_COLUMN_WIDTH } from "@/contractor/pages/home/rfq-quotes/constants";
import {
  RfqQuotesAuxiliaryQuoteItemFieldsFragment,
  RfqQuotesQuoteFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfqQuotes } from "../../../../providers/RfqQuotesProvider";
import { ExpirationRow } from "../../rfq-items-leveling/rfq-item/quote-item-card/ExpirationRow";
import { ManufacturerRow } from "../../rfq-items-leveling/rfq-item/quote-item-card/ManufacturerRow";
import { PricePositionRow } from "../../rfq-items-leveling/rfq-item/quote-item-card/PricePositionRow";
import { UnitPriceRow } from "../../rfq-items-leveling/rfq-item/quote-item-card/UnitPriceRow";
import { ItemDetailsRow } from "./ItemDetailsRow";
import { useAuxItemCard } from "./useAuxItemCard";

type Props = {
  item: RfqQuotesAuxiliaryQuoteItemFieldsFragment;
  quote: RfqQuotesQuoteFieldsFragment;
};

type ContainerProps = {
  $selected: boolean;
  $canBeSelected: boolean;
  $awarded: boolean;
};
const CardContainer = tw.div<ContainerProps>`
  grid relative w-full transition z-10 content-start h-fit
  rounded-3xl overflow-hidden border p-2 cursor-pointer border-gray-400 bg-white
  ${(p) => p.$awarded && `border-green-300 border-2 p-[7px]`}
  ${(p) => p.$selected && `border-2 border-blue-500 p-[7px] bg-blue-500 bg-opacity-10`}
  ${(p) => p.$canBeSelected && `hover:border-blue-500`}
`;

const Container = tw.div`
  relative block
`;

const AwardedDecoration = tw.div`
  absolute -top-2 left-2.5 bg-green-300 text-black font-normal py-0.5 px-1 rounded-[4px] z-20 text-2xs
`;

const Separator = tw.div`
  border-b border-gray-400 border-dashed h-0.5 mx-4
`;

export const AuxItemCard: FC<Props> = ({ item, quote }) => {
  const { hasManufacturersSetting } = useManufacturersSettings();
  const { rfq } = useRfqQuotes();
  const { isSelected, isAwarded, selectAuxItem } = useAuxItemCard({
    item,
    quote,
  });

  const manufacturerName = useMemo(
    () => item?.manufacturer?.name,
    [item?.manufacturer?.name],
  );

  const totalPrice = useMemo(() => {
    return (
      new DecimalSafe(item?.unitPrice ?? 0)
        .mul(item?.quantityDecimal ?? 0)
        .toNumber() || 0
    );
  }, [item?.quantityDecimal, item?.unitPrice]);

  return (
    <Container>
      <If isTrue={isAwarded && !isSelected}>
        <AwardedDecoration>
          <FormattedMessage id="ACCEPTED_QUOTE" />
        </AwardedDecoration>
      </If>
      <CardContainer
        data-testid="quote-item-card"
        $selected={isSelected}
        $awarded={isAwarded}
        $canBeSelected={rfq?.status === RfqStatus.Active}
        onClick={selectAuxItem}
        style={{
          maxWidth: `${MAX_COLUMN_WIDTH}px`,
        }}
      >
        <ItemDetailsRow name={item?.description} />
        <If isTrue={hasManufacturersSetting}>
          <ManufacturerRow
            manufacturerName={manufacturerName}
            notes={item?.notes}
            itemId={item?.id}
            assets={item?.assets}
          />
        </If>
        <Separator />
        <UnitPriceRow
          quantityDiff={0}
          quantityDecimal={item?.quantityDecimal}
          unit={item.uom?.mnemonic || ""}
          unitPrice={Number(item?.unitPrice)}
        />
        <If isTrue={item?.expirationDate}>
          <Separator />
          <ExpirationRow expirationDate={item?.expirationDate} />
        </If>
        <PricePositionRow
          price={totalPrice}
          isSelected={isSelected}
          leadTimeDays={item?.leadTimeDays}
        />
      </CardContainer>
    </Container>
  );
};
