import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback } from "react";
import { useInvoiceMatchedOrder } from "../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";

export const useInvoiceReleaseEditInfo = () => {
  const { release } = useRelease();
  const { invoice, updateInvoice } = useInvoiceVerification();
  const { setUseInvoiceItemPrice } = useInvoiceMatchedOrder();

  const handleSetSpecifyInvoicePrice = useCallback(
    async (value: boolean) => {
      await updateInvoice(
        {
          id: invoice?.id || "",
          updatedInvoicedReleaseItems: (release?.items ?? [])
            .filter((releaseItem) => !!releaseItem.invoiceItems.length)
            .flatMap((releaseItem) =>
              releaseItem.invoiceItems.map((releaseInvoiceItem) => ({
                id: releaseInvoiceItem.id,
                quantity: releaseInvoiceItem.quantity,
                quantitySoFar: releaseInvoiceItem.quantitySoFar,
                releaseItemId: releaseItem.id,
                ...(value
                  ? {
                      unitPrice: isLumpSumItem(releaseItem)
                        ? "1"
                        : releaseItem.unitPrice,
                      clearUnitPrice: undefined,
                    }
                  : {
                      clearUnitPrice: true,
                      unitPrice: releaseItem.unitPrice,
                    }),
              })),
            ),
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
      setTimeout(() => {
        setUseInvoiceItemPrice(value);
      });
    },
    [release, invoice, setUseInvoiceItemPrice, updateInvoice],
  );

  return {
    handleSetSpecifyInvoicePrice,
  };
};
