import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Price } from "@/common/components/price/Price";
import { FC, ReactNode, useCallback } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../../../providers/InvoiceVerificationProvider";
import { useInvoicesCards } from "./hooks/useInvoicesCards";

type Props = {
  expanded?: boolean;
};

type SlipProps = {
  $active?: boolean;
};
const SlipCard = tw.div<SlipProps>`
  grid grid-flow-col py-4 px-4 gap-4 w-fit bg-white border rounded-lg
  ${({ $active }) => ($active ? "bg-green-100 border-green-800 cursor-none" : "border-gray-400 hover:bg-blue-100")}
`;
const InvoiceDetailsContainer = tw.div`grid ml-2`;
const InvoiceDetailsItem = tw.div`text-xs text-black font-normal`;

export const InvoicesCard: FC<Props> = ({ expanded }) => {
  const intl = useIntl();
  const { getInvoiceFromUrl, changeInvoice } = useInvoicesCards();
  const { invoice } = useInvoiceVerification();

  const getImageWrapper = useCallback(
    (url: string, children: ReactNode) => {
      const currentInvoice = getInvoiceFromUrl(url);
      return (
        <SlipCard $active={currentInvoice?.id === invoice?.id}>
          <LinkLike onClick={() => changeInvoice(currentInvoice)}>
            {children}
          </LinkLike>
        </SlipCard>
      );
    },
    [getInvoiceFromUrl, invoice?.id, changeInvoice],
  );

  const getInvoiceDetails = useCallback(
    (parentId: string) => {
      const currentInvoice = getInvoiceFromUrl(parentId);
      return (
        <InvoiceDetailsContainer>
          <InvoiceDetailsItem>
            <FormattedMessage id={`INVOICE_STATUS_${currentInvoice?.status}`} />
          </InvoiceDetailsItem>
          <If isTrue={currentInvoice?.number}>
            <InvoiceDetailsItem>#{currentInvoice?.number}</InvoiceDetailsItem>
          </If>
          {currentInvoice?.issueDate && (
            <InvoiceDetailsItem>
              <FormattedDate value={new Date(currentInvoice?.issueDate)} />
            </InvoiceDetailsItem>
          )}
          <InvoiceDetailsItem>
            <Price price={currentInvoice?.total} />
          </InvoiceDetailsItem>
        </InvoiceDetailsContainer>
      );
    },
    [getInvoiceFromUrl],
  );

  return (
    <AssetsCard
      headerClassName="bg-blue-100"
      title={intl.$t({ id: "INVOICES_FOR_THIS_ORDER" })}
      expanded={expanded}
      includeTooltip={false}
      includeNavigation={false}
      assets={[
        ...(invoice?.release?.invoices.map((i) => ({
          ...i.documents[i.documents.length - 1].asset,
          parentId: i.id,
        })) || []),
      ]}
      imageDetails={getInvoiceDetails}
      getImageWrapper={getImageWrapper}
    />
  );
};
