import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useUnlinkInvoice } from "../../../../scanned-invoices/hooks/useUnlinkInvoice";
import { InvoiceExportStatusEnum } from "../enums/InvoiceExportStatusEnum";
import { InvoiceExportStatusProps } from "../types/InvoiceExportStatusProps";

export const useInvoiceExportStatus = ({
  invoice,
}: Pick<InvoiceExportStatusProps, "invoice">) => {
  const intl = useIntl();
  const { connectedIntegrationType, connectedSourceSystem } = useOrgSettings();
  const { unlinkInvoice } = useUnlinkInvoice();
  const { openDialog } = useDialog();

  const unlink = useCallback(() => {
    openDialog({
      title: intl.$t({ id: "DISCONNECT_INVOICE" }),
      content: intl.$t({ id: "DISCONNECT_INVOICE_CONFIRMATION" }),
      handleConfirm: async () => {
        await unlinkInvoice(invoice.link?.id);
      },
      includeWarningIcon: true,
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "DISCONNECT" }),
    });
  }, [openDialog, intl, unlinkInvoice, invoice.link?.id]);

  const status = useMemo(() => {
    if (invoice.link?.failedAt) {
      return InvoiceExportStatusEnum.Failed;
    }
    if (invoice.link?.exportedAt) {
      return InvoiceExportStatusEnum.OK;
    }
    return InvoiceExportStatusEnum.InProgress;
  }, [invoice.link?.failedAt, invoice.link?.exportedAt]);

  const integrationLabel = useMemo(
    () =>
      connectedSourceSystem
        ? `INTEGRATION_${connectedSourceSystem}`
        : `INTEGRATION_${connectedIntegrationType}`,
    [connectedIntegrationType, connectedSourceSystem],
  );

  return {
    status,
    unlink,
    integrationLabel,
  };
};
