import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import {
  PreviewInvoiceTemplateDocument,
  PreviewPoTemplateDocument,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { TemplateType } from "../enums/TemplateType.enum";
import { TemplateVariablesEnum } from "../enums/TemplateVariables.enum";

type Template = {
  value: string | null;
  preview: string | null;
};

type State = {
  templates: Record<TemplateType, Template>;
  setTemplate: (
    templateType: TemplateType,
    value: string | null,
    force?: boolean,
  ) => void;
  templateDefaults: Record<TemplateVariablesEnum, string>;
  updateDefaults: (defaults: Record<TemplateVariablesEnum, string>) => void;
};

const poExcludeVariables: TemplateVariablesEnum[] = [
  "InvoiceDescription" as TemplateVariablesEnum,
  "InvoiceNumber" as TemplateVariablesEnum,
  "InvoicePONumber" as TemplateVariablesEnum,
  "InvoiceShortURL" as TemplateVariablesEnum,
];

export const useTemplatePreviewStore = create<State>()(
  devtools((set, get) => ({
    templates: {
      [TemplateType.PoName]: {
        value: "",
        preview: "",
      },
      [TemplateType.PoDescription]: {
        value: "",
        preview: "",
      },
      [TemplateType.InvoiceDescription]: {
        value: "",
        preview: "",
      },
    },
    templateDefaults: Object.keys(TemplateVariablesEnum).reduce(
      (acc, key) => {
        acc[key as TemplateVariablesEnum] =
          TemplateVariablesEnum[key as keyof typeof TemplateVariablesEnum];
        return acc;
      },
      {} as Record<TemplateVariablesEnum, string>,
    ),
    updateDefaults: (defaults: Record<TemplateVariablesEnum, string>) => {
      set({ templateDefaults: defaults });
      get().setTemplate(
        TemplateType.PoName,
        get().templates[TemplateType.PoName].value,
        true,
      );
      get().setTemplate(
        TemplateType.PoDescription,
        get().templates[TemplateType.PoDescription].value,
        true,
      );
      get().setTemplate(
        TemplateType.InvoiceDescription,
        get().templates[TemplateType.InvoiceDescription].value,
        true,
      );
    },
    setTemplate: async (
      templateType: TemplateType,
      value: string,
      force = false,
    ) => {
      const client = useApolloClientStore.getState().client;

      if (
        !client ||
        (get().templates[templateType].value === value && !force)
      ) {
        return;
      }

      if (
        templateType === TemplateType.PoName ||
        templateType === TemplateType.PoDescription
      ) {
        const templateDefaults = Object.fromEntries(
          Object.entries(get().templateDefaults).filter(
            ([key]) =>
              !poExcludeVariables.includes(key as TemplateVariablesEnum),
          ),
        );

        const { data } = await client.mutate({
          mutation: PreviewPoTemplateDocument,
          variables: {
            input: {
              Template: value,
              ...templateDefaults,
            },
          },
        });

        set({
          templates: {
            ...get().templates,
            [templateType]: {
              value,
              preview: data?.previewPoTemplate ?? "",
            },
          },
        });
      } else if (templateType === TemplateType.InvoiceDescription) {
        const { data } = await client.mutate({
          mutation: PreviewInvoiceTemplateDocument,
          variables: {
            input: {
              Template: value,
              ...get().templateDefaults,
            },
          },
        });

        set({
          templates: {
            ...get().templates,
            [templateType]: {
              value,
              preview: data?.previewInvoiceTemplate ?? "",
            },
          },
        });
      }
    },
  })),
);
