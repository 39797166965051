import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { routes } from "@/config/routes";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useRelease } from "../../providers/ReleaseProvider";

const ContainerRoundedDashed = tw.div`flex gap-1 items-center py-1 px-3 text-xs text-gray-600 border border-dashed border-gray-600 rounded-3xl justify-between`;
const Text = tw.div`flex gap-1 items-center`;

export const ReleaseDetailsHeaderFooter: FC = () => {
  const { release } = useRelease();
  const navigate = useNavigate();
  return (
    <ContainerRoundedDashed>
      <Text>
        <InfoOutlined />
        <FormattedMessage id="RELEASE_CONFIRM_HEADER_INFO" />
      </Text>
      <PrimaryButton
        $small
        onClick={() =>
          navigate(
            generatePath(routes.specifyDeliveryDetails, {
              deliveryId: release?.id,
            }),
          )
        }
      >
        <FormattedMessage id="EDIT_ORDER" />
      </PrimaryButton>
    </ContainerRoundedDashed>
  );
};
