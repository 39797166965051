import { If } from "@/common/components/if/If";
import { OrderTypePickerControlled } from "@/common/components/order-type-picker/OrderTypePickerControlled";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { PoNumberInputControlled } from "@/common/components/po-number/PoNumberInputControlled";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { Popover } from "@/common/components/popover/Popover";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { MultiselectControlled } from "@/common/components/select/components/multiple/MultiselectControlled";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { VendorPickerControlled } from "@/common/components/vendor-picker/VendorPickerControlled";
import { WarehouseSelectorControlled } from "@/common/components/warehouse-selector/WarehouseSelectorControlled";
import {
  PROJECT_ADDRESS_ID,
  useWarehouseOptions,
} from "@/common/components/warehouse-selector/useWarehouseOptions";
import { useOrderTypesConfig } from "@/common/hooks/order-types-config/useOrderTypesConfig";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useUpdateFormFieldBasedOnValue } from "@/common/hooks/useUpdateFormFieldBasedOnValue";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useUsers } from "@/common/hooks/useUsers";
import { useUser } from "@/common/providers/UserProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { ReleaseDateTimePickerControlled } from "@/contractor/pages/home/release/pages/specify-details/components/release-date-time-picker/ReleaseDateTimePickerControlled";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseWatchersControlled } from "@/contractor/pages/home/releases/pages/deliveries/components/ReleaseWatchersControlled";
import { ReleaseNotificationsMode, TransactionKind } from "@/generated/graphql";
import { FC, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrderFromQuote } from "../../../../providers/OrderFromQuoteProvider";
import { CreateOrderFromQuoteFormValues } from "./CreateOrderFromQuoteForm";

const Container = tw.div`w-full grid grid-cols-4 grid-row-2 gap-2 items-center z-20 p-4 -top-5 bg-blue-100 rounded-2xl drop-shadow-md`;
const SwitchContainer = tw.div`grid w-full grid-cols-[1fr_1fr] px-1 justify-between gap-2 items-center text-xs h-10`;
const InputsContainer = tw.div`
  col-span-3 h-full grid grid-cols-[repeat(3,minmax(0,_1fr))] gap-2 items-start content-start
`;
const FulfillmentContainer = tw.div`
  border-l pl-2 border-dashed border-gray-400 
  h-full grid gap-2 items-start content-start
`;
const VendorContactTooltip = tw.div`max-w-[300px]`;
const WatchersContainer = tw.div`self-stretch border-gray-400 text-xs flex flex-col`;

export const CreateOrderFromQuoteHeader: FC = () => {
  const intl = useIntl();
  const { release } = useRelease();
  const { locations, loading: loadingLocations } = useUserLocations();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const { warehouses, loading: loadingWarehouses } = useWarehouses();

  const { vendors, findOrderTypeByLocationId, loadingPredictedPoNumber } =
    useOrderFromQuote();

  const { trigger, watch, setValue } =
    useFormContext<CreateOrderFromQuoteFormValues>();

  const { settings } = useOrgSettings();
  const isFoundationConnected =
    settings?.integrations.sourceSystems.some(
      (system) => system.connected && system.postInventoryReceipts,
    ) ||
    settings?.integrations.accounting.some(
      (accounting) => accounting.enabled && accounting.postInventoryReceipts,
    );

  const projectId = watch("projectId");

  const selectedProject = useMemo(
    () => projects.find((project) => project.id === projectId),
    [projectId, projects],
  );

  const { users } = useUsers({
    requireLocation: true,
    locationId: selectedProject?.location.id,
  });

  const predictedPoNumber = watch("predictedPoNumber");
  const vendorId = watch("vendorId");
  const { orderTypes } = useOrderTypeOptions();

  const orderTypeId = watch("orderTypeId");
  const businessLocationId = watch("businessLocationId");
  const projectOptions = useMemo(
    () =>
      projects.filter((project) => project.location.id === businessLocationId),
    [projects, businessLocationId],
  );
  const willCall = watch("willCall");

  const { orderTypeConfig } = useOrderTypesConfig({
    release: {
      type: {
        transactionKind:
          orderTypes.find((ot) => ot.id === orderTypeId)?.transactionKind ||
          TransactionKind.Purchase,
      },
    },
  });
  const { viewer } = useUser();

  const { warehouseOptions } = useWarehouseOptions(
    warehouses,
    projects.find((p) => p.id === projectId)?.address,
  );

  useUpdateFormFieldBasedOnValue<CreateOrderFromQuoteFormValues>(
    settings?.releases?.defaultWarehouse?.id ?? PROJECT_ADDRESS_ID,
    "fulfillmentLocationId",
  );

  useEffect(() => {
    if ((locations ?? []).length === 1) {
      setValue("businessLocationId", (locations ?? [])[0].id);
    }
  }, [locations, setValue]);

  useEffect(() => {
    if (projects.length === 1) {
      setValue("projectId", projects[0].id);
    }
  }, [projects, setValue]);

  useEffect(() => {
    if (vendors.length === 1) {
      setValue("vendorId", vendors[0].sellerOrgLocation.id);
    }
  }, [setValue, vendors]);

  useEffect(() => {
    if (vendorId) {
      const orderTypeId = findOrderTypeByLocationId(vendorId);
      if (orderTypeId) {
        setValue("orderTypeId", orderTypeId);
      }
    }
  }, [vendorId, setValue, findOrderTypeByLocationId]);

  useEffect(() => {
    if (vendorId) {
      const vendor = vendors.find((v) => v.sellerOrgLocation.id === vendorId);
      setValue(
        "vendorContactIds",
        (vendor?.contacts?.filter((c) => c.receivesOrderNotifications)
          .length === 1
          ? vendor?.contacts?.filter((c) => c.receivesOrderNotifications)
          : []
        ).map((c) => c.id) || [],
      );
    }
  }, [vendorId, vendors, setValue]);

  const selectedVendor = useMemo(
    () => vendors.find((v) => v.sellerOrgLocation.id === vendorId),
    [vendors, vendorId],
  );

  useEffect(() => {
    if (selectedProject && users) {
      if (release?.project && selectedProject.id === release?.project.id) {
        setValue(
          "watcherIds",
          release?.watchers
            ?.map((watcher) => watcher.id)
            .filter((watcher) => users.some((user) => user.id === watcher)) ||
            [],
        );
        return;
      }
      if (
        settings?.releases?.notifications ===
        ReleaseNotificationsMode.SubmitterOnly
      ) {
        if (viewer?.id) {
          setValue("watcherIds", [viewer?.id]);
        }
        return;
      }
      setValue(
        "watcherIds",
        selectedProject.team
          .map((teamMember) => teamMember.id)
          .filter((teamMember) => users.some((user) => user.id === teamMember)),
      );
    }
  }, [
    release?.project,
    release?.watchers,
    selectedProject,
    setValue,
    settings?.releases?.notifications,
    viewer?.id,
    users,
  ]);

  useUpdateFormFieldBasedOnValue<CreateOrderFromQuoteFormValues>(
    settings?.releases?.defaultWarehouse?.id,
    "fulfillmentLocationId",
  );

  return (
    <Container>
      <InputsContainer>
        <If isTrue={locations.length > 1}>
          <SelectControlled
            name="businessLocationId"
            options={locations ?? []}
            getValue={(o) => o.id}
            getLabel={(o) => o.name}
            placeholder={intl.$t({ id: "BUSINESS_LOCATION" })}
            loading={loadingLocations}
            rules={{
              required: true,
            }}
          />
        </If>
        <SelectControlled
          name="projectId"
          options={projectOptions}
          getValue={(o) => o.id}
          getLabel={(o) => getProjectSelectorLabel(o)}
          placeholder={intl.$t({ id: "PROJECT_NAME" })}
          loading={loadingProjects}
          rules={{
            required: true,
          }}
        />
        <OrderTypePickerControlled name="orderTypeId" />
        <PoNumberingSettingsCheck>
          <PoNumberInputControlled
            setValue={(poNumber) => setValue("poNumber", poNumber)}
            trigger={() => trigger("poNumber")}
            predictedPoNumber={predictedPoNumber}
            loading={loadingPredictedPoNumber}
            disabled={!projectId}
          />
        </PoNumberingSettingsCheck>
        <VendorPickerControlled
          required
          contactsFilter={(c) => c.receivesOrderNotifications}
        />
        {!!selectedVendor && selectedVendor.contacts.length === 0 ? (
          <Popover
            id="vendor-contact-ids-tooltip"
            $arrow
            element={
              <MultiselectControlled
                name="vendorContactIds"
                options={selectedVendor?.contacts || []}
                getValue={(o) => o.id}
                getLabel={(o) => o.name}
                placeholder={intl.$t({ id: "VENDOR_CONTACT" })}
                rules={{
                  required: selectedVendor
                    ? selectedVendor.contacts.filter(
                        (contact) => contact.receivesOrderNotifications,
                      ).length > 0
                    : true,
                }}
                noWrap
                limitTags={1}
                chipSize="small"
                includeCheckbox
                disableCloseOnSelect
              />
            }
          >
            <VendorContactTooltip>
              {intl.$t({ id: "VENDOR_WITHOUT_CONTACT_DETAILS" })}
            </VendorContactTooltip>
          </Popover>
        ) : (
          <MultiselectControlled
            name="vendorContactIds"
            options={selectedVendor?.contacts || []}
            getValue={(o) => o.id}
            getLabel={(o) => o.name}
            placeholder={intl.$t({ id: "VENDOR_CONTACT" })}
            rules={{
              required: selectedVendor
                ? selectedVendor.contacts.filter(
                    (contact) => contact.receivesOrderNotifications,
                  ).length > 0
                : true,
            }}
            noWrap
            limitTags={1}
            chipSize="small"
            includeCheckbox
            disableCloseOnSelect
          />
        )}
        <ReleaseDateTimePickerControlled />
        {selectedProject && users ? (
          <WatchersContainer>
            <FormattedMessage id="WATCHERS" />
            <ReleaseWatchersControlled
              name="watcherIds"
              moreTagCount={1}
              users={users}
            />
          </WatchersContainer>
        ) : null}
        <If
          isTrue={
            orderTypeConfig.features.includeVendorStocking &&
            isFoundationConnected
          }
        >
          <SwitchContainer>
            <FormattedMessage id="REQUIRES_INVENTORY_RECEIPT" />
            <SwitchControlled
              name="requiresInventoryReceipt"
              className="justify-self-end"
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
            />
          </SwitchContainer>
        </If>
      </InputsContainer>
      <FulfillmentContainer>
        <If isTrue={orderTypeConfig.features.includeWillCall}>
          <SwitchContainer>
            <FormattedMessage id="CLIENT_DELIVERY" />
            <SwitchControlled
              name="willCall"
              className="justify-self-end"
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
            />
          </SwitchContainer>
        </If>
        <If isTrue={!willCall}>
          <WarehouseSelectorControlled
            name="fulfillmentLocationId"
            options={warehouseOptions}
            loading={loadingWarehouses}
          />
          <If isTrue={orderTypeConfig.features.includeVendorStocking}>
            <SwitchContainer>
              <FormattedMessage id="VENDOR_STOCKING" />
              <SwitchControlled
                name="vendorStocking"
                className="justify-self-end"
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
              />
            </SwitchContainer>
          </If>
        </If>
      </FulfillmentContainer>
    </Container>
  );
};
