import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { getUTCDate } from "@/common/components/picker/components/utils/getUTCDate";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { dateDiffInDays } from "../../scanned-invoices/utils/dateDiff";
import {
  invoiceCreateReleaseFormDefaultValues,
  InvoiceCreateReleaseFormValues,
} from "../components/matched-order/components/InvoiceVerificationForm";
import { MatchedOrderViewState } from "../enums/MatchedOrderViewState";
import { useInvoiceCreateRelease } from "../providers/InvoiceCreateReleaseProvider";
import { useInvoiceMatchedOrder } from "../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";
import { useInvoiceImportExternalPO } from "./useInvoiceImportExternalPO";

const EDITABLE_ORDER_VIEW_STATES = [
  MatchedOrderViewState.CREATE_ORDER,
  MatchedOrderViewState.EDIT_ORDER,
  MatchedOrderViewState.IMPORT_ORDER,
];

export const useInvoiceOrderEdit = () => {
  const { settings } = useOrgSettings();
  const { invoice } = useInvoiceVerification();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const { reset, getValues, setValue } =
    useFormContext<InvoiceCreateReleaseFormValues>();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { locations } = useUserLocations({
    permission: "createInvoice",
  });
  const { projects } = useProjectListOptions();
  const { warehouses } = useWarehouses();
  const { vendors, findOrderTypeByLocationId } = useVendors();
  const {
    importedPoNumber,
    importedProjectId,
    importedOrderTypeId,
    importedSellerOrgLocationId,
    importedLocationId,
    importedTaxType,
    importedTaxAmount,
    importedCustomTaxAmount,
    importedTaxCodeId,
    importedPoDate,
    importedAdditionalCharges,
    importedDescription,
  } = useInvoiceImportExternalPO();
  const { defaultOrderType } = useOrderTypeOptions();
  const intl = useIntl();
  const { loading } = useInvoiceCreateRelease();

  const isOrderInEditMode = useMemo(
    () =>
      EDITABLE_ORDER_VIEW_STATES.includes(
        matchedOrderViewState ?? MatchedOrderViewState.DEFAULT,
      ),
    [matchedOrderViewState],
  );

  useEffect(() => {
    if (!loading) {
      if (isOrderInEditMode) {
        const projectId =
          getValues("projectId") ||
          invoice?.release?.project?.id ||
          importedProjectId ||
          invoice?.predictedProject?.id ||
          (projects.length === 1 ? projects[0].id : undefined);

        const vendorId =
          getValues("vendorId") ||
          invoice?.release?.sellerOrgLocation?.id ||
          importedSellerOrgLocationId ||
          invoice?.predictedSellerOrgLocation?.id ||
          (vendors.length === 1 ? vendors[0].sellerOrgLocation.id : undefined);

        const fulfillmentLocationId =
          getValues("fulfillmentLocationId") ||
          invoice?.release?.warehouse?.id ||
          (warehouses.length === 1 ? warehouses[0].id : undefined) ||
          projectId;

        const orderTypeId =
          getValues("orderTypeId") ||
          invoice?.release?.type.id ||
          importedOrderTypeId ||
          (vendorId ? findOrderTypeByLocationId(vendorId) : undefined) ||
          defaultOrderType?.id;

        const poNumber = includePoNumbering
          ? (getValues("poNumber") ??
            (invoice?.release?.poNumber ||
              importedPoNumber ||
              invoice?.poNumber ||
              ""))
          : undefined;

        const orderDate =
          getValues("orderDate") ||
          getUTCDate(
            invoice?.release?.time || importedPoDate || invoice?.issueDate,
          );

        const taxCodeId =
          getValues("taxCodeId") ?? importedTaxCodeId ?? undefined;
        const customTaxAmount =
          getValues("customTaxAmount") ||
          invoice?.release?.customTaxAmount ||
          importedTaxAmount ||
          importedCustomTaxAmount ||
          (!invoice?.release?.taxRate && invoice?.taxAmount !== "0"
            ? invoice?.taxAmount
            : undefined) ||
          undefined;

        const additionalCharges =
          getValues("additionalCharges") ??
          (invoice?.release ? invoice?.release.additionalCharges : undefined) ??
          importedAdditionalCharges ??
          (invoice?.chargesAmount && Number(invoice.chargesAmount) > 0
            ? [
                {
                  id: null,
                  amount: invoice?.chargesAmount,
                  description: intl.$t({ id: "ADDITIONAL_CHARGES" }),
                },
              ]
            : undefined) ??
          invoice?.charges ??
          [];

        const netAmount =
          getValues("netAmount") ||
          (invoice?.release?.netAmount
            ? Number(invoice?.release.netAmount)
            : undefined) ||
          Number(invoice?.subtotal);

        const total = invoice?.total ?? "0";

        const paymentTerm =
          invoice?.dueDate && invoice?.issueDate
            ? dateDiffInDays(invoice.dueDate, invoice.issueDate)?.toString()
            : "";

        const taxType = getValues("taxType") ?? importedTaxType ?? undefined;

        const taxVariance =
          getValues("taxVariance") ??
          invoice?.release?.taxVariance ??
          undefined;

        const taxRate =
          getValues("taxRate") ?? invoice?.release?.taxRate ?? undefined;

        const description =
          getValues("description") ||
          importedDescription ||
          invoice?.release?.description ||
          "";

        const instructions =
          getValues("instructions") ===
          invoiceCreateReleaseFormDefaultValues.instructions
            ? invoice?.release?.instructions ||
              invoiceCreateReleaseFormDefaultValues.instructions
            : getValues("instructions");

        reset({
          ...getValues(),
          projectId,
          vendorId,
          fulfillmentLocationId,
          poNumber,
          orderTypeId,
          orderDate,
          customTaxAmount,
          taxRate,
          additionalCharges,
          netAmount,
          total,
          paymentTerm,
          taxType,
          taxCodeId,
          taxVariance,
          description,
          instructions,
        });
      } else {
        reset(invoiceCreateReleaseFormDefaultValues);
      }
    }
  }, [
    isOrderInEditMode,
    invoice,
    getValues,
    setValue,
    includePoNumbering,
    reset,
    locations,
    projects,
    warehouses,
    vendors,
    defaultOrderType,
    findOrderTypeByLocationId,
    intl,
    importedLocationId,
    importedProjectId,
    importedSellerOrgLocationId,
    importedOrderTypeId,
    importedTaxType,
    importedTaxAmount,
    importedTaxCodeId,
    importedPoNumber,
    importedPoDate,
    importedCustomTaxAmount,
    importedAdditionalCharges,
    importedDescription,
    loading,
    settings?.releases?.defaultWarehouse?.id,
  ]);
};
