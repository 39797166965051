import { NumericalInput } from "@/common/components/numerical-input/NumericalInput";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { TaxCodeFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";

type Props = {
  code: TaxCodeFieldsFragment;
  prop: "rate";
  placeholder: string;
  required?: boolean;
  readonly?: boolean;
  error: boolean;
};

export const TaxRateCodeInput: FC<Props> = ({
  code,
  prop,
  placeholder,
  required,
  readonly,
}) => {
  const intl = useIntl();

  const value = useMemo(() => code[prop] || "0", [code, prop]);

  return (
    <NumericalInput
      size="small"
      className="w-full"
      value={new DecimalSafe(value).mul(100).toNumber()}
      required={required}
      placeholder={intl.$t({ id: placeholder })}
      suffix="%"
      centered
      staticText={readonly}
    />
  );
};
