import { routes } from "@/config/routes";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router";
import { useDeliverySlipSequence } from "../../../providers/DeliverySlipSequenceProvider";

export const useDeliverySlipSequenceStepper = () => {
  const navigate = useNavigate();
  const {
    step,
    sequenceIds,
    selectedDeliverySlips,
    newlyMatchedDeliverySlipIds,
  } = useDeliverySlipSequence();

  const hasReleaseAssigned = useCallback(
    (slipId: string) => {
      return (
        selectedDeliverySlips.find((slip) => slip.id === slipId)?.release ||
        newlyMatchedDeliverySlipIds.includes(slipId)
      );
    },
    [newlyMatchedDeliverySlipIds, selectedDeliverySlips],
  );

  const navigateToStep = useCallback(
    (step: number) => {
      navigate(
        generatePath(
          hasReleaseAssigned(sequenceIds[step])
            ? routes.deliverySlipReceiveOrder
            : routes.deliverySlipMatchOrder,
          {
            deliverySlipId: sequenceIds[step],
          },
        ),
      );
    },
    [hasReleaseAssigned, navigate, sequenceIds],
  );

  const prevStepCallback = useCallback(() => {
    if (sequenceIds[step - 1]) {
      navigateToStep(step - 1);
    }
  }, [navigateToStep, sequenceIds, step]);

  const nextStepCallback = useCallback(() => {
    if (sequenceIds[step + 1]) {
      navigateToStep(step + 1);
    }
  }, [navigateToStep, sequenceIds, step]);

  return {
    nextStepCallback,
    prevStepCallback,
  };
};
