import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useProjectAllItemsCheckbox } from "./useProjectAllItemsCheckbox";

const Container = tw.div`
  flex -ml-2
`;

export const ProjectAllItemsCheckbox: FC = () => {
  const { sequenceIds, handleChange, checked } = useProjectAllItemsCheckbox();

  return (
    <Container>
      <SelectionCheckbox
        items={sequenceIds}
        setSelection={handleChange}
        checked={checked}
      />
    </Container>
  );
};
