import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import {
  ColumnType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { ColumnSettings } from "handsontable/settings";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { AutocompleteWithPlaceholder } from "../editors/AutocompleteEditorWithPlaceholder";
import { useBudgetRenderer } from "../renderers/useBudgetRenderer";
import { useCheckboxRenderer } from "../renderers/useCheckboxRenderer";
import { useCostCodeRenderer } from "../renderers/useCostCodeRenderer";
import { useCostTypeRenderer } from "../renderers/useCostTypeRenderer";
import { useCreatableRenderer } from "../renderers/useCreatableRenderer";
import { useDeliveryDateRenderer } from "../renderers/useDeliveryDateRenderer";
import { useExtPriceRenderer } from "../renderers/useExtPriceRenderer";
import { useLeadTimeRenderer } from "../renderers/useLeadTimeRenderer";
import { useMaterialRenderer } from "../renderers/useMaterialRenderer";
import { usePrefillPriceRenderer } from "../renderers/usePrefillPriceRenderer";
import { useQuantityRenderer } from "../renderers/useQuantityRenderer";
import { useTagRenderer } from "../renderers/useTagRenderer";
import { useUnitPriceRenderer } from "../renderers/useUnitPriceRenderer";
import { useUomRenderer } from "../renderers/useUomRenderer";
import { useValueWithUnitRenderer } from "../renderers/useValueWithUnitRenderer";
import { useVendorRenderer } from "../renderers/useVendorRenderer";
import { useTableValidators } from "./useTableValidators";

enum COLUMN_WIDTH {
  ITEM_NAME = 270,
  NOTES = 130,
  DETAIL_MD = 144,
  DETAIL_SM = 117,
  DATE = 120,
  NUMBER = 99,
  CHECKBOX = 40,
}

export const useColumnConfig = () => {
  const intl = useIntl();
  const {
    itemOptions,
    manufacturerOptions,
    uomOptions,
    costCodeOptions,
    phaseCodeOptions,
    costTypeOptions,
    zoneOptions,
    vendorOptions,
    tagOptions,
  } = useColumnMapper();
  const {
    positiveNumberValidator,
    requiredValidator,
    combineValidators,
    dateInFutureValidator,
    deliveryDateValidator,
    uniqueCodeValidator,
    costCodeValidator,
    costTypeValidator,
    phaseCodeValidator,
    uniqueMaterialValidator,
  } = useTableValidators();

  const materialRenderer = useMaterialRenderer();
  const unitPriceRenderer = useUnitPriceRenderer();
  const creatableRenderer = useCreatableRenderer();
  const prefillPriceRenderer = usePrefillPriceRenderer();
  const extPriceRenderer = useExtPriceRenderer();
  const valueWithUnitRenderer = useValueWithUnitRenderer();
  const quantityRenderer = useQuantityRenderer();
  const tagRenderer = useTagRenderer();
  const costTypeRenderer = useCostTypeRenderer();
  const leadTimeRenderer = useLeadTimeRenderer();
  const costCodeRenderer = useCostCodeRenderer();
  const uomRenderer = useUomRenderer();
  const vendorRenderer = useVendorRenderer();
  const deliveryDateRenderer = useDeliveryDateRenderer();
  const checkboxRenderer = useCheckboxRenderer();
  const budgetRenderer = useBudgetRenderer();
  const { hasManufacturersSetting } = useManufacturersSettings();
  const { hasPhaseCodes } = useOrgSettings();

  return useCallback(
    (allColumns: ColumnType[]) => {
      return allColumns.map((col: ColumnType) => {
        const columnConfig = {
          ...col,
          data: col.columnType,
          filteringCaseSensitive: false,
          className: `htMiddle htCenter`,
          editorPlaceholder: intl.$t({ id: "TYPE_TO_SEARCH" }),
        } as ColumnSettings;

        const type = col.columnType.split("_")[0];

        switch (type) {
          case COLUMN_TYPE.Material:
            columnConfig.type = "autocomplete";
            columnConfig.editor = AutocompleteWithPlaceholder;
            columnConfig.className = `htMiddle`;
            columnConfig.width = COLUMN_WIDTH.ITEM_NAME;
            columnConfig.renderer = materialRenderer;
            columnConfig.allowInvalid = true;
            columnConfig.trimDropdown = false;
            columnConfig.source = itemOptions;
            columnConfig.group = "Material";
            columnConfig.validator = requiredValidator;
            break;
          case COLUMN_TYPE.MaterialName:
            columnConfig.type = "text";
            columnConfig.className = `htMiddle`;
            columnConfig.width = COLUMN_WIDTH.ITEM_NAME;
            columnConfig.renderer = materialRenderer;
            columnConfig.validator = combineValidators([
              requiredValidator,
              uniqueMaterialValidator,
            ]);
            break;
          case COLUMN_TYPE.Code:
            columnConfig.type = "text";
            columnConfig.width = COLUMN_WIDTH.ITEM_NAME;
            columnConfig.validator = combineValidators([
              requiredValidator,
              uniqueCodeValidator,
            ]);
            break;
          case COLUMN_TYPE.CodeDescription:
            columnConfig.type = "text";
            columnConfig.className = `htMiddle`;
            columnConfig.width = COLUMN_WIDTH.NOTES;
            columnConfig.validator = columnConfig.validator = requiredValidator;
            break;
          case COLUMN_TYPE.Manufacturer:
            columnConfig.type = "autocomplete";
            columnConfig.editor = AutocompleteWithPlaceholder;
            columnConfig.allowInvalid = true;
            columnConfig.trimDropdown = false;
            columnConfig.width = COLUMN_WIDTH.DETAIL_SM;
            columnConfig.source = manufacturerOptions;
            columnConfig.renderer = creatableRenderer;
            columnConfig.hidden = !hasManufacturersSetting;
            columnConfig.disabledForLumpSum = true;
            break;
          case COLUMN_TYPE.UOM:
            columnConfig.type = "autocomplete";
            columnConfig.editor = AutocompleteWithPlaceholder;
            columnConfig.allowInvalid = true;
            columnConfig.trimDropdown = false;
            columnConfig.width = COLUMN_WIDTH.DETAIL_SM;
            columnConfig.source = uomOptions;
            columnConfig.validator = requiredValidator;
            columnConfig.renderer = uomRenderer;
            break;
          case COLUMN_TYPE.Quantity:
            columnConfig.type = "numeric";
            columnConfig.width = COLUMN_WIDTH.NUMBER;
            columnConfig.renderer = quantityRenderer;
            columnConfig.validator = requiredValidator;
            columnConfig.disabledForLumpSum = true;
            break;
          case COLUMN_TYPE.PositiveQuantity:
            columnConfig.type = "numeric";
            columnConfig.width = COLUMN_WIDTH.NUMBER;
            columnConfig.renderer = quantityRenderer;
            columnConfig.validator = combineValidators([
              requiredValidator,
              positiveNumberValidator,
            ]);
            break;
          case COLUMN_TYPE.ReceivedQuantity:
            columnConfig.type = "numeric";
            columnConfig.width = COLUMN_WIDTH.NUMBER;
            columnConfig.renderer = quantityRenderer;
            break;
          case COLUMN_TYPE.UnitPrice:
            columnConfig.type = "numeric";
            columnConfig.width = COLUMN_WIDTH.NUMBER;
            columnConfig.renderer = unitPriceRenderer;
            columnConfig.numericFormat = {
              pattern: "$0,0.00",
              culture: "en-US",
            };
            columnConfig.validator = requiredValidator;
            break;
          case COLUMN_TYPE.ExtPrice:
            columnConfig.type = "numeric";
            columnConfig.width = COLUMN_WIDTH.NUMBER;
            columnConfig.renderer = extPriceRenderer;
            columnConfig.numericFormat = {
              pattern: "$0,0.00",
              culture: "en-US",
            };
            break;
          case COLUMN_TYPE.PrefilledPrice:
            columnConfig.type = "numeric";
            columnConfig.width = COLUMN_WIDTH.NUMBER;
            columnConfig.renderer = prefillPriceRenderer;
            columnConfig.numericFormat = {
              pattern: "$0,0.00",
              culture: "en-US",
            };
            columnConfig.validator = requiredValidator;
            break;
          case COLUMN_TYPE.CostCode:
            columnConfig.type = "autocomplete";
            columnConfig.editor = AutocompleteWithPlaceholder;
            columnConfig.strict = true;
            columnConfig.allowInvalid = true;
            columnConfig.trimDropdown = false;
            columnConfig.width = COLUMN_WIDTH.DETAIL_MD;
            columnConfig.source = costCodeOptions;
            columnConfig.validator = costCodeValidator;
            columnConfig.renderer = costCodeRenderer;
            columnConfig.hidden =
              hasPhaseCodes &&
              allColumns.some((c) => c.columnType === COLUMN_TYPE.PhaseCode);
            break;
          case COLUMN_TYPE.PhaseCode:
            columnConfig.type = "autocomplete";
            columnConfig.editor = AutocompleteWithPlaceholder;
            columnConfig.strict = true;
            columnConfig.allowInvalid = true;
            columnConfig.trimDropdown = false;
            columnConfig.width = COLUMN_WIDTH.DETAIL_MD;
            columnConfig.source = phaseCodeOptions;
            columnConfig.validator = phaseCodeValidator;
            columnConfig.hidden = !hasPhaseCodes;
            columnConfig.skipSpaceReplacement = true;
            break;
          case COLUMN_TYPE.CostType:
            columnConfig.type = "autocomplete";
            columnConfig.editor = AutocompleteWithPlaceholder;
            columnConfig.strict = true;
            columnConfig.allowInvalid = true;
            columnConfig.trimDropdown = false;
            columnConfig.width = COLUMN_WIDTH.DETAIL_SM;
            columnConfig.source = costTypeOptions;
            columnConfig.validator = costTypeValidator;
            columnConfig.renderer = costTypeRenderer;
            break;
          case COLUMN_TYPE.Zone:
            columnConfig.type = "autocomplete";
            columnConfig.editor = AutocompleteWithPlaceholder;
            columnConfig.allowInvalid = true;
            columnConfig.width = COLUMN_WIDTH.DETAIL_SM;
            columnConfig.source = zoneOptions;
            columnConfig.renderer = creatableRenderer;
            columnConfig.hidden = zoneOptions.length === 0;
            break;
          case COLUMN_TYPE.Notes:
            columnConfig.className = `htMiddle`;
            columnConfig.width = COLUMN_WIDTH.NOTES;
            break;
          case COLUMN_TYPE.Vendor:
            columnConfig.type = "autocomplete";
            columnConfig.editor = AutocompleteWithPlaceholder;
            columnConfig.strict = true;
            columnConfig.allowInvalid = true;
            columnConfig.trimDropdown = false;
            columnConfig.width = COLUMN_WIDTH.DETAIL_MD;
            columnConfig.source = vendorOptions;
            columnConfig.renderer = vendorRenderer;
            break;
          case COLUMN_TYPE.ExpirationDate:
            columnConfig.dateFormat = "MM/DD/YYYY";
            columnConfig.type = "date";
            columnConfig.correctFormat = true;
            columnConfig.width = COLUMN_WIDTH.DATE;
            columnConfig.validator = dateInFutureValidator;
            break;
          case COLUMN_TYPE.DeliveryDate:
            columnConfig.dateFormat = "MM/DD/YYYY";
            columnConfig.type = "date";
            columnConfig.width = COLUMN_WIDTH.DATE;
            columnConfig.correctFormat = true;
            columnConfig.renderer = deliveryDateRenderer;
            columnConfig.validator = deliveryDateValidator;
            break;
          case COLUMN_TYPE.EndDate:
            columnConfig.dateFormat = "MM/DD/YYYY";
            columnConfig.type = "date";
            columnConfig.width = COLUMN_WIDTH.DATE;
            columnConfig.correctFormat = true;
            break;
          case COLUMN_TYPE.OrderIncrement:
          case COLUMN_TYPE.MinimumOrder:
            columnConfig.type = "numeric";
            columnConfig.editor = "numeric";
            columnConfig.width = COLUMN_WIDTH.NUMBER;
            columnConfig.numericFormat = {
              pattern: "0,0.00",
              culture: "en-US",
            };
            columnConfig.renderer = valueWithUnitRenderer;
            columnConfig.validator = positiveNumberValidator;
            break;
          case COLUMN_TYPE.LeadTime:
            columnConfig.type = "numeric";
            columnConfig.editor = "numeric";
            columnConfig.width = COLUMN_WIDTH.NUMBER;
            columnConfig.renderer = leadTimeRenderer;
            columnConfig.validator = positiveNumberValidator;
            break;
          case COLUMN_TYPE.Tag:
            columnConfig.type = "autocomplete";
            columnConfig.editor = AutocompleteWithPlaceholder;
            columnConfig.renderer = tagRenderer;
            columnConfig.width = COLUMN_WIDTH.DETAIL_SM;
            columnConfig.allowInvalid = true;
            columnConfig.trimDropdown = false;
            columnConfig.source = tagOptions;
            columnConfig.skipSpaceReplacement = true;
            columnConfig.hidden =
              hasPhaseCodes &&
              allColumns.some((c) => c.columnType === COLUMN_TYPE.PhaseCode);
            break;
          case COLUMN_TYPE.Taxable:
            columnConfig.type = "checkbox";
            columnConfig.width = COLUMN_WIDTH.CHECKBOX;
            columnConfig.renderer = "checkbox";
            columnConfig.skipColumnOnPaste = true;
            columnConfig.renderer = checkboxRenderer;
            break;
          case COLUMN_TYPE.Budget:
            columnConfig.type = "numeric";
            columnConfig.width = COLUMN_WIDTH.NUMBER;
            columnConfig.renderer = budgetRenderer;
            columnConfig.numericFormat = {
              pattern: "$0,0.00",
              culture: "en-US",
            };
            break;
        }

        return {
          ...columnConfig,
          validator: col.validator ?? columnConfig.validator,
          renderer: col.renderer ?? columnConfig.renderer,
          source: col.options ?? columnConfig.source,
          hidden: col.hidden ?? columnConfig.hidden,
        } as ColumnSettings;
      });
    },
    [
      intl,
      materialRenderer,
      itemOptions,
      requiredValidator,
      combineValidators,
      uniqueMaterialValidator,
      uniqueCodeValidator,
      manufacturerOptions,
      creatableRenderer,
      hasManufacturersSetting,
      uomOptions,
      uomRenderer,
      quantityRenderer,
      positiveNumberValidator,
      unitPriceRenderer,
      extPriceRenderer,
      prefillPriceRenderer,
      costCodeOptions,
      costCodeValidator,
      costCodeRenderer,
      hasPhaseCodes,
      phaseCodeOptions,
      phaseCodeValidator,
      costTypeOptions,
      costTypeValidator,
      costTypeRenderer,
      zoneOptions,
      vendorOptions,
      vendorRenderer,
      dateInFutureValidator,
      deliveryDateRenderer,
      deliveryDateValidator,
      valueWithUnitRenderer,
      leadTimeRenderer,
      tagRenderer,
      tagOptions,
      checkboxRenderer,
      budgetRenderer,
    ],
  );
};
