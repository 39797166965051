import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { useUsers } from "@/common/hooks/useUsers";
import { getUserName } from "@/common/utils/users/getUserName";
import { CustomFilterContainer } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/list/invoice-additional-filters/InvoiceAdditionalFilters";
import { OrgLocationRole } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AdditionalFiltersOverlay } from "../additional-filters-overlay/AdditionalFiltersOverlay";

type Props = {
  values: string[] | null | undefined;
  setFilter: (createdByIds: string[] | undefined) => void;
  applyHeaderAccent?: boolean;
  titleId: string;
  className?: string;
  placeholderId?: string;
  withRoles?: boolean;
};

export const MultiSelectUserFilter = ({
  values,
  setFilter,
  applyHeaderAccent = false,
  titleId,
  placeholderId,
  withRoles = false,
}: Props) => {
  const intl = useIntl();
  const { users } = useUsers();

  const options = useMemo<{ id: string; name: string; roles: string }[]>(
    () =>
      users?.map((user) => {
        const userRoles: OrgLocationRole[] = [];
        user.locationRoles.forEach((locationRole) => {
          if (!userRoles.includes(locationRole.role)) {
            userRoles.push(locationRole.role);
          }
        });
        return {
          id: user.id,
          name: getUserName(user),
          roles: userRoles
            .map((userRole) => intl.$t({ id: `USER_ROLE_${userRole}` }))
            .join(", "),
        };
      }) || [],
    [intl, users],
  );

  const setFilterHandler = useCallback(
    (values: string[] | null) => {
      setFilter(values?.length ? values : undefined);
    },
    [setFilter],
  );

  return (
    <CustomFilterContainer>
      <AdditionalFiltersOverlay.Name>
        <FormattedMessage id={titleId} />
      </AdditionalFiltersOverlay.Name>
      <Multiselect
        options={options}
        getOptionLabel={(o) => o.name}
        getLabel={(o) =>
          `${o.name}${withRoles && o.roles ? ` (${o.roles})` : ""}`
        }
        getValue={(o) => o.id?.toString()}
        values={values}
        chipSize="small"
        includeCheckbox
        limitTags={1}
        disableCloseOnSelect
        className="flex-1"
        placeholder={
          values?.length ? "" : intl.$t({ id: placeholderId || "ALL_USERS" })
        }
        onMultipleChange={setFilterHandler}
        applyHeaderAccent={applyHeaderAccent}
      />
    </CustomFilterContainer>
  );
};
