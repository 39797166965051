import { InvoiceEmailFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useInvoiceEmailsSequence } from "../../hooks/useInvoiceEmailsSequence";

export const useInvoiceEmailCheckbox = ({
  invoiceEmail,
}: {
  invoiceEmail: InvoiceEmailFieldsFragment;
}) => {
  const { selectedEntities, setSelectedEntities } = useInvoiceEmailsSequence();

  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedEntities(
        newChecked
          ? [...selectedEntities, invoiceEmail]
          : selectedEntities.filter((d) => d.id !== invoiceEmail.id),
      );
    },
    [invoiceEmail, selectedEntities, setSelectedEntities],
  );

  return {
    handleChange,
  };
};
