import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useReleaseAdditionalChargesInput } from "@/common/components/release-additional-charges-and-taxes/hooks/useReleaseAdditionalChargesInput";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useSetCurrentProjectId } from "@/contractor/pages/home/project/hooks/useSetCurrentProjectId";
import { useFormPriceCalculation } from "@/contractor/pages/home/release/hooks/useFormPriceCalculation";
import { usePrefillDefaultTaxable } from "@/contractor/pages/home/release/hooks/usePrefillDefaultTaxable";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useReceiptCreateRelease } from "../../providers/ReceiptCreateReleaseProvider";
import { useReceipt } from "../../providers/ReceiptProvider";
import { ReceiptSpreadSheetItem } from "../../types/ReceiptSpreadSheetItem";
import { ReceiptCreateReleaseFormValues } from "../ReceiptVerificationForm";
import { useReceiptCreateReleaseSpreadsheetConfig } from "./itemized/create/ReceiptCreateRelease.config";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-full`;
const Footer = tw.div`mr-10 mt-10 w-full pb-5 flex flex-col justify-end`;

type Props = {
  itemized: boolean;
};

export const ReceiptCreateRelease: FC<Props> = ({ itemized }) => {
  const { setValue, watch, getValues } =
    useFormContext<ReceiptCreateReleaseFormValues>();
  const { release, loading: releaseLoading } = useRelease();
  const { receipt, hasReleaseRecorded, isItemized } = useReceipt();
  const { loading } = useReceiptCreateRelease();
  const { calcTableTotal, findMaterialByName } = useTableHelpers();
  const { spreadsheetData } = useColumnMapper();
  const customTaxAmount = watch("customTaxAmount");
  const netAmount = watch("netAmount");
  const spreadsheetViewColumns = useReceiptCreateReleaseSpreadsheetConfig();
  const { calcExtPrice } = usePriceCalculation();
  const { taxable } = usePrefillDefaultTaxable();

  const receiptItems: ReceiptSpreadSheetItem[] = useMemo(() => {
    const shouldRenderReceiptItems = hasReleaseRecorded && !isItemized(receipt);

    if (release && !shouldRenderReceiptItems) {
      return release.items.map((item) => {
        const matchingOrgMaterial = findMaterialByName(
          item.projectItem?.material.material.name || "",
        );

        const extPrice = new DecimalSafe(item?.unitPrice || 0)
          .mul(item?.quantityDecimal)
          .toNumber();

        return {
          id: item.id,
          description: item.name,
          material: matchingOrgMaterial,
          costCode:
            item.costCode?.code ??
            matchingOrgMaterial?.costCode?.code ??
            receipt?.costCode?.code ??
            undefined,
          manufacturer: item.manufacturer?.name,
          UOM: item.uom?.pluralDescription ?? item.uom?.mnemonic ?? undefined,
          quantityDecimal: item.quantityDecimal,
          unitPrice: Number(item.unitPrice),
          extPrice,
          taxable: item.taxable,
        };
      });
    }
    if (!receipt?.items || (receipt.release?.id && !shouldRenderReceiptItems)) {
      return [];
    }

    return receipt.items.map((item) => {
      const matchingOrgMaterial = findMaterialByName(item.description);
      const extPrice = calcExtPrice(item?.quantityDecimal, item?.unitPrice);
      const isLumpSum = isLumpSumUomText(item.UOM);

      return {
        ...item,
        material: matchingOrgMaterial,
        costCode:
          matchingOrgMaterial?.costCode?.code ??
          receipt.costCode?.code ??
          undefined,
        manufacturer:
          item.manufacturer ?? matchingOrgMaterial?.manufacturer?.name,
        UOM:
          item.UOM ??
          matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
          matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
        quantityDecimal:
          (isLumpSum ? item.unitPrice : item.quantityDecimal) ?? "",
        unitPrice: isLumpSum ? 1 : (Number(item.unitPrice) ?? undefined),
        extPrice,
        taxable,
      };
    });
  }, [
    hasReleaseRecorded,
    isItemized,
    receipt,
    release,
    findMaterialByName,
    calcExtPrice,
    taxable,
  ]);

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== netAmount) {
        setValue("netAmount", newTotal);
      }
      const taxableNetAmount = `${calcTableTotal(data, { taxable: true })}`;
      if (taxableNetAmount !== getValues("taxableNetAmount")) {
        setValue("taxableNetAmount", taxableNetAmount);
      }
    },
    [calcTableTotal, netAmount, getValues, setValue],
  );

  useEffect(() => {
    if (itemized) {
      updateSubtotal(spreadsheetData);
    }
  }, [spreadsheetData, updateSubtotal, itemized]);

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue(
          "customTaxAmount",
          values.customTaxAmount || receipt?.taxAmount || undefined,
        );
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }
      if (values.paymentTerm || values.paymentTerm === 0) {
        setValue("paymentTerm", values.paymentTerm.toString());
      }
      if (values.taxVariance || values.clearTaxVariance) {
        setValue(
          "taxVariance",
          values.clearTaxVariance ? undefined : values.taxVariance,
        );
      }

      return true;
    },
    [customTaxAmount, receipt?.taxAmount, setValue],
  );

  const { releaseInput } = useReleaseAdditionalChargesInput();
  const { total } = useFormPriceCalculation();

  const projectId = watch("projectId");
  useSetCurrentProjectId(projectId);

  return (
    <Container>
      <SpreadSheetView>
        <SpreadSheetTable
          items={receiptItems}
          columns={spreadsheetViewColumns}
          saving={loading || releaseLoading}
          height="380px"
          onChanges={updateSubtotal}
        />
      </SpreadSheetView>
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={`${total}`}
          releaseInput={releaseInput}
          editableByContractor
          updateRelease={handleUpdateRelease}
          includeAdditionalCharges={hasReleaseRecorded}
          includeNotesPanel={false}
          editableAdditionalCharges={false}
          includePaymentTerms={false}
          release={release}
        />
      </Footer>
    </Container>
  );
};
