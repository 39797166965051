import { useIntl } from "react-intl";
import { VariantListInfo } from "./VariantListInfo";

export const QbDesktopSpecificItemsListInfo = () => {
  const intl = useIntl();
  return (
    <VariantListInfo
      options={[
        {
          source: intl.$t({ id: "CLASSES" }),
          target: intl.$t({ id: "COST_CODES" }),
        },
        {
          source: intl.$t({ id: "ITEMS" }),
          target: intl.$t({ id: "ITEM_DATABASE" }),
        },
      ]}
      variant={intl.$t({ id: "SPECIFIC_ITEMS" })}
    />
  );
};
