import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { HOLD_FOR_RELEASE_TEXT } from "@/common/const";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { Identity } from "@/types/Identity";
import { format } from "date-fns";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { rowIsEmpty } from "../utils/rowIsEmpty";
import { useRenderHelpers } from "./useRenderHelpers";

export const useDeliveryDateRenderer = () => {
  const { checkReadOnly, applyClasses } = useRenderHelpers();
  const { release } = useRelease();
  const intl = useIntl();
  const { addIconWithTooltip, applyTooltip, removeTooltip } =
    useRenderHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const rows = instance?.getData();
      const physicalRow = instance?.toPhysicalRow(row) || row;
      const sourceRow = instance.getSourceDataAtRow(physicalRow) as Identity;
      const releaseItem = release?.items.find(
        (item) => item.id === sourceRow.id,
      );
      if (!value && !rowIsEmpty(rows[row])) {
        textRenderer(
          instance,
          td,
          row,
          col,
          prop,
          release?.time && !releaseItem?.deliveryDateTBD
            ? format(new Date(release?.time), "MM/dd/yyyy")
            : release?.timeTBD || releaseItem?.deliveryDateTBD
              ? HOLD_FOR_RELEASE_TEXT
              : "",
          cellProperties,
        );
        if (!releaseItem?.deliveryDateTBD) {
          applyClasses(td, "relative htCenter htMiddle text-gray-500");
        }
      } else {
        applyClasses(td, "relative htCenter htMiddle");
        textRenderer(instance, td, row, col, prop, value, cellProperties);
      }

      if (rowIsEmpty(rows[row])) {
        removeTooltip(td);
        return;
      }
      const includeTooltip =
        value !== HOLD_FOR_RELEASE_TEXT && (value || !release?.timeTBD);

      if (includeTooltip) {
        addIconWithTooltip({
          element: td,
          icon: "fa-calendar-xmark",
          tooltipText: intl.$t({
            id: "SET_ITEM_AS_HOLD_FOR_RELEASE",
          }),
          iconClasses: "cursor-pointer",
          onClick: () => {
            if (value === HOLD_FOR_RELEASE_TEXT) {
              instance?.setDataAtCell(row, col, "");
            } else {
              instance?.setDataAtCell(row, col, HOLD_FOR_RELEASE_TEXT);
            }
          },
        });
        removeTooltip(td);
      } else if (
        value === HOLD_FOR_RELEASE_TEXT ||
        (release?.timeTBD && !value)
      ) {
        applyTooltip(td, intl.$t({ id: "HOLD_FOR_RELEASE" }));
      }

      checkReadOnly(td, row, instance, cellProperties);
    },
    [
      release?.items,
      release?.timeTBD,
      release?.time,
      checkReadOnly,
      applyClasses,
      removeTooltip,
      addIconWithTooltip,
      intl,
      applyTooltip,
    ],
  );

  return renderer;
};
