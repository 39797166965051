import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { routes } from "@/config/routes";
import {
  InvoiceEmailFieldsFragment,
  InvoiceEmailStatus,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { useSplittingInvoicesWizard } from "../../../scanned-invoices/components/splitting-invoices/SplittingInvoicesWizardProvider";

const Button = tw(PrimaryButton)`w-25 px-0 h-6`;
const ButtonText = tw.div`text-white text-xs`;
const Container = tw.div``;
const InvoiceLink = tw.div``;
const ArchivedContainer = tw.div`text-xs`;

type Props = {
  invoiceEmail: InvoiceEmailFieldsFragment;
};

export const InvoiceEmailMatchedOrder: FC<Props> = ({ invoiceEmail }) => {
  const { openWizard } = useSplittingInvoicesWizard();

  const openWizardOnInspectAttachments = useCallback(() => {
    openWizard({
      initialPage: 1,
      urls: invoiceEmail.attachments.map((attachment) => attachment.asset.url),
      invoiceEmail,
    });
  }, [invoiceEmail, openWizard]);

  const isAllInvoicesArchived = useMemo(() => {
    return invoiceEmail.invoices.every((invoice) => invoice.archivedAt);
  }, [invoiceEmail.invoices]);

  if (
    (invoiceEmail.invoices || []).length === 0 ||
    invoiceEmail.status === InvoiceEmailStatus.AwaitingApproval
  ) {
    return (
      <Button onClick={openWizardOnInspectAttachments}>
        <FormattedMessage id="INSPECT" tagName={ButtonText} />
      </Button>
    );
  }
  return (
    <Container>
      <If isTrue={isAllInvoicesArchived}>
        <ArchivedContainer>
          <FormattedMessage id="DELETED" />
        </ArchivedContainer>
      </If>
      {invoiceEmail.invoices
        .filter((invoice) => !invoice.archivedAt)
        .map((invoice) => (
          <InvoiceLink key={invoice.id}>
            <LinkLike
              to={generatePath(routes.invoiceVerification, {
                invoiceId: invoice.id,
              })}
            >
              <FormattedMessage
                id="INVOICE_NUMBER"
                values={{ number: invoice.number }}
              />
            </LinkLike>
          </InvoiceLink>
        ))}
    </Container>
  );
};
