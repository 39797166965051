import { DecimalSafe } from "@/common/utils/decimalSafe";

export const sumIncludedFullPrices = (
  acc: number,
  item: {
    unitPrice: string;
    quantityDecimal: string;
    isIncluded: boolean;
  },
) => {
  const unitPrice = new DecimalSafe(
    item.isIncluded && item.unitPrice ? item.unitPrice : 0,
  );
  return Number(
    unitPrice
      .mul(new DecimalSafe(item.quantityDecimal ? item.quantityDecimal : 0))
      .add(new DecimalSafe(acc)),
  );
};
