import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import { If } from "@/common/components/if/If";
import { useCallback, useState } from "react";
import { AddEstimatedItemsDialog } from "../import-to-buyout/AddEstimatedItemsDialog";
import { BuyoutProjectItemsProvider } from "../providers/BuyoutProjectItemsProvider";
import { useSyncBuyoutItems } from "../providers/SyncBuyoutItemsProvider";

export const BuyoutAddItems = () => {
  const [showEstimatedItemsDialog, setShowEstimatedItemsDialog] =
    useState(false);
  const { syncBuyoutItems } = useSyncBuyoutItems();

  const gotoItemSelection = useCallback(async () => {
    const result = await syncBuyoutItems();
    if (result) {
      setShowEstimatedItemsDialog(true);
    }
  }, [syncBuyoutItems]);

  return (
    <>
      <AddItemsButton onClick={gotoItemSelection} />
      <If isTrue={showEstimatedItemsDialog}>
        <BuyoutProjectItemsProvider>
          <AddEstimatedItemsDialog
            setShowEstimatedItemsDialog={setShowEstimatedItemsDialog}
          />
        </BuyoutProjectItemsProvider>
      </If>
    </>
  );
};
