import { Exclamation } from "@/common/components/icons/Exclamation";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { formattedDate } from "@/common/utils/dates/DateView";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { BuyoutInclusionWrapper } from "../../document/components/BuyoutInclusionWrapper";
import { useBuyoutItemUnitPrice } from "./hooks/useBuyoutItemUnitPrice";

type Props = {
  item: BuyoutItemFieldsFragment;
};

export const BuyoutItemUnitPrice = ({ item }: Props) => {
  const { isExpired } = useBuyoutItemUnitPrice(item);

  const additionalNode = useMemo(() => {
    if (!isExpired) {
      return;
    }

    return (
      <Tooltip element={<Exclamation />}>
        <FormattedMessage
          id="BUYOUT_ITEM_EXPIRATION_DATE_WITH_VALUE"
          values={{
            date: formattedDate({ date: item.expirationDate }),
          }}
        />
      </Tooltip>
    );
  }, [isExpired, item.expirationDate]);

  return (
    <BuyoutInclusionWrapper item={item}>
      <ValueCurrency
        value={item.unitPrice || 0}
        perUom={item.projectItem.estimateUom}
        dynamicDigits
        mnemonicFirst={false}
        additionalNode={additionalNode}
      />
    </BuyoutInclusionWrapper>
  );
};
