import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseStatus } from "@/generated/graphql";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrderFromQuote } from "../../../../providers/OrderFromQuoteProvider";
import { useCreateOrderFromQuote } from "../../hooks/useCreateOrderFromQuote";
import { CreateOrderFromQuoteFormValues } from "./CreateOrderFromQuoteForm";
import { SubmitOrderUpdatesDialog } from "./SubmitOrderUpdatesDialog";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;

export const CreateOrderFromQuoteFooter: FC = () => {
  const {
    onCancel,
    handleSaveReleaseFromQuote,
    finalizeOrder,
    isSaving,
    isSaveDialogVisible,
    setIsSaveDialogVisible,
    onSave,
  } = useCreateOrderFromQuote();
  const { loading } = useOrderFromQuote();
  const { release } = useRelease();
  const { handleSubmit } = useFormContext<CreateOrderFromQuoteFormValues>();

  return (
    <FloatingFooterStyled>
      <ButtonContainer>
        <OutlinedButton className="py-0" onClick={onCancel}>
          <FormattedMessage id="CANCEL" />
        </OutlinedButton>
        <If isTrue={!release || release.status === ReleaseStatus.Draft}>
          <OutlinedButton
            className="py-0"
            onClick={handleSubmit(onSave)}
            loading={isSaving && loading}
            disabled={!isSaving && loading}
          >
            <FormattedMessage id="SAVE" />
          </OutlinedButton>
        </If>
        <PrimaryButton
          className="py-0"
          onClick={handleSubmit(finalizeOrder)}
          loading={isSaving && loading}
          disabled={!isSaving && loading}
        >
          <FormattedMessage
            id={
              !release || release.status === ReleaseStatus.Draft
                ? "FINALIZE_ORDER"
                : "UPDATE_RELEASE"
            }
          />
        </PrimaryButton>
      </ButtonContainer>
      <SubmitOrderUpdatesDialog
        onSubmit={async ({ skipVendorNotification }) => {
          return await handleSaveReleaseFromQuote({
            skipNavigate: true,
            skipVendorNotification,
          });
        }}
        setVisible={setIsSaveDialogVisible}
        visible={isSaveDialogVisible}
        isSaving={isSaving}
      />
    </FloatingFooterStyled>
  );
};
