import { ReleaseStatus } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useAddDeliverySlipStore } from "../../releases/pages/delivery-slips/store/useAddDeliverySlipStore";
import { useSyncReleaseItems } from "../pages/specify-details/hooks/useSyncReleaseItems";
import { useReleaseActions } from "../providers/ReleaseActionsProvider";
import { useRelease } from "../providers/ReleaseProvider";
import { useReleaseUpdate } from "../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../store/useReleaseStore";
import { RELEASE_STATUS_TRANSITIONS } from "./useReleaseBreadcrumbsAdditionalItems";

export const useReleaseStatusUpdate = () => {
  const { release } = useRelease();
  const { submitUpdate } = useReleaseUpdate();
  const { scheduleRelease, submitDraftRelease } = useReleaseActions();
  const { setAddSlipVisible, setSlipRelease } = useAddDeliverySlipStore();
  const { syncReleaseItems } = useSyncReleaseItems();
  const { hasChanges } = useReleaseStore();

  const statusOptions = useMemo(() => {
    if (release) {
      return RELEASE_STATUS_TRANSITIONS[release.status] || [];
    }
    return [];
  }, [release]);

  const changeStatus = useCallback(
    async (newStatus: ReleaseStatus, onSubmitRelease: () => void) => {
      if (!release) {
        return;
      }
      if (hasChanges) {
        await syncReleaseItems();
      }
      if (
        newStatus === ReleaseStatus.Received ||
        newStatus === ReleaseStatus.PartiallyReceived
      ) {
        setSlipRelease(release);
        setAddSlipVisible(true);
      } else if (
        release?.status === ReleaseStatus.Requested &&
        newStatus === ReleaseStatus.Scheduled
      ) {
        await scheduleRelease({
          releaseId: release.id,
          version: release.version,
        });
      } else if (
        release?.status === ReleaseStatus.Draft &&
        newStatus === ReleaseStatus.Reserved
      ) {
        submitDraftRelease(
          {
            skipConfirmation: true,
            reserve: true,
            skipVendorNotification: true,
          },
          release.id,
          (result) => {
            if (result?.data?.submitRelease) {
              onSubmitRelease();
            }
          },
        );
      } else if (
        release?.status === ReleaseStatus.Received ||
        release?.status === ReleaseStatus.PartiallyReceived
      ) {
        submitUpdate(release.version, {
          input: {
            releaseId: release.id,
            schedule: true,
          },
          skipConfirmation: true,
          skipVendorNotification: true,
          schedule: true,
        });
      }
    },
    [
      hasChanges,
      release,
      scheduleRelease,
      setAddSlipVisible,
      setSlipRelease,
      submitDraftRelease,
      submitUpdate,
      syncReleaseItems,
    ],
  );

  return {
    options: statusOptions,
    changeStatus,
  };
};
