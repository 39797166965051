import {
  AddressFieldsFragment,
  WarehouseFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const PROJECT_ADDRESS_ID = "project-address";

export const useWarehouseOptions = (
  options: WarehouseFieldsFragment[],
  projectAddress?: AddressFieldsFragment,
  existingWarehouse?: WarehouseFieldsFragment | null,
) => {
  const intl = useIntl();
  const warehouseOptions = useMemo(() => {
    if (!projectAddress) {
      return options;
    }

    return [
      {
        id: PROJECT_ADDRESS_ID,
        name: intl.$t({ id: "PROJECT_LOCATION_WITH_ADDRESS" }),
        address: projectAddress,
        trackInventory: false,
      },
      ...(existingWarehouse && existingWarehouse.archivedAt
        ? [
            {
              ...existingWarehouse,
              name: intl.$t(
                { id: "DELETED_WAREHOUSE" },
                { name: existingWarehouse.name },
              ),
            },
          ]
        : []),
      ...options.map((o) => ({
        ...o,
        name: intl.$t({ id: "FORMATTED_WAREHOUSE_NAME" }, { name: o.name }),
      })),
    ];
  }, [projectAddress, intl, existingWarehouse, options]);

  return { warehouseOptions };
};
