import { AssetItem } from "@/common/components/asset-item/AssetItem";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Loader } from "@/common/components/loader/Loader";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { DeliverySlipStatusChip } from "@/common/components/statuses/DeliverySlipStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { getUserName } from "@/common/utils/users/getUserName";
import { generateUUID } from "@/common/utils/uuidUtils";
import { routes } from "@/config/routes";
import { AssetContext, AssetType } from "@/generated/graphql";
import { CheckCircleOutline } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { BsExclamationCircle } from "react-icons/bs";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { ExpandedReleaseItem } from "../../providers/ReleaseProvider";
import { useReleaseItemDeliveredItems } from "./hooks/useReleaseItemDeliveredItems";

const ReceivedSoFar = tw.span`grid grid-flow-col gap-2 items-center mr-2`;
const CheckIcon = tw(
  CheckCircleOutline,
)`absolute left-5 top-1/4 items-center text-xl text-green-600 mr-1.5`;
const ErrorIcon = tw(
  BsExclamationCircle,
)`mr-1.5 left-5 absolute top-1/4 items-center text-lg fill-red-500`;
const ItemNameTitle = tw.div`text-xs font-thin mx-6`;
const ItemName = tw.div`text-xl font-medium mx-6`;
const Total = tw.div`font-medium text-lg`;
const Row = tw.div`flex h-20 items-center pl-3 pr-6 self-stretch`;
const Header = tw(Row)`bg-blue-100 mt-6 pl-6`;
const TitleCol = tw.div`flex-1 flex flex-col text-xs`;
const NumberCol = tw.div``;
const DetailsContainer = tw.div`grid grid-flow-row items-center h-fit`;
const SlipNumber = tw(LinkLike)`text-sm font-medium`;
const SlipDate = tw.div`text-xs font-medium`;
const CreatedByName = tw.div`text-xs font-light truncate max-w-44`;
const Items = tw.div`flex flex-1 flex-col pt-3 overflow-y-scroll gap-2`;

type Props = {
  releaseId: string | undefined;
  item: ExpandedReleaseItem;
};

export const ReleaseItemReceivedSoFarSidePanel: FC<Props> = ({
  item,
  releaseId,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const receivedQty = useMemo(
    () => new DecimalSafe(item.receivedQuantityDecimal || 0),
    [item.receivedQuantityDecimal],
  );

  const receivedEqualsOrdered = useMemo(
    () => receivedQty.equals(item.quantityDecimal),
    [receivedQty, item.quantityDecimal],
  );

  const itemIsLumpSum = isLumpSumItem(item);

  const { fetchDeliveredItems, deliveredItems, loadingDeliveredItems } =
    useReleaseItemDeliveredItems({
      releaseId,
      itemId: item.id,
    });

  if (!item.isIncluded) {
    return null;
  }

  if (receivedQty.equals(0)) {
    return (
      <ReceivedSoFar>
        <If isTrue={receivedQty.greaterThanOrEqualTo(item.quantityDecimal)}>
          <Tooltip
            id="received-quantity-tooltip"
            element={receivedEqualsOrdered ? <CheckIcon /> : <ErrorIcon />}
          >
            <FormattedMessage
              id={
                receivedEqualsOrdered
                  ? "RECEIVED_COMPLETELY"
                  : "RECEIVED_MORE_THAN_ORDERED"
              }
            />
          </Tooltip>
        </If>
        <ValueUnit
          value={item.receivedQuantityDecimal}
          uom={item.uom}
          renderAsPrice={itemIsLumpSum}
        />
      </ReceivedSoFar>
    );
  }

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <ReceivedSoFar
          onClick={() => {
            fetchDeliveredItems();
            togglePanel(true);
          }}
          className="cursor-pointer"
        >
          <If isTrue={receivedQty.greaterThanOrEqualTo(item.quantityDecimal)}>
            <Tooltip
              id="received-quantity-tooltip"
              element={receivedEqualsOrdered ? <CheckIcon /> : <ErrorIcon />}
            >
              <FormattedMessage
                id={
                  receivedEqualsOrdered
                    ? "RECEIVED_COMPLETELY"
                    : "RECEIVED_MORE_THAN_ORDERED"
                }
              />
            </Tooltip>
          </If>
          <ValueUnit
            value={item.receivedQuantityDecimal}
            uom={item.uom}
            classNames={{ container: "text-blue-500", footer: "text-blue-500" }}
            renderAsPrice={isLumpSumItem(item)}
          />
        </ReceivedSoFar>
      )}
      content={(togglePanel) => (
        <OverlayPanel
          title={intl.$t({ id: "RECEIVED_SO_FAR" })}
          onCancel={() => togglePanel(false)}
          className="px-0"
        >
          <FormattedMessage id="ITEM_NAME" tagName={ItemNameTitle} />
          <ItemName>{item.name}</ItemName>
          <Header>
            <TitleCol>
              <FormattedMessage id="TOTAL" tagName={Total} />
            </TitleCol>
            <NumberCol>
              <ValueUnit
                value={item.receivedQuantityDecimal}
                uom={item.uom}
                classNames={{ container: "font-medium" }}
                renderAsPrice={itemIsLumpSum}
              />
            </NumberCol>
          </Header>
          <Loader loading={loadingDeliveredItems}>
            <Items>
              {deliveredItems.map((deliveredItem, index) => (
                <Row key={deliveredItem.id}>
                  <AssetItem
                    key={deliveredItem.id}
                    asset={
                      deliveredItem.deliverySlip.asset ?? {
                        id: generateUUID(),
                        url: "",
                        type: AssetType.Image,
                        context: AssetContext.DeliverySlip,
                        createdAt: 0,
                      }
                    }
                    index={index}
                    readonly
                  />
                  <TitleCol className="ml-3">
                    <DetailsContainer>
                      <SlipNumber
                        onClick={() => {
                          navigate(
                            generatePath(routes.deliverySlipReceiveOrder, {
                              deliverySlipId: deliveredItem.deliverySlip.id,
                            }),
                          );
                        }}
                      >
                        <FormattedMessage id="DELIVERY_SLIP" />
                      </SlipNumber>
                      <DeliverySlipStatusChip
                        status={deliveredItem.deliverySlip.status}
                        type="small"
                        classes={{ root: "-ml-1" }}
                      />
                      <SlipDate>
                        <FormattedDate
                          value={deliveredItem.deliverySlip.createdAt}
                        />
                      </SlipDate>
                      <CreatedByName
                        title={getUserName(
                          deliveredItem.deliverySlip.createdBy,
                        )}
                      >
                        <FormattedMessage
                          id={
                            deliveredItem.deliverySlip?.asset
                              ? "SCANNED_BY"
                              : "RECORDED_BY"
                          }
                        />{" "}
                        {getUserName(deliveredItem.deliverySlip.createdBy)}
                      </CreatedByName>
                    </DetailsContainer>
                  </TitleCol>
                  <NumberCol>
                    <ValueUnit
                      value={deliveredItem.quantity}
                      uom={item.uom}
                      classNames={{ container: "font-medium" }}
                      renderAsPrice={itemIsLumpSum}
                    />
                  </NumberCol>
                </Row>
              ))}
            </Items>
          </Loader>
        </OverlayPanel>
      )}
    />
  );
};
