import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { NotificationsSetting } from "@/common/components/notifications-settings/NotificationsSetting";
import {
  BorderItem,
  ButtonsContainerStyled,
  Form,
  FormSection,
  Item,
  Label,
  LabelContainer,
  Mail,
  Phone,
  Text,
  UserProfileHeader,
  Value,
  ValueContainer,
} from "@/common/components/notifications-settings/NotificationsSetting.styles";
import { UpdateNotificationsInput } from "@/common/components/notifications-settings/types/UpdateNotificationsInput";
import { SMSValidation } from "@/common/components/sms-validation/SMSValidation";
import { InnerLabeledSwitchControlled } from "@/common/components/switch/InnerLabeledSwitchControlled";
import {
  SMSValidationProvider,
  useSMSVerification,
} from "@/common/providers/SMSValidationProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { useViewerOrgNotifications } from "@/contractor/pages/admin/organization/pages/org-notifications-settings/hooks/useViewerOrgNotifications";
import {
  BuyoutEvent,
  InvoiceEvent,
  MessageContextKind,
  QuoteEvent,
  ReleaseEvent,
  UpdateProfileInput,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useNotificationConfig } from "../../../../../common/components/notifications-settings/config/useNotificationConfig";
import { mapExcludedEventsToApi } from "../../../../../common/components/notifications-settings/utils/mapExcludedEventsToApi";
import { mapExcludedEventsToArray } from "../../../../../common/components/notifications-settings/utils/mapExcludedEventsToArray";
import { useViewerNotifications } from "./hooks/useViewerNotifications";

type UpdateProfileInputWithKeys = Pick<
  UpdateProfileInput,
  "getEmailNotifications" | "getSmsNotifications"
> &
  UpdateNotificationsInput;

const UserNotificationsSettingsWithProvider = () => {
  const { viewer, updateUser, updating } = useUser();
  const { loading, viewerNotifications } = useViewerNotifications();
  const intl = useIntl();
  const navigate = useNavigate();
  const { openDialog, cancelDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();
  const {
    initializeSMSVerification,
    resendSMSVerification,
    submitSMSVerification,
  } = useSMSVerification();
  const { config } = useNotificationConfig();
  const { viewerOrgNotifications, loading: loadingViewerOrgNotifications } =
    useViewerOrgNotifications();

  const excludedNotifications = useMemo(() => {
    return {
      excludedQuoteEvents: mapExcludedEventsToArray(
        viewerNotifications?.excludedQuoteEvents,
        Object.values(QuoteEvent),
        viewerOrgNotifications?.excludedQuoteEvents,
      ),
      excludedMessageContexts: mapExcludedEventsToArray(
        viewerNotifications?.excludedMessageContexts,
        Object.values(MessageContextKind),
        viewerOrgNotifications?.excludedMessageContexts,
      ),
      excludedBuyoutEvents: mapExcludedEventsToArray(
        viewerNotifications?.excludedBuyoutEvents,
        Object.values(BuyoutEvent),
        viewerOrgNotifications?.excludedBuyoutEvents,
      ),
      excludedReleaseEvents: mapExcludedEventsToArray(
        viewerNotifications?.excludedReleaseEvents,
        Object.values(ReleaseEvent),
        viewerOrgNotifications?.excludedReleaseEvents,
      ),
      excludedInvoiceEvents: mapExcludedEventsToArray(
        viewerNotifications?.excludedInvoiceEvents,
        Object.values(InvoiceEvent),
        viewerOrgNotifications?.excludedInvoiceEvents,
      ),
    };
  }, [viewerNotifications, viewerOrgNotifications]);

  const excludedMergedOrgNotificationsEvents = useMemo(() => {
    return [
      (viewerOrgNotifications?.excludedQuoteEvents || []).map((event) =>
        event.toString(),
      ),
      (viewerOrgNotifications?.excludedMessageContexts || []).map((event) =>
        event.toString(),
      ),
      (viewerOrgNotifications?.excludedBuyoutEvents || []).map((event) =>
        event.toString(),
      ),
      (viewerOrgNotifications?.excludedReleaseEvents || []).map((event) =>
        event.toString(),
      ),
      (viewerOrgNotifications?.excludedInvoiceEvents || []).map((event) =>
        event.toString(),
      ),
    ].flat();
  }, [viewerOrgNotifications]);

  const methods = useForm<UpdateProfileInputWithKeys>({
    defaultValues: {
      getSmsNotifications: viewer?.getSmsNotifications || false,
      getEmailNotifications: viewer?.getEmailNotifications || false,
      ...excludedNotifications,
    },
  });

  useEffect(() => {
    if (viewerNotifications) {
      methods.reset({
        getSmsNotifications: viewer?.getSmsNotifications || false,
        getEmailNotifications: viewer?.getEmailNotifications || false,
        ...excludedNotifications,
      });
    }
  }, [viewerNotifications, methods, viewer, excludedNotifications]);

  const save = useCallback(
    async (values: UpdateProfileInputWithKeys) => {
      const { getEmailNotifications, getSmsNotifications } = values;
      const excludedEvents = {
        excludedBuyoutEvents: mapExcludedEventsToApi(
          values.excludedBuyoutEvents,
        ),
        excludedInvoiceEvents: mapExcludedEventsToApi(
          values.excludedInvoiceEvents,
        ),
        excludedMessageContexts: mapExcludedEventsToApi(
          values.excludedMessageContexts,
        ),
        excludedQuoteEvents: mapExcludedEventsToApi(values.excludedQuoteEvents),
        excludedReleaseEvents: mapExcludedEventsToApi(
          values.excludedReleaseEvents,
        ),
      };
      if (!viewer?.isPhoneValidated && getSmsNotifications) {
        await updateUser({
          getEmailNotifications,
        });
        const verification = await initializeSMSVerification();
        if (verification) {
          openDialog({
            text: (
              <SMSValidation
                cancel={() => cancelDialog?.()}
                save={async () => {
                  const result = await updateUser({
                    getSmsNotifications: true,
                    getEmailNotifications,
                    ...excludedEvents,
                  });
                  if (result) {
                    setSuccessAlert(
                      intl.$t({ id: "PROFILE_INFORMATION_UPDATE_SUCCESS" }),
                    );
                    cancelDialog?.();
                    navigate(routes.projects);
                  }
                }}
                submit={submitSMSVerification}
                resend={resendSMSVerification}
                verification={verification}
              />
            ),
          });
        }
      } else {
        const result = await updateUser({
          getEmailNotifications,
          getSmsNotifications,
          ...excludedEvents,
        });
        if (result) {
          setSuccessAlert(
            intl.$t({ id: "PROFILE_INFORMATION_UPDATE_SUCCESS" }),
          );
          navigate(routes.projects);
        }
      }
    },
    [
      viewer,
      openDialog,
      cancelDialog,
      updateUser,
      setSuccessAlert,
      intl,
      initializeSMSVerification,
      submitSMSVerification,
      resendSMSVerification,
      navigate,
    ],
  );

  if (!viewerNotifications) {
    return null;
  }

  return (
    <Loader loading={loading || loadingViewerOrgNotifications}>
      <FormProvider {...methods}>
        <Helmet>
          <title>{intl.$t({ id: "NOTIFICATIONS_SETTINGS" })}</title>
        </Helmet>
        <FormattedMessage
          id="NOTIFICATIONS_SETTINGS"
          tagName={UserProfileHeader}
        />
        <Form>
          <FormSection $extraBottomMargin>
            <Item>
              <LabelContainer $roundedLeft>
                <BorderItem>
                  <Label>
                    <Mail />
                    <FormattedMessage
                      id="USER_EMAIL_NOTIFICATIONS"
                      tagName={Text}
                    />
                  </Label>
                </BorderItem>
              </LabelContainer>
              <ValueContainer>
                <BorderItem>
                  <Value>
                    <InnerLabeledSwitchControlled
                      name="getEmailNotifications"
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                    />
                  </Value>
                </BorderItem>
              </ValueContainer>
            </Item>
            <Item>
              <LabelContainer $roundedBottom>
                <BorderItem $lastItem>
                  <Phone />
                  <FormattedMessage
                    id="USERS_SMS_NOTIFICATION"
                    tagName={Text}
                  />
                </BorderItem>
              </LabelContainer>
              <ValueContainer>
                <BorderItem $lastItem>
                  <Value>
                    <InnerLabeledSwitchControlled
                      name="getSmsNotifications"
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                    />
                  </Value>
                </BorderItem>
              </ValueContainer>
            </Item>
          </FormSection>
          {config.map((category) => (
            <NotificationsSetting
              key={category.id}
              category={category}
              excludedOrgNotifications={excludedMergedOrgNotificationsEvents}
            />
          ))}
        </Form>
        <FloatingFooter>
          <ButtonsContainerStyled>
            <If isTrue={methods.formState.isDirty}>
              <OutlinedButton onClick={() => methods.reset()}>
                <FormattedMessage id="CANCEL" />
              </OutlinedButton>
            </If>
            <LoadingButton
              button={PrimaryButton}
              onClick={methods.handleSubmit(save)}
              loading={updating}
            >
              <FormattedMessage id="SAVE" />
            </LoadingButton>
          </ButtonsContainerStyled>
        </FloatingFooter>
      </FormProvider>
    </Loader>
  );
};

export const UserNotificationsSettings = () => (
  <SMSValidationProvider>
    <UserNotificationsSettingsWithProvider />
  </SMSValidationProvider>
);
