import { CategoryState } from "@/common/hooks/useToggleCategory";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { useInvoiceValidation } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceValidationProvider";
import { useOrderItemPoItemReferences } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderItemPoItemReferences";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback, useMemo } from "react";

export const useInvoiceReleaseItemZoneCheckbox = (
  zone:
    | CategoryState<ExpandedReleaseItem>
    | CategoryState<CategoryState<ExpandedReleaseItem>>,
) => {
  const { updateInvoice } = useInvoiceVerification();
  const { invoice } = useInvoiceVerification();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const { release } = useRelease();

  const items = useMemo(
    () => (zone as CategoryState<CategoryState<ExpandedReleaseItem>>).items,
    [zone],
  );

  const checked = useMemo(
    () =>
      items.every((item) =>
        (item.items || [])?.every(
          (item) => (item.invoiceItems || [])?.length > 0,
        ),
      ),
    [items],
  );

  const handleChange = useCallback(
    async (newChecked: boolean) => {
      const addedInvoicedReleaseItems = newChecked
        ? items
            .map((item) =>
              item.items
                .filter((item) => (item.invoiceItems || []).length === 0)
                .filter((item) => hasOrderItemPoItemReferences(item, release))
                .map((item) => ({
                  id: item.id,
                  releaseItemId: item.id,
                  quantity: item.quantityDecimal || "0",
                  unitPrice: item.unitPrice
                    ? item.unitPrice.toString()
                    : isLumpSumItem(item)
                      ? "1"
                      : "0",
                })),
            )
            .flat()
        : undefined;
      const removedInvoicedReleaseItems = !newChecked
        ? items
            .map(
              (item) =>
                item.items
                  .map((item) => item.invoiceItems?.[0].id)
                  .filter((id) => id) as string[],
            )
            .flat()
        : undefined;
      await updateInvoice(
        {
          id: invoice?.id || "",
          addedInvoicedReleaseItems,
          removedInvoicedReleaseItems,
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
      refetchInvoiceValidation();
    },
    [
      items,
      updateInvoice,
      invoice?.id,
      invoice?.release?.id,
      hasOrderItemPoItemReferences,
      release,
      refetchInvoiceValidation,
    ],
  );

  const hasSelectableItems = useMemo(
    () =>
      !items.some((item) =>
        (item.items || []).some(
          (item) => !hasOrderItemPoItemReferences(item, release),
        ),
      ),
    [hasOrderItemPoItemReferences, items, release],
  );

  return {
    checked,
    handleChange,
    hasSelectableItems,
  };
};
