import { getUserName } from "@/common/utils/users/getUserName";
import { useMemo } from "react";
import { ProjectType } from "./ProjectNameListDetails";

export const useProjectNameListDetails = (
  project: ProjectType | null | undefined,
) => {
  const projectMembers = useMemo(
    () =>
      project?.team?.map((teamMember) => getUserName(teamMember)).join(", ") ??
      "",
    [project?.team],
  );

  return { projectMembers };
};
