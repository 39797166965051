import { ReleaseItemsDateRangeFilter } from "@/common/components/release-items-date-range-filter/ReleaseItemsDateRangeFilter";
import { useIntl } from "react-intl";
import { useRelease } from "../../providers/ReleaseProvider";

export const ContractorReleaseItemsDateRangeFilter = () => {
  const intl = useIntl();
  const { filterDates, setFilterDates, timeTbdFilter, setTimeTbdFilter } =
    useRelease();

  return (
    <ReleaseItemsDateRangeFilter
      key="date-range-filter"
      filterDates={filterDates}
      setFilterDates={setFilterDates}
      timeTbdFilter={timeTbdFilter}
      setTimeTbdFilter={setTimeTbdFilter}
      labels={{
        timeTbd: intl.$t({ id: "FILTER_BY_HOLD_FOR_RELEASE_ITEMS" }),
      }}
    />
  );
};
