import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import {
  NUMERIC_DECIMAL_POINTS,
  TOTAL_PRICE_DECIMAL_POINTS,
} from "@/common/const";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { DistributorReleaseFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { RowInput, Styles } from "jspdf-autotable";
import { IntlShape } from "react-intl";

export const total = (
  doc: jsPDF,
  release: DistributorReleaseFieldsFragment,
  intl: IntlShape,
  formatCurrency: FormatCurrencyType,
) => {
  const additionalCharges = release.additionalCharges.map(
    (charge) =>
      [
        {
          content: `${charge.description}:`,
        },
        formatCurrency(charge.amount, {
          maximumFractionDigits: NUMERIC_DECIMAL_POINTS,
        }),
      ] as RowInput,
  );
  autoTable(doc, {
    theme: "plain",
    margin: { left: 190 },
    tableWidth: 93,
    showHead: false,
    styles: {
      fontSize: 10,
      halign: "right",
    },
    columns: [{ dataKey: "label" }, { dataKey: "value" }],
    body: [
      [
        {
          content: `${intl.$t({ id: "SUBTOTAL" })}:`,
          styles: { fontStyle: "bold" },
        },

        formatCurrency(release.netAmount, {
          maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
        }),
      ],
      ...additionalCharges,
      [
        {
          content: `${intl.$t({ id: "SALES_TAX" })}:`,
          styles: { fontStyle: "bold" },
        },
        release.customTaxAmount !== null &&
        release.customTaxAmount !== undefined
          ? formatCurrency(release.customTaxAmount)
          : `${new DecimalSafe(release.taxRate || 0).mul(
              100,
            )}% (${formatCurrency(Number(release.taxAmount))})`,
      ],
      ...(release.taxVariance !== null && release.taxVariance !== undefined
        ? [
            [
              {
                content: `${intl.$t({ id: "SALES_TAX_VARIANCE" })}:`,
                styles: { fontStyle: "bold" } as Partial<Styles>,
              },
              formatCurrency(release.taxVariance, {
                maximumFractionDigits: NUMERIC_DECIMAL_POINTS,
              }),
            ],
          ]
        : []),
      [
        {
          content: `${intl.$t({ id: "TOTAL" })}:`,
          styles: { fontStyle: "bold" },
        },
        formatCurrency(release.total, {
          maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
        }),
      ],
    ],
  });
};
