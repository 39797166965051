import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { BuyoutsBuyoutFieldsFragment } from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { useBuyoutCheckbox } from "./useBuyoutCheckbox";

const Container = tw.div`
   flex
`;

export type BuyoutCheckboxProps = {
  buyout: BuyoutsBuyoutFieldsFragment;
};

export const BuyoutCheckbox = ({ buyout }: BuyoutCheckboxProps) => {
  const { sequenceIds, handleChange } = useBuyoutCheckbox({ buyout });

  return (
    <Container>
      <SelectionCheckbox
        itemId={buyout.id}
        items={sequenceIds}
        setSelection={handleChange}
      />
    </Container>
  );
};
