import { getUserName } from "@/common/utils/users/getUserName";
import { routes } from "@/config/routes";
import {
  BuyoutStatus,
  Notification,
  NotificationBuyoutChangeContextFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { DetailsContainer, MessageBody } from "../common/Notification.styles";
import { BaseNotification, Sender } from "./BaseNotification";

type BuyoutChangeChangeNotificationProps = {
  notification: Notification;
};

export const BuyoutChangeNotification: FC<
  BuyoutChangeChangeNotificationProps
> = ({ notification }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const context =
    notification.context as NotificationBuyoutChangeContextFieldsFragment;

  const onCallback = () => {
    navigate(
      generatePath(routes.buyout, {
        id: context.buyout.id,
      }),
    );
  };

  const notificationMessage = useMemo(() => {
    if (
      context.oldBuyoutStatus === BuyoutStatus.Requested &&
      context.newBuyoutStatus === BuyoutStatus.Active
    ) {
      return intl.$t(
        { id: "NOTIFICATION_BUYOUT_ACCEPTED" },
        {
          vendorOrgName:
            context.buyout.preferredVendor?.sellerOrgLocation.org.name,
          clientIdentifier: context.buyout.clientIdentifier,
          projectName: context.buyout.project.name,
          vendorName: context.buyout.preferredVendor?.sellerOrgLocation.name,
        },
      );
    } else if (
      context.oldBuyoutStatus === BuyoutStatus.Draft &&
      context.newBuyoutStatus === BuyoutStatus.Active
    ) {
      return intl.$t(
        {
          id: "NOTIFICATION_BUYOUT_ACTIVATED",
        },
        {
          contractor: getUserName(context.buyout.createdBy),
          projectName: context.buyout.project.name,
        },
      );
    } else if (
      (context.oldBuyoutStatus === BuyoutStatus.Requested ||
        context.oldBuyoutStatus === BuyoutStatus.Active) &&
      context.newBuyoutStatus === BuyoutStatus.Cancelled
    ) {
      return intl.$t(
        {
          id: "NOTIFICATION_BUYOUT_CANCELLED",
        },
        {
          vendorOrgName:
            context.buyout.preferredVendor?.sellerOrgLocation.org.name,
          projectName: context.buyout.project.name,
        },
      );
    } else if (
      context.oldBuyoutStatus === BuyoutStatus.Requested &&
      context.newBuyoutStatus === BuyoutStatus.Declined
    ) {
      return intl.$t(
        {
          id: "NOTIFICATION_BUYOUT_DECLINED",
        },
        {
          vendorOrgName:
            context.buyout.preferredVendor?.sellerOrgLocation.org.name,
          clientIdentifier: context.buyout.clientIdentifier,
          projectName: context.buyout.project.name,
          vendorName: context.buyout.preferredVendor?.sellerOrgLocation.name,
        },
      );
    } else if (
      context.oldBuyoutStatus === BuyoutStatus.Active &&
      context.newBuyoutStatus === BuyoutStatus.Depleted
    ) {
      return intl.$t(
        {
          id: "NOTIFICATION_BUYOUT_DEPLETED",
        },
        {
          vendorOrgName:
            context.buyout.preferredVendor?.sellerOrgLocation.org.name,
          projectName: context.buyout.project.name,
        },
      );
    } else if (
      context.oldBuyoutStatus === BuyoutStatus.Draft &&
      context.newBuyoutStatus === BuyoutStatus.Requested
    ) {
      return intl.$t(
        {
          id: "NOTIFICATION_BUYOUT_REQUESTED",
        },
        {
          contractor:
            context.buyout.preferredVendor?.sellerOrgLocation.org.name,
          projectName: context.buyout.project.name,
        },
      );
    } else if (
      context.oldBuyoutStatus === BuyoutStatus.Requested &&
      context.newBuyoutStatus === BuyoutStatus.Requested
    ) {
      return intl.$t(
        {
          id: "NOTIFICATION_BUYOUT_UPDATED",
        },
        {
          contractor:
            context.buyout.preferredVendor?.sellerOrgLocation.org.name,
          projectName: context.buyout.project.name,
        },
      );
    } else {
      return "";
    }
  }, [context, intl]);

  return (
    <BaseNotification notification={notification} onCallback={onCallback}>
      <DetailsContainer>
        <Sender>{getUserName(context?.initiatedBy) ?? ""}</Sender>
        <MessageBody>{notificationMessage}</MessageBody>
      </DetailsContainer>
    </BaseNotification>
  );
};
