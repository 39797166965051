import { AssetDate } from "@/common/components/asset-date/AssetDate";
import { useCallback } from "react";

export const useInvoiceReleaseItems = () => {
  const getNotesImageDetails = useCallback(
    (url: string, createDate?: number) => {
      return <AssetDate date={createDate} className="mt-1" />;
    },
    [],
  );

  return { getNotesImageDetails };
};
