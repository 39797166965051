import { CategoryState } from "@/common/hooks/useToggleCategory";
import { Identity } from "@/types/Identity";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { CategoryType } from "../enums/CategoryType";
import { useGridTableContext } from "../providers/GridTableProvider";
import { GridTableProps } from "../types/GridTableProps";

export const useGridTable = <T extends Identity, TAdditional>({
  items,
}: Pick<GridTableProps<T, TAdditional>, "items">) => {
  const { categoryType } = useGridTableContext(
    useShallow((state) => ({
      categoryType: state.table.categoryType,
    })),
  );
  const hasItems = useMemo(() => {
    switch (categoryType) {
      case CategoryType.ONE_LEVEL:
        return (items || [])?.length > 0;
      case CategoryType.TWO_LEVELS:
      case CategoryType.THREE_LEVELS:
        return (
          (items || [])?.length > 0 ||
          (items as CategoryState<T>[])?.[0]?.items?.length > 0
        );
      default:
        return false;
    }
  }, [categoryType, items]);

  return { hasItems };
};
