import {
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { QuestionBox } from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useUser } from "@/common/providers/UserProvider";
import { formattedDate } from "@/common/utils/dates/DateView";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { MessageContextKind, ReleaseStatus } from "@/generated/graphql";
import { FC, ReactElement, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { ReleaseSequenceStepper } from "../../releases/pages/deliveries/components/ReleaseSequenceStepper";
import { useReleaseSequence } from "../../releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { useAddDeliverySlipStore } from "../../releases/pages/delivery-slips/store/useAddDeliverySlipStore";
import {
  ReleaseBreadcrumbsActions,
  useReleaseBreadcrumbsAdditionalItems,
} from "../hooks/useReleaseBreadcrumbsAdditionalItems";
import {
  ReleaseExportButtonView,
  useReleaseExportButtons,
} from "../hooks/useReleaseExportButtons";
import { ReleasePrintButton } from "../pages/release-details/ReleasePrintButton";
import { ReleaseUserDetails } from "../pages/specify-details/footer-actions/ReleaseUserDetails";
import { useSyncReleaseItems } from "../pages/specify-details/hooks/useSyncReleaseItems";
import { useReleaseActions } from "../providers/ReleaseActionsProvider";
import { useRelease } from "../providers/ReleaseProvider";
import { ReleaseItemListFilters } from "../release-items-list/components/ReleaseItemListFilters";
import { useReleaseStore } from "../store/useReleaseStore";
import { ReleaseChipStatusWithSelector } from "./ReleaseChipStatusWithSelector";
import { ReleaseMessagesButton } from "./ReleaseMessagesButton";
import { ConnectFoundationReleasePOButton } from "./connections/components/buttons/ConnectFoundationReleasePoButton";
import { ConnectReleasePOButton } from "./connections/components/buttons/ConnectReleasePoButton";
import { EditReleasePOButton } from "./connections/components/buttons/EditReleasePoButton";
import { ExportReleaseButton } from "./connections/components/buttons/ExportReleaseButton";
import {
  ReleaseExportStatus,
  ReleaseExportStatusEnum,
} from "./connections/components/common/ReleaseExportStatus";

const CustomBreadcrumbItem = tw.div`grid grid-flow-col gap-2 text-lg truncate`;
const LinkLikeStyled = tw(LinkLike)`text-base`;
const Text = tw.div`text-base flex gap-1 items-center`;
const ReleaseDeletedInfo = tw.div`ml-4 italic text-base`;

type Props = {
  sub?: string;
  shoppingIcon?: ReactElement;
  canGroupPrintData?: boolean;
  editableMode?: boolean;
};

export const ReleaseBreadcrumbs: FC<Props> = ({
  sub,
  shoppingIcon,
  canGroupPrintData = false,
  editableMode = false,
}) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { release } = useRelease();
  const { connectedSourceSystem } = useOrgSettings();
  const { scheduleRelease } = useReleaseActions();
  const { sequenceActive } = useReleaseSequence();
  const { setAddSlipVisible, setSlipRelease } = useAddDeliverySlipStore();
  const { hasChanges } = useReleaseStore();
  const { syncReleaseItems } = useSyncReleaseItems();
  const { poNumbering } = useUser();
  const { locations } = useUserLocations();
  const getAdditionalActions = useReleaseBreadcrumbsAdditionalItems();
  const { getReleaseExportButtons } = useReleaseExportButtons();

  const releaseLocation = useMemo(
    () =>
      locations.find((location) => location.id === release?.orgLocation?.id),
    [locations, release?.orgLocation?.id],
  );

  const breadcrumbs = useMemo(() => {
    const items = [
      {
        text: intl.$t({ id: "DELIVERIES" }),
        link: routes.deliveries,
        id: "deliveries",
      },
    ] as BreadcrumbItem[];

    if (release) {
      const releaseNumberTextId = !release.sequenceNumber
        ? release.buyout
          ? "DRAFT_FROM_BUYOUT"
          : "RELEASE_STATUS_DRAFT"
        : release.buyout
          ? "ORDER_NUMBER_WITH_BUYOUT"
          : "ORDER_NUMBER_WITHOUT_BUYOUT";
      items.push({
        text: "",
        id: "buyout",
        custom: (
          <CustomBreadcrumbItem data-testid="release-with-number">
            <FormattedMessage
              id={releaseNumberTextId}
              values={{
                sequenceNumber: release.sequenceNumber,
                link: (...chunks) => {
                  if (!sub) {
                    return chunks;
                  }
                  return (
                    <LinkLikeStyled
                      to={generatePath(routes.delivery, {
                        deliveryId: release.id,
                      })}
                    >
                      {chunks}
                    </LinkLikeStyled>
                  );
                },
              }}
              tagName={Text}
            />
            {release.buyout ? (
              <LinkLikeStyled
                to={generatePath(routes.buyout, {
                  id: release?.buyout?.id,
                })}
              >
                #{release.buyout?.clientIdentifier}
              </LinkLikeStyled>
            ) : null}
          </CustomBreadcrumbItem>
        ),
      });
    }

    if (sub) {
      items.push({
        text: intl.$t({ id: sub }),
        id: `sub-${sub}`,
      });
    }
    return items;
  }, [release, intl, sub]);

  const releaseExportButtons = useMemo(() => {
    if (!release) {
      return [];
    }

    return getReleaseExportButtons().map((buttonType) => {
      switch (buttonType) {
        case ReleaseExportButtonView.Immutable:
          return (
            <ReleaseExportStatus
              key="immutable-release-status"
              status={
                release?.poLink?.syncing
                  ? ReleaseExportStatusEnum.Syncing
                  : ReleaseExportStatusEnum.OK
              }
              sourceSystem={release?.poLink?.sourceSystem}
              date={release?.poLink?.syncedAt}
              poNumber={release?.poNumber}
              projectId={release?.project?.id}
              poLink={release?.poLink}
            />
          );
        case ReleaseExportButtonView.FoundationIntegration:
          return <ConnectFoundationReleasePOButton release={release} />;
        case ReleaseExportButtonView.GenericIntegration:
          return <ExportReleaseButton release={release} key="export-release" />;
        case ReleaseExportButtonView.PoLinkOrPoExists:
          return (
            <EditReleasePOButton
              release={release}
              key="edit-po"
              sourceSystem={connectedSourceSystem}
            />
          );
        case ReleaseExportButtonView.Connect:
          return (
            <ConnectReleasePOButton
              release={release}
              key="connect-po"
              sourceSystem={connectedSourceSystem}
            />
          );
      }
    });
  }, [release, getReleaseExportButtons, connectedSourceSystem]);

  const actions = useMemo(() => {
    const bActions = [];

    if (release) {
      if (!sequenceActive) {
        bActions.push(
          <ReleaseUserDetails release={release} key="release-user-details" />,
        );
      }

      if (release.status !== ReleaseStatus.Draft && !editableMode) {
        bActions.push(
          <ListHeaderFilterButton
            key="filters"
            filter={<ReleaseItemListFilters />}
            classes={{
              button: "hover:border-white flex-row-reverse px-0 py-0",
              buttonText: "text-xs",
            }}
            hideLabel={sequenceActive}
          />,
        );
      }

      bActions.push(
        <ReleasePrintButton
          release={release}
          key="print-release"
          canGroupPrintData={canGroupPrintData}
        />,
      );

      bActions.push(
        <ReleaseMessagesButton
          key="messages"
          release={release}
          text={
            <QuestionBox>
              <Tooltip
                id="release-messages"
                element={
                  <MessageButton
                    id={release.id}
                    kind={MessageContextKind.Release}
                  />
                }
              >
                <FormattedMessage id="MESSENGER" />
              </Tooltip>
            </QuestionBox>
          }
        />,
      );

      if (release?.sellerOrgLocation) {
        bActions.push(...releaseExportButtons);
      }
      if (sequenceActive) {
        bActions.push(<ReleaseSequenceStepper />);
      }
    }
    if (shoppingIcon) {
      bActions.push(shoppingIcon);
    }
    return bActions;
  }, [
    release,
    shoppingIcon,
    sequenceActive,
    editableMode,
    canGroupPrintData,
    releaseExportButtons,
  ]);

  const appendItems = useMemo(() => {
    if (!release) {
      return [];
    }
    return getAdditionalActions().map((action) => {
      if (action === ReleaseBreadcrumbsActions.ReleaseStatusChipWithUpdates) {
        return (
          <ReleaseChipStatusWithSelector
            release={release}
            key={ReleaseBreadcrumbsActions.ReleaseStatusChipWithUpdates}
          />
        );
      } else if (action === ReleaseBreadcrumbsActions.ReleaseStatusChip) {
        return (
          <ReleaseStatusChip
            key={ReleaseBreadcrumbsActions.ReleaseStatusChip}
            status={release.status}
            type="breadcrumb"
            releaseType={release.type}
          />
        );
      } else if (action === ReleaseBreadcrumbsActions.ReleaseDeletedNote) {
        return (
          <FormattedMessage
            key={ReleaseBreadcrumbsActions.ReleaseDeletedNote}
            tagName={ReleaseDeletedInfo}
            id="DELETED_AT"
            values={{
              deletedAt: formattedDate({ date: release.deletedAt }),
            }}
          />
        );
      } else {
        return <></>;
      }
    });
    //commented due to submitDraftRelease is not memoised
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    release,
    hasChanges,
    intl,
    poNumbering,
    releaseLocation?.permissions.submitReleaseDirectly,
    syncReleaseItems,
    setSlipRelease,
    setAddSlipVisible,
    scheduleRelease,
    openDialog,
  ]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      appendItems={appendItems}
      actions={actions}
    />
  );
};
