import {
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { QuestionBox } from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { DeliverySlipStatusChip } from "@/common/components/statuses/DeliverySlipStatusChip";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { routes } from "@/config/routes";
import { ReleaseMessagesButton } from "@/contractor/pages/home/release/components/ReleaseMessagesButton";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { MessageContextKind } from "@/generated/graphql";
import { FC, ReactElement, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";
import tw from "tailwind-styled-components";
import { useDeliverySlipSequence } from "../../providers/DeliverySlipSequenceProvider";
import { useDeliverySlipVerification } from "../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipListFilters } from "./DeliverySlipListFilters";
import { DeliverySlipSequenceStepper } from "./sequence-stepper/DeliverySlipSequenceStepper";

type Props = {
  receive?: boolean;
};

const LinkLikeStyled = tw(LinkLike)`
  text-lg
`;

const CustomBreadcrumbItem = tw.div`
  grid grid-flow-col gap-2 text-lg truncate
`;

export const DeliverySlipBreadcrumbs: FC<Props> = ({ receive }) => {
  const intl = useIntl();
  const { deliverySlip } = useDeliverySlipVerification();
  const { deliveryId } = useParams();
  const { release } = useRelease();
  const { sequenceActive } = useDeliverySlipSequence();

  const breadcrumbs = useMemo(() => {
    const items = [] as BreadcrumbItem[];
    if (deliveryId) {
      items.push({
        text: intl.$t({ id: "DELIVERIES" }),
        link: routes.deliveries,
        id: "deliveries",
      });

      if (release) {
        const releaseNumberTextId = !release.sequenceNumber
          ? release.buyout
            ? "DRAFT_FROM_BUYOUT"
            : "RELEASE_STATUS_DRAFT"
          : release.buyout
            ? "ORDER_NUMBER_WITH_BUYOUT"
            : "ORDER_NUMBER_WITHOUT_BUYOUT";
        items.push({
          text: "",
          id: "buyout",
          custom: (
            <CustomBreadcrumbItem data-testid="release-with-number">
              <FormattedMessage
                id={releaseNumberTextId}
                values={{
                  sequenceNumber: release.sequenceNumber,
                  link: (...chunks) => {
                    return (
                      <LinkLikeStyled
                        to={generatePath(routes.delivery, {
                          deliveryId: release.id,
                        })}
                      >
                        {chunks}
                      </LinkLikeStyled>
                    );
                  },
                }}
              />
              {release.buyout ? (
                <LinkLikeStyled
                  to={generatePath(routes.buyout, {
                    id: release?.buyout?.id,
                  })}
                >
                  #{release.buyout?.clientIdentifier}
                </LinkLikeStyled>
              ) : null}
            </CustomBreadcrumbItem>
          ),
        });
      }
    } else {
      items.push({
        text: intl.$t({ id: "PACKING_SLIPS" }),
        link: routes.deliverySlips,
        id: "deliverySlips",
      });
    }

    if (receive) {
      items.push({
        text: deliverySlip?.release?.sequenceNumber
          ? intl.$t(
              { id: "RECEIVE_ORDER_NUMBER" },
              {
                orderNumber: deliverySlip?.release?.sequenceNumber
                  ? deliverySlip?.release?.sequenceNumber
                  : "",
              },
            )
          : intl.$t({ id: "CONFIRM_DELIVERY" }),
        id: "receiveOrder",
      });
    } else if (deliverySlip) {
      items.push({
        text: intl.$t({ id: "MATCH_ORDER_TO_SLIP" }),
        link: generatePath(routes.deliverySlipMatchOrder, {
          deliverySlipId: deliverySlip.id,
        }),
        id: "deliverySlip",
      });
    } else {
      items.push({
        text: intl.$t({ id: "MATCH_ORDER_TO_SLIP" }),
        id: "deliverySlip",
      });
    }

    return items;
  }, [deliveryId, deliverySlip, intl, receive, release]);

  const appendedItems = useMemo(() => {
    const items = [] as ReactElement[];

    if (deliveryId && release) {
      items.push(
        <ReleaseStatusChip
          key="status"
          status={release.status}
          releaseType={release.type}
          type="breadcrumb"
        />,
      );
    } else if (deliverySlip) {
      items.push(
        <DeliverySlipStatusChip
          key="status"
          status={deliverySlip?.status}
          type="breadcrumb"
        />,
      );
    }
    return items;
  }, [deliveryId, deliverySlip, release]);

  const actions = useMemo(() => {
    const bActions = [];

    if (release) {
      bActions.push(
        <ListHeaderFilterButton
          key="filters"
          filter={<DeliverySlipListFilters />}
          classes={{
            button: "hover:border-white flex-row-reverse px-0 py-0",
            buttonText: "text-xs",
          }}
          hideLabel
        />,
      );

      bActions.push(
        <ReleaseMessagesButton
          key="messages"
          release={release}
          text={
            <QuestionBox>
              <Tooltip
                id="delivery-slip-messages"
                element={
                  <MessageButton
                    id={release.id}
                    kind={MessageContextKind.Release}
                  />
                }
              >
                <FormattedMessage id="MESSENGER" />
              </Tooltip>
            </QuestionBox>
          }
        />,
      );
    }
    if (sequenceActive) {
      bActions.push(
        <DeliverySlipSequenceStepper key="delivery-slips-sequence-stepper" />,
      );
    }
    return bActions;
  }, [release, sequenceActive]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      appendItems={appendedItems}
      actions={actions}
    />
  );
};
