import FileAllCheck from "@/assets/icons/file-all-check.svg?react";
import File from "@/assets/icons/file.svg?react";
import ImportFile from "@/assets/icons/import-file.svg?react";
import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import {
  AddItemsFromDialog,
  AddItemsOptionType,
} from "@/common/components/add-items-from-dialog/AddItemsFromDialog";
import { TaxesGroup } from "@/common/components/additional-charges/AdditionalCharges.styles";
import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { PageBackButton } from "@/common/components/page-back-button/PageBackButton";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { ReleaseDeliveryPhotos } from "@/common/components/release-images/ReleaseDeliveryPhotos";
import { ReleaseDeliverySlips } from "@/common/components/release-images/ReleaseDeliverySlips";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { SpreadsheetSaveType } from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  InstructionInput,
  ReleaseStatus,
  ServiceType,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { SpecifyCostCode } from "../../../../../../common/components/specify-cost-code/SpecifyCostCode";
import { useOrderTypesConfig } from "../../../../../../common/hooks/order-types-config/useOrderTypesConfig";
import { ReceiveReleaseDialog } from "../../../releases/pages/deliveries/components/receive-release-dialog/ReceiveReleaseDialog";
import { ReleaseDescription } from "../../components/ReleaseDescription";
import { ContractorReleaseAdditionalChargesAndTaxes } from "../../components/contractor-release-additional-charges-and-taxes/ContractorReleaseAdditionalChargesAndTaxes";
import { OrderApprovalsStepper } from "../../components/order-approval/OrderApprovalsStepper";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../store/useReleaseStore";
import { AddItemsToReleaseFromBuyoutDialog } from "../add-items-to-release/AddItemsToReleaseFromBuyoutDialog";
import { AddItemsToReleaseFromEstimatesDialog } from "../add-items-to-release/AddItemsToReleaseFromEstimatesDialog";
import { RequestInventoryReceiptToggle } from "./components/RequestInventoryReceiptToggle";
import { VendorReassignmentWizard } from "./components/VendorReassignmentWizard";
import { IMPORT_MATERIALS_DRAWER } from "./components/import-from-file/ImportReleaseItemsDrawer";
import { CancelReleaseButton } from "./footer-actions/CancelReleaseButton";
import { SubmitReleaseButton } from "./footer-actions/SubmitReleaseButton";
import { ReleaseRejectReasonView } from "./footer-actions/reject-reason/ReleaseRejectReasonView";
import { useSyncReleaseItems } from "./hooks/useSyncReleaseItems";

const ButtonContainer = tw.div`
  flex justify-end gap-2
`;

const Container = tw.div`
  grid justify-end mt-20 mb-9 justify-items-end
`;

const FooterGroup = tw.div`
  grid grid-flow-col items-center gap-2
`;

const FooterContent = tw.div`grid grid-cols-[100px_auto_1fr_auto] gap-4`;

const OrderRequestContainer = tw.div`grid`;

type Props = {
  items: ExpandedReleaseItem[];
};

const DELIVERY_SLIP_EDITABLE_STATES = [
  ReleaseStatus.Draft,
  ReleaseStatus.Requested,
  ReleaseStatus.Scheduled,
  ReleaseStatus.Received,
  ReleaseStatus.PartiallyReceived,
];

export const SpecifyDetailsFooter: FC<Props> = ({ items }) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { updateRelease, updating, submitUpdate } = useReleaseUpdate();
  const { validateItems, saving, syncReleaseItems } = useSyncReleaseItems();
  const { orderTypeConfig } = useOrderTypesConfig({ release });
  const { toggle } = useGlobalDrawer();

  const [showAddItemsDialog, setShowAddItemsDialog] = useState<boolean>(false);
  const [addFromBuyoutDialog, setAddFromBuyoutDialog] = useState<boolean>(
    release?.status === ReleaseStatus.Draft &&
      release?.items?.length === 0 &&
      !!release.buyout,
  );
  const [addFromEstimatesDialog, setAddFromEstimatesDialog] = useState(false);
  const { hasChanges, release: storeRelease } = useReleaseStore();
  const { setWarningAlert, setSuccessAlert } = useSnackbar();

  const [isWillCallDelivery, setIsWillCallDelivery] = useState(
    !release?.includeServices.includes(ServiceType.Delivery),
  );
  const [vendorStocking, setVendorStocking] = useState(
    release?.includeServices.includes(ServiceType.Stocking) ?? false,
  );

  const toggleService = useCallback(
    async (service: ServiceType) => {
      const includeServices = [];
      if (service === ServiceType.Delivery) {
        setIsWillCallDelivery(!isWillCallDelivery);
        includeServices.push({
          type: ServiceType.Delivery,
          remove: !isWillCallDelivery,
        });
        if (!isWillCallDelivery) {
          setVendorStocking(false);
          includeServices.push({
            type: ServiceType.Stocking,
            remove: true,
          });
        }
      }
      if (service === ServiceType.Stocking) {
        setVendorStocking(!vendorStocking);
        includeServices.push({
          type: ServiceType.Stocking,
          remove: vendorStocking,
        });
      }

      if (release) {
        const input: UpdateContractorReleaseInput = {
          releaseId: release?.id,
          version: release?.version,
          includeServices,
        };
        await updateRelease(input);
      }
    },
    [isWillCallDelivery, release, updateRelease, vendorStocking],
  );

  const gotoItemSelection = async () => {
    const result = await validateItems({ type: SpreadsheetSaveType.Toggle });
    if (result) {
      setShowAddItemsDialog(true);
    }
  };

  const showChangesAlert = useCallback(() => {
    setWarningAlert(
      intl.$t({
        id: "NEED_TO_SAVE_CHANGES_SWITCH_VIEW",
      }),
    );
  }, [intl, setWarningAlert]);

  const dialogOptions = useMemo<AddItemsOptionType[]>(
    () => [
      ...(release?.buyout
        ? [
            {
              label: "ADD_FROM_BUYOUT_ITEMS",
              details: "ADD_FROM_BUYOUT_ITEMS_INFO",
              callback: () => {
                setShowAddItemsDialog(false);
                setAddFromBuyoutDialog(true);
              },
              icon: <FileAllCheck className="h-28 w-28" />,
              preferred: true,
            },
          ]
        : []),
      {
        label: "ADD_FROM_PROJECT_ESTIMATED_ITEMS",
        details: "ADD_FROM_PROJECT_ESTIMATED_ITEMS_INFO",
        callback: () => {
          setShowAddItemsDialog(false);
          setAddFromEstimatesDialog(true);
        },
        icon: <File className="h-28 w-28" />,
      },
      {
        label: "ADD_FROM_IMPORT",
        details: "ADD_FROM_IMPORT_INFO",
        callback: () => {
          setShowAddItemsDialog(false);
          if (hasChanges) {
            showChangesAlert();
          } else {
            toggle(IMPORT_MATERIALS_DRAWER, true);
          }
        },
        icon: <ImportFile className="h-28 w-28" />,
      },
    ],
    [release?.buyout, hasChanges, showChangesAlert, toggle],
  );

  const saveInstructions = (instruction: InstructionInput) => {
    if (release) {
      const input: UpdateContractorReleaseInput = {
        releaseId: release.id,
        version: release.version ?? 0,
        instructions: instruction,
      };
      return updateRelease(input);
    }
  };

  if (!release) {
    return null;
  }

  return (
    <>
      <AddItemsFromDialog
        title="ADD_TO_RELEASE"
        options={dialogOptions}
        visible={showAddItemsDialog}
        setVisible={setShowAddItemsDialog}
      />
      <AddItemsToReleaseFromBuyoutDialog
        visible={addFromBuyoutDialog}
        setVisible={setAddFromBuyoutDialog}
      />
      <AddItemsToReleaseFromEstimatesDialog
        visible={addFromEstimatesDialog}
        setVisible={setAddFromEstimatesDialog}
      />
      <ReleaseRejectReasonView rejectionReason={release?.rejectionReason} />
      <Instructions
        projectId={release?.project?.id}
        saveInstruction={saveInstructions}
        saving={updating}
        instruction={release?.instructions}
        key={release?.id}
      >
        <SpecifyCostCode
          costCode={release.costCode}
          containerClassName="my-2"
          onCostCodeChange={(costCodeId) => {
            updateRelease({
              releaseId: release?.id,
              costCodeId,
            });
          }}
        />
        <ReleaseDescription release={release} directlyEditable={true} />
      </Instructions>
      <If
        isTrue={
          release && DELIVERY_SLIP_EDITABLE_STATES.includes(release.status)
        }
      >
        <ReleaseDeliverySlips
          release={release}
          deliverySlips={release.deliverySlips}
        />
        <ReleaseDeliveryPhotos release={release} />
      </If>
      <Container>
        <ContractorReleaseAdditionalChargesAndTaxes
          release={storeRelease}
          editableByContractor
          includeInvoicedTotals={[
            ReleaseStatus.PartiallyReceived,
            ReleaseStatus.Received,
          ].includes(release.status as ReleaseStatus)}
          additionalItems={
            <OrderRequestContainer>
              <If isTrue={orderTypeConfig.features.includeWillCall}>
                <TaxesGroup>
                  <FormattedMessage id="REQUEST_VENDOR_RELEASE" />
                  <InnerLabeledSwitch
                    toggle={() => toggleService(ServiceType.Delivery)}
                    width={55}
                    initialValue={isWillCallDelivery}
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    className="flex w-full justify-end"
                  />
                </TaxesGroup>
              </If>
              <If
                isTrue={
                  orderTypeConfig.features.includeVendorStocking &&
                  !isWillCallDelivery
                }
              >
                <TaxesGroup>
                  <FormattedMessage id="REQUEST_VENDOR_STOCKING" />
                  <InnerLabeledSwitch
                    toggle={() => toggleService(ServiceType.Stocking)}
                    width={55}
                    initialValue={vendorStocking}
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    className="flex w-full justify-end"
                  />
                </TaxesGroup>
              </If>
            </OrderRequestContainer>
          }
        />

        {release && (
          <FooterGroup>
            <AddItemsButton onClick={gotoItemSelection} />
            <If isTrue={items}>
              <FloatingFooter>
                <FooterContent>
                  <span />
                  <VendorReassignmentWizard />
                  <ButtonContainer>
                    <RequestInventoryReceiptToggle />
                    <OrderApprovalsStepper />
                    <PageBackButton />
                    <CancelReleaseButton release={release} />
                    <If
                      isTrue={
                        release.status === ReleaseStatus.Draft ||
                        release.status === ReleaseStatus.Reserved ||
                        release.status === ReleaseStatus.AwaitingApproval ||
                        release.status === ReleaseStatus.Canceled
                      }
                    >
                      <LoadingButton
                        button={OutlinedButton}
                        data-sentry="save-order-changes"
                        onClick={async () => {
                          const result = await syncReleaseItems(
                            SpreadsheetSaveType.SaveButton,
                          );
                          if (!result) {
                            return;
                          }
                          await submitUpdate(release?.version, {
                            forceVersion: true,
                            callback: () => {
                              if (hasChanges) {
                                setSuccessAlert(
                                  intl.$t({
                                    id: "CHANGES_SAVED",
                                  }),
                                );
                              }
                            },
                          });
                        }}
                        disabled={!hasChanges}
                      >
                        <FormattedMessage id="SAVE" />
                      </LoadingButton>
                    </If>
                    <SubmitReleaseButton
                      release={release}
                      items={items}
                      disabled={saving}
                    />
                  </ButtonContainer>
                </FooterContent>
              </FloatingFooter>
            </If>
          </FooterGroup>
        )}
      </Container>
      <ReceiveReleaseDialog />
    </>
  );
};
