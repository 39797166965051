import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  LinkBudgetInput,
  UpdateBudgetLinkInput,
  UpdateProjectInput,
  useLinkBudgetMutation,
  useProjectAllowanceQuery,
  useSyncBudgetMutation,
  useUnlinkBudgetMutation,
  useUpdateBudgetLinkMutation,
  useUpdateProjectAllowanceMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../../../store/useProjectStore";
import { useBudgetStore } from "../store/useBudgetStore";

type ProjectBudgetInput = Pick<
  UpdateProjectInput,
  "budgetAllowance" | "restrictCostCodes"
>;

export const useProjectBudget = () => {
  const { id } = useParams();
  const { data, error, loading } = useProjectAllowanceQuery({
    variables: { id: id ?? "" },
    skip: !id,
  });
  const { setError } = useGlobalError();
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const [updateProjectMutation] = useUpdateProjectAllowanceMutation();
  const { updateStoreData, allowance, zones, currentProjectId, budgetLink } =
    useProjectStore(
      useShallow((state) => ({
        updateStoreData: state.updateStoreData,
        allowance: state.allowance,
        zones: state.zones,
        currentProjectId: state.currentProjectId,
        budgetLink: state.budgetLink,
      })),
    );

  const { updateStoreData: updateBudgetStoreData, isZoneSpecificBudget } =
    useBudgetStore(
      useShallow((state) => ({
        updateStoreData: state.updateStoreData,
        isZoneSpecificBudget: state.isZoneSpecificBudget,
      })),
    );

  useErrorEffect(error);

  const updateProjectBudget = useCallback(
    async (input: ProjectBudgetInput) => {
      try {
        const { data: updatedProject, errors } = await updateProjectMutation({
          variables: {
            input: {
              id: id ?? "",
              ...input,
            },
          },
        });
        setError(errors);
        if (updatedProject?.updateProject) {
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_ALLOWANCE_UPDATED_SUCCESSFULLY",
            }),
          );

          updateStoreData(updatedProject?.updateProject);
          updateBudgetStoreData(allowance);
          return true;
        }
      } catch (errors) {
        setError(errors);
      }
      return false;
    },
    [
      updateProjectMutation,
      updateStoreData,
      updateBudgetStoreData,
      setError,
      id,
      intl,
      setSuccessAlert,
      allowance,
    ],
  );

  const [linkBudgetMutation, { loading: linkBudgetLoading }] =
    useLinkBudgetMutation();
  const linkBudget = useCallback(
    async (input: LinkBudgetInput) => {
      try {
        const { data, errors } = await linkBudgetMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        if (data?.linkBudget) {
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_BUDGET_LINKED_SUCCESSFULLY",
            }),
          );

          updateStoreData({
            budgetLink: data?.linkBudget,
            allowance: data?.linkBudget?.project?.allowance,
          });
        }
        return !!data;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [linkBudgetMutation, updateStoreData, setError, intl, setSuccessAlert],
  );

  const [syncBudgetMutation, { loading: syncBudgetLoading }] =
    useSyncBudgetMutation();
  const syncBudget = useCallback(
    async (budgetLinkId: string) => {
      try {
        const { data, errors } = await syncBudgetMutation({
          variables: {
            budgetLinkId,
          },
        });
        setError(errors);
        if (data?.syncBudget?.budgetLink) {
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_BUDGET_SYNCED_SUCCESSFULLY",
            }),
          );

          updateStoreData({
            allowance: data?.syncBudget?.allowance,
            budgetLink: data?.syncBudget?.budgetLink,
          });
        }
        return !!data;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [syncBudgetMutation, updateStoreData, setError, intl, setSuccessAlert],
  );

  const [unlinkBudgetMutation, { loading: unlinkBudgetLoading }] =
    useUnlinkBudgetMutation();
  const unlinkBudget = useCallback(
    async (budgetLinkId: string) => {
      try {
        const { data, errors } = await unlinkBudgetMutation({
          variables: {
            budgetLinkId,
          },
        });
        setError(errors);
        if (data?.unlinkBudget) {
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_BUDGET_UNLINKED_SUCCESSFULLY",
            }),
          );

          updateStoreData({
            budgetLink: undefined,
          });
        }
        return !!data;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [unlinkBudgetMutation, updateStoreData, setError, intl, setSuccessAlert],
  );

  const [updateBudgetLinkMutation, { loading: updateBudgetLinkLoading }] =
    useUpdateBudgetLinkMutation();
  const updateBudgetLink = useCallback(
    async (input: UpdateBudgetLinkInput) => {
      try {
        const { data, errors } = await updateBudgetLinkMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        if (data?.updateBudgetLink) {
          setSuccessAlert(
            intl.$t({
              id: "PROJECT_BUDGET_UPDATED_SUCCESSFULLY",
            }),
          );

          updateStoreData({ budgetLink: data?.updateBudgetLink });
          updateBudgetStoreData(allowance);
        }
        return !!data;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [
      updateBudgetLinkMutation,
      updateStoreData,
      updateBudgetStoreData,
      setError,
      intl,
      setSuccessAlert,
      allowance,
    ],
  );

  return {
    budget: data?.project?.allowance ?? null,
    loading,
    updateProjectBudget,
    updateBudgetLink,
    updateBudgetLinkLoading,
    unlinkBudget,
    unlinkBudgetLoading,
    linkBudget,
    linkBudgetLoading,
    syncBudget,
    syncBudgetLoading,
    allowance,
    zones,
    currentProjectId,
    budgetLink,
    isZoneSpecificBudget,
  };
};
