import { useOrderTypes } from "@/contractor/pages/admin/orders/pages/order-types/hooks/useOrderTypes";
import { CustomFilterContainer } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/list/invoice-additional-filters/InvoiceAdditionalFilters";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AdditionalFiltersOverlay } from "../additional-filters-overlay/AdditionalFiltersOverlay";
import { Multiselect } from "../select/components/multiple/Multiselect";

type Props<T> = {
  onChange: (values: T[] | undefined) => void;
  selectedOrderTypes: T[] | undefined | null;
  className?: string;
};

export const OrderTypesFilter = <T,>({
  selectedOrderTypes,
  onChange,
  className,
}: Props<T>) => {
  const { orderTypes } = useOrderTypes();
  const intl = useIntl();

  const orderTypesOptions = useMemo(() => {
    return orderTypes.map((type) => ({
      name: type.name,
      value: type.id,
    }));
  }, [orderTypes]);

  const filterValues = useMemo(() => {
    if (selectedOrderTypes?.length) {
      return selectedOrderTypes as string[];
    }
    return null;
  }, [selectedOrderTypes]);

  return (
    <CustomFilterContainer className={className}>
      <AdditionalFiltersOverlay.Name>
        <FormattedMessage id="SELECT_ORDER_TYPES" />
      </AdditionalFiltersOverlay.Name>

      <Multiselect
        options={orderTypesOptions}
        getLabel={(o) => o.name}
        getValue={(o) => o.value as string}
        values={filterValues}
        placeholder={
          filterValues?.length ? "" : intl.$t({ id: "ALL_ORDER_TYPES" })
        }
        onMultipleChange={(values: string[] | null) => {
          onChange(values?.length ? (values as T[]) : undefined);
        }}
        chipSize="small"
        includeCheckbox
        limitTags={1}
        disableCloseOnSelect
        className="flex-1"
      />
    </CustomFilterContainer>
  );
};
