import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { formattedDate } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import {
  ReleasePartialFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-col gap-1`;
const Item = tw.div`text-sm`;

type Props = {
  className?: string;
  release: ReleasePartialFieldsFragment;
};

export const ReleaseStatusChipWithApprovals: FC<Props> = ({
  className,
  release,
}) => {
  const intl = useIntl();
  const approvers = useMemo(() => {
    if (
      release.approvalProgress.slots.length < 2 ||
      release.status !== ReleaseStatus.AwaitingApproval
    ) {
      return undefined;
    }
    const parts = [];

    const approvals = release.approvalProgress.slots.filter(
      (s) => s.approvals.length > 0,
    );
    if (approvals.length > 0) {
      parts.push(intl.$t({ id: "APPROVED_BY" }) + ":");
      approvals.forEach((a, index) => {
        const role = a.role ? intl.$t({ id: `USER_ROLE_${a.role}` }) : "";
        const approverName = getUserName(a.approvals[0].approver);
        const date = formattedDate({ date: a.approvals[0].createdAt });
        const text = [role, approverName, date].filter((t) => t).join(" - ");
        parts.push(`${index + 1}. ${text}`);
      });
    }

    const pendingApprovals = release.approvalProgress.slots.filter(
      (s) => s.approvals.length === 0,
    );
    if (pendingApprovals.length > 0) {
      parts.push(intl.$t({ id: "REQUIRED_REVIEW" }) + ":");
      pendingApprovals.forEach((a, index) => {
        const role = a.role
          ? intl.$t({ id: `USER_ROLE_${a.role}` })
          : intl.$t({ id: "ANYONE_THAT_CAN_APPROVE_ORDERS" });
        parts.push(`${index + 1}. ${role}`);
      });
    }

    return (
      <Container>
        {parts.map((p, key) => (
          <Item key={key}>{p}</Item>
        ))}
      </Container>
    );
  }, [intl, release.approvalProgress.slots, release.status]);

  return (
    <ReleaseStatusChip
      status={release.status}
      className={className}
      hoverText={approvers}
      releaseType={release.type}
    />
  );
};
