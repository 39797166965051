import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { evictCacheById } from "@/common/utils/cacheUtils";
import {
  readValueFromKeys,
  removeValue,
  setValue,
} from "@/common/utils/localStorage";
import {
  BuyoutsBuyoutFieldsFragment,
  QueryBuyoutsFilter,
  namedOperations,
  useArchiveBuyoutMutation,
  useBuyoutsQuery,
} from "@/generated/graphql";
import { NoFunction, NoFunctionPromise } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useShallow } from "zustand/react/shallow";

export enum BuyoutsFilterSelection {
  Draft = "DRAFT",
  Requested = "REQUESTED",
  Active = "ACTIVE",
  Depleted = "DEPLETED",
  Cancelled = "CANCELLED",
}

type ProviderContextType = {
  buyouts: BuyoutsBuyoutFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  filter?: QueryBuyoutsFilter;
  setFilter: (filter: QueryBuyoutsFilter) => void;
  archiveBuyout: (id: string) => Promise<boolean>;
  isFiltered: boolean;
  refetch: () => Promise<void>;
};

const ProviderContext = createContext<ProviderContextType>({
  buyouts: [],
  loading: false,
  error: false,
  totalCount: 0,
  filter: undefined,
  setFilter: NoFunction,
  archiveBuyout: () => Promise.resolve(false),
  isFiltered: false,
  refetch: NoFunctionPromise,
});

export const DEFAULT_BUYOUTS_FILTER: QueryBuyoutsFilter = {
  closedProjects: false,
  deleted: false,
};

export const BuyoutsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { setError } = useGlobalError();
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { orgId } = useStartupDataStore(
    useShallow((state) => ({
      orgId: state.viewer?.org.id,
    })),
  );

  const [filter, setFilter] = useState<QueryBuyoutsFilter | undefined>(
    undefined,
  );

  const { paginationArgs, setPageInfo, previousPage, page } = usePagination();
  const {
    data: buyoutsData,
    loading,
    error,
    refetch,
  } = useBuyoutsQuery({
    variables: {
      ...paginationArgs,
      filter,
    },
    skip: !filter,
  });
  const [archiveBuyoutMutation] = useArchiveBuyoutMutation({
    update: (cache, { data: archiveBuyout }) =>
      evictCacheById(
        cache,
        namedOperations.Query.Buyout,
        archiveBuyout?.archiveBuyout.id,
      ),
  });

  useErrorEffect(error);

  useEffect(() => {
    if (buyoutsData?.buyouts?.pageInfo) {
      setPageInfo(buyoutsData.buyouts.pageInfo);
    }
  }, [buyoutsData, setPageInfo]);

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter, refetch]);

  useEffect(() => {
    const localStorageSettings = readValueFromKeys<QueryBuyoutsFilter>([
      [orgId || "", LOCAL_STORAGE_KEYS.BUYOUTS_LIST_FILTER],
      LOCAL_STORAGE_KEYS.BUYOUTS_LIST_FILTER,
    ]) as QueryBuyoutsFilter;
    setFilter({
      ...DEFAULT_BUYOUTS_FILTER,
      ...(localStorageSettings || {}),
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam, orgId]);

  const setFilterAndUpdateQueryString = (filter: QueryBuyoutsFilter) => {
    setFilter(filter);
    removeValue(LOCAL_STORAGE_KEYS.BUYOUTS_LIST_FILTER);
    setValue([orgId || "", LOCAL_STORAGE_KEYS.BUYOUTS_LIST_FILTER], filter);
    setFiltersQueryParams(filter);
  };

  const archiveBuyout = async (id: string) => {
    try {
      const { data, errors } = await archiveBuyoutMutation({
        variables: { id },
        onCompleted: () => {
          if (page !== 0 && buyoutsData?.buyouts.edges.length === 1) {
            previousPage();
          }
        },
      });
      setError(errors);
      return !!data?.archiveBuyout;
    } catch (error) {
      setError(error);
    }
    return false;
  };

  const buyouts = useMemo(
    () => buyoutsData?.buyouts?.edges?.map((edge) => edge.node) || [],
    [buyoutsData?.buyouts?.edges],
  );

  const refetchBuyouts = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <ProviderContext.Provider
      value={{
        buyouts,
        isFiltered: !!filter?.projectIDs || !!filter?.statuses?.length,
        loading,
        error: !!error,
        totalCount: buyoutsData?.buyouts?.totalCount || 0,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        archiveBuyout,
        refetch: refetchBuyouts,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useBuyouts = (): ProviderContextType =>
  useContext(ProviderContext);
