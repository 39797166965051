import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useFiltersQueryParams } from "@/common/stores/hooks/useFiltersQueryParams";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import {
  readValueFromKeys,
  removeValue,
  setValue,
} from "@/common/utils/localStorage";
import { useRfqMutations } from "@/contractor/pages/home/rfq/hooks/useRfqMutations";
import {
  QueryRfQsFilter,
  RfqsRfqFieldsFragment,
  useRfqsQuery,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useShallow } from "zustand/react/shallow";

enum RFQsFilterSelection {
  Active = "ACTIVE",
  Awarded = "AWARDED",
  Cancelled = "CANCELLED",
  Draft = "DRAFT",
}

type ProviderContextType = {
  rfqs: RfqsRfqFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  removeRfq: (rfqId: string) => Promise<boolean>;
  filter?: QueryRfQsFilter;
  isFiltered: boolean;
  setFilter: (filter: QueryRfQsFilter) => void;
  refetch: () => Promise<void>;
};

const ProviderContext = createContext<ProviderContextType>({
  rfqs: [],
  totalCount: 0,
  loading: false,
  error: false,
  removeRfq: NoFunctionBooleanPromise,
  filter: undefined,
  setFilter: NoFunction,
  isFiltered: false,
  refetch: NoFunctionPromise,
});

export const FILTER_OPTIONS = [
  {
    id: RFQsFilterSelection.Draft,
  },
  {
    id: RFQsFilterSelection.Active,
  },
  {
    id: RFQsFilterSelection.Awarded,
  },
  {
    id: RFQsFilterSelection.Cancelled,
  },
];

export const DEFAULT_RFQS_FILTER: QueryRfQsFilter = {
  deleted: false,
  closedProjects: false,
};

export const RfqsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { removeRfqMutation } = useRfqMutations();
  const { paginationArgs, page, setPageInfo, previousPage, setPage } =
    usePagination();
  const [filter, setFilter] = useState<QueryRfQsFilter | undefined>(undefined);
  const { orgId } = useStartupDataStore(
    useShallow((state) => ({
      orgId: state.viewer?.org.id,
    })),
  );

  useEffect(() => {
    const localStorageSettings = readValueFromKeys<QueryRfQsFilter>([
      [orgId || "", LOCAL_STORAGE_KEYS.RFQS_LIST_FILTER],
      LOCAL_STORAGE_KEYS.RFQS_LIST_FILTER,
    ]) as QueryRfQsFilter;
    setFilter({
      ...DEFAULT_RFQS_FILTER,
      ...(localStorageSettings || {}),
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam, orgId]);

  const setFilterAndUpdateQueryString = (updatedFilter: QueryRfQsFilter) => {
    setPage({ page: 0 });
    setFilter(updatedFilter);
    removeValue(LOCAL_STORAGE_KEYS.RFQS_LIST_FILTER);
    setValue([orgId || "", LOCAL_STORAGE_KEYS.RFQS_LIST_FILTER], updatedFilter);
    setFiltersQueryParams(updatedFilter);
  };

  const { data, loading, error, refetch } = useRfqsQuery({
    variables: {
      filter,
      ...paginationArgs,
    },
    skip: !filter,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.rfqs) {
      setPageInfo(data.rfqs.pageInfo);
    }
  }, [data, setPageInfo]);

  const removeRfq = async (rfqId: string) => {
    if (page !== 0 && data?.rfqs.edges.length === 1) {
      previousPage();
    }

    return await removeRfqMutation(rfqId);
  };

  const refetchRfqs = useCallback(async () => {
    await refetch();
  }, [refetch]);

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        rfqs: data?.rfqs?.edges?.map((edge) => edge.node) || [],
        loading,
        error: !!error,
        totalCount: data?.rfqs?.totalCount || 0,
        removeRfq,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        isFiltered:
          !!filter?.statuses?.length ||
          !!filter?.projectIds?.length ||
          filter?.deleted !== false,
        refetch: refetchRfqs,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useRfqs = (): ProviderContextType => useContext(ProviderContext);
