import { routes } from "@/config/routes";
import {
  Notification,
  NotificationQuoteChangeContextFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { DetailsContainer, MessageBody } from "../common/Notification.styles";
import { BaseNotification, Sender } from "./BaseNotification";

type RFQChangeNotificationProps = {
  notification: Notification;
};

export const RFQChangeNotification: FC<RFQChangeNotificationProps> = ({
  notification,
}) => {
  const context =
    notification.context as NotificationQuoteChangeContextFieldsFragment;
  const intl = useIntl();
  const navigate = useNavigate();
  const onCallback = () => {
    navigate(
      generatePath(routes.bidsWithId, {
        id: context.quote?.rfq.id,
      }),
    );
  };

  return (
    <BaseNotification notification={notification} onCallback={onCallback}>
      <DetailsContainer>
        <Sender>{context?.quote.rfq.project.name}</Sender>
        <MessageBody>
          {intl.$t(
            { id: "RFQ_STATUS_CHANGED" },
            { number: context?.quote.rfq.clientIdentifier },
          )}
        </MessageBody>
      </DetailsContainer>
    </BaseNotification>
  );
};
