import { AddCircleOutlineRounded, DeleteOutline } from "@mui/icons-material";
import { AdditionalChargeIconType } from "../../types/AdditionalChargeIconType";

type Props = {
  type?: AdditionalChargeIconType | null;
};

export const AdditionalChargeActionIcon = ({ type }: Props) => {
  if (!type) {
    return null;
  }
  if (type === AdditionalChargeIconType.Add) {
    return <AddCircleOutlineRounded />;
  }

  return <DeleteOutline />;
};
