import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { Switch } from "@/common/components/switch/Switch";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { Circle, RefreshOutlined } from "@mui/icons-material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useProjectBudget } from "../../../hooks/useProjectBudget";
import { useBudgetByCostCodes } from "../useBudgetByCostCodes";

const SwitchContainer = tw.div`grid grid-flow-col text-base gap-2 items-center`;
const SyncText = tw.div`grid items-end text-end`;
const SyncTextGroup = tw.div`grid grid-flow-col gap-1 place-items-center text-2xs text-gray-600`;
const RefreshOutlinedStyled = tw(
  RefreshOutlined,
)`text-lg cursor-pointer text-blue-500 hover:text-blue-300`;
const StatusCircle = tw(Circle)<{ $error: boolean }>`
  text-2xs -mt-0.5
  ${({ $error }) => ($error ? "text-red-500" : "text-green-800")}
`;

export const AutoSyncSwitch = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { syncBudget, syncBudgetLoading, budgetLink } = useProjectBudget();
  const [autoSyncBudget, setAutoSyncBudget] = useState(
    budgetLink?.autoSync ?? false,
  );
  const { project } = useProject();
  const { saveAutoSyncBudget } = useBudgetByCostCodes();

  useEffect(() => {
    setAutoSyncBudget(budgetLink?.autoSync ?? false);
  }, [budgetLink?.autoSync]);

  const onAutoSyncChange = useCallback(
    async (value: boolean) => {
      setAutoSyncBudget(value);
      await saveAutoSyncBudget(value);
    },
    [saveAutoSyncBudget],
  );

  const autoSyncDate = useMemo(() => {
    const time = budgetLink?.syncedAt || budgetLink?.createdAt;
    return time ? moment(time).format("L, LT") : "";
  }, [budgetLink?.syncedAt, budgetLink?.createdAt]);

  if (!connectedSourceSystem || project?.externalProjects.length === 0) {
    return null;
  }

  return (
    <SwitchContainer>
      <SyncText>
        <FormattedMessage id="AUTO_SYNC_BUDGET" />
        <If isTrue={budgetLink?.autoSync}>
          <SyncTextGroup>
            <Tooltip
              element={<StatusCircle $error={!!budgetLink?.autoSyncError} />}
            >
              {budgetLink?.autoSyncError}
            </Tooltip>
            <FormattedMessage
              id="AUTO_SYNC_BUDGET_LAST_SYNCED"
              values={{ lastSynced: autoSyncDate }}
            />
            <If isTrue={syncBudgetLoading}>
              <Loader small loading className="p-px" />
            </If>
            <If isTrue={!syncBudgetLoading}>
              <RefreshOutlinedStyled
                onClick={() => syncBudget(budgetLink?.id ?? "")}
              />
            </If>
          </SyncTextGroup>
        </If>
      </SyncText>
      <Switch
        onLabel={intl.$t({ id: "YES" })}
        offLabel={intl.$t({ id: "NO" })}
        value={autoSyncBudget}
        onChange={onAutoSyncChange}
      />
    </SwitchContainer>
  );
};
