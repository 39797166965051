import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { getUTCDate } from "@/common/components/picker/components/utils/getUTCDate";
import { DateView } from "@/common/utils/dates/DateView";
import { InvoiceSummaryFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { InvoiceDueDate } from "../invoice-due-date/InvoiceDueDate";

const Item = tw.div`text-sm`;
const Container = tw.div`flex flex-col`;

type Props = {
  invoice: InvoiceSummaryFieldsFragment;
};

export const InvoiceIssueDate: FC<Props> = ({ invoice }) => {
  return (
    <Container>
      <NotNullableRenderer value={!!invoice.issueDate}>
        <Item>
          <DateView date={getUTCDate(invoice.issueDate)} />
        </Item>
      </NotNullableRenderer>
      <InvoiceDueDate invoice={invoice} />
    </Container>
  );
};
