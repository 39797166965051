import { useCallback, useEffect } from "react";
import { useInvoiceSequence } from "../../../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../../../providers/InvoicesProvider";

export const useExportInvoicesButton = () => {
  const { startSequence, sequenceActive, sequenceIds } = useInvoiceSequence();
  const { exportEnabled, setExportEnabled } = useInvoices();
  useEffect(() => {
    if (sequenceActive) {
      setExportEnabled(sequenceActive);
    }
  }, [exportEnabled, sequenceActive, sequenceIds, setExportEnabled]);

  const onClosePanel = useCallback(
    (togglePanel?: (opened: boolean) => void) => {
      togglePanel?.(false);
    },
    [],
  );

  const startExportFlow = useCallback(() => {
    setExportEnabled(true);
  }, [setExportEnabled]);

  const onStartSequenceClicked = useCallback(() => {
    startSequence();
  }, [startSequence]);

  return {
    onClosePanel,
    startExportFlow,
    onStartSequenceClicked,
  };
};
