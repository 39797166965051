import { useCallback, useLayoutEffect, useRef } from "react";
import { useExpandableFooterStore } from "../stores/useExpandableFooterStore";

export const useExpandableFooterRef = () => {
  const { setHeight } = useExpandableFooterStore();
  const ref = useRef<HTMLDivElement>(null);

  const updateHeight = useCallback(
    (height: number) => {
      setHeight?.(height);
    },
    [setHeight],
  );

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      const height = entries[0]?.contentRect.height ?? 0;
      updateHeight(height);
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
      updateHeight(0);
    };
  }, [updateHeight]);

  return ref;
};
