import { If } from "@/common/components/if/If";
import { CloseOutlined } from "@mui/icons-material";
import {
  PickerShortcutChangeImportance,
  PickersShortcuts,
  PickersShortcutsItemContext,
  PickersShortcutsProps,
} from "@mui/x-date-pickers";
import {
  add,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { DateRangePickerValue } from "./types";

type CustomPickersShortcutsProps = {
  titleId?: string;
  isLandscape?: boolean;
  onClose?: () => void;
  onChange?: (
    value: Date | null,
    changeImportance: PickerShortcutChangeImportance,
    shortcut: PickersShortcutsItemContext,
  ) => void;
  isValid?: (value: Date | null) => boolean;
};

const Wrapper = tw.div`flex flex-col gap-2 p-6 pb-0 col-span-2`;
const InnerWrapper = tw.div`flex justify-between text-blue-800 mb-6`;
const Title = tw.span`font-bold text-base`;
const StyledCloseOutlined = tw(CloseOutlined)`ml-2 text-base cursor-pointer`;
const StyledPickersShortcuts = tw(PickersShortcuts)<
  PickersShortcutsProps<DateRangePickerValue>
>`col-span-2 row-span-1 flex flex-wrap max-w-[420px] p-0 gap-[0.5rem]`;

const CustomPickersShortcuts: FC<CustomPickersShortcutsProps> = (props) => {
  const now = startOfDay(new Date());
  const lastWeek = useMemo(() => add(now, { weeks: -1 }), [now]);
  const weekFromNow = useMemo(() => add(now, { weeks: 1 }), [now]);
  const monthFromNow = useMemo(() => add(now, { months: 1 }), [now]);
  const monthAgo = useMemo(() => add(now, { months: -1 }), [now]);
  const intl = useIntl();

  return (
    <Wrapper>
      <If isTrue={props.titleId}>
        <InnerWrapper>
          <Title>
            <FormattedMessage id={props.titleId} />
          </Title>
          <StyledCloseOutlined
            onClick={(e) => {
              e.stopPropagation();
              props?.onClose?.();
            }}
          />
        </InnerWrapper>
      </If>
      {}
      {/* @ts-expect-error incorrect date picker styles */}
      <StyledPickersShortcuts
        {...props}
        sx={{
          ".MuiListItem-root": {
            width: "fit-content",
            padding: "0",
            height: "25px",
          },
          ".MuiChip-root": {
            height: "25px",
            color: "#777",
            background: "#EFEFEF",
            fontWeight: "400",
            "&:hover": {
              background: "#7A8BA4",
              color: "#FFF",
            },
          },
        }}
        items={[
          {
            label: intl.$t({ id: "TODAY" }),
            getValue: () => ({
              startDate: startOfDay(now),
              endDate: endOfDay(now),
            }),
          },
          {
            label: intl.$t({ id: "LAST_WEEK" }),
            getValue: () => ({
              startDate: startOfWeek(lastWeek),
              endDate: endOfWeek(lastWeek),
            }),
          },
          {
            label: intl.$t({ id: "THIS_WEEK" }),
            getValue: () => ({
              startDate: startOfWeek(now),
              endDate: endOfWeek(now),
            }),
          },
          {
            label: intl.$t({ id: "NEXT_WEEK" }),
            getValue: () => ({
              startDate: startOfWeek(weekFromNow),
              endDate: endOfWeek(weekFromNow),
            }),
          },
          {
            label: intl.$t({ id: "THIS_MONTH" }),
            getValue: () => ({
              startDate: startOfMonth(now),
              endDate: endOfMonth(now),
            }),
          },
          {
            label: intl.$t({ id: "LAST_MONTH" }),
            getValue: () => ({
              startDate: startOfMonth(monthAgo),
              endDate: endOfMonth(monthAgo),
            }),
          },
          {
            label: intl.$t({ id: "NEXT_MONTH" }),
            getValue: () => ({
              startDate: startOfMonth(monthFromNow),
              endDate: endOfMonth(monthFromNow),
            }),
          },
        ]}
      />
    </Wrapper>
  );
};

export default CustomPickersShortcuts;
