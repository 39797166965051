import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { CategoriesProvider } from "@/common/components/category-filter/CategoriesProvider";
import { CategoryListFilter } from "@/common/components/category-filter/CategoryListFilter";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { useFacets } from "@/common/components/product-facets-filter/FacetsProvider";
import { ProductFacetsFilter } from "@/common/components/product-facets-filter/ProductFacetsFilter";
import {
  SkusAndProductsProvider,
  useSkusAndProducts,
} from "@/common/components/product-facets-filter/SkusAndProductsProvider";
import { ProductsList } from "@/common/components/product-list/ProductsList";
import {
  ShoppingCartIcon,
  ShoppingIconType,
} from "@/common/components/shopping-cart-icon/ShoppingCartIcon";
import { MasterSkusProductsToggleProvider } from "@/common/components/skus-products-switch/MasterSkusProductsToggleProvider";
import { routes } from "@/config/routes";
import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";
import tw from "tailwind-styled-components";
import {
  ProjectShoppingCartProvider,
  useProjectShoppingCart,
} from "../../providers/ProjectShoppingCartProvider";

const ProductsContainer = tw.div`
  flex mt-5 h-full
`;

const SelectProjectProductsWithProviders = () => {
  const { id } = useParams();

  const intl = useIntl();
  const {
    productFacets,
    productFacetsValues,
    selectedFacets,
    toggleFacetValue,
  } = useFacets();
  const { addToCart, project, loading, findItem, removeFromProject } =
    useProjectShoppingCart();
  const { searchPerformed } = useSkusAndProducts();

  if (loading || !project) {
    return <Loader loading />;
  }

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "NEW_RFQ" })}</title>
      </Helmet>
      <Breadcrumbs
        classes={{
          text: "flex items-center",
        }}
        items={[
          {
            text: intl.$t({ id: "PROJECTS" }),
            link: routes.projects,
            id: "projects",
          },
          {
            id: "projectName",
            link: generatePath(routes.projectEstimatedItems, {
              id: project.id,
            }),
            text: project.name,
          },
          {
            id: "estimatedItems",
            text: intl.$t({ id: "PROJECT_ESTIMATED_ITEMS" }),
          },
          {
            id: "catalog",
            text: intl.$t({ id: "PROJECT_ADD_PRODUCTS" }),
          },
        ]}
        actions={[
          <ShoppingCartIcon
            key="shopping-cart-icon"
            count={project.items.length}
            path={generatePath(routes.projectEstimatedItems, { id })}
            type={ShoppingIconType.List}
          />,
        ]}
      />
      <CategoryListFilter />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <ProductsContainer>
            <If isTrue={searchPerformed}>
              <Grid item xs={2}>
                <ProductFacetsFilter
                  productFacets={productFacets}
                  productFacetsValues={productFacetsValues}
                  selectedFacets={selectedFacets}
                  toggleFacetValue={toggleFacetValue}
                />
              </Grid>
            </If>
            <ProductsList
              addToCart={addToCart}
              findItem={findItem}
              removeFromCart={removeFromProject}
              customLabels={{
                addLabel: "ADD_TO_PROJECT",
                removeLabel: "ADDED_TO_PROJECT",
                removeIcon: <Check />,
                removeDisabled: true,
              }}
            />
          </ProductsContainer>
        </Grid>
      </Grid>
    </>
  );
};

export const SelectProjectProducts = () => (
  <CategoriesProvider initialCategory={null}>
    <PaginationProvider>
      <MasterSkusProductsToggleProvider>
        <SkusAndProductsProvider>
          <ProjectShoppingCartProvider>
            <SelectProjectProductsWithProviders />
          </ProjectShoppingCartProvider>
        </SkusAndProductsProvider>
      </MasterSkusProductsToggleProvider>
    </PaginationProvider>
  </CategoriesProvider>
);
