import { MissingBudgetCombinationsModal } from "@/contractor/pages/home/release/components/connections/components/connection-types/hosted/steps/missing-budget-combinations/MissingBudgetCombinationsModal";
import { InvoiceToExportFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useInvoiceReleaseValidation } from "../../../../invoice-verification/hooks/useInvoiceValidation";
import {
  InvoiceValidationProvider,
  useInvoiceValidation,
} from "../../../providers/InvoiceValidationProvider";
import { InvoiceReleaseDetails } from "../invoice-release-details/InvoiceReleaseDetails";
import { useHostedIntegrationInvoiceInformation } from "./hooks/useHostedIntegrationInvoiceInformation";

type Props = {
  invoice: InvoiceToExportFieldsFragment;
  linking?: boolean;
  error?: string;
};

const HostedIntegrationInvoiceInformationWithProvider: FC<Props> = ({
  invoice,
  linking,
  error,
}) => {
  const { loading, poNumber, setPoNumber } = useInvoiceValidation();
  const { exportValidations } = useInvoiceReleaseValidation(
    invoice,
    poNumber || invoice.poNumber || invoice.release?.poNumber?.toString() || "",
  );
  useHostedIntegrationInvoiceInformation();

  return (
    <>
      <InvoiceReleaseDetails
        invoice={invoice}
        includedValidations={exportValidations}
        linking={linking}
        loading={loading}
        poNumber={poNumber}
        setPoNumber={setPoNumber}
        error={error}
      />
      <MissingBudgetCombinationsModal />
    </>
  );
};

export const HostedIntegrationInvoiceInformation: FC<Props> = (props) => (
  <InvoiceValidationProvider invoice={props.invoice}>
    <HostedIntegrationInvoiceInformationWithProvider {...props} />
  </InvoiceValidationProvider>
);
