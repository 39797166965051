import { isMasterSku, isProductSku } from "@/common/components/material/utils";
import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import { PDF_FONT, TOTAL_PRICE_DECIMAL_POINTS } from "@/common/const";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import {
  DistributorQuoteFieldsFragment,
  ProjectMaterialSkuFieldsFragment,
  QuoteStatus,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { HAlignType, RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";
import { getLeadTimeInterval } from "../components/common/getLeadTimeInterval";

const EMPTY_STRING = "-";

const quoteItemsForPdf = (
  quote: DistributorQuoteFieldsFragment,
  intl: IntlShape,
  hasManufacturersSetting: boolean,
  formatCurrency: FormatCurrencyType,
): RowInput[] => {
  const quoteItems = quote.itemGroups
    .sort((a, b) =>
      a.rfqItem.projectItem.material.material.name.localeCompare(
        b.rfqItem.projectItem.material.material.name,
      ),
    )
    .flatMap((item) => item.quoteItems)
    .filter((i) => i.status !== QuoteStatus.Withdrawn);
  const quoteRows: RowInput[] = quoteItems.map((quoteItem) => {
    const group = quote.itemGroups.find((q) =>
      q.quoteItems.some((q2) => q2.id === quoteItem.id),
    );
    const index = quote.itemGroups.findIndex((q) =>
      q.quoteItems.includes(quoteItem),
    );
    const altKey =
      (group?.quoteItems?.length || 0) > 1
        ? String.fromCharCode(65 + (group?.quoteItems?.indexOf(quoteItem) || 0))
        : "";
    const rfqItem = group?.rfqItem;
    const totalPrice = Number(
      new DecimalSafe(quoteItem.unitPrice || 0).mul(
        quoteItem.quantityDecimal || 0,
      ),
    );
    const alternativeDescription =
      rfqItem?.description !== quoteItem.description
        ? `${intl.$t(
            {
              id:
                (group?.quoteItems?.length || 0) > 1
                  ? "ALTERNATIVE_TAG"
                  : "SPECIFIC_OPTION",
            },
            {
              tag: altKey,
            },
          )} - ${quoteItem.description || ""}`
        : "";

    const requestedItemName = `${
      isMasterSku(rfqItem?.projectItem.material.material) ||
      isProductSku(rfqItem?.projectItem.material.material)
        ? `${
            (
              rfqItem?.projectItem.material
                .material as ProjectMaterialSkuFieldsFragment
            ).manufacturer?.name || intl.$t({ id: "ANY_MANUFACTURER" })
          }\n`
        : ""
    }${rfqItem?.description}${
      alternativeDescription ? `\n${alternativeDescription}` : ""
    }${
      rfqItem?.instructions?.text
        ? `\n\n${intl.$t({
            id: "DISTRIBUTOR_QUOTE_CONTRACTOR_NOTES",
          })}:\n${rfqItem?.instructions?.text}`
        : ""
    }`;

    const requestedQuantity = `${intl.formatNumber(
      Number(rfqItem?.quantityDecimal) || 0,
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
      },
    )}\n${
      rfqItem?.projectItem.estimateUom.mnemonic ||
      rfqItem?.projectItem.estimateUom.pluralDescription
    }`;

    const manufacturer = quoteItem.manufacturer?.name || EMPTY_STRING;
    const leadTimeInterval = getLeadTimeInterval(quoteItem.leadTimeDays);

    const notes = quoteItem.notes ?? EMPTY_STRING;

    const leadTime = quoteItem.leadTimeDays
      ? `${quoteItem.leadTimeDays / leadTimeInterval} ${intl.$t({
          id:
            leadTimeInterval === 30
              ? "MONTHS"
              : leadTimeInterval === 7
                ? "WEEKS"
                : "DAYS",
        })}`
      : EMPTY_STRING;

    const providedQuantity =
      quoteItem.status !== QuoteStatus.Withdrawn
        ? `${intl.formatNumber(Number(quoteItem.quantityDecimal || 0), {
            minimumFractionDigits: 0,
            maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
          })}\n${
            rfqItem?.projectItem.estimateUom.mnemonic ||
            rfqItem?.projectItem.estimateUom.pluralDescription
          }`
        : "";

    const unitPrice = formatCurrency(quoteItem.unitPrice) || EMPTY_STRING;

    const totalPriceForItem =
      quoteItem.status !== QuoteStatus.Withdrawn
        ? formatCurrency(totalPrice)
        : "";
    return [
      `${index + 1}${altKey}`,
      requestedItemName,
      requestedQuantity,
      ...(hasManufacturersSetting ? [manufacturer] : []),
      notes,
      leadTime,
      providedQuantity,
      unitPrice,
      { content: totalPriceForItem, styles: { fontStyle: "bold" } },
    ];
  });

  const auxRows: RowInput[] = quote.auxiliaryItems.map((auxItem, index) => {
    const totalPrice = Number(
      new DecimalSafe(auxItem.unitPrice || 0).mul(auxItem.quantityDecimal || 0),
    );
    const manufacturer = auxItem.manufacturer?.name || EMPTY_STRING;
    const leadTimeInterval = getLeadTimeInterval(auxItem.leadTimeDays);

    const notes = auxItem.notes ?? EMPTY_STRING;

    const leadTime = auxItem.leadTimeDays
      ? `${auxItem.leadTimeDays / leadTimeInterval} ${intl.$t({
          id:
            leadTimeInterval === 30
              ? "MONTHS"
              : leadTimeInterval === 7
                ? "WEEKS"
                : "DAYS",
        })}`
      : EMPTY_STRING;
    const providedQuantity = `${intl.formatNumber(
      Number(auxItem.quantityDecimal || 0),
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
      },
    )}\n${auxItem.uom?.mnemonic || auxItem.uom?.pluralDescription}`;

    const unitPrice = formatCurrency(auxItem.unitPrice) || EMPTY_STRING;
    const totalPriceForAuxItem = formatCurrency(totalPrice);
    return [
      quote.itemGroups.flatMap((item) => item.quoteItems).length + index + 1,
      auxItem.description || "",
      EMPTY_STRING,
      ...(hasManufacturersSetting ? [manufacturer] : []),
      notes,
      leadTime,
      providedQuantity,
      unitPrice,
      { content: totalPriceForAuxItem, styles: { fontStyle: "bold" } },
    ];
  });
  return quoteRows.concat(auxRows);
};

const HEADER = [
  { text: "EMPTY_DESCRIPTION" },
  {
    text: "DISTRIBUTOR_QUOTE_ITEM_DESCRIPTION",
    styles: { halign: "left" as HAlignType },
  },
  { text: "QUANTITY" },
  { text: "PROVIDED_MANUFACTURER" },
  { text: "VENDOR_NOTES" },
  { text: "LEAD_TIME" },
  { text: "DISTRIBUTOR_QUOTE_UNITS_IN_YOUR_QUOTE" },
  { text: "UNIT_PRICE" },
  { text: "DISTRIBUTOR_QUOTE_YOUR_QUOTE" },
];

export const materials = (
  doc: jsPDF,
  quote: DistributorQuoteFieldsFragment,
  intl: IntlShape,
  hasManufacturersSetting: boolean,
  formatCurrency: FormatCurrencyType,
) => {
  autoTable(doc, {
    theme: "grid",
    styles: {
      font: PDF_FONT,
      fontSize: 9,
    },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
      halign: "center",
      valign: "middle",
    },
    columnStyles: {
      0: { halign: "center", valign: "middle", cellWidth: 10 },
      1: { valign: "middle" },
      2: { halign: "center", valign: "middle", cellWidth: 18 },
      3: { halign: "center", valign: "middle", cellWidth: 30 },
      4: { halign: "center", valign: "middle", cellWidth: 50 },
      5: { halign: "center", valign: "middle", cellWidth: 15 },
      6: { halign: "center", valign: "middle", cellWidth: 18 },
      7: { halign: "center", valign: "middle", cellWidth: 20 },
      8: { halign: "right", valign: "middle", cellWidth: 20 },
    },
    head: [
      HEADER.filter(
        (h) => hasManufacturersSetting || h.text !== "PROVIDED_MANUFACTURER",
      ).map((conf) => ({
        content: intl.$t({ id: conf.text }),
        styles: { ...(conf.styles || {}) },
      })),
    ],
    body: [
      ...quoteItemsForPdf(quote, intl, hasManufacturersSetting, formatCurrency),
    ],
  });
};
