import { getTotalTaxAmount } from "@/common/components/sales-tax-input/utils/salesTaxUtils";
import {
  BuyoutFieldsFragment,
  UpdateContractorBuyoutInput,
} from "@/generated/graphql";
import { extPriceCalculation } from "../../release/hooks/usePriceCalculation";
import { UpdateBuyoutNomenclature } from "./useBuyoutStore";
import { DecimalSafe } from "@/common/utils/decimalSafe";

export const getBuyoutNetAmount = (items: BuyoutFieldsFragment["items"]) =>
  items
    .filter((item) => item.isIncluded)
    .reduce(
      (acc, item) =>
        acc.add(extPriceCalculation(item.quantityDecimal, item.unitPrice)),
      new DecimalSafe(0),
    );

const getBuyoutSubtotalWithCharges = (buyout: BuyoutFieldsFragment) =>
  getBuyoutNetAmount(buyout.items).plus(buyout.additionalChargesAllowance || 0);

export const getBuyoutTotal = (buyout: BuyoutFieldsFragment) => {
  const subtotalWithCharges = getBuyoutSubtotalWithCharges(buyout);
  const taxAmount = getTotalTaxAmount({
    ...buyout,
    netAmount: subtotalWithCharges.toString(),
  });

  return subtotalWithCharges.plus(taxAmount).toString();
};

export const applyInputChanges = (
  buyout: BuyoutFieldsFragment,
  input: UpdateContractorBuyoutInput,
  nomenclature: UpdateBuyoutNomenclature = {
    taxCodes: [],
  },
): BuyoutFieldsFragment => {
  const { taxCodes } = nomenclature;
  const taxCode = input.clearTaxCode
    ? null
    : taxCodes.find((code) => code.id === input.taxCodeId) || buyout.taxCode;

  return {
    ...buyout,
    taxType: input.taxType || buyout.taxType,
    taxCode: taxCode,
    taxRate: input.customTaxAmount
      ? Number(getBuyoutSubtotalWithCharges(buyout))
        ? new DecimalSafe(input.customTaxAmount)
            .div(getBuyoutSubtotalWithCharges(buyout))
            .toString()
        : "0"
      : input.taxRate || buyout.taxRate,
    customTaxAmount: Object.hasOwn(input, "customTaxAmount")
      ? input.customTaxAmount
      : input.clearCustomTaxAmount
        ? null
        : buyout.customTaxAmount,
    taxAmount:
      input.customTaxAmount || input.taxRate || taxCode
        ? new DecimalSafe(taxCode ? taxCode.rate : input.taxRate || 0)
            .mul(getBuyoutSubtotalWithCharges(buyout))
            .toString()
        : buyout.taxAmount,
    items: buyout.items
      .filter((item) => !input.removedItems?.includes(item.id))
      .map((item) => {
        const updatedItem = input.updates?.find(
          (update) => update.buyoutItemId === item.id,
        );
        if (!updatedItem) {
          return item;
        }
        return {
          ...item,
          quantityDecimal: updatedItem.quantityDecimal || item.quantityDecimal,
        };
      }),
    additionalChargesAllowance:
      input.additionalChargesAllowance || buyout.additionalChargesAllowance,
  };
};
