import { routes } from "@/config/routes";
import { releaseRoutes } from "@/contractor/pages/home/release/Routes";
import { useMemo } from "react";
import { matchPath, useLocation } from "react-router";

const IGNORED_ROUTES = [routes.deliverySlips];

export const useGetReleaseFromPathParams = () => {
  const location = useLocation();

  const releaseId: string | undefined = useMemo(() => {
    const routes = releaseRoutes.children
      .flatMap((c) => c.children)
      .filter(
        (route) =>
          !!matchPath(
            {
              path: route.path,
            },
            location.pathname,
          ),
      );
    if (
      routes.length > 0 &&
      !IGNORED_ROUTES.some((route) => matchPath(route, location.pathname))
    ) {
      const match = matchPath(
        {
          path: routes[0].path,
        },
        location.pathname,
      );
      return match?.params.deliveryId ?? undefined;
    }
    return undefined;
  }, [location.pathname]);

  return { releaseId };
};
