import { MessageOrgType } from "@/common/components/messages/Messages";
import { MessagesPanel } from "@/common/components/messages/components/MessagesPanel";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { MESSAGES_SEGMENT } from "@/config/routes";
import { BuyoutFieldsFragment, MessageContextKind } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import tw from "tailwind-styled-components";

type Props = {
  buyout: Pick<BuyoutFieldsFragment, "id" | "clientIdentifier" | "project">;
  text: React.ReactNode;
  org: MessageOrgType;
};

const Container = tw.div`flex items-center`;

export const BuyoutMessagesButton: FC<Props> = ({
  text,
  buyout,
  org,
}: Props) => {
  const location = useLocation();
  const defaultOpened = useMemo(
    () => location.pathname.includes(MESSAGES_SEGMENT),
    [location.pathname],
  );

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <Container onClick={() => togglePanel(true)}>{text}</Container>
      )}
      content={(togglePanel) => (
        <MessagesPanel
          title="MESSENGER"
          id={buyout.id}
          kind={MessageContextKind.Buyout}
          context={{ buyout }}
          org={org}
          isContractor
          messageTabName={<FormattedMessage id="MESSAGE_VENDOR" />}
          noMessage="BUYOUT_NO_MESSAGES"
          onClose={() => togglePanel(false)}
          projectId={buyout.project.id}
        />
      )}
      defaultOpened={defaultOpened}
    />
  );
};
