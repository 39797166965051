import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { VendorListItem } from "@/common/components/vendor-list/VendorListItem";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "@/contractor/pages/home/release/providers/ReleaseActionsProvider";
import { BuyoutStatus, UpdateContractorBuyoutInput } from "@/generated/graphql";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

interface BuyoutVendorContactSelectorProps {
  isNonQuoted: boolean;
}

export const BuyoutVendorContactSelector = ({
  isNonQuoted,
}: BuyoutVendorContactSelectorProps) => {
  const intl = useIntl();
  const { buyout, updateBuyout, updating } = useContractorBuyout();
  const { inputErrors } = useReleaseActions();
  const { setWarningAlert } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [contacts, setContacts] = useState(
    buyout?.vendorContacts.map((vc) => vc.id) || [],
  );

  const update = useCallback(
    async (vendorContacts: string[]) => {
      if (buyout) {
        setSaving(true);
        const input: UpdateContractorBuyoutInput = {
          buyoutId: buyout?.id,
          version: buyout?.version,
          vendorContactIds: vendorContacts,
        };
        await updateBuyout(
          input,
          !isNonQuoted && buyout.status !== BuyoutStatus.Draft,
        );
        setSaving(false);
      }
    },
    [buyout, isNonQuoted, updateBuyout],
  );

  const handleChange = useCallback(
    async (values: string[] | null) => {
      if (!values?.length) {
        setWarningAlert(intl.$t({ id: "INVALID_VENDOR_CONTACTS" }));
        return;
      }
      if (isNonQuoted) {
        setContacts(values || []);
      }
      await update(values || []);
    },
    [intl, isNonQuoted, setWarningAlert, update],
  );

  return (
    <Multiselect
      testId="vendor-contacts-selector"
      options={buyout?.preferredVendor?.contacts || []}
      loading={saving && updating}
      values={
        isNonQuoted ? contacts : buyout?.vendorContacts.map((vc) => vc.id) || []
      }
      onMultipleChange={handleChange}
      onBlur={() => update(contacts)}
      required={(buyout?.preferredVendor?.contacts ?? []).length > 0}
      error={inputErrors.includes(ReleaseErrorType.VENDOR_CONTACT)}
      getLabel={(o) => o.name}
      getValue={(option) => option.id}
      noOptionsText={intl.$t({ id: "NO_VENDORS_CONTACTS_FOUND" })}
      customRender={(contact) => <VendorListItem contact={contact} />}
      limitTags={1}
      chipSize="small"
      includeCheckbox
      noWrap
    />
  );
};
