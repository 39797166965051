import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import { useKickbackCommentActions } from "@/common/hooks/useKickbackCommentActions";
import { useCallback } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { KickbackCommentFormProps } from "./KickbackCommentForm";

interface FormValues extends FieldValues {
  text: string;
}

export const useKickbackCommentForm = ({
  kickbackId,
  item,
  onCancelAdding,
}: KickbackCommentFormProps) => {
  const { assets, removeAsset } = useUploadAssets();
  const methods = useForm<FormValues>({
    defaultValues: {
      text: item?.text || "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { createInvoiceKickbackComment, updateInvoiceKickbackComment } =
    useKickbackCommentActions();

  const commentText = methods.watch("text");

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (item) {
        const result = await updateInvoiceKickbackComment({
          id: item.id,
          text: values.text,
          assetUrls: assets.map((asset) => asset.url),
        });
        if (result) {
          onCancelAdding?.();
        }
        return;
      }
      const result = await createInvoiceKickbackComment({
        kickbackId,
        text: values.text,
        assetUrls: assets.map((asset) => asset.url),
      });
      if (result) {
        onCancelAdding?.();
      }
    },
    [
      assets,
      createInvoiceKickbackComment,
      item,
      kickbackId,
      onCancelAdding,
      updateInvoiceKickbackComment,
    ],
  );

  return { commentText, onSubmit, removeAsset, assets, methods };
};
