import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { RfqsRfqFieldsFragment } from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { useRfqCheckbox } from "./useRfqCheckbox";

const Container = tw.div`
   flex
`;

export type RfqCheckboxProps = {
  quote: RfqsRfqFieldsFragment;
};

export const RfqCheckbox = ({ quote }: RfqCheckboxProps) => {
  const { sequenceIds, handleChange } = useRfqCheckbox({ quote });

  return (
    <Container>
      <SelectionCheckbox
        itemId={quote.id}
        items={sequenceIds}
        setSelection={handleChange}
      />
    </Container>
  );
};
