import Handsontable from "handsontable";
import { ADD_ITEMS_SOURCE } from "../constants/tableConstants";
import { COLUMN_TYPE } from "../enums/columnType";
import { getPhysicalColumnIndex } from "./getPhysicalColumnIndex";
import { rowIsEmpty } from "./rowIsEmpty";
import { setTableCells } from "./setTableCells";
import { toggleTableReadOnly } from "./toggleTableReadOnly";

export const appendTableRows = (
  dataToAppend: Record<string, string>[],
  hotInstance: Handsontable | undefined | null,
): void => {
  let lastNonEmptyRow =
    hotInstance?.getData().findLastIndex((row) => !rowIsEmpty(row)) ?? -1;
  toggleTableReadOnly(hotInstance, true);
  const cellsToAdd: [number, number, string | number][] = [];
  hotInstance?.alter(
    "insert_row_below",
    lastNonEmptyRow === -1 ? 0 : lastNonEmptyRow,
    dataToAppend.length,
  );
  dataToAppend.forEach((row, index) => {
    const physicalRow = hotInstance?.toPhysicalRow(index + lastNonEmptyRow + 1);
    Object.entries(row).forEach(([key, value]) => {
      const columnIndex = getPhysicalColumnIndex(
        hotInstance,
        key as COLUMN_TYPE,
      );
      if (columnIndex !== -1) {
        cellsToAdd.push([physicalRow || 0, columnIndex, value]);
      }
    });
  });
  setTableCells(cellsToAdd, hotInstance, ADD_ITEMS_SOURCE);
  hotInstance?.selectRows(
    lastNonEmptyRow + 1,
    lastNonEmptyRow + dataToAppend.length,
  );
  toggleTableReadOnly(hotInstance, false);
};
