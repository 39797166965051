import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { routes } from "@/config/routes";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { MessageContextType, MessageOrgType } from "../Messages";
import { useMessages } from "../providers/MessagesProvider";

const Container = tw.div`
  grid grid-flow-col justify-between
  bg-gray-100 py-4 shadow z-10 px-5 gap-5
  ${({ $marginTop }: { $marginTop?: boolean }) => $marginTop && "mt-14"}
`;

const Sender = tw.div`
  flex text-sm items-center gap-3 w-full
`;

const Context = tw.div`
  grid content-center gap-1 justify-start
`;

const Label = tw.div`
  text-sm
`;

type MessageHeaderProps = {
  org?: MessageOrgType;
  quote?: string;
  isContractor?: boolean;
  context: MessageContextType;
};

export const MessageHeader: FC<MessageHeaderProps> = ({
  org,
  isContractor,
  context: { quote, release, buyout },
}) => {
  const { isPrivate } = useMessages();
  if (isPrivate) {
    return null;
  }
  return (
    <Container $marginTop={isContractor}>
      <Sender>
        {org ? (
          <>
            <OrgLogo logoImageUrl={org.photoUrl} name={org.name} width={40} />
            {org.name}
          </>
        ) : null}
      </Sender>
      <Context>
        <If isTrue={quote}>
          <Label>
            <FormattedMessage
              id="QUOTE_NUMBER"
              values={{ number: quote?.clientIdentifier }}
            />
          </Label>
        </If>
        <If isTrue={release}>
          <Label>
            <FormattedMessage
              id="RELEASE_NAME"
              values={{ number: release?.sequenceNumber }}
            />
          </Label>
        </If>
        {buyout ? (
          <>
            {release ? (
              <LinkLike
                keepSearchParams
                to={generatePath(
                  isContractor ? routes.buyout : routes.distributorBuyout,
                  { id: buyout?.id },
                )}
              >
                <FormattedMessage
                  id="BUYOUT"
                  values={{ number: buyout?.clientIdentifier }}
                />
              </LinkLike>
            ) : (
              <Label>
                <FormattedMessage
                  id="BUYOUT"
                  values={{ number: buyout?.clientIdentifier }}
                />
              </Label>
            )}
          </>
        ) : null}
      </Context>
    </Container>
  );
};
