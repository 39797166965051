import { If } from "@/common/components/if/If";
import {
  DuplicatedInvoiceInfo,
  isDuplicatedInvoice,
} from "@/common/components/invoices/common/DuplicatedInvoiceInfo";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { InvoiceSummaryFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`flex items-center gap-1`;
const InnerContainer = tw.div``;
const Item = tw.div`flex-1 text-left text-sm max-w-20 truncate
${(props: { $error?: boolean }) => props.$error && "text-red-500"}`;
const Description = tw.div`truncate text-[10px] font-normal max-w-20`;

type Props = {
  invoice: InvoiceSummaryFieldsFragment;
};

export const InvoiceNumber: FC<Props> = ({ invoice }) => {
  return (
    <Container>
      <InnerContainer>
        <Tooltip
          id="invoice-number"
          element={
            <Item $error={isDuplicatedInvoice(invoice)}>{invoice.number}</Item>
          }
        >
          {invoice.number}
        </Tooltip>
        <If isTrue={invoice?.description}>
          <Description>{invoice?.description}</Description>
        </If>
      </InnerContainer>
      <DuplicatedInvoiceInfo invoice={invoice} />
    </Container>
  );
};
