import { ReleaseItem } from "@/generated/graphql";

type FilterParams = {
  timeTbdFilter: boolean | undefined;
  filterDates?: {
    minDate?: number;
    maxDate?: number;
  } | null;
  isReleaseTbd?: boolean;
  releaseTime?: number | null;
};

export const filterReleaseItemsByDates = (
  item: Pick<ReleaseItem, "deliveryDate" | "deliveryDateTBD">,
  { timeTbdFilter, filterDates, isReleaseTbd, releaseTime }: FilterParams,
) => {
  const date =
    item.deliveryDate || (!item.deliveryDateTBD ? releaseTime : undefined);
  return timeTbdFilter
    ? (isReleaseTbd &&
        (item.deliveryDate === null || item.deliveryDate === undefined)) ||
        !!item.deliveryDateTBD
    : filterDates && filterDates.minDate && filterDates.maxDate
      ? !!date && date >= filterDates.minDate && date <= filterDates.maxDate
      : true;
};
