import { Loader } from "@/common/components/loader/Loader";
import { BuyoutDocument } from "./components/BuyoutDocument";
import {
  DistributorBuyoutProvider,
  useDistributorBuyout,
} from "./providers/DistributorBuyoutProvider";

const DistributorBuyoutWithProvider = () => {
  const { buyout } = useDistributorBuyout();

  if (!buyout) {
    return <Loader loading />;
  }

  return <BuyoutDocument buyout={buyout} />;
};

export const DistributorBuyout = () => {
  return (
    <DistributorBuyoutProvider>
      <DistributorBuyoutWithProvider />
    </DistributorBuyoutProvider>
  );
};
