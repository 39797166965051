import { GroupToggle } from "@/common/components/group-toggle/GroupToggle";
import {
  Container,
  Content,
  Title,
} from "@/common/components/integration-batch-options/IntegrationBatchOptions.styles";
import { Row } from "@/common/components/integration-options/IntegrationOptions.styles";
import { DatePicker } from "@/common/components/picker/components/DatePicker";
import { SourceSystemWrapper } from "@/common/components/source-system-wrapper/SourceSystemWrapper";
import { Switch } from "@/common/components/switch/Switch";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { IntegrationType, VoucherType } from "@/generated/graphql";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useExportReceipt } from "../../providers/ExportReceiptProvider";

type Props = {
  defaultClosed?: boolean;
};

export const ReceiptConnectionOptions: FC<Props> = ({
  defaultClosed = false,
}) => {
  const intl = useIntl();
  const [collapsed, setCollapsed] = useState(defaultClosed);
  const { postingDate, setPostingDate, voucherType, setVoucherType } =
    useExportReceipt();

  return (
    <SourceSystemWrapper
      allowedIntegrationTypes={[IntegrationType.Foundation]}
      allowedFeatures={[IntegrationFeature.ReceiptsFileBased]}
    >
      <Container>
        <Title>
          <FormattedMessage id="OPTIONS" />
          <GroupToggle
            isOpened={!collapsed}
            onClick={() => setCollapsed((v) => !v)}
          />
        </Title>
        <Content $opened={!collapsed}>
          <Row>
            <FormattedMessage id="POSTING_DATE" />
            <DatePicker
              date={postingDate}
              onChange={(newValue) => {
                setPostingDate(newValue);
              }}
              className="bg-white"
              required
            />
          </Row>
          <SourceSystemWrapper
            allowedFeatures={[IntegrationFeature.InvoiceFileExports]}
          >
            <Row>
              <FormattedMessage id="VOUCHER_TYPE" />
              <Switch
                width={100}
                offLabel={intl.$t({ id: "VOUCHER_TYPE_REGULAR" })}
                onLabel={intl.$t({ id: "VOUCHER_TYPE_PREPAID" })}
                onChange={(value) => {
                  setVoucherType(
                    value ? VoucherType.Prepaid : VoucherType.Regular,
                  );
                }}
                value={voucherType === VoucherType.Prepaid}
              />
            </Row>
          </SourceSystemWrapper>
        </Content>
      </Container>
    </SourceSystemWrapper>
  );
};
