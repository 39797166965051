import { useDialog } from "@/common/components/dialog/DialogProvider";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { Release, ReleaseStatus, ReleaseType } from "@/generated/graphql";
import { MenuItem, Select } from "@mui/material";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReleaseStatusUpdate } from "../hooks/useReleaseStatusUpdate";
import { useReleaseStore } from "../store/useReleaseStore";

type ReleaseProps = Pick<Release, "status"> & {
  type: Pick<ReleaseType, "transactionKind">;
};

const StyledSelect = tw(
  Select,
)`[&>div]:p-0 [&>div]:pr-7 [&_fieldset]:border-none [&_svg]:right-0`;
const ConfirmationContainer = tw.div`max-w-38`;

export const ReleaseChipStatusWithSelector = ({
  release,
}: {
  release: ReleaseProps | undefined | null;
}) => {
  const { options, changeStatus } = useReleaseStatusUpdate();
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { hasChanges } = useReleaseStore();

  const onChange = useCallback(
    async (newStatus: ReleaseStatus) => {
      await changeStatus(newStatus, () => {
        openDialog({
          content: (
            <SuccessModal
              message={
                <ConfirmationContainer>
                  {intl.$t({ id: "ORDER_MOVED_TO_RESERVED" })}
                </ConfirmationContainer>
              }
            />
          ),
          closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        });
      });
    },
    [changeStatus, intl, openDialog],
  );

  if (!release) {
    return null;
  }

  return (
    <StyledSelect
      id="release-status-select"
      key="status-select"
      value={release.status}
      onChange={async (ev) => {
        if (hasChanges) {
          openDialog({
            cancelButtonText: intl.$t({ id: "CONTINUE_EDITING" }),
            confirmButtonText: intl.$t({ id: "SAVE_CHANGES" }),
            includeWarningIcon: true,
            title: intl.$t({ id: "UNSAVED_CHANGES_PRINT_TITLE" }),
            text: intl.$t(
              { id: "RELEASE_STATE_CHANGE_UNSAVED_CHANGES_DESCRIPTION" },
              {
                nextReleaseState: intl.$t({
                  id: `RELEASE_STATUS_${ev.target.value}`,
                }),
              },
            ),
            closeOnConfirm: true,
            handleConfirm: async () => {
              await onChange(ev.target.value as ReleaseStatus);
            },
          });
        } else {
          await onChange(ev.target.value as ReleaseStatus);
        }
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option}
          value={option}
          style={option === release.status ? { display: "none" } : undefined}
        >
          <ReleaseStatusChip
            status={option}
            type="breadcrumb"
            releaseType={release.type}
          />
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
