import { If } from "@/common/components/if/If";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { Card } from "@/common/layout/ResponsiveClasses";
import { useUser } from "@/common/providers/UserProvider";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useProjectNavigate } from "../../../projects/hooks/useProjectNavigate";
import { ReleaseWatchers } from "../release-details/ReleaseWatchers";
import { DeliveryInformation } from "./DeliveryInformation";
import { AssignBuyoutButton } from "./assign-buyout/AssignBuyoutButton";
import { useCanEditReleaseVendor } from "./hooks/useCanEditReleaseVendor";

const Container = tw(Card)`
  mb-6 justify-between border
  gap-2 items-center w-full bg-blue-100 py-3 pl-4 pr-3 rounded-md border-blue-200
  grid 
  ${({ $canEditVendor }: { $canEditVendor: boolean }) => ($canEditVendor ? "grid-cols-[1fr_0.5fr_3fr_0.5fr]" : "grid-cols-[0.8fr_0.8fr_0.5fr_3fr_0.5fr]")}
`;

const FieldsContainer = tw.div`grid grid-cols-[repeat(2,_auto)] gap-2
  ${({ $columnsCount }: { $columnsCount: number }) =>
    $columnsCount === 3
      ? "grid-cols-[repeat(3,1fr)]"
      : "grid-cols-[repeat(4,1fr)]"}
  `;

const WatchersContainer = tw.div`border-l border-dashed pl-2 self-stretch border-gray-400 text-xs my-3 flex flex-col gap-1 font-light`;

export const SpecifyDetailsHeader: FC<{
  release: ReleaseFieldsFragment;
}> = ({ release }) => {
  const intl = useIntl();
  const { includeOrderTypes } = useOrderTypeOptions();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { canEditVendor } = useCanEditReleaseVendor();
  const { getProjectPath } = useProjectNavigate();

  const columnsCount = useMemo(() => {
    const missingColumns =
      (!includeOrderTypes ? 1 : 0) +
      (!includePoNumbering ? 1 : 0) +
      (!canEditVendor ? 1 : 0);
    if (missingColumns > 1) {
      return 3;
    }
    return 4;
  }, [canEditVendor, includeOrderTypes, includePoNumbering]);

  const { viewer } = useUser();
  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "RELEASE" })}</title>
      </Helmet>
      <Container $canEditVendor={canEditVendor}>
        <OrgDetailsHeaderItem
          title={<FormattedMessage id="PROJECT" />}
          name={
            release.project
              ? `${release.project.name}${release.project.jobNumber ? ` (${release.project.jobNumber})` : ""}`
              : undefined
          }
          logoImageUrl={viewer?.org.photoUrl}
          includeAddress={!!release?.warehouse}
          navTo={getProjectPath(release.project)}
          jobNumber={release.project?.jobNumber}
          useDefaultTitlePosition={false}
          warehouseId={release.warehouse?.id}
          includeAddressLabel={!!release.warehouse}
        />
        <If isTrue={!canEditVendor}>
          <OrgDetailsHeaderItem
            title={<FormattedMessage id="VENDOR" />}
            orgName={release.sellerOrgLocation?.org.name}
            logoImageUrl={release.sellerOrgLocation?.org.photoUrl}
            address={release.sellerOrgLocation?.address}
            useDefaultTitlePosition={false}
          />
        </If>
        <AssignBuyoutButton release={release} />
        <FieldsContainer $columnsCount={columnsCount}>
          <DeliveryInformation />
        </FieldsContainer>
        <WatchersContainer>
          <FormattedMessage id="WATCHERS" />
          <ReleaseWatchers verticalItems moreTagCount={2} />
        </WatchersContainer>
      </Container>
    </>
  );
};
