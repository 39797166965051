import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useUser } from "@/common/providers/UserProvider";
import { AuthorizationStatus } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { InvoiceForm } from "./InvoiceForm";
import { InvoiceFormType } from "./types/InvoiceFormType";

export const ToggleAddInvoiceForm = () => {
  const { fullEmail } = useUser();

  const { locations } = useUserLocations({
    permission: "createInvoice",
  });

  const hasPermissionInOrgLocation = useMemo(
    () =>
      locations.some(
        (l) => l.permissions.createInvoice === AuthorizationStatus.Authorized,
      ),
    [locations],
  );

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <Tooltip
          id="disabled-tooltip"
          element={
            <PrimaryButton
              onClick={() => togglePanel(true)}
              disabled={!hasPermissionInOrgLocation}
              className="truncate"
            >
              <FormattedMessage id="ADD_INVOICE" />
            </PrimaryButton>
          }
          classes={{ tooltip: "max-w-screen-sm" }}
        >
          {!hasPermissionInOrgLocation && (
            <FormattedMessage
              id="ADD_INVOICE_DISABLED_TOOLTIP"
              values={{ email: fullEmail }}
            />
          )}
        </Tooltip>
      )}
      content={(togglePanel) => (
        <InvoiceForm
          onClose={() => togglePanel(false)}
          type={InvoiceFormType.ADD}
        />
      )}
    />
  );
};
