import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { formattedDate } from "@/common/utils/dates/DateView";
import { useReleaseStore } from "@/contractor/pages/home/release/store/useReleaseStore";
import { ErrorOutline } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { ReleaseDateTimePickerProps } from "../ReleaseDateTimePicker";

type Props = Pick<ReleaseDateTimePickerProps, "prefillIfTbd">;

export const ReleaseDateTimePickerErrorTooltip = ({ prefillIfTbd }: Props) => {
  const { releaseUpdateOptions } = useReleaseStore(
    useShallow((state) => ({
      releaseUpdateOptions: state.releaseUpdateOptions,
    })),
  );

  return (
    <If isTrue={prefillIfTbd && releaseUpdateOptions.requestedTime}>
      <Tooltip
        id="submit-date-replacement"
        element={<ErrorOutline color="error" />}
      >
        <FormattedMessage
          id="RELEASE_DATE_WILL_BE_SET_TO"
          values={{
            date: releaseUpdateOptions.requestedTime
              ? formattedDate({
                  date: new Date(releaseUpdateOptions.requestedTime),
                })
              : "",
          }}
        />
      </Tooltip>
    </If>
  );
};
