import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  InvoiceDetailsFooter,
  InvoiceFooterWarningIconOrange,
  InvoiceFooterWarningIconRed,
} from "../../../../../../../../common/components/invoices/invoice-details/footer/InvoiceFooterDetails";
import { areDecimalsEqual } from "../../../../../../../../common/utils/areDecimalsEqual";
import { useDistributorInvoice } from "../../../../../providers/DistributorInvoiceProvider";
import {
  Column,
  ContentColumn,
  PanelTitle,
  QuarterPanel,
} from "./InvoiceExpandable.styled";

export const InvoicePanel = () => {
  const { invoice } = useDistributorInvoice();

  const sameTotal = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return areDecimalsEqual(invoice.release.total, invoice?.total);
  }, [invoice?.release, invoice?.total]);

  const isTotalDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return new DecimalSafe(invoice?.release.total ?? 0)
      .minus(invoice?.total ?? 0)
      .abs()
      .lessThan(1);
  }, [invoice?.release, invoice?.total]);

  const sameSubtotal = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return areDecimalsEqual(invoice.release?.netAmount, invoice?.subtotal);
  }, [invoice?.release, invoice?.subtotal]);

  const isSubtotalDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return new DecimalSafe(invoice?.release.netAmount ?? 0)
      .minus(invoice?.subtotal ?? 0)
      .abs()
      .lessThan(1);
  }, [invoice?.release, invoice?.subtotal]);

  const sameAdditionalCharges = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return areDecimalsEqual(
      (invoice.release?.additionalCharges || [])
        .reduce((acc, charge) => {
          return Number(
            new DecimalSafe(acc).add(new DecimalSafe(charge.amount || 0)),
          );
        }, 0)
        .toString(),
      invoice?.chargesAmount,
    );
  }, [invoice?.release, invoice?.chargesAmount]);

  const isAdditionalChargesDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return new DecimalSafe(
      (invoice.release?.additionalCharges || []).reduce((acc, charge) => {
        return Number(
          new DecimalSafe(acc).add(new DecimalSafe(charge.amount || 0)),
        );
      }, 0),
    )
      .minus(invoice?.chargesAmount ?? 0)
      .abs()
      .lessThan(1);
  }, [invoice?.release, invoice?.chargesAmount]);

  const sameSalesTax = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return areDecimalsEqual(invoice.release?.taxAmount, invoice?.taxAmount);
  }, [invoice?.release, invoice?.taxAmount]);

  const isSalesTaxDiscrepancyMinor = useMemo(() => {
    if (!invoice?.release) {
      return undefined;
    }
    return new DecimalSafe(invoice?.release.taxAmount ?? 0)
      .minus(invoice?.taxAmount ?? 0)
      .abs()
      .lessThan(1);
  }, [invoice?.release, invoice?.taxAmount]);

  return (
    <QuarterPanel data-testid="invoiceFooter" className="bg-blue-250">
      <Column>
        <FormattedMessage id="INVOICE" tagName={PanelTitle} />
      </Column>
      <ContentColumn>
        <InvoiceDetailsFooter
          invoice={invoice}
          readonly
          subtotalWarning={
            <If isTrue={sameSubtotal === false}>
              {!isSubtotalDiscrepancyMinor ? (
                <InvoiceFooterWarningIconRed />
              ) : (
                <Tooltip
                  element={<InvoiceFooterWarningIconOrange />}
                  id="total-discrepancy"
                >
                  <FormattedMessage id="MINOR_DISCREPANCY_WITH_ORDER" />
                </Tooltip>
              )}
            </If>
          }
          additionalChargesWarning={
            <If isTrue={sameAdditionalCharges === false}>
              {!isAdditionalChargesDiscrepancyMinor ? (
                <InvoiceFooterWarningIconRed />
              ) : (
                <Tooltip
                  element={<InvoiceFooterWarningIconOrange />}
                  id="total-discrepancy"
                >
                  <FormattedMessage id="MINOR_DISCREPANCY_WITH_ORDER" />
                </Tooltip>
              )}
            </If>
          }
          salesTaxWarning={
            <If isTrue={sameSalesTax === false}>
              {!isSalesTaxDiscrepancyMinor ? (
                <InvoiceFooterWarningIconRed />
              ) : (
                <Tooltip
                  element={<InvoiceFooterWarningIconOrange />}
                  id="total-discrepancy"
                >
                  <FormattedMessage id="MINOR_DISCREPANCY_WITH_ORDER" />
                </Tooltip>
              )}
            </If>
          }
          totalWarning={
            <If isTrue={sameTotal === false}>
              {!isTotalDiscrepancyMinor ? (
                <InvoiceFooterWarningIconRed />
              ) : (
                <Tooltip
                  element={<InvoiceFooterWarningIconOrange />}
                  id="total-discrepancy"
                >
                  <FormattedMessage id="MINOR_DISCREPANCY_WITH_ORDER" />
                </Tooltip>
              )}
            </If>
          }
        />
      </ContentColumn>
    </QuarterPanel>
  );
};
