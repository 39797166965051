import { formattedDate } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { KickbackSummaryFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const useKickbackIndicator = ({
  kickbacks,
}: {
  kickbacks: KickbackSummaryFieldsFragment[];
}) => {
  const intl = useIntl();

  const totalKickbacks = useMemo(
    () => kickbacks.filter((kickback) => !kickback.deletedAt).length,
    [kickbacks],
  );

  const resolvedKickbacks = useMemo(
    () =>
      kickbacks.filter((kickback) => kickback.resolvedAt && !kickback.deletedAt)
        .length,
    [kickbacks],
  );

  const kickbackInfo = useMemo(() => {
    if (!totalKickbacks) {
      return [intl.$t({ id: "NO_KICKBACKS" })];
    }
    if (totalKickbacks === resolvedKickbacks) {
      return [intl.$t({ id: "KICKBACKS_RESOLVED" })];
    }
    return [
      `${intl.$t({ id: "KICKBACKS" })}:`,
      ...kickbacks
        .filter((kickback) => !kickback.resolvedAt && !kickback.deletedAt)
        .map((kickback) =>
          intl.$t(
            { id: "KICKBACK_INFO" },
            {
              assignedTo: kickback.assignees
                .map((assignee) => getUserName(assignee))
                .join(", "),
              date: formattedDate({ date: kickback.createdAt }),
              requestedBy: getUserName(kickback.reportedBy),
            },
          ),
        ),
    ];
  }, [totalKickbacks, resolvedKickbacks, intl, kickbacks]);

  return {
    kickbackInfo,
    totalKickbacks,
    resolvedKickbacks,
  };
};
