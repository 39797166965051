import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { useIntl } from "react-intl";
import { AccountingOnPremiseProviderContextType } from "../AccountingOnPremiseWizardProvider";
import { AccountingOnPremiseFinalStep } from "../steps/final/AccountingOnPremiseFinalStep";
import { AccountingOnPremiseMaterialCostType } from "../steps/material-cost-type/AccountingOnPremiseMaterialCostType";

import { DecimalSafe } from "@/common/utils/decimalSafe";
import { AccountingOnPremiseTaxAuthorities } from "../steps/tax-authorities/AccountingOnPremiseTaxAuthorities";

type FoundationPagesArgs = Pick<
  AccountingOnPremiseProviderContextType,
  "closeWizard" | "handleSaveWizardData" | "taxAuthorities" | "materialCostType"
>;

export const useOnPremiseSage300Pages = ({
  closeWizard,
  handleSaveWizardData,
  taxAuthorities,
  materialCostType,
}: FoundationPagesArgs): WizardModalPage[] => {
  const intl = useIntl();
  const { moveToPreviousStep, moveToNextStep } = useNestedStepper();

  return [
    {
      title: intl.$t({ id: "ACCOUNTING_MATERIAL_COST_TYPE" }),
      completed: !!materialCostType,
      pages: [
        {
          component: <AccountingOnPremiseMaterialCostType />,
          onNextClickDisabled: !materialCostType,
          onPreviousClick: moveToPreviousStep,
          onNextClick: async () => {
            await handleSaveWizardData({
              materialCostType,
            });
            moveToNextStep();
          },
        },
      ],
    },
    {
      title: intl.$t({ id: "TAX_AUTHORITIES" }),
      completed: !!taxAuthorities.length,
      pages: [
        {
          component: <AccountingOnPremiseTaxAuthorities />,
          onPreviousClick: moveToPreviousStep,
          onNextClick: async () => {
            await handleSaveWizardData({
              taxAuthorities: taxAuthorities
                .filter((t) => t.code && t.rate)
                .map((t) => ({
                  code: t.code,
                  rate: new DecimalSafe(t.rate).div(100).toString(),
                })),
            });

            moveToNextStep();
          },
          onNextClickDisabled: taxAuthorities.some((t) => !t.code || !t.rate),
        },
      ],
    },
    {
      title: null,
      pages: [
        {
          component: <AccountingOnPremiseFinalStep />,
          onCloseClick: closeWizard,
        },
      ],
    },
  ];
};
