import { SequenceStepper } from "@/common/components/sequence-stepper/SequenceStepper";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { setValue } from "@/common/utils/localStorage";
import { routes } from "@/config/routes";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router";
import { useReleaseSequence } from "../providers/ReleaseSequenceProvider";

export const ReleaseSequenceStepper = () => {
  const navigate = useNavigate();
  const { step, setStep, sequenceIds, redirectToReleases, selectedReleases } =
    useReleaseSequence();

  const redirectToRelease = useCallback(
    (sequenceStep: number) => {
      const firstRelease = selectedReleases[sequenceStep];
      if (firstRelease) {
        setValue(
          LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.REDIRECT_ROUTE,
          location.search,
        );
        navigate(
          generatePath(routes.delivery, {
            deliveryId: firstRelease.id,
          }),
        );
      }
    },
    [navigate, selectedReleases],
  );

  const prevStepCallback = useCallback(() => {
    redirectToRelease(step - 1);
  }, [redirectToRelease, step]);

  const nextStepCallback = useCallback(() => {
    redirectToRelease(step + 1);
  }, [redirectToRelease, step]);

  return (
    <SequenceStepper
      titleId="ORDERS"
      step={step}
      setStep={setStep}
      sequenceIds={sequenceIds}
      exitSequence={redirectToReleases}
      prevStepCallback={prevStepCallback}
      nextStepCallback={nextStepCallback}
    />
  );
};
