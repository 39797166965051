import { Popover as CustomPopover } from "@/common/components/popover/Popover";
import { useLoadingAction } from "@/common/hooks/useLoadingAction";
import { useUser } from "@/common/providers/UserProvider";

import { useEquipmentItems } from "@/contractor/pages/admin/cost-structure/pages/equipment/hooks/useEquipmentItems";
import { useServiceCodes } from "@/contractor/pages/admin/cost-structure/pages/service-codes/hooks/useServiceCodes";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { SplittingInvoicesWizardModal } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/splitting-invoices/SplittingInvoicesWizardModal";
import { InvoiceFieldsFragment, InvoiceType } from "@/generated/graphql";
import { Add, Cached, Check } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDebounce } from "use-debounce";
import { dateDiffInDays } from "../../../../contractor/pages/home/invoices/pages/scanned-invoices/utils/dateDiff";
import { LoadingButton } from "../../button/LoadingButton";
import { OutlinedButton } from "../../button/OutlinedButton";
import { If } from "../../if/If";
import { InfoTooltip } from "../../info-tooltip/InfoTooltip";
import { NumericalInput } from "../../numerical-input/NumericalInput";
import { DatePicker } from "../../picker/components/DatePicker";
import { CustomPaymentTerms } from "../../terms-and-discounts/CustomPaymentTerms";
import { TextField } from "../../textfield/TextField";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";
import { EquipmentItemServiceCodeSelectors } from "./components/EquipmentItemServiceCodeSelectors";
import { InvoiceDiscountDatePicker } from "./header/InvoiceDiscountDatePicker";

const Container = tw.div`flex flex-col rounded-3xl bg-blue-450 px-6 py-3 rounded-tl-none`;
const Row = tw.div`gap-y-4 grid grid-cols-2 py-3 text-sm gap-x-2 items-center`;
const RowWithBorder = tw(Row)`border-y border-dashed border-gray-500
  ${({ $readonly }: { $readonly?: boolean }) => $readonly && "border-b-0"}
`;
const LabelContainer = tw.div`flex items-center gap-2`;
const ButtonContent = tw.div`flex flex-row items-center py-0`;
const AddIcon = tw(Add)`py-0 mr-1 text-xl text-white`;
const CachedIcon = tw(Cached)`py-0 mr-1 text-xl text-white`;
export const SaveStatusPanel = tw.div`flex justify-end gap-1 text-xs text-green-800 items-center mt-2`;
export const CheckIcon = tw(Check)`w-5 h-5`;

type InvoiceDocumentOptionsProps = {
  invoice: InvoiceFieldsFragment | null;
  readonly?: boolean;
  rescanInvoice?: () => void;
  togglePanel: (opened: boolean) => void;
  type?: InvoiceType;
  closeOptions: () => void;
  readjust: () => void;
};

export const InvoiceDocumentOptions = ({
  invoice,
  readonly = false,
  rescanInvoice,
  togglePanel,
  type,
  closeOptions,
  readjust,
}: InvoiceDocumentOptionsProps) => {
  const intl = useIntl();
  const { loading, asyncAction } = useLoadingAction();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { updateInvoice } = useInvoiceVerification();
  const { isSystemAdmin } = useUser();
  const { serviceCodes, loading: loadingServiceCodes } = useServiceCodes();
  const { equipmentItems, loading: loadingEquipmentItems } =
    useEquipmentItems();

  const [updatesCount, setUpdatesCount] = useState(0);
  const [retentionPercentage, setRetentionPercentage] = useState(
    invoice?.retentionPercentage || "0",
  );
  const [debouncedRetentionPercentage] = useDebounce(retentionPercentage, 700);
  const [discountPercentage, setDiscountPercentage] = useState(
    invoice?.discountPercentage || "0",
  );
  const [debouncedDiscountPercentage] = useDebounce(discountPercentage, 700);
  const [discountDate, setDiscountDate] = useState(
    invoice?.discountDate ? new Date(invoice?.discountDate) : null,
  );

  useEffect(() => {
    setRetentionPercentage(invoice?.retentionPercentage ?? "");
  }, [invoice?.retentionPercentage]);

  useEffect(() => {
    setDiscountPercentage(invoice?.discountPercentage ?? "");
  }, [invoice?.discountPercentage]);
  useEffect(() => {
    setDiscountDate(
      invoice?.discountDate ? new Date(invoice?.discountDate) : null,
    );
  }, [invoice?.discountDate]);

  const invoiceIssueDate = useMemo(
    () => (invoice?.issueDate ? new Date(invoice.issueDate) : null),
    [invoice?.issueDate],
  );

  const invoiceDiscountDate = useMemo(
    () => (invoice?.discountDate ? new Date(invoice?.discountDate) : null),
    [invoice?.discountDate],
  );

  const showRescanInvoice = useMemo(
    () => rescanInvoice && type !== InvoiceType.Receipt && isSystemAdmin,
    [isSystemAdmin, rescanInvoice, type],
  );

  const handleRescan = async () => {
    if (!rescanInvoice) {
      return;
    }

    await asyncAction(async () => {
      await rescanInvoice();
    });
  };

  useEffect(() => {
    if (!invoice?.id || readonly) {
      return;
    }
    const currentValue = parseFloat(invoice?.retentionPercentage || "0");
    const newValue = parseFloat(debouncedRetentionPercentage);
    if (newValue !== currentValue) {
      if (newValue < 0 || newValue > 100) {
        return;
      }
      updateInvoice?.({
        id: invoice.id,
        retentionPercentage: debouncedRetentionPercentage,
      });
      setUpdatesCount((count) => count + 1);
    }
    //Dmitry Kozin: updateInvoice is not memoised so we need to exclude this method from the dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedRetentionPercentage,
    invoice?.id,
    invoice?.retentionPercentage,
    readonly,
  ]);

  useEffect(() => {
    if (!invoice?.id || readonly) {
      return;
    }
    const currentValue = parseFloat(invoice?.discountPercentage || "0");
    const newValue = parseFloat(debouncedDiscountPercentage);
    if (currentValue !== newValue) {
      if (newValue < 0 || newValue > 100) {
        return;
      }
      updateInvoice?.({
        id: invoice.id,
        discountPercentage: debouncedDiscountPercentage,
      });
      setUpdatesCount((count) => count + 1);
    }
    //Dmitry Kozin: updateInvoice is not memoised so we need to exclude this method from the dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedDiscountPercentage,
    invoice?.discountPercentage,
    invoice?.id,
    readonly,
  ]);

  useEffect(() => {
    if (!invoice?.id || readonly) {
      return;
    }
    if (discountDate?.getTime() !== invoiceDiscountDate?.getTime()) {
      updateInvoice?.({
        id: invoice.id,
        discountDate: discountDate?.getTime(),
        ...(discountDate ? {} : { clearDiscountDate: true }),
      });
      setUpdatesCount((count) => count + 1);
    }
    //Dmitry Kozin: updateInvoice is not memoised so we need to exclude this method from the dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.id, readonly, discountDate, invoiceDiscountDate]);

  const discountLabel = useMemo(
    () =>
      discountPercentage && invoice?.discountedAmount && invoice?.total
        ? formatCurrency(
            Number(invoice.total) - Number(invoice.discountedAmount),
          )
        : undefined,
    [
      discountPercentage,
      formatCurrency,
      invoice?.discountedAmount,
      invoice?.total,
    ],
  );

  const invoicePaymentTerm = useMemo(
    () =>
      invoice?.dueDate && invoice.issueDate
        ? dateDiffInDays(invoice.dueDate, invoice.issueDate)?.toString() || "0"
        : "0",
    [invoice?.dueDate, invoice?.issueDate],
  );

  const updateInvoicePaymentTerm = useCallback(
    (value: string) => {
      if (invoice) {
        const issueDate = invoice.issueDate
          ? new Date(invoice.issueDate)
          : new Date();
        const days = Number(value || 0);
        updateInvoice?.({
          id: invoice.id,
          dueDate: new Date(
            issueDate.setDate(issueDate.getDate() + days),
          ).getTime(),
        });
      }
    },
    [invoice, updateInvoice],
  );

  return (
    <Container>
      <EquipmentItemServiceCodeSelectors
        invoice={invoice}
        readonly={readonly}
        setUpdatesCount={setUpdatesCount}
        updateInvoice={updateInvoice}
        equipmentItems={equipmentItems}
        loadingEquipmentItems={loadingEquipmentItems}
        serviceCodes={serviceCodes}
        loadingServiceCodes={loadingServiceCodes}
      />
      <Row>
        <FormattedMessage id="PAYMENT_TERMS" />
        <CustomPaymentTerms
          paymentTerm={invoicePaymentTerm}
          updateItem={updateInvoicePaymentTerm}
          hideLabel
          classes={{
            container: "w-full",
            select: "mr-0 ml-2",
          }}
        />
      </Row>
      <If isTrue={type === InvoiceType.Invoice}>
        <Row>
          <FormattedMessage id="INVOICE_DISCOUNT_DATE" />
          <DatePicker
            minDate={invoiceIssueDate ?? undefined}
            date={discountDate}
            onChange={(date) => {
              setDiscountDate(date);
              if (!date) {
                setDiscountPercentage("0");
              }
            }}
            utc
            className="overflow-hidden rounded bg-white"
            textFieldComponent={(props) => (
              <TextField
                {...props}
                size="small"
                sx={{
                  " .MuiInputBase-input": {
                    fontSize: "14px",
                    textAlign: "center",
                  },
                }}
              />
            )}
            customInputAdornment={() => (
              <If isTrue={!readonly}>
                <InvoiceDiscountDatePicker
                  issueDate={invoiceIssueDate}
                  discountDate={discountDate}
                  setDiscountDate={setDiscountDate}
                />
              </If>
            )}
            error={Boolean(
              discountDate &&
                invoiceIssueDate &&
                discountDate.getTime() < invoiceIssueDate.getTime(),
            )}
            readonly={readonly}
          />
          <FormattedMessage id="INVOICE_DISCOUNT_PERCENTAGE" />
          <CustomPopover
            id="discountPercentage"
            element={
              <NumericalInput
                disabled={!discountDate}
                value={discountPercentage}
                onChange={(e) => setDiscountPercentage(e.target.value)}
                InputProps={{
                  className: "h-8 bg-white text-sm",
                  readOnly: readonly,
                }}
                fixedDecimalScale
                decimals={2}
                suffix="%"
                className="w-full"
                inputProps={{ className: "text-center" }}
                error={Boolean(
                  discountPercentage &&
                    (parseFloat(discountPercentage) < 0 ||
                      parseFloat(discountPercentage) > 100),
                )}
                label={discountLabel}
                InputLabelProps={{ className: "bg-white px-1 text-gray-500" }}
              />
            }
          >
            <If isTrue={!discountDate}>
              <FormattedMessage id="INVOICE_DISCOUNT_DATE_REQUIRED" />
            </If>
          </CustomPopover>
        </Row>

        <RowWithBorder $readonly={readonly}>
          <LabelContainer>
            <FormattedMessage id="INVOICE_RETAINAGE" />
            <InfoTooltip
              message={intl.$t({ id: "INVOICE_RETAINAGE_TOOLTIP" })}
            />
          </LabelContainer>
          <NumericalInput
            value={retentionPercentage}
            onChange={(e) => setRetentionPercentage(e.target.value)}
            InputProps={{
              className: "h-8 bg-white text-sm",
              readOnly: readonly,
            }}
            fixedDecimalScale
            decimals={2}
            suffix="%"
            className="w-full"
            inputProps={{ className: "text-center" }}
            error={Boolean(
              retentionPercentage &&
                (parseFloat(retentionPercentage) < 0 ||
                  parseFloat(retentionPercentage) > 100),
            )}
          />
        </RowWithBorder>
        <If isTrue={updatesCount}>
          <SaveStatusPanel>
            <CheckIcon />
            <FormattedMessage id="CHANGES_SAVED" />
          </SaveStatusPanel>
        </If>

        <If isTrue={!readonly}>
          <Row>
            <LabelContainer>
              <FormattedMessage id="MISSING_OR_WRONG_PAGES" />
              <InfoTooltip
                message={intl.$t({ id: "MISSING_OR_WRONG_PAGES_TOOLTIP" })}
              />
              <SplittingInvoicesWizardModal />
            </LabelContainer>
            <OutlinedButton $small onClick={readjust} className="h-6">
              <FormattedMessage id="READJUST" />
            </OutlinedButton>
          </Row>

          <Row>
            <OutlinedButton
              $small
              onClick={() => {
                togglePanel(true);
                closeOptions();
              }}
              className="h-6 pr-4"
            >
              <ButtonContent>
                <AddIcon className="text-blue-800" />
                <FormattedMessage id="UPLOAD_CORRECTION" />
              </ButtonContent>
            </OutlinedButton>

            <If isTrue={showRescanInvoice}>
              <LoadingButton
                button={OutlinedButton}
                onClick={handleRescan}
                loading={loading}
                $small
                className="h-6 pr-4"
              >
                <ButtonContent>
                  <CachedIcon className="text-blue-800" />
                  <FormattedMessage id="RESCAN_INVOICE" />
                </ButtonContent>
              </LoadingButton>
            </If>
          </Row>
        </If>
      </If>
    </Container>
  );
};
