import { DateTimePicker } from "@/common/components/picker/components/DateTimePicker";
import { useReleaseDateTimeAdjustment } from "@/contractor/pages/home/release/pages/specify-details/components/release-date-time-picker/hooks/useReleaseDateTimeAdjustment";
import { useReleaseDateTimePickerConfig } from "@/contractor/pages/home/release/pages/specify-details/components/release-date-time-picker/hooks/useReleaseDateTimePickerConfig";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../../providers/ReleaseProvider";
import { ReleaseDateTimePickerErrorTooltip } from "./components/ReleaseDateTimePickerErrorTooltip";
import { useReleaseDateTimePicker } from "./hooks/useReleaseDateTimePicker";

const Container = tw.div`grid grid-flow-col gap-2 items-center`;

export type ReleaseDateTimePickerProps = {
  value: Date | null;
  onChange: (date: Date | null | undefined) => void;
  label?: string;
  disabled?: boolean;
  includeCustomAction?: boolean;
  prefillIfTbd?: boolean;
  hideIfNotAdjustmentSet?: boolean;
  className?: string;
};

const inputProps = {
  className: "text-sm",
};

export const ReleaseDateTimePicker: FC<ReleaseDateTimePickerProps> = ({
  value,
  onChange,
  label: customLabel,
  disabled: forceDisable,
  includeCustomAction = true,
  prefillIfTbd,
  hideIfNotAdjustmentSet,
  className,
}) => {
  const { release } = useRelease();
  const {
    actionBar,
    labelProps: { label, styling },
    value: computedValue,
    disabled,
  } = useReleaseDateTimePickerConfig({
    date: value,
    labels: { customLabel },
    release,
    prefillIfTbd,
  });
  useReleaseDateTimeAdjustment({
    prefillIfTbd,
    release,
  });
  const { onChangeAndResetStore, hidden } = useReleaseDateTimePicker({
    onChange,
    prefillIfTbd,
    hideIfNotAdjustmentSet,
  });

  const isFieldDisabled = useMemo(
    () => forceDisable || disabled,
    [forceDisable, disabled],
  );

  const { inputErrors } = useReleaseActions();

  if (hidden) {
    return null;
  }

  return (
    <Container className={className}>
      <DateTimePicker
        label={label}
        date={computedValue}
        onChange={onChangeAndResetStore}
        onAccept={onChangeAndResetStore}
        required={!isFieldDisabled}
        error={inputErrors.includes(
          ReleaseErrorType.REQUESTED_FULFILLMENT_DATE,
        )}
        inputProps={inputProps}
        InputLabelProps={styling}
        actionBar={actionBar}
        disabled={isFieldDisabled}
        slotProps={{
          actionBar: {
            onClick: () => {
              onChange(undefined);
            },
            includeCustomAction,
          } as never,
        }}
      />
      <ReleaseDateTimePickerErrorTooltip prefillIfTbd={prefillIfTbd} />
    </Container>
  );
};
