import { TextField } from "@/common/components/textfield/TextField";
import { TaxCodeFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";

type Props = {
  code: TaxCodeFieldsFragment;
  prop: "code" | "description";
  placeholder: string;
  required?: boolean;
  readonly?: boolean;
  error: boolean;
  centered?: boolean;
};

export const TaxCodeInput: FC<Props> = ({
  code,
  prop,
  placeholder,
  required,
  readonly,
  centered,
}) => {
  const intl = useIntl();

  return (
    <TextField
      size="small"
      className="w-full"
      value={code[prop]}
      required={required}
      placeholder={intl.$t({ id: placeholder })}
      staticText={readonly}
      centered={centered}
    />
  );
};
