import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useExportBatch } from "@/common/providers/ExportBatchProvider";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { BatchType } from "@/generated/graphql";
import { useMemo, useState } from "react";
import { useIntegrationFeatureRequirement } from "../../integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationBatchOptionsProps } from "../types/IntegrationBatchOptionsProps";

export const useIntegrationBatchOptions = ({
  mode,
  type,
}: Pick<IntegrationBatchOptionsProps, "mode" | "type">) => {
  const { batchNumber, postAutomatically } = useExportBatch();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { connectedSourceSystemSettings } = useOrgSettings();

  const disableBatching = useMemo(
    () => mode !== ConnectionMode.Connect,
    [mode],
  );

  const [batchNumberText, setBatchNumberText] = useState<string>(
    batchNumber ?? "",
  );

  const includePostAutomatically = useMemo(
    () => type !== BatchType.PurchaseOrder,
    [type],
  );

  const includeBatching = useMemo(
    () =>
      !includePostAutomatically ||
      !postAutomatically ||
      type === BatchType.PurchaseOrder,
    [postAutomatically, type, includePostAutomatically],
  );

  const show = useMemo(
    () =>
      (hasFeatureInConnectedSourceSystem(IntegrationFeature.POBatching) &&
        (!hasFeatureInConnectedSourceSystem(IntegrationFeature.AutoPostPOs) ||
          !connectedSourceSystemSettings?.autoPostPOs) &&
        type === BatchType.PurchaseOrder) ||
      (hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceBatching) &&
        type === BatchType.Invoice),
    [connectedSourceSystemSettings, hasFeatureInConnectedSourceSystem, type],
  );

  return {
    disableBatching,
    batchNumberText,
    setBatchNumberText,
    includePostAutomatically,
    includeBatching,
    show,
  };
};
