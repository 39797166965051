import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { BATCH_MONTH_DATE_FORMAT } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useExportBatch } from "@/common/providers/ExportBatchProvider";
import { goToExternalUrls } from "@/common/utils/browserUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useCreateExternalBatch } from "@/contractor/pages/home/release/components/connections/hooks/useCreateExternalBatch";
import {
  BatchType,
  ExportInvoicesInput,
  IntegrationType,
  LinkInvoiceInput,
  SourceSystem,
  useExportInvoicesMutation,
} from "@/generated/graphql";
import { LinkingError, LinkingProgress } from "@/types/LinkingProgress";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import { format } from "date-fns";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useLinkInvoice } from "../hooks/useLinkInvoice";
import { useInvoicesByIds } from "./InvoicesByIdsProvider";
export type UpdatedInvoice = {
  id: string;
  externalVendorCode?: string;
};

type ProviderContextType = {
  exportInvoices: (input: ExportInvoicesInput) => Promise<boolean>;
  updatedInvoices: UpdatedInvoice[];
  updateInvoice: (invoices: UpdatedInvoice) => void;
  linking: LinkingProgress;
  linkInvoices: () => Promise<{ success: boolean; batch?: string } | undefined>;
  clearErrors: () => void;
  accountingDate: Date | null;
  setAccountingDate: (date: Date | null) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  exportInvoices: NoFunctionBooleanPromise,
  updatedInvoices: [],
  updateInvoice: NoFunction,
  linking: {
    linking: false,
    completed: [],
    percentage: 0,
    errors: [],
  },
  linkInvoices: NoFunctionUndefinedPromise,
  clearErrors: NoFunction,
  accountingDate: null,
  setAccountingDate: NoFunction,
});

export const ExportInvoiceProvider: FC<PropsWithChildren> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { invoiceIds, validatedInvoices, refetchInvoicesByIds } =
    useInvoicesByIds();
  const { connectedSourceSystemSettings } = useOrgSettings();
  const { linkInvoice } = useLinkInvoice();
  const { createExternalBatch } = useCreateExternalBatch();
  const { batchDate, newBatch, externalBatch } = useExportBatch();
  const [accountingDate, setAccountingDate] = useState<Date | null>(null);

  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const [updatedInvoices, setUpdatedInvoices] = useState<UpdatedInvoice[]>([]);

  const [linking, setLinking] = useState<LinkingProgress>({
    linking: false,
    percentage: 0,
    completed: [],
    errors: [],
  });

  const [exportInvoicesMutation] = useExportInvoicesMutation();
  const exportInvoices = useCallback(
    async (input: ExportInvoicesInput) => {
      try {
        const { data, errors } = await exportInvoicesMutation({
          variables: {
            input,
          },
        });
        setError(errors);

        const urls = data?.exportInvoices.fileURLs;
        if (urls) {
          await goToExternalUrls(urls);
        }
        refetchInvoicesByIds();
        return !errors;
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [exportInvoicesMutation, setError, refetchInvoicesByIds],
  );

  const linkInvoices = useCallback(async () => {
    if (connectedSourceSystemSettings?.system) {
      let batchId = externalBatch?.externalId;

      let createdBatchNumber, createdBatchDate;
      let success = true;

      const invoicesToExport = invoiceIds.filter(
        (r) => validatedInvoices.find((vr) => vr.id === r.id)?.validated,
      );
      setLinking({
        linking: true,
        percentage: 0,
        completed: [],
        errors: [],
      });

      if (
        hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceBatching) &&
        newBatch &&
        !connectedSourceSystemSettings.autoPostInvoices
      ) {
        const createdBatch = await createExternalBatch({
          sourceSystem: connectedSourceSystemSettings.system,
          month: format(batchDate, BATCH_MONTH_DATE_FORMAT),
          type: BatchType.Invoice,
        });
        if (createdBatch) {
          batchId = createdBatch.externalId;
          createdBatchNumber = createdBatch.number;
          const createdBatchDateArr = createdBatch.month.split("-");
          createdBatchDate =
            createdBatchDateArr.length === 2
              ? `${createdBatchDateArr[1]}-${createdBatchDateArr[0]}`
              : createdBatch.month;
        }
      }
      const errors: LinkingError[] = [];

      if (connectedSourceSystemSettings.system === SourceSystem.Foundation) {
        setLinking({
          linking: true,
          percentage: 0,
          completed: [],
          errors: [],
        });
        const result = await exportInvoices({
          integration: IntegrationType.Foundation,
          invoices: invoicesToExport.map((invoice) => ({
            invoiceId: invoice.id,
            includeCostCodes: false,
          })),
        });

        setLinking({
          linking: false,
          percentage: 0,
          completed: invoicesToExport.map((invoice) => invoice.id),
          errors: [],
        });
        if (!result) {
          success = false;
        }
      } else {
        for await (const [key, invoice] of invoicesToExport.entries()) {
          let error = "";
          const input: LinkInvoiceInput = {
            invoiceId: invoice.id,
            sourceSystem: connectedSourceSystemSettings.system,
            batchId: !connectedSourceSystemSettings.autoPostInvoices
              ? batchId
              : undefined,
            month: connectedSourceSystemSettings.autoPostInvoices
              ? format(batchDate, BATCH_MONTH_DATE_FORMAT)
              : undefined,
            accountingDate: accountingDate?.getTime(),
          };
          const result = await linkInvoice(input);
          const hasTextError = typeof result !== "boolean";
          if (hasTextError || !result) {
            success = false;
            if (hasTextError) {
              error = result;
            }
          }

          errors.push({ id: invoice.id, message: error });
          setLinking({
            linking: true,
            percentage: (key + 1) / invoicesToExport.length,
            completed: [...linking.completed, invoice.id],
            errors: [],
          });
        }
      }
      setLinking({
        linking: false,
        percentage: 0,
        completed: [],
        errors,
      });

      return {
        success,
        batch: createdBatchNumber
          ? intl.$t(
              { id: "BATCH_NUMBER_DATE" },
              {
                number: createdBatchNumber,
                date: createdBatchDate,
              },
            )
          : "",
      };
    }
    return { success: false };
  }, [
    connectedSourceSystemSettings?.system,
    connectedSourceSystemSettings?.autoPostInvoices,
    externalBatch?.externalId,
    invoiceIds,
    hasFeatureInConnectedSourceSystem,
    newBatch,
    intl,
    validatedInvoices,
    createExternalBatch,
    batchDate,
    exportInvoices,
    accountingDate,
    linkInvoice,
    linking.completed,
  ]);

  const updateInvoice = (updatedInvoice: UpdatedInvoice) => {
    const index = updatedInvoices.findIndex(
      (invoice) => invoice.id === updatedInvoice.id,
    );
    if (index === -1) {
      setUpdatedInvoices([...updatedInvoices, updatedInvoice]);
    } else {
      const newUpdatedInvoices = [...updatedInvoices];
      newUpdatedInvoices[index] = updatedInvoice;
      setUpdatedInvoices(newUpdatedInvoices);
    }
  };

  const clearErrors = () => {
    setLinking({
      linking: false,
      percentage: 0,
      completed: [],
      errors: [],
    });
  };

  return (
    <ProviderContext.Provider
      value={{
        exportInvoices,
        updatedInvoices,
        updateInvoice,
        linking,
        linkInvoices,
        clearErrors,
        accountingDate,
        setAccountingDate,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExportInvoice = (): ProviderContextType =>
  useContext(ProviderContext);
