import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useMemo } from "react";
import { DEFAULT_MATCHED_ORDER_VIEW_STATES } from "../../../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";

// TODO: rename to useInvoiceMatchedOrder after dropping the provider and its context hook
export const useInvoiceMatchedOrderHook = () => {
  const { invoice, loading: loadingInvoice } = useInvoiceVerification();
  const { matchedOrderViewState, autoMatching } = useInvoiceMatchedOrder();
  const { loading: loadingOrgSettings } = useOrgSettings();

  const isLoading = useMemo(() => {
    return (
      !matchedOrderViewState ||
      (loadingInvoice && !invoice) ||
      (!invoice?.poExists && loadingOrgSettings) ||
      autoMatching
    );
  }, [
    loadingInvoice,
    invoice,
    loadingOrgSettings,
    autoMatching,
    matchedOrderViewState,
  ]);

  const isDefaultMatchedOrderViewState = useMemo(
    () =>
      matchedOrderViewState &&
      DEFAULT_MATCHED_ORDER_VIEW_STATES.includes(matchedOrderViewState),
    [matchedOrderViewState],
  );

  return {
    isLoading,
    isDefaultMatchedOrderViewState,
  };
};
