import { useUsers } from "@/common/hooks/useUsers";
import { useUser } from "@/common/providers/UserProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import { QueryInvoicesFilter } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

export enum KickbackFilter {
  ANYONE = "anyone-filter",
}

export type KickbackInvoiceFilters = Pick<
  QueryInvoicesFilter,
  "kickbackResolved" | "kickbackByUserIds" | "kickbackToUserIds" | "hasKickback"
>;

export type KickbackFilterOption = {
  id: string;
  label: string;
};
const getUsersForKickbackFilter = (kickbackTo: string[] | null) => {
  if (
    !kickbackTo ||
    !kickbackTo.length ||
    kickbackTo[kickbackTo.length - 1] === KickbackFilter.ANYONE
  ) {
    return undefined;
  }
  return kickbackTo.filter((option) => option !== KickbackFilter.ANYONE);
};

export const useKickbackFilters = ({
  filter,
  setFilter,
}: {
  filter: KickbackInvoiceFilters | undefined;
  setFilter: (filter: KickbackInvoiceFilters) => void;
}) => {
  const intl = useIntl();
  const { viewer } = useUser();
  const { users, loading } = useUsers();

  const filteredUsers = useMemo(
    () => users?.filter((u) => u.id !== viewer?.id) || [],
    [users, viewer],
  );

  const kickbackOptions: KickbackFilterOption[] = useMemo(() => {
    const options = [
      {
        label: intl.$t({ id: "ANYONE" }),
        id: KickbackFilter.ANYONE as string,
      },
    ];
    if (viewer) {
      options.push({ label: intl.$t({ id: "MYSELF" }), id: viewer.id });
    }
    options.push(
      ...filteredUsers.map((user) => ({
        id: user.id,
        label: getUserName(user),
      })),
    );
    return options;
  }, [intl, viewer, filteredUsers]);

  const kickbackByFilter = useMemo(() => {
    if (
      filter?.kickbackByUserIds === undefined ||
      (Array.isArray(filter?.kickbackByUserIds) &&
        filter.kickbackByUserIds.length === 0)
    ) {
      return [KickbackFilter.ANYONE];
    }
    return filter.kickbackByUserIds;
  }, [filter?.kickbackByUserIds]);

  const kickbackToFilter = useMemo(() => {
    if (
      filter?.kickbackToUserIds === undefined ||
      (Array.isArray(filter?.kickbackToUserIds) &&
        filter.kickbackToUserIds.length === 0)
    ) {
      return [KickbackFilter.ANYONE];
    }
    return filter.kickbackToUserIds;
  }, [filter?.kickbackToUserIds]);

  const handleKickbackByChange = useCallback(
    (kickbackBy: string[] | null) => {
      setFilter({
        ...filter,
        kickbackByUserIds: getUsersForKickbackFilter(kickbackBy),
      });
    },
    [filter, setFilter],
  );

  const handleKickbackToChange = useCallback(
    (kickbackTo: string[] | null) => {
      setFilter({
        ...filter,
        kickbackToUserIds: getUsersForKickbackFilter(kickbackTo),
      });
    },
    [filter, setFilter],
  );

  return {
    kickbackOptions,
    handleKickbackToChange,
    loading,
    handleKickbackByChange,
    kickbackToFilter,
    kickbackByFilter,
  };
};
