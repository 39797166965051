import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { InvoiceCreateReleaseFormValues } from "../../matched-order/components/InvoiceVerificationForm";

type Values = Pick<
  InvoiceCreateReleaseFormValues,
  "projectId" | "vendorId" | "fulfillmentLocationId" | "orderDate"
>;

export const useInvoiceFieldValidation = () => {
  const intl = useIntl();
  const { setWarningAlert } = useSnackbar();

  const validate = useCallback(
    (values: Values) => {
      const fields: Array<{
        key: keyof Values;
        messageId: string;
      }> = [
        { key: "projectId", messageId: "INVOICE_FIELD_VALIDATION_PROJECT" },
        { key: "vendorId", messageId: "INVOICE_FIELD_VALIDATION_VENDOR" },
        {
          key: "fulfillmentLocationId",
          messageId: "INVOICE_FIELD_VALIDATION_FULFILLMENT_LOCATION",
        },
        { key: "orderDate", messageId: "INVOICE_FIELD_VALIDATION_ORDER_DATE" },
      ];

      for (const field of fields) {
        if (!values[field.key]) {
          setWarningAlert(intl.$t({ id: field.messageId }));
          return false;
        }
      }

      return true;
    },
    [setWarningAlert, intl],
  );

  return { validate };
};
