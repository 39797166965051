import { If } from "@/common/components/if/If";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { ProjectReportZoneFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { SpendingReportValueType } from "../../SpendingReportListItem.configuration";
import {
  TotalContainer,
  ValueWithTax,
} from "../cost-codes-totals/CostCodeTotal.Styles";
import { CurrencyDisplay } from "../CurrencyDisplay";

type Props = {
  item: ProjectReportZoneFieldsFragment | null | undefined;
  valueType: SpendingReportValueType;
};

export const ZoneOrderedInvoiced: FC<Props> = ({ item, valueType }) => {
  const { formatCurrency } = useFormatNumberToCurrency();

  if (!item) {
    return null;
  }
  return (
    <TotalContainer>
      <CurrencyDisplay
        value={
          (valueType === SpendingReportValueType.ORDERED
            ? item?.orderedTotal
            : item?.invoicedTotal) ?? "0.00"
        }
      />
      <If
        isTrue={
          valueType === SpendingReportValueType.ORDERED
            ? item?.orderedTotal &&
              item?.ordered &&
              item?.orderedTotal !== item?.ordered
            : item?.invoicedTotal &&
              item.invoiced &&
              item?.invoicedTotal !== item.invoiced
        }
      >
        <FormattedMessage
          id="VALUE_TAX_AND_FEES"
          tagName={ValueWithTax}
          values={{
            value: formatCurrency(
              valueType === SpendingReportValueType.ORDERED
                ? new DecimalSafe(item?.orderedTotal ?? 0).minus(
                    item?.ordered ?? 0,
                  )
                : new DecimalSafe(item?.invoicedTotal ?? 0).minus(
                    item?.invoiced ?? 0,
                  ),
            ),
          }}
        />
      </If>
    </TotalContainer>
  );
};
