import { AdditionalCharges } from "@/common/components/additional-charges/AdditionalCharges";
import { AdditionalChargesContainer } from "@/common/components/additional-charges/AdditionalCharges.styles";
import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { SuccessIcon } from "@/common/components/dialog-icons/SuccessIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { PaymentTerms } from "@/common/components/payment-terms/PaymentTerms";
import { Price } from "@/common/components/price/Price";
import { Footer } from "@/distributor/common/components/Footer";
import { QuoteStatus, RfqStatus, UpdateChargeInput } from "@/generated/graphql";
import { NoFunctionPromise } from "@/types/NoFunction";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { checkQuoteStatus } from "../../../../../common/utils/status-checks/checkQuoteStatus";
import {
  DISTRIBUTOR_BULK_UPDATE_STATUSES,
  DISTRIBUTOR_QUOTE_READ_ONLY_STATUSES,
} from "../../../../common/constants";
import { useTotalPrices } from "../../../../common/hooks/useTotalPrices";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";
import { useQuoteActions } from "../../providers/QuoteActionsProvider";
import { DistributorQuoteError } from "../../utils/DistributorQuoteErrors";
import { isItemIncluded } from "../../utils/isItemIncluded";
import { DistributorQuoteInlineNotesAndTerms } from "../distributor-quote-notes-and-terms/DistributorQuoteInlineNotesAndTerms";
import { DistributorQuoteDiscounts } from "../terms-and-discounts/DistributorQuoteDiscounts";
import { DistributorQuoteExpiration } from "../terms-and-discounts/DistributorQuoteExpiration";
import { DistributorQuoteOrderRequirements } from "../terms-and-discounts/DistributorQuoteOrderRequirements";

const TotalPriceContainer = tw.div`
  justify-self-end grid grid-cols-auto-auto gap-x-3 gap-y-1 justify-end bg-gray-100 w-fit py-3 px-5 rounded-4xl
`;

const PriceLabel = tw.div`
  text-base lg:text-lg font-medium flex-1 text-right
`;

const PriceValue = tw.div`
  text-base lg:text-lg font-medium flex-1 text-right pr-3 lg:pr-0
`;

const ButtonsContainerStyled = tw(ButtonsContainer)`
  justify-end text-sm md:text-base gap-2
`;

const TotalPriceOuterContainer = tw.div`flex justify-end`;

export const DistributorQuoteFooter = () => {
  const {
    quote,
    updateQuoteBulk,
    showValidationError,
    removeValidations,
    updateQuote,
  } = useDistributorQuote();
  const { submitBid, rejectRfq, isSubmitting } = useQuoteActions();
  const { openDialog } = useDialog();
  const intl = useIntl();
  const { discountPrice, totalPriceWithDiscount } = useTotalPrices(quote);

  const submissionErrors = useMemo(() => {
    const items =
      quote?.itemGroups.map((i) => ({ ...i, id: i.rfqItem.id })) || [];
    if (
      items
        .filter(isItemIncluded)
        .some((i) => i.quoteItems.some((qi) => !qi.quantityDecimal)) ||
      quote?.auxiliaryItems.some((i) => !i.quantityDecimal)
    ) {
      return DistributorQuoteError.IncludedQuotesMissingQuantity;
    }
    if (
      items.some(
        (i) =>
          (i.quoteItems[0]?.status !== QuoteStatus.Withdrawn &&
            i.quoteItems.some(
              (qi) => !!qi.quantityDecimal && qi.unitPrice === null,
            )) ||
          i.quoteItems.some(
            (qi) => qi.quantityDecimal === null && qi.unitPrice === null,
          ),
      ) ||
      quote?.auxiliaryItems.some((q) => q.unitPrice === null)
    ) {
      return DistributorQuoteError.IncludedQuotesMissingPrice;
    }
    if (
      items
        .filter(isItemIncluded)
        .every(
          (i) =>
            i.quoteItems[0]?.status === QuoteStatus.Withdrawn ||
            i.quoteItems.every((qi) => !qi.quantityDecimal),
        )
    ) {
      return DistributorQuoteError.NoQuoteItemsIncluded;
    }
    if (
      items
        .filter(isItemIncluded)
        .some(
          (i) =>
            i.quoteItems.length > 1 &&
            i.quoteItems.some((qi) => !qi.description),
        ) ||
      quote?.auxiliaryItems.some((i) => !i.description)
    ) {
      return DistributorQuoteError.MissingDescription;
    }
    if (quote?.auxiliaryItems.some((i) => !i.uom)) {
      return DistributorQuoteError.NoUomIncludedForAuxiliaryItem;
    }
    return null;
  }, [quote?.auxiliaryItems, quote?.itemGroups]);

  const readonly = useMemo(
    () =>
      !!quote?.nextRevision ||
      checkQuoteStatus(quote, DISTRIBUTOR_QUOTE_READ_ONLY_STATUSES),
    [quote],
  );

  if (!quote) {
    return null;
  }

  const submit = async () => {
    if (submissionErrors) {
      showValidationError(submissionErrors);
      return;
    }

    let successfullySubmitted;
    if (checkQuoteStatus(quote, DISTRIBUTOR_BULK_UPDATE_STATUSES)) {
      successfullySubmitted = await updateQuoteBulk();
    }
    if (quote.status !== QuoteStatus.Submitted) {
      successfullySubmitted = await submitBid();
    }

    if (successfullySubmitted) {
      openDialog({
        cancelButtonText: null,
        confirmButtonText: intl.$t({ id: "CLOSE" }),
        title: intl.$t({ id: "SUCCESS" }),
        text: intl.$t({ id: "QUOTE_SUBMIT_SUCCESS" }),
        handleConfirm: NoFunctionPromise,
        icon: <SuccessIcon />,
        fullScreenCentered: true,
      });
      removeValidations();
    }
  };

  const withdrawRfq = async () => {
    const successfullyWithdrawn = await rejectRfq();
    if (successfullyWithdrawn) {
      openDialog({
        cancelButtonText: null,
        confirmButtonText: intl.$t({ id: "CLOSE" }),
        title: intl.$t({ id: "SUCCESS" }),
        text: intl.$t({ id: "QUOTE_WITHDRAW_SUCCESS" }),
        handleConfirm: NoFunctionPromise,
        icon: <SuccessIcon />,
        fullScreenCentered: true,
      });
    }
  };

  return (
    <Footer className="mt-10 flex flex-col gap-4">
      <Instructions
        instruction={quote.rfq.instructions}
        projectId={quote.rfq.project.id}
      />
      <DistributorQuoteInlineNotesAndTerms quote={quote} />
      <AdditionalChargesContainer>
        <AdditionalCharges
          additionalCharges={quote.additionalCharges}
          readonly={readonly}
          updateAdditionalCharges={(additionalCharges: UpdateChargeInput[]) =>
            updateQuote({ additionalCharges })
          }
        />
        <PaymentTerms
          item={quote}
          readonly={readonly}
          onChange={(paymentTerm) => {
            updateQuote({
              paymentTermDays: Number(paymentTerm),
            });
          }}
        />
        <DistributorQuoteExpiration
          expirationDate={quote.expirationDate}
          updateItem={(expirationDate: number) => {
            updateQuote({ expirationDate });
          }}
          readonly={readonly}
        />
      </AdditionalChargesContainer>
      <DistributorQuoteDiscounts readonly={readonly} />
      <TotalPriceOuterContainer>
        <TotalPriceContainer>
          <If isTrue={discountPrice}>
            <PriceLabel>
              <FormattedMessage id="DISTRIBUTOR_QUOTE_TOTAL_DISCOUNT" />
            </PriceLabel>
            <PriceValue>
              <Price price={discountPrice} negative maximumFractionDigits={2} />
            </PriceValue>
          </If>
          <PriceLabel>
            <FormattedMessage id="DISTRIBUTOR_QUOTE_TOTAL_QUOTE" />
          </PriceLabel>
          <PriceValue>
            <Price price={totalPriceWithDiscount} maximumFractionDigits={2} />
          </PriceValue>
        </TotalPriceContainer>
      </TotalPriceOuterContainer>
      <If
        isTrue={
          quote.status !== QuoteStatus.Withdrawn &&
          quote.status !== QuoteStatus.Rejected &&
          quote.status !== QuoteStatus.Accepted &&
          quote.status !== QuoteStatus.CancelledByContractor &&
          quote.rfq?.status !== RfqStatus.Cancelled &&
          !quote.nextRevision
        }
      ></If>
      <FloatingFooter>
        <ButtonsContainerStyled>
          <If
            isTrue={
              quote.status !== QuoteStatus.Withdrawn &&
              quote.status !== QuoteStatus.Rejected &&
              quote.status !== QuoteStatus.Accepted &&
              quote.status !== QuoteStatus.CancelledByContractor &&
              quote.rfq?.status !== RfqStatus.Cancelled &&
              !quote.nextRevision
            }
          >
            <OutlinedButton
              onClick={() =>
                openDialog({
                  cancelButtonText: intl.$t({ id: "CANCEL" }),
                  confirmButtonText: intl.$t({ id: "PROCEED" }),
                  includeWarningIcon: true,
                  title: intl.$t(
                    { id: "CONFIRM_WITHDRAW_QUOTE_QUESTION" },
                    { number: quote?.clientIdentifier },
                  ),
                  text: intl.$t({
                    id: "CONFIRM_WITHDRAW_QUOTE_QUESTION_TEXT",
                  }),
                  handleConfirm: withdrawRfq,
                  closeOnConfirm: false,
                })
              }
            >
              <FormattedMessage id="QUOTE_WITHDRAW" />
            </OutlinedButton>
          </If>
          <If
            isTrue={
              checkQuoteStatus(quote, [
                QuoteStatus.Draft,
                QuoteStatus.Requested,
                QuoteStatus.Submitted,
              ]) && !quote.nextRevision
            }
          >
            <If isTrue={checkQuoteStatus(quote, [QuoteStatus.Submitted])}>
              <PrimaryButton
                onClick={() =>
                  openDialog({
                    cancelButtonText: intl.$t({ id: "CANCEL" }),
                    confirmButtonText: intl.$t({ id: "PROCEED" }),
                    includeWarningIcon: true,
                    title: intl.$t(
                      { id: "DISTRIBUTOR_QUOTE_UPDATE_QUOTE" },
                      { number: quote?.rfq?.clientIdentifier },
                    ),
                    text: intl.$t({
                      id: "CONFIRM_REQUEST_CHANGES_TO_RFQ_DISTRIBUTOR",
                    }),
                    handleConfirm: submit,
                    closeOnConfirm: false,
                  })
                }
                autoFocus
              >
                <FormattedMessage id="QUOTE_UPDATE_QUOTE" />
              </PrimaryButton>
            </If>
          </If>
          <If
            isTrue={checkQuoteStatus(quote, [
              QuoteStatus.Draft,
              QuoteStatus.Requested,
              QuoteStatus.ChangeRequested,
            ])}
          >
            <LoadingButton
              button={PrimaryButton}
              onClick={submit}
              loading={isSubmitting}
              testId="submit-quote"
            >
              <FormattedMessage id="QUOTE_SUBMIT_QUOTE" />
            </LoadingButton>
          </If>
        </ButtonsContainerStyled>
      </FloatingFooter>
      <DistributorQuoteOrderRequirements />
    </Footer>
  );
};
