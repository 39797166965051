import {
  BuyoutFieldsFragment,
  TaxCodeSummaryFieldsFragment,
  UpdateContractorBuyoutInput,
  UpdateVendorBuyoutInput,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  applyInputChanges,
  getBuyoutNetAmount,
  getBuyoutTotal,
} from "./buyoutStoreUtils";

export type UpdateBuyoutNomenclature = {
  taxCodes: TaxCodeSummaryFieldsFragment[];
};

type BuyoutState = {
  buyout: BuyoutFieldsFragment | null | undefined;
  setStoreBuyout: (
    buyout: BuyoutFieldsFragment | null | undefined,
    forceUpdate?: boolean,
  ) => void;
  updateStoreItems: (items: BuyoutFieldsFragment["items"]) => void;
  updateStoreBuyout: (
    buyout:
      | UpdateContractorBuyoutInput
      | Omit<UpdateVendorBuyoutInput, "addedItems">,
    nomenclature: UpdateBuyoutNomenclature,
  ) => void;
  clearBuyout: () => void;
};

const hydrateItems = (
  items: BuyoutFieldsFragment["items"],
  buyout: BuyoutFieldsFragment,
) => {
  return items.map((i) => ({
    ...i,
    isIncluded: buyout?.items.find((bi) => bi.id === i.id)?.isIncluded ?? true,
  }));
};

export const useBuyoutStore = create<BuyoutState>()(
  devtools((set, get) => ({
    buyout: null,
    setStoreBuyout: (buyout, forceUpdate = false) => {
      if (!buyout || (get().buyout?.id === buyout.id && !forceUpdate)) {
        return;
      }
      set({
        buyout: {
          ...buyout,
          netAmount: getBuyoutNetAmount(buyout?.items).toString(),
        },
      });
    },
    updateStoreItems: (items) => {
      const buyout = get().buyout;
      if (!buyout) {
        return;
      }
      const hydratedItems = hydrateItems(items, buyout);
      set((state) => ({
        buyout: {
          ...state.buyout!,
          items: hydratedItems,
          netAmount: getBuyoutNetAmount(hydratedItems).toString(),
          total: getBuyoutTotal({
            ...state.buyout!,
            items: hydratedItems,
          }).toString(),
        },
      }));
    },
    updateStoreBuyout: (updateInput, nomenclature) => {
      const buyout = get().buyout;
      if (!buyout) {
        return;
      }
      const updatedBuyout = applyInputChanges(
        buyout,
        updateInput,
        nomenclature,
      );
      set({
        buyout: {
          ...updatedBuyout,
          netAmount: getBuyoutNetAmount(updatedBuyout.items).toString(),
        },
      });
    },
    clearBuyout: () => {
      set(() => ({ buyout: null }));
    },
  })),
);
