import { If } from "@/common/components/if/If";
import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { MAX_QUANTITY_DECIMALS, SCREEN_BREAKPOINTS } from "@/common/const";
import { useWindowSize } from "@/common/hooks/useWindowResize";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorRelease } from "../providers/DistributorReleaseProvider";

const BackorderContent = tw.div`flex justify-center bg-white text-xs absolute top-6 mx-auto left-0 right-0 text-center text-gray-600 truncate bg-transparent`;
const BackorderText = tw.div`bg-white`;
const ErrorOutlineOutlinedStyled = tw(ErrorOutlineOutlined)`text-red-500`;
const LabelContainer = tw.div`flex items-center justify-evenly gap-2`;
const Container = tw.div`w-full`;

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  error?: boolean;
  readonly?: boolean;
  label?: string | React.JSX.Element;
};

export const DistributorReleaseQuantityInput: FC<Props> = ({
  item,
  readonly,
  label,
}) => {
  const { updateVendorReleaseItem, updatedItem } = useDistributorRelease();
  const intl = useIntl();
  const windowSize = useWindowSize();
  const hideLabel = windowSize.width > SCREEN_BREAKPOINTS.xs;

  const saveQuantity = useCallback(
    async (quantityDecimal: string | null) => {
      await updateVendorReleaseItem({
        quantityDecimal,
        releaseItemId: item.id,
      });
    },
    [item.id, updateVendorReleaseItem],
  );

  const requestedQuantity = useMemo(() => {
    return !item.redelivery
      ? item.requestedQuantity
      : item.redelivery.issue.releaseItem.quantityDecimal;
  }, [item.redelivery, item.requestedQuantity]);

  const backordered = useMemo(() => {
    if (!item.alternativeFulfillmentQuantityDecimal) {
      return;
    }
    return (
      <BackorderContent>
        <FormattedMessage
          id="BACKORDERED_QUANTITY_INFO"
          values={{
            value: intl.formatNumber(
              Number(item.alternativeFulfillmentQuantityDecimal || 0),
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: MAX_QUANTITY_DECIMALS,
              },
            ),
          }}
          tagName={BackorderText}
        />
      </BackorderContent>
    );
  }, [intl, item.alternativeFulfillmentQuantityDecimal]);

  const helperText = useMemo(() => {
    if (requestedQuantity === 0 || backordered) {
      return;
    }

    const isLessThanRequested = !new DecimalSafe(
      requestedQuantity,
    ).lessThanOrEqualTo(item.quantityDecimal);

    return (
      <LabelContainer onMouseOver={(event) => event.stopPropagation()}>
        {intl.$t(
          { id: "REQUESTED_QUANTITY_VALUE" },
          {
            quantity: intl.formatNumber(Number(requestedQuantity), {
              minimumFractionDigits: 2,
              maximumFractionDigits: MAX_QUANTITY_DECIMALS,
            }),
          },
        )}
        <If isTrue={isLessThanRequested}>
          <Tooltip
            id={`alternative-fulfillment-quantity-${item.id}`}
            element={<ErrorOutlineOutlinedStyled />}
          >
            <FormattedMessage id="LESS_THAN_REQUESTED" />
          </Tooltip>
        </If>
      </LabelContainer>
    );
  }, [backordered, intl, item.id, item.quantityDecimal, requestedQuantity]);

  if (!(updatedItem(item)?.isIncluded ?? item.isIncluded)) {
    return null;
  }

  return (
    <Container>
      <QuantityPicker
        quantity={item?.quantityDecimal}
        label={!hideLabel ? label : undefined}
        helperText={helperText}
        id={item.id}
        saveQuantity={saveQuantity}
        hoverLabel={item.uom?.pluralDescription}
        customError={backordered}
        staticText={readonly}
        uom={item.uom}
        className="bg-white"
        includeUomMnemonic={false}
        testId="distributor-release-quantity"
      />
    </Container>
  );
};
