import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { Loader } from "@/common/components/loader/Loader";
import { NoteDocumentPanel } from "@/common/components/note-document-panel/NoteDocumentPanel";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { ReleaseDeliverySlips } from "@/common/components/release-images/ReleaseDeliverySlips";
import { ReleaseReceipts } from "@/contractor/pages/home/release/pages/release-details/ReleaseReceipts";
import { ReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseItemList } from "@/contractor/pages/home/release/release-items-list/ReleaseItemList";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceReleaseEditInfo } from "../invoice-edit-info/InvoiceReleaseEditInfo";
import { InvoiceReleaseItemsFilterHeader } from "./components/invoice-release-items-filter-header/InvoiceReleaseItemsFilterHeader";
import { InvoicesCard } from "./components/invoices-card/InvoicesCard";
import { useInvoiceReleaseItems } from "./hooks/useInvoiceReleaseItems";
import { useInvoiceReleaseItemsTableConfiguration } from "./hooks/useInvoiceReleaseItemsTableConfiguration";

const NotesCard = tw(AssetsCard)`my-[1px] lg:border-0 mt-3 overflow-visible`;
const ReceiptsCard = tw(ReleaseReceipts)`my-[1px] lg:border-0 overflow-visible`;

type Props = {
  readonly?: boolean;
};

export const InvoiceReleaseItems: FC<Props> = () => {
  const { release } = useRelease();
  const intl = useIntl();
  const { getNotesImageDetails } = useInvoiceReleaseItems();
  const { items, tableConfiguration, classNames, itemFn } =
    useInvoiceReleaseItemsTableConfiguration();

  if (!release) {
    return <Loader loading />;
  }

  return (
    <ReleaseItemsZoneProvider items={items}>
      <InvoiceReleaseItemsFilterHeader />
      <ReleaseItemList
        columns={tableConfiguration}
        classNames={classNames}
        itemFn={itemFn}
      />
      <InvoiceReleaseEditInfo />
      <NotesCard
        headerClassName="bg-blue-100"
        title={intl.$t({ id: "NOTES_AND_ATTACHMENTS" })}
        assets={release.instructions?.assets || []}
        expanded={false}
        imageDetails={getNotesImageDetails}
        description={release.description || undefined}
      />
      <QuoteDocumentPanel
        readonly
        quoteDocument={release.quoteDocument}
        quote={release.quote}
      />
      <NoteDocumentPanel readonly noteDocument={release.noteDocument} />
      <ReceiptsCard release={release} prePaid={false} />
      <ReleaseDeliverySlips
        release={release}
        deliverySlips={release.deliverySlips}
      />
      <InvoicesCard expanded />
    </ReleaseItemsZoneProvider>
  );
};
