import { generateUUID } from "@/common/utils/uuidUtils";
import { AdditionalChargesFieldsFragment } from "@/generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AdditionalChargeProps } from "../types/AdditionalChargeProps";

export const useAdditionalCharge = ({
  charge,
  onChange,
  readonly,
}: Pick<AdditionalChargeProps, "onChange" | "charge" | "readonly">) => {
  const [additionalCharge, setAdditionalCharge] =
    useState<AdditionalChargesFieldsFragment>(charge);

  useEffect(() => {
    setAdditionalCharge(charge);
  }, [charge]);

  const update = useCallback(
    (updatedCharge: AdditionalChargesFieldsFragment, triggerChange = true) => {
      setAdditionalCharge(updatedCharge);
      if (
        triggerChange &&
        Number(updatedCharge.amount) >= 0 &&
        updatedCharge.description !== undefined
      ) {
        onChange({
          ...updatedCharge,
          id: updatedCharge.id || generateUUID(),
        });
      }
    },
    [onChange],
  );

  const updateDescription = useCallback(
    (event: React.FocusEvent<HTMLInputElement>, triggerChange?: boolean) => {
      update(
        {
          ...additionalCharge,
          description: event.target.value,
        },
        triggerChange,
      );
    },
    [additionalCharge, update],
  );

  const updateAmount = useCallback(
    (value: string | null) => {
      update({
        ...additionalCharge,
        amount: value || "0",
      });
    },
    [additionalCharge, update],
  );

  const labelClassName = useMemo(
    () =>
      `text-sm text-right bg-white min-w-40 ${
        readonly && "!bg-transparent italic"
      }`,
    [readonly],
  );

  const inputClassName = useMemo(
    () =>
      `bg-white text-right ${
        readonly && "justify-end bg-transparent text-sm font-normal"
      }`,
    [readonly],
  );

  const classes = useMemo(
    () => ({
      label: labelClassName,
      input: inputClassName,
    }),
    [labelClassName, inputClassName],
  );

  return {
    additionalCharge,
    updateDescription,
    updateAmount,
    classes,
  };
};
