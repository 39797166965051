import { LinkChunks } from "@/common/components/link-chunks/LinkChunks";
import { routes } from "@/config/routes";
import {
  BuyoutFieldsFragment,
  PoMaterialConversionFieldsFragment,
  PoValidationError,
  PoValidationFieldsFragment,
  PoValidationWarning,
  ReleaseSummaryFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import { ReactNode, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PrimaryButtonStyled } from "../../release/components/connections/Connections.styles";
import { AdminUsersInfo } from "../../release/components/connections/components/common/AdminUsersInfo";
import { ValidationType } from "../../release/components/connections/components/common/ExportErrorList";
import { MissingBudgetCombinationType } from "../../release/components/connections/providers/MissingBudgetCombinationsProvider";
import { MaterialType } from "../../release/components/connections/providers/MissingItemsFromInventoryProvider";

type Input = {
  poValidation: PoValidationFieldsFragment | undefined;
  sourceSystem: SourceSystem | undefined;
  openMissingConversionsModal: (args: {
    sourceSystem?: SourceSystem;
    conversions: PoMaterialConversionFieldsFragment[];
  }) => void;
  openMissingItemsModal: (args: {
    sourceSystem: SourceSystem;
    materials: MaterialType[];
  }) => void;
  openMissingBudgetCombinationsModal: (args: {
    sourceSystem?: SourceSystem;
    missingBudgetCombinations: MissingBudgetCombinationType[];
    projectId: string | undefined;
  }) => void;
} & (
  | { type: "Release"; item: ReleaseSummaryFieldsFragment }
  | {
      type: "Buyout";
      item: BuyoutFieldsFragment;
    }
);

export const usePoValidationMappings = ({
  type,
  item,
  poValidation,
  sourceSystem,
  openMissingConversionsModal,
  openMissingItemsModal,
  openMissingBudgetCombinationsModal,
}: Input) => {
  const intl = useIntl();

  return useMemo(() => {
    if (!poValidation) {
      return [];
    }
    const integration = intl.$t({ id: `INTEGRATION_${sourceSystem}` });
    return [
      {
        id: "INTEGRATION_NOT_CONFIGURED",
        condition: poValidation?.errors.includes(
          PoValidationError.IntegrationNotConfigured,
        ),
        key: "EXPORT_PO_HOSTED_INTEGRATION_NOT_CONFIGURED",
        toolTipKey: "EXPORT_PO_HOSTED_INTEGRATION_NOT_CONFIGURED_TOOLTIP",
        adminToolTipKey:
          "EXPORT_PO_HOSTED_INTEGRATION_NOT_CONFIGURED_TOOLTIP_ADMIN",
        values: {
          admin: () => <AdminUsersInfo className="text-white underline" />,
          sub1: (chunks: ReactNode) => (
            <LinkChunks chunks={chunks} route={routes.connections} />
          ),
          integration,
        },
      },
      {
        id: "VENDOR_NOT_CONNECTED",
        condition: poValidation?.errors.includes(
          PoValidationError.VendorNotConnected,
        ),
        key: "EXPORT_PO_HOSTED_VENDOR_NOT_CONNECTED",
        toolTipKey: "EXPORT_PO_HOSTED_VENDOR_NOT_CONNECTED_TOOLTIP",
        adminToolTipKey: "EXPORT_PO_HOSTED_VENDOR_NOT_CONNECTED_ADMIN_TOOLTIP",
        values: {
          admin: () => <AdminUsersInfo className="text-white underline" />,
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.vendorsWithId}
              routeParams={{ vendorId: item.preferredVendor?.id || "" }}
            />
          ),
          vendor: item.sellerOrgLocation?.org.name,
          integration,
        },
      },
      {
        id: "PROJECT_NOT_CONNECTED",
        condition: poValidation?.errors.includes(
          PoValidationError.ProjectNotConnected,
        ),
        key: "EXPORT_PO_HOSTED_PROJECT_NOT_CONNECTED",
        toolTipKey: "EXPORT_PO_HOSTED_PROJECT_NOT_CONNECTED_TOOLTIP",
        values: {
          projectName: item.project?.name,
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={routes.projectDetails}
              routeParams={{ id: item.project?.id ?? "" }}
            />
          ),
          integration,
        },
      },
      {
        id: "VENDOR_MISMATCH",
        condition: poValidation?.errors.includes(
          PoValidationError.VendorMismatch,
        ),
        key: "EXPORT_PO_HOSTED_VENDOR_MISMATCH",
        toolTipKey: "EXPORT_PO_HOSTED_VENDOR_MISMATCH_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "EXPORT_PO_HOSTED_PROJECT_MISMATCH",
        condition: poValidation?.errors.includes(
          PoValidationError.ProjectMismatch,
        ),
        key: "EXPORT_PO_HOSTED_PROJECT_MISMATCH",
        toolTipKey: "EXPORT_PO_HOSTED_PROJECT_MISMATCH_TOOLTIP",
        values: {
          integration,
        },
      },
      {
        id: "TAX_CODE_MISSING",
        condition: poValidation?.errors.includes(
          PoValidationError.TaxCodeMissing,
        ),
        key: "EXPORT_PO_TAX_CODE_MISSING",
        toolTipKey: "EXPORT_PO_TAX_CODE_MISSING_TOOLTIP",
      },
      {
        id: "TAX_CODE_MISMATCH",
        condition: poValidation?.errors.includes(
          PoValidationError.TaxCodeMismatch,
        ),
        key: "EXPORT_PO_TAX_CODE_MISMATCH",
        toolTipKey: "EXPORT_PO_TAX_CODE_MISMATCH_TOOLTIP",
      },
      {
        id: "PO_IMMUTABLE",
        condition: poValidation?.errors.includes(PoValidationError.PoImmutable),
        key: "EXPORT_PO_HOSTED_PO_IMMUTABLE",
        toolTipKey: "EXPORT_PO_HOSTED_PO_IMMUTABLE_TOOLTIP",
      },
      {
        id: "PO_INVALID",
        condition: poValidation?.errors.includes(
          PoValidationError.PoNumberInvalid,
        ),
        key: "EXPORT_PO_HOSTED_PO_INVALID",
        toolTipKey: "EXPORT_PO_HOSTED_PO_INVALID_TOOLTIP",
      },
      {
        id: "PO_TOO_LONG",
        condition: poValidation?.errors.includes(
          PoValidationError.PoNumberTooLong,
        ),
        key: "EXPORT_PO_HOSTED_PO_TOO_LONG",
        toolTipKey: "EXPORT_PO_HOSTED_PO_TOO_LONG_TOOLTIP",
      },
      {
        id: "FORMAT_MISMATCH",
        condition: poValidation?.errors.includes(
          PoValidationError.FormatMismatch,
        ),
        key: "EXPORT_PO_HOSTED_FORMAT_MISMATCH",
        toolTipKey: "EXPORT_PO_HOSTED_FORMAT_MISMATCH_TOOLTIP",
      },
      {
        id:
          type === "Release"
            ? "EXPORT_RELEASE_ERROR_MISSING_PRICES"
            : "EXPORT_BUYOUT_ERROR_MISSING_PRICES",
        condition: poValidation.itemsMissingPrices.length > 0,
        key:
          type === "Release"
            ? "EXPORT_RELEASE_ERROR_MISSING_PRICES"
            : "EXPORT_BUYOUT_ERROR_MISSING_PRICES",
        toolTipKey:
          type === "Release"
            ? "EXPORT_RELEASE_ERROR_MISSING_PRICES_TOOLTIP"
            : "EXPORT_BUYOUT_ERROR_MISSING_PRICES_TOOLTIP",
        values: {
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={type === "Release" ? routes.delivery : routes.buyout}
              routeParams={
                type === "Release" ? { deliveryId: item.id } : { id: item.id }
              }
            />
          ),
          releaseNumber: type === "Release" ? item.sequenceNumber : undefined,
          buyoutNumber: type === "Buyout" ? item.clientIdentifier : undefined,
        },
      },
      {
        id:
          type === "Release"
            ? "EXPORT_RELEASE_ERROR_MISSING_ITEMS"
            : "EXPORT_BUYOUT_ERROR_MISSING_ITEMS",
        condition: poValidation.itemsMissingCostCodes.length > 0,
        key:
          type === "Release"
            ? "EXPORT_RELEASE_ERROR_MISSING_ITEMS"
            : "EXPORT_BUYOUT_ERROR_MISSING_ITEMS",
        toolTipKey:
          type === "Release"
            ? "EXPORT_RELEASE_ERROR_MISSING_ITEMS_TOOLTIP"
            : "EXPORT_BUYOUT_ERROR_MISSING_ITEMS_TOOLTIP",
        values: {
          sub: (chunks: ReactNode) => (
            <LinkChunks
              chunks={chunks}
              route={type === "Release" ? routes.delivery : routes.buyout}
              routeParams={
                type === "Release" ? { deliveryId: item.id } : { id: item.id }
              }
            />
          ),
          releaseNumber: type === "Release" ? item.sequenceNumber : undefined,
          buyoutNumber: type === "Buyout" ? item.clientIdentifier : undefined,
        },
      },
      {
        id: "EXPORT_PO_COST_CODES_NOT_CONNECTED",
        condition: poValidation.costCodesNotConnected.length > 0,
        key: "EXPORT_PO_COST_CODES_NOT_CONNECTED",
        adminToolTipKey: "EXPORT_PO_COST_CODES_NOT_CONNECTED_ADMIN_TOOLTIP",
        toolTipKey: "EXPORT_PO_COST_CODES_NOT_CONNECTED_TOOLTIP",
        values: {
          integration,
          sub: (chunks: ReactNode) => (
            <LinkChunks chunks={chunks} route={routes.costStructure} />
          ),
        },
      },
      {
        id: "EXPORT_PO_MISSING_ORG_MATERIALS",
        condition: poValidation?.materialsNotConnected.length > 0,
        key: "EXPORT_PO_MISSING_ORG_MATERIALS",
        toolTipKey: "EXPORT_PO_MISSING_ORG_MATERIALS_TOOLTIP",
        values: {
          value: poValidation?.materialsNotConnected.length,
          integration,
        },
        button: (
          <PrimaryButtonStyled
            onClick={() =>
              sourceSystem &&
              openMissingItemsModal({
                sourceSystem,
                materials: poValidation.materialsNotConnected,
              })
            }
          >
            <FormattedMessage id="CONNECT" />
          </PrimaryButtonStyled>
        ),
      },
      {
        id: "EXPORT_PO_MISSING_UOM_CONVERSIONS",
        condition:
          (poValidation?.materialConversions || []).filter(
            (c) => !c.quantityMultiplier,
          ).length > 0,
        key: "EXPORT_PO_MISSING_UOM_CONVERSIONS",
        toolTipKey: "EXPORT_PO_MISSING_UOM_CONVERSIONS_TOOLTIP",
        values: {
          value: poValidation?.materialConversions.filter(
            (c) => !c.quantityMultiplier,
          ).length,
          integration,
        },
        button: (
          <PrimaryButtonStyled
            onClick={() =>
              openMissingConversionsModal({
                sourceSystem,
                conversions: poValidation.materialConversions,
              })
            }
          >
            <FormattedMessage id="SET_CONVERSION" />
          </PrimaryButtonStyled>
        ),
      },
      {
        id: "EXPORT_ITEMS_MISSING_TAGS",
        condition: poValidation?.itemsMissingTags.length > 0,
        key: "EXPORT_ITEMS_MISSING_TAGS",
        toolTipKey:
          type === "Release"
            ? "EXPORT_ITEMS_MISSING_TAGS_TOOLTIP"
            : "EXPORT_BUYOUT_ITEMS_MISSING_TAGS_TOOLTIP",
        values: {
          values: poValidation?.itemsMissingTags
            .map((item) => item.orgMaterial?.material.name)
            .join(", "),
          releaseNumber: type === "Release" ? item.sequenceNumber : undefined,
          buyoutNumber: type === "Buyout" ? item.clientIdentifier : undefined,
        },
      },
      {
        id: "EXISTING_CONVERSIONS_WITH_NUMBER",
        condition:
          poValidation.materialConversions.length > 0 &&
          (poValidation?.materialConversions || []).filter(
            (c) => !c.quantityMultiplier,
          ).length === 0,
        key: "EXISTING_CONVERSIONS_WITH_NUMBER",
        values: {
          value: (poValidation?.materialConversions || []).filter(
            (c) => c.quantityMultiplier,
          ).length,
          integration,
        },
        hideInfoIcon: true,
        validationType: ValidationType.Warning,
        button: (
          <PrimaryButtonStyled
            onClick={() =>
              openMissingConversionsModal({
                sourceSystem,
                conversions: poValidation.materialConversions.filter(
                  (c) => c.quantityMultiplier,
                ),
              })
            }
          >
            <FormattedMessage id="EDIT" />
          </PrimaryButtonStyled>
        ),
      },
      {
        id: "EXPORT_CHANGE_ORDER",
        condition: poValidation?.warnings.includes(
          PoValidationWarning.ChangeOrder,
        ),
        key: "EXPORT_CHANGE_ORDER",
        toolTipKey: "EXPORT_CHANGE_ORDER_TOOLTIP",
        validationType: ValidationType.Warning,
      },
      {
        id: "RELEASE_TYPE_PO_FORMAT_NOT_SUPPORTED",
        condition: poValidation?.errors.includes(
          PoValidationError.ReleaseTypePoFormatNotSupported,
        ),
        key: "RELEASE_TYPE_PO_FORMAT_NOT_SUPPORTED",
        toolTipKey: "RELEASE_TYPE_PO_FORMAT_NOT_SUPPORTED_TOOLTIP",
        validationType: ValidationType.Error,
      },
      {
        id: "TAX_CODE_NOT_CONNECTED",
        condition: poValidation?.errors.includes(
          PoValidationError.TaxCodeNotConnected,
        ),
        key: "TAX_CODE_NOT_CONNECTED",
        values: {
          integration,
          sub: (chunks: ReactNode) => (
            <LinkChunks chunks={chunks} route={routes.taxCodes} />
          ),
        },
        toolTipKey: "TAX_CODE_NOT_CONNECTED_TOOLTIP",
        validationType: ValidationType.Error,
      },
      {
        id: "TAX_CODE_NOT_ASSIGNED",
        condition: poValidation?.errors.includes(
          PoValidationError.TaxCodeNotAssigned,
        ),
        key: "TAX_CODE_NOT_ASSIGNED",
        toolTipKey: "TAX_CODE_NOT_ASSIGNED_TOOLTIP",
        validationType: ValidationType.Error,
      },
      {
        id: "MISSING_BUDGET_COMBINATIONS",
        condition: poValidation.missingBudgetCombinations.length > 0,
        key: "MISSING_BUDGET_COMBINATIONS",
        values: {
          count: (poValidation?.missingBudgetCombinations || []).length,
          integration,
        },
        hideInfoIcon: true,
        button: (
          <PrimaryButtonStyled
            onClick={() =>
              openMissingBudgetCombinationsModal({
                sourceSystem,
                missingBudgetCombinations:
                  poValidation.missingBudgetCombinations.map(
                    (combination, key) => ({
                      ...combination,
                      id: (key + 1).toString(),
                    }),
                  ),
                projectId: item.project?.id,
              })
            }
          >
            <FormattedMessage id="VIEW" />
          </PrimaryButtonStyled>
        ),
      },
      {
        id: "PROJECT_MISMATCH",
        condition: poValidation?.errors.includes(
          PoValidationError.ProjectMismatch,
        ),
        key: "PROJECT_MISMATCH",
        toolTipKey: "PROJECT_MISMATCH_TOOLTIP",
        validationType: ValidationType.Error,
        values: {
          integration,
          projectName: item.project?.name,
        },
      },
      {
        id: "ALL_ITEMS_NON_TAXABLE",
        condition: poValidation?.errors.includes(
          PoValidationError.AllItemsNonTaxable,
        ),
        key: "ALL_ITEMS_NON_TAXABLE",
        toolTipKey: "ALL_ITEMS_NON_TAXABLE_TOOLTIP",
        validationType: ValidationType.Error,
      },
    ];
  }, [
    poValidation,
    intl,
    sourceSystem,
    item,
    type,
    openMissingItemsModal,
    openMissingConversionsModal,
    openMissingBudgetCombinationsModal,
  ]);
};
