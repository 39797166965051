import { CustomPaymentTerms } from "@/common/components/terms-and-discounts/CustomPaymentTerms";
import { UpdateOrgInput } from "@/generated/graphql";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

export const OrgPaymentTerms = () => {
  const { watch, setValue } = useFormContext<UpdateOrgInput>();
  const defaultPaymentTerm = watch("settings.invoices.defaultPaymentTerm");

  const setPaymentTerm = useCallback(
    (value: string | null) => {
      setValue(
        "settings.invoices.defaultPaymentTerm",
        value ? Number(value) : undefined,
      );
      setValue("settings.invoices.clearDefaultPaymentTerm", !value);
    },
    [setValue],
  );

  return (
    <CustomPaymentTerms
      paymentTerm={defaultPaymentTerm?.toString()}
      updateItem={setPaymentTerm}
      hideLabel
    />
  );
};
