import { SourceSystemFeatureRequirement } from "@/common/components/integration-feature-requirement/SourceSystemFeatureRequirement";
import { Row } from "@/common/components/integration-options/IntegrationOptions.styles";
import { DatePicker } from "@/common/components/picker/components/DatePicker";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { FormattedMessage } from "react-intl";
import { useExportInvoice } from "../../../providers/ExportInvoiceProvider";

export const InvoiceConnectionAccountingDate = () => {
  const { accountingDate, setAccountingDate } = useExportInvoice();
  return (
    <SourceSystemFeatureRequirement
      feature={IntegrationFeature.InvoiceAccountingDate}
    >
      <Row>
        <FormattedMessage id="ACCOUNTING_DATE" />
        <DatePicker
          date={accountingDate}
          onChange={setAccountingDate}
          className="bg-white"
        />
      </Row>
    </SourceSystemFeatureRequirement>
  );
};
