import { useAuthentication } from "@/common/hooks/useAuthentication";
import { useUser } from "@/common/providers/UserProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import { routes } from "@/config/routes";
import { Menu, MenuItem } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import {
  ButtonStyled,
  Container,
  ExpandMoreStyled,
  UserAvatarStyled,
  UserFullName,
} from "./UserMenu.styles";

export const ContractorUserMenu = () => {
  const { viewer } = useUser();
  const navigate = useNavigate();
  const { logout } = useAuthentication();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleYourAccount = useCallback(() => {
    handleClose();
    navigate(routes.me);
  }, [navigate, handleClose]);

  const handleNotifications = useCallback(() => {
    handleClose();
    navigate(routes.userNotifications);
  }, [navigate, handleClose]);

  const options = useMemo(() => {
    return [
      {
        label: "USER_YOUR_ACCOUNT",
        onClick: handleYourAccount,
      },
      {
        label: "NOTIFICATIONS",
        onClick: handleNotifications,
      },
      {
        label: "HEADER_LOGOUT",
        onClick: () => logout(),
      },
    ];
  }, [handleNotifications, handleYourAccount, logout]);

  return (
    <Container>
      <ButtonStyled id="user-menu-button" color="inherit" onClick={openMenu}>
        <UserAvatarStyled
          photoUrl={viewer?.photoUrl}
          firstName={viewer?.firstName}
          lastName={viewer?.lastName}
          email={viewer?.email}
        />
      </ButtonStyled>
      <ButtonStyled id="user-menu-button" color="inherit" onClick={openMenu}>
        <UserFullName>{getUserName(viewer)}</UserFullName>
        <ExpandMoreStyled />
      </ButtonStyled>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "user-menu-button",
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.label} onClick={option.onClick}>
            <FormattedMessage id={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};
