import { ImportableItem } from "@/common/components/import-external-po/types/ImportableItem";
import { Instructions } from "@/common/components/instructions/Instructions";
import {
  ReleaseAdditionalChargesInputFormContext,
  useReleaseAdditionalChargesInput,
} from "@/common/components/release-additional-charges-and-taxes/hooks/useReleaseAdditionalChargesInput";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useInstruction } from "@/common/hooks/useInsruction";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useInvoiceImportExternalPO } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoiceImportExternalPO";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { ReleaseDescription } from "@/contractor/pages/home/release/components/ReleaseDescription";
import { useFormPriceCalculation } from "@/contractor/pages/home/release/hooks/useFormPriceCalculation";
import { usePrefillDefaultTaxable } from "@/contractor/pages/home/release/hooks/usePrefillDefaultTaxable";
import { TotalProps } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AdditionalChargesFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useDeliverySlipImportExternalPO } from "../../../../hooks/useDeliverySlipImportExternalPO";
import { useDeliverySlipRelease } from "../../../../providers/DeliverySlipReleaseProvider";
import { useDeliverySlipVerification } from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipReleaseFormValues } from "../delivery-slip-form/DeliverySlipVerificationForm";
import { useDeliverySlipReleaseSpreadsheetConfig } from "./DeliverySlipRelease.config";
import { DeliverySlipCreateReleaseHeader } from "./DeliverySlipReleaseHeader";
import { DeliverySlipReleasePhotosPanel } from "./DeliverySlipReleasePhotosPanel";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;
const Footer = tw.div`mr-7 mt-10 w-full pb-5`;

export const DeliverySlipRelease: FC = () => {
  const { release, loading: releaseLoading } = useRelease();
  const { deliverySlip } = useDeliverySlipVerification();
  const { loading, setDeliveryPhotos } = useDeliverySlipRelease();
  const { importedItems, importedTaxCodeId, importedPoIsImmutable } =
    useDeliverySlipImportExternalPO();
  const { findMaterialByName } = useTableHelpers();
  const { getPrefilledValue, calcTableTotal } = useTableHelpers();
  const { spreadsheetData } = useColumnMapper();
  const spreadsheetViewColumns = useDeliverySlipReleaseSpreadsheetConfig();
  const setGlobalProjectId = useProjectStore(
    (state) => state.setCurrentProjectId,
  );
  const { saveDescription, saveInstruction } = useInstruction();
  const { importedDescription } = useInvoiceImportExternalPO();

  const form = useFormContext<DeliverySlipReleaseFormValues>();
  const { watch, setValue, getValues } = form;

  const customTaxAmount = watch("customTaxAmount");
  const netAmount = watch("netAmount");
  const projectId = watch("projectId");

  useEffect(() => {
    if (projectId) {
      setGlobalProjectId(projectId);
    }
  }, [setGlobalProjectId, projectId]);

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== netAmount) {
        setValue("netAmount", newTotal);
      }
      const taxableNetAmount = `${calcTableTotal(data, { taxable: true })}`;
      if (taxableNetAmount !== getValues("taxableNetAmount")) {
        setValue("taxableNetAmount", taxableNetAmount);
      }
    },
    [calcTableTotal, netAmount, getValues, setValue],
  );

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  const [tableItems, setTableItems] = useState<ImportableItem[]>([]);
  const { releaseInput } = useReleaseAdditionalChargesInput({
    form: form as unknown as UseFormReturn<ReleaseAdditionalChargesInputFormContext>,
  });
  const { total } = useFormPriceCalculation({
    form: form as unknown as UseFormReturn<TotalProps>,
  });
  const { taxable } = usePrefillDefaultTaxable();

  useEffect(() => {
    if (tableItems.length === 0) {
      setTableItems(
        importedItems ??
          deliverySlip?.items.map((item) => {
            const matchingOrgMaterial = findMaterialByName(
              item.description ?? "",
            );
            const prefilledPrice = getPrefilledValue({
              material: item.description ?? "",
              manufacturer:
                matchingOrgMaterial?.manufacturer?.name ??
                item.manufacturer ??
                "",
              uom:
                matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
                matchingOrgMaterial?.defaultEstimateUom?.mnemonic ??
                item.uom ??
                "",
            });

            return {
              ...item,
              description: item.description ?? "",
              quantityDecimal: item.orderedQuantity ?? "0",
              receivedQuantityDecimal: item.receivedQuantity ?? "0",
              material: matchingOrgMaterial,
              costCode: matchingOrgMaterial?.costCode?.code ?? undefined,
              manufacturer:
                item.manufacturer ?? matchingOrgMaterial?.manufacturer?.name,
              UOM:
                item.uom ??
                matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
                matchingOrgMaterial?.defaultEstimateUom?.mnemonic ??
                "",
              unitPrice: prefilledPrice?.value ?? undefined,
              taxable,
            };
          }) ??
          [],
      );
    } else if (importedItems) {
      setTableItems(importedItems);
    }
  }, [
    importedItems,
    deliverySlip?.items,
    findMaterialByName,
    tableItems.length,
    getPrefilledValue,
    taxable,
  ]);

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (values.additionalCharges) {
        setValue("additionalCharges", values.additionalCharges);
      }
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue("customTaxAmount", values.customTaxAmount || "0");
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }
      if (values.paymentTerm || values.paymentTerm === 0) {
        setValue("paymentTerm", values.paymentTerm);
      }
      if (values.taxVariance || values.clearTaxVariance) {
        setValue(
          "taxVariance",
          values.clearTaxVariance ? undefined : values.taxVariance,
        );
      }

      return true;
    },
    [customTaxAmount, setValue],
  );

  useEffect(() => {
    if (importedTaxCodeId) {
      setValue("taxCodeId", importedTaxCodeId);
    }
  }, [importedTaxCodeId, setValue]);

  return (
    <Container>
      <DeliverySlipCreateReleaseHeader />
      <SpreadSheetView>
        <SpreadSheetTable
          items={tableItems}
          columns={spreadsheetViewColumns}
          saving={loading || releaseLoading}
          height="350px"
          onChanges={updateSubtotal}
          readOnly={importedPoIsImmutable}
        />
      </SpreadSheetView>
      <DeliverySlipReleasePhotosPanel
        updateAssets={(assets) => setDeliveryPhotos(assets)}
      />
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={`${total}`}
          releaseInput={releaseInput}
          editableByContractor={!importedPoIsImmutable}
          updateRelease={(values) =>
            handleUpdateRelease({ ...values, version: -1 })
          }
          includePaymentTerms
          editablePaymentTerms
          additionalCharges={
            watch("additionalCharges") as AdditionalChargesFieldsFragment[]
          }
        />
        <Instructions
          projectId={projectId}
          instruction={release?.instructions}
          saveInstruction={saveInstruction}
        >
          <ReleaseDescription
            release={release}
            directlyEditable
            saveDescriptionCustomMethod={saveDescription}
            description={release ? undefined : importedDescription || undefined}
          />
        </Instructions>
      </Footer>
    </Container>
  );
};
