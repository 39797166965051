import {
  BuyoutFieldsFragment,
  BuyoutItemFieldsFragment,
  useBuyoutQuery,
} from "@/generated/graphql";

import {
  NoFunctionBooleanPromise,
  NoFunctionUndefined,
} from "@/types/NoFunction";
import { FC, createContext, useContext } from "react";
import { useParams } from "react-router";
import { useBuyoutMutations } from "./useBuyoutMutations";

type ProviderContextType = {
  addToBuyout: (input: {
    masterSkuId?: string;
    masterProductId?: string;
    description?: string;
    orgCatalogSkuId?: string;
    estimateUom: string;
  }) => Promise<boolean>;
  removeFromBuyout: (itemId: string) => Promise<boolean>;
  findItem: (id: string) => BuyoutItemFieldsFragment | undefined;
  count: number;
  buyout: BuyoutFieldsFragment | null;
};

const ProviderContext = createContext<ProviderContextType>({
  addToBuyout: NoFunctionBooleanPromise,
  removeFromBuyout: NoFunctionBooleanPromise,
  findItem: NoFunctionUndefined,
  count: 0,
  buyout: null,
});

export const BuyoutShoppingCartProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { id } = useParams();
  const { data } = useBuyoutQuery({
    variables: { id: id || "" },
  });
  const {
    addToBuyout: addToBuyoutMutation,
    removeFromBuyout: removeFromBuyoutMutation,
  } = useBuyoutMutations();

  const addToBuyout = async (input: {
    masterSkuId?: string;
    masterProductId?: string;
    description?: string;
    estimateUom: string;
  }) => {
    const buyoutId = id || "";

    return !!(await addToBuyoutMutation({
      items: [
        {
          projectItem: {
            masterProductId: input.masterProductId,
            masterSkuId: input.masterSkuId,
            estimateUom: input.estimateUom,
          },
          description: input.description || "",
        },
      ],
      buyoutId,
    }));
  };

  const removeFromBuyout = async (itemId: string) => {
    return await removeFromBuyoutMutation({
      buyoutId: id || "",
      buyoutItemIds: [itemId],
    });
  };

  const findItem = (
    materialId: string,
  ): BuyoutItemFieldsFragment | undefined => {
    if (data?.buyout) {
      return data.buyout.items.find(
        (item) => item.projectItem.material.material?.id === materialId,
      );
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        buyout: data?.buyout || null,
        addToBuyout,
        findItem,
        removeFromBuyout,
        count: data?.buyout?.items?.length || 0,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useBuyoutShoppingCart = (): ProviderContextType =>
  useContext(ProviderContext);
