import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceReleaseItemZoneCheckbox } from "./hooks/useInvoiceReleaseItemZoneCheckbox";

const Container = tw.div`flex`;

type Props = {
  zone:
    | CategoryState<ExpandedReleaseItem>
    | CategoryState<CategoryState<ExpandedReleaseItem>>;
};

export const InvoiceReleaseItemZoneCheckbox: FC<Props> = ({ zone }) => {
  const { checked, handleChange, hasSelectableItems } =
    useInvoiceReleaseItemZoneCheckbox(zone);

  return (
    <If isTrue={hasSelectableItems}>
      <Container>
        <SelectionCheckbox
          testId={`invoice-zone-${zone.name.toLowerCase().replaceAll(/\s+/g, "-")}`}
          checked={checked}
          setSelection={handleChange}
        />
      </Container>
    </If>
  );
};
