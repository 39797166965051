export enum TemplateVariablesEnum {
  ProjectName = "Four Seasons",
  ProjectJobNumber = "9099",
  BuyoutDescription = "Piping Supplies",
  BuyoutClientIdentifier = "10042",
  ReleaseDescription = "Piping Materials",
  ReleaseSeqNumber = "654",
  InvoiceDescription = "202851-1 Piping materials",
  InvoiceNumber = "202851",
  InvoicePONumber = "42-001",
  VendorName = "Action Gypsum Supply",
  VendorAlternateName = "AGS",
}
