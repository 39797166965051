import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { InvoiceEmailFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceEmailsSequence } from "../../hooks/useInvoiceEmailsSequence";
import { useInvoiceEmailCheckbox } from "./useInvoiceEmailCheckbox";
const Container = tw.div`
   flex
`;

export const InvoiceEmailCheckbox: FC<{
  invoiceEmail: InvoiceEmailFieldsFragment;
}> = ({ invoiceEmail }) => {
  const { sequenceIds } = useInvoiceEmailsSequence();
  const { handleChange } = useInvoiceEmailCheckbox({ invoiceEmail });

  if (
    invoiceEmail.archivedAt ||
    invoiceEmail.invoices.some((invoice) => !invoice.archivedAt)
  ) {
    return null;
  }

  return (
    <Container>
      <SelectionCheckbox
        itemId={invoiceEmail.id}
        items={sequenceIds}
        setSelection={handleChange}
      />
    </Container>
  );
};
