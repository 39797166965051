import { If } from "@/common/components/if/If";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import React, { FC, useMemo } from "react";
import { useDistributorBuyout } from "../providers/DistributorBuyoutProvider";

type WithdrawalWrapperProps = {
  item: DistributorBuyoutItemFieldsFragment;
  children: React.JSX.Element;
};

export const WithdrawalWrapper: FC<WithdrawalWrapperProps> = ({
  item,
  children,
}) => {
  const { buyout } = useDistributorBuyout();
  const condition = useMemo(
    () => buyout?.items.some((i) => i.id === item.id && i.isIncluded),
    [buyout?.items, item],
  );

  return <If isTrue={condition}>{children}</If>;
};
