import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import { routes } from "@/config/routes";
import {
  RfqDocument,
  RfqsDocument,
  UomsDocument,
  UpdateRfqInput,
  namedOperations,
  useCancelRfqMutation,
  useCreateEditCopyRfqForChangesMutation,
  useRemoveRfqMutation,
  useUpdateRfqMutation,
} from "@/generated/graphql";
import { useNavigate } from "react-router";
import { useRfqGroupedItemsStore } from "../stores/useRfqGroupedItemsStore";

export const useRfqMutations = () => {
  const { setError } = useGlobalError();
  const [cancelRfq] = useCancelRfqMutation();
  const [createEditCopyRfqForChanges] =
    useCreateEditCopyRfqForChangesMutation();
  const navigate = useNavigate();
  const { paginationArgs } = usePagination();
  const [removeRfq] = useRemoveRfqMutation();
  const [updateRfqMutation, { loading: isUpdating }] = useUpdateRfqMutation();
  const { setRfqGroupedItemsFromRfq } = useRfqGroupedItemsStore();

  const cancelRfqMutation = async (rfqId: string) => {
    try {
      const { errors } = await cancelRfq({
        variables: {
          rfqID: rfqId,
        },
        refetchQueries: [
          {
            query: RfqsDocument,
            variables: { ...paginationArgs, filter: {} },
          },
        ],
      });

      setError(errors);
      if (!errors) {
        navigate(routes.quotes);
      }
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const createRfqDraftMutation = async (rfqId: string) => {
    try {
      const { data, errors } = await createEditCopyRfqForChanges({
        variables: {
          rfqID: rfqId,
        },
        refetchQueries: [
          { query: RfqDocument, variables: { id: rfqId } },
          { query: RfqsDocument, variables: { ...paginationArgs, filter: {} } },
        ],
        update: (cache) => cleanQuery(cache, namedOperations.Query.Rfqs),
      });
      setError(errors);
      if (data?.createEditCopyRfqForChanges.id) {
        return data?.createEditCopyRfqForChanges;
      }
      return undefined;
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  const removeRfqMutation = async (id: string) => {
    try {
      const { errors } = await removeRfq({
        variables: { id },
        refetchQueries: [
          { query: RfqsDocument, variables: { ...paginationArgs, filter: {} } },
        ],
        update: (cache) => cleanQuery(cache, namedOperations.Query.Rfqs),
      });

      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const updateRfq = async (input: UpdateRfqInput) => {
    try {
      const { data, errors } = await updateRfqMutation({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: UomsDocument,
          },
        ],
      });
      if (data) {
        setRfqGroupedItemsFromRfq(data.updateRfq);
      }

      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
    }
    return false;
  };

  return {
    cancelRfqMutation,
    createRfqDraftMutation,
    removeRfqMutation,
    updateRfq,
    isUpdating,
  };
};
