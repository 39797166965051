import { useCallback, useMemo } from "react";
import { useBuyoutSequence } from "../hooks/useBuyoutSequence";
import { useBuyouts } from "../providers/BuyoutsProvider";

export const useBuyoutAllItemsCheckbox = () => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useBuyoutSequence();
  const { buyouts } = useBuyouts();

  const checked = useMemo(
    () =>
      buyouts.length > 0 && buyouts.every((d) => selectedEntities.includes(d)),
    [buyouts, selectedEntities],
  );
  const handleChange = useCallback(() => {
    const filteredItems = buyouts;
    setSelectedEntities(
      checked
        ? selectedEntities.filter((i) => !filteredItems.includes(i))
        : [
            ...selectedEntities.filter((i) => filteredItems.includes(i)),
            ...filteredItems,
          ],
    );
  }, [buyouts, checked, selectedEntities, setSelectedEntities]);

  return { sequenceIds, handleChange, checked };
};
