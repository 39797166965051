import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseStatus } from "@/generated/graphql";
import { useCallback, useState } from "react";
import { InvoiceFooterState } from "../../../../../../../../../common/components/invoices/invoice-details/types/InvoiceFooterState";
import { INVOICE_READONLY_STATUSES } from "../../../../../../../../../distributor/pages/invoices/providers/DistributorInvoiceProvider";
import { MatchedOrderViewState } from "../../../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceUpdateRelease } from "../../../providers/InvoiceUpdateReleaseProvider";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../../matched-order/components/InvoiceVerificationForm";
import { EditOrderButtonsProps } from "./EditOrderButtons";

export const useEditOrderButtons = ({
  createReleaseFormHandleSubmit,
}: EditOrderButtonsProps) => {
  const { invoice, footerState, setFooterState } = useInvoiceVerification();
  const { setMatchedOrderViewState } = useInvoiceMatchedOrder();
  const { syncUpdateReleaseFromInvoice, saving } = useInvoiceUpdateRelease();
  const { release } = useRelease();
  const [updateDialogVisible, setUpdateDialogVisible] = useState(false);

  const handleUpdateReleaseFromInvoice = useCallback(
    async ({
      skipVendorNotification,
      skipConfirmation,
    }: {
      skipVendorNotification?: boolean;
      skipConfirmation?: boolean;
    } = {}) => {
      await createReleaseFormHandleSubmit(
        async (values: InvoiceCreateReleaseFormValues) => {
          const sync = await syncUpdateReleaseFromInvoice({
            ...values,
            skipConfirmation,
            skipVendorNotification,
          });
          if (!sync) {
            return null;
          }
          if (invoice && !INVOICE_READONLY_STATUSES.includes(invoice.status)) {
            setMatchedOrderViewState(
              MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
            );
          } else {
            setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
          }
          setFooterState(InvoiceFooterState.DEFAULT);
        },
      )();
      return true;
    },
    [
      createReleaseFormHandleSubmit,
      syncUpdateReleaseFromInvoice,
      invoice,
      setFooterState,
      setMatchedOrderViewState,
    ],
  );

  const onSaveClick = useCallback(async () => {
    if (
      release &&
      [
        ReleaseStatus.Requested,
        ReleaseStatus.Scheduled,
        ReleaseStatus.PartiallyReceived,
      ].includes(release?.status)
    ) {
      setUpdateDialogVisible(true);
      return;
    }
    await handleUpdateReleaseFromInvoice();
  }, [handleUpdateReleaseFromInvoice, release]);

  return {
    saving,
    onSaveClick,
    updateDialogVisible,
    invoice,
    footerState,
    setFooterState,
    setMatchedOrderViewState,
    setUpdateDialogVisible,
    handleUpdateReleaseFromInvoice,
    release,
  };
};
