import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { ScannedInvoicesHeader } from "./components/list/scanned-invoices-header/ScannedInvoicesHeader";
import { ScannedInvoicesList } from "./components/list/ScannedInvoicesList";
import { InvoicesSideActions } from "./components/side-actions/InvoicesSideActions";
import { SplittingInvoicesWizardModal } from "./components/splitting-invoices/SplittingInvoicesWizardModal";
import { SplittingInvoicesWizardProvider } from "./components/splitting-invoices/SplittingInvoicesWizardProvider";
import { InvoiceCreationProvider } from "./providers/InvoiceCreationProvider";
import { InvoiceSequenceProvider } from "./providers/InvoiceSequenceProvider";
import { InvoicesProvider, useInvoices } from "./providers/InvoicesProvider";

const ScannedInvoicesProviderWithProvider = () => {
  const { invoices, loading, totalCount, isFiltered } = useInvoices();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "SCANNED_INVOICES" })}</title>
      </Helmet>
      <ScannedInvoicesHeader />
      <ScannedInvoicesList
        invoices={invoices}
        isFiltered={isFiltered}
        loading={loading}
        totalCount={totalCount}
      />
      <InvoicesSideActions />
      <SplittingInvoicesWizardModal />
    </>
  );
};
export const ScannedInvoices = () => (
  <InvoiceCreationProvider>
    <InvoiceSequenceProvider>
      <NestedStepperProvider>
        <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
          <InvoicesProvider>
            <SplittingInvoicesWizardProvider>
              <ScannedInvoicesProviderWithProvider />
            </SplittingInvoicesWizardProvider>
          </InvoicesProvider>
        </PaginationProvider>
      </NestedStepperProvider>
    </InvoiceSequenceProvider>
  </InvoiceCreationProvider>
);
