import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import {
  LgFixedContainer,
  LogoContainer,
  MdFixedContainer,
  SmFixedContainer,
  XsFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { InvoiceEmailFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceEmailActions } from "./InvoiceEmailActions";
import { InvoiceEmailCheckbox } from "./InvoiceEmailCheckbox";
import { InvoiceEmailFiles } from "./InvoiceEmailFiles";
import { InvoiceEmailMatchedOrder } from "./InvoiceEmailMatchedOrder";
import { InvoiceEmailsAllItemsCheckbox } from "./InvoiceEmailsAllItemsCheckbox";
import { InvoiceEmailStatusDisplay } from "./InvoiceEmailStatusDisplay";

const Item = tw.div`text-sm`;
const EmailContainer = tw(Item)`truncate`;

export const useInvoiceEmailsConfiguration = () => {
  const configuration: Array<GridCol<InvoiceEmailFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: XsFixedContainer,
        position: "center",
        item: ({ item }) => <InvoiceEmailCheckbox invoiceEmail={item} />,
        header: <InvoiceEmailsAllItemsCheckbox />,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => (
          <Item>
            <DateView date={item.receivedAt} options={SHORT_DATE_OPTION} />
          </Item>
        ),
        header: <FormattedMessage id="INVOICE_EMAIL_RECEIVED" />,
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => (
          <Tooltip
            id="email-from"
            className="w-full"
            element={<EmailContainer>{item.from}</EmailContainer>}
          >
            {item.from}
          </Tooltip>
        ),
        header: <FormattedMessage id="INVOICE_EMAIL_FROM" />,
      },
      {
        wrapper: LogoContainer,
        header: <FormattedMessage id="INVOICE_EMAIL_VENDOR" />,
        item: ({ item, index }) => (
          <VendorLogo
            logoImageUrl={
              item.orgPreferredVendor?.sellerOrgLocation.org.photoUrl
            }
            address={item.orgPreferredVendor?.sellerOrgLocation.address}
            contactName={getVendorContacts(item.orgPreferredVendor?.contacts)}
            orgName={item.orgPreferredVendor?.sellerOrgLocation.org.name}
            index={index}
            vendorNotSelectedKey="INVOICE_EMAIL_VENDOR_NOT_SELECTED"
          />
        ),
        position: "center",
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => <Item>{item.subject}</Item>,
        header: <FormattedMessage id="INVOICE_EMAIL_SUBJECT" />,
      },
      {
        wrapper: LgFixedContainer,
        item: ({ item }) => <InvoiceEmailFiles invoiceEmail={item} />,
        header: <FormattedMessage id="INVOICE_EMAIL_FILES" />,
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="INVOICE_ATTACHMENTS_VERIFICATION" />,
        item: ({ item }) => <InvoiceEmailStatusDisplay invoiceEmail={item} />,
        position: "center",
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => <InvoiceEmailMatchedOrder invoiceEmail={item} />,
        header: <FormattedMessage id="INVOICES" />,
        position: "center",
      },
      {
        wrapper: XxsFixedContainer,
        item: ({ item }) => <InvoiceEmailActions invoiceEmail={item} />,
      },
    ],
    [],
  );

  return configuration;
};
