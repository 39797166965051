import { useDialog } from "@/common/components/dialog/DialogProvider";
import { ReactElement, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useArchiveProjects } from "../../hooks/useArchiveProjects";
import { useProjectSequence } from "../../hooks/useProjectSequence";
import { useProjects } from "../../providers/ProjectsProvider";

type Props = {
  deleteIcon: ReactElement;
};

export const useProjectsSideActions = ({ deleteIcon }: Props) => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useProjectSequence();
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { refetchProjects: refetch, filter } = useProjects();
  const { archiveProjects } = useArchiveProjects();

  const deleteItems = useCallback(() => {
    const removableItems = selectedEntities.filter(
      (project) => !project.deletedAt,
    );
    if (removableItems.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        includeWarningIcon: true,
        title: intl.$t({ id: "DELETE_CONFIRMATION" }),
        text:
          removableItems.length === 1 && removableItems[0]?.id
            ? intl.$t(
                { id: "DELETE_PROJECT_MESSAGE" },
                {
                  projectName: removableItems[0].name,
                },
              )
            : intl.$t(
                { id: "DELETE_PROJECTS_MESSAGE" },
                { number: removableItems.length },
              ),
        handleConfirm: async () => {
          if (
            await archiveProjects(removableItems.map((release) => release.id))
          ) {
            setSelectedEntities([]);
            refetch();
          }
        },
      });
    }
  }, [
    selectedEntities,
    openDialog,
    intl,
    archiveProjects,
    setSelectedEntities,
    refetch,
  ]);

  const actions = useMemo(() => {
    const sideActions = [];

    if (
      selectedEntities.some((project) => !project.deletedAt) &&
      filter?.deleted !== true
    ) {
      sideActions.push({
        icon: deleteIcon,
        tooltipKey: "DELETE_ITEMS",
        callback: deleteItems,
      });
    }

    return sideActions;
  }, [selectedEntities, filter?.deleted, deleteIcon, deleteItems]);

  return { sequenceIds, actions };
};
