import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { routes } from "@/config/routes";
import {
  AssetContext,
  CreateDeliverySlipInput,
  useCreateDeliverySlipMutation,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { BsPlus } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useRelease } from "../../../../providers/ReleaseProvider";

const AddPackingSlipDocument = tw.div`
  grid grid-flow-col justify-start gap-2 items-center text-blue-500 hover:text-blue-300 cursor-pointer text-sm
`;
const AddPackingSlipDocumentButton = tw.button`
  grid place-items-center border-gray-300 w-17 h-17 border rounded-md  text-3xl
`;

const Container = tw.div`
  grid p-4 gap-4
`;

const AddPackingSlipBtnWithProviders: FC = () => {
  const intl = useIntl();
  const [updateContractorRelease, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const { release } = useRelease();
  const { setError } = useGlobalError();
  const navigate = useNavigate();
  const { assets } = useUploadAssets();
  const [createDeliverySlipMutation] = useCreateDeliverySlipMutation();
  const createDeliverySlip = async (input: CreateDeliverySlipInput) => {
    try {
      const { data, errors } = await createDeliverySlipMutation({
        variables: {
          input,
        },
      });

      setError(errors);
      return data?.createDeliverySlip;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  const createNewPackingSlip = async () => {
    if (!release || !release.project) {
      return;
    }

    const newSlip: CreateDeliverySlipInput = {
      orgLocationId: release.project.location.id || "",
      projectId: release.project.id,
      releaseID: release.id,
      assetUrls: assets.map((asset) => asset.url),
      async: false,
    };
    const deliverySlip = await createDeliverySlip(newSlip);

    if (!deliverySlip) {
      return;
    }

    try {
      const { errors, data } = await updateContractorRelease({
        variables: {
          input: {
            releaseId: release.id,
            version: release.version,
            deliverySlipIds: [
              ...release.deliverySlips.map((slip) => slip.id),
              deliverySlip.id,
            ],
          },
        },
      });
      setError(errors);
      if (data) {
        navigate(
          generatePath(routes.deliverySlipReceiveOrder, {
            deliverySlipId: deliverySlip.id,
          }),
        );
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <AddPackingSlipDocument onClick={() => togglePanel(true)}>
          <AddPackingSlipDocumentButton>
            <BsPlus />
          </AddPackingSlipDocumentButton>
          <FormattedMessage id="ADD_PACKING_SLIP" />
        </AddPackingSlipDocument>
      )}
      content={(togglePanel) => (
        <OverlayPanel
          onSave={createNewPackingSlip}
          saveLabel={intl.$t({ id: "CREATE" })}
          title={intl.$t({ id: "ADD_PACKING_SLIP" })}
          onCancel={() => togglePanel(false)}
          className="px-0"
          isLoading={updating}
        >
          <Container>
            <UploadAsset
              accept={{
                [PDF_MIME_TYPE]: PDF_EXTENSIONS,
                [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
              }}
            />
          </Container>
        </OverlayPanel>
      )}
    />
  );
};

export const AddPackingSlipBtn: FC = () => {
  const { locations } = useUserLocations({
    permission: "createDeliverySlip",
  });
  const hasPermissionInOrgLocation = useMemo(
    () => locations.length > 0,
    [locations],
  );

  if (!hasPermissionInOrgLocation) {
    return null;
  }

  return (
    <UploadAssetProvider context={AssetContext.DeliverySlip}>
      <AddPackingSlipBtnWithProviders />
    </UploadAssetProvider>
  );
};
