import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { Popover } from "@/common/components/popover/Popover";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { routes } from "@/config/routes";
import {
  RfqFieldsFragment,
  RfqQuotesFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";

type Props = {
  rfq: RfqQuotesFieldsFragment | RfqFieldsFragment | null | undefined;
  createRfqDraft: (
    rfqId: string,
  ) => Promise<undefined | { id: string; clientIdentifier: string }>;
};

export const RequestChangesButton: FC<Props> = ({
  rfq,
  createRfqDraft,
}: Props) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();
  const navigate = useNavigate();

  const requestChanges = useCallback(async () => {
    if (rfq) {
      const newRfq = await createRfqDraft(rfq.id);
      if (newRfq?.id) {
        setSuccessAlert(
          intl.$t(
            {
              id: "QUOTE_REQUEST_CHANGES_SUCCESS",
            },
            {
              rfqNumber: strongify(newRfq.clientIdentifier),
            },
          ),
        );
        if (rfq.noteDocument) {
          navigate(
            generatePath(routes.editRfqFromNote, {
              id: newRfq.id,
              noteDocumentId: rfq.noteDocument.id,
            }),
          );
        } else {
          navigate(
            generatePath(routes.rfqCheckout, {
              id: newRfq.id,
            }),
          );
        }
      }
    }
  }, [createRfqDraft, intl, navigate, rfq, setSuccessAlert]);

  return (
    <Popover
      element={
        <PrimaryButton
          onClick={() =>
            openDialog({
              cancelButtonText: intl.$t({ id: "CANCEL" }),
              confirmButtonText: intl.$t({ id: "PROCEED" }),
              includeWarningIcon: true,
              title: intl.$t(
                { id: "CONFIRM_REQUEST_CHANGES_TO_RFQ_QUESTION" },
                { number: rfq?.clientIdentifier },
              ),
              text: intl.$t({
                id: "CONFIRM_REQUEST_CHANGES_TO_RFQ_DETAILS",
              }),
              handleConfirm: requestChanges,
            })
          }
          disabled={!!rfq?.nextRevision}
          autoFocus
        >
          <FormattedMessage id="RFQ_REQUEST_CHANGES" />
        </PrimaryButton>
      }
      $arrow
      $ellipsis={!rfq?.nextRevision}
      id="request-changes-popover"
      position="top"
    >
      <FormattedMessage id="RFQ_REQUEST_CHANGES_DISABLED" />
    </Popover>
  );
};
