import { ADDRESS_FIELDS } from "@/common/fragments/addressFragment";
import { gql } from "@apollo/client";

export const WAREHOUSE_FIELDS = gql`
  ${ADDRESS_FIELDS}
  fragment WarehouseFields on Warehouse {
    id
    name
    address {
      ...AddressFields
    }
    externalCode
    archivedAt
    trackInventory
  }
`;
