import { useLoadingAction } from "@/common/hooks/useLoadingAction";
import { NoFunction, NoFunctionPromise } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router";
import { Dialog, DialogProps } from "./Dialog";

type DialogDataType = Omit<DialogProps, "show">;

type DialogProviderProps = {
  openDialog: (data: Partial<DialogDataType>) => void;
  cancelDialog: DialogDataType["handleCancel"];
  isVisible: boolean;
};

const DialogProviderContext = createContext<DialogProviderProps>({
  openDialog: NoFunction,
  cancelDialog: NoFunction,
  isVisible: false,
});

const DEFAULT_DIALOG_DATA: DialogDataType = {
  cancelButtonText: "",
  confirmButtonText: "",
  handleCancel: NoFunction,
  handleConfirm: NoFunctionPromise,
  text: "",
  title: "",
  content: null,
  icon: null,
  closeOnConfirm: true,
  closeOnCustom: false,
  saving: false,
  maxWidth: "sm",
  fullScreen: false,
  fullScreenCentered: false,
};

export const DialogProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [show, setShow] = useState(false);
  const [dialogData, setDialogData] =
    useState<DialogDataType>(DEFAULT_DIALOG_DATA);
  const { loading, asyncAction } = useLoadingAction();
  const location = useLocation();

  useEffect(() => {
    setShow(false);
  }, [location]);

  const openDialog = useCallback(
    (data: Partial<DialogDataType>) => {
      if (!show) {
        setShow(true);
      }
      setDialogData({
        ...DEFAULT_DIALOG_DATA,
        ...data,
      });
    },
    [show],
  );

  const handleCancel = useCallback(() => {
    setShow(false);
    dialogData.handleCancel?.();
  }, [dialogData]);

  const handleConfirm = useCallback(async () => {
    await asyncAction(async () => {
      if (dialogData.handleConfirm) {
        await dialogData.handleConfirm();
      }
    });

    if (dialogData.closeOnConfirm) {
      setShow(false);
    }
  }, [asyncAction, dialogData]);

  const handleCustomButtonAction = useCallback(async () => {
    await asyncAction(async () => {
      if (dialogData.handleCustomButtonAction) {
        await dialogData.handleCustomButtonAction();
      }
    });

    if (dialogData.closeOnCustom) {
      setShow(false);
    }
  }, [asyncAction, dialogData]);

  return (
    <DialogProviderContext.Provider
      value={{ openDialog, cancelDialog: handleCancel, isVisible: show }}
    >
      {children}
      <Dialog
        {...dialogData}
        show={show}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        handleCustomButtonAction={handleCustomButtonAction}
        saving={loading}
      />
    </DialogProviderContext.Provider>
  );
};

export const useDialog = (): DialogProviderProps =>
  useContext(DialogProviderContext);
