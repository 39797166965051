import { TextField } from "@/common/components/textfield/TextField";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useUsers } from "@/common/hooks/useUsers";
import { useUser } from "@/common/providers/UserProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../../if/If";
import { InfoTooltip } from "../../info-tooltip/InfoTooltip";
import { Multiselect } from "../../select/components/multiple/Multiselect";
import { useMessages } from "../providers/MessagesProvider";

type SendMessageButtonProps = {
  message: string;
  setMessage: (message: string) => void;
};

export type MessageSenderComponents = {
  MessageSenderButton?: FC<SendMessageButtonProps>;
  MessageSenderTop?: React.ReactNode;
  MessageSenderBottom?: React.ReactElement;
};

type Props = {
  note?: React.ReactNode;
  sendMessagePlaceholder?: string;
  messageTabName: React.ReactNode;
  components?: MessageSenderComponents;
  projectId?: string;
};

const Container = tw.div`
  flex bg-gray-100 gap-5 p-5
`;
const TextFieldStyled = tw(TextField)`
  group w-full
`;

const InputContainer = tw.div`
  flex flex-col gap-2 relative w-full
`;
const ControlsContainer = tw.div`flex flex-col flex-1`;

export const MessagesSender: FC<Props> = ({
  sendMessagePlaceholder,
  components,
  projectId,
}) => {
  const { message, setMessage, setUserIds, userIds, isPrivate } = useMessages();
  const intl = useIntl();
  const { users, loading } = useUsers({ projectId });
  const { viewer } = useUser();
  const { projects } = useProjectListOptions({ skip: !projectId });

  const filteredUsers = useMemo(
    () => users?.filter((user) => user.id !== viewer?.id) || [],
    [users, viewer],
  );

  const selectedProject = useMemo(
    () => projects.find((project) => project.id === projectId),
    [projectId, projects],
  );

  const viewerIsAssignedToProject = useMemo(
    () =>
      selectedProject?.team.some(
        (teamMember) => viewer?.id === teamMember.id,
      ) || false,
    [selectedProject, viewer],
  );

  const controlsDisabled = !!projectId && !viewerIsAssignedToProject;

  return (
    <Container>
      <ControlsContainer>
        {components?.MessageSenderTop}
        <InputContainer>
          <If isTrue={isPrivate}>
            <Multiselect
              testId="form-team-selector"
              placeholder={intl.$t({ id: "NOTIFY_TEAM_MEMBER" })}
              options={filteredUsers}
              values={userIds}
              onMultipleChange={(values) => setUserIds(values || [])}
              loading={loading}
              getLabel={getUserName}
              getValue={(option) => option.id}
              noOptionsText={intl.$t({ id: "NO_USERS_FOUND" })}
              disableCloseOnSelect
              includeCheckbox
              className="min-w-60"
              chipSize="small"
              InputLabelProps={{
                className: "bg-white",
              }}
              disabled={controlsDisabled}
            />
          </If>
          <TextFieldStyled
            multiline
            label={intl.$t({
              id: sendMessagePlaceholder || "MESSAGE_PLACEHOLDER",
            })}
            minRows={2}
            maxRows={3}
            value={message}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setMessage(ev.target.value)
            }
            className="bg-white"
            sx={{
              " fieldset": {
                border: 1,
              },
            }}
            disabled={controlsDisabled}
          />

          {components?.MessageSenderBottom &&
            React.cloneElement(components.MessageSenderBottom, {
              isPrivate,
            })}
        </InputContainer>
      </ControlsContainer>
      <If isTrue={controlsDisabled}>
        <InfoTooltip
          message={intl.$t({ id: "USER_NOT_ASSIGNED_TO_PROJECT" })}
          className="mt-1.5"
        />
      </If>
    </Container>
  );
};
