import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { Loader } from "@/common/components/loader/Loader";
import { NoteDocumentPanel } from "@/common/components/note-document-panel/NoteDocumentPanel";
import { RfqStatusChip } from "@/common/components/statuses/RfqStatusChip";
import { routes } from "@/config/routes";
import { RFQInfo } from "@/contractor/pages/home/rfq-master-catalog-import/components/RFQInfo";
import { QuoteStatus, RfqStatus } from "@/generated/graphql";
import { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import RfqFooter from "./components/RfqFooter";
import { RfqItemsList } from "./components/RfqItemsList";
import { useRfq } from "./hooks/useRfq";

const OrderWithProviderContainer = tw.div`pb-15`;

const RfqNoWrapper = tw.div`pr-2`;

export const Rfq = () => {
  const { rfq, loading } = useRfq();
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    if (rfq?.status === RfqStatus.Draft && !rfq.deletedAt) {
      navigate(generatePath(routes.rfqCheckout, { id: rfq.id }));
    }
  }, [rfq?.status, rfq?.id, navigate, rfq?.deletedAt]);

  const actions = useMemo(() => {
    if (rfq && rfq.quotes.some((q) => q.status === QuoteStatus.Submitted)) {
      return [
        <PrimaryButton
          className="text-sm"
          key="quotes"
          onClick={() => {
            navigate(generatePath(routes.bidsWithId, { id: rfq.id }));
          }}
        >
          <FormattedMessage id={`VENDOR_QUOTES`} />
        </PrimaryButton>,
      ];
    }
  }, [rfq, navigate]);

  if (loading || !rfq) {
    return <Loader loading />;
  }

  return (
    <OrderWithProviderContainer>
      <Helmet>
        <title>
          {intl.$t({ id: "RFQ" })}: {rfq.project?.name}
        </title>
      </Helmet>
      <Breadcrumbs
        classes={{
          text: "flex items-center",
        }}
        items={[
          {
            text: intl.$t({ id: "QUOTES" }),
            link: routes.quotes,
            id: "quotes",
          },
          {
            id: "rfqNumber",
            text: (
              <RfqNoWrapper>
                <FormattedMessage
                  id="RFQ_NUMBER_WITH_SYMBOL"
                  values={{ number: rfq.clientIdentifier }}
                />
              </RfqNoWrapper>
            ),
          },
        ]}
        appendItems={[
          <RfqStatusChip key="status" status={rfq.status} type="breadcrumb" />,
        ]}
        actions={actions}
      />
      <RFQInfo rfq={rfq} hideVendors={rfq.status === RfqStatus.Active} />
      <RfqItemsList readonly loading={loading} />
      <Instructions instruction={rfq.instructions} projectId={rfq.project.id} />
      <If isTrue={rfq.noteDocument}>
        <NoteDocumentPanel noteDocument={rfq.noteDocument} />
      </If>
      <RfqFooter />
    </OrderWithProviderContainer>
  );
};
