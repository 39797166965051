import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useProjectPredictedPoNumber } from "@/common/components/po-number/hooks/useProjectPredictedPoNumber";
import { MagicWand } from "@/common/components/po-number/MagicWand";
import { useInvoiceOrderMagicWand } from "./hooks/useInvoiceOrderMagicWand";
import { InvoiceOrderMagicWandProps } from "./types/InvoiceOrderMagicWandProps";

export const InvoiceOrderMagicWand = ({
  poNumber,
  projectId,
  setPoNUmber,
}: InvoiceOrderMagicWandProps) => {
  const { predictedPoNumber } = useProjectPredictedPoNumber(projectId || "");
  const { hidden } = useInvoiceOrderMagicWand({ poNumber, projectId });

  return (
    <If isTrue={!hidden}>
      <LinkLike>
        <MagicWand
          onClick={() => {
            if (predictedPoNumber) {
              setPoNUmber(predictedPoNumber);
            }
          }}
          disabled={!projectId}
        />
      </LinkLike>
    </If>
  );
};
