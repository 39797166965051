import { Loader } from "@/common/components/loader/Loader";
import { useEffect } from "react";
import { BuyoutDocument } from "./components/document/BuyoutDocument";
import {
  ContractorBuyoutProvider,
  useContractorBuyout,
} from "./providers/ContractorBuyoutProvider";
import { useBuyoutStore } from "./store/useBuyoutStore";

const BuyoutWithProvider = () => {
  const { buyout } = useContractorBuyout();

  if (!buyout) {
    return <Loader loading />;
  }

  return <BuyoutDocument />;
};

export const Buyout = () => {
  const clearBuyout = useBuyoutStore((state) => state.clearBuyout);

  useEffect(() => {
    return () => {
      clearBuyout();
    };
  }, [clearBuyout]);

  return (
    <ContractorBuyoutProvider>
      <BuyoutWithProvider />
    </ContractorBuyoutProvider>
  );
};
