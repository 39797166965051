import { useProjectEstimatedItemsCountLazyQuery } from "@/generated/graphql";
import { useCallback, useEffect, useState } from "react";

export const useProjectEstimatedItemsCount = (
  selectedProjectId: string | null | undefined,
) => {
  const [fetchProjectEstimatedItemsCountQuery, { loading }] =
    useProjectEstimatedItemsCountLazyQuery({
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

  const [estimatedItemsCount, setEstimatedItemsCount] = useState<number | null>(
    null,
  );

  const fetchProjectEstimatedItemsCount = useCallback(
    async (projectId: string | null | undefined) => {
      if (!projectId) {
        return null;
      }
      const { data } = await fetchProjectEstimatedItemsCountQuery({
        variables: { id: projectId },
      });
      setEstimatedItemsCount(data?.project?.estimatedItemsCount ?? null);
    },
    [fetchProjectEstimatedItemsCountQuery],
  );

  useEffect(() => {
    setEstimatedItemsCount(null);
    if (!!selectedProjectId) {
      fetchProjectEstimatedItemsCount(selectedProjectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId]);

  return {
    estimatedItemsCount,
    loading,
  };
};
