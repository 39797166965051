import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

export const usePrefillDefaultTaxable = () => {
  const { vendors } = useVendors();
  const { projects } = useProjectListOptions();

  const { watch } = useFormContext<{ vendorId: string; projectId: string }>();

  const [vendorId, projectId] = watch(["vendorId", "projectId"]);

  const taxable = useMemo(() => {
    const vendor = vendors.find((v) => v.id === vendorId);
    const project = projects.find((p) => p.id === projectId);

    return vendor?.taxExempt || project?.taxExempt ? false : true;
  }, [vendorId, projectId, vendors, projects]);

  return { taxable };
};
