import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { QUERYSTRING } from "@/common/const";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { routes } from "@/config/routes";
import {
  ProjectProjectsFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { generatePath, useNavigate } from "react-router";
import { BudgetContainer, LinkLikeStyled } from "../Projects.styles";
import { DisplayProjectBudgetBuyoutDiff } from "./DisplayProjectBudgetBuyoutDiff";

type Props = {
  project: ProjectProjectsFieldsFragment;
};

export const DisplayProjectDeliveries: FC<Props> = ({ project }) => {
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);

  const navigate = useNavigate();
  const onClick = () => {
    const params = new URLSearchParams({
      [QUERYSTRING.PROJECT_ID]: project.id,
      [QUERYSTRING.DELIVERY_STATUS]: `${ReleaseStatus.Received},${ReleaseStatus.PartiallyReceived},${ReleaseStatus.Scheduled}`,
    });

    navigate({
      pathname: generatePath(routes.deliveries),
      search: params.toString(),
    });
  };

  const deliveriesAmount = useMemo(() => {
    return project.orderedAmount
      ? new DecimalSafe(project.orderedAmount)
      : new DecimalSafe(0);
  }, [project.orderedAmount]);

  const count = useMemo(() => {
    return project.releaseCount;
  }, [project.releaseCount]);

  return (
    <NotNullableRenderer
      value={Number(hasPermissions ? deliveriesAmount : count)}
    >
      <LinkLikeStyled onClick={onClick}>
        <BudgetContainer>
          {hasPermissions ? (
            <>
              <ValueCurrency
                value={Number(deliveriesAmount)}
                classNames={{ value: "text-sm" }}
              />
              <If
                isTrue={
                  !project.budget &&
                  deliveriesAmount.greaterThan(
                    new DecimalSafe(project.budget || 0),
                  )
                }
              >
                <DisplayProjectBudgetBuyoutDiff
                  percentage={project.percentageOfBudgetSpent}
                />
              </If>
            </>
          ) : (
            count
          )}
        </BudgetContainer>
      </LinkLikeStyled>
    </NotNullableRenderer>
  );
};
