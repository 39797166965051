import { MessageOrgType } from "@/common/components/messages/Messages";
import { MessagesPanel } from "@/common/components/messages/components/MessagesPanel";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { RELEASE_MESSAGE_DISABLE_STATUSES } from "@/common/const";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { MESSAGES_SEGMENT } from "@/config/routes";
import {
  Buyout,
  MessageContextKind,
  Project,
  Release,
  SourceSystem,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import tw from "tailwind-styled-components";

type ReleaseInfo = Pick<Release, "id" | "sequenceNumber" | "status"> & {
  buyout?: Pick<Buyout, "id" | "clientIdentifier"> | null;
  project?: Pick<Project, "id" | "name"> | null;
  poLink?:
    | {
        immutable: boolean;
        sourceSystem: SourceSystem;
      }
    | null
    | undefined;
};

type Props = {
  release?: ReleaseInfo;
  text: React.ReactNode;
  org?: MessageOrgType;
};

const Container = tw.div`flex items-center`;

export const ReleaseMessagesButton: FC<Props> = ({
  text,
  release,
  org,
}: Props) => {
  const location = useLocation();
  const defaultOpened = useMemo(
    () => location.pathname.includes(MESSAGES_SEGMENT),
    [location.pathname],
  );

  const vendorMessagingAllowed = useMemo(
    () =>
      !checkReleaseStatus(release, RELEASE_MESSAGE_DISABLE_STATUSES) &&
      !release?.poLink?.immutable,
    [release],
  );

  if (!release) {
    return null;
  }

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <Container onClick={() => togglePanel(true)}>{text}</Container>
      )}
      content={(togglePanel) => (
        <MessagesPanel
          title="MESSENGER"
          id={release.id}
          kind={MessageContextKind.Release}
          context={{ release, buyout: release.buyout }}
          isContractor
          org={org}
          messageTabName={<FormattedMessage id="MESSAGE_VENDOR" />}
          noMessage="RELEASE_NO_MESSAGES"
          onClose={() => togglePanel(false)}
          projectId={release.project?.id}
          disabled={{
            vendor: !vendorMessagingAllowed,
          }}
        />
      )}
      defaultOpened={defaultOpened}
    />
  );
};
