import {
  OrgRolesWrapper,
  Permission,
} from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { Price } from "@/common/components/price/Price";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  release?: Pick<ReleaseFieldsFragment, "total" | "status"> | null;
};

const Container = tw.div`hidden lg:flex items-center flex-col`;
const TotalContainer = tw.div`font-bold`;
const TotalTextContainer = tw.div`font-light text-xs`;

export const ReleaseTotal: FC<Props> = ({ release }) => {
  const total = useMemo(() => {
    return new DecimalSafe(release?.total || "0");
  }, [release]);
  return (
    <OrgRolesWrapper permissions={[Permission.canViewPrices]}>
      <Container>
        <TotalContainer>
          <Price price={total} />
        </TotalContainer>
        <FormattedMessage tagName={TotalTextContainer} id="TOTAL_ORDER" />
      </Container>
    </OrgRolesWrapper>
  );
};
