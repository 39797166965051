import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  UNSPECIFIED_COST_CODE_ID,
  useUnspecifiedCostCode,
} from "@/common/hooks/useUnspecifiedCostCode";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import {
  ProjectAllowanceFieldsFragment,
  ProjectCostCodeFieldsFragment,
  ProjectReportMaterialFieldsFragment,
  ProjectReportPerMaterialFieldsFragment,
  ProjectReportPerMaterialZoneFieldsFragment,
  ProjectReportPerVendorFieldsFragment,
  ProjectReportVendorFieldsFragment,
  useProjectAllowanceQuery,
  useProjectReportPerMaterialQuery,
  useProjectReportPerMaterialZoneQuery,
  useProjectReportPerVendorQuery,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { useBudgetStore } from "../pages/project-budget/store/useBudgetStore";
import { getSpendingReportCostCodeOptions } from "../pages/project-spending-report/spending-report-list/utils/getSpendingReportCostCodeOptions";

export type ProjectReportType = (
  | ProjectReportVendorFieldsFragment
  | ProjectReportMaterialFieldsFragment
) & {
  id: string;
  zoneId?: string;
  costCodeId?: string;
  name?: string;
};

export enum BUDGET_VIEW_TYPE {
  ZONES = "zones",
  VENDORS = "vendors",
  MATERIALS = "materials",
}

type ProviderContextType = {
  viewType: BUDGET_VIEW_TYPE;
  projectAllowance: ProjectAllowanceFieldsFragment | null;
  materialsReport: ProjectReportPerMaterialFieldsFragment | null;
  vendorsReport: ProjectReportPerVendorFieldsFragment | null;
  zonesReport: ProjectReportPerMaterialZoneFieldsFragment | null;
  loadingAllowance: boolean;
  materialsLoading: boolean;
  vendorsLoading: boolean;
  zonesLoading: boolean;
  groupedByZones: boolean;
  setGroupedByZones: (grouped: boolean) => void;
  selectedZones: string[] | null;
  setSelectedZones: (items: string[]) => void;
  costCodes: string[] | null;
  setCostCodes: (items: string[]) => void;
  tags: string[] | null;
  setTags: (items: string[]) => void;
  showChart: boolean;
  setShowCart: (show: boolean) => void;
  startDate: number | undefined;
  setStartDate: (date: number | undefined) => void;
  endDate: number | undefined;
  setEndDate: (date: number | undefined) => void;
  sellerOrgLocationIds: string[] | null;
  setSellerOrgLocationIds: (items: string[]) => void;
  sellerOrgIds: string[] | null;
  setSellerOrgIds: (items: string[]) => void;
  costCodeOptions: ProjectCostCodeFieldsFragment[];
};

const ProviderContext = createContext<ProviderContextType>({
  viewType: BUDGET_VIEW_TYPE.ZONES,
  projectAllowance: null,
  materialsReport: null,
  vendorsReport: null,
  zonesReport: null,
  loadingAllowance: false,
  materialsLoading: false,
  vendorsLoading: false,
  zonesLoading: false,
  groupedByZones: false,
  setGroupedByZones: NoFunction,
  selectedZones: [],
  setSelectedZones: NoFunction,
  costCodes: [],
  setCostCodes: NoFunction,
  tags: [],
  setTags: NoFunction,
  showChart: false,
  setShowCart: NoFunction,
  startDate: undefined,
  setStartDate: NoFunction,
  endDate: undefined,
  setEndDate: NoFunction,
  sellerOrgLocationIds: [],
  setSellerOrgLocationIds: NoFunction,
  sellerOrgIds: [],
  setSellerOrgIds: NoFunction,
  costCodeOptions: [],
});

export const ProjectSpendingReportProvider: FC<{
  children: React.ReactNode;
  id?: string;
}> = ({ children, id }) => {
  const {
    data: projectAllowance,
    error: allowanceError,
    loading: loadingAllowance,
  } = useProjectAllowanceQuery({
    variables: { id: id ?? "" },
    skip: !id,
  });
  const viewState = useTableViewStore((state) => state.viewState);
  const { isZoneSpecific } = useBudgetStore(
    useShallow((state) => ({
      isZoneSpecific: state.isZoneSpecificBudget,
    })),
  );
  const [groupedByZones, setGroupedByZones] = useState<boolean>(isZoneSpecific);
  const [selectedZones, setSelectedZones] = useState<string[]>([]);
  const [costCodes, setCostCodes] = useState<Array<string>>([]);
  const [tags, setTags] = useState<Array<string>>([]);
  const [showChart, setShowCart] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<number>();
  const [endDate, setEndDate] = useState<number>();
  const [sellerOrgLocationIds, setSellerOrgLocationIds] = useState<string[]>(
    [],
  );
  const [sellerOrgIds, setSellerOrgIds] = useState<string[]>([]);

  useEffect(() => {
    setGroupedByZones(isZoneSpecific);
  }, [isZoneSpecific]);

  const viewType = useMemo(() => {
    switch (viewState) {
      case TableViewState.materials:
        return groupedByZones
          ? BUDGET_VIEW_TYPE.ZONES
          : BUDGET_VIEW_TYPE.MATERIALS;
      case TableViewState.vendors:
        return BUDGET_VIEW_TYPE.VENDORS;
      default:
        return BUDGET_VIEW_TYPE.ZONES;
    }
  }, [viewState, groupedByZones]);

  useErrorEffect(allowanceError);

  const { id: projId, projectId } = useParams();

  const costCodeIds = useMemo(
    () =>
      costCodes.map((costCodeId) =>
        costCodeId === UNSPECIFIED_COST_CODE_ID ? null : costCodeId,
      ),
    [costCodes],
  );

  const {
    data: materialsData,
    error: materialsError,
    loading: materialsLoading,
  } = useProjectReportPerMaterialQuery({
    variables: {
      input: {
        projectId: id || projectId || projId || "",
        costCodeIds,
        tagIds: tags,
        zoneIds: selectedZones.map((zoneId) =>
          zoneId === UNSPECIFIED_ZONE_ID ? null : zoneId,
        ),
        endDate,
        startDate,
        sellerOrgLocationIds,
        sellerOrgIds,
      },
    },
    skip:
      viewType !== BUDGET_VIEW_TYPE.MATERIALS || (!id && !projectId && !projId),
    fetchPolicy: "cache-and-network",
  });

  const {
    data: vendorsData,
    error: vendorsError,
    loading: vendorsLoading,
  } = useProjectReportPerVendorQuery({
    variables: {
      input: {
        projectId: id || projectId || projId || "",
        costCodeIds,
        tagIds: tags,
        endDate,
        startDate,
        sellerOrgIds,
      },
    },
    skip:
      viewType !== BUDGET_VIEW_TYPE.VENDORS || (!id && !projectId && !projId),
    fetchPolicy: "cache-and-network",
  });

  const {
    data: zonesData,
    error: zonesError,
    loading: zonesLoading,
  } = useProjectReportPerMaterialZoneQuery({
    variables: {
      input: {
        projectId: id || projectId || projId || "",
        costCodeIds,
        tagIds: tags,
        zoneIds: selectedZones.map((zoneId) =>
          zoneId === UNSPECIFIED_ZONE_ID ? null : zoneId,
        ),
        endDate,
        startDate,
        sellerOrgIds,
      },
    },
    skip: viewType !== BUDGET_VIEW_TYPE.ZONES || (!id && !projectId && !projId),
    fetchPolicy: "cache-and-network",
  });

  const error =
    viewType === BUDGET_VIEW_TYPE.MATERIALS
      ? materialsError
      : viewType === BUDGET_VIEW_TYPE.VENDORS
        ? vendorsError
        : zonesError;
  useErrorEffect(error);

  const { costCodes: orgCostCodes, formatCostCode } = useCostCodes();
  const { unassignedCostCode } = useUnspecifiedCostCode();

  const costCodeOptions = useMemo(() => {
    const allCostCodes = [
      ...orgCostCodes,
      { ...unassignedCostCode, formatted: formatCostCode(unassignedCostCode) },
    ];
    switch (viewType) {
      case BUDGET_VIEW_TYPE.MATERIALS:
        return getSpendingReportCostCodeOptions(
          materialsData?.projectReportPerMaterial?.costCodes.map(
            (costCodePerMaterial) => costCodePerMaterial.costCode,
          ) ?? [],
          allCostCodes,
        );
      case BUDGET_VIEW_TYPE.ZONES:
        return getSpendingReportCostCodeOptions(
          zonesData?.projectReportPerMaterialZone?.zones
            .flatMap((zone) => zone.costCodes)
            .map((costCode) => costCode.costCode) ?? [],
          allCostCodes,
        );
      case BUDGET_VIEW_TYPE.VENDORS:
        return getSpendingReportCostCodeOptions(
          vendorsData?.projectReportPerVendor?.costCodes.map(
            (costCodePerVendor) => costCodePerVendor.costCode,
          ) ?? [],
          allCostCodes,
        );
      default:
        return allCostCodes;
    }
  }, [
    viewType,
    materialsData?.projectReportPerMaterial,
    zonesData?.projectReportPerMaterialZone,
    vendorsData?.projectReportPerVendor,
    orgCostCodes,
    unassignedCostCode,
    formatCostCode,
  ]);

  return (
    <ProviderContext.Provider
      value={{
        viewType,
        projectAllowance: projectAllowance?.project?.allowance || null,
        materialsReport: materialsData?.projectReportPerMaterial || null,
        vendorsReport: vendorsData?.projectReportPerVendor || null,
        zonesReport: zonesData?.projectReportPerMaterialZone || null,
        loadingAllowance,
        materialsLoading,
        vendorsLoading,
        zonesLoading,
        groupedByZones,
        setGroupedByZones,
        selectedZones,
        setSelectedZones,
        costCodes,
        setCostCodes,
        tags,
        setTags,
        showChart,
        setShowCart,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        sellerOrgLocationIds,
        setSellerOrgLocationIds,
        sellerOrgIds,
        setSellerOrgIds,
        costCodeOptions,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSpendingReport = (): ProviderContextType =>
  useContext(ProviderContext);
