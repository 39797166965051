import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceReleaseItemCostCodeCheckbox } from "./hooks/useInvoiceReleaseItemCostCodeCheckbox";

const Container = tw.div`flex`;

type Props = {
  costCode: CategoryState<ExpandedReleaseItem>;
};

export const InvoiceReleaseItemCostCodeCheckbox: FC<Props> = ({ costCode }) => {
  const { checked, handleChange, hasSelectableItems } =
    useInvoiceReleaseItemCostCodeCheckbox(costCode);

  return (
    <If isTrue={hasSelectableItems}>
      <Container>
        <SelectionCheckbox
          testId={`invoice-cost-code-${costCode.name.toLowerCase().replaceAll(/\s+/g, "-")}`}
          checked={checked}
          setSelection={handleChange}
        />
      </Container>
    </If>
  );
};
