import { Address } from "@/common/components/address/Address";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ThreeStateResult } from "@/common/components/three-state-result/ThreeStateResult";
import {
  LgContainer,
  MdContainer,
  SmdFixedContainer,
  XlContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { useInventoryManagementFeature } from "@/contractor/pages/home/inventory-management/common/hooks/useInventoryManagementFeature";
import { WarehouseFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { DeleteWarehouse } from "./DeleteWarehouse";

const ItemName = tw.div`text-sm font-medium`;
const Item = tw.div`text-sm`;
const WarehouseNameContainer = tw(LgContainer)`col-span-full`;

export const useWarehousesConfiguration = () => {
  const { includeInventoryManagement } = useInventoryManagementFeature();

  const configuration: Array<GridCol<WarehouseFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: WarehouseNameContainer,
        item: ({ item }) => <ItemName>{item.name}</ItemName>,
        position: "none",
        header: <FormattedMessage id="WAREHOUSE_NAME" />,
      },
      {
        wrapper: XlContainer,
        item: ({ item }) => (
          <Item>
            <Address address={item.address} inline />
          </Item>
        ),
        position: "none",
        header: <FormattedMessage id="ADDRESS" />,
      },
      {
        wrapper: MdContainer,
        item: ({ item }) => (
          <NotNullableRenderer value={item.externalCode}>
            <Item>{item.externalCode}</Item>
          </NotNullableRenderer>
        ),
        position: "center",
        header: <FormattedMessage id="WAREHOUSE_ID" />,
      },
      {
        wrapper: SmdFixedContainer,
        item: ({ item }) => (
          <ThreeStateResult value={item.trackInventory} tagName={Item} />
        ),
        position: "center",
        header: <FormattedMessage id="TRACK_INVENTORY" />,
        hidden: !includeInventoryManagement,
      },
      {
        wrapper: XxsFixedContainer,
        item: ({ item }) => <DeleteWarehouse warehouse={item} />,
        position: "none",
      },
    ],
    [includeInventoryManagement],
  );

  return configuration;
};
