import { Price } from "@/common/components/price/Price";
import { TOTAL_PRICE_DECIMAL_POINTS } from "@/common/const";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import Decimal from "decimal.js";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

type DiscountViewProps = {
  discount?: {
    amount?: string | null | Decimal;
    percentage?: string | null;
  } | null;
  total?: number;
};

export const DiscountView: FC<DiscountViewProps> = ({
  discount,
  total = 0,
}) => {
  const percentage = discount?.percentage;
  const amount =
    discount?.amount || percentage
      ? Number(
          discount?.amount ||
            (percentage &&
              new DecimalSafe(total).mul(percentage).dividedBy(100)),
        )
      : null;
  return (
    <>
      {percentage || amount ? (
        <>
          {percentage != null ? (
            <>
              <Price
                price={amount}
                maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
                negative
              />{" "}
              ({percentage}%)
            </>
          ) : (
            <Price
              price={amount}
              maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
              negative
            />
          )}
        </>
      ) : (
        <FormattedMessage id="NONE" />
      )}
    </>
  );
};
