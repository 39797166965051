import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { Dialog } from "@/common/components/dialog/Dialog";
import { useExpandableFooterStore } from "@/common/components/footer/stores/useExpandableFooterStore";
import { If } from "@/common/components/if/If";
import { useImportExternalPos } from "@/common/components/import-external-po/hooks/useImportExternalPos";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useEnabledPoInSourceSystem } from "@/common/hooks/useEnabledPoInSourceSystem";
import { DateView } from "@/common/utils/dates/DateView";
import { routes } from "@/config/routes";
import { useInvoiceValidation } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceValidationProvider";
import { useReverseSyncPO } from "@/contractor/pages/home/release/components/connections/hooks/useReverseSyncPO";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { PoFormat } from "@/generated/graphql";
import { LockOutlined, Refresh } from "@mui/icons-material";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { MatchedOrderViewState } from "../../../../../enums/MatchedOrderViewState";
import { useConnectMissingPOItems } from "../../../../../hooks/useConnectMissingPOItems";
import { useInvoiceImportExternalPO } from "../../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceMatchedOrder } from "../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";

const LinkLikeStyled = tw(LinkLike)`flex items-center gap-1 text-blue-500`;
const Container = tw.div`flex gap-1 mb-5`;
const PoNumberContainer = tw.div`grid grid-flow-col items-center gap-1`;
const InnerContainer = tw.div`flex flex-col gap-0.5`;
const ClearButtonText = tw.div`text-xs font-normal text-blue-800`;
const DetailsGroup = tw.div`flex flex-row gap-1 text-xs`;
const Label = tw.div`font-medium`;
const Name = tw.div``;
const PoContainer = tw.div`flex flex-row items-center gap-2`;
const IconButtonBorderlessStyled = tw(
  IconButtonBorderless,
)`h-4 min-h-4 w-4 py-0`;

export const InvoiceOrderInputSelector: FC<PropsWithChildren> = ({
  children,
}) => {
  const intl = useIntl();
  const { invoice, updateInvoice, loading, setFooterState } =
    useInvoiceVerification();
  const { setOptions, setVariables } = useImportExternalPos();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const { setMatchedOrderViewState } = useInvoiceMatchedOrder();
  const { openModal } = useInvoiceImportExternalPO();
  const { connectMissingPOItems } = useConnectMissingPOItems();
  const { release } = useRelease();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { reverseSyncPO, reverseSyncingPO } = useReverseSyncPO();
  const { isPoEnabledInSourceSystem } = useEnabledPoInSourceSystem();
  const [showDetachDialog, setShowDetachDialog] = useState(false);
  const { setExpanded } = useExpandableFooterStore();

  const releasePath = useMemo(() => {
    if (invoice?.release?.id) {
      return generatePath(routes.delivery, {
        deliveryId: invoice?.release?.id,
      });
    }
  }, [invoice?.release?.id]);

  const onPOClick = useCallback(() => {
    setOptions({ forceFetchExternalPO: true });
    setVariables({ orderTypeId: release?.type?.id });
    openModal({
      skipPoList: true,
    });
  }, [release?.type.id, setVariables, setOptions, openModal]);

  const releaseHasOtherAssignedInvoices = useMemo(
    () =>
      release?.invoices.some(
        (releaseInvoice) => releaseInvoice.id !== invoice?.id,
      ),
    [release, invoice],
  );

  const clearInvoiceRelease = useCallback(async () => {
    if (invoice) {
      await updateInvoice({ clearRelease: true, id: invoice.id });
      setMatchedOrderViewState(MatchedOrderViewState.MATCH_ORDER);
      setFooterState(InvoiceFooterState.MATCH_ORDER);
      setExpanded(false);
    }
  }, [
    invoice,
    updateInvoice,
    setMatchedOrderViewState,
    setFooterState,
    setExpanded,
  ]);

  const clearInvoiceReleaseWithDeletion = useCallback(async () => {
    if (invoice) {
      await updateInvoice({
        clearRelease: true,
        id: invoice.id,
        archiveClearedRelease: true,
      });
      setMatchedOrderViewState(MatchedOrderViewState.MATCH_ORDER);
      setFooterState(InvoiceFooterState.MATCH_ORDER);
      setExpanded(false);
    }
  }, [
    invoice,
    updateInvoice,
    setMatchedOrderViewState,
    setFooterState,
    setExpanded,
  ]);

  const value = useMemo(() => {
    if (invoice?.release) {
      return (
        <>
          {intl.$t(
            { id: "ORDER_WITH_NUMBER" },
            { orderNumber: invoice?.release?.sequenceNumber },
          )}
        </>
      );
    }
    return null;
  }, [invoice, intl]);

  const includeRefreshButton = useMemo(
    () =>
      release?.type.poFormat === PoFormat.Detail &&
      release.items.some((i) => !i.poItemLink),
    [release],
  );

  const refresh = useCallback(async () => {
    if (invoice?.release?.poLink?.immutable) {
      reverseSyncPO(invoice.release.poLink.id, invoice.release.id);
    } else {
      await connectMissingPOItems(invoice?.release?.poLink?.id, invoice?.id);
      refetchInvoiceValidation();
    }
  }, [
    invoice?.id,
    invoice?.release?.id,
    invoice?.release?.poLink?.id,
    invoice?.release?.poLink?.immutable,
    connectMissingPOItems,
    refetchInvoiceValidation,
    reverseSyncPO,
  ]);

  const handleClearRelease = useCallback(() => {
    if (releaseHasOtherAssignedInvoices) {
      clearInvoiceRelease();
      setMatchedOrderViewState(MatchedOrderViewState.MATCH_ORDER);
      setFooterState(InvoiceFooterState.MATCH_ORDER);
      setExpanded(false);
      return;
    }
    setShowDetachDialog(true);
  }, [
    clearInvoiceRelease,
    setMatchedOrderViewState,
    setFooterState,
    setExpanded,
    releaseHasOtherAssignedInvoices,
  ]);

  const closeDetachDialog = useCallback(() => setShowDetachDialog(false), []);

  return (
    <Container>
      <If isTrue={value}>
        <InnerContainer>
          <PoNumberContainer>
            <LinkLikeStyled to={releasePath} disabled={!releasePath}>
              {value}
            </LinkLikeStyled>
            <ReleaseStatusChip
              status={invoice?.release?.status}
              type="small"
              releaseType={invoice?.release?.type}
            />
            {children}
            <If isTrue={!!invoice?.release}>
              <OutlinedButton
                $small
                className="h-6 min-w-16"
                onClick={handleClearRelease}
              >
                <FormattedMessage id="CLEAR" tagName={ClearButtonText} />
              </OutlinedButton>
              <Dialog
                cancelButtonText={intl.$t({ id: "CLOSE" })}
                confirmButtonText={intl.$t({
                  id: "INVOICE_RELEASE_DETACH_ONLY",
                })}
                loading={loading}
                customButtonText={intl.$t({
                  id: "INVOICE_RELEASE_DETACH_WITH_DELETION",
                })}
                handleConfirm={clearInvoiceRelease}
                handleCustomButtonAction={clearInvoiceReleaseWithDeletion}
                maxWidth="xl"
                includeWarningIcon
                handleCancel={closeDetachDialog}
                show={showDetachDialog}
                content={
                  <FormattedMessage id="INVOICE_RELEASE_DETACH_CONFIRMATION" />
                }
                closeOnConfirm
                closeOnCustom
              />
            </If>
          </PoNumberContainer>
          <DetailsGroup>
            <Label>
              <FormattedMessage id="DELIVERY" />:
            </Label>
            <Name>
              <DateView date={invoice?.release?.time} />
            </Name>
          </DetailsGroup>
          <DetailsGroup>
            <Label>
              <FormattedMessage id="ORDER_TYPE" />:
            </Label>
            <Name>{invoice?.release?.type.name}</Name>
          </DetailsGroup>
          <If isTrue={invoice?.release?.poNumber}>
            <PoContainer>
              <LinkLikeStyled
                disabled={!isPoEnabledInSourceSystem}
                className={!isPoEnabledInSourceSystem ? "text-black" : ""}
                onClick={onPOClick}
              >
                <If isTrue={invoice?.release?.poLink?.immutable}>
                  <Tooltip
                    id="release-immutable-tooltip"
                    element={<LockOutlined className="text-xl" />}
                  >
                    <FormattedMessage
                      id="ORDER_IMMUTABLE_DESCRIPTION"
                      values={{
                        sourceSystem:
                          release?.poLink?.sourceSystem ??
                          intl.$t({ id: "SOURCE_SYSTEM" }),
                      }}
                    />
                  </Tooltip>
                </If>
                <FormattedMessage id="PO_HASH" />
                {invoice?.release?.poNumber}
              </LinkLikeStyled>
              <If
                isTrue={
                  !!invoice?.release?.poLink?.immutable ||
                  (includeRefreshButton &&
                    !hasFeatureInConnectedSourceSystem(
                      IntegrationFeature.POAlwaysMutable,
                    ))
                }
              >
                <IconButtonBorderlessStyled
                  onClick={refresh}
                  disabled={reverseSyncingPO}
                >
                  <Refresh />
                </IconButtonBorderlessStyled>
              </If>
            </PoContainer>
          </If>
        </InnerContainer>
      </If>
    </Container>
  );
};
