import { Dialog } from "@/common/components/dialog/Dialog";
import { Loader } from "@/common/components/loader/Loader";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { BuyoutStatus } from "@/generated/graphql";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { BuyoutGroupedProvider } from "../../../buyout/components/document/providers/BuyoutGroupedProvider";
import { ProjectItemsZonesProvider } from "../../../project/providers/ProjectItemsZonesProvider";
import { useEnsureBuyoutStatus } from "../../hooks/hooks";
import { useRelease } from "../../providers/ReleaseProvider";
import {
  AddItemsToReleaseProvider,
  SelectableItem,
  useAddItemsToRelease,
} from "./AddItemsToReleaseProvider";
import { AddFromBuyoutToReleaseList } from "./release-items-list/AddFromBuyoutToReleaseList";
import { useAddToReleaseFromBuyoutConfiguration } from "./release-items-list/AddToReleaseFromBuyout.configuration";
import {
  ReleaseProjectProvider,
  useReleaseProject,
} from "./release-items-list/ReleaseProjectProvider";

const Container = tw.div`w-[calc(90vw)] 3xl:w-[calc(70vw)] max-h-[calc(80vh)]`;

type AddItemsToReleaseDialogProps = {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  callback?: (items: SelectableItem[]) => void;
};

const AddItemsToReleaseWithProvider: FC<AddItemsToReleaseDialogProps> = ({
  visible = true,
  setVisible,
  callback,
}) => {
  const { release, updating } = useRelease();
  const intl = useIntl();
  const { buyout } = useContractorBuyout();
  const { setSuccessAlert, setWarningAlert } = useSnackbar();
  const { addItemsToRelease, selectedItems, setSelectedItems } =
    useAddItemsToRelease();
  const { project } = useReleaseProject();

  useEffect(() => {
    if (visible) {
      setSelectedItems([]);
    }
  }, [setSelectedItems, visible]);

  useEnsureBuyoutStatus(
    buyout,
    BuyoutStatus.Requested,
    BuyoutStatus.Active,
    BuyoutStatus.Draft,
  );
  const { configuration } = useAddToReleaseFromBuyoutConfiguration(project);

  if (!release) {
    return;
  }

  const handleCancel = () => {
    setVisible?.(false);
  };

  const addItems = () => {
    if (selectedItems.length === 0) {
      setWarningAlert(<FormattedMessage id={`NOTHING_TO_ADD`} />);
      return;
    }
    if (callback) {
      callback(selectedItems);
    } else {
      const result = addItemsToRelease();
      if (!result) {
        return;
      }
      setSuccessAlert(intl.$t({ id: "ITEMS_ADDED_TO_RELEASE" }));
    }
    handleCancel();
  };

  return (
    <Dialog
      title={<FormattedMessage id={"ADD_ITEMS_TO_RELEASE_FROM_BUYOUT"} />}
      confirmButtonText={intl.$t(
        {
          id:
            selectedItems.length === 0
              ? "ADD_TO_RELEASE"
              : "ADD_TO_RELEASE_WITH_COUNT",
        },
        { count: selectedItems.length },
      )}
      handleConfirm={addItems}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
      }}
      extraButtonMargin={false}
      loading={updating}
      handleCancel={handleCancel}
      maxWidth={false}
      show={visible}
      content={
        <Container>
          <AddFromBuyoutToReleaseList
            columns={configuration}
            buyoutId={buyout?.id}
          />
        </Container>
      }
    />
  );
};

const AddItemsToReleaseWithProjectProvider: FC<AddItemsToReleaseDialogProps> = (
  props,
) => {
  const { project } = useReleaseProject();

  if (!project) {
    return <Dialog show content={<Loader loading />} />;
  }

  return (
    <ProjectItemsZonesProvider project={project}>
      <AddItemsToReleaseProvider>
        <AddItemsToReleaseWithProvider {...props} />
      </AddItemsToReleaseProvider>
    </ProjectItemsZonesProvider>
  );
};

export const AddItemsToReleaseFromBuyoutDialog: FC<
  AddItemsToReleaseDialogProps
> = (props) => {
  const { buyout } = useContractorBuyout();
  if (!buyout || !props.visible) {
    return;
  }

  return (
    <ReleaseProjectProvider id={buyout?.project.id}>
      <BuyoutGroupedProvider buyout={buyout}>
        <AddItemsToReleaseWithProjectProvider {...props} />
      </BuyoutGroupedProvider>
    </ReleaseProjectProvider>
  );
};
