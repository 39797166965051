import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceReleaseItemCheckbox } from "./hooks/useInvoiceReleaseItemCheckbox";

type Props = {
  item: ExpandedReleaseItem;
};

const Container = tw.div`flex`;

export const InvoiceReleaseItemCheckbox: FC<Props> = ({ item }) => {
  const { checked, handleChange, hidden } = useInvoiceReleaseItemCheckbox(item);

  return (
    <If isTrue={!hidden}>
      <Container>
        <SelectionCheckbox
          itemId={item.id}
          items={item.invoiceItems}
          checked={checked}
          setSelection={handleChange}
        />
      </Container>
    </If>
  );
};
