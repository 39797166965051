import { SequenceStepper } from "@/common/components/sequence-stepper/SequenceStepper";
import { useInvoiceSequence } from "../../../scanned-invoices/providers/InvoiceSequenceProvider";
import { useInvoiceSequenceStepper } from "./hooks/useInvoiceSequenceStepper";

export const InvoiceSequenceStepper = () => {
  const { step, setStep, sequenceIds, redirectToInvoices } =
    useInvoiceSequence();
  const { prevStepCallback, nextStepCallback } = useInvoiceSequenceStepper();

  return (
    <SequenceStepper
      titleId="INVOICES"
      step={step}
      setStep={setStep}
      sequenceIds={sequenceIds}
      exitSequence={redirectToInvoices}
      prevStepCallback={prevStepCallback}
      nextStepCallback={nextStepCallback}
    />
  );
};
