import { TextField } from "@/common/components/textfield/TextField";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { CostCode, useCostCodesList } from "../providers/CostCodesListProvider";

type Props = {
  code: CostCode;
  prop: "code" | "description";
  placeholder: string;
  required?: boolean;
  readonly?: boolean;
  error: boolean;
};

export const CostCodeInput: FC<Props> = ({
  code,
  prop,
  placeholder,
  required,
  readonly,
}) => {
  const intl = useIntl();
  const { costCodes, setCostCodes } = useCostCodesList();
  const [costCode, setCostCode] = useState(code[prop]);

  const onChange = (e: React.FocusEvent<HTMLInputElement>) => {
    setCostCodes(
      costCodes.map((c) =>
        c.id === code.id ? { ...c, [prop]: e.target.value } : c,
      ),
    );
  };

  return (
    <TextField
      size="small"
      className="w-full"
      value={costCode}
      required={required}
      placeholder={intl.$t({ id: placeholder })}
      onBlur={onChange}
      onChange={(e) => setCostCode(e.target.value)}
      staticText={readonly}
      error={code.isSubmitted && !code[prop]}
    />
  );
};
