import { InvoiceValidationFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";

const INVOICE_VALIDATION_KEYS = [
  "errors",
  "costCodesNotConnected",
  "wbsMissingInPO",
  "itemsMissingCostCodes",
  "costCodesMissingInPO",
  "itemsMissingTags",
  "itemsNotConnected",
  "itemsExceedingRemainingAmount",
];

export const useValidateInvoice = () => {
  const isValidInvoice = useCallback(
    (invoiceValidation: InvoiceValidationFieldsFragment | undefined) => {
      return INVOICE_VALIDATION_KEYS.every((key) => {
        const value =
          invoiceValidation?.[key as keyof InvoiceValidationFieldsFragment];
        return !value || (value as []).length === 0;
      });
    },
    [],
  );

  return { isValidInvoice };
};
