import { useAllItemsCheckboxSelection } from "@/common/components/pagination/hooks/useAllItemsCheckboxSelection";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceEmails } from "../../hooks/useInvoiceEmails";
import { useInvoiceEmailsSequence } from "../../hooks/useInvoiceEmailsSequence";

const Container = tw.div`
  flex -ml-2
`;

export const InvoiceEmailsAllItemsCheckbox: FC = () => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useInvoiceEmailsSequence();
  const { invoiceEmails } = useInvoiceEmails();
  const { checked, handleChange } = useAllItemsCheckboxSelection(
    invoiceEmails.filter((email) => !email.archivedAt),
    selectedEntities,
    (emails) => {
      setSelectedEntities(emails);
    },
  );

  return (
    <Container>
      <SelectionCheckbox
        items={sequenceIds}
        setSelection={handleChange}
        checked={checked}
      />
    </Container>
  );
};
