import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useUser } from "@/common/providers/UserProvider";
import { useEquipmentItemsQuery } from "@/generated/graphql";
import { useMemo } from "react";

export const useEquipmentItems = () => {
  const { isContractor } = useUser();
  const { data, error, loading } = useEquipmentItemsQuery({
    skip: !isContractor,
  });
  useErrorEffect(error);

  const equipmentItems = useMemo(() => {
    return data?.viewer?.org?.equipmentItems || [];
  }, [data?.viewer?.org?.equipmentItems]);

  return {
    equipmentItems,
    loading,
  };
};
