import { DECIMAL_MAX_FRACTION_DIGITS } from "@/common/const";
import {
  RendererFunctionType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";
import { useRenderHelpers } from "./useRenderHelpers";

export const useUnitPriceCopyRenderer = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { getMetadata } = useColumnMapper();
  const {
    applyTooltip,
    applyClasses,
    checkReadOnly,
    formatPrice,
    addIconWithTooltip,
  } = useRenderHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const formattedValue = formatPrice(row, instance, value);

      textRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      const meta = getMetadata(row);

      if (
        meta?.copiedPrice &&
        value &&
        Number(value).toFixed(2) === Number(meta.copiedPrice).toFixed(2)
      ) {
        applyClasses(td, "htCenter htMiddle relative bg-green-100");

        td.innerText = "";
        const partText = document.createElement("span");
        partText.className = "text-green-800 pr-2";
        partText.innerText = formattedValue;
        td.appendChild(partText);

        applyTooltip(
          partText,
          intl.$t({ id: "COPIED_PRICE_TOOLTIP" }, { price: formattedValue }),
        );
      }

      const samePrice =
        Math.round(Number(meta?.previousPrice) * 1e6) ===
        Math.round(Number(meta?.copiedPrice) * 1e6);

      if (!samePrice && meta?.previousPrice) {
        addIconWithTooltip({
          element: td,
          tooltipText: intl.$t(
            { id: "PREVIOUS_PRICE_TOOLTIP" },
            {
              price: formatCurrency(meta?.previousPrice as number, {
                maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
              }),
            },
          ),
          iconClasses: "text-green-800 hover:text-green-600",
        });
      }

      checkReadOnly(td, row, instance, cellProperties);
    },
    [
      formatPrice,
      getMetadata,
      checkReadOnly,
      applyClasses,
      applyTooltip,
      intl,
      formatCurrency,
      addIconWithTooltip,
    ],
  );

  return renderer;
};
