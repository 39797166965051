import { AssetDate } from "@/common/components/asset-date/AssetDate";
import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import {
  DuplicatedInvoiceInfo,
  isDuplicatedInvoice,
} from "@/common/components/invoices/common/DuplicatedInvoiceInfo";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { routes } from "@/config/routes";
import { AssetFieldsFragment, Invoice } from "@/generated/graphql";
import { FC, ReactNode, useCallback } from "react";
import { useIntl } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { AddInvoiceBtn } from "../specify-details/components/add-invoice-btn/AddInvoiceBtn";

type Props = {
  release: {
    invoices: (Pick<
      Invoice,
      "id" | "number" | "vendorDuplicate" | "orgDuplicate"
    > & {
      documents: {
        asset: AssetFieldsFragment;
      }[];
    })[];
  };
};

const Container = tw.div`flex gap-1 items-center`;
const InvoiceNumber = tw.div`${(props: { $error?: boolean }) => props.$error && "text-red-500"}`;
const InvoiceInfoContainer = tw.div`flex flex-col items-center`;

export const ReleaseInvoices: FC<Props> = ({ release }) => {
  const intl = useIntl();

  const getInvoiceByIndex = useCallback(
    (index: number = 0) => release.invoices[index],
    [release],
  );

  const getImageWrapper = useCallback(
    (_: string, children: ReactNode, index: number) => {
      const currentInvoice = getInvoiceByIndex(index);
      return (
        <InvoiceInfoContainer>
          <LinkLike
            to={generatePath(routes.invoiceVerification, {
              invoiceId: currentInvoice?.id,
            })}
          >
            {children}
          </LinkLike>
        </InvoiceInfoContainer>
      );
    },
    [getInvoiceByIndex],
  );

  const getInvoiceDetails = useCallback(
    (_: string, createDate?: number, index?: number) => {
      const currentInvoice = getInvoiceByIndex(index);
      return (
        <InvoiceInfoContainer>
          <Container>
            <InvoiceNumber $error={isDuplicatedInvoice(currentInvoice)}>
              #{currentInvoice?.number}
            </InvoiceNumber>
            <DuplicatedInvoiceInfo invoice={currentInvoice} />
          </Container>
          <AssetDate date={createDate} />
        </InvoiceInfoContainer>
      );
    },
    [getInvoiceByIndex],
  );

  if (release.invoices.length === 0) {
    return null;
  }

  return (
    <AssetsCard
      headerClassName="bg-blue-100"
      title={intl.$t({ id: "INVOICES" })}
      expanded={true}
      includeTooltip={false}
      includeNavigation={false}
      assets={[
        ...release.invoices.map(
          (invoice) => invoice.documents[invoice.documents.length - 1].asset,
        ),
      ]}
      imageDetails={getInvoiceDetails}
      getImageWrapper={getImageWrapper}
    >
      <AddInvoiceBtn />
    </AssetsCard>
  );
};
