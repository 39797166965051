import { isItemExpired } from "@/common/components/expiration-picker/utils/isItemExpired";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";

export const useBuyoutItemUnitPrice = (item: BuyoutItemFieldsFragment) => {
  const isExpired = useMemo(() => {
    if (item.expirationDate) {
      return isItemExpired(new Date(item.expirationDate));
    }
    return false;
  }, [item.expirationDate]);

  return { isExpired };
};
