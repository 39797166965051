import {
  AssetFieldsFragment,
  PageRangeFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";
import { PageRangeWithExcludedPages } from "../SplittingInvoicesWizardProvider";
import { isPageInRange } from "../utils/isPageInRange";

export const useSplittingInvoices = () => {
  const setInitialPageRange = useCallback(
    (pageRanges: PageRangeWithExcludedPages[]) => {
      const newPageRanges: Array<PageRangeWithExcludedPages> = [];

      if (pageRanges.length) {
        const lastPage = pageRanges[pageRanges.length - 1].end;

        for (let i = 1; i <= lastPage; i++) {
          const isPageExcluded = pageRanges.some(
            (pageRange) =>
              pageRange.start === i &&
              pageRange.end === i &&
              pageRange.excluded,
          );

          if (
            !pageRanges.some(
              (pageRange) => pageRange.start <= i && pageRange.end >= i,
            ) ||
            isPageExcluded
          ) {
            newPageRanges.push({
              start: i,
              end: i,
              excluded: true,
            });
          } else {
            const existingPageRange = pageRanges.find(
              (pageRange) => pageRange.start <= i && pageRange.end >= i,
            );

            if (
              existingPageRange &&
              !newPageRanges.some(
                (p) =>
                  p.start === existingPageRange.start &&
                  p.end === existingPageRange.end,
              )
            ) {
              newPageRanges.push({ ...existingPageRange, excluded: false });
            }
          }
        }
      }

      return newPageRanges;
    },
    [],
  );

  const getPageRangeWithExcludedPages = useCallback(
    (asset: AssetFieldsFragment, pageRange: PageRangeFieldsFragment) => {
      if (!asset.pages?.length) {
        return [pageRange];
      }

      const pages: PageRangeWithExcludedPages[] = [];

      for (let i = 1; i <= asset.pages.length; i++) {
        if (!isPageInRange(i, pageRange)) {
          pages.push({ start: i, end: i, excluded: true });
        } else if (
          !pages.some(
            (p) => p.start === pageRange.start && p.end === pageRange.end,
          )
        ) {
          pages.push(pageRange);
        }
      }

      return pages;
    },
    [],
  );

  return { setInitialPageRange, getPageRangeWithExcludedPages };
};
