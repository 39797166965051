import { Checkbox } from "@/common/components/checkbox/Checkbox";
import { If } from "@/common/components/if/If";
import { TemplateVariablesEnum } from "@/contractor/pages/admin/integrations/enums/TemplateVariables.enum";
import { useTemplatePreviewStore } from "@/contractor/pages/admin/integrations/store/useTemplatePreviewStore";
import { useMemo, useState } from "react";
import { FaClipboardCheck } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";

const Container = tw.div`grid grid-cols-[1fr_auto] gap-4 min-w-max`;
const Suggestions = tw.div`grid gap-2 items-start content-start`;
const SuggestionsTitle = tw.div`grid grid-cols-[1fr_auto] items-center gap-6 mt-1 font-medium`;
const SuggestionsSubtitle = tw.div`text-sm text-gray-500 mb-4`;
const SuggestionsList = tw.div`grid gap-2`;
const SuggestionListItem = tw.div`grid grid-cols-[1fr_1fr] items-center gap-2`;
const DefaultValue = tw.div`w-full text-xs border-none p-2.5 bg-gray-100`;
const HeaderLabel = tw.div`text-xs font-medium`;

type SuggestionButtonProps = {
  $currentTemplate: boolean;
};

const SuggestionButton = tw.button<SuggestionButtonProps>`
  grid grid-cols-[1fr_auto] items-center gap-2 pl-2 pr-4 py-2 text-sm text-left bg-blue-100 hover:bg-blue-200 rounded-md transition-colors w-fit
  ${({ $currentTemplate }) => ($currentTemplate ? "bg-blue-500 text-white pointer-events-none pr-2" : "")}
`;

const IncludeConditional = tw.button`
  grid grid-cols-[1fr_auto] items-center gap-2 pl-2 pr-4 py-1 text-sm text-left rounded-md transition-colors w-fit
`;
const additionalVariables = ["InvoiceDownloadURL", "InvoiceShortURL"];

export const TemplateVariables = () => {
  const [currentTemplate, setCurrentTemplate] = useState<string>("");
  const [includeConditional, setIncludeConditional] = useState<boolean>(true);

  const { updateDefaults } = useTemplatePreviewStore(
    useShallow((state) => ({
      updateDefaults: state.updateDefaults,
    })),
  );
  const formattedVariables = useMemo(
    () =>
      Object.keys(TemplateVariablesEnum)
        .map((variableKey) => ({
          label: `.${variableKey}`,
          value: variableKey,
          template: includeConditional
            ? `{{if .${variableKey}}}\n  {{.${variableKey}}}\n{{end}}`
            : `{{.${variableKey}}}`,
        }))
        .concat(
          additionalVariables.map((variable) => ({
            label: `.${variable}`,
            value: variable,
            template: includeConditional
              ? `{{if .${variable}}}\n  {{.${variable}}}\n{{end}}`
              : `{{.${variable}}}`,
          })),
        ),
    [includeConditional],
  );

  const [defaultValues, setDefaultValues] = useState<Record<string, string>>(
    Object.keys(TemplateVariablesEnum).reduce(
      (acc, variable) => {
        acc[variable] =
          TemplateVariablesEnum[variable as keyof typeof TemplateVariablesEnum];
        return acc;
      },
      {} as Record<string, string>,
    ),
  );

  const copyToClipboard = (template: string) => {
    setCurrentTemplate(template);
    navigator.clipboard.writeText(template);
  };

  const onDefaultValueChange = (value: string, key: string) => {
    const newDefaultValues = {
      ...defaultValues,
      [key]: value,
    };
    setDefaultValues(newDefaultValues);
    updateDefaults(newDefaultValues as Record<TemplateVariablesEnum, string>);
  };

  return (
    <Container>
      <Suggestions>
        <SuggestionsTitle>
          <FormattedMessage id="AVAILABLE_VARIABLES" />
          <IncludeConditional
            onClick={() => setIncludeConditional(!includeConditional)}
          >
            <Checkbox
              checked={includeConditional}
              onChange={() => setIncludeConditional(!includeConditional)}
            />
            <FormattedMessage id="INCLUDE_CONDITIONAL" />
          </IncludeConditional>
        </SuggestionsTitle>
        <SuggestionsSubtitle>
          <FormattedMessage id="AVAILABLE_VARIABLES_SUBTITLE" />
        </SuggestionsSubtitle>
        <SuggestionsList>
          <SuggestionListItem>
            <HeaderLabel>
              <FormattedMessage id="VARIABLE_NAME" />
            </HeaderLabel>
            <HeaderLabel>
              <FormattedMessage id="PREVIEW_VALUE" />
            </HeaderLabel>
          </SuggestionListItem>
          {formattedVariables.map(({ label, value, template }) => (
            <SuggestionListItem key={label}>
              <SuggestionButton
                key={label}
                onClick={() => copyToClipboard(template)}
                title={template}
                $currentTemplate={currentTemplate === template}
              >
                {label}
                {currentTemplate === template && (
                  <FaClipboardCheck className="h-4 w-4" />
                )}
              </SuggestionButton>
              <If isTrue={defaultValues[value]}>
                <DefaultValue
                  contentEditable
                  suppressContentEditableWarning
                  onBlur={(e) =>
                    onDefaultValueChange(e.target.innerText, value)
                  }
                >
                  {defaultValues[value]}
                </DefaultValue>
              </If>
            </SuggestionListItem>
          ))}
        </SuggestionsList>
      </Suggestions>
    </Container>
  );
};
