import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { InputOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ImportItemsPanel } from "./ImportItemsPanel";

const Container = tw.div`flex flex-row gap-2 items-center cursor-pointer text-blue-500 hover:text-blue-300`;
const ImportIcon = tw(InputOutlined)`rotate-180`;
const ImportText = tw.div`text-right w-30 font-normal text-xs`;

export const ImportItemsButton = () => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <Container onClick={() => togglePanel(true)}>
          <FormattedMessage
            id="IMPORT_ITEMS_FROM_ANOTHER_PROJECT"
            tagName={ImportText}
          />
          <ImportIcon />
        </Container>
      )}
      content={(togglePanel) => (
        <ImportItemsPanel onClose={() => togglePanel(false)} />
      )}
    />
  );
};
