import { routes } from "@/config/routes";
import { ValidationType } from "@/contractor/pages/home/release/components/connections/components/common/ExportErrorList";
import { useCallback, useMemo } from "react";
import { generatePath } from "react-router";
import { useInvoiceReleaseValidation } from "../../../../../invoice-verification/hooks/useInvoiceValidation";
import { InvoiceReleaseDetailsProps } from "../types/InvoiceReleaseDetailsProps";

export const useInvoiceReleaseDetails = ({
  invoice,
  includedValidations,
  linking,
}: Pick<
  InvoiceReleaseDetailsProps,
  "invoice" | "includedValidations" | "linking"
>) => {
  const { validations } = useInvoiceReleaseValidation(
    invoice,
    invoice?.poNumber || invoice?.release?.poNumber?.toString() || "",
  );

  const generateReleasePath = useCallback(() => {
    if (invoice.release) {
      return generatePath(routes.delivery, { deliveryId: invoice.release?.id });
    }

    return "";
  }, [invoice.release]);

  const generateInvoicePath = useCallback(
    () =>
      generatePath(routes.invoiceVerification, {
        invoiceId: invoice?.id,
      }),
    [invoice?.id],
  );

  const errorsAndWarnings = useMemo(
    () => includedValidations.filter((v) => v.condition),
    [includedValidations],
  );

  const errors = useMemo(
    () =>
      includedValidations.filter(
        (v) => v.condition && v.validationType !== ValidationType.Warning,
      ),
    [includedValidations],
  );

  const uniqueValidations = useMemo(
    () =>
      [...validations, ...errorsAndWarnings].filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
      ),
    [validations, errorsAndWarnings],
  );

  const hidden = useMemo(() => linking && errors.length > 0, [linking, errors]);

  return {
    generateReleasePath,
    generateInvoicePath,
    errorsAndWarnings,
    uniqueValidations,
    hidden,
  };
};
