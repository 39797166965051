import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { WizardModalFooterButton } from "@/common/components/wizard-modal/components/WizardModalFooterButton";
import { useInvoiceCreation } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceCreationProvider";
import {
  InvoiceEmailFieldsFragment,
  InvoiceEmailsDocument,
  PageRange,
  useUpdateInvoiceEmailAttachmentMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionPromise } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useInvoiceEmails } from "../../../email-invoices/hooks/useInvoiceEmails";
import { useInvoiceVerification } from "../../../invoice-verification/providers/InvoiceVerificationProvider";
import { useInvoiceStats } from "../../hooks/useInvoiceStats";
import { useSplitInvoiceDocument } from "../../hooks/useSplitInvoiceDocument";
import {
  InvoiceSequenceData,
  useInvoiceSequence,
} from "../../providers/InvoiceSequenceProvider";
import { InvoiceEmailFooterInfo } from "./InvoiceEmailFooterInfo";
import { PageRanges } from "./PageViewerPanel";
import { useSplittingInvoices } from "./hooks/useSplittingInvoices";
import { AnimatedLoading } from "./steps/AnimatedLoading";
import { DocumentViewer } from "./steps/DocumentViewer";
import { ImportSuccessful } from "./steps/ImportSuccessful";
import { InvoiceEmailContent } from "./steps/InvoiceEmailContent";
import { SplittingInvoiceFooterActions } from "./steps/SplittingInvoiceFooterActions";

export enum SplitType {
  Split = 1,
  Restructure = 2,
}

export type PageRangeWithExcludedPages = PageRange & {
  excluded?: boolean;
};

type ProviderContextType = {
  pages: WizardModalPage[];
  wizardOpened: boolean;
  openWizard: (args: {
    initialPage?: number;
    urls: string[];
    pageRanges?: PageRangeWithExcludedPages[];
    type?: SplitType;
    invoiceEmail?: InvoiceEmailFieldsFragment;
    locationId?: string;
  }) => void;
  closeWizard: () => void;
  handleInspectAttachments: () => void;
  handleSaveWizardData: () => void;

  selectedPage?: number;
  setSelectedPage: (page: number) => void;
  currentUrl: string;
  urls: string[];
  pageRanges: PageRangeWithExcludedPages[];
  setPageRanges: (pageRanges: PageRangeWithExcludedPages[]) => void;

  selectedPages: number[];
  setSelectedPages: (pages: number[]) => void;
  splitting: boolean;
  type: SplitType;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  wizardOpened: false,
  openWizard: NoFunction,
  closeWizard: NoFunction,
  handleInspectAttachments: NoFunction,
  handleSaveWizardData: NoFunctionPromise,
  selectedPage: undefined,
  setSelectedPage: NoFunction,
  currentUrl: "",
  urls: [],
  pageRanges: [],
  setPageRanges: NoFunction,
  selectedPages: [],
  setSelectedPages: NoFunction,
  splitting: false,
  type: SplitType.Split,
});

export const SplittingInvoicesWizardProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { setStep, setNestedStep, moveToNextStep, moveToPreviousStep } =
    useNestedStepper();
  const { updateInvoice, invoice } = useInvoiceVerification();
  const { splitInvoiceDocument, loading } = useSplitInvoiceDocument();

  const [wizardOpened, setWizardOpened] = useState(false);
  const [pageRanges, setPageRanges] = useState<PageRangeWithExcludedPages[]>(
    [],
  );
  const { createInvoices, creating } = useInvoiceCreation();
  const { setSelectedInvoices, selectedInvoices } = useInvoiceSequence();

  const [urls, setUrls] = useState<string[]>([]);
  const [currentUrl, setCurrentUrl] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);
  const [splitting, setSplitting] = useState(false);
  const [locationId, setLocationId] = useState<string | null | undefined>(null);
  const [type, setType] = useState<SplitType>(SplitType.Split);
  const [invoiceEmail, setInvoiceEmail] =
    useState<InvoiceEmailFieldsFragment | null>(null);
  const { refetch: refetchInvoiceEmail } = useInvoiceEmails({
    skipQuery: true,
  });
  const { refetch: refetchInvoiceStats } = useInvoiceStats();
  const { setInitialPageRange } = useSplittingInvoices();

  const handleCreateInvoices = useCallback(async () => {
    const result = await createInvoices({
      assetUrl: currentUrl,
      pageRanges: pageRanges
        .filter((p) => !p.excluded)
        .map((p) => ({
          start: p.start,
          end: p.end,
        })),
      orgLocationId: locationId,
    });
    if (result) {
      setSelectedInvoices(result as InvoiceSequenceData[], result.length > 1);
    }
    return result;
  }, [createInvoices, currentUrl, locationId, pageRanges, setSelectedInvoices]);

  const [updateInvoiceEmailAttachment] =
    useUpdateInvoiceEmailAttachmentMutation();
  const excludeInvoiceAttachment = useCallback(async () => {
    const invoiceEmailAttachment = invoiceEmail?.attachments.find(
      (a) => a.asset.url === currentUrl,
    );
    if (
      invoiceEmailAttachment &&
      (await updateInvoiceEmailAttachment({
        variables: {
          input: {
            id: invoiceEmailAttachment.id,
            rejected: true,
          },
        },
        refetchQueries: [{ query: InvoiceEmailsDocument }],
      })) &&
      urls.indexOf(currentUrl) < urls.length - 1
    ) {
      const newIndex = urls.indexOf(currentUrl) + 1;
      setCurrentUrl(urls[newIndex]);
      const ranges = await splitInvoiceDocument({
        assetUrl: urls[newIndex],
      });
      if (ranges) {
        setPageRanges(ranges);
      }
    } else {
      moveToNextStep();
    }
  }, [
    currentUrl,
    urls,
    invoiceEmail,
    moveToNextStep,
    splitInvoiceDocument,
    updateInvoiceEmailAttachment,
  ]);

  const setInitialPageRangeAndSetState = useCallback(
    (pageRanges: PageRangeWithExcludedPages[]) => {
      const newPageRanges = setInitialPageRange(pageRanges);
      setPageRanges(newPageRanges);
    },
    [setInitialPageRange],
  );

  const openWizard = useCallback(
    async ({
      initialPage,
      urls,
      pageRanges: initialPageRanges,
      type = SplitType.Split,
      invoiceEmail,
      locationId,
    }: {
      initialPage?: number;
      urls: string[];
      pageRanges?: PageRange[];
      type?: SplitType;
      invoiceEmail?: InvoiceEmailFieldsFragment;
      locationId?: string;
    }) => {
      setStep(initialPage ?? 0);
      setUrls(urls);
      setCurrentUrl(urls[0]);
      setSelectedPages([]);
      setWizardOpened(true);
      setType(type);
      setInvoiceEmail(invoiceEmail ?? null);
      if (type !== SplitType.Restructure) {
        setSelectedInvoices([], true);
      }
      setLocationId(locationId);
      if (initialPageRanges) {
        setInitialPageRangeAndSetState(initialPageRanges);
      } else {
        setSplitting(true);
        let ranges = null;
        let i = 0;
        while (!ranges) {
          ranges = await splitInvoiceDocument({
            assetUrl: urls[i],
          });
          setCurrentUrl(urls[i]);
          i++;
        }
        setInitialPageRangeAndSetState(ranges);
        setSplitting(false);
      }
    },
    [
      setStep,
      setSelectedInvoices,
      setInitialPageRangeAndSetState,
      splitInvoiceDocument,
    ],
  );

  const resetWizard = useCallback(() => {
    setStep(0);
    setNestedStep(0);
    setInvoiceEmail(null);
  }, [setStep, setNestedStep, setInvoiceEmail]);

  const closeWizard = useCallback(() => {
    setWizardOpened(false);
    resetWizard();
  }, [resetWizard]);

  const handleUpdateInvoice = useCallback(async () => {
    if (!invoice) {
      return;
    }
    const result = await updateInvoice(
      {
        id: invoice.id,
        pageRange: pageRanges
          .filter((p) => !p.excluded)
          .map((p) => ({
            start: p.start,
            end: p.end,
          }))[0],
      },
      { includeDocuments: true },
    );
    if (result?.updateInvoice) {
      closeWizard();
    }
  }, [closeWizard, invoice, pageRanges, updateInvoice]);

  const pages: WizardModalPage[] = useMemo(() => {
    const p: WizardModalPage[] = [];
    const closeButton: WizardModalFooterButton = {
      text: intl.$t({ id: "CLOSE" }),
      onClick: closeWizard,
      type: "outlined",
      className: "mr-2 w-40 max-w-60",
    };

    if (invoiceEmail) {
      p.push({
        title: null,
        pages: [
          {
            component: <InvoiceEmailContent invoiceEmail={invoiceEmail} />,
            onCloseClick: closeWizard,
            hideHeader: true,
            footerButtonsConfig: [
              {
                type: "text",
                text: "",
                children: (
                  <InvoiceEmailFooterInfo invoiceEmail={invoiceEmail} />
                ),
              },
              closeButton,
            ],
          },
        ],
      });
    }
    const footerButtonsConfig: WizardModalFooterButton[] = [closeButton];
    if (invoiceEmail) {
      footerButtonsConfig.push({
        text: intl.$t({ id: "EXCLUDE" }),
        onClick: excludeInvoiceAttachment,
        type: "outlined",
        className: "mr-2 w-40 max-w-60",
      });
    }
    footerButtonsConfig.push({
      text:
        type === SplitType.Split
          ? intl.$t(
              { id: "IMPORT_COUNT" },
              {
                count: pageRanges
                  .filter((p) => !p.excluded)
                  .map((p) => ({
                    start: p.start,
                    end: p.end,
                    hideHeader: true,
                  })).length,
              },
            )
          : intl.$t({ id: "SAVE" }),
      onClick: async () => {
        if (type === SplitType.Restructure) {
          await handleUpdateInvoice();
          return;
        }
        if (urls.indexOf(currentUrl) !== urls.length - 1) {
          if (
            (await handleCreateInvoices()) &&
            urls.indexOf(currentUrl) < urls.length - 1
          ) {
            const newIndex = urls.indexOf(currentUrl) + 1;
            setCurrentUrl(urls[newIndex]);
            const ranges = await splitInvoiceDocument({
              assetUrl: urls[newIndex],
            });
            if (ranges) {
              setPageRanges(ranges);
            }
          }
        } else {
          moveToNextStep();
          if (await handleCreateInvoices()) {
            moveToNextStep();
            if (invoiceEmail) {
              refetchInvoiceEmail();
              refetchInvoiceStats();
            }
          } else {
            moveToPreviousStep();
          }
        }
      },
      type: "primary",
      disabled: pageRanges.filter((p) => !p.excluded).length === 0,
      className: "w-40 max-w-60",
    });

    p.push(
      ...[
        {
          pages: [
            {
              component: (
                <DocumentViewer page={selectedPage} url={currentUrl} />
              ),
              leftSidePanel: (
                <PageRanges url={currentUrl} page={selectedPage} type={type} />
              ),
              hideHeader: true,
              footerContent: {
                node: <SplittingInvoiceFooterActions />,
              },
              footerButtonsConfig,
              classNames: {
                modal: "overflow-hidden",
                header: "mt-0 pt-0",
                wrapper: "px-0 bg-gray-100 mb-24 pb-0",
                footer: "bg-white left-52 [&>div:first-child]:flex-[5]",
              },
            },
          ],
        },
        {
          title: null,
          pages: [
            {
              component: (
                <AnimatedLoading
                  loading={creating}
                  text={intl.$t({ id: "MATCHING_INVOICES" })}
                />
              ),
              onCloseClick: closeWizard,
            },
          ],
        },
        {
          title: null,
          pages: [
            {
              component: (
                <ImportSuccessful successful={selectedInvoices.length > 0} />
              ),
              hideHeader: true,
              onCloseClick: closeWizard,
            },
          ],
        },
      ],
    );

    return p;
  }, [
    intl,
    closeWizard,
    invoiceEmail,
    type,
    pageRanges,
    selectedPage,
    currentUrl,
    creating,
    selectedInvoices.length,
    excludeInvoiceAttachment,
    urls,
    handleUpdateInvoice,
    handleCreateInvoices,
    splitInvoiceDocument,
    moveToNextStep,
    refetchInvoiceEmail,
    refetchInvoiceStats,
    moveToPreviousStep,
  ]);

  return (
    <ProviderContext.Provider
      value={{
        wizardOpened,
        openWizard,
        closeWizard,
        pages,
        handleInspectAttachments: moveToNextStep,
        handleSaveWizardData: handleCreateInvoices,
        currentUrl,
        urls,
        selectedPage,
        setSelectedPage,
        pageRanges,
        selectedPages,
        setSelectedPages,
        setPageRanges,
        splitting: splitting || loading,
        type,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSplittingInvoicesWizard = () => useContext(ProviderContext);
