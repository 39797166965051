import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InvoiceSummaryFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";

export const useInvoiceWarnings = ({
  invoice,
}: {
  invoice: InvoiceSummaryFieldsFragment;
}) => {
  const { settings } = useOrgSettings();

  const isRequiredReceipt = useMemo(() => {
    return (
      settings?.integrations.sourceSystems.find((system) => system.connected)
        ?.postInventoryReceipts ||
      settings?.integrations.accounting.find((accounting) => accounting.enabled)
        ?.postInventoryReceipts
    );
  }, [settings?.integrations.accounting, settings?.integrations.sourceSystems]);

  const tooltipTranslationKey = useMemo(() => {
    if (
      invoice.link &&
      invoice.link.exportedAt === null &&
      invoice.link.failedAt === null
    ) {
      return "INVOICE_EXPORT_IN_PROGRESS";
    }
    if (invoice.link?.failedAt) {
      return "INVOICE_EXPORT_FAILED";
    }
    if (invoice.exportedAt || invoice.link?.exportedAt) {
      if (invoice.exportedBy || invoice.link?.createdBy) {
        return "INVOICE_EXPORTED_AT_BY";
      }
      return "INVOICE_EXPORTED_AT";
    }
    return "INVOICE_NOT_EXPORTED";
  }, [invoice.link, invoice.exportedAt, invoice.exportedBy]);

  return {
    isRequiredReceipt,
    tooltipTranslationKey,
  };
};
