import { If } from "@/common/components/if/If";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { useSourceSystemConfig } from "@/common/hooks/integrations/source-system-config/useSourceSystemConfig";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceExternalPOCostCodes } from "./hooks/useInvoiceExternalPOCostCodes";

const Container = tw.div`flex flex-col w-full my-4`;
const AggregatedItem = tw.div`grid grid-cols-[3fr_1fr_1fr] lg:grid-cols-[5fr_1fr_1fr] px-3 border border-gray-500 rounded-xl py-2 mt-2 justify-between border-dashed items-center`;
const Label = tw.div`text-2xs text-gray-500`;
const Item = tw.div`flex flex-col justify-start`;
const Title = tw.div`flex items-center gap-2 text-base font-medium`;

export const InvoiceExternalPOCostCodes = () => {
  const { connectedSourceSystem } = useOrgSettings();
  const { sourceSystemConfig } = useSourceSystemConfig(connectedSourceSystem);
  const { aggregatedItemsWithAdditionalChargesAndSalesTax, hidden } =
    useInvoiceExternalPOCostCodes();

  return (
    <If isTrue={!hidden}>
      <Container>
        <Title>
          <img src={sourceSystemConfig?.logo.small} />
          <FormattedMessage id="ASSIGN_COST_CODES_AND_CATEGORIZE" />
        </Title>
        {aggregatedItemsWithAdditionalChargesAndSalesTax?.map((item, key) => (
          <AggregatedItem key={key}>
            <Item>
              <FormattedMessage id="COST_CODE" tagName={Label} />
              {item.externalCostCode?.name}
            </Item>
            <Item>
              <FormattedMessage id="TOTAL" tagName={Label} />
              <ValueCurrency
                classNames={{ container: "flex-row" }}
                value={item.amount || "0"}
              />
            </Item>
            <Item>
              <FormattedMessage id="ORDER_ITEM_INVOICED" tagName={Label} />
              <ValueCurrency
                classNames={{ container: "flex-row" }}
                value={item.invoicedAmount || "0"}
              />
            </Item>
          </AggregatedItem>
        ))}
      </Container>
    </If>
  );
};
