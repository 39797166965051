import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { routes } from "@/config/routes";
import { BuyoutReleasesFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { generatePath } from "react-router";
import { ReleasesInfoBoxes } from "./ReleasesInfoBoxes";

type Props = {
  buyout: BuyoutReleasesFieldsFragment;
};

export const ReleasesHeader: FC<Props> = ({ buyout }) => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {intl.$t(
            { id: "BUYOUT_DELIVERY_HISTORY" },
            { clientIdentifier: buyout?.clientIdentifier },
          )}
        </title>
      </Helmet>
      <Breadcrumbs
        classes={{
          text: "flex items-center",
        }}
        items={[
          {
            text: intl.$t({ id: "BUYOUTS" }),
            link: routes.buyouts,
            id: "buyouts",
          },
          {
            id: "buyoutNo",
            link: generatePath(routes.buyout, { id: buyout?.id || "" }),
            text: intl.$t(
              { id: "BUYOUT" },
              { number: buyout?.clientIdentifier },
            ),
          },
          {
            id: "deliveryHistory",
            text: intl.$t({ id: "DELIVERY_HISTORY" }),
          },
        ]}
      />
      <ReleasesInfoBoxes buyout={buyout} />
    </>
  );
};
