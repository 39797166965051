import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { OrderApprovalsStepper } from "@/contractor/pages/home/release/components/order-approval/OrderApprovalsStepper";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { SubmitOrderUpdatesDialog } from "../../../order-from-quote/create-order-from-quote/components/SubmitOrderUpdatesDialog";
import { CreateOrderFromNoteFormValues } from "./CreateOrderFromNoteForm";
import { useCreateOrderFromNoteFooter } from "./useCreateOrderFromNoteFooter";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;

export const CreateOrderFromNoteFooter: FC = () => {
  const {
    handleSaveReleaseFromNote,
    isSaving,
    loading,
    onCancel,
    finalizeOrder,
    isSaveDialogVisible,
    setIsSaveDialogVisible,
  } = useCreateOrderFromNoteFooter();

  const { handleSubmit } = useFormContext<CreateOrderFromNoteFormValues>();

  return (
    <FloatingFooterStyled>
      <ButtonContainer>
        <OrderApprovalsStepper />
        <OutlinedButton className="py-0" onClick={onCancel}>
          <FormattedMessage id="CANCEL" />
        </OutlinedButton>
        <PrimaryButton
          className="py-0"
          onClick={handleSubmit(finalizeOrder)}
          loading={isSaving && loading}
          disabled={!isSaving && loading}
        >
          <FormattedMessage id="FINALIZE_ORDER" />
        </PrimaryButton>
      </ButtonContainer>
      <SubmitOrderUpdatesDialog
        onSubmit={async ({ skipVendorNotification }) => {
          return await handleSaveReleaseFromNote({
            skipNavigate: true,
            skipVendorNotification,
          });
        }}
        setVisible={setIsSaveDialogVisible}
        visible={isSaveDialogVisible}
        isSaving={isSaving}
      />
    </FloatingFooterStyled>
  );
};
