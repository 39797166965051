import { CalendarToday, CloseOutlined } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { IconButtonBorderless } from "../button/IconButton";
import useRangeLabelFormat from "../date-range-picker/useRangeLabelFormat";
import { If } from "../if/If";
import { FilterCountIndicator } from "../list-header-filter-button/FilterCountIndicator";

const Container = tw.div``;
const ButtonContent = tw.div<{
  $isDefault: boolean;
  $includeStyling?: boolean;
}>`flex gap-2 items-center relative
  ${({ $isDefault }) => ($isDefault ? "bg-white text-blue-500" : "bg-blue-500 font-normal text-white")}
  ${({ $includeStyling }) => $includeStyling && "px-2 py-1.5 rounded-xl border border-white hover:border-gray-400 "}
`;
const StyledCloseOutlined = tw(
  CloseOutlined,
)`bg-white text-gray-600 rounded-full text-xs cursor-pointer mr-auto ml-2`;
const Labels = tw.div``;
const Label = tw.div`flex gap-1 items-center w-full`;
const LabelValue = tw.div`flex-1 text-left font-medium`;
const Headline = tw.div``;

type Props = {
  children: (onClose: () => void) => React.ReactNode;
  dates: {
    startDate: number | null | undefined;
    endDate: number | null | undefined;
    label?: string;
    key?: string;
  }[];
  clearValues: (key: string | undefined) => void;
  isDefault?: boolean;
  appliedFilters?: number;
  hideDates?: boolean;
  includeIconLabel?: boolean;
  classes?: {
    container?: string;
    buttonContent?: string;
    icon?: string;
  };
};

export const ListDatesButton = ({
  children,
  dates,
  clearValues,
  isDefault = true,
  appliedFilters,
  hideDates = false,
  includeIconLabel = true,
  classes,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const intl = useIntl();
  const { generateLabel } = useRangeLabelFormat();

  const configuration = useMemo(() => {
    if (dates.every((date) => !date.startDate && !date.endDate) || hideDates) {
      return [
        {
          label: includeIconLabel ? intl.$t({ id: "DATES" }) : "",
          value: null,
          key: "",
        },
      ];
    }
    return dates
      .map((date) => {
        if (date.startDate || date.endDate) {
          return {
            label: date.label ? `${date.label}:` : null,
            value: generateLabel({
              startDate: date.startDate ? new Date(date.startDate) : undefined,
              endDate: date.endDate ? new Date(date.endDate) : undefined,
            }),
            key: date.key,
          };
        }
        return null;
      })
      .filter((val) => !!val);
  }, [dates, hideDates, includeIconLabel, intl, generateLabel]);

  return (
    <Container className={classes?.container}>
      <IconButtonBorderless
        autoScale
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ButtonContent
          $isDefault={isDefault}
          $includeStyling={includeIconLabel}
          className={classes?.buttonContent}
        >
          <CalendarToday className={classes?.icon} />
          <If isTrue={includeIconLabel}>
            <Labels>
              {configuration.map((conf) => (
                <Label key={conf?.key}>
                  <If isTrue={conf?.label}>
                    <Headline>{conf?.label}</Headline>
                  </If>
                  <If isTrue={conf?.value}>
                    <LabelValue>{conf?.value}</LabelValue>
                    <StyledCloseOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        clearValues(conf?.key);
                      }}
                    />
                  </If>
                </Label>
              ))}
            </Labels>
          </If>
          <If isTrue={!isDefault}>
            <FilterCountIndicator>
              <If isTrue={appliedFilters}>{appliedFilters}</If>
            </FilterCountIndicator>
          </If>
        </ButtonContent>
      </IconButtonBorderless>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {children(handleClose)}
      </Popover>
    </Container>
  );
};
