import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { TextfieldWithActions } from "@/common/components/textfield-with-actions/TextfieldWithActions";
import { InfoIconContainer } from "@/contractor/pages/home/projects/components/project-form-with-stepper/ProjectFormWithStepper.styles";
import { Buyout } from "@/generated/graphql";
import { FormControl } from "@mui/material";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-col`;
const Content = tw.div`flex items-center mt-2 mb-4`;
const TextfieldWithActionsStyled = tw(TextfieldWithActions)`mr-3 w-96`;

type Props = {
  buyout: Pick<Buyout, "id" | "version" | "description"> | undefined | null;
  saveDescription?: (value: string) => void;
  readonly?: boolean;
};

export const BuyoutDescription: FC<Props> = ({
  buyout,
  saveDescription,
  readonly,
}: Props) => {
  const intl = useIntl();

  const labelOptional = useMemo(() => {
    return `${intl.$t({ id: "BUYOUT_DESCRIPTION" })} ${intl.$t({
      id: "OPTIONAL_WITH_BRACKETS",
    })}`;
  }, [intl]);

  const tooltipLabel = useMemo(() => {
    return intl.$t({
      id: "BUYOUT_DESCRIPTION_TOOLTIP",
    });
  }, [intl]);

  if (!buyout) {
    return null;
  }

  if (readonly) {
    return (
      <NotNullableRenderer value={buyout?.description}>
        <Container>
          <Content>{buyout?.description}</Content>
        </Container>
      </NotNullableRenderer>
    );
  }

  return (
    <Container>
      <Content>
        <FormControl>
          <TextfieldWithActionsStyled
            label={labelOptional}
            defaultValue={buyout?.description || ""}
            onChange={(e) =>
              readonly ? undefined : saveDescription?.(e.target.value)
            }
          />
        </FormControl>
        <InfoIconContainer>
          <InfoTooltip message={tooltipLabel} id="description-info" />
        </InfoIconContainer>
      </Content>
    </Container>
  );
};
