import { FC } from "react";
import tw from "tailwind-styled-components";
import { HostedIntegrationInvoiceInformation } from "../hosted-integration-information/HostedIntegrationInvoiceInformation";
import { useInvoiceAgaveConnections } from "./hooks/useInvoiceAgaveConnections";
import { InvoiceAgaveConnectionsProps } from "./types/InvoiceAgaveConnectionsProps";

const Container = tw.div`flex flex-col gap-3`;

export const InvoiceAgaveConnections: FC<InvoiceAgaveConnectionsProps> = ({
  invoices,
  linking,
}) => {
  const { filteredReleases } = useInvoiceAgaveConnections({
    invoices,
    linking,
  });

  return (
    <Container>
      {filteredReleases.map((invoice, key) => {
        return (
          <HostedIntegrationInvoiceInformation
            invoice={invoice}
            key={key}
            linking={linking.linking && !linking.completed.includes(invoice.id)}
            error={linking.errors.find((e) => e.id === invoice.id)?.message}
          />
        );
      })}
    </Container>
  );
};
