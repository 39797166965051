import {
  AuthorizationStatus,
  OrgLocationPermissions,
  OrgRole,
} from "@/generated/graphql";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useUser } from "../providers/UserProvider";
import { useStartupDataStore } from "../stores/useStartupDataStore";

const isPermissionAuthorized = (permission?: AuthorizationStatus) =>
  permission === AuthorizationStatus.Authorized ||
  permission === AuthorizationStatus.SelfAssignable;

type Props = {
  permission?: keyof OrgLocationPermissions;
};

export const useUserLocations = (props?: Props) => {
  const { locations, loading } = useStartupDataStore(
    useShallow((state) => ({
      locations: state.locations,
      loading: state.loading,
    })),
  );
  const { viewer } = useUser();

  const filteredLocations = useMemo(
    () =>
      locations.filter((loc) => {
        const hasRole =
          viewer?.locationRoles.some((role) => role.orgLocID === loc.id) ||
          viewer?.orgRoles.some((role) => role === OrgRole.OrgAdmin);

        const hasPermission = props?.permission
          ? isPermissionAuthorized(
              props.permission
                ? (loc.permissions[
                    props.permission as keyof OrgLocationPermissions
                  ] as AuthorizationStatus)
                : undefined,
            )
          : true;

        return hasRole && hasPermission;
      }),
    [locations, props?.permission, viewer?.locationRoles, viewer?.orgRoles],
  );

  return useMemo(
    () => ({
      locations: filteredLocations || [],
      loading,
      isPermissionAuthorized,
    }),
    [loading, filteredLocations],
  );
};
