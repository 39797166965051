import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { MatchedOrderViewState } from "../../../../../../../enums/MatchedOrderViewState";
import { useInvoiceImportExternalPO } from "../../../../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceMatchedOrder } from "../../../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../../../../providers/InvoiceVerificationProvider";

export const useInvoiceExternalPOCostCodes = () => {
  const { isNonItemizedPO, importedPoItemized, aggregatedItems, externalPo } =
    useInvoiceImportExternalPO();
  const { invoice } = useInvoiceVerification();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();

  const intl = useIntl();
  const aggregatedItemsWithAdditionalChargesAndSalesTax = useMemo(() => {
    return [
      ...(aggregatedItems || []),
      {
        externalCostCode: {
          name: intl.$t({ id: "ADDITIONAL_CHARGES" }),
        },
        amount: externalPo?.itemGroups.additionalCharges.reduce(
          (acc, item) => new DecimalSafe(acc).add(item.amount).toNumber(),
          0,
        ),
        invoicedAmount: externalPo?.itemGroups.additionalCharges.reduce(
          (acc, item) =>
            new DecimalSafe(acc).add(item.invoicedAmount || "0").toNumber(),
          0,
        ),
      },
      {
        externalCostCode: {
          name: intl.$t({ id: "SALES_TAX" }),
        },
        amount: externalPo?.itemGroups.salesTax.reduce(
          (acc, item) => new DecimalSafe(acc).add(item.amount).toNumber(),
          0,
        ),
        invoicedAmount: externalPo?.itemGroups.salesTax.reduce(
          (acc, item) =>
            new DecimalSafe(acc).add(item.invoicedAmount || "0").toNumber(),
          0,
        ),
      },
    ];
  }, [
    aggregatedItems,
    externalPo?.itemGroups.additionalCharges,
    externalPo?.itemGroups.salesTax,
    intl,
  ]);

  const hidden = useMemo(
    () =>
      !isNonItemizedPO ||
      matchedOrderViewState !== MatchedOrderViewState.IMPORT_ORDER ||
      !invoice?.poExists ||
      !importedPoItemized,
    [
      isNonItemizedPO,
      matchedOrderViewState,
      invoice?.poExists,
      importedPoItemized,
    ],
  );

  return {
    aggregatedItemsWithAdditionalChargesAndSalesTax,
    hidden,
  };
};
