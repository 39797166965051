import { useTabActive } from "@/common/hooks/useTabActive";
import { useEffect } from "react";
import { useInvoiceValidation } from "../../../../providers/InvoiceValidationProvider";

export const useHostedIntegrationInvoiceInformation = () => {
  const { refetchInvoiceValidation, loading } = useInvoiceValidation();
  const { isTabActive } = useTabActive();

  useEffect(() => {
    if (isTabActive && !loading) {
      refetchInvoiceValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabActive]);
};
