import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  WizardModalMode,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { useUpdateOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useUpdateOrgSettings";
import {
  FoundationTaxAuthorityInput,
  IntegrationType,
  UpdateAccountingSettingsInput,
} from "@/generated/graphql";
import { NoFunction, NoFunctionPromise } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useOnPremiseFoundationPages } from "./onpremise-providers-pages/useOnPremiseFoundationPages";
import { useOnPremiseSage300Pages } from "./onpremise-providers-pages/useOnPremiseSage300Pages";

export type AccountingOnPremiseProviderContextType = {
  pages: WizardModalPage[];
  wizardOpened: boolean;
  wizardMode: WizardModalMode;
  openWizard: (args: {
    mode?: WizardModalMode;
    initialPage?: number;
    integrationType: IntegrationType;
  }) => void;
  closeWizard: () => void;
  handleSaveWizardData: (
    data: Omit<UpdateAccountingSettingsInput, "integration">,
  ) => Promise<void>;

  postInventoryReceipts: boolean;
  setPostInventoryReceipts: (value: boolean) => void;
  inventoryReceiptLedgerAccount: string | null;
  setInventoryReceiptLedgerAccount: (value: string | null) => void;

  // step 2
  setGLAccountNumber: (value: string | null) => void;
  glAccountNumber: string | null;
  materialCostType: string | null;
  setMaterialCostType: (value: string | null) => void;

  taxAuthorities: FoundationTaxAuthorityInput[];
  setTaxAuthorities: (value: FoundationTaxAuthorityInput[]) => void;
};

const ProviderContext = createContext<AccountingOnPremiseProviderContextType>({
  pages: [],
  wizardOpened: false,
  wizardMode: WizardModalMode.MULTIPLE_PAGES,
  openWizard: NoFunction,
  closeWizard: NoFunction,
  handleSaveWizardData: NoFunctionPromise,

  postInventoryReceipts: false,
  setPostInventoryReceipts: NoFunction,
  inventoryReceiptLedgerAccount: null,
  setInventoryReceiptLedgerAccount: NoFunction,

  setGLAccountNumber: NoFunction,
  glAccountNumber: null,
  materialCostType: null,
  setMaterialCostType: NoFunction,

  taxAuthorities: [],
  setTaxAuthorities: NoFunction,
});

export const FoundationOnPremiseWizardProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { connectedAccountingSystem, orgId } = useOrgSettingsExtended();
  const { updateOrgSettings } = useUpdateOrgSettings();
  const { setStep, setNestedStep } = useNestedStepper();

  const [wizardOpened, setWizardOpened] = useState(false);
  const [wizardMode, setWizardMode] = useState(WizardModalMode.MULTIPLE_PAGES);
  const [integrationType, setIntegrationType] = useState<IntegrationType>(
    IntegrationType.Foundation,
  );

  const [postInventoryReceipts, setPostInventoryReceipts] = useState(
    connectedAccountingSystem?.postInventoryReceipts || false,
  );
  const [inventoryReceiptLedgerAccount, setInventoryReceiptLedgerAccount] =
    useState(connectedAccountingSystem?.inventoryReceiptLedgerAccount || null);

  const [glAccountNumber, setGLAccountNumber] = useState<string | null>(
    connectedAccountingSystem?.toJobLedgerAccount || "",
  );
  const [materialCostType, setMaterialCostType] = useState<string | null>(
    connectedAccountingSystem?.materialCostType || "",
  );

  const [taxAuthorities, setTaxAuthorities] = useState<
    FoundationTaxAuthorityInput[]
  >(
    connectedAccountingSystem?.taxAuthorities.map((t) => ({
      code: t.code,
      rate: new DecimalSafe(t.rate).mul(100).toString(),
    })) || [{ code: "", rate: "" }],
  );

  useEffect(() => {
    if (connectedAccountingSystem) {
      setGLAccountNumber(connectedAccountingSystem?.toJobLedgerAccount || "");
      setTaxAuthorities(
        connectedAccountingSystem?.taxAuthorities.length
          ? connectedAccountingSystem?.taxAuthorities.map((t) => ({
              code: t.code,
              rate: new DecimalSafe(t.rate).mul(100).toString(),
            }))
          : [{ code: "", rate: "" }],
      );
      setMaterialCostType(connectedAccountingSystem?.materialCostType || "");
      setPostInventoryReceipts(
        connectedAccountingSystem?.postInventoryReceipts || false,
      );
      setInventoryReceiptLedgerAccount(
        connectedAccountingSystem?.inventoryReceiptLedgerAccount ?? null,
      );
    }
  }, [connectedAccountingSystem]);

  const handleSaveWizardData = useCallback(
    async (data: Omit<UpdateAccountingSettingsInput, "integration">) => {
      await updateOrgSettings({
        id: orgId,
        integrations: {
          accounting: { ...data, integration: integrationType },
        },
      });
    },
    [integrationType, orgId, updateOrgSettings],
  );

  const openWizard = useCallback(
    ({
      mode,
      initialPage,
      integrationType,
    }: {
      mode?: WizardModalMode;
      integrationType: IntegrationType;
      initialPage?: number;
    }) => {
      setWizardMode(mode ?? WizardModalMode.MULTIPLE_PAGES);
      setStep(initialPage ?? 0);
      setWizardOpened(true);
      setIntegrationType(integrationType);
    },
    [setStep],
  );

  const resetWizard = useCallback(() => {
    setStep(0);
    setNestedStep(0);
  }, [setStep, setNestedStep]);

  const closeWizard = useCallback(() => {
    setWizardOpened(false);
    resetWizard();
  }, [resetWizard]);

  const foundationPages = useOnPremiseFoundationPages({
    closeWizard,
    handleSaveWizardData,
    postInventoryReceipts,
    glAccountNumber,
    taxAuthorities,
    inventoryReceiptLedgerAccount,
  });

  const sage300Pages = useOnPremiseSage300Pages({
    closeWizard,
    handleSaveWizardData,
    taxAuthorities,
    materialCostType,
  });

  const pages: WizardModalPage[] = useMemo(() => {
    switch (integrationType) {
      case IntegrationType.Foundation:
        return foundationPages;
      case IntegrationType.Sage300:
        return sage300Pages;
      default:
        return [];
    }
  }, [foundationPages, integrationType, sage300Pages]);

  return (
    <ProviderContext.Provider
      value={{
        wizardOpened,
        wizardMode,
        openWizard,
        closeWizard,
        pages,
        handleSaveWizardData,

        postInventoryReceipts,
        setPostInventoryReceipts,
        inventoryReceiptLedgerAccount,
        setInventoryReceiptLedgerAccount,

        setGLAccountNumber,
        glAccountNumber,
        materialCostType,
        setMaterialCostType,

        taxAuthorities,
        setTaxAuthorities,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useAccountingOnPremiseWizard = () => useContext(ProviderContext);
