import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { sumTableUnitPrices } from "@/common/components/spreadsheet-table/utils/sumTableUnitPrices";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { MatchedOrderViewState } from "../../../../../../../enums/MatchedOrderViewState";
import { useInvoiceImportExternalPO } from "../../../../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceCreateRelease } from "../../../../../../../providers/InvoiceCreateReleaseProvider";
import { useInvoiceMatchedOrder } from "../../../../../../../providers/InvoiceMatchedOrderProvider";
import { InvoiceCreateReleaseFormValues } from "../../../../InvoiceVerificationForm";

export const useInvoiceCreateReleaseItemizedView = () => {
  const {
    importedItems,
    importedPoItemized,
    isNonItemizedPO,
    importedTaxCodeId,
  } = useInvoiceImportExternalPO();
  const { setValue, watch, getValues } =
    useFormContext<InvoiceCreateReleaseFormValues>();
  const { invoiceItems } = useInvoiceCreateRelease();
  const { calcTableTotal } = useTableHelpers();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();

  const netAmount = watch("netAmount");

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = importedPoItemized
        ? calcTableTotal(data)
        : sumTableUnitPrices(data);
      if (newTotal !== netAmount) {
        setValue("netAmount", newTotal);
      }
      const taxableNetAmount = `${calcTableTotal(data, { taxable: true })}`;
      if (taxableNetAmount !== getValues("taxableNetAmount")) {
        setValue("taxableNetAmount", taxableNetAmount);
      }
    },
    [importedPoItemized, calcTableTotal, netAmount, getValues, setValue],
  );

  useEffect(() => {
    if (importedTaxCodeId) {
      setValue("taxCodeId", importedTaxCodeId);
    }
  }, [importedTaxCodeId, setValue]);

  const items = useMemo(() => {
    if (matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER) {
      if (importedPoItemized && isNonItemizedPO) {
        return invoiceItems;
      }
      return importedItems || [];
    }
    return importedItems ?? invoiceItems;
  }, [
    importedItems,
    invoiceItems,
    importedPoItemized,
    matchedOrderViewState,
    isNonItemizedPO,
  ]);

  return {
    items,
    updateSubtotal,
  };
};
