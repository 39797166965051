import { DecimalSafe } from "@/common/utils/decimalSafe";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { CategoryLike } from "../../../../../common/hooks/useToggleCategory";
import { extPriceCalculation } from "../../release/hooks/usePriceCalculation";

export const getEstimatedItem = ({
  item,
  index,
  sumIfMultiple = true,
}: {
  item: ProjectItemFieldsFragment;
  index?: number;
  sumIfMultiple?: boolean;
  category?: CategoryLike;
  groupedByZones?: boolean;
}) => {
  if (!item) {
    return null;
  }
  const { estimatedItems } = item;
  if (!isNaN(Number(index))) {
    return estimatedItems[index || 0];
  }
  if (estimatedItems.length === 1) {
    return estimatedItems[0];
  }
  if (estimatedItems.length > 1 && sumIfMultiple) {
    const sum = estimatedItems.reduce(sumQuantities, 0);
    const tagsMap = new Map();
    const itemsWithPrice = estimatedItems.filter(
      (item) => Number(item.unitPrice ?? 0) > 0,
    );
    const sumQuantityForItemWithPrice = itemsWithPrice.reduce(sumQuantities, 0);
    const averageUnitPrice = itemsWithPrice
      .reduce(
        (acc: Decimal, item: EstimatedItemFieldsFragment) =>
          acc.add(
            extPriceCalculation(item.quantityDecimal, item.unitPrice ?? 0),
          ),
        new DecimalSafe(0),
      )
      .div(sumQuantityForItemWithPrice);
    estimatedItems
      .map((e) => e.tags)
      .flat()
      .filter((t) => t)
      .forEach((t) => {
        tagsMap.set(t.id, t);
      });
    return {
      ...estimatedItems[0],
      quantityDecimal: sum.toString(),
      unitPrice: averageUnitPrice.toString(),
      tags: Array.from(tagsMap.values()),
    };
  }
};

const sumQuantities = (acc: number, item: EstimatedItemFieldsFragment) => {
  const unitPrice = new DecimalSafe(item.quantityDecimal || "0");
  return Number(unitPrice.add(new DecimalSafe(acc)));
};
