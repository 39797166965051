import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useSequence } from "@/common/hooks/useSequence";
import { routes } from "@/config/routes";
import { InvoiceEmailFieldsFragment } from "@/generated/graphql";
import { useInvoiceEmailsSequenceStore } from "./useInvoiceEmailsSequenceStore";

export type StoredInvoiceEmail = Pick<
  InvoiceEmailFieldsFragment,
  "id" | "archivedAt"
>;

export const useInvoiceEmailsSequence = () => {
  return useSequence<StoredInvoiceEmail>({
    listPath: routes.emailInvoices,
    localStorageConfig: LOCAL_STORAGE_KEYS.INVOICE_EMAILS_SEQUENCE,
    useStore: useInvoiceEmailsSequenceStore,
  });
};
