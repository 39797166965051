import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";

import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import Handsontable from "handsontable";
import { textRenderer } from "handsontable/renderers";
import { ColumnSettings } from "handsontable/settings";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useTableHelpers } from "../hooks/useTableHelpers";
import { getMaterialNameParts } from "../utils/getMaterialNameParts";
import { getPhysicalColumnIndex } from "../utils/getPhysicalColumnIndex";
import { useRenderHelpers } from "./useRenderHelpers";

type MaterialRenderer = (
  instance: Handsontable,
  td: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: string,
  cellProperties: Handsontable.CellProperties,
) => void;

export const useMaterialRenderer = () => {
  const intl = useIntl();
  const { hasPhaseCodes } = useOrgSettings();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { isBuyoutItem } = useTableHelpers();
  const { applyTooltip, checkReadOnly, applyClasses, addIconWithTooltip } =
    useRenderHelpers();
  const { getMetadata } = useColumnMapper();

  const renderer: MaterialRenderer = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      textRenderer(instance, td, row, col, prop, value, cellProperties);

      if (!value) {
        return;
      }
      applyClasses(td, "htMiddle relative pr-3");
      checkReadOnly(td, row, instance, cellProperties);

      const rows = instance?.getData();
      const columns = instance?.getSettings().columns;
      const autocompleteOptions: string[] = cellProperties.source as string[];
      if (!rows || !columns) {
        return;
      }

      const additionData: string[] = [];
      (columns as ColumnSettings[]).forEach((c, index) => {
        if (
          c.additional === (columns as ColumnSettings[])[col].columnType &&
          rows[row][index]
        ) {
          additionData.push(`${c.header} = ${rows[row][index]}`);
        }
      });

      if (additionData.length) {
        td.innerText = td.innerText.replace(/,.*(=.*)/, "");
        const container = document.createElement("span");
        container.className = "text-green-600 font-light";
        container.innerText = `, ${additionData.join(", ")}`;
        td.appendChild(container);
      }

      const { namePart, partNumber } = getMaterialNameParts(value);
      if (partNumber) {
        td.innerText = namePart;
        const partText = document.createElement("span");
        partText.className = "text-gray-600 font-light text-xs select-none";
        partText.innerText = `〈${partNumber}〉`;
        td.appendChild(partText);

        applyTooltip(partText, intl.$t({ id: "CUSTOM_PART_NUMBER_NOTICE" }));
      }

      const isLumpSumItem = isLumpSumUomText(
        rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
      );

      if (
        value &&
        autocompleteOptions?.length &&
        !autocompleteOptions.includes(value.toString()) &&
        !isLumpSumItem
      ) {
        td.className = "htMiddle text-blue-800 bg-blue-100";
        const newSup = document.createElement("sup");
        newSup.className = "text-blue-500 font-semibold text-[8px] ml-1";
        newSup.innerText = intl.$t({ id: "NEW" });
        td.appendChild(newSup);
      }

      const buyoutItem = cellProperties.extraOptions
        ? isBuyoutItem(
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Vendor)],
            rows[row][
              getPhysicalColumnIndex(instance, COLUMN_TYPE.Manufacturer)
            ],
            rows[row][
              getPhysicalColumnIndex(instance, COLUMN_TYPE.PrefilledPrice)
            ] ||
              rows[row][
                getPhysicalColumnIndex(instance, COLUMN_TYPE.UnitPrice)
              ],
            hasPhaseCodes
              ? rows[row][
                  getPhysicalColumnIndex(instance, COLUMN_TYPE.PhaseCode)
                ]
              : rows[row][
                  getPhysicalColumnIndex(instance, COLUMN_TYPE.CostCode)
                ],
          )
        : null;

      if (buyoutItem) {
        const buyoutText = document.createElement("span");
        buyoutText.className = "text-gray-500";
        buyoutText.innerText = ` ⟮${hasPhaseCodes ? buyoutItem.tags[0]?.name : buyoutItem.costCode?.description || unassignedCostCode.description}, ${buyoutItem.quantityDecimal} ${buyoutItem.projectItem.estimateUom.pluralDescription}⟯`;
        const bubble = document.createElement("span");
        bubble.className = "absolute top-1/4 right-1 text-green-800";
        bubble.innerText = "●";
        applyTooltip(bubble, intl.$t({ id: "BUYOUT_ITEM_NOTICE" }));
        td.appendChild(bubble);
        td.appendChild(buyoutText);
      }

      const meta = getMetadata(row);

      if (meta?.warningIconTooltipText) {
        addIconWithTooltip({
          element: td,
          tooltipText: meta?.warningIconTooltipText.toString(),
          iconClasses: "text-red-500 hover:text-red-300",
        });
      }
    },
    [
      applyClasses,
      applyTooltip,
      checkReadOnly,
      intl,
      addIconWithTooltip,
      isBuyoutItem,
      unassignedCostCode.description,
      getMetadata,
      hasPhaseCodes,
    ],
  );

  return renderer;
};
