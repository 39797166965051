import { AssetItem } from "@/common/components/asset-item/AssetItem";
import { DeleteButton } from "@/common/components/button/DeleteButton";
import { EditButton } from "@/common/components/button/EditButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { InvoiceKickbackCommentFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { KickbackCommentForm } from "./KickbackCommentForm";
import { useKickbackComment } from "./useKickbackComment";

export interface KickbackCommentProps {
  kickbackId: string;
  item?: InvoiceKickbackCommentFieldsFragment;
  onCancelAdding?: () => void;
  readonly?: boolean;
  setEditedCommentId?: (commentId: string | undefined) => void;
}

const Container = tw.div`mb-2`;
const Headline = tw.div`text-gray-600 flex gap-2 items-center italic text-sm`;
const HeadlineData = tw.span`text-xs`;
const HeadlineLine = tw.span`flex-1 border-t border-dashed border-gray-500`;
const Assets = tw.div`grid col-span-full grid-flow-col justify-items-start gap-2 mb-2 justify-start`;
const CommentDataContainer = tw.div`flex flex-col gap-2`;
const CommentBlock = tw.div`flex gap-4 items-start`;
const CommentText = tw.span`flex-1 mt-2 text-xs`;
const CommentActions = tw.div`flex items-center`;
const DialogBlock = tw.div`flex flex-col items-center gap-2`;
const DialogText = tw.span`italic`;

export const KickbackComment = (props: KickbackCommentProps) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { kickbackId, item, onCancelAdding, readonly } = props;
  const {
    isCommentCreator,
    handleCancelEdit,
    handleEditClick,
    deleteInvoiceKickbackComment,
    edit,
  } = useKickbackComment(props);

  const handleDeleteClick = useCallback(() => {
    if (!item) {
      return;
    }
    openDialog({
      includeWarningIcon: true,
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      title: intl.$t({ id: "DELETE_COMMENT" }),
      text: (
        <DialogBlock>
          <DateView
            date={item.createdAt}
            options={SHORT_DATE_OPTION}
            hideTimeZone
            includeTime
          />
          <DialogText>{`"${item.text}"`}</DialogText>
        </DialogBlock>
      ),
      maxWidth: "xl",
      closeOnConfirm: true,
      confirmButtonText: intl.$t({ id: "DELETE" }),
      handleConfirm: async () => {
        await deleteInvoiceKickbackComment(item.id);
      },
    });
  }, [deleteInvoiceKickbackComment, intl, item, openDialog]);

  return (
    <Container>
      <Headline>
        <If isTrue={!!item}>
          <HeadlineData>
            {intl.$t(
              { id: "COMMENT_BY" },
              { userName: getUserName(item?.createdBy) },
            )}
          </HeadlineData>
        </If>
        <HeadlineLine />
        <If isTrue={!!item}>
          <HeadlineData>
            <DateView
              date={item?.createdAt}
              options={SHORT_DATE_OPTION}
              hideTimeZone
              includeTime
            />
          </HeadlineData>
        </If>
      </Headline>
      {edit && !readonly ? (
        <KickbackCommentForm
          item={item}
          onCancelAdding={item ? handleCancelEdit : onCancelAdding}
          kickbackId={kickbackId}
        />
      ) : (
        <CommentDataContainer>
          <CommentBlock>
            <CommentText>{item?.text}</CommentText>
            <If isTrue={!readonly && isCommentCreator}>
              <CommentActions>
                <EditButton onClick={handleEditClick} className="h-5" />
                <DeleteButton className="h-5" onClick={handleDeleteClick} />
              </CommentActions>
            </If>
          </CommentBlock>
          <If isTrue={item?.assets?.length}>
            <Assets>
              {item?.assets?.map((asset, index) => (
                <AssetItem key={index} asset={asset} index={index} />
              ))}
            </Assets>
          </If>
        </CommentDataContainer>
      )}
    </Container>
  );
};
