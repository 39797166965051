import { sumAuxiliaryQuoteFullPrices } from "@/common/components/price/utils/sumAuxiliaryQuoteFullPrices";
import { sumQuoteGroupFullPrices } from "@/common/components/price/utils/sumQuoteGroupFullPrices";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import {
  DistributorQuoteCommonFieldsFragment,
  DistributorQuoteFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";

export const useTotalPrices = (
  quote:
    | (DistributorQuoteFieldsFragment & DistributorQuoteCommonFieldsFragment)
    | null
    | undefined,
) => {
  const totalPrice = useMemo(
    () =>
      Number(
        new DecimalSafe(
          quote?.itemGroups.reduce(sumQuoteGroupFullPrices, 0) || 0,
        )
          .add(
            quote?.auxiliaryItems.reduce(sumAuxiliaryQuoteFullPrices, 0) || 0,
          )
          .add(
            quote?.additionalCharges.reduce((acc, item) => {
              const amount = new DecimalSafe(item.amount || 0);
              return Number(amount.add(new DecimalSafe(acc)));
            }, 0) || 0,
          ),
      ),
    [quote],
  );
  const discountPrice = useMemo(() => {
    if (!totalPrice) {
      return 0;
    }
    if (quote?.discount?.amount) {
      return Number(quote.discount.amount);
    }
    if (quote?.discount?.percentage) {
      return Number(
        new DecimalSafe(totalPrice).mul(
          new DecimalSafe(quote.discount.percentage ?? "0").dividedBy(100),
        ),
      );
    }

    return 0;
  }, [totalPrice, quote?.discount]);
  const totalPriceWithDiscount = useMemo(() => {
    if (discountPrice) {
      return Number(
        new DecimalSafe(totalPrice).sub(new DecimalSafe(discountPrice)),
      );
    } else {
      return totalPrice;
    }
  }, [discountPrice, totalPrice]);

  return {
    totalPrice,
    discountPrice,
    totalPriceWithDiscount,
  };
};
