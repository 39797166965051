import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  BatchType,
  ExternalBatchFieldsFragment,
  SourceSystem,
  useExternalBatchQuery,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { format } from "date-fns";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { BATCH_MONTH_DATE_FORMAT } from "../const";

type ProviderContextType = {
  newBatch: boolean;
  setNewBatch: (value: boolean) => void;
  setBatchNumber: (value: string) => void;
  batchNumber?: string;
  batchDate: Date;
  setBatchDate: (value: Date) => void;
  externalBatch?: ExternalBatchFieldsFragment | null;
  loadingExternalBatch?: boolean;
  postAutomatically: boolean;
  setPostAutomatically: (value: boolean) => void;
};

type Props = {
  children: React.ReactNode;
  type: BatchType;
};

const ProviderContext = createContext<ProviderContextType>({
  newBatch: false,
  setNewBatch: NoFunction,
  setBatchNumber: NoFunction,
  batchNumber: "",
  batchDate: new Date(),
  setBatchDate: NoFunction,
  externalBatch: undefined,
  loadingExternalBatch: false,
  postAutomatically: false,
  setPostAutomatically: NoFunction,
});

export const ExportBatchProvider: FC<Props> = ({ children, type }) => {
  const { connectedSourceSystemSettings } = useOrgSettings();

  const [newBatch, setNewBatch] = useState(true);
  const [postAutomatically, setPostAutomatically] = useState(
    type === BatchType.Invoice
      ? !!connectedSourceSystemSettings?.autoPostInvoices
      : !!connectedSourceSystemSettings?.autoPostPOs,
  );
  const [batchDate, setBatchDate] = useState<Date>(new Date());
  const [batchNumber, setBatchNumber] = useState<string>("");

  useEffect(() => {
    if (type === BatchType.Invoice) {
      setPostAutomatically(!!connectedSourceSystemSettings?.autoPostInvoices);
    }
    if (type === BatchType.PurchaseOrder) {
      setPostAutomatically(!!connectedSourceSystemSettings?.autoPostPOs);
    }
  }, [connectedSourceSystemSettings, type]);

  const { data, loading: loadingExternalBatch } = useExternalBatchQuery({
    variables: {
      input: {
        sourceSystem:
          connectedSourceSystemSettings?.system || SourceSystem.Vista,
        month: format(batchDate, BATCH_MONTH_DATE_FORMAT),
        number: batchNumber,
      },
    },
    skip:
      newBatch ||
      !batchDate ||
      !batchNumber ||
      (!!connectedSourceSystemSettings?.autoPostPOs &&
        type === BatchType.PurchaseOrder),
  });

  return (
    <ProviderContext.Provider
      value={{
        newBatch,
        setNewBatch,
        setBatchNumber,
        batchNumber,
        batchDate,
        setBatchDate,
        externalBatch:
          data?.externalBatch?.type === type ? data.externalBatch : undefined,
        loadingExternalBatch,
        postAutomatically,
        setPostAutomatically,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExportBatch = (): ProviderContextType =>
  useContext(ProviderContext);
