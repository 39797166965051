import { MessageOrgType } from "@/common/components/messages/Messages";
import { MessagesPanel } from "@/common/components/messages/components/MessagesPanel";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { MESSAGES_SEGMENT } from "@/config/routes";
import {
  DistributorInvoiceFieldsFragment,
  DistributorReleaseFieldsFragment,
  MessageContextKind,
  ReleaseFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import tw from "tailwind-styled-components";

type Props = {
  release:
    | ReleaseFieldsFragment
    | DistributorReleaseFieldsFragment
    | DistributorInvoiceFieldsFragment["release"];
  text: React.ReactNode;
  org?: MessageOrgType;
};

const Container = tw.div`flex items-center`;

export const VendorReleaseMessagesButton: FC<Props> = ({
  text,
  release,
  org,
}: Props) => {
  const location = useLocation();
  const defaultOpened = useMemo(
    () => location.pathname.includes(MESSAGES_SEGMENT),
    [location.pathname],
  );
  if (!release) {
    return null;
  }

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <Container onClick={() => togglePanel(true)}>{text}</Container>
      )}
      content={(togglePanel) => (
        <MessagesPanel
          title="MESSAGE_PANEL_TITLE_CONTRACTOR"
          id={release.id}
          kind={MessageContextKind.Release}
          context={{
            release,
            buyout: (release as ReleaseFieldsFragment)?.buyout,
          }}
          org={org}
          messageTabName={<FormattedMessage id="MESSAGE_CONTRACTOR" />}
          noMessage="RELEASE_NO_MESSAGES"
          onClose={() => togglePanel(false)}
        />
      )}
      defaultOpened={defaultOpened}
    />
  );
};
