import { COLUMN_TYPE } from "../enums/columnType";
import { getCellValue } from "./getCellValue";

export const rowIsChecked = (
  row: Record<string, string>,
  columnType: COLUMN_TYPE,
) => {
  const value = getCellValue(row, columnType);
  return value === "true" || value === "1";
};
