import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { ProjectProjectsFieldsFragment } from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { useProjectCheckbox } from "./useProjectCheckbox";

const Container = tw.div`
   flex
`;

export type ProjectCheckboxProps = {
  project: ProjectProjectsFieldsFragment;
};

export const ProjectCheckbox = ({ project }: ProjectCheckboxProps) => {
  const { sequenceIds, handleChange } = useProjectCheckbox({ project });

  return (
    <Container>
      <SelectionCheckbox
        itemId={project.id}
        items={sequenceIds}
        setSelection={handleChange}
      />
    </Container>
  );
};
