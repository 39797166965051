import {
  OrgRolesWrapper,
  Permission,
} from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { RfqsHeader } from "./components/RfqsHeader";
import { RfqsList } from "./components/rfqs-list/RfqsList";
import { RfqsSideActions } from "./components/side-actions/RfqsSideActions";
import { RfqsProvider, useRfqs } from "./providers/RfqsProvider";

const RfqsWithProvider = () => {
  const { rfqs, loading, totalCount, isFiltered } = useRfqs();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "QUOTES" })}</title>
      </Helmet>
      <RfqsHeader />
      <RfqsList
        rfqs={rfqs}
        loading={loading}
        totalCount={totalCount}
        isFiltered={isFiltered}
      />
      <RfqsSideActions />
    </>
  );
};
export const Rfqs = () => (
  <OrgRolesWrapper permissions={[Permission.canViewPrices]} redirect>
    <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
      <RfqsProvider>
        <RfqsWithProvider />
      </RfqsProvider>
    </PaginationProvider>
  </OrgRolesWrapper>
);
