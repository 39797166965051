import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { routes } from "@/config/routes";
import {
  ReleaseReassignmentFieldsFragment,
  ReleaseStatus,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { useCallback, useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { useReleaseActions } from "../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";

export type CallbackFunction = ({
  skip,
  receive,
  version,
  callback,
  skipVendorNotification,
  schedule,
}: {
  skip?: boolean;
  receive?: boolean;
  version?: number;
  callback?: (result: boolean) => void;
  skipVendorNotification?: boolean;
  schedule?: boolean;
}) => Promise<boolean | ReleaseReassignmentFieldsFragment[]> | void | boolean;

export const useUpdateReleaseDialog = ({
  onSuccess,
  callback,
  setVisible,
  visible,
  withCompletionDialog,
  withRedirect,
}: {
  onSuccess: () => void;
  callback?: CallbackFunction;
  setVisible: (visible: boolean) => void;
  visible: boolean;
  withRedirect?: boolean;
  withCompletionDialog?: boolean;
}) => {
  const [skipVendorNotification, setSkipVendorNotification] = useState(false);
  const { release } = useRelease();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const { setRequestedDate } = useReleaseActions();
  const { updateRelease } = useReleaseUpdate();
  const { releaseUpdateOptions } = useReleaseStore(
    useShallow((state) => ({
      releaseUpdateOptions: state.releaseUpdateOptions,
    })),
  );

  useEffect(() => {
    if (visible) {
      setSkipVendorNotification(false);
      setSaving(false);
    }
  }, [visible]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const displayDialog = useCallback(
    (result: boolean | ReleaseReassignmentFieldsFragment[]) => {
      if (result && release?.id) {
        if (withCompletionDialog) {
          onSuccess();
          if (withRedirect) {
            setTimeout(() => {
              navigate(
                generatePath(routes.delivery, {
                  deliveryId: release.id,
                }),
              );
            }, DIALOG_AUTO_CLOSE_TIMER);
          }
        }
        setSaving(false);
        setVisible(false);
      }
    },
    [
      navigate,
      onSuccess,
      release?.id,
      setVisible,
      withCompletionDialog,
      withRedirect,
    ],
  );

  const handleConfirm = useCallback(async () => {
    const date = releaseUpdateOptions.requestedTime
      ? new Date(releaseUpdateOptions.requestedTime)
      : undefined;
    if (!callback) {
      setVisible(false);
      return;
    }

    setSaving(true);
    if (releaseUpdateOptions.requestedTime && release?.id) {
      await updateRelease({
        releaseId: release?.id,
        version: release.version,
        requestedTime: date?.getTime(),
        timeTBD: false,
      });
    }

    await callback({
      skip: true,
      callback: (result) => displayDialog(result),
      skipVendorNotification:
        release?.status !== ReleaseStatus.Received
          ? skipVendorNotification
          : true,
    });
  }, [
    callback,
    displayDialog,
    release?.id,
    release?.status,
    release?.version,
    releaseUpdateOptions.requestedTime,
    setVisible,
    skipVendorNotification,
    updateRelease,
  ]);

  const onDateConfirm = useCallback(
    async (date: Date | null | undefined) => {
      const isTBD = date === undefined;
      setRequestedDate(date ?? null);

      if (release) {
        const input: UpdateContractorReleaseInput = {
          releaseId: release?.id,
          version: release?.version,
        };

        input.requestedTime = !isTBD && date ? date.getTime() : undefined;
        input.timeTBD = isTBD;

        await updateRelease(input);
      }
    },
    [release, setRequestedDate, updateRelease],
  );

  return {
    onDateConfirm,
    handleConfirm,
    handleCancel,
    saving,
    setSaving,
    skipVendorNotification,
    setSkipVendorNotification,
  };
};
