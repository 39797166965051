import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  DistributorInvoiceFieldsFragment,
  InvoiceFieldsFragment,
  InvoiceType,
  ReceiptFieldsFragment,
  UpdateInvoiceInput,
  UpdateInvoiceMutation,
  UpdateReceiptMutation,
} from "@/generated/graphql";
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

export enum HeaderDetailType {
  NUMBER,
  PO_NUMBER,
  ISSUE_DATE,
  DUE_DATE,
  PAID,
  PRE_PAID,
  DESCRIPTION,
  PAYMENT_METHOD,
  NOTES,
  COST_CODE,
}

export const PAID_VIA_INVOICE_ID = "paid-via-invoice-id";

export const useInvoiceDetailsHeader = ({
  type,
  invoice,
  updateInvoice,
  skipAutoMatching,
}: {
  type: InvoiceType;
  invoice:
    | DistributorInvoiceFieldsFragment
    | InvoiceFieldsFragment
    | null
    | ReceiptFieldsFragment;
  updateInvoice?: (
    input: UpdateInvoiceInput,
  ) => Promise<
    UpdateInvoiceMutation | UpdateReceiptMutation | null | undefined
  >;
  skipAutoMatching?: boolean;
}) => {
  const intl = useIntl();
  const { setSystemAlert } = useSnackbar();
  const [editingInputs, setEditingInputs] = useState<HeaderDetailType[]>([]);
  const [number, setNumber] = useState(invoice?.number ?? "");
  const [poNumber, setPoNumber] = useState(invoice?.poNumber ?? "");
  const [issueDate, setIssueDate] = useState(invoice?.issueDate);
  const [dueDate, setDueDate] = useState(invoice?.dueDate);
  const [description, setDescription] = useState(invoice?.description ?? "");
  const [paymentMethodId, setPaymentMethodId] = useState(
    (invoice as ReceiptFieldsFragment)?.paymentMethod?.id ??
      (!invoice?.prePaid ? PAID_VIA_INVOICE_ID : undefined),
  );
  const [optionsAnchorEl, setOptionsAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    setNumber(invoice?.number ?? "");
    setPoNumber(invoice?.poNumber ?? "");
    setIssueDate(invoice?.issueDate);
    setDueDate(invoice?.dueDate);
    setPaymentMethodId(
      (invoice as ReceiptFieldsFragment)?.paymentMethod?.id ??
        (!invoice?.prePaid ? PAID_VIA_INVOICE_ID : undefined),
    );
  }, [invoice]);

  const handleEditToggleClick = useCallback(
    (detailType: HeaderDetailType) => {
      if (editingInputs.includes(detailType)) {
        switch (detailType) {
          case HeaderDetailType.NUMBER:
            setNumber(number ?? "");
            break;
          case HeaderDetailType.PO_NUMBER:
            setPoNumber(poNumber ?? "");
            break;
          case HeaderDetailType.ISSUE_DATE:
            setIssueDate(issueDate);
            break;
          case HeaderDetailType.DUE_DATE:
            setDueDate(dueDate);
            break;
          case HeaderDetailType.DESCRIPTION:
            setDescription(description);
            break;
        }
        setEditingInputs((e) => e.filter((r) => r !== detailType));
      } else {
        setEditingInputs((e) => [...e, detailType]);
      }
    },
    [
      number,
      poNumber,
      issueDate,
      dueDate,
      editingInputs,
      description,
      setNumber,
      setPoNumber,
      setIssueDate,
      setDueDate,
      setEditingInputs,
      setDescription,
    ],
  );

  const handleSaveInvoiceDetail = useCallback(
    async (detailType: HeaderDetailType, value?: string | number | boolean) => {
      switch (detailType) {
        case HeaderDetailType.NUMBER:
          if (invoice) {
            updateInvoice?.({ number: value as string, id: invoice.id });
          }
          setNumber((value as string) ?? "");

          break;
        case HeaderDetailType.PO_NUMBER:
          const previousReleaseId = invoice?.release?.id;
          if (invoice) {
            const result = await updateInvoice?.({
              poNumber: value as string,
              id: invoice.id,
              skipAutoMatching,
            });
            let release: UpdateInvoiceMutation["updateInvoice"]["release"] =
              null;
            if (result && type === InvoiceType.Invoice) {
              release = (result as UpdateInvoiceMutation).updateInvoice.release;
            }
            if (!previousReleaseId && release) {
              setSystemAlert([
                `${intl.$t(
                  { id: "INVOICE_AUTOLINK_INFO" },
                  {
                    releaseNumber: release.sequenceNumber,
                  },
                )}${release.itemCount ? "" : ` ${intl.$t({ id: "INVOICE_AUTOLINK_BLANK_RELEASE" })}`}`,
                intl.$t({ id: "INVOICE_UNLINK_MESSAGE" }),
              ]);
            }
          }
          setPoNumber((value as string) ?? "");
          break;

        case HeaderDetailType.DUE_DATE:
          if (invoice) {
            updateInvoice?.({ dueDate: value as number, id: invoice.id });
          }
          setDueDate(value as number);
          break;

        case HeaderDetailType.ISSUE_DATE:
          if (invoice) {
            updateInvoice?.({ issueDate: value as number, id: invoice.id });
          }
          setIssueDate(value as number);
          break;

        case HeaderDetailType.PAID:
          if (invoice) {
            updateInvoice?.({ paid: value as boolean, id: invoice.id });
          }
          break;

        case HeaderDetailType.PRE_PAID:
          if (invoice) {
            updateInvoice?.({ prePaid: !value as boolean, id: invoice.id });
          }
          break;

        case HeaderDetailType.DESCRIPTION:
          if (invoice) {
            updateInvoice?.({ description: value as string, id: invoice.id });
          }
          setDescription(value as string);
          break;

        case HeaderDetailType.PAYMENT_METHOD:
          if (invoice) {
            if (value === PAID_VIA_INVOICE_ID) {
              updateInvoice?.({
                id: invoice.id,
                clearPaymentMethod: true,
                prePaid: false,
              });
            } else {
              updateInvoice?.({
                id: invoice.id,
                paymentMethodId: value as string,
                prePaid: true,
              });
            }
          }
          setPaymentMethodId(value as string);
          break;

        case HeaderDetailType.NOTES:
          if (invoice) {
            updateInvoice?.({
              id: invoice.id,
              notes: value as string,
            });
          }
          break;

        case HeaderDetailType.COST_CODE:
          if (invoice) {
            if (!value && !!(invoice as ReceiptFieldsFragment).costCode) {
              updateInvoice?.({
                id: invoice.id,
                clearCostCode: true,
              });
            } else if (!!value) {
              updateInvoice?.({
                id: invoice.id,
                costCodeId: value as string,
              });
            }
          }
          break;
      }

      setEditingInputs((e) => e.filter((r) => r !== detailType));
    },
    [invoice, updateInvoice, skipAutoMatching, type, setSystemAlert, intl],
  );

  const handleOptionsClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setOptionsAnchorEl((el) => (el ? null : event.currentTarget));
    },
    [],
  );

  const handleOptionsClose = useCallback(() => setOptionsAnchorEl(null), []);

  const isInvoice = useMemo(() => type === InvoiceType.Invoice, [type]);

  return {
    isInvoice,
    handleOptionsClose,
    handleOptionsClick,
    handleSaveInvoiceDetail,
    handleEditToggleClick,
    optionsAnchorEl,
    paymentMethodId,
    editingInputs,
    number,
    issueDate,
    setIssueDate,
    poNumber,
    dueDate,
    setDueDate,
    description,
  };
};
