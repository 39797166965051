import { CategoriesProvider } from "@/common/components/category-filter/CategoriesProvider";
import { CategoryListFilter } from "@/common/components/category-filter/CategoryListFilter";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { useFacets } from "@/common/components/product-facets-filter/FacetsProvider";
import { ProductFacetsFilter } from "@/common/components/product-facets-filter/ProductFacetsFilter";
import {
  SkusAndProductsProvider,
  useSkusAndProducts,
} from "@/common/components/product-facets-filter/SkusAndProductsProvider";
import { ProductsList } from "@/common/components/product-list/ProductsList";
import {
  ShoppingCartIcon,
  ShoppingIconType,
} from "@/common/components/shopping-cart-icon/ShoppingCartIcon";
import { MasterSkusProductsToggleProvider } from "@/common/components/skus-products-switch/MasterSkusProductsToggleProvider";
import { routes } from "@/config/routes";
import { ProductsContainer } from "@/contractor/pages/home/rfq-master-catalog-import/Rfq.styles";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { ReleaseBreadcrumbs } from "../../../components/ReleaseBreadcrumbs";
import {
  ReleaseShoppingCartProvider,
  useReleaseShoppingCart,
} from "./ReleaseShoppingCartProvider";

const SelectReleaseProductsWithProviders = () => {
  const { release, addToRelease, findItem, removeFromRelease } =
    useReleaseShoppingCart();

  const {
    productFacets,
    productFacetsValues,
    selectedFacets,
    toggleFacetValue,
  } = useFacets();
  const { searchPerformed } = useSkusAndProducts();
  const navigate = useNavigate();

  const intl = useIntl();

  if (!release) {
    return <Loader loading />;
  }

  if (release.deletedAt) {
    navigate(
      generatePath(routes.specifyDeliveryDetails, { deliveryId: release.id }),
    );
  }

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "NEW_RELEASE" })}</title>
      </Helmet>
      <ReleaseBreadcrumbs
        shoppingIcon={
          <ShoppingCartIcon
            key="shopping-cart-icon"
            count={release.items.length}
            path={generatePath(routes.specifyDeliveryDetails, {
              deliveryId: release.id,
            })}
            type={ShoppingIconType.Truck}
          />
        }
      />
      <CategoryListFilter />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <ProductsContainer>
            <If isTrue={searchPerformed}>
              <Grid item xs={2}>
                <ProductFacetsFilter
                  productFacets={productFacets}
                  productFacetsValues={productFacetsValues}
                  selectedFacets={selectedFacets}
                  toggleFacetValue={toggleFacetValue}
                />
              </Grid>
            </If>
            <ProductsList
              addToCart={addToRelease}
              findItem={findItem}
              removeFromCart={removeFromRelease}
            />
          </ProductsContainer>
        </Grid>
      </Grid>
    </>
  );
};

export const SelectReleaseProducts = () => (
  <CategoriesProvider initialCategory={null}>
    <PaginationProvider>
      <MasterSkusProductsToggleProvider>
        <SkusAndProductsProvider>
          <ReleaseShoppingCartProvider>
            <SelectReleaseProductsWithProviders />
          </ReleaseShoppingCartProvider>
        </SkusAndProductsProvider>
      </MasterSkusProductsToggleProvider>
    </PaginationProvider>
  </CategoriesProvider>
);
