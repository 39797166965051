import { AssetItem } from "@/common/components/asset-item/AssetItem";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  InvoiceKickbackCommentFieldsFragment,
} from "@/generated/graphql";
import { FormProvider } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { KickbackCommentAssetUpload } from "./KickbackCommentAssetUpload";
import { useKickbackCommentForm } from "./useKickbackCommentForm";

export interface KickbackCommentFormProps {
  kickbackId: string;
  item?: InvoiceKickbackCommentFieldsFragment;
  onCancelAdding?: () => void;
}

const DescriptionContainer = tw.div`grid grid-cols-[3fr_1fr] gap-2 mb-2 mt-4`;
const Assets = tw.div`grid col-span-full grid-flow-col justify-items-start gap-2 mb-2 justify-start`;
const ButtonContainer = tw.div`flex justify-end gap-4 mt-4`;

const KickbackCommentFormWithProviders = (props: KickbackCommentFormProps) => {
  const intl = useIntl();
  const { item, onCancelAdding } = props;
  const { commentText, removeAsset, assets, methods, onSubmit } =
    useKickbackCommentForm(props);

  return (
    <FormProvider {...methods}>
      <DescriptionContainer>
        <TextFieldControlled
          name="text"
          multiline
          label={intl.$t({
            id: commentText ? "YOUR_COMMENT" : "ADD_COMMENT",
          })}
          required
          minRows={3}
          maxRows={10}
          rules={{ required: true, maxLength: 4096 }}
        />
        <KickbackCommentAssetUpload />
      </DescriptionContainer>
      <Assets>
        {assets.map((asset, index) => (
          <AssetItem
            key={index}
            asset={asset}
            index={index}
            remove={removeAsset}
          />
        ))}
      </Assets>
      <ButtonContainer>
        <OutlinedButton
          className="h-8 px-4 text-xs"
          $small
          onClick={onCancelAdding}
        >
          <FormattedMessage id="CANCEL" />
        </OutlinedButton>
        <PrimaryButton
          className="h-8 px-4 text-xs"
          $small
          onClick={methods.handleSubmit(onSubmit)}
          disabled={!commentText.trim()}
        >
          <FormattedMessage id={item?.id ? "UPDATE" : "SUBMIT"} />
        </PrimaryButton>
      </ButtonContainer>
    </FormProvider>
  );
};

export const KickbackCommentForm = (props: KickbackCommentFormProps) => (
  <UploadAssetProvider
    initialAssets={props.item?.assets}
    context={AssetContext.Note}
  >
    <KickbackCommentFormWithProviders {...props} />
  </UploadAssetProvider>
);
