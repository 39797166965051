import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import useSticky from "@/common/hooks/useSticky";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import {
  LEFT_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH,
  TOP_OFFSET,
} from "@/contractor/pages/home/rfq-quotes/constants";
import { useRfqItemsGrouped } from "@/contractor/pages/home/rfq/providers/RfqItemsGroupedProvider";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { FC, useRef } from "react";
import tw from "tailwind-styled-components";
import { useRfqQuoteOptions } from "../../../../providers/RfqQuoteOptionsProvider";
import { useRfqQuotes } from "../../../../providers/RfqQuotesProvider";
import { CategoryTotals } from "./CategoryTotals";

type Props = {
  category: CategoryState<RfqItemFieldsFragment>;
  topOffset: number;
};

type ContainerProps = {
  $stuck: boolean;
};

const Container = tw.div<ContainerProps>`  
  sticky self-start z-40 group/item first:shadow cursor-pointer
  grid bg-yellow-200 hover:bg-yellow-300 w-full rounded-3xl  
  min-h-11
  ${(p) =>
    p.$stuck
      ? `
    before:absolute before:content-[''] before:inset-0 
    before:rounded-b-4xl before:bg-white before:-right-1
    border-b
  `
      : "shadow"}
`;

const Group = tw.div`
  sticky self-start z-40 group/item
  grid bg-yellow-200 hover:bg-yellow-300 w-full rounded-3xl
`;

const CostCodeName = tw.div`
  grid z-30 grid-flow-col w-full sticky left-0 justify-self-start px-2 py-1
  text-sm font-semibold items-center justify-start 
  rounded-l-3xl bg-yellow-200 group-hover/item:bg-yellow-300
`;

const Headers = tw.div`
  grid grid-flow-col gap-2 items-center px-4
  text-sm font-bold rounded-r-3xl
`;

export const CostCodesHeader: FC<Props> = ({ category, topOffset }) => {
  const { toggleCategory } = useRfqItemsGrouped();
  const { visibleQuotes } = useRfqQuoteOptions();
  const { rfq } = useRfqQuotes();
  const ref = useRef<HTMLDivElement | null>(null);
  const isStuck = useSticky({ ref, top: 300 });

  const handleClick = () => {
    toggleCategory(category.id);
  };

  return (
    <Container
      ref={ref}
      style={{
        top: `${TOP_OFFSET}px`,
      }}
      $stuck={isStuck}
      onClick={handleClick}
    >
      <Group
        style={{
          gridTemplateColumns: `${LEFT_COLUMN_WIDTH}px auto`,
        }}
      >
        <CostCodeName>
          <ProductCategory
            category={category}
            items={category.items.length}
            classes={{ root: "bg-transparent" }}
            includeCounter
            type={ProductCategoryType.CostCode}
            projectId={rfq?.project.id}
          />
        </CostCodeName>
        <Headers
          style={{
            gridTemplateColumns: `repeat(${visibleQuotes.length}, minmax(${MIN_COLUMN_WIDTH}px, 1fr))`,
          }}
        >
          {visibleQuotes.map((quote) => (
            <CategoryTotals
              category={category}
              topOffset={topOffset}
              quote={quote}
              key={quote.id}
            />
          ))}
        </Headers>
      </Group>
    </Container>
  );
};
