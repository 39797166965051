import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { ReleaseStatus } from "@/generated/graphql";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import tw from "tailwind-styled-components";
import { MatchedOrderViewState } from "../../../../enums/MatchedOrderViewState";
import { useInvoiceImportExternalPO } from "../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceMatchedOrder } from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";
import { useInvoiceSelectModeHeader } from "./hooks/useInvoiceSelectModeHeader";
const MenuButton = tw(
  PrimaryButton,
)`w-6 h-6 min-w-6 max-h-6 rounded-xl cursor-pointer h-full items-center justify-center mr-2`;
const Row = tw.div`flex flex-row items-center justify-start w-full`;
const Header = tw(Row)`justify-between mb-4`;
const MenuIconStyled = tw(MenuIcon)`text-base mt-0.5`;
const Title = tw.div`text-base font-medium ml-1 mt-0.5`;

type Props = PropsWithChildren<{
  classes?: {
    root?: string;
    row?: string;
  };
}>;

export const InvoiceSelectModeHeader: FC<Props> = ({ classes, children }) => {
  const { footerState } = useInvoiceVerification();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const { options, anchorEl, handleClose, handleClick } =
    useInvoiceSelectModeHeader();
  const { importModalOpened } = useInvoiceImportExternalPO();

  return (
    <Header className={classes?.root}>
      <Row className={classes?.row}>
        <MenuButton onClick={handleClick}>
          <MenuIconStyled />
        </MenuButton>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          variant="selectedMenu"
          onClose={handleClose}
          keepMounted
        >
          {options.map((item, index) => (
            <MenuItem
              key={index}
              onClick={item.onClick}
              selected={item.selected}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
        <Title>{options.find((o) => o.selected)?.label}</Title>
        <If
          isTrue={
            footerState === InvoiceFooterState.CREATE_ORDER &&
            matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER &&
            !importModalOpened
          }
        >
          <ReleaseStatusChip
            className="ml-2 bg-gray-100"
            status={ReleaseStatus.Draft}
            type="breadcrumb"
            releaseType={undefined}
          />
        </If>
      </Row>
      {children}
    </Header>
  );
};
