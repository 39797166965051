import { Loader } from "@/common/components/loader/Loader";
import { useEffect } from "react";
import { BuyoutEditableDocument } from "./components/document/BuyoutEditableDocument";
import {
  ContractorBuyoutProvider,
  useContractorBuyout,
} from "./providers/ContractorBuyoutProvider";
import { useBuyoutStore } from "./store/useBuyoutStore";

const SpecifyBuyoutDetailsWithProvider = () => {
  const { buyout } = useContractorBuyout();

  if (!buyout) {
    return <Loader loading />;
  }

  return <BuyoutEditableDocument />;
};

export const SpecifyBuyoutDetails = () => {
  const clearBuyout = useBuyoutStore((state) => state.clearBuyout);

  useEffect(() => {
    return () => {
      clearBuyout();
    };
  }, [clearBuyout]);

  return (
    <ContractorBuyoutProvider>
      <SpecifyBuyoutDetailsWithProvider />
    </ContractorBuyoutProvider>
  );
};
