import { If } from "@/common/components/if/If";
import { WizardDecisionStep } from "@/common/components/wizard-modal/components/WizardDecisionStep";
import { routes } from "@/config/routes";
import { CheckCircleOutline, ErrorOutlineOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { useInvoiceSequence } from "../../../providers/InvoiceSequenceProvider";
import { useSplittingInvoicesWizard } from "../SplittingInvoicesWizardProvider";

const Container = tw.div`flex flex-1 flex-col items-center justify-center`;
const Success = tw.div`flex flex-row items-center gap-2`;
const CheckCircleOutlineStyled = tw(
  CheckCircleOutline,
)`mb-4 h-30 w-30 text-blue-500`;
const ErrorOutlineOutlinedStyled = tw(
  ErrorOutlineOutlined,
)`mb-4 h-30 w-30 text-red-500`;

type Props = {
  successful: boolean;
};

export const ImportSuccessful: FC<Props> = ({ successful }) => {
  const intl = useIntl();
  const { selectedInvoices } = useInvoiceSequence();
  const { closeWizard } = useSplittingInvoicesWizard();
  const navigate = useNavigate();

  return (
    <Container>
      <Success>
        {successful ? (
          <CheckCircleOutlineStyled />
        ) : (
          <ErrorOutlineOutlinedStyled />
        )}
        <FormattedMessage
          id={
            successful
              ? "INVOICES_IMPORTED_SUCCESSFULLY"
              : "INVOICES_IMPORTED_ERROR"
          }
        />
      </Success>
      <If isTrue={successful}>
        <WizardDecisionStep
          key="toWarehouseLedgerAccountDecision"
          title={intl.$t({
            id: "DO_YOU_WANT_TO_PROCEED_TO_INVOICE_VERIFICATION",
          })}
          description={intl.$t({
            id: "BY_DOING_THAT_YOU_WILL_VERIFY_YOUR_INVOICES_COMPARED_TO_ORDERS",
          })}
          onNoClick={() => {
            closeWizard();
          }}
          onYesClick={() => {
            // TODO replace
            navigate(
              generatePath(routes.invoiceVerification, {
                invoiceId: selectedInvoices[0].id,
              }),
            );
          }}
          containerClassName="w-full mt-0"
        />
      </If>
    </Container>
  );
};
