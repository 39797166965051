import { useDialog } from "@/common/components/dialog/DialogProvider";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const SuccessContainer = tw.div`flex flex-col gap-2 items-center`;
const SuccessDetails = tw.div`text-sm font-normal`;
const SuccessTitle = tw.div`font-medium text-lg`;

export const useInvoiceIssueDialogs = () => {
  const { openDialog } = useDialog();

  const showUpdateDialog = useCallback(
    (index?: number) => {
      openDialog({
        content: (
          <SuccessModal
            message={
              <SuccessContainer>
                <FormattedMessage
                  id="ISSUE_NUMBER_UPDATED"
                  values={{
                    number: index,
                  }}
                  tagName={SuccessTitle}
                />
                <FormattedMessage
                  id="VENDOR_WAS_NOTIFIED"
                  tagName={SuccessDetails}
                />
              </SuccessContainer>
            }
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
    },
    [openDialog],
  );

  const showResolveDialog = useCallback(
    (index?: number) => {
      openDialog({
        content: (
          <SuccessModal
            message={
              <SuccessContainer>
                <FormattedMessage
                  id="ISSUE_NUMBER_RESOLVED"
                  values={{
                    number: index,
                  }}
                  tagName={SuccessTitle}
                />
              </SuccessContainer>
            }
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
    },
    [openDialog],
  );

  const showAcceptDialog = useCallback(() => {
    openDialog({
      content: (
        <SuccessModal
          message={
            <SuccessContainer>
              <FormattedMessage
                id="ISSUE_ACKNOWLEDGED"
                tagName={SuccessTitle}
              />
              <FormattedMessage
                id="REMEMBER_TO_UPLOAD_INVOICE"
                tagName={SuccessDetails}
              />
            </SuccessContainer>
          }
        />
      ),
      closingTimer: DIALOG_AUTO_CLOSE_TIMER,
    });
  }, [openDialog]);

  const showDeclineDialog = useCallback(() => {
    openDialog({
      content: (
        <SuccessModal
          message={
            <SuccessContainer>
              <FormattedMessage id="ISSUE_DECLINED" tagName={SuccessTitle} />
              <FormattedMessage
                id="CONTRACTOR_WAS_NOTIFIED"
                tagName={SuccessDetails}
              />
            </SuccessContainer>
          }
        />
      ),
      closingTimer: DIALOG_AUTO_CLOSE_TIMER,
    });
  }, [openDialog]);

  return {
    showUpdateDialog,
    showResolveDialog,
    showAcceptDialog,
    showDeclineDialog,
  };
};
