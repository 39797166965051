import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { routes } from "@/config/routes";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router";

export const BackToAllBuyoutsButton = () => {
  return (
    <Link to={routes.buyouts}>
      <OutlinedButton>
        <FormattedMessage id="BACK_TO_ALL_BUYOUTS" />
      </OutlinedButton>
    </Link>
  );
};
