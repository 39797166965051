import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";

type Props = {
  items: BuyoutItemFieldsFragment[];
};

export const BuyoutCategoryPrice: FC<Props> = ({ items }) => {
  const price = useMemo(() => {
    return items
      .filter((item) => item.isIncluded)
      .reduce((total, item) => {
        return total.plus(
          new DecimalSafe(
            new DecimalSafe(item.unitPrice || 0).mul(
              item.quantityDecimal || 0,
            ) ?? 0,
          ),
        );
      }, new DecimalSafe(0))
      .toString();
  }, [items]);

  return (
    <ValueCurrency
      value={price}
      classNames={{ value: "font-medium text-right", container: "items-end" }}
    />
  );
};
