import { FC, useCallback, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { OutlinedButton } from "../../../../../../../../common/components/button/OutlinedButton";
import { PrimaryButton } from "../../../../../../../../common/components/button/PrimaryButton";
import { WarningIcon } from "../../../../../../../../common/components/dialog-icons/WarningIcon";
import { useDialog } from "../../../../../../../../common/components/dialog/DialogProvider";
import { If } from "../../../../../../../../common/components/if/If";
import { InvoiceFooterState } from "../../../../../../../../common/components/invoices/invoice-details/types/InvoiceFooterState";
import { VENDOR_CONTACTS_FAKE_ID } from "../../../../../../../../common/components/invoices/invoice-issue/kickback/InvoiceKickbackForm";
import { PageBackButton } from "../../../../../../../../common/components/page-back-button/PageBackButton";
import { Price } from "../../../../../../../../common/components/price/Price";
import { SuccessModal } from "../../../../../../../../common/components/success-modal/SuccessModal";
import { Tooltip } from "../../../../../../../../common/components/tooltip/Tooltip";
import {
  APPROVED_INVOICES_STATUS,
  DIALOG_AUTO_CLOSE_TIMER,
} from "../../../../../../../../common/const";
import { getUserName } from "../../../../../../../../common/utils/users/getUserName";
import {
  AuthorizationStatus,
  InvoicePermissions,
  InvoiceStatus,
} from "../../../../../../../../generated/graphql";
import { useOrgSettings } from "../../../../../../admin/org-settings/hooks/useOrgSettings";
import { useRelease } from "../../../../../release/providers/ReleaseProvider";
import { useArchiveInvoice } from "../../../scanned-invoices/hooks/useArchiveInvoice";
import { useInvoiceSequence } from "../../../scanned-invoices/providers/InvoiceSequenceProvider";
import { MatchedOrderViewState } from "../../enums/MatchedOrderViewState";
import { useApproveInvoice } from "../../hooks/useApproveInvoice";
import { useInvoiceIssues } from "../../hooks/useInvoiceIssues";
import { useInvoiceKickbacks } from "../../hooks/useInvoiceKickbacks";
import { useInvoiceMatchedOrder } from "../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../matched-order/components/InvoiceVerificationForm";
import { InvoiceKickbackFormValues } from "./InvoiceFooter";
import { CreateOrderButtons } from "./buttons/CreateOrderButtons";
import { EditOrderButtons } from "./buttons/EditOrderButtons";

export const ButtonContainer = tw.div`flex gap-1.5 h-10 justify-end items-center ml-auto`;
const TooltipContent = tw.div`text-center`;
const SuccessContainer = tw.div`flex flex-col gap-2 items-center`;
const SuccessDetails = tw.div`text-sm`;
const SuccessTitle = tw.div`font-medium text-lg`;
const FooterOutlinedButton = tw(OutlinedButton)`px-2 min-w-20`;
const FooterPrimaryButton = tw(PrimaryButton)`px-2 h-8`;

type Props = {
  createReleaseForm: UseFormReturn<
    InvoiceCreateReleaseFormValues,
    undefined,
    undefined
  >;
  kickbackForm: UseFormReturn<InvoiceKickbackFormValues, undefined, undefined>;
  isApprovedOrPaid: boolean;
  fetchInvoicePermissions: (
    invoiceId: string,
    projectId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
  permissions:
    | (Omit<InvoicePermissions, "approve"> & { approve?: AuthorizationStatus })
    | undefined;
  saveChanges: () => Promise<void>;
};

export const InvoiceFooterButtons: FC<Props> = ({
  createReleaseForm: {
    getValues: createReleaseFormGetValues,
    trigger: createReleaseFormTrigger,
    handleSubmit: createReleaseFormHandleSubmit,
  },
  kickbackForm: {
    watch: kickbackFormWatch,
    getValues: kickbackFormGetValues,
    reset: kickbackFormReset,
  },
  isApprovedOrPaid,
  permissions,
  fetchInvoicePermissions,
  saveChanges,
}) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const {
    invoice,
    approving,
    footerState,
    setFooterState,
    hasChanges,
    resetUpdates,
  } = useInvoiceVerification();
  const { setMatchedOrderViewState } = useInvoiceMatchedOrder();
  const { createInvoiceKickback } = useInvoiceKickbacks();
  const { createInvoiceIssue } = useInvoiceIssues();
  const { archiveInvoice } = useArchiveInvoice();
  const { navigateToNextSequence } = useInvoiceSequence();
  const { settings } = useOrgSettings();
  const { refetch: refetchRelease } = useRelease();
  const { approve } = useApproveInvoice();

  const assigneeIds = kickbackFormWatch("assigneeIds");

  const handleKickbackClick = async () => {
    if (assigneeIds.includes(VENDOR_CONTACTS_FAKE_ID)) {
      await sendIssueToVendor();
      return;
    }
    await kickback();
  };

  const sendIssueToVendor = useCallback(async () => {
    const { description } = kickbackFormGetValues();
    if (
      await createInvoiceIssue({
        invoiceId: invoice?.id ?? "",
        description,
        assetUrls: kickbackFormGetValues().assetUrls,
      })
    ) {
      kickbackFormReset();
      openDialog({
        content: (
          <SuccessModal message={intl.$t({ id: "ISSUE_REPORTED_TO_VENDOR" })} />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
      setFooterState(InvoiceFooterState.DEFAULT);
      navigateToNextSequence();
    }
  }, [
    kickbackFormGetValues,
    kickbackFormReset,
    createInvoiceIssue,
    invoice?.id,
    openDialog,
    intl,
    setFooterState,
    navigateToNextSequence,
  ]);

  const kickback = useCallback(async () => {
    const { description, assigneeIds } = kickbackFormGetValues();

    const result = await createInvoiceKickback({
      invoiceId: invoice?.id ?? "",
      description,
      assigneeIds,
      assetUrls: kickbackFormGetValues().assetUrls,
    });
    if (result) {
      kickbackFormGetValues();
      openDialog({
        content: (
          <SuccessModal
            message={
              <SuccessContainer>
                <FormattedMessage
                  id="KICKBACK_SENT_TO_TITLE"
                  tagName={SuccessTitle}
                />
                <FormattedMessage
                  id="KICKBACK_SENT_TO"
                  values={{
                    kickbackTo: result.kickbacks[
                      result.kickbacks.length - 1
                    ].assignees
                      .map(getUserName)
                      .join(", "),
                  }}
                  tagName={SuccessDetails}
                />
              </SuccessContainer>
            }
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
      setFooterState(InvoiceFooterState.DEFAULT);
      navigateToNextSequence();
    }
  }, [
    kickbackFormGetValues,
    createInvoiceKickback,
    invoice?.id,
    openDialog,
    setFooterState,
    navigateToNextSequence,
  ]);

  const back = useCallback(() => {
    resetUpdates();
    if (invoice?.release?.id) {
      refetchRelease();
    }
    setFooterState(InvoiceFooterState.DEFAULT);
    setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
  }, [
    resetUpdates,
    invoice?.release?.id,
    setFooterState,
    setMatchedOrderViewState,
    refetchRelease,
  ]);

  const showSaveChangesDialog = useCallback(() => {
    if (!hasChanges) {
      return;
    }

    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "SAVE_CHANGES" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "INVOICE_UNSAVED_CHANGES" }),
      text: intl.$t({ id: "SAVE_COVERAGE_CHANGES_WARNING" }),
      handleConfirm: saveChanges,
    });
  }, [openDialog, saveChanges, hasChanges, intl]);

  const defaultFooterState = useMemo(
    () =>
      [
        InvoiceFooterState.DEFAULT,
        InvoiceFooterState.EDIT_INVOICE_COVERAGES,
      ].includes(footerState),
    [footerState],
  );

  return (
    <ButtonContainer>
      <If
        isTrue={
          footerState === InvoiceFooterState.EDIT_INVOICE_COVERAGES &&
          isApprovedOrPaid
        }
      >
        <FooterOutlinedButton onClick={back}>
          <FormattedMessage id="BACK" />
        </FooterOutlinedButton>
      </If>
      <CreateOrderButtons
        createReleaseFormGetValues={createReleaseFormGetValues}
        createReleaseFormTrigger={createReleaseFormTrigger}
        createReleaseFormHandleSubmit={createReleaseFormHandleSubmit}
        fetchInvoicePermissions={fetchInvoicePermissions}
      />
      <EditOrderButtons
        createReleaseFormHandleSubmit={createReleaseFormHandleSubmit}
      />
      <If isTrue={!invoice?.archivedAt}>
        <If isTrue={footerState === InvoiceFooterState.KICKBACK}>
          <FooterOutlinedButton
            onClick={() => setFooterState(InvoiceFooterState.DEFAULT)}
          >
            <FormattedMessage id="BACK" />
          </FooterOutlinedButton>
          <FooterPrimaryButton
            className="py-0"
            onClick={handleKickbackClick}
            disabled={assigneeIds.length === 0}
          >
            <FormattedMessage id="INVOICE_KICKBACK" />
          </FooterPrimaryButton>
        </If>
        <If isTrue={defaultFooterState && invoice?.permissions.archive}>
          <If
            isTrue={
              !(
                footerState === InvoiceFooterState.EDIT_INVOICE_COVERAGES &&
                isApprovedOrPaid
              )
            }
          >
            <PageBackButton outlinedButtonClassName="min-w-20" />
          </If>
          <FooterOutlinedButton
            onClick={() => {
              openDialog({
                cancelButtonText: intl.$t({ id: "CANCEL" }),
                confirmButtonText: intl.$t({ id: "PROCEED" }),
                icon: <WarningIcon />,
                title: intl.$t({ id: "DELETE_INVOICE_DETAILS" }),
                text: intl.$t({ id: "DELETE_INVOICE_DETAILS_TEXT" }),
                handleConfirm: async () => {
                  await archiveInvoice(invoice?.id ?? "");
                  navigateToNextSequence({ navigateToInvoices: true });
                },
              });
            }}
          >
            <FormattedMessage id="DELETE" />
          </FooterOutlinedButton>
        </If>
        <If
          isTrue={defaultFooterState && invoice?.status !== InvoiceStatus.Paid}
        >
          <FooterOutlinedButton
            className="py-0"
            onClick={() => {
              showSaveChangesDialog();
              setFooterState(InvoiceFooterState.KICKBACK);
            }}
          >
            <FormattedMessage id="INVOICE_KICKBACK" />
          </FooterOutlinedButton>
        </If>
        <If
          isTrue={defaultFooterState && invoice?.status !== InvoiceStatus.Paid}
        >
          <If
            isTrue={
              invoice?.permissions.edit === AuthorizationStatus.Authorized &&
              invoice.release &&
              (footerState === InvoiceFooterState.DEFAULT ||
                footerState === InvoiceFooterState.EDIT_INVOICE_COVERAGES) &&
              hasChanges
            }
          >
            <FooterOutlinedButton onClick={saveChanges}>
              <FormattedMessage
                id={
                  invoice && APPROVED_INVOICES_STATUS.includes(invoice.status)
                    ? "SAVE_CHANGES"
                    : "SAVE_PROGRESS"
                }
              />
            </FooterOutlinedButton>
          </If>
          <If
            isTrue={
              invoice?.release && invoice.status !== InvoiceStatus.Approved
            }
          >
            <Tooltip
              id="asset"
              hideTooltip={
                permissions?.approve === AuthorizationStatus.Authorized
              }
              element={
                <FooterPrimaryButton
                  onClick={() => approve()}
                  testId="approve-invoice"
                  loading={approving}
                  disabled={
                    permissions?.approve !== AuthorizationStatus.Authorized
                  }
                  $small
                >
                  <FormattedMessage id="INVOICE_APPROVE_INVOICE" />
                </FooterPrimaryButton>
              }
            >
              <If
                isTrue={permissions?.approve !== AuthorizationStatus.Authorized}
              >
                <TooltipContent>
                  <FormattedMessage
                    id="ROLES_APPROVE_INVOICE"
                    tagName={"div"}
                  />
                  {settings?.invoices.approval?.roles.map((role) => (
                    <div key={role.role}>
                      - {intl.$t({ id: `USER_ROLE_${role.role}` })}
                      <If isTrue={role.maxAmount}>
                        {" "}
                        <FormattedMessage
                          id="UP_TO_PRICE_AMOUNT"
                          values={{
                            price: <Price price={role.maxAmount} />,
                          }}
                        />
                      </If>
                    </div>
                  ))}
                </TooltipContent>
              </If>
            </Tooltip>
          </If>
        </If>
        <If
          isTrue={
            invoice?.status === InvoiceStatus.Paid &&
            footerState === InvoiceFooterState.EDIT_INVOICE_COVERAGES &&
            hasChanges
          }
        >
          <FooterPrimaryButton onClick={saveChanges} $small>
            <FormattedMessage id={"SAVE_CHANGES"} />
          </FooterPrimaryButton>
        </If>
      </If>
    </ButtonContainer>
  );
};
