import { DecimalSafe } from "@/common/utils/decimalSafe";
import { ReleaseItem } from "@/generated/graphql";
import { filterReleaseItemsByDates } from "../../../../../../common/components/release-items-date-range-filter/utils/filterReleaseItemsByDates";
import { ExpandedReleaseItem } from "../../providers/ReleaseProvider";
import {
  ReleaseItemFilter,
  ReleaseItemsFilters,
} from "../../types/ReleaseItemsFilters";

const filterFullyReceived = (
  item: Pick<ReleaseItem, "receivedQuantityDecimal" | "quantityDecimal">,
  filter: ReleaseItemsFilters,
) => {
  switch (filter[ReleaseItemFilter.FULLY_RECEIVED]) {
    case false:
      return new DecimalSafe(item.receivedQuantityDecimal || 0).lessThan(
        item.quantityDecimal,
      );
    case true:
      return new DecimalSafe(
        item.receivedQuantityDecimal || 0,
      ).greaterThanOrEqualTo(item.quantityDecimal);
    default:
      return true;
  }
};

const filterFullyInvoiced = (
  item: Pick<
    ReleaseItem,
    "receivedQuantityDecimal" | "quantityDecimal" | "invoicedQuantity"
  >,
  filter: ReleaseItemsFilters,
) => {
  switch (filter[ReleaseItemFilter.FULLY_INVOICED]) {
    case false:
      return new DecimalSafe(item.invoicedQuantity).lessThan(
        item.quantityDecimal,
      );
    case true:
      return new DecimalSafe(item.invoicedQuantity).greaterThanOrEqualTo(
        item.quantityDecimal,
      );
    default:
      return true;
  }
};

const filterTags = (item: ExpandedReleaseItem, filteredTags: string[]) =>
  filteredTags.length
    ? item.tags.some((tag) => filteredTags.includes(tag.id))
    : true;

export const filterReleaseItems = ({
  items,
  filter,
  filteredTags,
  timeTbdFilter,
  filterDates,
  isReleaseTbd,
  releaseTime,
}: {
  items: ExpandedReleaseItem[];
  filter: ReleaseItemsFilters;
  filteredTags: string[];
  timeTbdFilter: boolean | undefined;
  filterDates?: {
    minDate?: number;
    maxDate?: number;
  } | null;
  isReleaseTbd: boolean | undefined;
  releaseTime: number | null | undefined;
}) =>
  items
    .filter((item) => filterFullyInvoiced(item, filter))
    .filter((item) => filterFullyReceived(item, filter))
    .filter((item) => filterTags(item, filteredTags))
    .filter((item) =>
      filterReleaseItemsByDates(item, {
        timeTbdFilter,
        filterDates,
        isReleaseTbd,
        releaseTime,
      }),
    );
