import { ProjectItemFieldsFragment } from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { useAddEstimatedItemVendorView } from "./useAddEstimatedItemVendorView";

const VendorName = tw.div`text-sm font-light`;

type Props = {
  item: ProjectItemFieldsFragment;
};

export const AddEstimatedItemVendorView = ({ item }: Props) => {
  const { vendorName } = useAddEstimatedItemVendorView(item);
  return <VendorName>{vendorName}</VendorName>;
};
