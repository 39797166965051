import { Checkbox } from "@/common/components/checkbox/Checkbox";
import { TaxableReleaseItem } from "@/common/components/taxable-release-item/TaxableReleaseItem";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorRelease } from "../../providers/DistributorReleaseProvider";

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  readonly: boolean;
};

export const DistributorTaxableReleaseItem: FC<Props> = ({
  item,
  readonly,
}) => {
  const { updateVendorReleaseItem } = useDistributorRelease();

  const handleChange = useCallback(async () => {
    await updateVendorReleaseItem({
      releaseItemId: item.id,
      taxable: !item.taxable,
    });
  }, [updateVendorReleaseItem, item]);

  if (readonly) {
    return <TaxableReleaseItem item={item} />;
  }

  return (
    <Checkbox size="small" checked={item.taxable} onChange={handleChange} />
  );
};
