import { If } from "@/common/components/if/If";
import { LinkChunks } from "@/common/components/link-chunks/LinkChunks";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { TextField } from "@/common/components/textfield/TextField";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { AdminUsersInfo } from "@/contractor/pages/home/release/components/connections/components/common/AdminUsersInfo";
import {
  ConnectionError,
  ValidationType,
} from "@/contractor/pages/home/release/components/connections/components/common/ExportErrorList";
import {
  IntegrationType,
  InvoiceToExportFieldsFragment,
} from "@/generated/graphql";
import { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useExportInvoice } from "../../../../providers/ExportInvoiceProvider";

const AdminUsersInfoStyled = tw(AdminUsersInfo)`text-white text-base`;

export const useInvoiceExportInformation = ({
  invoice,
}: {
  invoice: InvoiceToExportFieldsFragment;
}) => {
  const intl = useIntl();
  const { settings, connectedIntegrationType } = useOrgSettings();
  const { updatedInvoices, updateInvoice } = useExportInvoice();
  const { includePoNumbering } = usePoNumberingSettingsCheck();

  const hasExportedPo = useMemo(
    () =>
      !includePoNumbering ||
      !!invoice.release?.exportedAt ||
      !!invoice.release?.poLink?.syncedAt,
    [
      includePoNumbering,
      invoice.release?.exportedAt,
      invoice.release?.poLink?.syncedAt,
    ],
  );

  const updatedInvoice = useMemo(
    () => updatedInvoices.find((r) => r.id === invoice.id),
    [invoice.id, updatedInvoices],
  );

  const hasVendorId = useMemo(
    () =>
      !!invoice.release?.preferredVendor?.externalCode ||
      updatedInvoice?.externalVendorCode,
    [
      invoice.release?.preferredVendor?.externalCode,
      updatedInvoice?.externalVendorCode,
    ],
  );

  // TO DO - add logic after implementing the inventory receipt
  const hasInventoryReceipt = useMemo(() => true, []);
  const hasMissingItems = useMemo(
    () => invoice?.release?.hasMissingCostCodes,
    [invoice?.release?.hasMissingCostCodes],
  );

  const includedValidations: ConnectionError[] = useMemo(
    () =>
      [
        {
          id: "EXPORT_RELEASE_ERROR_MISSING_ITEMS",
          condition: hasMissingItems,
          key: "EXPORT_RELEASE_ERROR_MISSING_ITEMS",
          toolTipKey: "EXPORT_RELEASE_ERROR_MISSING_ITEMS_TOOLTIP",
          values: {
            releaseNumber: invoice.release?.sequenceNumber,
            sub: (chunks: ReactNode) => (
              <LinkChunks
                chunks={chunks}
                route={routes.delivery}
                routeParams={{ deliveryId: invoice.release?.id || "" }}
              />
            ),
          },
        },
        {
          id: "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID",
          key: "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID",
          condition: !hasVendorId,
          includeCondition:
            !invoice.release?.preferredVendor?.externalCode &&
            !!invoice.release?.preferredVendor?.deletedAt,
          toolTipKey: !invoice.release?.preferredVendor?.deletedAt
            ? "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID_TOOLTIP"
            : "EXPORT_RELEASE_ERROR_MISSING_DELETED_VENDOR_ID_TOOLTIP",
          adminToolTipKey: !invoice.release?.preferredVendor?.deletedAt
            ? "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID_TOOLTIP_ADMIN"
            : "EXPORT_RELEASE_ERROR_MISSING_DELETED_VENDOR_ID_TOOLTIP",
          values: {
            sub: (chunks: ReactNode) => (
              <LinkChunks
                chunks={chunks}
                route={routes.vendorsWithId}
                routeParams={{
                  vendorId: invoice.release?.preferredVendor?.id || "",
                }}
              />
            ),
            sub1: () => <AdminUsersInfoStyled />,
            org: invoice.release?.sellerOrgLocation?.org.name || "da",
          },
          child: (
            <If isTrue={invoice.release?.preferredVendor?.deletedAt}>
              <TextField
                placeholder={intl.$t({
                  id: "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID",
                })}
                className="mb-auto"
                value={updatedInvoice?.externalVendorCode}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateInvoice({
                    id: invoice.id,
                    externalVendorCode: event.target.value,
                  })
                }
                size="small"
                required
                shrink
              />
            </If>
          ),
        },
        {
          id: "EXPORT_INVOICE_ERROR_MISSING_GL",
          condition:
            connectedIntegrationType === IntegrationType.Foundation
              ? !settings?.integrations.accounting.find((a) => a.enabled)
                  ?.toJobLedgerAccount
              : !invoice.release?.type.ledgerAccount,
          key: "EXPORT_INVOICE_ERROR_MISSING_GL",
          toolTipKey: "EXPORT_INVOICE_ERROR_MISSING_GL_TOOLTIP",
          adminToolTipKey: "EXPORT_INVOICE_ERROR_MISSING_GL_TOOLTIP_ADMIN",
          values: {
            sub1: () => <AdminUsersInfoStyled />,
            sub: (chunks: ReactNode) => (
              <LinkChunks chunks={chunks} route={routes.connections} />
            ),
          },
        },
        {
          id: "EXPORT_INVOICE_ERROR_PO_NOT_EXPORTED",
          condition: !hasExportedPo,
          key: "EXPORT_INVOICE_ERROR_PO_NOT_EXPORTED",
          toolTipKey: "EXPORT_INVOICE_ERROR_PO_NOT_EXPORTED_TOOLTIP",
          validationType: ValidationType.Warning,
        },
        {
          id: "EXPORT_INVOICE_ERROR_INVENTORY_RECEIPT_NOT_CREATED",
          condition: !hasInventoryReceipt,
          key: "EXPORT_INVOICE_ERROR_INVENTORY_RECEIPT_NOT_CREATED",
          toolTipKey:
            "EXPORT_INVOICE_ERROR_INVENTORY_RECEIPT_NOT_CREATED_TOOLTIP",
          validationType: ValidationType.Warning,
        },
      ].filter((e) =>
        e.includeCondition !== undefined
          ? e.includeCondition || e.condition
          : e.condition,
      ),
    [
      connectedIntegrationType,
      hasExportedPo,
      hasInventoryReceipt,
      hasMissingItems,
      hasVendorId,
      intl,
      invoice.id,
      invoice.release?.id,
      invoice.release?.preferredVendor?.deletedAt,
      invoice.release?.preferredVendor?.externalCode,
      invoice.release?.preferredVendor?.id,
      invoice.release?.sellerOrgLocation?.org.name,
      invoice.release?.sequenceNumber,
      invoice.release?.type.ledgerAccount,
      settings?.integrations.accounting,
      updateInvoice,
      updatedInvoice?.externalVendorCode,
    ],
  );

  return {
    includedValidations,
  };
};
