import { FC } from "react";
import { Outlet } from "react-router";
import tw from "tailwind-styled-components";
import { AdminItemsTabNavigation } from "./AdminItemsTabNavigation";

const Container = tw.div`mt-14 px-16 -mx-16 bg-gray-100 flex flex-col -mb-8 pb-8 min-h-[calc(100vh-210px)]`;

export const WithAdminItemsTabNavigation: FC = () => (
  <>
    <AdminItemsTabNavigation />
    <Container>
      <Outlet />
    </Container>
  </>
);
