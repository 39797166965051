import { ImportableExternalPOItems } from "@/common/components/import-external-po/import-external-po-wizard/importable-external-po-items/ImportableExternalPOItems";
import { ExternalPOs } from "@/common/components/import-external-po/import-external-po-wizard/importable-external-pos/ExternalPOs";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useExternalPOsWithPagination } from "@/common/hooks/useExternalPOsWithPagination";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useReverseSyncPO } from "@/contractor/pages/home/release/components/connections/hooks/useReverseSyncPO";
import {
  ExternalPoFieldsFragment,
  useExternalPoQuery,
} from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useShallow } from "zustand/react/shallow";
import { useIntegrationFeatureRequirement } from "../../integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { usePoNumberingSettingsCheck } from "../../po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useNestedStepper } from "../../stepper/NestedStepper";
import {
  NestedWizardModalPage,
  WizardModalPage,
} from "../../wizard-modal/WizardModal";
import { useExternalPOUtils } from "../hooks/useExternalPOUtils";
import { useImportExternalCostCodes } from "../hooks/useImportExternalCostCodes";
import { useImportExternalPoStore } from "../stores/useImportExternalPoStore";
import { ExternalPOsQueryInputType } from "../types/ExternalPOsQueryInputType";

type ImportExternalPoOpenModal = {
  skipPoList: boolean;
};

type GetPageFunction = (props: {
  importSelectedExternalPO: (
    externalPO: ExternalPoFieldsFragment | null | undefined,
  ) => void;
  includeDate?: boolean;
}) => WizardModalPage[];

export const useImportExternalPos = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { moveToNextStep, setStep, step } = useNestedStepper();
  const {
    selectedBaseExternalPO,
    setSelectedBaseExternalPO,
    setShowModal,
    showModal,
    variables,
    setVariables,
    forceFetchExternalPO,
    resetImportExternalPo,
    importedItems,
    setImportedItems,
    options,
    setOptions,
  } = useImportExternalPoStore(
    useShallow((state) => ({
      selectedBaseExternalPO: state.selectedBaseExternalPO,
      setSelectedBaseExternalPO: state.setSelectedBaseExternalPO,
      setShowModal: state.setShowModal,
      showModal: state.showModal,
      variables: state.variables,
      setVariables: state.setVariables,
      forceFetchExternalPO: state.options.forceFetchExternalPO,
      resetImportExternalPo: state.resetImportExternalPo,
      importedItems: state.importedItems,
      setImportedItems: state.setImportedItems,
      options: state.options,
      setOptions: state.setOptions,
    })),
  );
  const { orderTypeId, poNumber, projectId, release } = variables;
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { reverseSyncPO } = useReverseSyncPO();
  const { getIncludedItemsCategory, getImportableItems } = useExternalPOUtils();
  const {
    importExternalCostCodes: importCostCodes,
    importingExternalCostCodes,
  } = useImportExternalCostCodes();
  const {
    getUnsupportedCostTypesCategory,
    getMissingCostCodesCategory,
    getMissingTagsCategory,
    checkIfPOIsItemized,
  } = useExternalPOUtils();

  const externalPOsQueryForm = useFormContext<ExternalPOsQueryInputType>();
  const reset = useCallback(
    (poNumber?: string) => {
      resetImportExternalPo();
      externalPOsQueryForm.reset({
        sellerOrgLocationID: "",
        search: "",
        minDate: null,
        maxDate: null,
        poNumber,
      });
    },
    [resetImportExternalPo, externalPOsQueryForm],
  );

  const { includePoNumbering } = usePoNumberingSettingsCheck();

  const projectIdForm = externalPOsQueryForm.watch("projectId");
  const sellerOrgLocationID = externalPOsQueryForm.watch("sellerOrgLocationID");
  const search = externalPOsQueryForm.watch("search");
  const minDate = externalPOsQueryForm.watch("minDate");
  const maxDate = externalPOsQueryForm.watch("maxDate");

  const externalPOsPaginatedOutput = useExternalPOsWithPagination({
    sourceSystem: connectedSourceSystem,
    projectId:
      !!projectIdForm && projectIdForm !== "" ? projectIdForm : undefined,
    sellerOrgLocationID:
      !!sellerOrgLocationID && sellerOrgLocationID !== ""
        ? sellerOrgLocationID
        : undefined,
    search: search ?? undefined,
    minDate: minDate ? Number(minDate) : undefined,
    maxDate: maxDate ? Number(maxDate) : undefined,
    skip:
      !includePoNumbering || !showModal || options?.readonlyMode || step === 1,
  });

  const openModal = useCallback(
    (options?: ImportExternalPoOpenModal) => {
      setShowModal(true);
      setStep(options?.skipPoList ? 1 : 0);
      setOptions({
        readonlyMode: !!options?.skipPoList,
        forceFetchExternalPO: !!options?.skipPoList,
      });
    },
    [setOptions, setShowModal, setStep],
  );

  const closeModal = useCallback(() => {
    setShowModal(false);
    resetImportExternalPo();
  }, [resetImportExternalPo, setShowModal]);

  const hasMissingPoLink = useMemo(
    () => !!variables.release?.poLink === null,
    [variables.release],
  );

  const hasExternalPoReadsProjectSpecific = useMemo(
    () =>
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.ExternalPoReadsProjectSpecific,
      ),
    [hasFeatureInConnectedSourceSystem],
  );

  const externalPoQueryOptions = useMemo(() => {
    if (!connectedSourceSystem || !showModal || step === 0) {
      return { skip: true };
    }
    if (selectedBaseExternalPO) {
      return {
        variables: {
          input: {
            sourceSystem: connectedSourceSystem,
            externalId: selectedBaseExternalPO.externalId,
            projectId: hasExternalPoReadsProjectSpecific
              ? selectedBaseExternalPO.project?.project?.id
              : undefined,
            releaseTypeId: orderTypeId,
          },
        },
        skip:
          !selectedBaseExternalPO.externalId ||
          (hasExternalPoReadsProjectSpecific
            ? !selectedBaseExternalPO.project?.project?.id
            : false),
      };
    }
    if (!!release?.id && forceFetchExternalPO) {
      return {
        variables: {
          input: {
            sourceSystem: connectedSourceSystem,
            number: release?.poNumber,
            projectId: hasExternalPoReadsProjectSpecific
              ? release?.project?.id
              : undefined,
          },
        },
        skip:
          !release?.poNumber ||
          (hasExternalPoReadsProjectSpecific ? !release?.project?.id : false),
      };
    }
    return {
      variables: {
        input: {
          sourceSystem: connectedSourceSystem,
          number: poNumber,
          projectId: hasExternalPoReadsProjectSpecific ? projectId : undefined,
        },
      },
      skip:
        !forceFetchExternalPO ||
        !poNumber ||
        (hasExternalPoReadsProjectSpecific ? !projectId : false),
    };
  }, [
    connectedSourceSystem,
    showModal,
    step,
    selectedBaseExternalPO,
    release?.id,
    release?.poNumber,
    release?.project?.id,
    forceFetchExternalPO,
    poNumber,
    hasExternalPoReadsProjectSpecific,
    projectId,
    orderTypeId,
  ]);

  const {
    data,
    loading: loadingExternalPo,
    error,
    refetch,
  } = useExternalPoQuery({
    ...externalPoQueryOptions,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const importableItems = getImportableItems(data?.externalPO);
    setOptions({
      disableImportButton: importableItems.length === 0,
    });
  }, [data?.externalPO, getImportableItems, setOptions]);

  useEffect(() => {
    if (data) {
      const sellerOrgLocationId =
        data?.externalPO?.vendor?.orgPreferredVendors?.[0]?.sellerOrgLocation
          .id;
      const orderTypeId =
        variables.orderTypeId ?? data?.externalPO?.releaseType?.id;
      const date = data?.externalPO?.date;
      if (orderTypeId || sellerOrgLocationId || date) {
        setVariables({
          ...(sellerOrgLocationId && { sellerOrgLocationId }),
          ...(orderTypeId && { orderTypeId }),
          ...(date && { date }),
        });
      }
    }
  }, [data, variables.orderTypeId, setVariables]);

  const isNonItemizedPO = useMemo(
    () => !checkIfPOIsItemized(data?.externalPO),
    [checkIfPOIsItemized, data?.externalPO],
  );

  const syncImmutablePo = useCallback(async () => {
    if (release?.poLink?.immutable) {
      await reverseSyncPO(release?.poLink?.id, release?.id);
      setShowModal(false);
    }
  }, [
    release?.poLink?.immutable,
    release?.poLink?.id,
    release?.id,
    reverseSyncPO,
    setShowModal,
  ]);

  const includedItemsCategory = useMemo(
    () => getIncludedItemsCategory(data?.externalPO),
    [data?.externalPO, getIncludedItemsCategory],
  );

  const getPages: GetPageFunction = useCallback(
    ({ importSelectedExternalPO, includeDate }) => [
      {
        pages: [
          {
            hideHeader: true,
            component: <ExternalPOs />,
            onNextClick: moveToNextStep,
            onNextClickDisabled: !selectedBaseExternalPO,
            onCloseClick: () => setShowModal(false),
          } as NestedWizardModalPage,
        ],
      },
      {
        pages: [
          {
            hideHeader: true,
            component: <ImportableExternalPOItems includeDate={includeDate} />,
            onNextClick: !!release?.poLink?.immutable
              ? syncImmutablePo
              : loadingExternalPo ||
                  (data?.externalPO?.poLinks ?? []).length > 0
                ? undefined
                : () => importSelectedExternalPO(data?.externalPO),
            onNextClickDisabled:
              options.disableImportButton ||
              (!!release?.poLink?.immutable
                ? false
                : !data?.externalPO?.project?.project ||
                  (data?.externalPO?.vendor?.orgPreferredVendors ?? [])
                    .length === 0 ||
                  (data?.externalPO?.poLinks ?? []).length > 0),
            onNextLabel: intl.$t(
              {
                id:
                  options.labelKeys?.importButton ??
                  (release?.poLink?.immutable
                    ? "SYNC_IMMUTABLE_PO"
                    : isNonItemizedPO
                      ? "ASSOCIATE_PO_WITH_ORDER"
                      : "IMPORT"),
              },
              {
                integration: connectedSourceSystem,
              },
            ),
            onNextClassName: isNonItemizedPO ? "w-60" : "",
            onCloseClick: () => setShowModal(false),
          } as NestedWizardModalPage,
        ],
      },
    ],
    [
      connectedSourceSystem,
      data?.externalPO,
      intl,
      isNonItemizedPO,
      loadingExternalPo,
      moveToNextStep,
      options.disableImportButton,
      options.labelKeys?.importButton,
      release?.poLink?.immutable,
      selectedBaseExternalPO,
      setShowModal,
      syncImmutablePo,
    ],
  );

  const importExternalCostCodes = useCallback(async () => {
    if (data?.externalPO) {
      await importCostCodes(data?.externalPO);
      if (externalPoQueryOptions.skip) {
        refetch();
      }
    }
  }, [data?.externalPO, externalPoQueryOptions.skip, importCostCodes, refetch]);

  const missingTagsCategory = useMemo(
    () => getMissingTagsCategory(data?.externalPO),
    [data?.externalPO, getMissingTagsCategory],
  );
  const missingCostCodesCategory = useMemo(
    () => getMissingCostCodesCategory(data?.externalPO),
    [data?.externalPO, getMissingCostCodesCategory],
  );
  const unsupportedCostTypesCategory = useMemo(
    () => getUnsupportedCostTypesCategory(data?.externalPO),
    [data?.externalPO, getUnsupportedCostTypesCategory],
  );
  const aggregatedItems = useMemo(
    () => data?.externalPO?.itemGroups.aggregatedItems,
    [data?.externalPO?.itemGroups],
  );

  useErrorEffect(error);

  return {
    getPages,
    externalPo: data?.externalPO,
    loadingExternalPo,
    importExternalCostCodes,
    includedItemsCategory,
    importingExternalCostCodes,
    missingTagsCategory,
    unsupportedCostTypesCategory,
    missingCostCodesCategory,
    selectedBaseExternalPO,
    setSelectedBaseExternalPO,
    isNonItemizedPO,
    aggregatedItems,
    variables,
    setVariables,
    setShowModal,
    showModal,
    reset,
    importedItems,
    setImportedItems,
    openModal,
    closeModal,
    hasMissingPoLink,
    options,
    externalPOsPaginatedOutput,
    setOptions,
  };
};
