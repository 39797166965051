import { OverlayPanel } from "@/common/components/panel/OverlayPanel";

import { InvoicesExport } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/export/InvoicesExport";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExportInvoiceProvider } from "../../../providers/ExportInvoiceProvider";
import {
  InvoicesByIdsProvider,
  useInvoicesByIds,
} from "../../../providers/InvoicesByIdsProvider";
import { useExportInvoicesPanel } from "./hooks/useExportInvoicesPanel";

const CardList = tw.div`flex flex-col gap-3`;

type Props = {
  onClose: () => void;
  invoiceIds: string[];
};

const ExportInvoicesPanelWithProvider: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const { invoiceIds: invoicesByIds, loading } = useInvoicesByIds();
  const { invoicesToExportCount, onSave } = useExportInvoicesPanel({
    onCancel: onClose,
  });

  return (
    <OverlayPanel
      title={intl.$t({ id: "EXPORT_INVOICES" })}
      isLoading={loading && invoicesByIds.length === 0}
      onCancel={onClose}
      onSave={onSave}
      saveLabel={intl.$t(
        {
          id:
            !invoicesToExportCount || invoicesByIds.length > 1
              ? "EXPORT_WITH_NUMBER"
              : "EXPORT",
        },
        { number: invoicesToExportCount },
      )}
      disableSave={invoicesToExportCount === 0}
    >
      <CardList>
        <InvoicesExport invoices={invoicesByIds} />
      </CardList>
    </OverlayPanel>
  );
};

export const ExportInvoicesPanel: FC<Props> = (props) => (
  <InvoicesByIdsProvider invoiceIds={props.invoiceIds}>
    <ExportInvoiceProvider>
      <ExportInvoicesPanelWithProvider {...props} />
    </ExportInvoiceProvider>
  </InvoicesByIdsProvider>
);
