import { CategoryState } from "@/common/hooks/useToggleCategory";
import { Identity } from "@/types/Identity";
import { useCallback, useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { CountType } from "../../../circle-counter/CountType";
import { CategoryType } from "../../enums/CategoryType";
import { ItemCard, ItemCardContent } from "../../GridTable.styles";
import { useGridTableCategoryVisibility } from "../../hooks/useGridTableCategoryVisibility";
import { useGridTableContext } from "../../providers/GridTableProvider";
import { GridTableConfiguration } from "../../types/GridTableConfiguration";
import { RenderType } from "../../types/RenderType";
import { GridItem } from "../grid-item/GridItem";
import { GRID_ERROR_ROW_KEY } from "../grid-table-category/GridTableCategory";
import { GridTableVirtualizationContainer } from "../grid-virtualization/GridTableVirtualizationContainer";

type Props<T extends Identity, TAdditional> = {
  item: T;
  index: number;
  className?: string;
  contentClassName?: string;
  configuration: GridTableConfiguration<T, TAdditional>;
  readonly: boolean;
  readonlyFn?: (
    additionalItem: TAdditional | undefined,
    item?: T | undefined,
  ) => boolean;
  itemFn?: (
    item: T,
    category: CategoryState<T> | undefined,
    index?: number,
    editMode?: boolean,
  ) => { error?: boolean; className: string };
  renderType?: RenderType;
  onClick?: (item: T) => void;
  hasCardItems?: boolean;
  count: CountType;
  error?: boolean;
  category?: CategoryState<T>;
  inEditModeFn?: (item: T, index?: number, editMode?: boolean) => boolean;
  hasRowError?: (item: T, index?: number) => boolean;
  expandedItemComponent?: (item: T) => JSX.Element;
  preRowItemComponent?: (item: T) => JSX.Element;
  setEditing?: (editing: boolean) => void;
  itemKey: string;
};

export const GridTableItem = <T extends Identity, TAdditional>({
  item,
  itemKey,
  index,
  configuration,
  readonly,
  readonlyFn,
  itemFn,
  className,
  contentClassName,
  onClick,
  hasCardItems = false,
  renderType = RenderType.Item,
  count,
  error = false,
  category,
  inEditModeFn,
  hasRowError,
  expandedItemComponent,
  preRowItemComponent,
  setEditing,
}: Props<T, TAdditional>) => {
  const { categoryType } = useGridTableContext(
    useShallow((state) => ({ categoryType: state.table.categoryType })),
  );
  const { visible } = useGridTableCategoryVisibility(itemKey);
  const [editMode, setEditMode] = useState(
    inEditModeFn?.(item, index) ?? false,
  );
  const itemStyle = useMemo(
    () => itemFn?.(item, category, index, editMode),
    [item, category, index, editMode, itemFn],
  );
  const setEditModeFn = useCallback(
    (editMode: boolean) => {
      setEditMode(editMode);
      setEditing?.(editMode);
    },
    [setEditing],
  );

  const skipVirtualization = useMemo(
    () => categoryType === undefined || categoryType !== CategoryType.ONE_LEVEL,
    [categoryType],
  );

  return (
    <GridTableVirtualizationContainer
      itemKey={itemKey}
      skipVirtualization={skipVirtualization}
    >
      <ItemCard
        key={index}
        className={`${className} ${itemStyle?.className || ""}`}
        onClick={() => onClick && onClick(item)}
        data-attr={itemStyle?.error ? GRID_ERROR_ROW_KEY : ""}
        $hasCardItems={hasCardItems}
        $hasCursor={!!onClick}
        data-testid={`grid-table-item-${count}`}
      >
        {preRowItemComponent && preRowItemComponent(item)}
        <ItemCardContent
          className={contentClassName}
          data-testid="grid-table-item-card"
        >
          <GridItem
            className="text-xs md:text-base"
            columns={configuration.columns}
            item={item}
            readonly={readonly}
            editMode={editMode}
            setEditMode={setEditModeFn}
            readonlyFn={readonlyFn}
            index={index}
            renderType={renderType || RenderType.Item}
            count={count}
            error={error}
            rowError={hasRowError?.(item, index) ?? false}
            visible={visible}
          />
        </ItemCardContent>
        {expandedItemComponent && expandedItemComponent(item)}
      </ItemCard>
    </GridTableVirtualizationContainer>
  );
};
