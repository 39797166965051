import { useMemo } from "react";
import { useStartupDataStore } from "../stores/useStartupDataStore";

export const useGetWarehouseById = (warehouseId: string | undefined) => {
  const { getWarehouseById } = useStartupDataStore();

  return useMemo(
    () => getWarehouseById(warehouseId),
    [warehouseId, getWarehouseById],
  );
};
