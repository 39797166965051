import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  MdFixedContainer,
  SmFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  BuyoutFieldsFragment,
  BuyoutItemFieldsFragment,
  BuyoutStatus,
  ReleaseStatus,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CategoryLike } from "../../../../../../../common/hooks/useToggleCategory";
import { BuyoutInclusionWrapper } from "../../document/components/BuyoutInclusionWrapper";
import { BuyoutItemMaterialView } from "../../document/components/BuyoutItemMaterialView";
import { BuyoutReleaseAmounts } from "../../document/components/BuyoutReleaseAmounts";
import { BuyoutReleaseAmountsCount } from "../../document/components/BuyoutReleaseAmountsCount";
import { BuyoutTagsPicker } from "../../document/components/BuyoutTagsPicker";
import { BidPriceTotalForItem } from "../../list-items/BidPriceTotalForItem";
import { BuyoutCategoryPrice } from "../../list-items/BuyoutCategoryPrice";
import { GroupBuyoutByCostCodeToggle } from "../GroupBuyoutByCostCodeToggle";
import { GroupQuotedBuyoutByCostCodeToggle } from "../GroupQuotedBuyoutByCostCodeToggle";
import { BuyoutItemUnitPrice } from "../buyout-item-unit-price/BuyoutItemUnitPrice";
import { BuyoutEmptyFooter } from "../footer/BuyoutEmptyFooter";
import { BuyoutOrderedTotalFooter } from "../footer/BuyoutOrderedTotalFooter";
import { BuyoutPaymentFooter } from "../footer/BuyoutPaymentFooter";
import { BuyoutReceivedTotalFooter } from "../footer/BuyoutReceivedTotalFooter";
import { BuyoutTitleColFooter } from "../footer/BuyoutTitleColFooter";
import { BuyoutTotalFooter } from "../footer/BuyoutTotalFooter";
import { BuyoutRfqItemContainer } from "./BuyoutListItemsConfiguration";

export const useBuyoutListNonDraftedConfiguration = ({
  buyout,
}: {
  buyout: BuyoutFieldsFragment | null;
}) => {
  const intl = useIntl();
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);
  const { hasPhaseCodes, hasAdditionalCharges } = useOrgSettings();
  const isNotDraft = useMemo(
    () => !checkBuyoutStatus(buyout, [BuyoutStatus.Draft]),
    [buyout],
  );
  const includeAdditionalCharges = useMemo(
    () =>
      (buyout?.additionalChargesAllowance &&
        Number(buyout.additionalChargesAllowance) > 0) ||
      hasAdditionalCharges,
    [buyout?.additionalChargesAllowance, hasAdditionalCharges],
  );
  const configuration: Array<GridCol<BuyoutItemFieldsFragment, CategoryLike>> =
    useMemo(
      () => [
        {
          wrapper: BuyoutRfqItemContainer,
          header: (
            <>
              <FormattedMessage id="ITEM_DESCRIPTION" />
              {buyout?.quote ? (
                <GroupQuotedBuyoutByCostCodeToggle />
              ) : (
                <GroupBuyoutByCostCodeToggle />
              )}
            </>
          ),
          item: ({ item, count }) => (
            <BuyoutItemMaterialView item={item} count={count} />
          ),
          group: (category) => (
            <ProductCategory
              type={ProductCategoryType.CostCode}
              category={category}
              items={category.items.length}
            />
          ),
          includesCounter: true,
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutPaymentFooter
                includeAdditionalCharges={includeAdditionalCharges}
              />
            </If>
          ),
        },
        {
          wrapper: MdFixedContainer,
          header: (
            <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAGS"} />
          ),
          item: ({ item }) => {
            return <BuyoutTagsPicker item={item} />;
          },
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutEmptyFooter
                Wrapper={MdFixedContainer}
                includeAdditionalCharges={includeAdditionalCharges}
              />
            </If>
          ),
        },
        {
          header: <FormattedMessage id="BUYOUT_QUANTITY" />,
          wrapper: MdFixedContainer,
          position: "center",
          item: ({ item }) => (
            <ValueUnit
              value={item.quantityDecimal}
              uom={item.projectItem.estimateUom}
              mnemonicFirst={false}
              classNames={{ footer: "truncate" }}
            />
          ),
          group: (category) => (
            <ProductCategoryCount itemsLength={category.items.length} />
          ),
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutEmptyFooter
                Wrapper={MdFixedContainer}
                includeAdditionalCharges={includeAdditionalCharges}
              />
            </If>
          ),
        },
        {
          header: <FormattedMessage id="PRICE" />,
          wrapper: SmFixedContainer,
          position: "center",
          item: ({ item }) => <BuyoutItemUnitPrice item={item} />,
          hidden: !canViewPrices,
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutTitleColFooter
                includeAdditionalCharges={includeAdditionalCharges}
                includeLabels={checkBuyoutStatus(buyout, [
                  BuyoutStatus.Draft,
                  BuyoutStatus.Requested,
                ])}
              />
            </If>
          ),
        },
        {
          header: <FormattedMessage id="TOTAL" />,
          wrapper: MdFixedContainer,
          position: "end",
          item: ({ item }) => (
            <BuyoutInclusionWrapper item={item} includeMessage>
              <BidPriceTotalForItem item={item} />
            </BuyoutInclusionWrapper>
          ),
          group: (category) => <BuyoutCategoryPrice items={category.items} />,
          hidden: !canViewPrices,
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutTotalFooter
                Wrapper={MdFixedContainer}
                includeAdditionalCharges={includeAdditionalCharges}
              />
            </If>
          ),
        },
        {
          header: <FormattedMessage id="ORDERED_SO_FAR" />,
          wrapper: MdFixedContainer,
          position: "end",
          item: ({ item }) => (
            <BuyoutReleaseAmounts
              title={intl.$t({ id: "ORDERED_SO_FAR" })}
              item={item}
              amount={item.releasedAmount}
              releaseStatuses={[
                ReleaseStatus.Requested,
                ReleaseStatus.Scheduled,
                ReleaseStatus.Received,
                ReleaseStatus.PartiallyReceived,
              ]}
            />
          ),
          group: (category) => (
            <BuyoutReleaseAmountsCount
              items={category.items}
              releaseStatuses={[
                ReleaseStatus.Requested,
                ReleaseStatus.Scheduled,
                ReleaseStatus.Received,
                ReleaseStatus.PartiallyReceived,
              ]}
            />
          ),
          hidden: checkBuyoutStatus(buyout, [
            BuyoutStatus.Draft,
            BuyoutStatus.Requested,
          ]),
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutOrderedTotalFooter
                Wrapper={MdFixedContainer}
                includeAdditionalCharges={includeAdditionalCharges}
              />
            </If>
          ),
        },
        {
          header: <FormattedMessage id="RECEIVED_SO_FAR" />,
          wrapper: MdFixedContainer,
          position: "end",
          item: ({ item }) => (
            <BuyoutReleaseAmounts
              title={intl.$t({ id: "RECEIVED_SO_FAR" })}
              item={item}
              amount={item.completedAmount}
              releaseStatuses={[ReleaseStatus.Received]}
            />
          ),
          group: (category) => (
            <BuyoutReleaseAmountsCount
              items={category.items}
              releaseStatuses={[ReleaseStatus.Received]}
            />
          ),
          hidden: checkBuyoutStatus(buyout, [
            BuyoutStatus.Draft,
            BuyoutStatus.Requested,
          ]),
          footer: (
            <If isTrue={isNotDraft}>
              <BuyoutReceivedTotalFooter
                Wrapper={MdFixedContainer}
                includeAdditionalCharges={includeAdditionalCharges}
              />
            </If>
          ),
        },
      ],
      [
        buyout,
        isNotDraft,
        hasPhaseCodes,
        canViewPrices,
        includeAdditionalCharges,
        intl,
      ],
    );

  return configuration;
};
