import { DecimalSafe } from "@/common/utils/decimalSafe";
import { extPriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { QuoteStatus } from "@/generated/graphql";

export const sumQuoteGroupFullPrices = (
  acc: number,
  item: {
    rfqItem: {
      id: string;
    };
    quoteItems: {
      unitPrice?: string | null | undefined;
      quantityDecimal?: string | null;
      status: QuoteStatus;
    }[];
  },
) => {
  const lowestPrice = new DecimalSafe(
    Math.min(
      ...item.quoteItems.map((qi) =>
        Number(new DecimalSafe(qi.unitPrice || 0)),
      ),
    ),
  );

  const bestItem = item.quoteItems.find(
    (qi) =>
      Number(new DecimalSafe(qi.unitPrice || 0)) === lowestPrice.toNumber(),
  );

  const unitPrice = new DecimalSafe(
    bestItem?.status !== QuoteStatus.Withdrawn ? bestItem?.unitPrice || 0 : 0,
  ).toString();

  return new DecimalSafe(
    extPriceCalculation(bestItem?.quantityDecimal, unitPrice),
  )
    .add(new DecimalSafe(acc))
    .toNumber();
};
