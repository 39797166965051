import { NUMERIC_DECIMAL_POINTS } from "@/common/const";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { DistributorBuyoutFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

export const total = (
  doc: jsPDF,
  buyout: DistributorBuyoutFieldsFragment,
  intl: IntlShape,
  formatCurrency: (value: number) => string,
) => {
  const additionalCharges = buyout.additionalCharges.map(
    (charge) =>
      [
        {
          content: `${charge.description}:`,
        },
        `$${intl.formatNumber(Number(charge.amount), {
          minimumFractionDigits: 0,
          maximumFractionDigits: NUMERIC_DECIMAL_POINTS,
        })}`,
      ] as RowInput,
  );
  autoTable(doc, {
    theme: "plain",
    margin: { left: 200 },
    tableWidth: 100,
    showHead: false,
    styles: {
      fontSize: 10,
    },
    columns: [{ dataKey: "label" }, { dataKey: "value" }],
    body: [
      [
        {
          content: `${intl.$t({ id: "SUBTOTAL" })}:`,
          styles: { fontStyle: "bold" },
        },
        formatCurrency(Number(buyout.netAmount || 0)),
      ],
      ...additionalCharges,
      [
        {
          content: `${intl.$t({ id: "SALES_TAX" })}:`,
          styles: { fontStyle: "bold" },
        },
        buyout.customTaxAmount !== null && buyout.customTaxAmount !== undefined
          ? `${formatCurrency(Number(buyout.customTaxAmount))}`
          : `${new DecimalSafe(buyout.taxRate || 0).mul(
              100,
            )}% (${formatCurrency(Number(buyout.taxAmount))})`,
      ],
      [
        {
          content: `${intl.$t({ id: "TOTAL" })}:`,
          styles: { fontStyle: "bold" },
        },
        formatCurrency(Number(buyout.amount || 0)),
      ],
    ],
  });
};
