import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useCurrenciesQuery } from "@/generated/graphql";
import { useMemo } from "react";

export const useCurrencies = () => {
  const { data, error, loading } = useCurrenciesQuery();
  useErrorEffect(error);

  const currencies = useMemo(() => {
    return data?.currencies || [];
  }, [data?.currencies]);

  return {
    currencies,
    loading,
  };
};
