import { readValue, setValue } from "@/common/utils/localStorage";
import { InvoiceReleaseSortOptionsEnum } from "@/contractor/pages/home/invoices/pages/invoice-verification/enums/InvoiceReleaseSortOptionsEnum";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { useReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

const INVOICE_SORT_ORDER = "invoiceSortOrder";

export const useInvoiceReleaseItemsFilterHeader = () => {
  const { invoice, setSortingOrder, sortingOrder } = useInvoiceVerification();
  const { setGroupedByCostCode, setSortFunction, groupedByCostCode } =
    useReleaseItemsZoneProvider();
  const { release } = useRelease();

  const intl = useIntl();

  const sortOptions = useMemo(() => {
    return Object.values(InvoiceReleaseSortOptionsEnum).map((value) => ({
      value,
      label: intl.$t({ id: value }),
    }));
  }, [intl]);

  const hintMap = useMemo(() => {
    const map = new Map<string, number>();
    release?.items.forEach((item) => {
      const invoiceItem = invoice?.releaseItemHints.find(
        (i) => i?.releaseItem?.id === item?.id,
      )?.invoiceItem;
      const index =
        invoice?.items.findIndex((i) => i?.id === invoiceItem?.id) ?? -1;
      map.set(item?.id ?? "", index !== -1 ? index + 1 : 0);
    });
    return map;
  }, [invoice?.items, invoice?.releaseItemHints, release?.items]);

  const sortByInvoicePosition = useCallback(
    (a: ReleaseItemFieldsFragment, b: ReleaseItemFieldsFragment) => {
      const hintA = hintMap.get(a.id);
      const hintB = hintMap.get(b.id);
      return hintA ? (hintB ? hintA - hintB : -1) : 1;
    },
    [hintMap],
  );

  const sortByItemName = useCallback(
    (a: ReleaseItemFieldsFragment, b: ReleaseItemFieldsFragment) =>
      a?.name.localeCompare(b?.name),
    [],
  );

  const onSortChange = useCallback(
    (value: string | null) => {
      setValue(INVOICE_SORT_ORDER, value);
      switch (value) {
        case InvoiceReleaseSortOptionsEnum.ZonesAndCodes:
          setGroupedByCostCode(true);
          break;
        case InvoiceReleaseSortOptionsEnum.OriginalOrder:
          setGroupedByCostCode(false);
          setSortFunction(undefined);
          break;
        case InvoiceReleaseSortOptionsEnum.InvoiceOrder:
          setGroupedByCostCode(false);
          setSortFunction(() => sortByInvoicePosition);
          break;
        case InvoiceReleaseSortOptionsEnum.ItemDescription:
          setGroupedByCostCode(false);
          setSortFunction(() => sortByItemName);
          break;
      }
      setSortingOrder(value as InvoiceReleaseSortOptionsEnum);
    },
    [
      setGroupedByCostCode,
      setSortFunction,
      setSortingOrder,
      sortByInvoicePosition,
      sortByItemName,
    ],
  );

  useEffect(() => {
    const sort = readValue(INVOICE_SORT_ORDER) as InvoiceReleaseSortOptionsEnum;
    if (sort) {
      onSortChange(sort);
    }
  }, [onSortChange]);

  useEffect(() => {
    if (
      groupedByCostCode &&
      sortingOrder !== InvoiceReleaseSortOptionsEnum.ZonesAndCodes
    ) {
      setSortingOrder(InvoiceReleaseSortOptionsEnum.ZonesAndCodes);
      setSortFunction(undefined);
    } else if (
      !groupedByCostCode &&
      sortingOrder === InvoiceReleaseSortOptionsEnum.ZonesAndCodes
    ) {
      setSortingOrder(InvoiceReleaseSortOptionsEnum.InvoiceOrder);
      setGroupedByCostCode(false);
      setSortFunction(() => sortByInvoicePosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedByCostCode]);

  return {
    sortOptions,
    onSortChange,
  };
};
