import { BreadcrumbItem } from "@/common/components/breadcrumbs/Breadcrumbs";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { OrgLocationRole } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";

export const useInvoiceBreadcrumbs = () => {
  const intl = useIntl();
  const { invoice } = useInvoiceVerification();
  const { hasPermissions: canViewInvoices } = usePermissions([
    Permission.canViewInvoices,
  ]);
  const { viewer } = useUser();
  const isForeman = useMemo(
    () =>
      viewer?.locationRoles.every(
        (s) => s.role === OrgLocationRole.LocationForeman,
      ),
    [viewer],
  );

  const breadcrumbs = useMemo(() => {
    return [
      {
        text: intl.$t({ id: "INVOICES" }),
        link: canViewInvoices && !isForeman ? routes.invoices : undefined,
        id: "invoices",
      },
      {
        text: intl.$t(
          { id: "VENDOR_WITH_INVOICE_NUMBER" },
          {
            vendor: invoice?.release?.sellerOrgLocation?.org.name,
            invoiceNumber: invoice?.number,
          },
        ),
        id: "invoices",
      },
    ] as BreadcrumbItem[];
  }, [
    canViewInvoices,
    intl,
    invoice?.number,
    invoice?.release?.sellerOrgLocation?.org.name,
    isForeman,
  ]);
  return { breadcrumbs };
};
