import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { PaymentTerm, usePaymentTerms } from "@/common/hooks/usePaymentTerms";
import { useUser } from "@/common/providers/UserProvider";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { hasProperty } from "@/common/utils/objectUtils";
import {
  BuyoutStatus,
  DistributorBuyoutFieldsFragment,
  UpdateVendorBuyoutInput,
  useAcceptBuyoutMutation,
  useDeclineBuyoutMutation,
  useDistributorBuyoutQuery,
  useUpdateVendorBuyoutMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router";

type ProviderContextType = {
  buyout: DistributorBuyoutFieldsFragment | null;
  loading: boolean;
  updateVendorBuyout: (
    input: Pick<UpdateVendorBuyoutInput, "notes" | "updates" | "assetUrls">,
  ) => Promise<boolean>;
  acceptBuyout: () => Promise<boolean>;
  declineBuyout: () => Promise<boolean>;
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
  validationError: boolean;
  setValidationError: (validationError: true) => void;
  paymentTermOptions: PaymentTerm[];
};

const ProviderContext = createContext<ProviderContextType>({
  buyout: null,
  loading: false,
  updateVendorBuyout: NoFunctionBooleanPromise,
  acceptBuyout: NoFunctionBooleanPromise,
  declineBuyout: NoFunctionBooleanPromise,
  expandedItems: [],
  setExpandedItems: NoFunction,
  validationError: false,
  setValidationError: NoFunction,
  paymentTermOptions: [],
});

let changes: UpdateVendorBuyoutInput | undefined;

export const DistributorBuyoutProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { id } = useParams();
  const { setContractorCurrency } = useUser();
  const { setError } = useGlobalError();
  const buyoutId = id || "";
  const { data, loading, error } = useDistributorBuyoutQuery({
    variables: { id: buyoutId },
    skip: !buyoutId,
  });
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [updateVendorBuyoutMutation, { loading: updating }] =
    useUpdateVendorBuyoutMutation();
  const [acceptBuyoutMutation] = useAcceptBuyoutMutation();
  const [declineBuyoutMutation] = useDeclineBuyoutMutation();
  const [validationError, setValidationError] = useState<boolean>(false);

  const { paymentTermsOptions } = usePaymentTerms();

  const buyout = useMemo(() => {
    return data?.buyout
      ? {
          ...data?.buyout,
          items:
            data.buyout.status !== BuyoutStatus.Requested
              ? data.buyout.items.filter((item) => item.isIncluded)
              : data.buyout.items,
        }
      : null;
  }, [data?.buyout]);

  useEffect(() => {
    if (buyout?.project.location.org.settings?.display?.currency) {
      setContractorCurrency(
        buyout?.project.location.org.settings?.display?.currency,
      );
    }
  }, [
    buyout?.project.location.org.settings?.display?.currency,
    setContractorCurrency,
  ]);

  const updateVendorBuyout = async (
    input: Pick<UpdateVendorBuyoutInput, "notes" | "updates" | "assetUrls">,
    version?: number,
  ) => {
    accumulateChanges(input);
    if (updating) {
      return false;
    } else {
      const data = await submitUpdate(version);
      if (changes && data) {
        await updateVendorBuyout(
          {
            ...(changes as UpdateVendorBuyoutInput),
          },
          data?.updateVendorBuyout.version,
        );
      }
    }

    return true;
  };

  const accumulateChanges = (
    input: Pick<UpdateVendorBuyoutInput, "notes" | "updates">,
  ) => {
    changes = {
      ...changes,
      ...input,
      buyoutId,
      version: data?.buyout?.version || 0,
      updates: [...(changes?.updates || []), ...(input?.updates || [])],
    };

    changes.updates = changes.updates?.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.buyoutItemId === item.buyoutItemId),
    );
  };

  const submitUpdate = async (version?: number) => {
    const allChanges = { ...changes } as UpdateVendorBuyoutInput;
    const items =
      data?.buyout?.items.map((item) => {
        const updatedItem = allChanges.updates?.find(
          (i) => i.buyoutItemId === item.id,
        );

        if (updatedItem) {
          return {
            ...item,
            ...updatedItem,
            assetUrls: hasProperty(updatedItem, "assetUrls")
              ? updatedItem.assetUrls || []
              : item.assets.map((asset) => asset.url),
            unitPrice: hasProperty(updatedItem, "unitPrice")
              ? String(updatedItem.unitPrice)
              : item.unitPrice,
            isIncluded: hasProperty(updatedItem, "isIncluded")
              ? Boolean(updatedItem.isIncluded)
              : item.isIncluded,
          };
        } else {
          return item;
        }
      }) || [];
    changes = undefined;
    try {
      if (!data?.buyout) {
        return;
      }
      const { data: updateResponse, errors } = await updateVendorBuyoutMutation(
        {
          variables: {
            input: {
              ...allChanges,
              version: version || allChanges.version,
            },
          },
          optimisticResponse: {
            updateVendorBuyout: {
              ...data?.buyout,
              id: buyoutId,
              version: allChanges.version + 1,
              amount: new DecimalSafe(
                items.reduce((acc, item) => {
                  if (item.isIncluded) {
                    return Number(
                      new DecimalSafe(item.unitPrice || "0")
                        .mul(item.quantityDecimal)
                        .add(new DecimalSafe(acc)),
                    );
                  } else {
                    return acc;
                  }
                }, 0) || 0,
              )
                .plus(data.buyout.taxAmount || 0)
                .toString(),
              items,
              notes: allChanges.notes,
            },
          },
        },
      );
      setError(errors);
      return updateResponse;
    } catch (errors) {
      setError(errors);
      return null;
    }
  };

  const acceptBuyout = async (): Promise<boolean> => {
    try {
      const { errors } = await acceptBuyoutMutation({
        variables: {
          input: { buyoutId, version: data?.buyout?.version || 0 },
        },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const declineBuyout = async (): Promise<boolean> => {
    try {
      const { errors } = await declineBuyoutMutation({
        variables: { input: { buyoutId, version: data?.buyout?.version || 0 } },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        buyout,
        paymentTermOptions: paymentTermsOptions,
        loading,
        updateVendorBuyout,
        acceptBuyout,
        declineBuyout,
        expandedItems,
        setExpandedItems,
        validationError,
        setValidationError,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDistributorBuyout = () => useContext(ProviderContext);
