import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useProjectEstimatedItemsCount } from "../../../hooks/useProjectEstimatedItemsCount";

const Row = tw.div`flex flex-row w-full gap-2 items-center`;

type Props = {
  smallPlaceholder?: boolean;
} & ControlledProps;

export const ProjectTemplateSelector: FC<Props> = ({
  name,
  smallPlaceholder = false,
}) => {
  const intl = useIntl();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const { watch } = useFormContext();
  const selectedTemplateProjectId = watch("templateProjectId");
  const { estimatedItemsCount } = useProjectEstimatedItemsCount(
    selectedTemplateProjectId,
  );

  return (
    <Row>
      <SelectControlled
        name={name}
        options={projects.map((project) => ({
          id: project.id,
          name: `${project.name}${project.id === selectedTemplateProjectId && estimatedItemsCount ? ` (${estimatedItemsCount})` : ""}`,
        }))}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        loading={loadingProjects}
        label={intl.$t({ id: "PROJECT_TEMPLATE_OPTIONAL" })}
        placeholder={intl.$t({ id: "SELECT_PROJECT" })}
        className="flex flex-1"
        disableClearable={false}
        sx={
          smallPlaceholder
            ? {
                ".MuiFormLabel-root": {
                  fontSize: 14,
                  paddingTop: "1px",
                },
              }
            : undefined
        }
      />
      <InfoTooltip message={intl.$t({ id: "PROJECT_TEMPLATE_TOOLTIP" })} />
    </Row>
  );
};
