import { useTabActive } from "@/common/hooks/useTabActive";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { IntegrationType } from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useIsValidReceiptFoundationExport } from "../../hooks/useIsValidReceiptFoundationExport";
import { useExportReceipt } from "../../providers/ExportReceiptProvider";
import { useReceiptsByIds } from "../../providers/ReceiptsByIdsProvider";

export const useExportReceiptsPanel = ({
  onExportCompleted,
}: {
  onExportCompleted: () => void;
}) => {
  const {
    receiptIds: receiptsByIds,
    loading,
    refetchReceiptsByIds,
  } = useReceiptsByIds();
  const { exportReceipts, updatedReceipts, postingDate, voucherType } =
    useExportReceipt();
  const { connectedIntegrationType, refetchSettings } = useOrgSettings();
  const { isValidReceiptFoundationExport } =
    useIsValidReceiptFoundationExport();
  const { isTabActive } = useTabActive();

  useEffect(() => {
    if (isTabActive && !loading) {
      refetchReceiptsByIds();
      refetchSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabActive]);

  const receiptsToExportCount = useMemo(
    () =>
      receiptsByIds.filter((receipt) =>
        isValidReceiptFoundationExport({
          receipt,
          updatedReceipts,
        }),
      ).length,
    [receiptsByIds, isValidReceiptFoundationExport, updatedReceipts],
  );

  const onSave = useCallback(async () => {
    if (
      await exportReceipts({
        integration: connectedIntegrationType ?? IntegrationType.Foundation,
        postingDate: postingDate ? postingDate.getTime() : undefined,
        invoices: receiptsByIds
          .filter((receipt) =>
            isValidReceiptFoundationExport({
              receipt,
              updatedReceipts,
            }),
          )
          .map((receipt) => ({
            invoiceId: receipt.id,
            includeCostCodes: false,
            voucherType,
            vendorExternalCode: updatedReceipts.find((r) => r.id === receipt.id)
              ?.externalVendorCode,
          })),
      })
    ) {
      onExportCompleted();
    }
  }, [
    exportReceipts,
    connectedIntegrationType,
    postingDate,
    receiptsByIds,
    isValidReceiptFoundationExport,
    updatedReceipts,
    voucherType,
    onExportCompleted,
  ]);

  return {
    onSave,
    receiptsToExportCount,
    loading,
    receiptsByIds,
    connectedIntegrationType,
    postingDate,
  };
};
