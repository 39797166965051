import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { readValue, removeValue, setValue } from "@/common/utils/localStorage";
import { routes } from "@/config/routes";
import { DeliverySlipPartialFieldsFragment } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { generatePath, useLocation, useNavigate } from "react-router";

type NavigationOptions = {
  navigateToDeliverySlips?: boolean;
  navigateToReceiveOrder?: boolean;
  navigateToOrder?: boolean;
  orderId?: string;
};

type ProviderContextType = {
  step: number;
  setStep: (step: number) => void;
  sequenceIds: string[];
  selectedDeliverySlips: DeliverySlipPartialFieldsFragment[];
  setSelectedDeliverySlips: (
    invoices: DeliverySlipPartialFieldsFragment[],
    save?: boolean,
  ) => void;
  sequenceActive: boolean;
  startSequence: () => void;
  stopSequence: () => void;
  navigateToNextSequence: (params?: {
    navigateToDeliverySlips: boolean;
  }) => void;
  navigateToNextSequenceOrRedirect: (
    deliverySlipId: string | null | undefined,
    options?: NavigationOptions,
  ) => void;
  redirectToDeliverySlips: () => void;
  newlyMatchedDeliverySlipIds: string[];
  setNewlyMatchedDeliverySlipIds: (ids: string[]) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  step: 0,
  setStep: NoFunction,
  sequenceIds: [],
  selectedDeliverySlips: [],
  setSelectedDeliverySlips: NoFunction,
  sequenceActive: false,
  startSequence: NoFunction,
  stopSequence: NoFunction,
  navigateToNextSequence: NoFunction,
  redirectToDeliverySlips: NoFunction,
  navigateToNextSequenceOrRedirect: NoFunction,
  newlyMatchedDeliverySlipIds: [],
  setNewlyMatchedDeliverySlipIds: NoFunction,
});

export const DeliverySlipSequenceProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newlyMatchedDeliverySlipIds, setNewlyMatchedDeliverySlipIds] =
    useState<string[]>([]);
  const [selectedDeliverySlips, setSelectedDeliverySlips] = useState<
    DeliverySlipPartialFieldsFragment[]
  >(
    readValue(
      LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SELECTED_DELIVERY_SLIPS,
      [],
    ) as DeliverySlipPartialFieldsFragment[],
  );
  const [step, setStep] = useState(
    readValue(
      LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SEQUENCE_STEP,
      0,
    ) as number,
  );
  const [sequenceActive, setSequenceActive] = useState(
    readValue(
      LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SEQUENCE_ACTIVE,
      false,
    ) as boolean,
  );

  const setSelectedDeliverySlipsAndUpdateLocalStorage = useCallback(
    (invoices: DeliverySlipPartialFieldsFragment[]) => {
      setSelectedDeliverySlips(invoices);
      setValue(
        LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SELECTED_DELIVERY_SLIPS,
        invoices,
      );
    },
    [],
  );

  const setSequenceActiveAndUpdateLocalStorage = useCallback(
    (active: boolean) => {
      setSequenceActive(active);
      setValue(
        LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SEQUENCE_ACTIVE,
        active,
      );
    },
    [],
  );

  const setStepAndUpdateLocalStorage = useCallback((step: number) => {
    setStep(step);
    setValue(LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SEQUENCE_STEP, step);
  }, []);

  const startSequence = useCallback(() => {
    setStepAndUpdateLocalStorage(0);
    setSelectedDeliverySlipsAndUpdateLocalStorage(selectedDeliverySlips);
    setSequenceActiveAndUpdateLocalStorage(true);
    setValue(LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SEQUENCE_ACTIVE, true);
    const sequenceStep = readValue(
      LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SEQUENCE_STEP,
      0,
    ) as number;
    const firstDeliverySlip = selectedDeliverySlips[sequenceStep];
    if (firstDeliverySlip) {
      setValue(
        LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.REDIRECT_ROUTE,
        location.search,
      );
      navigate(
        generatePath(
          firstDeliverySlip.release ||
            newlyMatchedDeliverySlipIds.includes(firstDeliverySlip.id)
            ? routes.deliverySlipReceiveOrder
            : routes.deliverySlipMatchOrder,
          {
            deliverySlipId: firstDeliverySlip.id,
          },
        ),
      );
    }
  }, [
    location.search,
    navigate,
    newlyMatchedDeliverySlipIds,
    selectedDeliverySlips,
    setSelectedDeliverySlipsAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
  ]);

  const stopSequence = useCallback(() => {
    setSelectedDeliverySlipsAndUpdateLocalStorage([]);
    setSequenceActiveAndUpdateLocalStorage(false);
    setStepAndUpdateLocalStorage(0);
    removeValue(LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.REDIRECT_ROUTE);
    removeValue(LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SEQUENCE_STEP);
  }, [
    setSelectedDeliverySlipsAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
  ]);

  const sequenceIds = useMemo(
    () => selectedDeliverySlips.map((i) => i.id),
    [selectedDeliverySlips],
  );

  const redirectToDeliverySlips = useCallback(() => {
    const redirectParams = readValue(
      LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.REDIRECT_ROUTE,
      "",
    ) as string | undefined;
    stopSequence();
    navigate(generatePath(`${routes.deliverySlips}${redirectParams}`));
  }, [navigate, stopSequence]);

  const navigateToNextSequence = useCallback(
    (
      { navigateToDeliverySlips, navigateToReceiveOrder }: NavigationOptions = {
        navigateToDeliverySlips: false,
        navigateToReceiveOrder: false,
      },
    ) => {
      if (sequenceActive) {
        if (sequenceIds.length <= step + 1) {
          redirectToDeliverySlips();
        } else {
          setStepAndUpdateLocalStorage(step + 1);
          navigate(
            generatePath(
              selectedDeliverySlips.find(
                (slip) => slip.id === sequenceIds[step],
              )?.release || navigateToReceiveOrder
                ? routes.deliverySlipReceiveOrder
                : routes.deliverySlipMatchOrder,
              {
                deliverySlipId: sequenceIds[step + 1],
              },
            ),
          );
        }
      } else if (navigateToDeliverySlips) {
        redirectToDeliverySlips();
      }
    },
    [
      sequenceActive,
      sequenceIds,
      step,
      redirectToDeliverySlips,
      setStepAndUpdateLocalStorage,
      navigate,
      selectedDeliverySlips,
    ],
  );

  const setSelectedDeliverySlipsAndSaveToLocalStorage = useCallback(
    (
      invoices: DeliverySlipPartialFieldsFragment[],
      saveToLocalStorage = false,
    ) => {
      setSelectedDeliverySlips(invoices);
      if (saveToLocalStorage) {
        setValue(
          LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SELECTED_DELIVERY_SLIPS,
          invoices,
        );
        if (invoices.length > 1) {
          setValue(
            LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SEQUENCE_ACTIVE,
            true,
          );
          setValue(LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SEQUENCE_STEP, 0);
        }
      }
    },
    [],
  );

  const navigateToNextSequenceOrRedirect = useCallback(
    (
      deliverySlipId: string | null | undefined,
      options?: NavigationOptions,
    ) => {
      if (selectedDeliverySlips.length > 1) {
        const index = selectedDeliverySlips.findIndex(
          (curr) => curr.id === deliverySlipId,
        );
        const isLastIndex = index === selectedDeliverySlips.length - 1;
        if (isLastIndex) {
          navigate(routes.deliverySlips);
        } else {
          if (options?.navigateToReceiveOrder) {
            navigate(
              generatePath(routes.deliverySlipReceiveOrder, {
                deliverySlipId: selectedDeliverySlips[index + 1].id,
              }),
            );
            return;
          }
          navigateToNextSequence({ navigateToDeliverySlips: true });
        }
      } else {
        if (options?.navigateToReceiveOrder) {
          navigate(
            generatePath(routes.deliverySlipReceiveOrder, {
              deliverySlipId,
            }),
          );
          return;
        }
        if (options?.navigateToOrder && options.orderId) {
          navigate(
            generatePath(routes.delivery, {
              deliveryId: options.orderId,
            }),
          );
          return;
        }
        navigateToNextSequence({ navigateToDeliverySlips: true });
      }
    },
    [navigate, navigateToNextSequence, selectedDeliverySlips],
  );

  return (
    <ProviderContext.Provider
      value={{
        step,
        setStep: setStepAndUpdateLocalStorage,
        sequenceIds,
        selectedDeliverySlips,
        setSelectedDeliverySlips: setSelectedDeliverySlipsAndSaveToLocalStorage,
        sequenceActive,
        startSequence,
        stopSequence,
        navigateToNextSequence,
        navigateToNextSequenceOrRedirect,
        redirectToDeliverySlips,
        setNewlyMatchedDeliverySlipIds,
        newlyMatchedDeliverySlipIds,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliverySlipSequence = (): ProviderContextType =>
  useContext(ProviderContext);
