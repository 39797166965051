import { useCallback, useMemo } from "react";
import { useProjectSequence } from "../../hooks/useProjectSequence";
import { useProjects } from "../../providers/ProjectsProvider";

export const useProjectAllItemsCheckbox = () => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useProjectSequence();
  const { projects } = useProjects();

  const checked = useMemo(
    () =>
      projects.length > 0 &&
      projects.every((d) => selectedEntities.includes(d)),
    [projects, selectedEntities],
  );

  const handleChange = useCallback(() => {
    const filteredItems = projects;
    setSelectedEntities(
      checked
        ? selectedEntities.filter((i) => !filteredItems.includes(i))
        : [
            ...selectedEntities.filter((i) => filteredItems.includes(i)),
            ...filteredItems,
          ],
    );
  }, [projects, checked, selectedEntities, setSelectedEntities]);

  return { handleChange, sequenceIds, checked };
};
