import { generateUUID } from "@/common/utils/uuidUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { AdditionalChargesFieldsFragment } from "@/generated/graphql";
import { useCallback, useEffect, useState } from "react";
import { AdditionalChargesProps } from "../AdditionalChargesProps";
import { AdditionalChargeIconType } from "../types/AdditionalChargeIconType";

const getDefaultCharge = () => ({
  description: "",
  amount: "0",
  id: generateUUID(),
});

export const useAdditionalCharges = ({
  additionalCharges,
  readonly,
  updateAdditionalCharges,
}: Pick<
  AdditionalChargesProps,
  "additionalCharges" | "readonly" | "updateAdditionalCharges"
>) => {
  const { hasAdditionalCharges } = useOrgSettings();

  const [charges, setCharges] = useState<AdditionalChargesFieldsFragment[]>(
    () => {
      if ((additionalCharges || []).length > 0) {
        return additionalCharges || [];
      } else if (readonly) {
        return [];
      } else {
        return [getDefaultCharge()];
      }
    },
  );

  useEffect(() => {
    if (!additionalCharges || additionalCharges.length === 0) {
      setCharges(
        readonly
          ? []
          : [
              {
                description: "",
                amount: "0",
                id: generateUUID(),
              },
            ],
      );
    } else if (additionalCharges && additionalCharges.length > 0) {
      setCharges(additionalCharges);
    }
  }, [readonly, additionalCharges]);

  const onAdd = useCallback(() => {
    if (!readonly) {
      setCharges([
        ...charges,
        {
          description: "",
          amount: "0",
          id: generateUUID(),
        },
      ]);
    }
  }, [readonly, charges]);

  const onRemove = useCallback(
    (key: number) => {
      const newCharges = [
        ...(charges || []).slice(0, key),
        ...(charges || []).slice(key + 1),
      ];

      if (!readonly) {
        setCharges(newCharges);
        updateAdditionalCharges?.(newCharges);
      }
    },
    [charges, readonly, updateAdditionalCharges],
  );

  const updateCharge = useCallback(
    (key: number, charge: AdditionalChargesFieldsFragment) => {
      const newCharges = [
        ...(charges || []).slice(0, key),
        charge,
        ...(charges || []).slice(key + 1),
      ];

      if (!readonly) {
        if (Number(charge.amount) > 0 && charge.description) {
          setCharges(newCharges);
          updateAdditionalCharges?.(newCharges);
        } else {
          const existingCharge = charges[key];
          if (
            (existingCharge.description === charge.description ||
              charge.description === "") &&
            Number(charge.amount) === 0
          ) {
            const filteredCharges = charges.filter((c) => c.id !== charge.id);
            setCharges(
              filteredCharges.length > 0
                ? filteredCharges
                : [getDefaultCharge()],
            );
            updateAdditionalCharges?.(filteredCharges);
          }
        }
      }
    },
    [charges, readonly, updateAdditionalCharges],
  );

  const handleAdditionalChargeClick = useCallback(
    (key: number) => {
      if (key === 0 && hasAdditionalCharges) {
        onAdd();
      } else {
        onRemove(key);
      }
    },
    [hasAdditionalCharges, onAdd, onRemove],
  );

  const getActionIconType = useCallback(
    (key: number) => {
      if (key === 0) {
        if (hasAdditionalCharges) {
          return AdditionalChargeIconType.Add;
        }
        return null;
      }
      return AdditionalChargeIconType.Delete;
    },
    [hasAdditionalCharges],
  );

  return {
    charges,
    updateCharge,
    handleAdditionalChargeClick,
    getActionIconType,
  };
};
