import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { SpreadSheetConfig } from "@/common/providers/ColumnMapperProvider";

import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const useProjectListItemConfiguration = () => {
  const { isNotLumpSumUomValidator } = useTableValidators();
  const intl = useIntl();
  const { uoms } = useUomOptions();

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: "material",
        columnType: COLUMN_TYPE.Material,
      },
      {
        header: intl.$t({ id: "VENDOR" }),
        columnId: "vendorName",
        columnType: COLUMN_TYPE.Vendor,
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer.name",
        columnType: COLUMN_TYPE.Manufacturer,
      },
      {
        header: intl.$t({ id: "TAG" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.Tag,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "estimateUom.pluralDescription",
        options: uoms.filter((o) => o.mnemonic).map((o) => o.pluralDescription),
        columnType: COLUMN_TYPE.UOM,
        validator: isNotLumpSumUomValidator,
      },
      {
        header: intl.$t({ id: "ESTIMATED_QUANTITY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.PositiveQuantity,
      },
      {
        header: intl.$t({ id: "ESTIMATED_UNIT_PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.PrefilledPrice,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "code.name",
        columnType: COLUMN_TYPE.CostCode,
      },
      {
        header: intl.$t({ id: "PHASE_CODE" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.PhaseCode,
      },
      {
        header: intl.$t({ id: "ZONE" }),
        columnId: "zone.name",
        columnType: COLUMN_TYPE.Zone,
      },
    ],
    [intl, isNotLumpSumUomValidator, uoms],
  );

  return { spreadsheetConfig };
};
