import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useCallback } from "react";

export const useTaxCalculation = () => {
  // get tax amount based on tax rate or custom tax amount
  const getTaxAmount = useCallback(
    (
      taxRate: string | null | undefined,
      customTaxAmount: string | null | undefined,
      netAmount: string | null | undefined | number,
      taxVariance?: string | null | undefined,
      chargesAmount?: string | null | undefined,
    ) => {
      const subtotalDecimal = new DecimalSafe(netAmount || 0).plus(
        chargesAmount || 0,
      );
      const taxAmount =
        customTaxAmount !== null && customTaxAmount !== undefined
          ? new DecimalSafe(customTaxAmount || 0)
          : subtotalDecimal.mul(taxRate || 0);

      return taxAmount.plus(taxVariance || "0").toNumber();
    },
    [],
  );

  return { getTaxAmount };
};
