import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { generateUUID } from "@/common/utils/uuidUtils";
import { routes } from "@/config/routes";
import {
  AssetContext,
  AssetType,
  ReleaseFieldsFragment,
} from "@/generated/graphql";
import { FC, ReactNode, useCallback } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";
import tw from "tailwind-styled-components";
import { useDeliverySlipVerification } from "../../../../providers/DeliverySlipVerificationProvider";

type Props = {
  release: ReleaseFieldsFragment;
  expanded?: boolean;
};

type SlipProps = {
  $active?: boolean;
};
const SlipCard = tw.div<SlipProps>`
  grid grid-flow-col py-4 px-8 gap-4 w-fit bg-white border border-gray-400 rounded-lg
  ${({ $active }) => ($active ? "bg-green-100 border-green-800" : "")}
`;
const DateAndStatus = tw.div`grid place-content-center gap-1 ml-2`;
const Label = tw.div`text-xs`;

export const DeliverySlipPanel: FC<Props> = ({ release, expanded }) => {
  const intl = useIntl();
  const { deliverySlip } = useDeliverySlipVerification();

  const getSlipFromUrl = useCallback(
    (id: string) => {
      return release.deliverySlips?.find((s) => s.id === id);
    },
    [release],
  );

  const getImageWrapper = useCallback(
    (id: string, children: ReactNode) => {
      const slip = getSlipFromUrl(id);
      return (
        <SlipCard $active={slip?.id === deliverySlip?.id}>
          <LinkLike
            to={generatePath(routes.deliverySlipReceiveOrder, {
              deliverySlipId: slip?.id,
            })}
          >
            {children}
          </LinkLike>
        </SlipCard>
      );
    },
    [deliverySlip?.id, getSlipFromUrl],
  );

  const getSlipDetails = useCallback(
    (url: string) => {
      const slip = getSlipFromUrl(url);
      return (
        <DateAndStatus>
          <FormattedMessage
            id={`DELIVERY_SLIP_STATUS_${slip?.status}`}
            tagName={Label}
          />
          <Label>
            <FormattedDate
              value={slip?.reviewedAt || slip?.createdAt}
              year="numeric"
              month="2-digit"
              day="2-digit"
            />
          </Label>
        </DateAndStatus>
      );
    },
    [getSlipFromUrl],
  );

  return (
    <AssetsCard
      headerClassName="bg-blue-100"
      title={intl.$t({ id: "DELIVERY_SLIPS_FOR_THIS_ORDER" })}
      expanded={expanded}
      includeTooltip={false}
      includeNavigation={false}
      assets={[
        ...release.deliverySlips
          .filter((deliverySlip) => !deliverySlip.archivedAt)
          .map((slip) => ({
            id: generateUUID(),
            url: "",
            type: AssetType.Image,
            context: AssetContext.DeliverySlip,
            parentId: slip.id,
            createdAt: 0,
            ...slip.asset,
          })),
      ]}
      imageDetails={getSlipDetails}
      getImageWrapper={getImageWrapper}
    />
  );
};
