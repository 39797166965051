import Decimal from "decimal.js";

export class DecimalSafe extends Decimal {
  constructor(value: Decimal.Value) {
    try {
      super(value);
    } catch {
      // If value is not correct we simply ignore it here and initialize with zero
      // eslint-disable-next-line sonarjs/super-invocation
      super(0);
    }
  }
}
