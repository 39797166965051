import { useMemo } from "react";
import { InvoiceAgaveConnectionsProps } from "../types/InvoiceAgaveConnectionsProps";

export const useInvoiceAgaveConnections = ({
  invoices,
  linking,
}: InvoiceAgaveConnectionsProps) => {
  const filteredReleases = useMemo(() => {
    if (linking.linking) {
      return invoices;
    } else if (linking.completed.length) {
      return invoices.filter(
        (invoice) => !linking.completed.includes(invoice.id),
      );
    } else {
      return invoices;
    }
  }, [linking.completed, linking.linking, invoices]);

  return { filteredReleases };
};
