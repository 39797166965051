import { AssetFieldsFragment } from "@/generated/graphql";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExpandablePanel } from "../expandable-panel/ExpandablePanel";
import { If } from "../if/If";
import { TextField } from "../textfield/TextField";
import { UploadAsset } from "../upload-asset/UploadAsset";
import { ATTACHMENTS_MIME_TYPES } from "../upload/FileUploadArea";

const TextFieldWithBackground = tw(TextField)<{ staticText?: boolean }>`
  w-full mb-3
  ${({ staticText }: { staticText?: boolean }) =>
    staticText ? "" : "bg-white"}
`;

const Content = tw.div<{ $mode?: "horizontal" | "vertical" }>`
  grid gap-2
  ${({ $mode }) => ($mode === "horizontal" ? "grid-cols-[2fr_1fr]" : "")}
`;
type Props = {
  save?: ({
    newAssets,
    notes,
  }: {
    newAssets?: AssetFieldsFragment[];
    notes?: string | undefined;
  }) => void;
  notes?: string | null | undefined;
  readonly?: boolean;
  header?: string;
};

export const InlineNotesAndTerms: FC<Props> = ({
  save,
  notes: initialNotes,
  readonly,
  header,
}) => {
  const [notes, setNotes] = useState(initialNotes ?? "");
  const intl = useIntl();
  return (
    <ExpandablePanel
      header={
        header ??
        intl.$t({
          id: !readonly ? "ADD_NOTES_AND_TERMS" : "NOTES_AND_TERMS",
        })
      }
      isOpened={true}
    >
      <Content>
        <If isTrue={!readonly || notes}>
          <TextFieldWithBackground
            testId="notesAndTerms"
            multiline
            placeholder={intl.$t({
              id: "ADD_NOTES_AND_TERMS_PLACEHOLDER",
            })}
            minRows={3}
            maxRows={10}
            value={notes}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setNotes(ev.target.value)
            }
            onBlur={() => (readonly ? undefined : save?.({ notes }))}
            staticText={readonly}
          />
        </If>
        <UploadAsset
          accept={ATTACHMENTS_MIME_TYPES}
          readonly={readonly}
          onChange={(assets) => save?.({ newAssets: assets, notes })}
        />
      </Content>
    </ExpandablePanel>
  );
};
