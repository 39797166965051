import { DiscardButton } from "@/common/components/button/DiscardButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { getFileName } from "@/common/components/upload-asset/getFileName";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { goToExternalUrl } from "@/common/utils/browserUtils";
import { DateView } from "@/common/utils/dates/DateView";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { routes } from "@/config/routes";
import { InvoiceEmailFieldsFragment } from "@/generated/graphql";
import { AttachmentRounded, Close, InfoOutlined } from "@mui/icons-material";
import { differenceInDays } from "date-fns";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { InvoiceEmailActions } from "../../../../email-invoices/components/list/InvoiceEmailActions";
import { useSplittingInvoicesWizard } from "../SplittingInvoicesWizardProvider";

const View = tw.div``;
const Col = tw.div`flex flex-col`;
const Row = tw.div`flex flex-row`;
const Header = tw(Row)`flex flex-row justify-between`;
const Title = tw.div`text-lg font-medium ml-4 flex items-center`;
const EmailSourceRow = tw(Row)`items-center px-5 py-4`;
const EmailDate = tw.div`text-gray-500 text-sm mr-3`;
const EmailAddress = tw.div`text-sm ml-3`;
const GrayBackground = tw.div`flex flex-col bg-gray-100 rounded-3xl self-stretch mx-5`;
const LeftCol = tw(Col)`w-[324px]`;
const RightCol = tw(Col)`w-1 flex-1 rounded-r-3xl bg-blue-200`;
const TitleRow = tw(Row)`h-12 items-center pl-4 pt-2`;
const EmailAttachments = tw.div`font-medium mr-3`;
const Attachment = tw(LinkLike)<{
  $withBorder: boolean;
}>`h-22 items-center flex cursor-pointer px-5 ${({ $withBorder }) =>
  $withBorder ? "border-b border-dashed border-gray-300" : ""}`;
const AttachmentIcon = tw(AttachmentRounded)`mr-2`;
const AttachmentName = tw.div`truncate text-sm font-normal`;
const InvoicesRow = tw(Row)<{
  $withBorder: boolean;
}>`h-22 items-center px-3 gap-3 overflow-x-scroll ${({ $withBorder }) =>
  $withBorder ? "border-b border-dashed border-gray-400" : ""}`;
const MatchedToRow = tw(Row)`text-xs font-normal text-blue-500`;
const ExcludedRow = tw(Row)`text-red-500 text-sm items-center gap-3 ml-2`;
const InvoiceRow = tw(Row)`items-center pl-2`;
const PdfPlaceholder = tw.div`flex items-center justify-center w-18 h-18 overflow-hidden rounded-md border-[0.5px] border-blue-800 mr-3 cursor-pointer bg-white text-blue-500 pl-1`;
const PdfPreview = tw.img`w-18 h-18 overflow-hidden rounded-md border-[0.5px] border-blue-800 mr-3 cursor-pointer`;
const InspectInvoicesView = tw.div`flex flex-1 items-center justify-center py-10`;
const InspectInvoicesCol = tw(Col)`items-center`;
const InspectInvoicesRow = tw(Row)`gap-2 text-gray-600`;
const InspectInvoicesButton = tw(PrimaryButton)`mt-2 px-4`;
const Content = tw.div`pt-6 px-8 whitespace-pre-line text-base`;

type Props = {
  invoiceEmail: InvoiceEmailFieldsFragment;
};

export const InvoiceEmailContent: FC<Props> = ({ invoiceEmail }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { closeWizard, handleInspectAttachments } =
    useSplittingInvoicesWizard();

  const daysSinceEmailReceived = useMemo(
    () => differenceInDays(new Date(), new Date(invoiceEmail.receivedAt)),
    [invoiceEmail.receivedAt],
  );

  const attachmentsSorted = useMemo(
    () =>
      [...invoiceEmail.attachments].sort((a1, a2) =>
        a1.invoiceDocuments.length > 0 && a2.invoiceDocuments.length === 0
          ? -1
          : 1,
      ),
    [invoiceEmail.attachments],
  );

  const goToInvoiceVerification = useCallback(
    (invoiceId: string) => {
      navigate(generatePath(routes.invoiceVerification, { invoiceId }));
    },
    [navigate],
  );

  return (
    <View>
      <Header>
        <Title>{invoiceEmail.subject}</Title>
        <Row>
          <InvoiceEmailActions
            invoiceEmail={invoiceEmail}
            buttons={[
              <DiscardButton
                key="close"
                onClick={closeWizard}
                className="text-black"
              />,
            ]}
          />
        </Row>
      </Header>
      <EmailSourceRow>
        <EmailDate>
          <DateView date={invoiceEmail.receivedAt} />
          {" ("}
          {daysSinceEmailReceived > 0
            ? intl.$t(
                { id: "DAYS_AGO_CONT" },
                { count: daysSinceEmailReceived },
              )
            : intl.$t({ id: "TODAY" })}
          {")"}
        </EmailDate>
        <VendorLogo
          logoImageUrl={
            invoiceEmail.orgPreferredVendor?.sellerOrgLocation.org.photoUrl
          }
          address={invoiceEmail.orgPreferredVendor?.sellerOrgLocation.address}
          contactName={getVendorContacts(
            invoiceEmail.orgPreferredVendor?.contacts,
          )}
          orgName={invoiceEmail.orgPreferredVendor?.sellerOrgLocation.org.name}
        />
        <EmailAddress>{invoiceEmail.from}</EmailAddress>
      </EmailSourceRow>
      <GrayBackground>
        <Row>
          <LeftCol>
            <TitleRow>
              <FormattedMessage id="ATTACHMENTS" tagName={EmailAttachments} />
            </TitleRow>
            {attachmentsSorted.map((attachment, key) => (
              <Attachment
                onClick={() => goToExternalUrl(attachment.asset.url, true)}
                key={key}
                $withBorder={key !== invoiceEmail.attachments.length - 1}
                to={attachment.asset.url}
              >
                <AttachmentIcon />
                <AttachmentName>
                  {getFileName(attachment.asset.url)}
                </AttachmentName>
              </Attachment>
            ))}
          </LeftCol>
          <RightCol>
            <TitleRow>
              <FormattedMessage id="MATCHES" tagName={EmailAttachments} />
            </TitleRow>
            {attachmentsSorted
              .filter((attachment) => attachment.invoiceDocuments.length > 0)
              .map((attachment, key) => (
                <InvoicesRow
                  key={key}
                  $withBorder={key !== invoiceEmail.attachments.length - 1}
                >
                  <If isTrue={!!attachment.rejectedAt}>
                    <ExcludedRow>
                      <Close />
                      <FormattedMessage id="EXCLUDED_FROM_MATCHING" />
                    </ExcludedRow>
                  </If>
                  <If isTrue={!attachment.rejectedAt}>
                    {attachment.invoiceDocuments.map((doc, docKey) => (
                      <InvoiceRow key={docKey}>
                        <If isTrue={!!doc.asset.pages?.[0].thumbnailUrl}>
                          <PdfPreview
                            onClick={() =>
                              goToInvoiceVerification(doc.invoice.id)
                            }
                            src={doc.asset.pages?.[0].thumbnailUrl}
                          />
                        </If>
                        <If isTrue={!doc.asset.pages?.[0].thumbnailUrl}>
                          <PdfPlaceholder>
                            <AttachmentIcon />
                          </PdfPlaceholder>
                        </If>
                        <Col>
                          <Row>
                            <LinkLike
                              onClick={() =>
                                goToInvoiceVerification(doc.invoice.id)
                              }
                            >
                              <FormattedMessage
                                id="INVOICE_NUMBER"
                                values={{ number: doc.invoice.number }}
                              />
                            </LinkLike>
                          </Row>
                          <If isTrue={doc.invoice.release?.sequenceNumber}>
                            <MatchedToRow>
                              <FormattedMessage id="MATCHED_TO" />
                            </MatchedToRow>
                            <Row>
                              <LinkLike
                                onClick={() =>
                                  navigate(
                                    generatePath(routes.delivery, {
                                      deliveryId: doc.invoice.release?.id,
                                    }),
                                  )
                                }
                              >
                                <FormattedMessage
                                  id="DELIVERY_WITH_NUMBER"
                                  values={{
                                    number: doc.invoice.release?.sequenceNumber,
                                  }}
                                />
                              </LinkLike>
                            </Row>
                          </If>
                          <If
                            isTrue={
                              !doc.invoice.release?.sequenceNumber &&
                              !doc.invoice.archivedAt
                            }
                          >
                            <PrimaryButton
                              className="mt-1.5 h-6 w-25 text-xs"
                              $small
                              onClick={() =>
                                navigate(
                                  generatePath(routes.invoiceVerification, {
                                    invoiceId: doc.invoice.id,
                                  }),
                                )
                              }
                            >
                              <FormattedMessage id="MATCH_TO_ORDER" />
                            </PrimaryButton>
                          </If>
                        </Col>
                      </InvoiceRow>
                    ))}
                  </If>
                </InvoicesRow>
              ))}
            <If
              isTrue={attachmentsSorted.some(
                (a) => a.invoiceDocuments.length === 0,
              )}
            >
              <InspectInvoicesView>
                <InspectInvoicesCol>
                  <InspectInvoicesRow>
                    <InfoOutlined />
                    <FormattedMessage id="EXTRACT_INVOICES_FROM_ATTACHMENTS_AND_VERIFY" />
                  </InspectInvoicesRow>
                  <View>
                    <InspectInvoicesButton
                      $small
                      onClick={handleInspectAttachments}
                    >
                      <FormattedMessage id="INSPECT_ATTACHMENTS" />
                    </InspectInvoicesButton>
                  </View>
                </InspectInvoicesCol>
              </InspectInvoicesView>
            </If>
          </RightCol>
        </Row>
      </GrayBackground>
      <Content>{invoiceEmail.content}</Content>
    </View>
  );
};
