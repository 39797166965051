import { DecimalSafe } from "@/common/utils/decimalSafe";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { ExpandedReleaseItem } from "../providers/ReleaseProvider";

export const hasUnreleasedQuantity = (
  item: ExpandedReleaseItem | ReleaseItemFieldsFragment,
) =>
  !new DecimalSafe(item.quantityDecimal || 0).equals(
    item.receivedQuantityDecimal || 0,
  );
