import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { readValue, removeValue, setValue } from "@/common/utils/localStorage";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { routes } from "@/config/routes";
import {
  AuthorizationStatus,
  ReleasePartialFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { generatePath, useLocation, useNavigate } from "react-router";
import { useReleaseNavigation } from "../hooks/useReleaseNavigation";

type ProviderContextType = {
  step: number;
  setStep: (step: number) => void;
  sequenceIds: string[];
  selectedReleases: ReleasePartialFieldsFragment[];
  setSelectedReleases: (invoices: ReleasePartialFieldsFragment[]) => void;
  sequenceActive: boolean;
  startSequence: () => void;
  stopSequence: () => void;
  navigateToNextSequence: (params?: {
    navigateToReleases: boolean;
    navigateToReleaseId?: string | undefined;
  }) => void;
  redirectToReleases: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  step: 0,
  setStep: NoFunction,
  sequenceIds: [],
  selectedReleases: [],
  setSelectedReleases: NoFunction,
  sequenceActive: false,
  startSequence: NoFunction,
  stopSequence: NoFunction,
  navigateToNextSequence: NoFunction,
  redirectToReleases: NoFunction,
});

export const ReleaseSequenceProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedReleases, setSelectedReleases] = useState<
    ReleasePartialFieldsFragment[]
  >(
    readValue(
      LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SELECTED_RELEASES,
      [],
    ) as ReleasePartialFieldsFragment[],
  );
  const [step, setStep] = useState(
    readValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_STEP, 0) as number,
  );
  const [sequenceActive, setSequenceActive] = useState(
    readValue(
      LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_ACTIVE,
      false,
    ) as boolean,
  );
  const { getReleaseEditPath } = useReleaseNavigation();

  const setSelectedReleasesAndUpdateLocalStorage = useCallback(
    (releases: ReleasePartialFieldsFragment[]) => {
      setSelectedReleases(releases);
      setValue(
        LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SELECTED_RELEASES,
        releases,
      );
    },
    [],
  );

  const setSequenceActiveAndUpdateLocalStorage = useCallback(
    (active: boolean) => {
      setSequenceActive(active);
      setValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_ACTIVE, active);
    },
    [],
  );

  const setStepAndUpdateLocalStorage = useCallback((step: number) => {
    setStep(step);
    setValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_STEP, step);
  }, []);

  const redirectToRelease = useCallback(
    (sequenceStep: number) => {
      const firstRelease = selectedReleases[sequenceStep];
      if (firstRelease) {
        setValue(
          LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.REDIRECT_ROUTE,
          location.search,
        );
        let path = routes.delivery;
        if (
          checkReleaseStatus(firstRelease, [
            ReleaseStatus.Draft,
            ReleaseStatus.AwaitingApproval,
            ReleaseStatus.Rejected,
            ReleaseStatus.Reserved,
          ]) &&
          firstRelease.permissions.submit === AuthorizationStatus.Authorized
        ) {
          path = getReleaseEditPath(firstRelease);
        }
        navigate(
          generatePath(path, {
            deliveryId: firstRelease.id,
            noteDocumentId: firstRelease.noteDocument?.id,
            quoteDocumentId: firstRelease.quoteDocument?.id,
          }),
        );
      }
    },
    [getReleaseEditPath, location.search, navigate, selectedReleases],
  );

  const startSequence = useCallback(() => {
    setStepAndUpdateLocalStorage(0);
    setSelectedReleasesAndUpdateLocalStorage(selectedReleases);
    setSequenceActiveAndUpdateLocalStorage(true);
    setValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_ACTIVE, true);
    const sequenceStep = readValue(
      LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_STEP,
      0,
    ) as number;
    redirectToRelease(sequenceStep);
  }, [
    redirectToRelease,
    selectedReleases,
    setSelectedReleasesAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
  ]);

  const stopSequence = useCallback(() => {
    setSelectedReleasesAndUpdateLocalStorage([]);
    setSequenceActiveAndUpdateLocalStorage(false);
    setStepAndUpdateLocalStorage(0);
    removeValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.REDIRECT_ROUTE);
    removeValue(LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.SEQUENCE_STEP);
  }, [
    setSelectedReleasesAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
  ]);

  const sequenceIds = useMemo(
    () => selectedReleases.map((i) => i.id),
    [selectedReleases],
  );

  const redirectToReleases = useCallback(() => {
    const redirectParams = readValue(
      LOCAL_STORAGE_KEYS.RELEASE_SEQUENCES.REDIRECT_ROUTE,
      "",
    ) as string | undefined;
    navigate(generatePath(`${routes.deliveries}${redirectParams}`));
  }, [navigate]);

  const navigateToNextSequence = useCallback(
    (
      {
        navigateToReleases,
        navigateToReleaseId,
      }: {
        navigateToReleases?: boolean;
        navigateToReleaseId?: string;
      } = {
        navigateToReleases: false,
        navigateToReleaseId: undefined,
      },
    ) => {
      if (sequenceActive) {
        if (sequenceIds.length === step + 1) {
          redirectToReleases();
        } else {
          setStepAndUpdateLocalStorage(step + 1);
          redirectToRelease(step + 1);
        }
      } else if (navigateToReleases) {
        redirectToReleases();
      } else if (navigateToReleaseId) {
        navigate(
          generatePath(routes.delivery, { deliveryId: navigateToReleaseId }),
        );
      }
    },
    [
      sequenceActive,
      sequenceIds.length,
      step,
      redirectToReleases,
      setStepAndUpdateLocalStorage,
      redirectToRelease,
      navigate,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        step,
        setStep: setStepAndUpdateLocalStorage,
        sequenceIds,
        selectedReleases,
        setSelectedReleases,
        sequenceActive,
        startSequence,
        stopSequence,
        navigateToNextSequence,
        redirectToReleases,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseSequence = (): ProviderContextType =>
  useContext(ProviderContext);
