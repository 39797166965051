import { If } from "@/common/components/if/If";
import { FC } from "react";
import { Container, LinkLikeStyled } from "./AdditionalCharges.styles";
import { AdditionalChargesProps } from "./AdditionalChargesProps";
import { AdditionalChargeActionIcon } from "./components/additional-charge-action-icon/AdditionalChargeActionIcon";
import { AdditionalCharge } from "./components/additional-charge/AdditionalCharge";
import { useAdditionalCharges } from "./hooks/useAdditionalCharges";

export const AdditionalCharges: FC<AdditionalChargesProps> = ({
  additionalCharges = [],
  readonly,
  showError = false,
  updateAdditionalCharges,
  classes,
}) => {
  const {
    charges,
    getActionIconType,
    handleAdditionalChargeClick,
    updateCharge,
  } = useAdditionalCharges({
    readonly,
    additionalCharges,
    updateAdditionalCharges,
  });

  if (charges.length === 0) {
    return null;
  }

  return (
    <Container $readonly={readonly} className={classes?.container}>
      {charges?.map((additionalCharge, key) => (
        <AdditionalCharge
          key={key}
          index={key}
          charge={additionalCharge}
          readonly={readonly}
          showError={showError}
          onChange={(charge) => updateCharge(key, charge)}
        >
          <If isTrue={!readonly}>
            <LinkLikeStyled onClick={() => handleAdditionalChargeClick(key)}>
              <AdditionalChargeActionIcon type={getActionIconType(key)} />
            </LinkLikeStyled>
          </If>
        </AdditionalCharge>
      ))}
    </Container>
  );
};
