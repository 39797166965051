import { useDialog } from "@/common/components/dialog/DialogProvider";
import {
  ExportCompleted,
  ExportType,
} from "@/common/components/export-completed/ExportCompleted";
import { OverlayPanelProps } from "@/common/components/panel/types/OverlayPanelProps";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useTabActive } from "@/common/hooks/useTabActive";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { IntegrationType, SourceSystem } from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { useIsValidInvoiceFoundationExport } from "../../../../hooks/useIsValidInvoiceFoundationExport";
import { useExportInvoice } from "../../../../providers/ExportInvoiceProvider";
import { useInvoicesByIds } from "../../../../providers/InvoicesByIdsProvider";

export const useExportInvoicesPanel = ({
  onCancel,
}: Pick<OverlayPanelProps, "onCancel">) => {
  const {
    invoiceIds: invoicesByIds,
    loading,
    refetchInvoicesByIds,
  } = useInvoicesByIds();
  const { refetchSettings } = useOrgSettings();
  const { isTabActive } = useTabActive();
  const { exportInvoices, updatedInvoices } = useExportInvoice();
  const { connectedIntegrationType, connectedSourceSystem, settings } =
    useOrgSettings();
  const { openDialog } = useDialog();

  const { isValidInvoiceFoundationExport } =
    useIsValidInvoiceFoundationExport();

  useEffect(() => {
    if (isTabActive && !loading) {
      refetchInvoicesByIds();

      refetchSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabActive]);

  const invoicesToExportCount = useMemo(
    () =>
      invoicesByIds.filter((invoice) => {
        if (
          connectedIntegrationType === IntegrationType.Foundation ||
          connectedSourceSystem === SourceSystem.Foundation
        ) {
          return isValidInvoiceFoundationExport({
            invoice,
            updatedInvoices,
          });
        } else {
          return true;
        }
      }).length,
    [
      connectedIntegrationType,
      connectedSourceSystem,
      invoicesByIds,
      isValidInvoiceFoundationExport,
      updatedInvoices,
    ],
  );

  const onSave = useCallback(async () => {
    if (
      await exportInvoices({
        integration:
          !connectedIntegrationType ||
          connectedSourceSystem === SourceSystem.Foundation
            ? IntegrationType.Foundation
            : connectedIntegrationType,
        invoices: invoicesByIds
          .filter((invoice) =>
            isValidInvoiceFoundationExport({
              invoice,
              updatedInvoices,
            }),
          )
          .map((invoice) => ({
            invoiceId: invoice.id,
            includeCostCodes: false,
            GLExpense:
              connectedIntegrationType === IntegrationType.Foundation
                ? settings?.integrations.accounting.find((a) => a.enabled)
                    ?.toJobLedgerAccount
                : undefined,
          })),
      })
    ) {
      openDialog({
        content: (
          <ExportCompleted
            count={invoicesToExportCount}
            type={ExportType.INVOICE}
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        hideTitle: true,
      });
      onCancel?.();
    }
  }, [
    exportInvoices,
    connectedIntegrationType,
    connectedSourceSystem,
    invoicesByIds,
    isValidInvoiceFoundationExport,
    updatedInvoices,
    settings?.integrations.accounting,
    openDialog,
    invoicesToExportCount,
    onCancel,
  ]);

  return {
    invoicesToExportCount,
    onSave,
  };
};
