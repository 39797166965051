import { useDialog } from "@/common/components/dialog/DialogProvider";
import { SystemAlertType } from "@/common/components/system-alert/SystemAlert";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { goToExternalUrl } from "@/common/utils/browserUtils";
import { useReceiptSequence } from "@/contractor/pages/home/receipts/pages/receipts/providers/ReceiptsSequenceProvider";
import {
  DownloadInvoiceDocumentInput,
  InvoicesDocument,
  InvoiceType,
  ReceiptsDocument,
  useDownloadInvoiceDocumentsMutation,
} from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { InvoicesDownloadDialog } from "../../scanned-invoices/components/side-actions/invoices-download-dialog/InvoicesDownloadDialog";
import { useInvoiceSequence } from "../../scanned-invoices/providers/InvoiceSequenceProvider";
import { useInvoices } from "../../scanned-invoices/providers/InvoicesProvider";

export const useInvoicesDownload = (type: InvoiceType) => {
  const { setError } = useGlobalError();
  const { paginationArgs, page, filter } = useInvoices();
  const [downloadInvoiceDocuments, { loading }] =
    useDownloadInvoiceDocumentsMutation();
  const { selectedInvoices } = useInvoiceSequence();
  const { selectedReceipts } = useReceiptSequence();
  const { openDialog, cancelDialog } = useDialog();
  const intl = useIntl();
  const { setSystemAlert } = useSnackbar();

  const downloadInvoices = useCallback(
    async (invoices: DownloadInvoiceDocumentInput[]) => {
      try {
        if (!invoices.length) {
          return;
        }

        const { data, errors } = await downloadInvoiceDocuments({
          variables: {
            input: {
              isReceipt: type === InvoiceType.Receipt,
              invoices,
            },
          },
          refetchQueries: [
            {
              query:
                type === InvoiceType.Invoice
                  ? InvoicesDocument
                  : ReceiptsDocument,
              variables: {
                ...paginationArgs,
                page,
                filter,
              },
            },
          ],
        });

        if (errors) {
          setError(errors);
        }

        return data?.downloadInvoiceDocuments;
      } catch (error) {
        setError(error);
        return;
      }
    },
    [downloadInvoiceDocuments, type, paginationArgs, page, filter, setError],
  );

  const downloadItems = useCallback(async () => {
    let cancelled = false;
    const selectedItems =
      type === InvoiceType.Invoice ? selectedInvoices : selectedReceipts;
    if (selectedItems.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        content: (
          <InvoicesDownloadDialog
            type={type}
            itemsCount={selectedItems.length}
          />
        ),
        handleCancel: () => {
          cancelled = true;
          cancelDialog?.();
        },
      });
      const invoicesDownloadLink = await downloadInvoices(
        selectedItems.map((item) => ({
          invoiceId: item.id,
          version: item.version,
        })),
      );
      if (!cancelled && invoicesDownloadLink) {
        goToExternalUrl(invoicesDownloadLink);
        setSystemAlert(
          intl.$t(
            {
              id:
                type === InvoiceType.Invoice
                  ? "INVOICES_DOWNLOAD_SUCCESS"
                  : "RECEIPTS_DOWNLOAD_SUCCESS",
            },
            { count: selectedItems.length },
          ),
          { type: SystemAlertType.MANUAL },
        );
      }
      cancelDialog?.();
    }
  }, [
    type,
    selectedInvoices,
    selectedReceipts,
    openDialog,
    intl,
    downloadInvoices,
    cancelDialog,
    setSystemAlert,
  ]);

  return {
    downloadItems,
    loading,
  };
};
