import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { ChevronLeft, Close } from "@mui/icons-material";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { LoadingButton } from "../button/LoadingButton";
import { If } from "../if/If";
import { Loader } from "../loader/Loader";
import { OverlayPanelTabs } from "./OverlayPanelTabs";
import { OverlayPanelProps } from "./types/OverlayPanelProps";

const OverlayPanelContainer = tw.div`
  w-screen lg:w-[500px] flex flex-col flex-1 h-full
`;

const TitleHeader = tw.div`
  flex py-7 pl-7 bg-gradient-to-r from-blue-400 to-blue-800 text-white text-2xl font-bold
`;

const ButtonsContainer = tw.div`
  grid grid-flow-col gap-2 p-5 border-t border-gray-200
`;

const ChevronLeftIcon = tw(ChevronLeft)`
  text-3xl mr-4 cursor-pointer
`;

type DataContainerProps = {
  $noPadding: boolean;
  $flexDirectionRow: boolean;
};

const DataContainer = tw.div`
  h-full lg:max-w-[500px] flex overflow-auto mb-5
  ${(props: DataContainerProps) =>
    props.$flexDirectionRow ? "flex-row" : "flex-col"}
  ${(props: DataContainerProps) => (props.$noPadding ? "p-0" : "pt-6 px-7")}
`;

const CloseContainer = tw.div`
  mr-8 cursor-pointer
`;

const Title = tw.div`
  flex-1
`;

export const OverlayPanel: FC<OverlayPanelProps> = ({
  title,
  children,
  onSave,
  onCancel,
  saveLabel,
  cancelLabel,
  className = "",
  bottomContainerClassName = "",
  disableSave = false,
  noPadding = false,
  flexDirectionRow = false,
  saving = false,
  hasBackArrow = false,
  isLoading = false,
  hideCloseButton = false,
  onClick,
  onBack,
  tabs,
  activeTab,
  onTabChange,
}) => {
  const intl = useIntl();

  return (
    <OverlayPanelContainer
      onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
        onClick?.();
        ev.stopPropagation();
      }}
    >
      <TitleHeader>
        <Title>
          <If isTrue={hasBackArrow}>
            <ChevronLeftIcon
              onClick={() => (onBack ? onBack() : onCancel?.())}
            />
          </If>
          {title}
        </Title>
        <If isTrue={!hideCloseButton}>
          <CloseContainer
            onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
              ev.stopPropagation();
              onCancel?.();
            }}
          >
            <Close />
          </CloseContainer>
        </If>
      </TitleHeader>
      {tabs ? (
        <OverlayPanelTabs
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={onTabChange}
        />
      ) : null}
      <DataContainer
        className={className}
        data-testid="overlay-panel-content"
        $noPadding={noPadding}
        $flexDirectionRow={flexDirectionRow}
      >
        <Loader loading={isLoading}>{children}</Loader>
      </DataContainer>
      <ButtonsContainer
        data-testid="overlay-panel-buttons"
        className={bottomContainerClassName}
      >
        <If isTrue={onCancel}>
          <OutlinedButton onClick={onCancel} tabIndex={1}>
            {cancelLabel || intl.$t({ id: "CLOSE" })}
          </OutlinedButton>
        </If>
        <If isTrue={onSave}>
          <LoadingButton
            button={PrimaryButton}
            onClick={onSave}
            disabled={disableSave}
            loading={saving}
            tabIndex={1}
            testId="panel-save-button"
          >
            {saveLabel || intl.$t({ id: "SAVE" })}
          </LoadingButton>
        </If>
      </ButtonsContainer>
    </OverlayPanelContainer>
  );
};
