import { InputAdornmentProps } from "@mui/material";
import { DatePicker as DatePickerMaterial } from "@mui/x-date-pickers";
import { FC, ReactNode, Ref, forwardRef } from "react";
import { TextField, TextFieldProps } from "../../textfield/TextField";
import { getUTCDate } from "./utils/getUTCDate";
import { localToUTC } from "./utils/localToUTC";

export type DatePickerProps = {
  date: Date | null;
  onChange?: (date: Date | null) => void;
  onBlur?: () => void;
  label?: string | ReactNode;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  required?: boolean;
  staticText?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  error?: boolean;
  defaultValue?: Date;
  disabled?: boolean;
  textFieldComponent?: FC<TextFieldProps>;
  customInputAdornment?: FC<InputAdornmentProps>;
  clearable?: boolean;
  views?: ("year" | "month" | "day")[];
  utc?: boolean;
  readonly?: boolean;
};

const DatePickerTextField: FC<TextFieldProps> = ({
  className,
  required,
  staticText,
  onBlur,
  error,
  ...props
}) => {
  return (
    <TextField
      className={className}
      {...props}
      size="small"
      required={required}
      staticText={staticText}
      onBlur={onBlur}
      error={error}
      sx={{
        " .MuiInputBase-input": {
          paddingTop: "8.5px",
          paddingBottom: "8.5px",
          lineHeight: "1em",
        },
        " .MuiIconButton-root": {
          color: "#0070C0",
        },
        " .MuiIconButton-root:hover": {
          color: "#23B1ED",
          border: 0,
          backgroundColor: "transparent",
        },
      }}
    />
  );
};

export const DatePicker: FC<DatePickerProps> = forwardRef(
  (
    {
      date,
      onChange,
      label,
      minDate,
      maxDate,
      className,
      required,
      staticText,
      open,
      onBlur,
      setOpen,
      error,
      disabled,
      textFieldComponent,
      customInputAdornment,
      clearable,
      views,
      utc,
      readonly = false,
    },
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <DatePickerMaterial
        readOnly={readonly}
        inputRef={ref}
        className={className}
        open={open}
        onOpen={() => setOpen?.(true)}
        onClose={() => setOpen?.(false)}
        value={date && utc ? getUTCDate(date) : date}
        label={label}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(newDate) =>
          onChange?.(newDate && utc ? localToUTC(newDate) : newDate)
        }
        disableOpenPicker={staticText}
        slots={{
          textField: textFieldComponent ?? DatePickerTextField,
          inputAdornment: customInputAdornment ?? undefined,
        }}
        views={views}
        format={!views ? "MM/‎dd‎/yyyy" : undefined}
        slotProps={{
          actionBar: {
            actions: clearable ? ["clear"] : [],
          },
          textField: {
            required,
            onBlur,
            error,
            staticText,
          } as TextFieldProps,
        }}
      />
    );
  },
);
