import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useArchiveInvoiceEmailsMutation } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useInvoiceEmails } from "../../hooks/useInvoiceEmails";
import { useInvoiceEmailsSequence } from "../../hooks/useInvoiceEmailsSequence";

export const useInvoiceEmailsSideAction = () => {
  const { selectedEntities, setSelectedEntities } = useInvoiceEmailsSequence();
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { refetch, filter } = useInvoiceEmails();
  const [archiveInvoiceEmailsMutation] = useArchiveInvoiceEmailsMutation();
  const { setError } = useGlobalError();

  const deleteItems = useCallback(() => {
    const removableItems = selectedEntities.filter(
      (entity) => !entity.archivedAt,
    );
    if (removableItems.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        includeWarningIcon: true,
        title: intl.$t({ id: "DELETE_CONFIRMATION" }),
        text: intl.$t(
          { id: "DELETE_EMAILS_MESSAGE" },
          { number: removableItems.length },
        ),
        handleConfirm: async () => {
          try {
            const { errors } = await archiveInvoiceEmailsMutation({
              variables: {
                input: { ids: removableItems.map((item) => item.id) },
              },
            });
            setError(errors);
            if (!errors) {
              setSelectedEntities([]);
              refetch();
            }
          } catch (e) {
            setError(e);
          }
        },
      });
    }
  }, [
    selectedEntities,
    openDialog,
    intl,
    archiveInvoiceEmailsMutation,
    setError,
    setSelectedEntities,
    refetch,
  ]);

  const shouldShowDeleteAction = useCallback(() => {
    return (
      selectedEntities.some((entity) => !entity.archivedAt) &&
      filter?.archived !== true
    );
  }, [filter?.archived, selectedEntities]);

  return {
    deleteItems,
    shouldShowDeleteAction,
  };
};
