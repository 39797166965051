import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useUser } from "@/common/providers/UserProvider";
import {
  AuthorizationStatus,
  PoNumberingMode,
  ReleaseStatus,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useRelease } from "../providers/ReleaseProvider";

export enum ReleaseBreadcrumbsActions {
  ReleaseStatusChipWithUpdates = "ReleaseStatusChipWithUpdates",
  ReleaseStatusChip = "ReleaseStatusChip",
  ReleaseDeletedNote = "ReleaseDeletedNote",
}

export const RELEASE_STATUS_TRANSITIONS = {
  [ReleaseStatus.Draft]: [ReleaseStatus.Draft, ReleaseStatus.Reserved],
  [ReleaseStatus.Requested]: [
    ReleaseStatus.Requested,
    ReleaseStatus.Scheduled,
    ReleaseStatus.PartiallyReceived,
    ReleaseStatus.Received,
  ],
  [ReleaseStatus.Scheduled]: [
    ReleaseStatus.Scheduled,
    ReleaseStatus.PartiallyReceived,
    ReleaseStatus.Received,
  ],
  [ReleaseStatus.AwaitingApproval]: [],
  [ReleaseStatus.Canceled]: [],
  [ReleaseStatus.PartiallyReceived]: [
    ReleaseStatus.PartiallyReceived,
    ReleaseStatus.Scheduled,
  ],
  [ReleaseStatus.Received]: [ReleaseStatus.Received, ReleaseStatus.Scheduled],
  [ReleaseStatus.Rejected]: [],
  [ReleaseStatus.Reserved]: [
    ReleaseStatus.Reserved,
    ReleaseStatus.PartiallyReceived,
    ReleaseStatus.Received,
  ],
};

export const useReleaseBreadcrumbsAdditionalItems = () => {
  const { release } = useRelease();
  const { locations } = useUserLocations();
  const { poNumbering } = useUser();

  const releaseLocation = useMemo(
    () =>
      locations.find((location) => location.id === release?.orgLocation?.id),
    [locations, release?.orgLocation?.id],
  );

  const getAdditionalActions = useCallback(() => {
    const items = [];
    if (
      release?.status &&
      RELEASE_STATUS_TRANSITIONS[release.status].length &&
      (release.status !== ReleaseStatus.Draft ||
        (release.permissions.submitDirectly ===
          AuthorizationStatus.Authorized &&
          releaseLocation?.permissions.submitReleaseDirectly ===
            AuthorizationStatus.Authorized &&
          (poNumbering === PoNumberingMode.Never || release.poNumber)))
    ) {
      items.push(ReleaseBreadcrumbsActions.ReleaseStatusChipWithUpdates);
    } else if (release) {
      items.push(ReleaseBreadcrumbsActions.ReleaseStatusChip);
    }
    if (release?.deletedAt) {
      items.push(ReleaseBreadcrumbsActions.ReleaseDeletedNote);
    }
    return items;
  }, [
    poNumbering,
    release,
    releaseLocation?.permissions.submitReleaseDirectly,
  ]);

  return getAdditionalActions;
};
