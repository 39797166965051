import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { TextField } from "../../../textfield/TextField";
import { TextFieldContainer } from "../../AdditionalCharges.styles";
import { useAdditionalCharge } from "./hooks/useAdditionalCharge";
import { AdditionalChargeProps } from "./types/AdditionalChargeProps";

const Container = tw.div<{
  $readonly: boolean;
}>`
  grid gap-4 text-right items-center text-sm md:text-base font-medium justify-end
  ${({ $readonly }: { $readonly: boolean }) =>
    $readonly ? "grid-cols-[auto_164px]" : "grid-cols-[auto_auto_24px]"} 
`;
const Item = tw.div`flex items-center justify-end`;

export const AdditionalCharge: FC<AdditionalChargeProps> = ({
  charge,
  readonly,
  index,
  showError = false,
  children,
  onChange,
}) => {
  const intl = useIntl();
  const { additionalCharge, updateDescription, updateAmount, classes } =
    useAdditionalCharge({
      charge,
      onChange,
      readonly,
    });

  return (
    <Container key={index} $readonly={readonly}>
      <Item data-testid="order-additionalChargeDescription">
        <Tooltip
          id={`${additionalCharge.id}-key`}
          element={
            <TextField
              value={additionalCharge.description}
              staticText={readonly}
              onChange={(event: React.FocusEvent<HTMLInputElement>) =>
                updateDescription(event, false)
              }
              onBlur={updateDescription}
              slotProps={{
                htmlInput: {
                  className: classes.label,
                },
              }}
              placeholder={intl.$t({ id: "ADDITIONAL_CHARGE" })}
              error={!additionalCharge.description && showError}
              xs
            />
          }
        >
          {!readonly && <FormattedMessage id="ADDITIONAL_CHARGE_INFO" />}
        </Tooltip>
      </Item>
      <TextFieldContainer data-testid="order-additionalChargeValue">
        <PricePicker
          value={additionalCharge.amount}
          readonly={readonly}
          onBlur={updateAmount}
          decimals={2}
          className={classes.input}
          error={!Number(additionalCharge.amount) && showError}
          hideErrorIcon
          index={-1}
        />
      </TextFieldContainer>
      {children}
    </Container>
  );
};
