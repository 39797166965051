import { DecimalSafe } from "@/common/utils/decimalSafe";
import { monetarySplit } from "@/common/utils/monetarySplit";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { useCallback } from "react";
import { usePriceCalculation } from "../../../hooks/usePriceCalculation";
import { ZoneCategory } from "../../../providers/ReleaseItemsZonesProvider";
import { getReleaseAdditionalCharges } from "../../../store/releaseStoreUtils";

export const useCategoryWeights = () => {
  const { calcExtPrice } = usePriceCalculation();

  // returns the weights for each cost code category
  // the weight consists of the sales tax distributed along taxable
  // cost codes and additional charge
  const getWeights = useCallback(
    (
      release: ReleaseFieldsFragment | null | undefined,
      zoneCategories: ZoneCategory[],
    ) => {
      const chargesAmount = getReleaseAdditionalCharges(
        release?.additionalCharges ?? [],
      );

      let nonTaxableCostCodeTotal = new DecimalSafe(0);
      let nonTaxableItemsTotal = new DecimalSafe(0);
      const costCodeTotals: Decimal[] = [];

      const weights = zoneCategories.flatMap((zoneCategory) =>
        zoneCategory.items.map((costCodeCategory) => {
          const costCodeSubtotal = costCodeCategory.items.reduce(
            (agg, item) => {
              if (!item.isIncluded) {
                return agg;
              }
              const extPrice = new DecimalSafe(
                calcExtPrice(item.quantityDecimal, item.unitPrice) || "0",
              );
              if (item.taxable) {
                return agg.add(extPrice);
              }
              if (costCodeCategory.items.some((item) => item.taxable)) {
                nonTaxableItemsTotal = nonTaxableItemsTotal.add(extPrice);
              } else {
                nonTaxableCostCodeTotal = nonTaxableCostCodeTotal.add(extPrice);
              }

              return agg;
            },
            new DecimalSafe(0),
          );
          costCodeTotals.push(costCodeSubtotal);
          return costCodeSubtotal;
        }),
      );

      const total = new DecimalSafe(release?.total || 0)
        .minus(nonTaxableCostCodeTotal)
        .minus(nonTaxableItemsTotal);

      //if items are filtered we add all the filtered items' amounts as an additional item to maintain the balance
      const itemsSummaryWeights = weights.reduce(
        (agg, item) => agg.add(item),
        new DecimalSafe(0),
      );
      weights.push(
        new DecimalSafe(release?.taxableNetAmount || 0).minus(
          itemsSummaryWeights,
        ),
      );

      weights.push(chargesAmount);

      const split = monetarySplit(total, weights);

      const result = split.map((item, index) => {
        if (index < split.length - 2) {
          return item.minus(costCodeTotals[index]);
        }
        return item;
      });

      return result;
    },
    [calcExtPrice],
  );

  return {
    getWeights,
  };
};
