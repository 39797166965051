import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { If } from "@/common/components/if/If";
import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { Card } from "@/common/layout/ResponsiveClasses";
import { useUser } from "@/common/providers/UserProvider";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { FormControl } from "@mui/material";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { FaCalculator } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useProjectBudget } from "../../../hooks/useProjectBudget";
import { useBudgetByCostCodes } from "../useBudgetByCostCodes";
import { AutoSyncSwitch } from "./AutoSyncSwitch";

const Container = tw(Card)`
  grid grid-flow-col mb-3 BudgetHeaderFormValues justify-between items-center border mt-3 shadow-md
  gap-2 w-full bg-blue-100 py-3 pl-4 pr-3 rounded-md border-blue-200
`;
const SwitchContainer = tw.div<{ $disabled?: boolean }>`
  grid grid-flow-col text-base gap-2 items-center
  ${({ $disabled }) => ($disabled ? "text-gray-500" : "")}
`;
const Divider = tw.div`border-l border-dashed h-full border-gray-500 mx-2`;
const LeftContainer = tw.div`grid grid-flow-col gap-2`;
const RightContainer = tw.div`grid grid-flow-col gap-2`;
const IconButtonBorderlessStyled = tw(IconButtonBorderless)`
  -mr-2
`;

export const BudgetHeader: FC = () => {
  const intl = useIntl();
  const { currencySymbol } = useUser();
  const { calculateBudget } = useBudgetByCostCodes();
  const { budgetLink } = useProjectBudget();
  const { zones } = useProjectStore(
    useShallow((state) => ({
      zones: state.zones,
    })),
  );

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "PROJECT_BUDGET" })}</title>
      </Helmet>
      <Container>
        <LeftContainer>
          <FormControl fullWidth>
            <NumericalInputControlled
              name="allowance"
              label={intl.$t({ id: "OVERALL_BUDGET" })}
              className="bg-white"
              prefix={currencySymbol}
              slotProps={{
                input: {
                  endAdornment: (
                    <Tooltip
                      element={
                        <IconButtonBorderlessStyled onClick={calculateBudget}>
                          <FaCalculator />
                        </IconButtonBorderlessStyled>
                      }
                    >
                      <FormattedMessage id="CALCULATE_BUDGET" />
                    </Tooltip>
                  ),
                },
              }}
            />
          </FormControl>
          <If isTrue={zones?.length > 0}>
            <Divider />
            <SwitchContainer $disabled={budgetLink?.autoSync}>
              <FormattedMessage id="ZONE_SPECIFIC_BUDGET" />
              <SwitchControlled
                name="zoneSpecificBudget"
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
                disabled={budgetLink?.autoSync}
              />
            </SwitchContainer>
          </If>
          <Divider />
          <SwitchContainer>
            <FormattedMessage id="LIMIT_PROJECT_TO_SELECTED_COST_CODES" />
            <SwitchControlled
              name="restrictCostCodes"
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
            />
          </SwitchContainer>
        </LeftContainer>
        <RightContainer>
          <AutoSyncSwitch />
        </RightContainer>
      </Container>
    </>
  );
};
