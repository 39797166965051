import { InlineNotesAndAttachments } from "@/common/components/inline-notes-and-attachments/InlineNotesAndAttachments";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  DistributorBuyoutItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorBuyout } from "../providers/DistributorBuyoutProvider";

type Props = {
  item: DistributorBuyoutItemFieldsFragment;
  readonly: boolean;
};

const BuyoutNotesDetailsWithProvider: FC<Props> = ({ item, readonly }) => {
  const { setExpandedItems, expandedItems, updateVendorBuyout } =
    useDistributorBuyout();

  const closeItem = useCallback(
    () => setExpandedItems(expandedItems.filter((id) => id !== item.id)),
    [expandedItems, item.id, setExpandedItems],
  );

  const handleOnSave = useCallback(
    async (notes: string, assets?: AssetFieldsFragment[]) => {
      await updateVendorBuyout({
        updates: [
          {
            buyoutItemId: item.id,
            notes: notes || null,
            assetUrls: assets?.map((a) => a.url) ?? [],
          },
        ],
      });

      closeItem();
    },
    [closeItem, item.id, updateVendorBuyout],
  );

  return (
    <InlineNotesAndAttachments
      closeItem={closeItem}
      item={item}
      readonly={readonly}
      saveItem={handleOnSave}
    />
  );
};

export const BuyoutNotesDetails: FC<Props> = (props) => {
  const { item } = props;
  const { buyout } = useDistributorBuyout();

  return (
    <UploadAssetProvider
      initialAssets={item.assets}
      context={AssetContext.Note}
      projectId={buyout?.project?.id}
    >
      <BuyoutNotesDetailsWithProvider {...props} />
    </UploadAssetProvider>
  );
};
