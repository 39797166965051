import { useReleaseStore } from "@/contractor/pages/home/release/store/useReleaseStore";
import { useCallback, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { ReleaseDateTimePickerProps } from "../ReleaseDateTimePicker";

type Props = Pick<
  ReleaseDateTimePickerProps,
  "onChange" | "prefillIfTbd" | "hideIfNotAdjustmentSet"
>;

export const useReleaseDateTimePicker = ({
  onChange,
  prefillIfTbd,
  hideIfNotAdjustmentSet,
}: Props) => {
  const { updateReleaseUpdateOptions, releaseUpdateOptions } = useReleaseStore(
    useShallow((state) => ({
      updateReleaseUpdateOptions: state.updateReleaseUpdateOptions,
      releaseUpdateOptions: state.releaseUpdateOptions,
    })),
  );

  const onChangeAndResetStore = useCallback(
    (date: Date | null | undefined) => {
      if (prefillIfTbd && releaseUpdateOptions?.requestedTime) {
        updateReleaseUpdateOptions({
          requestedTime: null,
        });
      }
      onChange(date);
    },
    [
      onChange,
      prefillIfTbd,
      releaseUpdateOptions?.requestedTime,
      updateReleaseUpdateOptions,
    ],
  );

  const hidden = useMemo(
    () => hideIfNotAdjustmentSet && !releaseUpdateOptions.requestedTime,
    [hideIfNotAdjustmentSet, releaseUpdateOptions.requestedTime],
  );
  return {
    onChangeAndResetStore,
    hidden,
  };
};
