import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { BATCH_MONTH_DATE_FORMAT } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useExportBatch } from "@/common/providers/ExportBatchProvider";
import { goToExternalUrls } from "@/common/utils/browserUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useCreateExternalBatch } from "@/contractor/pages/home/release/components/connections/hooks/useCreateExternalBatch";
import {
  BatchType,
  ExportInvoicesInput,
  LinkInvoiceInput,
  useExportReceiptsMutation,
  VoucherType,
} from "@/generated/graphql";
import { LinkingError, LinkingProgress } from "@/types/LinkingProgress";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import { format } from "date-fns";
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useLinkReceipt } from "../hooks/useLinkReceipt";
import { useReceiptsByIds } from "./ReceiptsByIdsProvider";
export type UpdatedReceipt = {
  id: string;
  externalVendorCode?: string;
};

type ProviderContextType = {
  exportReceipts: (input: ExportInvoicesInput) => Promise<boolean>;
  updatedReceipts: UpdatedReceipt[];
  updateReceipt: (receipts: UpdatedReceipt) => void;
  linking: LinkingProgress;
  linkReceipts: () => Promise<{ success: boolean; batch?: string } | undefined>;
  clearErrors: () => void;
  postingDate: Date | null;
  setPostingDate: (date: Date | null) => void;
  voucherType: VoucherType;
  setVoucherType: (value: VoucherType) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  exportReceipts: NoFunctionBooleanPromise,
  updatedReceipts: [],
  updateReceipt: NoFunction,
  linking: {
    linking: false,
    completed: [],
    percentage: 0,
    errors: [],
  },
  linkReceipts: NoFunctionUndefinedPromise,
  clearErrors: NoFunction,
  postingDate: null,
  setPostingDate: NoFunction,
  voucherType: VoucherType.Prepaid,
  setVoucherType: NoFunction,
});

export const ExportReceiptProvider: FC<PropsWithChildren> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { receiptIds, validatedReceipts } = useReceiptsByIds();
  const { connectedSourceSystemSettings } = useOrgSettings();
  const { linkReceipt } = useLinkReceipt();
  const { createExternalBatch } = useCreateExternalBatch();
  const { batchDate, newBatch, externalBatch } = useExportBatch();
  const [postingDate, setPostingDate] = useState<Date | null>(null);
  const [voucherType, setVoucherType] = useState<VoucherType>(
    VoucherType.Prepaid,
  );

  const [updatedReceipts, setUpdatedReceipts] = useState<UpdatedReceipt[]>([]);
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const [linking, setLinking] = useState<LinkingProgress>({
    linking: false,
    percentage: 0,
    completed: [],
    errors: [],
  });

  const linkReceipts = useCallback(async () => {
    if (connectedSourceSystemSettings?.system) {
      let batchId = externalBatch?.externalId;

      let createdBatchNumber, createdBatchDate;
      let success = true;

      const receiptsToExport = receiptIds.filter(
        (r) => validatedReceipts.find((vr) => vr.id === r.id)?.validated,
      );
      setLinking({
        linking: true,
        percentage: 0,
        completed: [],
        errors: [],
      });

      if (
        hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceBatching) &&
        newBatch &&
        !connectedSourceSystemSettings.autoPostInvoices
      ) {
        const createdBatch = await createExternalBatch({
          sourceSystem: connectedSourceSystemSettings.system,
          month: format(batchDate, BATCH_MONTH_DATE_FORMAT),
          type: BatchType.Invoice,
        });
        if (createdBatch) {
          batchId = createdBatch.externalId;
          createdBatchNumber = createdBatch.number;
          const createdBatchDateArr = createdBatch.month.split("-");
          createdBatchDate =
            createdBatchDateArr.length === 2
              ? `${createdBatchDateArr[1]}-${createdBatchDateArr[0]}`
              : createdBatch.month;
        }
      }
      const errors: LinkingError[] = [];

      await Promise.all(
        receiptsToExport.map(async (receipt, key) => {
          const input: LinkInvoiceInput = {
            invoiceId: receipt.id,
            sourceSystem: connectedSourceSystemSettings.system,
            batchId: !connectedSourceSystemSettings.autoPostInvoices
              ? batchId
              : undefined,
            month: connectedSourceSystemSettings.autoPostInvoices
              ? format(batchDate, BATCH_MONTH_DATE_FORMAT)
              : undefined,
          };
          const result = await linkReceipt(input);
          const hasTextError = typeof result !== "boolean";
          let error = "";
          if (hasTextError || !result) {
            success = false;
            if (hasTextError) {
              error = result;
            }
          }
          errors.push({ id: receipt.id, message: error });
          setLinking({
            linking: true,
            percentage: (key + 1) / receiptsToExport.length,
            completed: [...linking.completed, receipt.id],
            errors: [],
          });
        }),
      );

      setLinking({
        linking: false,
        percentage: 0,
        completed: [],
        errors,
      });

      return {
        success,
        batch: createdBatchNumber
          ? intl.$t(
              { id: "BATCH_NUMBER_DATE" },
              {
                number: createdBatchNumber,
                date: createdBatchDate,
              },
            )
          : "",
      };
    }
    return { success: false };
  }, [
    connectedSourceSystemSettings,
    externalBatch?.externalId,
    receiptIds,
    hasFeatureInConnectedSourceSystem,
    newBatch,
    intl,
    validatedReceipts,
    createExternalBatch,
    batchDate,
    linkReceipt,
    linking.completed,
  ]);

  const [exportReceiptsMutation] = useExportReceiptsMutation();
  const exportReceipts = async (input: ExportInvoicesInput) => {
    try {
      const { data, errors } = await exportReceiptsMutation({
        variables: {
          input,
        },
      });
      setError(errors);

      const urls = data?.exportReceipts.fileURLs;
      if (urls) {
        await goToExternalUrls(urls);
      }
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const updateReceipt = (updatedReceipt: UpdatedReceipt) => {
    const index = updatedReceipts.findIndex(
      (receipt) => receipt.id === updatedReceipt.id,
    );
    if (index === -1) {
      setUpdatedReceipts([...updatedReceipts, updatedReceipt]);
    } else {
      const newUpdatedReceipts = [...updatedReceipts];
      newUpdatedReceipts[index] = updatedReceipt;
      setUpdatedReceipts(newUpdatedReceipts);
    }
  };

  const clearErrors = useCallback(() => {
    setLinking({
      linking: false,
      percentage: 0,
      completed: [],
      errors: [],
    });
  }, []);

  return (
    <ProviderContext.Provider
      value={{
        exportReceipts,
        updatedReceipts,
        updateReceipt,
        linking,
        linkReceipts,
        clearErrors,
        postingDate,
        setPostingDate,
        voucherType,
        setVoucherType,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExportReceipt = (): ProviderContextType =>
  useContext(ProviderContext);
