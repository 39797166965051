import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useInvoiceExtPrices } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoiceExtPrices";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { useInvoiceValidation } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceValidationProvider";
import { useOrderItemPoItemReferences } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderItemPoItemReferences";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback, useMemo } from "react";

export const useInvoiceReleaseItemCheckbox = (item: ExpandedReleaseItem) => {
  const { updateInvoice } = useInvoiceVerification();
  const { invoice } = useInvoiceVerification();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const { release } = useRelease();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();
  const { extPrice } = useInvoiceExtPrices(item);

  const checked = useMemo(
    () => (item.invoiceItems || [])?.length > 0,
    [item.invoiceItems],
  );

  const handleChange = useCallback(
    async (newChecked: boolean) => {
      const isLumpSum = isLumpSumItem(item);
      const quantity = isLumpSum
        ? extPrice || "0"
        : item.quantityDecimal
          ? item.quantityDecimal
          : "";
      const addedInvoicedReleaseItems = newChecked
        ? [
            {
              id: item.id,
              releaseItemId: item.id,
              quantity,
              unitPrice: item.unitPrice
                ? item.unitPrice.toString()
                : isLumpSumItem(item)
                  ? "1"
                  : "0",
            },
          ]
        : undefined;
      const removedInvoicedReleaseItems =
        !newChecked && item.invoiceItems?.[0].id
          ? [item.invoiceItems?.[0].id]
          : undefined;
      await updateInvoice(
        {
          id: invoice?.id || "",
          addedInvoicedReleaseItems,
          removedInvoicedReleaseItems,
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
      refetchInvoiceValidation();
    },
    [
      item,
      extPrice,
      updateInvoice,
      invoice?.id,
      invoice?.release?.id,
      refetchInvoiceValidation,
    ],
  );

  const hidden = useMemo(
    () => hasOrderItemPoItemReferences(item, release),
    [hasOrderItemPoItemReferences, item, release],
  );

  return {
    checked,
    handleChange,
    hidden,
  };
};
