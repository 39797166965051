import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import {
  ItemizedNonItemizedToggle,
  ViewType,
} from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { useAddInvoiceItems } from "@/common/hooks/add-missing-items-to-order/useAddInvoiceItems";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { MatchedOrderViewState } from "../../../../enums/MatchedOrderViewState";
import { useInvoiceImportExternalPO } from "../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceMatchedOrder } from "../../../../providers/InvoiceMatchedOrderProvider";
import { InvoiceSelectModeHeader } from "../invoice-select-mode-header/InvoiceSelectModeHeader";
import { InvoiceCreateReleaseHeader } from "./components/invoice-create-release-header/InvoiceCreateReleaseHeader";
import { InvoiceCreateReleaseItemizedView } from "./components/invoice-create-release-itemized-view/InvoiceCreateReleaseItemizedView";
import { InvoiceCreateReleaseNonItemizedView } from "./components/invoice-create-release-non-itemized-view/InvoiceCreateReleaseNonItemizedView";
import { InvoiceExternalPOCostCodes } from "./components/invoice-external-po-cost-codes/InvoiceExternalPOCostCodes";
import { useInvoiceCreateRelease } from "./hooks/useInvoiceCreateRelease";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const HeaderGroup = tw.div`flex w-full place-items-center gap-1 mb-2`;
const ButtonGroup = tw.div`flex flex-row gap-2 min-w-screen-xs justify-end pr-2 items-center`;

export const InvoiceCreateRelease: FC = () => {
  const { addInvoiceItems } = useAddInvoiceItems();
  const {
    importedPoItemized,
    isNonItemizedPO,
    importedPoIsImmutable,
    setVariables,
  } = useInvoiceImportExternalPO();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();

  useInvoiceCreateRelease();

  return (
    <Container>
      <HeaderGroup>
        <InvoiceSelectModeHeader
          classes={{
            root: "flex flex-full justify-evenly",
            row: "justify-start",
          }}
        >
          <ButtonGroup>
            <If isTrue={importedPoItemized && !importedPoIsImmutable}>
              <OutlinedButton $small onClick={() => addInvoiceItems()}>
                <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_INVOICE" />
              </OutlinedButton>
            </If>
            <If
              isTrue={
                (isNonItemizedPO ||
                  matchedOrderViewState !==
                    MatchedOrderViewState.IMPORT_ORDER) &&
                !importedPoIsImmutable
              }
            >
              <ItemizedNonItemizedToggle
                handleChange={(view) =>
                  setVariables({
                    importedPoItemized: view === ViewType.Itemized,
                  })
                }
                isItemized={!!importedPoItemized}
              />
            </If>
          </ButtonGroup>
        </InvoiceSelectModeHeader>
      </HeaderGroup>
      <InvoiceCreateReleaseHeader />
      <InvoiceExternalPOCostCodes />
      <If
        isTrue={
          importedPoItemized ||
          matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
        }
      >
        <InvoiceCreateReleaseItemizedView />
      </If>
      <If
        isTrue={
          !importedPoItemized &&
          matchedOrderViewState !== MatchedOrderViewState.IMPORT_ORDER
        }
      >
        <InvoiceCreateReleaseNonItemizedView />
      </If>
    </Container>
  );
};
