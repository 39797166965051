import { useKickbackCommentActions } from "@/common/hooks/useKickbackCommentActions";
import { useUser } from "@/common/providers/UserProvider";
import { useCallback, useMemo, useState } from "react";
import { KickbackCommentProps } from "./KickbackComment";

const DEFAULT_COMMENT_ID = "new-comment-id";

export const useKickbackComment = ({
  item,
  setEditedCommentId,
}: KickbackCommentProps) => {
  const [edit, setEdit] = useState(!item);
  const { deleteInvoiceKickbackComment } = useKickbackCommentActions();
  const { viewer } = useUser();

  const handleEditClick = useCallback(() => {
    setEdit(true);
    setEditedCommentId?.(item?.id || DEFAULT_COMMENT_ID);
  }, [item?.id, setEditedCommentId]);

  const handleCancelEdit = useCallback(() => {
    setEdit(false);
    setEditedCommentId?.(undefined);
  }, [setEditedCommentId]);

  const isCommentCreator = useMemo(
    () => item?.createdBy.id === viewer?.id,
    [item?.createdBy, viewer?.id],
  );

  return {
    isCommentCreator,
    handleCancelEdit,
    handleEditClick,
    deleteInvoiceKickbackComment,
    edit,
    setEdit,
  };
};
