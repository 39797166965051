import { useUser } from "@/common/providers/UserProvider";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { TaxType } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { If } from "../if/If";
import { TaxExemptInfoTooltip } from "./components/common/TaxExemptInfoTooltip";
import { NativeSalesTaxInput } from "./components/use-native-sales-tax/NativeSalesTaxInput";
import { UseTaxSwitch } from "./components/use-native-sales-tax/toggle/UseTaxSwitch";
import { SalesTaxVarianceInput } from "./components/use-sales-tax/input/SalesTaxVarianceInput";
import {
  AmountPercentageSwitch,
  SalesTaxType,
} from "./components/use-sales-tax/toggle/AmountPercentageSwitch";
import { UseSalesTaxInput } from "./components/use-sales-tax/UseSalesTaxInput";
import { useFormattedSalesTax } from "./hooks/useFormattedSalesTax";
import { useTaxCodeSummaries } from "./hooks/useTaxCodeSummaries";
import { useTaxType } from "./hooks/useTaxType";
import {
  ItemContainer,
  SalesReadonlyTaxLabel,
  SalesTaxLabel,
  SalesTaxValue,
} from "./styles/SalesTaxInput.styles";
import {
  SalesTaxInputProps,
  UpdateSalesTaxFunctionInput,
} from "./types/SalesTaxInputProps";

type OptionalSalesTaxProps = {
  canEdit: boolean;
  classes?: {
    container?: string;
    label?: string;
  };
  includeTaxVariance?: boolean;
  inlineEditTaxVariance?: boolean;
};

type Props = SalesTaxInputProps & OptionalSalesTaxProps;

const DEFAULT_CLASS_NAME = "mt-1";

export const SalesTax: FC<Props> = ({
  taxExempt,
  salesTaxInput,
  canEdit,
  update,
  classes,
  fallbackOrderTypes,
  fallbackTaxCodes,
  includeTaxVariance = true,
  inlineEditTaxVariance = false,
}) => {
  const { hasTaxVariance } = useOrgSettings();
  const { isContractor } = useUser();

  const [salesAmount, setSalesAmount] = useState(
    salesTaxInput.customTaxAmount ? Number(salesTaxInput.customTaxAmount) : 0,
  );
  const [type, setType] = useState<SalesTaxType>(
    salesTaxInput.customTaxAmount ? SalesTaxType.Amount : SalesTaxType.Percent,
  );
  const [taxType, setTaxType] = useState<TaxType | undefined>(
    salesTaxInput.taxType ?? undefined,
  );
  const [taxCode, setTaxCode] = useState<string | null>(
    salesTaxInput.taxCodeId ?? null,
  );
  const [salesTax, setSalesTax] = useState(
    salesTaxInput.taxRate
      ? Number(new DecimalSafe(salesTaxInput.taxRate ?? 0).mul(100))
      : null,
  );
  const [salesTaxVariance, setSalesTaxVariance] = useState<string | null>(
    salesTaxInput.taxVariance ? salesTaxInput.taxVariance : null,
  );

  const { taxTypeKey, useTaxKey, isNativeSalesTax } = useTaxType(
    salesTaxInput.orderTypeId,
    fallbackOrderTypes,
    salesTaxInput.taxType,
  );

  const { taxCodes: allTaxCodes } = useTaxCodeSummaries();

  const {
    formattedSalesTax,
    formattedNativeSalesTax,
    formattedTaxAmount,
    calculatedTaxAmount,
  } = useFormattedSalesTax({
    params: { fallbackTaxCodes, salesTaxInput, fallbackOrderTypes },
    options: { taxCode, canEdit },
  });

  const subtotalWithCharges = useMemo(
    () =>
      new DecimalSafe(salesTaxInput.taxableNetAmount || 0).add(
        salesTaxInput.chargesAmount || 0,
      ),
    [salesTaxInput.taxableNetAmount, salesTaxInput.chargesAmount],
  );

  useEffect(() => {
    if (salesTaxInput.customTaxAmount) {
      setType(SalesTaxType.Amount);
    } else {
      setType(SalesTaxType.Percent);
    }
    setSalesAmount(
      salesTaxInput.customTaxAmount ? Number(salesTaxInput.customTaxAmount) : 0,
    );
    setSalesTax(
      salesTaxInput.taxRate
        ? Number(new DecimalSafe(salesTaxInput.taxRate).mul(100))
        : 0,
    );
    setTaxType(
      salesTaxInput.taxType || (isNativeSalesTax ? TaxType.Use : undefined),
    );
    setTaxCode(salesTaxInput.taxCodeId || null);
    setSalesTaxVariance(salesTaxInput.taxVariance || null);
  }, [
    isNativeSalesTax,
    salesTaxInput.customTaxAmount,
    salesTaxInput.taxRate,
    salesTaxInput.taxType,
    salesTaxInput.taxCodeId,
    salesTaxInput.taxVariance,
  ]);

  const handleUpdate = useCallback(
    async (props: Partial<UpdateSalesTaxFunctionInput>) => {
      if (update) {
        await update({
          ...props,
          id: salesTaxInput.id,
          version: salesTaxInput.version,
        });
      }
    },
    [salesTaxInput.id, salesTaxInput.version, update],
  );

  const changeType = useCallback(
    async (newType: SalesTaxType) => {
      if (type === newType) {
        return;
      }
      if (newType) {
        if (newType === SalesTaxType.Amount) {
          const newCustomSalesTax = new DecimalSafe(
            calculatedTaxAmount || "0",
          ).toNumber();
          if (handleUpdate) {
            await handleUpdate({
              customTaxAmount: newCustomSalesTax.toString(),
              clearCustomTaxAmount: false,
              ...(includeTaxVariance &&
                isContractor && { clearTaxVariance: true }),
              taxVariance: undefined,
              ...(isContractor && { clearTaxCode: true }),
            });
          } else {
            setSalesAmount(newCustomSalesTax);
            setSalesTax(0);
          }
        } else {
          const newTaxRate =
            subtotalWithCharges && Number(subtotalWithCharges)
              ? new DecimalSafe(salesAmount || "0")
                  .div(
                    subtotalWithCharges ? Number(subtotalWithCharges) || 1 : 1,
                  )
                  .toDP(4)
                  .toNumber()
              : 0;
          if (update) {
            await handleUpdate({
              clearCustomTaxAmount: true,
              customTaxAmount: null,
              taxRate: newTaxRate.toString(),
            });
          } else {
            setSalesTax(newTaxRate);
            setSalesAmount(0);
          }
        }
      }
    },
    [
      type,
      calculatedTaxAmount,
      handleUpdate,
      includeTaxVariance,
      isContractor,
      subtotalWithCharges,
      salesAmount,
      update,
    ],
  );

  const changeNativeTax = useCallback(
    async (newType: TaxType) => {
      if (newType) {
        if (handleUpdate) {
          await handleUpdate({
            taxType: newType,
            clearCustomTaxAmount: undefined,
            taxRate: undefined,
            customTaxAmount: undefined,
          });
        } else {
          setTaxType(newType);
        }
      }
    },
    [handleUpdate],
  );

  const readonlyTaxVariance = useMemo(
    () => !isContractor || !canEdit,
    [isContractor, canEdit],
  );

  // include tax variance feature if the feature is enabled or if the tax variance was previously set
  // and the tax type is percent
  // and includeTaxVariance flag is set to true
  const includeTaxVarianceFeature = useMemo(() => {
    return (
      ((hasTaxVariance || (salesTaxVariance && Number(salesTaxVariance))) &&
        includeTaxVariance &&
        type === SalesTaxType.Percent) ||
      inlineEditTaxVariance
    );
  }, [
    hasTaxVariance,
    includeTaxVariance,
    inlineEditTaxVariance,
    salesTaxVariance,
    type,
  ]);

  return (
    <>
      <ItemContainer
        className={classes?.container ?? DEFAULT_CLASS_NAME}
        data-testid="order-salesTax"
      >
        <SalesTaxLabel className={classes?.label}>
          <FormattedMessage
            id={!canEdit && isNativeSalesTax ? useTaxKey : taxTypeKey}
            tagName={!canEdit ? SalesReadonlyTaxLabel : undefined}
          />
          <TaxExemptInfoTooltip taxExempt={taxExempt} />
          <If isTrue={canEdit}>
            <If isTrue={!isNativeSalesTax}>
              <AmountPercentageSwitch type={type} changeType={changeType} />
            </If>
            <If isTrue={isNativeSalesTax && allTaxCodes.length > 0}>
              <UseTaxSwitch changeType={changeNativeTax} type={taxType} />
            </If>
          </If>
        </SalesTaxLabel>
        <If isTrue={!canEdit}>
          <SalesTaxValue>{formattedSalesTax}</SalesTaxValue>
        </If>
        <If isTrue={canEdit}>
          <If isTrue={!isNativeSalesTax}>
            <UseSalesTaxInput
              formattedTaxAmount={formattedTaxAmount}
              salesAmount={salesAmount}
              salesTax={salesTax}
              setSalesAmount={setSalesAmount}
              setSalesTax={setSalesTax}
              type={type}
              update={handleUpdate}
            />
          </If>
          <If isTrue={isNativeSalesTax}>
            <NativeSalesTaxInput
              setTaxCode={setTaxCode}
              taxCode={taxCode}
              update={handleUpdate}
              taxType={taxType}
              fallbackTaxCodes={fallbackTaxCodes}
            />
          </If>
        </If>
      </ItemContainer>
      <If isTrue={includeTaxVarianceFeature}>
        <SalesTaxVarianceInput
          classes={classes}
          setValue={setSalesTaxVariance}
          value={salesTaxVariance}
          update={handleUpdate}
          readonly={readonlyTaxVariance}
          inlineEditTaxVariance={inlineEditTaxVariance}
        />
      </If>
      <If isTrue={isNativeSalesTax && formattedNativeSalesTax && canEdit}>
        <ItemContainer
          className={classes?.container}
          data-testid="order-salesTax"
        >
          <SalesTaxLabel>
            <FormattedMessage id="TAX" tagName={SalesReadonlyTaxLabel} />
          </SalesTaxLabel>
          <SalesTaxValue>{formattedNativeSalesTax}</SalesTaxValue>
        </ItemContainer>
      </If>
    </>
  );
};
