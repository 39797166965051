import { useCallback, useMemo } from "react";
import { useRfqs } from "../../providers/RfqsProvider";
import { useRfqSequence } from "../hooks/useRfqSequence";

export const useRfqAllItemsCheckbox = () => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useRfqSequence();
  const { rfqs } = useRfqs();

  const checked = useMemo(
    () => rfqs.length > 0 && rfqs.every((d) => selectedEntities.includes(d)),
    [rfqs, selectedEntities],
  );

  const handleChange = useCallback(() => {
    const filteredItems = rfqs;
    setSelectedEntities(
      checked
        ? selectedEntities.filter((i) => !filteredItems.includes(i))
        : [
            ...selectedEntities.filter((i) => filteredItems.includes(i)),
            ...filteredItems,
          ],
    );
  }, [rfqs, checked, selectedEntities, setSelectedEntities]);

  return { handleChange, sequenceIds, checked };
};
