import { DiscardButton } from "@/common/components/button/DiscardButton";
import { EditButton } from "@/common/components/button/EditButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { GroupToggle } from "@/common/components/group-toggle/GroupToggle";
import { If } from "@/common/components/if/If";
import { InvoiceKickbackForm } from "@/common/components/invoices/invoice-issue/kickback/InvoiceKickbackForm";
import { InvoiceVendorIssueForm } from "@/common/components/invoices/invoice-issue/vendor-issue/InvoiceVendorIssueForm";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import {
  AssetFieldsFragment,
  InvoiceKickbackCommentFieldsFragment,
  InvoiceKickbackFieldsFragment,
  UsersUserFieldsFragment,
} from "@/generated/graphql";
import { Attachment, CheckCircle } from "@mui/icons-material";
import { Form, FormProvider } from "react-hook-form";
import { BsExclamationCircle, BsExclamationCircleFill } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { KickbackComment } from "../kickback-comment/KickbackComment";
import { InvoiceRespondForm, ResponseType } from "./InvoiceRespondForm";
import { IssueContent } from "./IssueContent";
import { useInvoiceIssue } from "./useInvoiceIssue";

const IssueContainer = tw.div`flex flex-row`;
const IssueView = tw.div`grid grid-flow-row content-start items-center bg-white rounded-l-3xl border px-4 py-2 font-normal text-sm w-full`;
const IssueResolution = tw(
  IssueView,
)`bg-green-300 rounded-r-3xl rounded-l-none`;
const IssueRejection = tw(
  IssueView,
)`bg-yellow-400 rounded-r-3xl rounded-l-none`;
const IssueViewHeader = tw.div`flex md:flex-row items-center justify-between font-medium flex-col`;
const RedExclamationCircle = tw(
  BsExclamationCircleFill,
)`text-red-500 mr-2 text-xl`;
const ExclamationCircle = tw(BsExclamationCircle)<{
  $hasDescription: boolean;
}>`mr-2 text-xl
  ${({ $hasDescription }) =>
    $hasDescription ? "text-gray-500" : "text-red-500"}
`;
const GreenCheckmarkCircle = tw(CheckCircle)`text-green-800 mr-1.5 text-2xl`;
const IssueDate = tw.div`text-2xs font-normal text-right`;
const IssueHeaderItem = tw.div`flex flex-row items-center text-sm`;
const IssueTitle = tw.div`flex flex-col`;
const Users = tw.div`text-xs font-normal`;
const ButtonContainer = tw.div`flex justify-end gap-4`;
const LinkLikeStyled = tw(LinkLike)`text-sm font-normal`;
const AttachmentStyled = tw(Attachment)`text-blue-500`;
const AttachmentsInfo = tw.div`grid justify-center grid-flow-col items-center gap-2 mx-2`;
const StyledOutlineButton = tw(OutlinedButton)`h-8 px-4 text-sm`;
const StyledPrimaryButton = tw(PrimaryButton)`h-8 px-4 text-sm`;

export enum InvoiceIssueType {
  ISSUE = "ISSUE",
  KICKBACK = "KICKBAcK",
}

export enum InvoiceDocumentType {
  INVOICE = "INVOICE",
  RECEIPT = "RECEIPT",
}

export interface InvoiceIssueProps {
  type: InvoiceIssueType;
  documentType?: InvoiceDocumentType;
  resolutionDescription: string | null | undefined;
  issueDescription: string | null | undefined;
  rejectionReason?: string | null | undefined;
  issueDate: number | null | undefined;
  issueResponseDate: number | null | undefined;
  issueBy?: string;
  assignees?: Pick<UsersUserFieldsFragment, "firstName" | "lastName" | "id">[];
  userCanRespond?: boolean;
  responseBy?: string;
  id: string;
  invoiceId?: string;
  kickback?: InvoiceKickbackFieldsFragment;
  canEdit?: boolean;
  index?: number;
  refetchInvoice?: () => Promise<void>;
  assets?: AssetFieldsFragment[];
  resolutionAssets?: AssetFieldsFragment[] | null;
  comments?: InvoiceKickbackCommentFieldsFragment[];
  invoiceDeleted: boolean;
}

export const InvoiceIssue = (props: InvoiceIssueProps) => {
  const {
    edit,
    setEdit,
    isAddingComment,
    editedCommentId,
    setEditedCommentId,
    isAssignee,
    expanded,
    setExpanded,
    responseType,
    setResponseType,
    methods,
    loading,
    updatingIssue,
    isContractor,
    update,
    deleteItem,
    resolve,
    acceptIssue,
    declineIssue,
    issueResolved,
    canResolve,
    handleAddComment,
    handleCancelAddingComment,
  } = useInvoiceIssue(props);
  const {
    type,
    issueDescription,
    resolutionDescription,
    rejectionReason,
    issueDate,
    issueResponseDate,
    issueBy,
    responseBy,
    canEdit,
    assignees,
    id,
    invoiceId,
    index,
    assets,
    resolutionAssets,
    comments,
    invoiceDeleted,
  } = props;

  const { openDialog } = useDialog();

  const intl = useIntl();

  return (
    <FormProvider {...methods}>
      <Form>
        <IssueContainer
          className={`${issueResolved ? "" : "w-full rounded-r-3xl"}`}
        >
          <IssueView className={issueResolved ? "w-full" : "rounded-r-3xl"}>
            <IssueViewHeader>
              <IssueHeaderItem>
                <GroupToggle
                  isOpened={expanded}
                  onClick={() => setExpanded((v) => !v)}
                />
                {!issueResolved && (isAssignee || !isContractor) ? (
                  <RedExclamationCircle />
                ) : (
                  <ExclamationCircle $hasDescription={issueResolved} />
                )}
                <IssueTitle>
                  <FormattedMessage
                    id={
                      type === InvoiceIssueType.KICKBACK
                        ? "ISSUE_REPORTED_BY_USER"
                        : "ISSUE_DESCRIPTION_WITH_NUMBER"
                    }
                    values={{ userName: issueBy, number: index }}
                    tagName="div"
                  />
                  <If
                    isTrue={
                      type === InvoiceIssueType.KICKBACK && !issueResolved
                    }
                  >
                    <FormattedMessage
                      id="ASSIGNED_TO_USERS"
                      values={{
                        users: assignees?.map((a) => getUserName(a)).join(", "),
                      }}
                      tagName={Users}
                    />
                  </If>
                </IssueTitle>
              </IssueHeaderItem>
              <If isTrue={!expanded && assets?.length}>
                <AttachmentsInfo>
                  <AttachmentStyled onClick={() => setExpanded((v) => !v)} />(
                  {assets?.length})
                </AttachmentsInfo>
              </If>
              <IssueHeaderItem>
                <If isTrue={issueDate}>
                  <IssueDate>
                    <DateView
                      date={issueDate}
                      includeTime
                      options={SHORT_DATE_OPTION}
                      hideTimeZone
                      twoLines
                    />
                  </IssueDate>
                  <If
                    isTrue={
                      canEdit &&
                      !issueResolved &&
                      !isAddingComment &&
                      !editedCommentId &&
                      !responseType &&
                      !invoiceDeleted
                    }
                  >
                    {!edit ? (
                      <EditButton
                        onClick={() => {
                          setEdit(true);
                          setExpanded(true);
                        }}
                        className="h-3"
                      />
                    ) : (
                      <DiscardButton onClick={() => setEdit(false)} />
                    )}
                  </If>
                </If>
              </IssueHeaderItem>
            </IssueViewHeader>
            {edit ? (
              <>
                <If isTrue={type === InvoiceIssueType.KICKBACK}>
                  <InvoiceKickbackForm
                    invoiceId={invoiceId}
                    hideCloseButton
                    assets={assets}
                  />
                </If>
                <If isTrue={type === InvoiceIssueType.ISSUE}>
                  <InvoiceVendorIssueForm hideFooter />
                </If>
                <ButtonContainer>
                  <LinkLikeStyled
                    onClick={() =>
                      openDialog({
                        cancelButtonText: intl.$t({ id: "CANCEL" }),
                        confirmButtonText: intl.$t({ id: "DELETE" }),
                        includeWarningIcon: true,
                        titleClassName: "w-96",
                        title: intl.$t(
                          {
                            id:
                              type === InvoiceIssueType.KICKBACK
                                ? "DELETE_KICKBACK_NUMBER"
                                : "DELETE_ISSUE_NUMBER",
                          },
                          { number: index },
                        ),
                        text:
                          type === InvoiceIssueType.ISSUE
                            ? intl.$t({
                                id: "VENDOR_WILL_BE_IMMEDIATELY_NOTIFIED",
                              })
                            : undefined,
                        handleConfirm: deleteItem,
                      })
                    }
                  >
                    <FormattedMessage id="DELETE" />
                  </LinkLikeStyled>
                  <StyledOutlineButton $small onClick={() => setEdit(false)}>
                    <FormattedMessage id="CANCEL" />
                  </StyledOutlineButton>
                  <StyledPrimaryButton
                    $small
                    onClick={update}
                    loading={updatingIssue || loading}
                  >
                    <FormattedMessage id="UPDATE" />
                  </StyledPrimaryButton>
                </ButtonContainer>
              </>
            ) : (
              <If isTrue={expanded}>
                <IssueContent description={issueDescription} assets={assets} />
                <If isTrue={type === InvoiceIssueType.KICKBACK}>
                  {comments?.map((comment) => (
                    <KickbackComment
                      item={comment}
                      key={comment.id}
                      kickbackId={id}
                      readonly={
                        !!responseType ||
                        issueResolved ||
                        isAddingComment ||
                        (!!editedCommentId && editedCommentId !== comment.id) ||
                        invoiceDeleted
                      }
                      setEditedCommentId={setEditedCommentId}
                    />
                  ))}
                  <If isTrue={isAddingComment}>
                    <KickbackComment
                      onCancelAdding={handleCancelAddingComment}
                      kickbackId={id}
                    />
                  </If>
                </If>
              </If>
            )}
            <If
              isTrue={
                !edit &&
                expanded &&
                !issueResolved &&
                !isAddingComment &&
                !editedCommentId &&
                !invoiceDeleted
              }
            >
              <ButtonContainer>
                <If
                  isTrue={type === InvoiceIssueType.KICKBACK && !responseType}
                >
                  <OutlinedButton $small onClick={handleAddComment}>
                    <FormattedMessage id="COMMENT" />
                  </OutlinedButton>
                </If>
                <If isTrue={canResolve}>
                  <OutlinedButton
                    $small
                    onClick={() => {
                      setResponseType(ResponseType.Kickback);
                      setExpanded(true);
                    }}
                  >
                    <FormattedMessage id="RESOLVE" />
                  </OutlinedButton>
                </If>
              </ButtonContainer>
            </If>
            <If isTrue={responseType}>
              <InvoiceRespondForm type={responseType} />
              <If isTrue={isContractor}>
                <ButtonContainer>
                  <StyledOutlineButton
                    $small
                    onClick={() => setResponseType(null)}
                  >
                    <FormattedMessage id="CANCEL" />
                  </StyledOutlineButton>
                  <StyledPrimaryButton
                    $small
                    onClick={resolve}
                    loading={updatingIssue || loading}
                  >
                    <FormattedMessage id="MARK_AS_RESOLVED" />
                  </StyledPrimaryButton>
                </ButtonContainer>
              </If>
            </If>
            <If isTrue={!isContractor}>
              <ButtonContainer>
                <If
                  isTrue={
                    !responseType && !resolutionDescription && !rejectionReason
                  }
                >
                  <OutlinedButton
                    className="h-8 text-sm"
                    $small
                    onClick={() => setResponseType(ResponseType.Decline)}
                  >
                    <FormattedMessage id="DECLINE" />
                  </OutlinedButton>
                  <StyledOutlineButton
                    $small
                    onClick={() => setResponseType(ResponseType.Accept)}
                  >
                    <FormattedMessage id="ACCEPT_AND_CORRECT" />
                  </StyledOutlineButton>
                </If>
                <If isTrue={responseType === ResponseType.Accept}>
                  <StyledOutlineButton
                    $small
                    onClick={() => setResponseType(null)}
                  >
                    <FormattedMessage id="CANCEL" />
                  </StyledOutlineButton>
                  <StyledPrimaryButton
                    $small
                    onClick={acceptIssue}
                    disabled={!methods.watch("resolution")}
                  >
                    <FormattedMessage id="ACCEPT_AND_CORRECT_ISSUE" />
                  </StyledPrimaryButton>
                </If>
                <If isTrue={responseType === ResponseType.Decline}>
                  <StyledOutlineButton
                    $small
                    onClick={() => setResponseType(null)}
                  >
                    <FormattedMessage id="CANCEL" />
                  </StyledOutlineButton>
                  <StyledPrimaryButton
                    $small
                    onClick={declineIssue}
                    disabled={!methods.watch("resolution")}
                  >
                    <FormattedMessage id="DECLINE_ISSUE" />
                  </StyledPrimaryButton>
                </If>
              </ButtonContainer>
            </If>
          </IssueView>
          <If isTrue={issueResolved}>
            <IssueResolution>
              <IssueViewHeader>
                <IssueHeaderItem>
                  <GreenCheckmarkCircle />
                  <FormattedMessage
                    id={
                      type === InvoiceIssueType.KICKBACK
                        ? "USER_RESPONSE"
                        : "ISSUE_RESOLVED"
                    }
                    values={{ userName: responseBy, number: index }}
                  />
                </IssueHeaderItem>
                <If isTrue={!expanded && resolutionAssets?.length}>
                  <AttachmentsInfo>
                    <AttachmentStyled onClick={() => setExpanded((v) => !v)} />(
                    {resolutionAssets?.length})
                  </AttachmentsInfo>
                </If>
                <If isTrue={issueDate}>
                  <IssueDate>
                    <DateView
                      date={issueResponseDate}
                      includeTime
                      options={SHORT_DATE_OPTION}
                      hideTimeZone
                      twoLines
                    />
                  </IssueDate>
                </If>
              </IssueViewHeader>
              <If isTrue={expanded}>
                <IssueContent
                  description={resolutionDescription}
                  assets={resolutionAssets}
                />
              </If>
            </IssueResolution>
          </If>
          <If isTrue={rejectionReason}>
            <IssueRejection>
              <IssueViewHeader>
                <IssueHeaderItem>
                  <RedExclamationCircle />
                  <FormattedMessage
                    id="ISSUE_REJECTED"
                    values={{ number: index }}
                  />
                </IssueHeaderItem>
                <If isTrue={!expanded && resolutionAssets?.length}>
                  <AttachmentsInfo>
                    <AttachmentStyled onClick={() => setExpanded((v) => !v)} />(
                    {resolutionAssets?.length})
                  </AttachmentsInfo>
                </If>
                <If isTrue={issueDate}>
                  <IssueDate>
                    <DateView
                      date={issueResponseDate}
                      includeTime
                      options={SHORT_DATE_OPTION}
                      hideTimeZone
                      twoLines
                    />
                  </IssueDate>
                </If>
              </IssueViewHeader>
              <If isTrue={expanded}>
                <IssueContent
                  description={rejectionReason}
                  assets={resolutionAssets}
                />
              </If>
            </IssueRejection>
          </If>
        </IssueContainer>
      </Form>
    </FormProvider>
  );
};
