import { ExpirationPicker } from "@/common/components/expiration-picker/ExporationPicker";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Price } from "@/common/components/price/Price";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { TagItem } from "@/common/components/tag-picker/TagItem";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  Base,
  SmdFixedContainer,
  SmFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ProjectItemMaterialEditableView } from "@/contractor/pages/home/project/components/project-items/ProjectItemMaterialEditableView";
import { useProjectMaps } from "@/contractor/pages/home/project/hooks/useProjectMaps";
import {
  BuyoutItemFieldsFragment,
  ProjectExtendedFieldsFragment,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { GroupBuyoutByCostCodeToggle } from "../../../../buyout/components/common/GroupBuyoutByCostCodeToggle";
import { filterByDescriptionAndInstructions } from "../../../utils/filters/filterByInstructions";
import { filterByMaterialName } from "../../../utils/filters/filterByMaterialName";
import { AddBuyoutAllItemsCheckbox } from "./components/AddBuyoutAllItemsCheckbox";
import { AddBuyoutItemCheckbox } from "./components/AddBuyoutItemCheckbox";
import { AddBuyoutItemGroupCheckbox } from "./components/AddBuyoutItemGroupCheckbox";
import { BuyoutItemDetails } from "./components/BuyoutItemDetails";

const NameContainer = tw.div`grid w-full gap-0.5`;
const ItemContainer = tw(Base)`
  col-span-full flex-1
`;
const Text = tw.div`text-sm
${({ $red }: { $red: boolean }) => $red && "text-red-500"}
`;

export const useAddToReleaseFromBuyoutConfiguration = (
  project: ProjectExtendedFieldsFragment | null,
) => {
  const { projectItemsMap } = useProjectMaps(project);
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { hasPhaseCodes } = useOrgSettings();

  const getProjItem = useCallback(
    (item: BuyoutItemFieldsFragment) => {
      return projectItemsMap.get(item.projectItem.id) || null;
    },
    [projectItemsMap],
  );

  const configuration: Array<GridCol<BuyoutItemFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: Base,
        position: "center",
        item: ({ item }) => <AddBuyoutItemCheckbox item={item} />,
        group: (category) => <AddBuyoutItemGroupCheckbox group={category} />,
      },
      {
        wrapper: ItemContainer,
        item: ({ item, count }) => {
          const projItem = getProjItem(item);
          if (!projItem) {
            return null;
          }
          return (
            <NameContainer>
              <ProjectItemMaterialEditableView
                item={projItem}
                count={count}
                costCode={item.costCode}
                manufacturer={item.manufacturer}
                notes={item.instructions?.text}
                assets={item.instructions?.assets}
                displayRowToggle={false}
              />
              <BuyoutItemDetails
                notes={item.notes}
                assets={item.assets}
                instructions={item.instructions}
              />
            </NameContainer>
          );
        },
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={project?.id}
          />
        ),
        headerFn: (category) => (
          <>
            <AddBuyoutAllItemsCheckbox category={category} />
            <FormattedMessage id="BUYOUT_ITEMS" />
            <GroupBuyoutByCostCodeToggle />
          </>
        ),
        details: ({ item }) => {
          const projItem = getProjItem(item);
          if (!projItem) {
            return null;
          }
          return (
            <ProjectItemMaterialView
              item={projItem}
              className="pl-8 before:content-['•']"
              includeCounter={false}
              manufacturer={item?.manufacturer}
            />
          );
        },
        includesCounter: true,
        searchItemFn: (item, searchText) =>
          filterByDescriptionAndInstructions(item, searchText) ||
          filterByMaterialName(item.projectItem, searchText),
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id={hasPhaseCodes ? "PHASE_CODE" : "TAG"} />,
        position: "center",
        item: ({ item }) => {
          return <TagItem tag={item.tags[0]} />;
        },
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => {
          return (
            <ValueUnit
              value={item?.quantityDecimal ?? "0"}
              uom={getProjItem(item)?.estimateUom}
            />
          );
        },
        header: <FormattedMessage id="BUYOUT_QUANTITY" />,
        group: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} $bold />
        ),
        subgroup: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} />
        ),
        details: ({ item }) => {
          return (
            <ValueUnit
              value={item?.quantityDecimal || "0"}
              uom={getProjItem(item)?.estimateUom}
            />
          );
        },
      },
      {
        header: <FormattedMessage id="PRICE" />,
        wrapper: SmFixedContainer,
        position: "center",
        item: ({ item }) => (
          <Price className="text-sm" price={item.unitPrice} />
        ),
        hidden: !hasPermissions,
      },
      {
        header: <FormattedMessage id="ORDERED_SO_FAR" />,
        wrapper: SmFixedContainer,
        position: "end",
        item: ({ item }) => {
          const percent = new DecimalSafe(item?.releasedAmount || 0)
            .div(Number(item.unitPrice) || 1)
            .div(
              item.quantityDecimal !== null &&
                item.quantityDecimal !== undefined
                ? Number(item.quantityDecimal) || 1
                : 1,
            )
            .mul(100)
            .toNumber();
          return (
            <Text $red={Math.round(percent) > 100}>{Math.round(percent)}%</Text>
          );
        },
      },
      {
        wrapper: SmdFixedContainer,
        position: "center",
        item: ({ item }) => (
          <ExpirationPicker date={item.expirationDate} readonly />
        ),
        header: <FormattedMessage id="PRICE_EXPIRATION" />,
      },
    ],
    [getProjItem, hasPermissions, project?.id, hasPhaseCodes],
  );

  return { configuration };
};
