import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";

export type Option<T> = {
  id: string;
  title: string;
  filter: (value: boolean | null) => T;
  value: (filter: T | undefined) => boolean | undefined | null;
};

type Props<T> = {
  customBottomFilters?: React.ReactNode;
  options: Option<T>[];
  filter: T | undefined;
  setFilter: (value: T) => void;
  defaultFilter?: T;
  includeBottomBorder?: boolean;
  twoColumns?: boolean;
};

const Container = tw.div`px-5 py-3`;
const FilterHeader = tw.div`flex mt-2 text-xs pb-2 border-b border-dashed border-gray-400 mb-4`;
const FilterHeaderText = tw.div`flex-1`;
const FilterContainer = tw.div`flex justify-between items-center gap-4`;
const Name = tw.div`min-w-48`;
const Configurations = tw.div`flex gap-2 justify-end`;
const Configuration = tw.div<{
  $active: boolean;
  $isDefault?: boolean;
}>`text-right
${({ $active }) =>
  $active
    ? "text-black cursor-default underline font-bold"
    : "text-blue-500 cursor-pointer"} 
${({ $isDefault = false }) => ($isDefault ? "ml-1 border-l-2 pl-2 border-dotted w-20" : "")}   
`;
export const DividerLine = tw.div`
  w-full h-px border-b border-dashed mt-2 mb-2
`;
const ResetLink = tw(LinkLike)`text-xs`;
const ColumnsContainer = tw.div`flex flex-col`;
const Col = tw.div`flex flex-col gap-2`;

const isYes = (value: boolean | undefined | null) => {
  return !!value;
};

const isAll = (value: boolean | undefined | null) => {
  return value === undefined || value === null;
};

const isNo = (value: boolean | undefined | null) => {
  return !isAll(value) && !value;
};

export const AdditionalFiltersOverlay = <T,>({
  customBottomFilters,
  options,
  filter,
  setFilter,
  defaultFilter,
  includeBottomBorder = true,
  twoColumns = false,
}: Props<T>) => {
  const updateFilter = useCallback(
    (option: Option<T>, value: boolean | null) => {
      setFilter({
        ...filter,
        ...option.filter(value),
      });
    },
    [filter, setFilter],
  );

  const resetFilter = useCallback(() => {
    if (defaultFilter) {
      setFilter(defaultFilter);
    }
  }, [defaultFilter, setFilter]);

  return (
    <Container data-testid="filters">
      <FilterHeader>
        <FormattedMessage id="FILTERS" tagName={FilterHeaderText} />
        <If isTrue={defaultFilter}>
          <ResetLink onClick={resetFilter}>
            <FormattedMessage id="RESET" />
          </ResetLink>
        </If>
      </FilterHeader>
      <ColumnsContainer className={twoColumns ? "flex-row gap-4" : "flex-col"}>
        <Col>
          {options.map((option) => (
            <FilterContainer key={option.id}>
              <Name>{option.title}</Name>
              <Configurations>
                <Configuration
                  $active={isYes(option.value(filter))}
                  onClick={() => updateFilter(option, true)}
                >
                  <FormattedMessage id="YES" />
                </Configuration>
                <Configuration
                  $active={isNo(option.value(filter))}
                  onClick={() => updateFilter(option, false)}
                >
                  <FormattedMessage id="NO" />
                </Configuration>
                <Configuration
                  $active={isAll(option.value(filter))}
                  onClick={() => updateFilter(option, null)}
                  $isDefault={true}
                >
                  <FormattedMessage id="NO_FILTER" />
                </Configuration>
              </Configurations>
            </FilterContainer>
          ))}
        </Col>
        <If isTrue={twoColumns}>
          <Col className="border-l border-dashed" />
        </If>
        <Col>
          {customBottomFilters && (
            <>
              <If isTrue={includeBottomBorder}>
                <DividerLine />
              </If>
              {customBottomFilters}
            </>
          )}
        </Col>
      </ColumnsContainer>
    </Container>
  );
};

AdditionalFiltersOverlay.Container = Container;
AdditionalFiltersOverlay.FilterHeader = FilterHeader;
AdditionalFiltersOverlay.FilterContainer = FilterContainer;
AdditionalFiltersOverlay.Name = Name;
AdditionalFiltersOverlay.Configurations = Configurations;
AdditionalFiltersOverlay.Configuration = Configuration;
AdditionalFiltersOverlay.isYes = isYes;
AdditionalFiltersOverlay.isNo = isNo;
AdditionalFiltersOverlay.isAll = isAll;
