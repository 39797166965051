import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import {
  HOLD_FOR_RELEASE_TEXT,
  LUMP_SUM_UOM,
  LUMP_SUM_UOM_PLURAL_DESCRIPTION,
} from "@/common/const";
import {
  ValidatorContextType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { hasProperty } from "@/common/utils/objectUtils";
import { pascalCaseToUnderscore } from "@/common/utils/stringUtils";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { OrgCatalogSku } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { getCellValue } from "../utils/getCellValue";
import { rowIsEmpty } from "../utils/rowIsEmpty";
import { useTableHelpers } from "./useTableHelpers";

export type ValidatorFn = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  callback: (valid: boolean) => void,
) => void;

export const useTableValidators = () => {
  const { setWarningAlert } = useSnackbar();
  const { getCellWithAdditionalData } = useTableHelpers();
  const {
    config,
    phaseCodeOptions,
    costTypeOptions,
    vendorOptions,
    spreadsheetData,
    invalidCells,
    setInvalidCells,
  } = useColumnMapper();
  const { costCodes } = useCostCodes();
  const { materials } = useMaterials();
  const allCostCodes = useMemo(
    () => costCodes.map((costCode) => costCode.formatted) ?? [],
    [costCodes],
  );

  const validateRequiredValues = useCallback(
    async (columns: COLUMN_TYPE[], filteredData?: Record<string, string>[]) => {
      await new Promise((resolve) => setTimeout(resolve, 0));
      const filteredRows = (filteredData ?? spreadsheetData).filter(
        (row) => !rowIsEmpty(row),
      );
      if (filteredRows.length === 0) {
        return true;
      }

      let currentInvalidCells = invalidCells;

      const missingValueColumn = columns.find((column) => {
        const hasMissingValues = filteredRows.some((row) => {
          const isLumpSumRow = isLumpSumUomText(row[COLUMN_TYPE.UOM]);

          const hasExtPriceColumn = config.some(
            (columnConfig) => columnConfig.columnType === COLUMN_TYPE.ExtPrice,
          );
          const columnToValidate =
            isLumpSumRow && column === COLUMN_TYPE.Quantity
              ? hasExtPriceColumn
                ? COLUMN_TYPE.ExtPrice
                : COLUMN_TYPE.PrefilledPrice
              : column;

          const missing = getCellValue(row, columnToValidate) === "";

          const columnConfig = config.find(
            (columnConfig) => columnConfig.columnType === columnToValidate,
          );
          const columnIsReadOnly = !!columnConfig?.readOnlyFn?.(row.id);

          if (missing && !columnIsReadOnly) {
            const rowIndex = spreadsheetData.findIndex((r) => r === row);
            const columnIndex = config.findIndex(
              (c) => c.columnType === columnToValidate,
            );
            currentInvalidCells = [
              ...currentInvalidCells,
              { row: rowIndex, col: columnIndex },
            ];
          }
          return missing && !columnIsReadOnly;
        });
        if (!hasMissingValues) {
          currentInvalidCells = [];
        }
        return hasMissingValues;
      });

      setInvalidCells(currentInvalidCells);

      const invalidColumn = (
        currentInvalidCells[0]
          ? config[currentInvalidCells[0].col].columnType
          : missingValueColumn
      ) as COLUMN_TYPE;

      if (invalidColumn) {
        setWarningAlert(
          <FormattedMessage
            id={`VALIDATION_ERROR_SHEETS_MISSING_${pascalCaseToUnderscore(invalidColumn)}`}
            values={{
              row:
                filteredRows.findIndex(
                  (r) => getCellValue(r, invalidColumn) === "",
                ) + 1,
              b: (...chunks) => <b>{chunks}</b>,
            }}
          />,
        );
        return false;
      }

      return true;
    },
    [spreadsheetData, config, invalidCells, setInvalidCells, setWarningAlert],
  );

  const checkIfValueInOptions = useCallback(
    (
      row: Record<string, string>,
      rowIndex: number,
      options: string[],
      column: COLUMN_TYPE,
    ) => {
      const value = getCellValue(row, column);
      if (value === "" || value == null || value === undefined) {
        return true;
      }

      if (!options.includes(value)) {
        setWarningAlert(
          <FormattedMessage
            id={`VALIDATION_ERROR_SHEETS_INVALID_${pascalCaseToUnderscore(column)}`}
            values={{
              b: (...chunks) => <strong>{chunks}</strong>,
              row: rowIndex + 1,
            }}
          />,
        );
        return false;
      }
      return true;
    },
    [setWarningAlert],
  );

  const checkIfValueInRange = useCallback(
    (
      row: Record<string, string>,
      rowIndex: number,
      column: COLUMN_TYPE,
      min?: number,
      max?: number,
    ) => {
      const value = Number(getCellValue(row, column));
      if (
        Number.isNaN(value) ||
        (min !== undefined && Number(value) < min) ||
        (max !== undefined && Number(value) > max)
      ) {
        setWarningAlert(
          <FormattedMessage
            id={`VALIDATION_ERROR_SHEETS_INVALID_${pascalCaseToUnderscore(column)}`}
            values={{
              b: (...chunks) => <strong>{chunks}</strong>,
              row: rowIndex + 1,
            }}
          />,
        );
        return false;
      }
      return true;
    },
    [setWarningAlert],
  );

  const checkIfDateInFuture = useCallback(
    (row: Record<string, string>, rowIndex: number, column: COLUMN_TYPE) => {
      const value = getCellValue(row, column);
      if (value && isNaN(new Date(value).getTime())) {
        setWarningAlert(
          <FormattedMessage
            id={`VALIDATION_ERROR_SHEETS_INVALID_${column.toUpperCase()}`}
            values={{
              b: (...chunks) => <strong>{chunks}</strong>,
              row: rowIndex + 1,
            }}
          />,
        );
        return false;
      }
      const date = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (date < today) {
        setWarningAlert(
          <FormattedMessage
            id={`VALIDATION_ERROR_SHEETS_${pascalCaseToUnderscore(column)}_IN_THE_PAST`}
            values={{
              b: (...chunks) => <strong>{chunks}</strong>,
              row: rowIndex + 1,
            }}
          />,
        );
        return false;
      }
      return true;
    },
    [setWarningAlert],
  );

  const checkIfDuplicateMaterialName = useCallback(
    (row: Record<string, string>, rowIndex: number) => {
      const name = getCellWithAdditionalData(row, COLUMN_TYPE.MaterialName);
      const customPartNumber = getCellValue(row, COLUMN_TYPE.CustomPartNumber);
      const duplicate = materials.some(
        (material) =>
          (!row.id || row.id !== material.id) &&
          name === material.material.name &&
          customPartNumber ===
            (material.material as OrgCatalogSku).customPartNumber &&
          !isMasterSku(material.material) &&
          !isProductSku(material.material),
      );

      if (duplicate) {
        setWarningAlert(
          <FormattedMessage
            id={`VALIDATION_ERROR_SHEETS_MATERIAL_NAME_DUPLICATE`}
            values={{
              name,
              row: rowIndex + 1,
              b: (...chunks) => <strong>{chunks}</strong>,
            }}
          />,
        );
        const columnIndex = config.findIndex(
          (c) => c.columnType === COLUMN_TYPE.MaterialName,
        );
        setInvalidCells([{ row: rowIndex, col: columnIndex }]);
        return false;
      }
      return true;
    },
    [
      config,
      getCellWithAdditionalData,
      materials,
      setInvalidCells,
      setWarningAlert,
    ],
  );

  const checkIfDuplicateCode = useCallback(
    (row: Record<string, string>, rowIndex: number, col: COLUMN_TYPE) => {
      const value = getCellValue(row, col);
      const duplicate =
        value &&
        row.id &&
        spreadsheetData.filter((r) => {
          return getCellWithAdditionalData(r, col) === value;
        }).length > 1;

      if (duplicate) {
        setWarningAlert(
          <FormattedMessage
            id={`VALIDATION_ERROR_SHEETS_${pascalCaseToUnderscore(col)}_DUPLICATE`}
            values={{
              name: value,
              row: rowIndex + 1,
              b: (...chunks) => <strong>{chunks}</strong>,
            }}
          />,
        );
        const columnIndex = config.findIndex((c) => c.columnType === col);
        setInvalidCells([{ row: rowIndex, col: columnIndex }]);
        return false;
      }
      return true;
    },
    [
      config,
      getCellWithAdditionalData,
      setInvalidCells,
      setWarningAlert,
      spreadsheetData,
    ],
  );

  const checkIfDeliveryDateValid = useCallback(
    (row: Record<string, string>, rowIndex: number, column: COLUMN_TYPE) => {
      const value = getCellValue(row, column);
      const valid =
        !value ||
        value === HOLD_FOR_RELEASE_TEXT ||
        !isNaN(new Date(value).getTime());
      if (!valid) {
        setWarningAlert(
          <FormattedMessage
            id={`VALIDATION_ERROR_SHEETS_INVALID_${pascalCaseToUnderscore(column)}`}
            values={{
              name: value,
              row: rowIndex + 1,
              b: (...chunks) => <strong>{chunks}</strong>,
            }}
          />,
        );
      }
      return valid;
    },
    [setWarningAlert],
  );

  const checkIfLumpSumUom = useCallback(
    (row: Record<string, string>, rowIndex: number, column: COLUMN_TYPE) => {
      const value = getCellValue(row, column);
      const valid =
        value?.toUpperCase() !== LUMP_SUM_UOM &&
        value?.toUpperCase() !== LUMP_SUM_UOM_PLURAL_DESCRIPTION;
      if (!valid) {
        setWarningAlert(
          <FormattedMessage
            id={`VALIDATION_ERROR_SHEETS_LUMP_SUM_NOT_SUPPORTED`}
            values={{
              row: rowIndex + 1,
            }}
          />,
        );
      }
      return valid;
    },
    [setWarningAlert],
  );

  const validateRowValues = useCallback(
    async (
      columns: COLUMN_TYPE[],
      filteredData?: Record<string, string>[],
      params?: {
        minPrice?: number;
        prohibitLsUom?: boolean;
      },
    ) => {
      await new Promise((resolve) => setTimeout(resolve, 0));

      const filteredRows = (filteredData ?? spreadsheetData).filter(
        (row) => !rowIsEmpty(row),
      );

      const minPrice =
        params && hasProperty(params, "minPrice") ? params.minPrice : 0;

      return filteredRows.every((row, index) =>
        columns.every((column) => {
          switch (column) {
            case COLUMN_TYPE.MaterialName:
              return checkIfDuplicateMaterialName(row, index);
            case COLUMN_TYPE.Quantity:
              return checkIfValueInRange(row, index, column);
            case COLUMN_TYPE.PositiveQuantity:
              return checkIfValueInRange(row, index, column, 0);
            case COLUMN_TYPE.UnitPrice:
            case COLUMN_TYPE.PrefilledPrice:
              return minPrice
                ? checkIfValueInRange(row, index, column, minPrice)
                : true;
            case COLUMN_TYPE.CostCode:
              return checkIfValueInOptions(row, index, allCostCodes, column);
            case COLUMN_TYPE.PhaseCode:
              return checkIfValueInOptions(
                row,
                index,
                phaseCodeOptions,
                column,
              );
            case COLUMN_TYPE.CostType:
              return checkIfValueInOptions(row, index, costTypeOptions, column);
            case COLUMN_TYPE.Vendor:
              return checkIfValueInOptions(row, index, vendorOptions, column);
            case COLUMN_TYPE.ExpirationDate:
              return checkIfDateInFuture(row, index, column);
            case COLUMN_TYPE.OrderIncrement:
            case COLUMN_TYPE.MinimumOrder:
            case COLUMN_TYPE.LeadTime:
              return checkIfValueInRange(row, index, column, 0);
            case COLUMN_TYPE.Code:
              return checkIfDuplicateCode(row, index, column);
            case COLUMN_TYPE.DeliveryDate:
              return checkIfDeliveryDateValid(row, index, column);
            case COLUMN_TYPE.UOM:
              return params?.prohibitLsUom
                ? checkIfLumpSumUom(row, index, column)
                : true;
            default:
              return true;
          }
        }),
      );
    },
    [
      spreadsheetData,
      checkIfDuplicateMaterialName,
      checkIfValueInRange,
      checkIfValueInOptions,
      allCostCodes,
      phaseCodeOptions,
      costTypeOptions,
      vendorOptions,
      checkIfDateInFuture,
      checkIfDuplicateCode,
      checkIfDeliveryDateValid,
      checkIfLumpSumUom,
    ],
  );

  const combineValidators = useCallback(
    (validators: ValidatorFn[]): ValidatorFn => {
      return function (
        this: ValidatorContextType,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any,
        callback: (valid: boolean) => void,
      ): void {
        let index = 0;

        const nextValidator = (isValid: boolean) => {
          if (!isValid || index >= validators.length) {
            callback(isValid);
            return;
          }

          validators[index++].call(this, value, nextValidator);
        };

        nextValidator(true);
      };
    },
    [],
  );

  const requiredValidator: ValidatorFn = useCallback(
    function (this: ValidatorContextType, value, callback) {
      const { row, col } = this;
      const isMarked = invalidCells.some(
        (cell) => cell.row === row && cell.col === col,
      );

      if (value === null || value === undefined || value === "") {
        callback(!isMarked);
      } else {
        callback(true);
      }
    },
    [invalidCells],
  );

  const vendorValidator: ValidatorFn = useCallback(
    function (value, callback) {
      if (value === null || value === undefined || value === "") {
        callback(true);
      } else {
        callback(
          vendorOptions.find((option) => option === value) !== undefined,
        );
      }
    },
    [vendorOptions],
  );

  const costCodeValidator: ValidatorFn = useCallback(
    function (value, callback) {
      const options = costCodes.map((c) => c.formatted);
      if (value === null || value === undefined || value === "") {
        callback(true);
      } else {
        callback(
          options.find((option) => option === value.toString()) !== undefined,
        );
      }
    },
    [costCodes],
  );

  const costTypeValidator: ValidatorFn = useCallback(
    function (value, callback) {
      if (value === null || value === undefined || value === "") {
        callback(true);
      } else {
        callback(
          costTypeOptions.find((option) => option === value) !== undefined,
        );
      }
    },
    [costTypeOptions],
  );

  const phaseCodeValidator: ValidatorFn = useCallback(
    function (value, callback) {
      if (value === null || value === undefined || value === "") {
        callback(true);
      } else {
        callback(
          phaseCodeOptions.find((option) => option === value) !== undefined,
        );
      }
    },
    [phaseCodeOptions],
  );

  const dateInFutureValidator: ValidatorFn = useCallback(function (
    value,
    callback,
  ) {
    if (value === null || value === undefined || value === "") {
      callback(true);
    } else {
      const date = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      callback(date >= today);
    }
  }, []);

  const uniqueCodeValidator: ValidatorFn = useCallback(
    function (this: ValidatorContextType, value, callback) {
      const { row } = this;
      if (value === null || value === undefined || value === "") {
        callback(true);
      } else {
        const rowData = spreadsheetData[row];
        if (!rowData) {
          callback(true);
          return;
        }
        const code = getCellValue(rowData, COLUMN_TYPE.Code);
        const duplicate =
          !!code &&
          rowData.id &&
          spreadsheetData.filter((r) => {
            return getCellValue(r, COLUMN_TYPE.Code) === code;
          }).length > 1;

        callback(!duplicate);
      }
    },
    [spreadsheetData],
  );

  const uniqueCodeDescriptionValidator: ValidatorFn = useCallback(
    function (this: ValidatorContextType, value, callback) {
      const { row } = this;
      if (value === null || value === undefined || value === "") {
        callback(true);
      } else {
        const rowData = spreadsheetData[row];
        if (!rowData) {
          callback(true);
          return;
        }
        const code = getCellValue(rowData, COLUMN_TYPE.CodeDescription);
        const duplicate =
          !!code &&
          rowData.id &&
          spreadsheetData.filter((r) => {
            return getCellValue(r, COLUMN_TYPE.CodeDescription) === code;
          }).length > 1;

        callback(!duplicate);
      }
    },
    [spreadsheetData],
  );

  const uniqueMaterialValidator: ValidatorFn = useCallback(
    function (this: ValidatorContextType, value, callback) {
      const { row } = this;
      if (value === null || value === undefined || value === "") {
        callback(true);
      } else {
        const duplicate = materials.some(
          (material) =>
            spreadsheetData &&
            spreadsheetData[row] &&
            (!spreadsheetData[row].id ||
              spreadsheetData[row].id !== material.id) &&
            value === material.material.name &&
            (!isOrgCatalogSku(material.material) ||
              spreadsheetData[row][COLUMN_TYPE.CustomPartNumber] ===
                (material.material as OrgCatalogSku).customPartNumber) &&
            !isMasterSku(material.material) &&
            !isProductSku(material.material),
        );

        callback(!duplicate);
      }
    },
    [materials, spreadsheetData],
  );

  const positiveNumberValidator: ValidatorFn = useCallback(function (
    value,
    callback,
  ) {
    if (value === null || value === undefined || value === "") {
      callback(true);
    } else {
      callback(!Number.isNaN(Number(value)) && Number(value) >= 0);
    }
  }, []);

  const deliveryDateValidator: ValidatorFn = useCallback(function (
    value,
    callback,
  ) {
    if (value === null || value === undefined || value === "") {
      callback(true);
    } else {
      callback(
        !isNaN(new Date(value).getTime()) || value === HOLD_FOR_RELEASE_TEXT,
      );
    }
  }, []);

  const isNotLumpSumUomValidator: ValidatorFn = useCallback(function (
    value,
    callback,
  ) {
    callback(
      String(value).toUpperCase() !== LUMP_SUM_UOM &&
        String(value).toUpperCase() !== LUMP_SUM_UOM_PLURAL_DESCRIPTION,
    );
  }, []);

  const uniqueValidator: ValidatorFn = useCallback(
    function (this: ValidatorContextType, value, callback) {
      const { prop } = this;
      if (value === null || value === undefined || value === "" || !prop) {
        callback(true);
      } else {
        const duplicate =
          spreadsheetData.filter((r) => {
            return r[prop] === value;
          }).length > 1;
        callback(!duplicate);
      }
    },
    [spreadsheetData],
  );

  return {
    combineValidators,
    validateRequiredValues,
    validateRowValues,
    positiveNumberValidator,
    requiredValidator,
    uniqueValidator,
    vendorValidator,
    costCodeValidator,
    costTypeValidator,
    phaseCodeValidator,
    dateInFutureValidator,
    deliveryDateValidator,
    uniqueMaterialValidator,
    uniqueCodeValidator,
    uniqueCodeDescriptionValidator,
    isNotLumpSumUomValidator,
  };
};
