import { RefObject, useEffect, useState } from "react";

const useIsEllipsisVisibleHook = (ref: RefObject<HTMLElement | null>) => {
  const [ellipsisVisible, setEllipsisVisible] = useState(false);

  const isEllipsisVisible = (e: {
    offsetHeight: number;
    scrollHeight: number;
    offsetWidth: number;
    scrollWidth: number;
  }) => {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  };

  useEffect(() => {
    if (ref?.current && isEllipsisVisible(ref.current)) {
      setEllipsisVisible(true);
    } else {
      setEllipsisVisible(false);
    }
  }, [ref]);

  return { isEllipsisVisible: ellipsisVisible };
};

export default useIsEllipsisVisibleHook;
