import {
  OrgRolesWrapper,
  Permission,
} from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { StateSelectorControlled } from "../../../../../../../common/components/state-selector/StateSelectorControlled";
import { ProjectAddress2TextField } from "../input-components/ProjectAddress2TextField";
import { ProjectAddressCityTextField } from "../input-components/ProjectAddressCityTextField";
import { ProjectAddressPostalCodeTextField } from "../input-components/ProjectAddressPostalCodeTextField";
import { ProjectAddressTextField } from "../input-components/ProjectAddressTextField";
import { ProjectBudgetTextField } from "../input-components/ProjectBudgetTextField";
import { ProjectCountrySelector } from "../input-components/ProjectCountrySelector";
import { ProjectJobDatePicker } from "../input-components/ProjectJobDatePicker";
import { ProjectJobNumberTextField } from "../input-components/ProjectJobNumberTextField";
import { ProjectLocationSelector } from "../input-components/ProjectLocationSelector";
import { ProjectNameTextField } from "../input-components/ProjectNameTextField";
import { ProjectStatusSelector } from "../input-components/ProjectStatusSelector";
import { ProjectTaxExempt } from "../input-components/ProjectTaxExempt";
import { ProjectUsersSelector } from "../input-components/ProjectUsersSelector";
import { ProjectTemplateSelector } from "./ProjectTemplateSelector";

const Container = tw.div`w-full flex flex-col gap-4`;
const Row = tw.div`grid gap-1
${({ cols }: { cols?: number }) => cols === 2 && "grid-cols-[_1fr_170px]"}
${({ cols }: { cols?: number }) => cols === 4 && "grid-cols-[_1fr_70px_120px_100px]"}
`;

type NewProjectFormProps = {
  calendarOpened?: boolean;
  setCalendarOpened?: (opened: boolean) => void;
};

export const ProjectFormProjectInformation: FC<NewProjectFormProps> = ({
  calendarOpened,
  setCalendarOpened,
}) => {
  return (
    <Container
      onClick={(ev: { stopPropagation: () => void }) => {
        ev.stopPropagation();
      }}
    >
      <Row>
        <ProjectLocationSelector name="locationId" />
      </Row>
      <Row cols={2}>
        <ProjectNameTextField name="projectName" />
        <ProjectJobNumberTextField name="jobNumber" />
      </Row>
      <Row>
        <ProjectStatusSelector name="status" isNewProject />
      </Row>
      <Row>
        <ProjectAddressTextField name="address" />
      </Row>
      <Row>
        <ProjectAddress2TextField name="address2" />
      </Row>
      <Row cols={2}>
        <ProjectAddressCityTextField name="city" />
        <StateSelectorControlled name="state" countryInputName="country" />
      </Row>
      <Row cols={2}>
        <ProjectAddressPostalCodeTextField
          name="postalCode"
          countryInputName="country"
        />
        <ProjectCountrySelector name="country" />
      </Row>
      <Row>
        <ProjectUsersSelector name="projectUsers" />
      </Row>
      <Row>
        <ProjectJobDatePicker
          name="jobStartDate"
          open={calendarOpened}
          setOpen={setCalendarOpened}
        />
      </Row>
      <OrgRolesWrapper permissions={[Permission.canViewPrices]}>
        <Row>
          <ProjectBudgetTextField name="budget" />
        </Row>
      </OrgRolesWrapper>
      <ProjectTemplateSelector name="templateProjectId" />
      <ProjectTaxExempt name="taxExempt" />
    </Container>
  );
};
