import { InvoiceToExportFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { InvoiceReleaseDetails } from "../invoice-release-details/InvoiceReleaseDetails";
import { useInvoiceExportInformation } from "./hooks/useInvoiceExportInformation";

type Props = {
  invoice: InvoiceToExportFieldsFragment;
};

export const InvoiceExportInformation: FC<Props> = ({ invoice }) => {
  const { includedValidations } = useInvoiceExportInformation({ invoice });

  return (
    <InvoiceReleaseDetails
      invoice={invoice}
      includedValidations={includedValidations}
      poNumber={invoice.release?.poNumber || ""}
    />
  );
};
