import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import {
  ItemContainer,
  SalesReadonlyTaxLabel,
  SalesTaxLabel,
  SalesTaxValue,
} from "@/common/components/sales-tax-input/styles/SalesTaxInput.styles";
import { UpdateSalesTaxFunction } from "@/common/components/sales-tax-input/types/SalesTaxInputProps";
import { useUser } from "@/common/providers/UserProvider";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useSalesTaxVarianceInput } from "./hooks/useSalesTaxVarianceInput";

const ItemContainerStyled = tw(ItemContainer)`relative`;
const PricePickerStyled = tw(PricePicker)`w-full text-right text-sm justify-end
${(props) => props.readonly && "bg-transparent"}`;
const StyledSalesTaxValue = tw(SalesTaxValue)`flex flex-row items-center`;
const IconContainer = tw.div`absolute right-4`;

type Props = {
  value: string | number | null;
  setValue: (value: string | null) => void;
  update?: UpdateSalesTaxFunction;
  readonly?: boolean;
  classes?: {
    container?: string;
    label?: string;
  };
  inlineEditTaxVariance?: boolean;
};

export const SalesTaxVarianceInput: React.FC<Props> = ({
  setValue,
  value,
  update,
  readonly,
  classes,
  inlineEditTaxVariance,
}) => {
  const intl = useIntl();
  const { editing, setEditing } = useSalesTaxVarianceInput();
  const { currencySymbol, isContractor } = useUser();
  const onBlur = useCallback(
    async (newValue: string | null) => {
      const value = isNaN(parseFloat(newValue || "")) ? null : newValue;
      setValue(newValue);
      const input = {
        taxVariance: value,
        ...(isContractor && { clearTaxVariance: !value }),
        ...(editing && { triggerUpdate: true }),
      };

      if (editing) {
        setEditing(false);
      }
      await update?.(input);
    },
    [setValue, isContractor, editing, update, setEditing],
  );
  return (
    <ItemContainerStyled className={classes?.container}>
      <SalesTaxLabel className={classes?.label}>
        <FormattedMessage
          id="SALES_TAX_VARIANCE"
          tagName={SalesReadonlyTaxLabel}
        />
        <InfoTooltip message={intl.$t({ id: "SALES_TAX_VARIANCE_TOOLTIP" })} />
      </SalesTaxLabel>
      <StyledSalesTaxValue>
        <PricePickerStyled
          testId="tax-variance"
          value={value}
          onBlur={onBlur}
          decimals={2}
          placeholder={intl.$t(
            { id: "SALES_TAX_VARIANCE_PLACEHOLDER" },
            { currency: currencySymbol },
          )}
          readonly={readonly && !editing}
          InputProps={{
            endAdornment: (
              <>
                <If isTrue={inlineEditTaxVariance && editing}>
                  <LinkLike onClick={() => onBlur((value || "0").toString())}>
                    <CheckOutlined />
                  </LinkLike>
                  <LinkLike onClick={() => setEditing(false)}>
                    <CloseOutlined />
                  </LinkLike>
                </If>
              </>
            ),
          }}
        />
        <If isTrue={inlineEditTaxVariance && !editing}>
          <IconContainer>
            <LinkLike>
              <EditOutlined onClick={() => setEditing(true)} fontSize="small" />
            </LinkLike>
          </IconContainer>
        </If>
      </StyledSalesTaxValue>
    </ItemContainerStyled>
  );
};
