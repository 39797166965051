import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { getFileName } from "@/common/components/upload-asset/getFileName";
import { InvoiceEmailFieldsFragment } from "@/generated/graphql";
import { Attachment } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`w-full flex flex-col gap-1 text-xs`;
const Item = tw.div`flex flex-row items-center gap-2`;
const ItemText = tw.div`truncate text-wrap`;
const AttachmentIcon = tw(Attachment)`text-gray-500`;

type Props = {
  invoiceEmail: InvoiceEmailFieldsFragment;
};

export const InvoiceEmailFiles: FC<Props> = ({ invoiceEmail }) => {
  return (
    <NotNullableRenderer value={invoiceEmail.attachments.length > 0}>
      <Container>
        {invoiceEmail.attachments.map((attachment, key) => (
          <Item key={key}>
            <AttachmentIcon />
            <ItemText>{getFileName(attachment.asset.url)}</ItemText>
          </Item>
        ))}
      </Container>
    </NotNullableRenderer>
  );
};
