import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useUser } from "@/common/providers/UserProvider";
import { useStartupDataStore } from "@/common/stores/useStartupDataStore";
import { useCallback, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";

export const useOrgSettings = () => {
  const { viewer } = useUser();
  const {
    error,
    settings,
    refetch,
    connectedIntegrationType,
    connectedSourceSystem,
    hasPhaseCodes,
    hasAdditionalCharges,
    hasTaxVariance,
    loading,
  } = useStartupDataStore(
    useShallow((state) => ({
      viewer: state.viewer,
      loading: state.loading,
      error: state.error,
      refetch: state.fetchStartupData,
      settings: state.settings,
      connectedIntegrationType: state.connectedIntegrationType,
      connectedSourceSystem: state.connectedSourceSystem,
      hasPhaseCodes: state.hasPhaseCodes,
      hasAdditionalCharges:
        state.settings?.display?.enableAdditionalCharges || false,
      hasTaxVariance: state.settings?.display?.enableTaxVariance || false,
    })),
  );
  useErrorEffect(error);

  const refetchSettings = useCallback(async () => {
    await refetch(true);
  }, [refetch]);

  const connectedSourceSystemSettings = useMemo(
    () => settings?.integrations.sourceSystems.find((s) => s.connected),
    [settings?.integrations.sourceSystems],
  );

  return {
    orgId: viewer?.org?.id || "",
    settings,
    loading,
    connectedIntegrationType,
    connectedSourceSystem,
    connectedSourceSystemSettings,
    refetchSettings,
    hasPhaseCodes,
    hasAdditionalCharges,
    hasTaxVariance,
  };
};
