import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { routes } from "@/config/routes";
import { DistributorBuyoutReleaseFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { ListConfiguration } from "./ListConfiguration";

const ListContainer = tw.div`
  flex flex-col mb-30 md:mb-0
`;

type Props = {
  releases: DistributorBuyoutReleaseFieldsFragment[];
};

export const DistributorReleasesList: FC<Props> = ({ releases }) => {
  const navigate = useNavigate();

  const goToRelease = useCallback(
    (release: DistributorBuyoutReleaseFieldsFragment) => {
      if (release.issues.length > 0) {
        navigate(
          generatePath(routes.distributorDeliveryRecord, {
            deliveryId: release.id,
          }) + location.search,
        );
      } else {
        navigate(
          generatePath(routes.distributorDelivery, { deliveryId: release.id }) +
            location.search,
        );
      }
    },
    [navigate],
  );

  return (
    <ListRenderer
      hasItemsCondition={releases && releases.length > 0}
      emptyList={<NoResults translationKey="DISTRIBUTOR_NO_RELEASES" />}
    >
      <GridTable<DistributorBuyoutReleaseFieldsFragment>
        configuration={{
          container: ListContainer,
          columns: ListConfiguration,
          classNames: {
            header: "top-[32px]",
            category: "xl:top-[102px] top-[36px]",

            details: "justify-end",
          },
          toggle: {
            item: goToRelease,
          },
        }}
        items={releases}
      />
    </ListRenderer>
  );
};
