import { DecimalSafe } from "@/common/utils/decimalSafe";
import { extPriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";

export const sumAuxiliaryQuoteFullPrices = (
  acc: number,
  item: {
    unitPrice?: string | null | undefined;
    quantityDecimal?: string | null;
  },
) => {
  const unitPrice = new DecimalSafe(item.unitPrice || 0).toString();

  return new DecimalSafe(
    extPriceCalculation(item.quantityDecimal || 0, unitPrice),
  )
    .add(new DecimalSafe(acc))
    .toNumber();
};
