import { useProjectPredictedPoNumber } from "@/common/components/po-number/hooks/useProjectPredictedPoNumber";
import { useUser } from "@/common/providers/UserProvider";
import { PoNumberingMode } from "@/generated/graphql";
import { useMemo } from "react";
import { InvoiceOrderMagicWandProps } from "../types/InvoiceOrderMagicWandProps";

export const useInvoiceOrderMagicWand = ({
  poNumber,
  projectId,
}: Pick<InvoiceOrderMagicWandProps, "poNumber" | "projectId">) => {
  const { poNumbering } = useUser();
  const { predictedPoNumber } = useProjectPredictedPoNumber(projectId || "");
  const hidden = useMemo(
    () =>
      poNumber ||
      poNumbering === PoNumberingMode.Never ||
      (!predictedPoNumber && projectId),
    [poNumber, poNumbering, predictedPoNumber, projectId],
  );

  return { hidden };
};
