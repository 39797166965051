import { OverlayPanel } from "@/common/components/panel/OverlayPanel";

import { If } from "@/common/components/if/If";
import { LocationSelectorControlled } from "@/common/components/location-selector/LocationSelectorControlled";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { MultiselectControlled } from "@/common/components/select/components/multiple/MultiselectControlled";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useProjectListOptions } from "@/common/hooks/useProjectListOptions";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { routes } from "@/config/routes";
import {
  RfqCreationProvider,
  useRfqCreation,
} from "@/contractor/pages/home/rfq-master-catalog-import/components/RfqCreationProvider";
import {
  AssetFieldsFragment,
  ProjectFieldsFragment,
  RfqFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import tw from "tailwind-styled-components";
import { NoteDocumentImport } from "../../common/note-document/components/NoteDocumentImport";
import {
  NoteDocumentProvider,
  useNoteDocument,
} from "../../common/note-document/providers/NoteDocumentProvider";
import { NewRfqFormType } from "./NewRfqFormType";

enum NewRfqType {
  CreateNew = "createNew",
  ImportNote = "note",
}

const tabs = [
  {
    label: <FormattedMessage id="CREATE_NEW" />,
    viewState: NewRfqType.CreateNew,
  },
  {
    label: <FormattedMessage id="IMPORT_FROM_NOTE" values={{ br: <br /> }} />,
    viewState: NewRfqType.ImportNote,
  },
];

type RfqFormProps = {
  label: string;
  onClose: () => void;
  rfq?: RfqFieldsFragment | undefined | null;
  project?: ProjectFieldsFragment | null;
  hideVendors?: boolean;
};

const VendorTooltip = tw.div`max-w-[400px]`;
const FormContainer = tw.div`flex flex-col w-full gap-5 pt-5`;

const RfqFormWithProvider: FC<RfqFormProps> = ({
  onClose,
  label,
  rfq,
  project,
}) => {
  const { vendors, shouldShowVendorCode } = useVendors();
  const { noteDocument, createNoteDocumentWithAsset } = useNoteDocument();
  const { createRfq } = useRfqCreation();
  const [asset, setAsset] = useState<AssetFieldsFragment | null>(null);
  const { projects } = useProjectListOptions();
  const { locations } = useUserLocations();
  const [view, setView] = useState(NewRfqType.CreateNew);
  const intl = useIntl();
  const [preferredVendors, setPreferredVendors] =
    useState<Array<string> | null>(
      rfq?.targetSellersOrgLocations?.map((a) => a.id) || [],
    );
  const navigate = useNavigate();

  const { handleSubmit, setValue, watch, ...formProps } = useForm({
    defaultValues: {
      locationId: "",
      projectId: project?.id || "",
      vendorIds: [],
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const onTabChange = useCallback(
    (viewState: string) => {
      setView(viewState as NewRfqType);
    },
    [setView],
  );

  const onSubmit = useCallback(
    async (values: NewRfqFormType) => {
      const { projectId, vendorIds } = values;
      const result = await createRfq({
        projectId,
        noteDocumentId: noteDocument?.id,
        vendorLocationIds: vendorIds,
        clientCustomNumber: values.clientCustomNumber,
      });
      if (result) {
        navigate(generatePath(routes.rfqById, { id: result.id }));
      }
    },
    [createRfq, navigate, noteDocument],
  );

  const createFromNote = useCallback(
    async (values: NewRfqFormType) => {
      const location =
        locations.length === 1
          ? locations[0].id
          : values.locationId || project?.location.id || "";
      const result = await createNoteDocumentWithAsset(location);

      if (result) {
        navigate({
          pathname: generatePath(routes.rfqFromNote, {
            noteDocumentId: result?.id,
          }),
        });
      }
    },
    [createNoteDocumentWithAsset, navigate, locations, project?.location.id],
  );

  const checkStatus = useCallback(
    (statuses: RfqStatus[]) => {
      if (!rfq) {
        return true;
      }
      if (rfq.deletedAt) {
        return false;
      }

      return statuses.includes(rfq.status);
    },
    [rfq],
  );

  const vendorIds = watch("vendorIds");
  const isAllRequiredFieldsPresented = useCallback(() => {
    return vendorIds?.length !== 0;
  }, [vendorIds]);

  return (
    <OverlayPanel
      title={label}
      onSave={
        view === NewRfqType.CreateNew
          ? handleSubmit(onSubmit)
          : handleSubmit(createFromNote)
      }
      disableSave={
        view === NewRfqType.ImportNote
          ? !asset
          : !isAllRequiredFieldsPresented()
      }
      onCancel={onClose}
      tabs={tabs}
      activeTab={view}
      flexDirectionRow
      onTabChange={onTabChange}
    >
      <FormProvider
        {...formProps}
        handleSubmit={handleSubmit}
        setValue={setValue}
        watch={watch}
      >
        <FormContainer>
          <If isTrue={locations && locations.length !== 1 && !project?.id}>
            <LocationSelectorControlled />
          </If>
          <If isTrue={view === NewRfqType.CreateNew}>
            <SelectControlled
              name="projectId"
              placeholder={intl.$t({
                id:
                  rfq || project?.id
                    ? "PROJECT_NAME"
                    : "PROJECT_SELECT_YOUR_PROJECT",
              })}
              testId="rfq-form-projects-selector"
              options={projects}
              getLabel={(option) => getProjectSelectorLabel(option)}
              getValue={(option) => option.id}
              required
              noOptionsText={intl.$t({
                id: "NO_PROJECTS_IN_LOCATION",
              })}
              staticText={!!rfq || !!project?.id}
            />
            <MultiselectControlled
              name="vendorIds"
              placeholder={intl.$t({ id: "PREFERRED_VENDORS" })}
              options={vendors || []}
              customRender={(item) =>
                VendorPickerCustomRender(item, {
                  filter: (c) => c.receivesQuoteNotifications,
                  shouldShowVendorCode,
                })
              }
              values={preferredVendors}
              onMultipleChange={setPreferredVendors}
              getLabel={(o) =>
                vendorLabelFormatter(
                  o.sellerOrgLocation,
                  o.contacts.filter((c) => c.receivesQuoteNotifications),
                  {
                    vendorCode: shouldShowVendorCode ? o.externalCode : null,
                  },
                )
              }
              testId="rfq-form-vendor-selector"
              getValue={(option) => option.sellerOrgLocation.id}
              disableCloseOnSelect
              includeCheckboxFn={(option) => option.contacts.length > 0}
              optionDisabledFn={(option) => option.contacts.length === 0}
              optionTooltipRenderFn={(option) =>
                option.contacts.length === 0 ? (
                  <VendorTooltip>
                    {intl.$t({ id: "VENDOR_DISABLED_TOOLTIP" })}
                  </VendorTooltip>
                ) : undefined
              }
              required
              staticText={
                !checkStatus([RfqStatus.Draft, RfqStatus.Active]) ||
                !!rfq?.nextRevision
              }
            />
          </If>
          <If isTrue={view === NewRfqType.ImportNote}>
            <NoteDocumentImport onChange={setAsset} />
          </If>
        </FormContainer>
      </FormProvider>
    </OverlayPanel>
  );
};

export const RfqForm: FC<RfqFormProps> = (props) => (
  <NoteDocumentProvider>
    <RfqCreationProvider>
      <RfqFormWithProvider {...props} />
    </RfqCreationProvider>
  </NoteDocumentProvider>
);
