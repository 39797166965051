import { useDialog } from "@/common/components/dialog/DialogProvider";
import { RfqStatus } from "@/generated/graphql";
import { ReactElement, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useRfqs } from "../../providers/RfqsProvider";
import { useArchiveRfqs } from "../hooks/useArchiveRfqs";
import { useRfqSequence } from "../hooks/useRfqSequence";

const RFQ_DELETE_STATUSES = [RfqStatus.Draft, RfqStatus.Cancelled];

type Props = {
  deleteIcon: ReactElement;
};

export const useRfqsSideActions = ({ deleteIcon }: Props) => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useRfqSequence();
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { refetch, filter } = useRfqs();
  const { archiveRfqs } = useArchiveRfqs();

  const deleteItems = useCallback(() => {
    const removableItems = selectedEntities.filter(
      (quote) => !quote.deletedAt && RFQ_DELETE_STATUSES.includes(quote.status),
    );
    if (removableItems.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        includeWarningIcon: true,
        title: intl.$t({ id: "DELETE_CONFIRMATION" }),
        text:
          removableItems.length === 1 && removableItems[0]?.id
            ? intl.$t(
                { id: "DELETE_RFQ_MESSAGE" },
                {
                  quoteNumber: removableItems[0].clientIdentifier,
                },
              )
            : intl.$t(
                { id: "DELETE_RFQS_MESSAGE" },
                { number: removableItems.length },
              ),
        handleConfirm: async () => {
          if (await archiveRfqs(removableItems.map((release) => release.id))) {
            setSelectedEntities([]);
            refetch();
          }
        },
      });
    }
  }, [
    selectedEntities,
    openDialog,
    intl,
    archiveRfqs,
    setSelectedEntities,
    refetch,
  ]);

  const actions = useMemo(() => {
    const sideActions = [];

    if (
      selectedEntities.some(
        (quote) =>
          !quote.deletedAt && RFQ_DELETE_STATUSES.includes(quote.status),
      ) &&
      filter?.deleted !== true
    ) {
      sideActions.push({
        icon: deleteIcon,
        tooltipKey: "DELETE_ITEMS",
        callback: deleteItems,
      });
    }

    return sideActions;
  }, [selectedEntities, filter?.deleted, deleteIcon, deleteItems]);

  return { sequenceIds, actions };
};
