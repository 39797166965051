import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { routes } from "@/config/routes";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import {
  BuyoutReleasesFieldsFragment,
  BuyoutStatus,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router";
import { ButtonContainer } from "../../../buyout/Buyout.styles";
import { BuyoutCancelButton } from "../../../buyout/components/common/BuyoutCancelButton";

export const BuyoutReleasesFooter: FC<{
  buyout: BuyoutReleasesFieldsFragment;
}> = ({ buyout }) => {
  const navigate = useNavigate();

  const { createReservedRelease } = useCreateRelease();

  const handleRequestDelivery = useCallback(async () => {
    if (buyout?.id) {
      const result = await createReservedRelease({
        buyoutId: buyout.id,
        typeId: buyout.releaseType.id,
      });
      if (result) {
        navigate(
          generatePath(routes.specifyDeliveryDetails, {
            deliveryId: result.id,
          }),
        );
      }
    }
  }, [buyout.id, buyout.releaseType.id, createReservedRelease, navigate]);

  return (
    <If
      isTrue={
        ![BuyoutStatus.Depleted, BuyoutStatus.Cancelled].includes(buyout.status)
      }
    >
      <FloatingFooter>
        <ButtonContainer>
          <If isTrue={buyout.status === BuyoutStatus.Active}>
            <BuyoutCancelButton buyout={buyout} />
            <OutlinedButton
              onClick={handleRequestDelivery}
              testId="create-release-button"
            >
              <FormattedMessage id="CREATE_DELIVERY" />
            </OutlinedButton>
          </If>
        </ButtonContainer>
      </FloatingFooter>
    </If>
  );
};
