import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { Base } from "@/common/layout/ResponsiveClasses";
import { SpreadSheetConfig } from "@/common/providers/ColumnMapperProvider";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

export const BuyoutRfqItemContainer = tw(Base)`col-span-full flex-1`;

export const useBuyoutItemsListConfiguration = () => {
  const intl = useIntl();

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: "projectItem.material",
        columnType: COLUMN_TYPE.Material,
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer.name",
        columnType: COLUMN_TYPE.Manufacturer,
      },
      {
        header: intl.$t({ id: "TAG" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.Tag,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "projectItem.estimateUom.pluralDescription",
        columnType: COLUMN_TYPE.UOM,
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.Quantity,
      },
      {
        header: intl.$t({ id: "PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.PrefilledPrice,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode.code",
        columnType: COLUMN_TYPE.CostCode,
      },
      {
        header: intl.$t({ id: "PHASE_CODE" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.PhaseCode,
      },
      {
        header: intl.$t({ id: "NOTES" }),
        columnId: "instructions.text",
        columnType: COLUMN_TYPE.Notes,
      },
    ],
    [intl],
  );

  return spreadsheetConfig;
};
