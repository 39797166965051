import { useCallback, useMemo } from "react";
import { useInvoices } from "../../../../providers/InvoicesProvider";
import { InvoiceActionsProps } from "../types/InvoiceActionsProps";

export const useInvoiceActions = ({
  invoice,
  includeTrash,
}: InvoiceActionsProps) => {
  const { archiveInvoice, exportEnabled } = useInvoices();

  const handleDelete = useCallback(() => {
    return archiveInvoice(invoice.id);
  }, [archiveInvoice, invoice.id]);

  const includeDelete = useMemo(
    () => !exportEnabled && invoice.permissions.archive && includeTrash,
    [exportEnabled, includeTrash, invoice.permissions.archive],
  );

  return { handleDelete, includeDelete };
};
