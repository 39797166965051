import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { PhoneInputControlled } from "@/common/components/phone-input/PhoneInputControlled";
import { SMSValidation } from "@/common/components/sms-validation/SMSValidation";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import {
  SMSValidationProvider,
  useSMSVerification,
} from "@/common/providers/SMSValidationProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { useCallback } from "react";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Form = tw.form`flex flex-col gap-5 md:min-w-screen-xs text-sm my-2`;
const LineItem = tw.div`flex flex-row justify-between items-center w-full`;
const ButtonContainer = tw.div`flex flex-row justify-center gap-2 my-5 text-base`;

const ViewerProfileFormWithProvider = () => {
  const { viewer, updateUser, loading, currency } = useUser();
  const intl = useIntl();
  const { openDialog, cancelDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();
  const {
    initializeSMSVerification,
    resendSMSVerification,
    submitSMSVerification,
  } = useSMSVerification();
  const methods = useForm({
    defaultValues: {
      firstName: viewer?.firstName || "",
      lastName: viewer?.lastName || "",
      email: viewer?.email || "",
      cellPhone: viewer?.cellPhone || "",
      getSmsNotifications: viewer?.getSmsNotifications || false,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { dirtyFields } = useFormState({ control: methods.control });

  const updateUserProfile = useCallback(async () => {
    const input = methods.getValues();

    if (!viewer?.isPhoneValidated && input.getSmsNotifications) {
      await updateUser({
        firstName: input.firstName,
        lastName: input.lastName,
        cellPhone: input.cellPhone,
      });
      const verification = await initializeSMSVerification();
      if (verification) {
        openDialog({
          text: (
            <SMSValidation
              cancel={() => cancelDialog?.()}
              save={async () => {
                const result = await updateUser({
                  getSmsNotifications: true,
                });
                if (result) {
                  setSuccessAlert(
                    intl.$t({ id: "PROFILE_INFORMATION_UPDATE_SUCCESS" }),
                  );
                  cancelDialog?.();
                }
              }}
              submit={submitSMSVerification}
              resend={resendSMSVerification}
              verification={verification}
            />
          ),
        });
      }
    } else {
      const result = await updateUser({
        firstName: input.firstName,
        lastName: input.lastName,
        cellPhone: input.cellPhone,
        getSmsNotifications: input.getSmsNotifications,
      });
      if (result) {
        setSuccessAlert(intl.$t({ id: "PROFILE_INFORMATION_UPDATE_SUCCESS" }));
        cancelDialog?.();
      }
    }
  }, [
    cancelDialog,
    initializeSMSVerification,
    intl,
    methods,
    openDialog,
    resendSMSVerification,
    setSuccessAlert,
    submitSMSVerification,
    updateUser,
    viewer?.isPhoneValidated,
  ]);

  const onSave = useCallback(async () => {
    if (dirtyFields.cellPhone && viewer?.getSmsNotifications) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        title: intl.$t({ id: "PHONE_UPDATE" }),
        text: intl.$t({ id: "PHONE_UPDATE_CONFIRM" }),
        handleConfirm: updateUserProfile,
      });
    } else {
      await updateUserProfile();
    }
  }, [
    dirtyFields.cellPhone,
    intl,
    openDialog,
    updateUserProfile,
    viewer?.getSmsNotifications,
  ]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSave)}>
        <LineItem>
          <TextFieldControlled
            label={intl.$t({ id: "USER_EMAIL" })}
            name="email"
            staticText
          />
        </LineItem>
        <LineItem>
          <TextFieldControlled
            label={intl.$t({ id: "USER_FIRST_NAME" })}
            name="firstName"
            rules={{ required: true }}
          />
        </LineItem>
        <LineItem>
          <TextFieldControlled
            label={intl.$t({ id: "USER_LAST_NAME" })}
            name="lastName"
            rules={{ required: true }}
          />
        </LineItem>
        <LineItem>
          <PhoneInputControlled
            name="cellPhone"
            label={intl.$t({ id: "USER_CELL_PHONE" })}
            rules={{ required: true }}
            countryCode={currency?.code}
          />
        </LineItem>
        <LineItem>
          <FormattedMessage id="TEXT_NOTIFICATIONS" />
          <SwitchControlled
            name="getSmsNotifications"
            width={100}
            onLabel={intl.$t({ id: "ENABLED" })}
            offLabel={intl.$t({ id: "DISABLED" })}
          />
        </LineItem>
        <ButtonContainer>
          <OutlinedButton fullWidth onClick={() => cancelDialog?.()}>
            <FormattedMessage id="CANCEL" />
          </OutlinedButton>
          <PrimaryButton
            type="submit"
            disabled={!methods.formState.isValid}
            loading={loading}
            fullWidth
            testId="save-user-profile"
          >
            <FormattedMessage id="SAVE" />
          </PrimaryButton>
        </ButtonContainer>
      </Form>
    </FormProvider>
  );
};

export const ViewerProfileForm = () => (
  <SMSValidationProvider>
    <ViewerProfileFormWithProvider />
  </SMSValidationProvider>
);
