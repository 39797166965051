import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { routes } from "@/config/routes";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router";
import tw from "tailwind-styled-components";
import { useSpendingReport } from "../../../../providers/ProjectSpendingReportProvider";

const Container = tw.div`flex items-center gap-1`;
const LinkLikeStyled = tw(LinkLike)`text-xs font`;
const TextStyle = tw.span`text-xs text-gray-600 font-light`;

type Props = {
  item:
    | {
        id: string;
        allowance?: string | null | undefined;
      }
    | null
    | undefined;
};

export const SetCostCodeAllowance: FC<Props> = ({ item }) => {
  const navigate = useNavigate();
  const { groupedByZones } = useSpendingReport();
  const { id } = useParams();
  if (item?.allowance) {
    return null;
  }
  return (
    <If isTrue={item?.id !== UNSPECIFIED_COST_CODE_ID}>
      <Container onClick={(e) => e.stopPropagation()}>
        <LinkLikeStyled
          onClick={() => {
            navigate(generatePath(routes.projectBudget, { id }));
          }}
        >
          <FormattedMessage id="SET" />
        </LinkLikeStyled>
        <FormattedMessage
          id={groupedByZones ? "ZONE_ALLOWANCE" : "COST_CODE_ALLOWANCE"}
          tagName={TextStyle}
        />
      </Container>
    </If>
  );
};
