import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import { ATTACHMENTS_MIME_TYPES } from "@/common/components/upload/FileUploadArea";

export const KickbackCommentAssetUpload = () => {
  return (
    <UploadAsset
      accept={ATTACHMENTS_MIME_TYPES}
      mode="horizontal"
      includeAssets={false}
      classes={{
        container: "bg-white rounded-lg",
        dndContainer: "py-2",
        uploadContainer: {
          container: "px-2 h-17",
          image: "w-10 h-10",
          text: "w-24 text-xs",
        },
      }}
    />
  );
};
