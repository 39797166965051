import { routes } from "@/config/routes";
import React, { FC } from "react";
import { matchPath, useLocation } from "react-router";
import { SIGNATURE_HEADER_NAME } from "../const";
import { useQueryParams } from "../hooks/useQueryParams";
import { ExternalAuthAuthenticated } from "./ExternalAuthAuthenticated";

const ALLOWED_ROUTES_WITH_SIGNATURE = [
  routes.distributorBidMessages,
  routes.distributorBid,
  routes.distributorBuyout,
  routes.distributorBuyoutDeliveries,
  routes.distributorBuyoutMessages,
  routes.distributorDelivery,
  routes.distributorDeliveryRecord,
  routes.distributorDeliveryMessages,
  routes.distributorInvoice,
];

export const Authenticated: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { queryParams } = useQueryParams();
  const location = useLocation();

  const signatureParam = queryParams.get(SIGNATURE_HEADER_NAME);
  if (
    signatureParam &&
    ALLOWED_ROUTES_WITH_SIGNATURE.some(
      (pattern) => matchPath(pattern, location.pathname) !== null,
    )
  ) {
    return <>{children}</>;
  }

  return <ExternalAuthAuthenticated>{children}</ExternalAuthAuthenticated>;
};
