import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useValidateRequiredParams } from "@/common/hooks/useValidateRequiredParams";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import {
  AddToReleaseItemInput,
  InvoiceStatus,
  ReleaseDocument,
  ReleaseItemFieldsFragment,
  ReleaseProjectItemFieldsFragment,
  ReleaseQuery,
  ReleaseStatus,
  useReleaseQuery,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { endOfDay, startOfDay } from "date-fns";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { useSetCurrentProjectId } from "../../project/hooks/useSetCurrentProjectId";
import { usePriceCalculation } from "../hooks/usePriceCalculation";
import { SyncedRelease } from "../pages/specify-details/hooks/useSyncReleaseItems";
import { useReleaseStore } from "../store/useReleaseStore";

type FilterDates = {
  minDate?: number | undefined;
  maxDate?: number | undefined;
};

export type ExpandedReleaseItem = ReleaseProjectItemFieldsFragment &
  ReleaseItemFieldsFragment & {
    isIncluded: boolean;
    quantityDecimal: string;
    expirationDate?: string | null;
    vendorName?: string | null;
    deliveryDate?: string | null;
    orderIndex?: number;
  };

export type ExpandedRelease = ReleaseQuery["release"] & {
  time?: number | null | undefined;
};

type ProviderContextType = {
  release: ExpandedRelease | null | undefined;
  setRelease: (release: ExpandedRelease) => void;
  loading: boolean;
  removeItemFromRelease: (ids: string[]) => Promise<boolean>;
  removing: boolean;
  addItemsToRelease: (
    itemsToAdd: AddToReleaseItemInput[],
    itemsToRemove?: string[],
  ) => Promise<boolean>;
  updating: boolean;
  changeReleaseBuyout: (updatedRelease: SyncedRelease) => Promise<boolean>;
  invoiceId?: string;
  invoiceStatuses?: InvoiceStatus[];
  refetch: () => void;
  releaseId: string;
  filterDates: FilterDates | null;
  setFilterDates: (dates: FilterDates | null) => void;
  timeTbdFilter: boolean | undefined;
  setTimeTbdFilter: (value: boolean | undefined) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  release: null,
  setRelease: NoFunction,
  loading: false,
  removeItemFromRelease: NoFunctionBooleanPromise,
  removing: false,
  addItemsToRelease: NoFunctionBooleanPromise,
  updating: false,
  changeReleaseBuyout: NoFunctionBooleanPromise,
  invoiceId: undefined,
  invoiceStatuses: undefined,
  refetch: NoFunction,
  releaseId: "",
  filterDates: null,
  setFilterDates: NoFunction,
  timeTbdFilter: false,
  setTimeTbdFilter: NoFunction,
});

export const ReleaseProvider: FC<{
  children: React.ReactNode;
  id?: string;
  invoiceId?: string;
  invoiceStatuses?: InvoiceStatus[];
}> = ({ children, id, invoiceId, invoiceStatuses }) => {
  const { deliveryId } = useParams();
  const {
    data: releaseData,
    loading,
    error,
    refetch,
  } = useReleaseQuery({
    variables: {
      id: deliveryId || id || "",
      invoiceId,
      invoiceStatuses,
    },
    skip: !deliveryId && !id,
    fetchPolicy: "cache-and-network",
  });

  const [release, setRelease] = useState<ExpandedRelease>();
  const [refetching, setRefetching] = useState(false);
  const { setError } = useGlobalError();
  const [updateContractorRelease, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const { calcExtPrice } = usePriceCalculation();
  useErrorEffect(error);
  const {
    setStoreRelease,
    release: storeRelease,
    updateReleaseUpdateOptions,
  } = useReleaseStore(
    useShallow((state) => ({
      setStoreRelease: state.setStoreRelease,
      release: state.release,
      updateReleaseUpdateOptions: state.updateReleaseUpdateOptions,
    })),
  );

  useSetCurrentProjectId(release?.project?.id);

  useEffect(() => {
    return () => {
      updateReleaseUpdateOptions({});
    };
  }, [updateReleaseUpdateOptions]);

  const { validateParam } = useValidateRequiredParams("ReleaseProvider");
  const [filterDates, setFilterDates] = useState<FilterDates | null>(null);
  const [timeTbdFilter, setTimeTbdFilter] = useState<boolean | undefined>(
    false,
  );

  useEffect(() => {
    if (releaseData?.release?.id && !refetching) {
      setRelease(releaseData.release);
      setStoreRelease(releaseData.release);
    }
  }, [refetching, releaseData, setStoreRelease]);

  const [removing, setRemoving] = useState(false);

  const removeItemFromRelease = async (ids: string[]) => {
    if (release) {
      try {
        setRemoving(true);
        validateParam({
          value: storeRelease?.version,
          methodName: "removeItemFromRelease",
          paramName: "version",
        });
        const { data, errors } = await updateContractorRelease({
          variables: {
            input: {
              releaseId: release.id,
              version: storeRelease?.version,
              removedItems: ids,
            },
          },
          update: (cache, { data }) => {
            if (data?.updateContractorRelease) {
              cache.writeQuery({
                query: ReleaseDocument,
                variables: {
                  id: release.id || "",
                },
                data: {
                  release: data?.updateContractorRelease,
                },
              });
            }
          },
        });
        if (data?.updateContractorRelease) {
          setStoreRelease(data?.updateContractorRelease);
        }
        setRemoving(false);
        setError(errors);
        return Boolean(!errors);
      } catch (errors) {
        setRemoving(false);
        setError(errors);
        return false;
      }
    } else {
      return false;
    }
  };

  const compareItems = useCallback(
    (item1: ReleaseItemFieldsFragment, item2: AddToReleaseItemInput) => {
      return (
        item1.projectItem?.material.material.id ===
          item2.projectItem?.masterProductId ||
        item1.projectItem?.material.id === item2.projectItem?.masterSkuId ||
        item1.projectItem?.material.id === item2.projectItem?.orgCatalogSkuId ||
        (item1.buyoutItem?.id === item2.buyoutItemId && item2.buyoutItemId)
      );
    },
    [],
  );

  const addItemsToRelease = async (
    itemsToAdd: AddToReleaseItemInput[],
    itemsToRemove?: string[],
  ): Promise<boolean> => {
    if (release && itemsToAdd.length > 0) {
      try {
        validateParam({
          value: storeRelease?.version,
          methodName: "addItemsToRelease",
          paramName: "version",
        });
        const { data, errors } = await updateContractorRelease({
          variables: {
            input: {
              releaseId: release.id,
              version: storeRelease?.version,
              addedItems: itemsToAdd,
            },
          },
          update: (cache, { data }) => {
            if (!data?.updateContractorRelease) {
              return;
            }

            let updatedCache = data?.updateContractorRelease;

            if (
              checkReleaseStatus(release, [
                ReleaseStatus.Requested,
                ReleaseStatus.Scheduled,
                ReleaseStatus.Received,
                ReleaseStatus.PartiallyReceived,
              ])
            ) {
              const index =
                release.items.findIndex((i) => compareItems(i, itemsToAdd[0])) +
                release.items.filter((i) => compareItems(i, itemsToAdd[0]))
                  .length -
                1;
              const existingItemIds = new Set(release.items.map((i) => i.id));
              const addedItems = data?.updateContractorRelease.items.filter(
                (i) => {
                  return (
                    !existingItemIds.has(i.id) &&
                    (!itemsToRemove || !itemsToRemove.some((id) => id === i.id))
                  );
                },
              );

              let subtotalIncrease = new DecimalSafe(0);

              addedItems?.forEach((item) => {
                const price = item.unitPrice || 0;

                subtotalIncrease = subtotalIncrease.add(
                  calcExtPrice(item.quantityDecimal, price),
                );
              });

              const taxIncrease = subtotalIncrease.mul(
                new DecimalSafe(release.taxRate || 0),
              );
              const totalIncrease = subtotalIncrease.add(taxIncrease);

              const updatedItems = [
                ...release.items.slice(0, index + 1),
                ...addedItems,
                ...release.items.slice(index + 1),
              ].filter((i) => !(itemsToRemove || []).some((id) => id === i.id));

              updatedCache = {
                ...updatedCache,
                poNumber: release.poNumber,
                time: release.time,
                siteContact: release.siteContact,
                warehouse: release.warehouse,
                taxRate: release.taxRate,
                netAmount: !release.netAmount
                  ? release.netAmount
                  : new DecimalSafe(release.netAmount)
                      .add(subtotalIncrease)
                      .toString(),
                taxAmount: !release.taxAmount
                  ? release.taxAmount
                  : new DecimalSafe(release.taxAmount)
                      .add(taxIncrease)
                      .toString(),
                total: !release.total
                  ? release.total
                  : new DecimalSafe(release.total)
                      .add(totalIncrease)
                      .toString(),
                items: updatedItems,
              };
            }

            cache.writeQuery({
              query: ReleaseDocument,
              variables: {
                id: release.id || "",
              },
              data: {
                release: updatedCache,
              },
            });
          },
        });
        setError(errors);
        if (data?.updateContractorRelease) {
          setStoreRelease(data?.updateContractorRelease);
        }
        return Boolean(!!data?.updateContractorRelease && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    }
    return false;
  };

  const changeReleaseBuyout = async (
    updatedRelease: SyncedRelease,
  ): Promise<boolean> => {
    if (release && updatedRelease) {
      try {
        validateParam({
          value: storeRelease?.version,
          methodName: "changeReleaseBuyout",
          paramName: "version",
        });
        const { data, errors } = await updateContractorRelease({
          variables: {
            input: { ...updatedRelease, version: storeRelease?.version },
          },
        });
        if (data?.updateContractorRelease) {
          setStoreRelease(data?.updateContractorRelease);
        }
        setError(errors);
        return Boolean(!!data?.updateContractorRelease && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    }
    return false;
  };

  const refetchRelease = useCallback(async () => {
    setRefetching(true);
    await refetch();
    setRefetching(false);
  }, [refetch]);

  const showLoading = useMemo(() => {
    if (!deliveryId || !id) {
      return false;
    }
    return (loading || !release) && !refetching;
  }, [loading, release, deliveryId, id, refetching]);

  const setTimeTbdFilterAndClearDates = useCallback(
    (value: boolean | undefined) => {
      setTimeTbdFilter(value);
      setFilterDates(null);
    },
    [setTimeTbdFilter],
  );

  const setFilterDatesWithAdjustments = useCallback(
    (dates: FilterDates | null) => {
      const minDate = dates?.minDate
        ? startOfDay(dates.minDate).getTime()
        : undefined;
      const maxDate = dates?.maxDate
        ? endOfDay(dates.maxDate).getTime()
        : undefined;

      setFilterDates({ minDate, maxDate });
    },
    [setFilterDates],
  );

  return (
    <ProviderContext.Provider
      value={{
        release,
        refetch: refetchRelease,
        setRelease,
        loading: showLoading,
        releaseId: deliveryId || id || "",
        removeItemFromRelease,
        removing,
        addItemsToRelease,
        updating,
        changeReleaseBuyout,
        invoiceId,
        invoiceStatuses,
        filterDates,
        setFilterDates: setFilterDatesWithAdjustments,
        timeTbdFilter,
        setTimeTbdFilter: setTimeTbdFilterAndClearDates,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useRelease = (): ProviderContextType =>
  useContext(ProviderContext);
