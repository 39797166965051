import { TextField } from "@/common/components/textfield/TextField";
import { FC } from "react";
import { useIntl } from "react-intl";
import { CostType, useCostTypesList } from "../providers/CostTypesListProvider";

type Props = {
  costType: CostType;
  prop: "code" | "description";
  placeholder: string;
  required?: boolean;
  readonly?: boolean;
  error: boolean;
};

export const CostTypeInput: FC<Props> = ({
  costType,
  prop,
  placeholder,
  required,
  readonly,
}) => {
  const intl = useIntl();
  const { costTypes, setCostTypes } = useCostTypesList();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCostTypes(
      costTypes.map((c) =>
        c.id === costType.id ? { ...c, [prop]: e.target.value } : c,
      ),
    );
  };

  return (
    <TextField
      size="small"
      className="w-full"
      value={costType[prop]}
      required={required}
      placeholder={intl.$t({ id: placeholder })}
      onChange={onChange}
      staticText={readonly}
      error={costType.isSubmitted && !costType[prop]}
    />
  );
};
