import { SequenceStepper } from "@/common/components/sequence-stepper/SequenceStepper";
import { useDeliverySlipSequence } from "../../../providers/DeliverySlipSequenceProvider";
import { useDeliverySlipSequenceStepper } from "./useDeliverySlipSequenceStepper";

export const DeliverySlipSequenceStepper = () => {
  const { step, setStep, sequenceIds, redirectToDeliverySlips } =
    useDeliverySlipSequence();

  const { nextStepCallback, prevStepCallback } =
    useDeliverySlipSequenceStepper();

  return (
    <SequenceStepper
      titleId="PACKING_SLIPS"
      step={step}
      setStep={setStep}
      sequenceIds={sequenceIds}
      exitSequence={redirectToDeliverySlips}
      prevStepCallback={prevStepCallback}
      nextStepCallback={nextStepCallback}
    />
  );
};
