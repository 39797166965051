import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useEffect } from "react";
import { useInvoiceMatchedOrder } from "../../../providers/InvoiceMatchedOrderProvider";

export const useInvoiceItemPrice = () => {
  const { release } = useRelease();
  const { setUseInvoiceItemPrice } = useInvoiceMatchedOrder();

  useEffect(() => {
    if (
      (release?.items ?? []).some((releaseItem) =>
        releaseItem.invoiceItems.some(
          (releaseInvoiceItem) =>
            releaseInvoiceItem.unitPrice !== null &&
            releaseInvoiceItem.unitPrice !== releaseItem.unitPrice,
        ),
      )
    ) {
      setUseInvoiceItemPrice(true);
    }
  }, [release?.items, setUseInvoiceItemPrice]);
};
