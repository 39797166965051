import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { defaultTableItemSelectionClassNames } from "@/common/components/searchable-list/utils";
import { QUERYSTRING } from "@/common/const";
import { ObjectType, useSortingStore } from "@/common/stores/useSortingStore";
import { routes } from "@/config/routes";
import {
  DeliverySlipPartialFieldsFragment,
  QueryDeliverySlipsSortBy,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useCallback, useEffect } from "react";
import { createSearchParams, generatePath, useNavigate } from "react-router";
import { useDeliverySlipSequence } from "../../providers/DeliverySlipSequenceProvider";
import { useDeliverySlips } from "../../providers/DeliverySlipsProvider";
import { useDeliverySlipsConfiguration } from "./DeliverySlips.configuration";

type Props = {
  deliverySlips: DeliverySlipPartialFieldsFragment[];
  loading: boolean;
  totalCount: number;
  isFiltered: boolean;
};

export const DeliverySlipsList: FC<Props> = ({
  deliverySlips,
  loading,
  totalCount,
  isFiltered,
}) => {
  const configuration = useDeliverySlipsConfiguration();
  const navigate = useNavigate();
  const { exportEnabled } = useDeliverySlips();

  const { sequenceIds, selectedDeliverySlips, setSelectedDeliverySlips } =
    useDeliverySlipSequence();
  const setSortEntity = useSortingStore((state) => state.setSortEntity);

  useEffect(() => {
    setSortEntity({
      objectType: ObjectType.DeliverySlip,
      field: QueryDeliverySlipsSortBy.CreatedAt,
      descending: true,
    });
  }, [setSortEntity]);

  const openDeliverySlip = useCallback(
    (deliverySlip: DeliverySlipPartialFieldsFragment) => {
      if (exportEnabled) {
        const isIncluded = sequenceIds.includes(deliverySlip.id);
        setSelectedDeliverySlips(
          !isIncluded
            ? [...selectedDeliverySlips, deliverySlip]
            : selectedDeliverySlips.filter((i) => i.id !== deliverySlip.id),
        );
      } else {
        navigate({
          pathname: generatePath(
            deliverySlip.release
              ? routes.deliverySlipReceiveOrder
              : routes.deliverySlipMatchOrder,
            {
              deliverySlipId: deliverySlip.id,
            },
          ),
          search:
            deliverySlip?.release?.poNumber &&
            (deliverySlip.release.status === ReleaseStatus.Draft ||
              deliverySlip.release.status === ReleaseStatus.Reserved) &&
            !deliverySlip.release.itemCount
              ? createSearchParams({
                  [QUERYSTRING.SPREADSHEET_VIEW]: "true",
                }).toString()
              : undefined,
        });
      }
    },
    [
      exportEnabled,
      navigate,
      selectedDeliverySlips,
      sequenceIds,
      setSelectedDeliverySlips,
    ],
  );

  const itemFn = useCallback(
    (item: DeliverySlipPartialFieldsFragment) => {
      if (item.archivedAt) {
        return {
          className: "bg-gray-300 opacity-50",
        };
      }
      if (exportEnabled && sequenceIds.includes(item.id)) {
        return { className: defaultTableItemSelectionClassNames };
      }
      return { className: "" };
    },
    [exportEnabled, sequenceIds],
  );

  return (
    <ListRenderer
      totalCount={totalCount}
      count={deliverySlips?.length}
      paginated
    >
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            itemContent: "grid-cols-[50px_repeat(5,_auto)]",
            header: "top-[140px] bg-transparent",
            root: "pt-2",
            itemFn,
          },
          toggle: {
            item: openDeliverySlip,
          },
          hasCardItems: true,
        }}
        items={deliverySlips}
        loading={loading}
        sortObjectType={ObjectType.DeliverySlip}
        emptyList={
          <NoResults
            isFiltered={isFiltered}
            filteredTranslationKey="DELIVERIES_LIST_NO_DELIVERY_SLIPS_FILTERED"
            translationKey="DELIVERIES_LIST_NO_DELIVERY_SLIPS"
          />
        }
      />
    </ListRenderer>
  );
};
