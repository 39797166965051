import { If } from "@/common/components/if/If";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { MatchedOrderViewState } from "../../../../../../enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "../../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceReleaseItemUnitPriceInput } from "./hooks/useInvoiceReleaseItemUnitPriceInput";

const Container = tw.div`
  w-full
`;
const PricePickerStyled = tw(PricePicker)`
${({ readonly }: { readonly: boolean }) =>
  readonly ? "bg-transparent" : "bg-white"}
`;
const OrderedUnitPrice = tw.div`
  text-red-500 w-22 text-center text-2xs
`;
const OrderedUnitPriceTextWrap = tw.div`text-wrap`;
const OrderedUnitPriceText = tw.div`text-red-500 absolute -right-1 -left-1 z-10 px-0.5`;
const Text = tw.span`bg-white`;

type Props = {
  item: ExpandedReleaseItem;
  readonly?: boolean;
};

export const InvoiceReleaseItemUnitPriceInput: FC<Props> = ({ item }) => {
  const {
    differentPrices,
    invoicedPriceDifferentThanOrdered,
    readonly,
    updatePrice,
    useInvoiceItemPrice,
  } = useInvoiceReleaseItemUnitPriceInput(item);
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const { formatCurrency } = useFormatNumberToCurrency();

  if (useInvoiceItemPrice) {
    return (
      <Container>
        <PricePickerStyled
          value={item.invoiceItems?.[0]?.unitPrice}
          readonly={readonly}
          onBlur={updatePrice}
          className="text-center text-sm"
          hideErrorIcon
          helperText={
            invoicedPriceDifferentThanOrdered ? (
              <OrderedUnitPriceTextWrap>
                <OrderedUnitPriceText>
                  <FormattedMessage
                    id="ORDERED_UNIT_PRICE"
                    values={{ unitPrice: formatCurrency(item.unitPrice) }}
                    tagName={Text}
                  />
                </OrderedUnitPriceText>
              </OrderedUnitPriceTextWrap>
            ) : undefined
          }
        />
        <If
          isTrue={
            matchedOrderViewState !==
              MatchedOrderViewState.EDIT_INVOICE_COVERAGES &&
            invoicedPriceDifferentThanOrdered
          }
        >
          <FormattedMessage
            id="ORDERED_UNIT_PRICE"
            values={{ unitPrice: formatCurrency(item.unitPrice) }}
            tagName={OrderedUnitPrice}
          />
        </If>
      </Container>
    );
  }

  return (
    <Container>
      <If isTrue={!differentPrices}>
        <PricePickerStyled
          value={item.unitPrice}
          readonly={readonly}
          onBlur={updatePrice}
          className="text-center text-sm"
          hideErrorIcon
        />
      </If>
      <If isTrue={differentPrices}>
        <Tooltip
          id="unit-price"
          element={
            <PricePickerStyled
              value={item.unitPrice}
              readonly={readonly}
              onBlur={updatePrice}
              className="text-center text-sm text-red-500"
              hideErrorIcon
            />
          }
        >
          <FormattedMessage id="UNIT_PRICE_DOES_NOT_MATCH" />
        </Tooltip>
      </If>
    </Container>
  );
};
