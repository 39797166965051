import { threeStateResult } from "@/common/utils/threeStateResult";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";

type Props = {
  value: boolean | null | undefined;
  includeNotDefined?: boolean;
  tagName?: React.ElementType;
};

export const ThreeStateResult: FC<Props> = ({
  value,
  includeNotDefined = false,
  tagName: Tag = React.Fragment,
}) => {
  const intl = useIntl();
  const result = useMemo(
    () =>
      threeStateResult(value, {
        yes: intl.$t({ id: "YES" }),
        no: intl.$t({ id: "NO" }),
        notDefined: includeNotDefined ? "--" : intl.$t({ id: "NO" }),
      }),
    [value, includeNotDefined, intl],
  );

  return <Tag>{result}</Tag>;
};
