import { TemplateType } from "@/contractor/pages/admin/integrations/enums/TemplateType.enum";
import { useTemplatePreviewStore } from "@/contractor/pages/admin/integrations/store/useTemplatePreviewStore";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import {
  SimpleStepContainer,
  SimpleStepRow,
  SimpleStepTitle,
  SimpleStepTitleContainer,
} from "../../../Wizard.styles";
import { TemplateEditor } from "./TemplateEditor";
import { TemplatePreview } from "./TemplatePreview";
import { TemplateVariables } from "./TemplateVariables";

const Container = tw.div`grid grid-cols-[500px_auto] gap-4 mt-2`;
const Content = tw.div`w-full`;

export const TemplateStrings: FC = () => {
  const { connectedSourceSystem } = useOrgSettingsExtended();
  const intl = useIntl();

  const { templates, setTemplate } = useTemplatePreviewStore(
    useShallow((state) => ({
      templates: state.templates,
      setTemplate: state.setTemplate,
    })),
  );

  useEffect(() => {
    if (connectedSourceSystem) {
      setTemplate(
        TemplateType.PoName,
        connectedSourceSystem.poNameTemplate ?? "",
      );
      setTemplate(
        TemplateType.PoDescription,
        connectedSourceSystem.poDescriptionTemplate ?? "",
      );
      setTemplate(
        TemplateType.InvoiceDescription,
        connectedSourceSystem.invoiceDescriptionTemplate ?? "",
      );
    }
  }, [connectedSourceSystem, setTemplate]);

  return (
    <SimpleStepContainer>
      <SimpleStepRow>
        <InfoOutlined className="mr-4 h-12 w-12 text-gray-400" />
        <SimpleStepTitleContainer>
          <SimpleStepTitle>
            {intl.$t({ id: "TEMPLATE_STRINGS" })}
          </SimpleStepTitle>
        </SimpleStepTitleContainer>
      </SimpleStepRow>
      <Container>
        <Content>
          <SimpleStepTitle className="text-md">
            {intl.$t({ id: "PO_NAME_TEMPLATE_STRING" })}
          </SimpleStepTitle>
          <TemplateEditor
            content={templates[TemplateType.PoName].value}
            setContent={(value) => setTemplate(TemplateType.PoName, value)}
          />
          <TemplatePreview preview={templates[TemplateType.PoName].preview} />
          <SimpleStepTitle className="text-md mt-4">
            {intl.$t({ id: "PO_DESCRIPTION_TEMPLATE_STRING" })}
          </SimpleStepTitle>
          <TemplateEditor
            content={templates[TemplateType.PoDescription].value}
            setContent={(value) =>
              setTemplate(TemplateType.PoDescription, value)
            }
          />
          <TemplatePreview
            preview={templates[TemplateType.PoDescription].preview}
          />
          <SimpleStepTitle className="text-md mt-4">
            {intl.$t({ id: "INVOICE_TEMPLATE_STRING" })}
          </SimpleStepTitle>
          <TemplateEditor
            content={templates[TemplateType.InvoiceDescription].value}
            setContent={(value) =>
              setTemplate(TemplateType.InvoiceDescription, value)
            }
          />
          <TemplatePreview
            preview={templates[TemplateType.InvoiceDescription].preview}
          />
        </Content>
        <Content>
          <TemplateVariables />
        </Content>
      </Container>
    </SimpleStepContainer>
  );
};
