import { LUMP_SUM_UOM } from "@/common/const";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ExternalPOTableCategoryId } from "@/contractor/pages/home/common/external-po/ExternalPOItemsTable.configuration";
import { ExternalPoFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { ImportableItem } from "../types/ImportableItem";

export const useExternalPOUtils = () => {
  const intl = useIntl();
  const { getUomByName } = useUomOptions();
  const { settings } = useOrgSettings();

  const getUnsupportedCostTypesCategory = useCallback(
    (externalPO?: ExternalPoFieldsFragment | null) =>
      (externalPO?.itemGroups.unsupportedCostType ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.UNSUPPORTED_COST_TYPES,
              isOpened: true,
              name: intl.$t({ id: "UNSUPPORTED_COST_TYPES" }),
              description: intl.$t({ id: "UNSUPPORTED_COST_TYPES_TOOLTIP" }),
              items: (externalPO?.itemGroups.unsupportedCostType ?? []).map(
                (item) => ({ ...item, id: item.externalId }),
              ),
            },
          ]
        : [],
    [intl],
  );

  const getMissingTagsCategory = useCallback(
    (externalPO?: ExternalPoFieldsFragment | null) =>
      (externalPO?.itemGroups.missingTag ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.MISSING_TAG,
              isOpened: true,
              name: intl.$t({ id: "MISSING_PHASE_CODES" }),
              description: intl.$t({ id: "MISSING_PHASE_CODES_TOOLTIP" }),
              items: (externalPO?.itemGroups.missingTag ?? []).map((item) => ({
                ...item,
                id: item.externalId,
              })),
            },
          ]
        : [],
    [intl],
  );

  const getMissingCostCodesCategory = useCallback(
    (externalPO?: ExternalPoFieldsFragment | null) =>
      (externalPO?.itemGroups.missingCostCode ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.MISSING_COST_CODES,
              isOpened: true,
              name: intl.$t({ id: "MISSING_COST_CODES" }),
              description: intl.$t({ id: "MISSING_COST_CODES_TOOLTIP" }),
              items: (externalPO?.itemGroups.missingCostCode ?? []).map(
                (item) => ({ ...item, id: item.externalId }),
              ),
            },
          ]
        : [],
    [intl],
  );

  const checkIfPOIsItemized = useCallback(
    (externalPO?: ExternalPoFieldsFragment | null) =>
      (externalPO?.itemGroups.aggregatedItems ?? []).length === 0,
    [],
  );

  const getIncludedItemsCategory = useCallback(
    (externalPO?: ExternalPoFieldsFragment | null) => [
      {
        id: ExternalPOTableCategoryId.INCLUDED,
        isOpened: true,
        name: "",
        items: [
          ...(externalPO?.itemGroups.importable ?? []),
          ...(externalPO?.itemGroups.additionalCharges ?? []),
          ...(externalPO?.itemGroups.salesTax ?? []),
        ].map((item) => ({ ...item, id: item.externalId })),
      },
    ],
    [],
  );

  const getImportableItems = useCallback(
    (externalPO?: ExternalPoFieldsFragment | null): ImportableItem[] => {
      const itemCostTypes = settings?.display?.itemCostTypes;
      const isNonItemizedPO = !checkIfPOIsItemized(externalPO);
      if (!isNonItemizedPO) {
        return (externalPO?.itemGroups.importable ?? []).map((item) => {
          const isLumpSum = getUomByName(item.uom)?.mnemonic === LUMP_SUM_UOM;
          return {
            id: item.externalId,
            description: item.description,
            costCode: item.costCode?.code,
            ...(itemCostTypes ? { costType: item.costType?.code } : {}),
            tags: item.wbsTag ? [item.wbsTag] : [],
            UOM: item.uom,
            quantityDecimal: isLumpSum ? item.amount : item.quantity,
            unitPrice: isLumpSum ? "1" : item.unitCost,
            extPrice: isLumpSum
              ? item.amount
              : new DecimalSafe(item?.unitCost).mul(item?.quantity).toNumber(),
            instructions: { text: item.notes },
            name: item.costCode?.code
              ? intl.$t(
                  { id: "COST_CODE_EXPENSES" },
                  { costCode: item.costCode?.code },
                )
              : intl.$t({ id: "EXPENSES" }),
            taxable: true,
          };
        });
      } else {
        return (externalPO?.itemGroups.aggregatedItems || []).map((item) => ({
          id: item.externalId,
          description: item.externalCostCode?.name || "",
          ...(itemCostTypes ? { costType: item.costType?.code } : {}),
          costCode: item.costCode?.code || item.externalCostCode?.code,
          UOM: LUMP_SUM_UOM,
          quantityDecimal: item.amount,
          unitPrice: "1",
          extPrice: item.amount,
          tags: item.wbsTag ? [item.wbsTag] : [],
          taxable: true,
        }));
      }
    },
    [checkIfPOIsItemized, getUomByName, intl, settings?.display?.itemCostTypes],
  );

  return {
    getUnsupportedCostTypesCategory,
    getMissingTagsCategory,
    getMissingCostCodesCategory,
    getIncludedItemsCategory,
    checkIfPOIsItemized,
    getImportableItems,
  };
};
