import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { QUERYSTRING } from "@/common/const";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { routes } from "@/config/routes";
import { ProjectProjectsFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { generatePath, useNavigate } from "react-router";
import { BudgetContainer, LinkLikeStyled } from "../Projects.styles";
import { DisplayProjectBudgetBuyoutDiff } from "./DisplayProjectBudgetBuyoutDiff";

type DisplayProjectBuyoutsProps = {
  project: ProjectProjectsFieldsFragment;
};

export const DisplayProjectBuyouts: FC<DisplayProjectBuyoutsProps> = ({
  project,
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    const params = new URLSearchParams({
      [QUERYSTRING.PROJECT_ID]: project.id,
    });

    navigate({
      pathname: generatePath(routes.buyouts),
      search: params.toString(),
    });
  };

  return (
    <NotNullableRenderer value={Number(project.totalAmount)}>
      <LinkLikeStyled onClick={onClick}>
        <BudgetContainer>
          <ValueCurrency
            value={Number(project.totalAmount)}
            classNames={{ value: "text-sm" }}
          />
          <If isTrue={Number(project.totalAmount) && project.budget}>
            <DisplayProjectBudgetBuyoutDiff
              percentage={new DecimalSafe(project.totalAmount).dividedBy(
                project.budget || 0,
              )}
            />
          </If>
        </BudgetContainer>
      </LinkLikeStyled>
    </NotNullableRenderer>
  );
};
