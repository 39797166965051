import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useMemo } from "react";

export const useInvoicePricing = () => {
  const { release } = useRelease();

  const invoicedSoFar = useMemo(
    () =>
      (release?.invoices ?? []).reduce(
        (invoicesTotal, invoice) => invoicesTotal.plus(invoice.total ?? 0),
        new DecimalSafe(0),
      ),
    [release?.invoices],
  );

  const uninvoicedBalance = useMemo(
    () => new DecimalSafe(release?.total ?? 0).minus(invoicedSoFar),
    [release?.total, invoicedSoFar],
  );

  return {
    invoicedSoFar,
    uninvoicedBalance,
  };
};
