import File from "@/assets/icons/file.svg?react";
import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import {
  AddItemsFromDialog,
  AddItemsOptionType,
} from "@/common/components/add-items-from-dialog/AddItemsFromDialog";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { SpreadsheetSaveType } from "@/common/providers/ColumnMapperProvider";
import { useMemo, useState } from "react";
import { useSyncEstimatedItems } from "../../providers/SyncEstimatedItemsProvider";
import { UPLOAD_MATERIALS_DRAWER } from "../upload-materials/UploadMaterialsDrawer";

export const ProjectAddItems = () => {
  const [showAddItemsDialog, setShowAddItemsDialog] = useState(false);
  const { toggle } = useGlobalDrawer();
  const { syncEstimatedItems } = useSyncEstimatedItems();

  const dialogOptions = useMemo<AddItemsOptionType[]>(
    () => [
      {
        label: "UPLOAD_ESTIMATE",
        details: "UPLOAD_ESTIMATE_INFO",
        callback: () => {
          setShowAddItemsDialog(false);
          toggle(UPLOAD_MATERIALS_DRAWER, true);
        },
        icon: <File className="h-28 w-28" />,
        preferred: true,
      },
    ],
    [toggle],
  );

  const gotoItemSelection = async () => {
    const result = await syncEstimatedItems(SpreadsheetSaveType.Toggle);
    if (result) {
      setShowAddItemsDialog(true);
    }
  };

  return (
    <>
      <AddItemsButton onClick={gotoItemSelection} />
      <AddItemsFromDialog
        title="ADD_ITEMS_TO_PROJECT"
        options={dialogOptions}
        visible={showAddItemsDialog}
        setVisible={setShowAddItemsDialog}
      />
    </>
  );
};
