import { useShallow } from "zustand/react/shallow";

import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { getCellValue } from "@/common/components/spreadsheet-table/utils/getCellValue";
import { rowIsEmpty } from "@/common/components/spreadsheet-table/utils/rowIsEmpty";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { SetProjectAllowanceInput } from "@/generated/graphql";
import { useCallback } from "react";
import { useProjectStore } from "../../../../store/useProjectStore";
import { useProjectBudget } from "../../hooks/useProjectBudget";

export const useBudgetByTags = () => {
  const { spreadsheetData, gotoInvalidRow } = useColumnMapper();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { updateProjectBudget } = useProjectBudget();
  const { allowance } = useProjectStore(
    useShallow((state) => ({
      allowance: state.allowance,
    })),
  );
  const { getRowTagIds } = useTableHelpers();

  const validateItems = useCallback(async () => {
    if (
      !(await validateRequiredValues([COLUMN_TYPE.Tag])) ||
      !(await validateRowValues([COLUMN_TYPE.Tag]))
    ) {
      gotoInvalidRow();
      return false;
    }
    return true;
  }, [gotoInvalidRow, validateRequiredValues, validateRowValues]);

  const getTagBudget = useCallback(() => {
    const data = spreadsheetData.filter((row) => !rowIsEmpty(row));
    const input = {
      costCodes: allowance.costCodes.map((costCode) => ({
        costCodeId: costCode.costCode.id,
        amount: costCode.amount,
        position: costCode.position,
        zoneId: costCode.zone?.id,
        costTypeIds: costCode.costTypes?.map((costType) => costType.id),
      })),
      tags: data.map((row) => ({
        tagId: getRowTagIds(row)[0],
        amount: getCellValue(row, COLUMN_TYPE.Budget) || "0",
      })),
    } as SetProjectAllowanceInput;
    return input;
  }, [spreadsheetData, allowance.costCodes, getRowTagIds]);

  const saveTagBudget = useCallback(async () => {
    if (!(await validateItems())) {
      return undefined;
    }
    const result = await updateProjectBudget({
      budgetAllowance: getTagBudget(),
    });

    return result;
  }, [updateProjectBudget, getTagBudget, validateItems]);

  return {
    validateItems,
    saveTagBudget,
  };
};
