import {
  TableViewState,
  useTableViewStore,
} from "@/common/stores/useTableViewStore";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useEffect } from "react";
import { useShallow } from "zustand/react/shallow";
import { useInvoiceImportExternalPO } from "../../../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";

export const useInvoiceCreateRelease = () => {
  const { invoice } = useInvoiceVerification();
  const { selectedBaseExternalPO, importedPoItemized, setVariables } =
    useInvoiceImportExternalPO();
  const { initViewStore, resetViewStore, setViewState } = useTableViewStore(
    useShallow((state) => ({
      initViewStore: state.initViewStore,
      resetViewStore: state.resetViewStore,
      setViewState: state.setViewState,
    })),
  );

  useEffect(() => {
    initViewStore(TableViewState.spreadsheet);
    return () => {
      resetViewStore();
    };
  }, [initViewStore, resetViewStore]);

  useEffect(() => {
    setViewState(
      importedPoItemized ? TableViewState.spreadsheet : TableViewState.normal,
    );
  }, [importedPoItemized, setViewState]);

  useEffect(() => {
    if (!selectedBaseExternalPO) {
      setVariables({
        importedPoItemized: !(
          (invoice?.items ?? []).length === 1 &&
          isLumpSumUomText(invoice?.items[0]?.UOM)
        ),
      });
    }
  }, [selectedBaseExternalPO, invoice?.items, setVariables]);
};
