import { useMemo } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { TotalProps, usePriceCalculation } from "./usePriceCalculation";

type Props = {
  form?: UseFormReturn<TotalProps, undefined, undefined>;
};

export const useFormPriceCalculation = (props?: Props) => {
  const { watch: defaultWatch } = useFormContext<TotalProps>();
  const watch = props?.form?.watch || defaultWatch;
  const { calcTotalPrice } = usePriceCalculation();
  const [
    netAmount,
    customTaxAmount,
    taxCodeId,
    taxRate,
    orderTypeId,
    additionalCharges,
    taxVariance,
    taxableNetAmount,
  ] = watch([
    "netAmount",
    "customTaxAmount",
    "taxCodeId",
    "taxRate",
    "orderTypeId",
    "additionalCharges",
    "taxVariance",
    "taxableNetAmount",
  ]);

  const total = useMemo(
    () =>
      calcTotalPrice({
        netAmount,
        customTaxAmount,
        taxCodeId,
        taxRate,
        orderTypeId,
        additionalCharges,
        taxVariance,
        taxableNetAmount,
      }),
    [
      calcTotalPrice,
      netAmount,
      customTaxAmount,
      taxCodeId,
      taxRate,
      orderTypeId,
      additionalCharges,
      taxVariance,
      taxableNetAmount,
    ],
  );

  return {
    total,
  };
};
