import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceItemReceivedQuantity } from "./hooks/useInvoiceItemReceivedQuantity";

const Container = tw.div`w-full`;

type Props = {
  item: ExpandedReleaseItem;
};

export const InvoiceItemReceivedQuantity: FC<Props> = ({ item }) => {
  const {
    receivedQtyIsGreaterThanOrdered,
    invoicedQtyIsGreaterThenReceivedQty,
  } = useInvoiceItemReceivedQuantity(item);

  return (
    <Container>
      <If
        isTrue={
          !receivedQtyIsGreaterThanOrdered &&
          !invoicedQtyIsGreaterThenReceivedQty
        }
      >
        <ValueUnit
          value={item.receivedQuantityDecimal}
          uom={item.uom}
          renderAsPrice={isLumpSumItem(item)}
        />
      </If>
      <If
        isTrue={
          receivedQtyIsGreaterThanOrdered || invoicedQtyIsGreaterThenReceivedQty
        }
      >
        <Tooltip
          id="unit-price"
          element={
            <ValueUnit
              classNames={{ container: "text-red-500" }}
              value={item.receivedQuantityDecimal}
              uom={item.uom}
            />
          }
        >
          <FormattedMessage
            id={
              receivedQtyIsGreaterThanOrdered
                ? "RECEIVED_QTY_GREATER_THAN_ORDERED"
                : "INVOICED_QTY_GREATER_THAN_RECEIVED"
            }
          />
        </Tooltip>
      </If>
    </Container>
  );
};
