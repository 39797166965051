import { Select } from "@/common/components/select/components/single/Select";
import {
  AddressFieldsFragment,
  WarehouseFieldsFragment,
} from "@/generated/graphql";
import { InputBaseComponentProps } from "@mui/material";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { WarehouseSelectorCustomRenderer } from "./WarehouseSelectorCustomRenderer";
import { PROJECT_ADDRESS_ID, useWarehouseOptions } from "./useWarehouseOptions";

type Props = {
  options: WarehouseFieldsFragment[];
  value: WarehouseFieldsFragment | null | undefined;
  onChange: (value: string | null) => void;
  projectAddress?: AddressFieldsFragment;
  readonly?: boolean;
  inputProps?: InputBaseComponentProps | undefined;
  disableClearable?: boolean;
  placeholder?: string;
  required?: boolean;
  loading?: boolean;
};

export const WarehouseSelector: FC<Props> = ({
  options,
  value,
  onChange,
  projectAddress,
  readonly = false,
  inputProps,
  disableClearable,
  placeholder,
  required = true,
  loading = false,
}) => {
  const intl = useIntl();
  const formattedValue = useMemo(
    () => (!value ? PROJECT_ADDRESS_ID : value.id),
    [value],
  );
  const { warehouseOptions } = useWarehouseOptions(
    options,
    projectAddress,
    value,
  );

  return (
    <Select
      options={warehouseOptions}
      placeholder={placeholder ?? intl.$t({ id: "FULFILLMENT_LOCATION" })}
      value={formattedValue}
      inputProps={inputProps}
      onChange={onChange}
      getLabel={(option: WarehouseFieldsFragment) => option.name}
      getValue={(option: WarehouseFieldsFragment) => option.id}
      InputProps={{ className: value?.archivedAt ? "text-gray-400" : "" }}
      customRender={WarehouseSelectorCustomRenderer}
      required={required}
      disabled={readonly}
      disableClearable={disableClearable}
      loading={loading}
    />
  );
};
