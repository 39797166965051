import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useInvoiceVerification } from "../../../../../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../../../../InvoiceVerificationForm";

export const useInvoiceCreateReleaseNonItemizedView = () => {
  const { invoice } = useInvoiceVerification();
  const { setValue } = useFormContext<InvoiceCreateReleaseFormValues>();

  useEffect(() => {
    if (invoice && invoice.subtotal) {
      setValue("netAmount", Number(invoice.subtotal));
    }
  }, [setValue, invoice]);
};
