import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoicedItemExtPriceGroup } from "./hooks/useInvoicedItemExtPriceGroup";

const Container = tw.div`
  grid w-full text-center text-2xs
`;

const ValueWithTax = tw.div`
  w-full text-center text-2xs text-gray-600
`;

type Props = {
  items: ExpandedReleaseItem[];
};

export const InvoicedItemExtPriceGroup: FC<Props> = ({ items }) => {
  const { formatCurrency } = useFormatNumberToCurrency();
  const { netAmount, valueWithTax, hidden } =
    useInvoicedItemExtPriceGroup(items);

  return (
    <If isTrue={hidden}>
      <Container>
        <Price price={netAmount} />
        <If isTrue={valueWithTax}>
          <FormattedMessage
            id="VALUE_WITH_TAX_AND_FEES"
            tagName={ValueWithTax}
            values={{ value: formatCurrency(valueWithTax) }}
          />
        </If>
      </Container>
    </If>
  );
};
