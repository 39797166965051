import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { getExpandedItems } from "@/common/components/grid-table/utils/getExpandedItems";
import { If } from "@/common/components/if/If";
import { useHideZones } from "@/common/hooks/useHideZones";
import { ZoneItemsContainer } from "@/contractor/pages/home/project/Project.styles";
import {
  EstimatedItemFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, ReactNode, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { ReleaseNotesFooter } from "../pages/release-details/release-footer/ReleaseNotesFooter";
import { useReleaseActions } from "../providers/ReleaseActionsProvider";
import { useReleaseItemsZoneProvider } from "../providers/ReleaseItemsZonesProvider";
import { ExpandedReleaseItem, useRelease } from "../providers/ReleaseProvider";
import { useReleaseStore } from "../store/useReleaseStore";

export const EmptyListContainer = tw.div`flex justify-center items-center h-full p-5 border border-gray-500 border-dashed rounded-xl`;

type Props = {
  columns: Array<GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>>;
  classNames?: {
    header: string;
    category: string;
    subCategory: (groupedByZones: boolean) => string;
    root?: string;
  };
  readonly?: boolean;
  itemFn?: (item: ExpandedReleaseItem) => {
    className: string;
  };
  emptyList?: ReactNode;
};

export const ReleaseItemList: FC<Props> = ({
  columns,
  classNames = {
    header: "top-[118px]",
    category: "top-[132px]",
    subCategory: (groupedByZones: boolean) =>
      groupedByZones ? "top-[218px]" : "top-[15px]",
    root: "",
  },
  itemFn,
  emptyList,
}) => {
  const { zones, toggleZone, toggleCostCode, groupedByCostCode } =
    useReleaseItemsZoneProvider();
  const { expandedItems } = useReleaseActions();
  const { loading } = useRelease();
  const { hideZones } = useHideZones(zones);
  const { release } = useRelease();
  const { updateStoreItems } = useReleaseStore(
    useShallow((state) => ({
      updateStoreItems: state.updateStoreItems,
    })),
  );

  useEffect(() => {
    if (release?.items) {
      updateStoreItems(release.items);
    }
  }, [release?.items, updateStoreItems]);

  return (
    <GridTable
      emptyList={
        emptyList ?? (
          <EmptyListContainer>
            <If isTrue={release?.status === ReleaseStatus.Reserved}>
              <InfoOutlined className="mr-1 text-gray-500" />
            </If>
            <FormattedMessage
              id={
                release?.status === ReleaseStatus.Reserved
                  ? "RESERVED_PO_WITHOUT_ITEMS"
                  : "NO_RELEASE_ITEMS"
              }
            />
          </EmptyListContainer>
        )
      }
      configuration={{
        container: ZoneItemsContainer,
        columns,
        classNames: {
          header: classNames.header,
          category: classNames.category,
          subCategory: groupedByCostCode
            ? classNames.subCategory(!hideZones)
            : "hidden",
          detailsContent: "lg:p-0",
          root: classNames.root,
          itemFn,
        },
        toggle: {
          category: toggleZone,
          subCategory: toggleCostCode,
        },
      }}
      items={zones}
      readonly
      hideGroup={hideZones}
      expandedItems={(item) => getExpandedItems(item, expandedItems)}
      loading={loading || !zones}
      virtualizedItemsCount={20}
      virtualized
      footer={<ReleaseNotesFooter />}
    />
  );
};
