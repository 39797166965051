import { GridTable } from "@/common/components/grid-table/GridTable";
import { getExpandedItems } from "@/common/components/grid-table/utils/getExpandedItems";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { Loader } from "@/common/components/loader/Loader";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutStore } from "../../../store/useBuyoutStore";
import { useBuyoutListNonDraftedConfiguration } from "../../common/table-configurations/BuyoutListNonDraftedConfiguration";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";

const Container = tw.div``;

type Props = {
  buyoutItems: BuyoutItemFieldsFragment[];
  loading: boolean;
  totalCount: number;
  isNonQuoted: boolean;
};

export const BuyoutList: FC<Props> = ({
  buyoutItems,
  loading,
  totalCount,
  isNonQuoted,
}) => {
  const { costCodes, toggleCategory, groupedByCostCode, filteredItems } =
    useBuyoutGrouped();
  const { buyout, expandedItems } = useContractorBuyout();

  const { updateStoreItems } = useBuyoutStore(
    useShallow((state) => ({
      updateStoreItems: state.updateStoreItems,
    })),
  );

  useEffect(() => {
    if (buyout?.items) {
      updateStoreItems(buyout.items);
    }
  }, [buyout?.items, updateStoreItems]);

  const costCodesItems = useMemo(() => {
    return costCodes.map((c) => ({
      ...c,
      items: c.items.filter((i) => buyoutItems.some((bi) => bi.id === i.id)),
    }));
  }, [buyoutItems, costCodes]);

  const itemFn = useCallback(
    (item: BuyoutItemFieldsFragment) => {
      if (buyoutItems.some((i) => i.id === item.id && !i.isIncluded)) {
        return { className: "bg-gray-100" };
      }
      return { className: "" };
    },
    [buyoutItems],
  );

  const isEmpty = useMemo(
    () => costCodesItems.length === 0 && buyoutItems.length === 0 && !loading,
    [costCodesItems.length, buyoutItems.length, loading],
  );

  const nonDraftedConfiguration = useBuyoutListNonDraftedConfiguration({
    buyout,
  });

  const items = useMemo(() => {
    return filteredItems.toSorted(
      (a, b) => (a.position || 0) - (b.position || 0),
    );
  }, [filteredItems]);

  const groupedItems = useMemo(
    () => (groupedByCostCode ? costCodesItems : items),
    [groupedByCostCode, costCodesItems, items],
  );

  if (!buyout) {
    return <Loader loading />;
  }

  return (
    <ListRenderer
      totalCount={totalCount}
      hasItemsCondition={!isEmpty}
      count={buyoutItems.length}
    >
      <GridTable
        configuration={{
          container: Container,
          columns: nonDraftedConfiguration,
          classNames: {
            header: "top-8",
            category: "top-[36px] lg:top-[74px] bg-yellow-200",
            itemFn: isNonQuoted ? itemFn : undefined,
            detailsContent: "lg:p-0",
          },
          toggle: {
            category: toggleCategory,
          },
        }}
        items={groupedItems}
        virtualized
        readonly
        loading={loading}
        expandedItems={(item) => getExpandedItems(item, expandedItems)}
      />
    </ListRenderer>
  );
};
